import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";

export interface IAccountGroupSetup {
    LDAPEnabled: boolean,
    LDAPServer: string,
    LDAPDomain: string,
    LDAPProvider: string
}

@Component({
    selector: 'app-account-group-setup',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-group-setup.component.html',
    styleUrl: './account-group-setup.component.css'
})
export class AccountGroupSetupComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    modified: boolean = false;
    editee: IAccountGroupSetup;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountGroupSetup }
    ) {
        this.editee = data.editee;
    }

    submit(): void {
        this.faxSrv.PutAccountsGeneral(this.editee).subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => alert(err.message)
        });
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
