import { Component, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from '../services/fenutils.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestApiKey } from '../api/api';

interface IEditee {
    Description: string,
    Enabled: boolean,
    Expires: string,
    Key?: string
}

@Component({
    selector: 'app-api-key-new',
    imports: [FormsModule, CommonModule],
    providers: [DatePipe],
    templateUrl: './api-key-new.component.html',
    styleUrl: './api-key-new.component.css'
})
export class ApiKeyNewComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    copiedToClipboard: boolean = false;
    copyImage: string = 'icon-copy-to-clipboard.png';

    expiration: string = 'expires';

    infinite: boolean = false;
    key: IEditee = {
        Description: '',
        Enabled: true,
        Expires: ''
    };
    Error: string | null = null;

    dateTitle: string = 'The expiration date for the key. The date must be at least one hour from now, and not later than 30 December 2037.';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        private clipboard: Clipboard,
        private datePipe: DatePipe
    ) {
        // Set default expiration to 1 year from today
        let exp: Date = new Date();
        exp.setFullYear(exp.getFullYear() + 1);
        this.key.Expires = this.datePipe.transform(exp, 'yyyy-MM-dd')!;
    }

    set_expirationmode(mode: number): void {
        this.infinite = (mode === 0);
    }

    ExpirationIsValid(): boolean {
        if (this.infinite) return true;
        if (!this.key.Expires) return false;
        let exp = new Date(this.key.Expires);
        let min = new Date(Date.now() + (60 * 60 * 1000));
        let max = new Date('2037-12-31T11:59:59Z');
        return (exp >= min && exp <= max);
    }

    CreateKeyEnabled(): boolean {
        return (!this.hasKey() && this.key.Description && this.ExpirationIsValid())? true: false;
    }

    CreateKey(): void {
        this.Error = null;
        let def: FaxConfigRestApiKey = {
            Description: this.key.Description,
            Enabled: this.key.Enabled
        };
        if (this.infinite) {
            def.Expires = '2037-12-31T12:00:00.000Z';
        } else {
            // Assume the chosen date was local, and convert to UTC.
            let local: Date = new Date(this.key.Expires);
            def.Expires = new Date(local.getFullYear(), local.getMonth(), local.getDate()).toISOString();
        }

        this.faxSrv.AddApiKey(def).subscribe({
            next: res => {
                if (res.Key) {
                    this.key.Key = res.Key;
                } else {
                    this.Error = res.Detail?? 'There was an unknown failure creating the key';
                }
            },
            error: err => {
                this.Error = err.message?? 'There was an unknown error creating the key';
            }
        });
    }

    hasKey(): boolean {
        return (this.key.Key !== undefined);
    }

    copyToClipboard(): void {
        let remainingAttempts = 3;
        const pending = this.clipboard.beginCopy(this.key.Key!);
        const attempt = () => {
            if (pending.copy()) {
                pending.destroy();
                this.copiedToClipboard = true;
                // Flash the icon for 1/2 second, to give some feedback
                this.copyImage = 'icon-checkmark.png';
                setTimeout(() => {
                    this.copyImage = 'icon-copy-to-clipboard.png';
                }, 500);
            } else if (remainingAttempts > 0) {
                remainingAttempts--;
                setTimeout(attempt);
            } else {
                pending.destroy();
                alert('The attempt to copy the key to the clipboard was unsuccessful.');
            }
        };
        attempt();
    }

    closeDialog(): void {
        if (this.hasKey()) {
            if (this.copiedToClipboard || confirm('This is the only opportunity to copy the API key.\nAre you sure you wish to close the dialog?')) {
                this.close();
            }
        } else {
            // No key generated, so no need to warn
            this.close();
        }
    }

    close(): void {
        this.dialogRef.close(this.hasKey());
    }
}
