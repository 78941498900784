import { CommonModule } from '@angular/common';
import { Observable, forkJoin, map } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestNumSubstDef, FaxConfigRestResult } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, ICellRendererParams, GridOptions, GridReadyEvent,
    GetContextMenuItemsParams, MenuItemDef, PaginationChangedEvent, ValueFormatterParams,
    CellDoubleClickedEvent, ValueGetterParams, FilterChangedEvent
} from 'ag-grid-community';
import { NumSubstComponent, INumSubstScope } from '../num-subst/num-subst.component';

interface IMachine {
    id: string | null;
    label: string;
}

@Component({
    selector: 'app-num-subst-list',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './num-subst-list.component.html',
    styleUrl: './num-subst-list.component.css'
})
export class NumSubstListComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    gridInitialized: boolean = false;
    cssGridHeight = { 'height': '300px' };
    gridOptions: GridOptions = {};
    gridApi: GridApi | null = null;
    machines: IMachine[] = [];
    machine: string | null = null;
    canEnable: boolean = false;
    canDisable: boolean = false;
    canRemove: boolean = false;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    private colEnabledCellRenderer(params: ICellRendererParams) {
        let img = document.createElement("img");
        if (params.value === 'true') {
            img.src ='icon-checkmark.png';
        } else {
            img.src ='icon-cross.png';
        }
        return img.outerHTML;
    }

    private substituteDisplayName(params: FaxConfigRestNumSubstDef): string {
        return params.Block? '<Blocked>': (params.Substitute?? '');
    }

    private substituteComparator(valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean): number {
        let dispA = (nodeA?.data?.Block)? '<Blocked>': (valA?? '');
        let dispB = (nodeB?.data?.Block)? '<Blocked>': (valB?? '');
        return dispA.localeCompare(dispB);
    }

    numsubstGridColDefs: ColDef<FaxConfigRestNumSubstDef>[] = [
        {
            field: 'Enabled',
            headerTooltip: 'Enabled',
            cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
            valueGetter: (params: ValueGetterParams) => {
                return params.data.Enabled? 'true': 'false';
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
                suppressMiniFilter : true,
                suppressSelectAll : true
            }
        },
        {
            field: 'Block',
            hide: true,
            filter: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            field: 'MessageType',
            headerTooltip: 'Message Type',
            filter: 'agSetColumnFilter',
            filterParams: { suppressMiniFilter : true }
        },
        {
            field: 'Number',
            headerTooltip: 'Number',
            filter: 'agSetColumnFilter'
        },
        {
            field: 'Substitute',
            headerName: 'Substitute With',
            headerTooltip: 'Substitute With',
            valueFormatter: (params: ValueFormatterParams) => this.substituteDisplayName(params.data),
            filter: 'agSetColumnFilter',
            filterValueGetter: (params: ValueGetterParams) => this.substituteDisplayName(params.data),
            comparator: (valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean) => this.substituteComparator(valA, valB, nodeA, nodeB, isDesc),
        },
        {
            field: 'Machine',
            headerTooltip: 'Machine',
            hide: true,
            filter: 'agSetColumnFilter'
        },
        {
            field: 'Description',
            headerTooltip: 'Description',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        }
    ];

    private init(): void {
        this.gridOptions = {
            columnDefs: this.numsubstGridColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            rowModelType: 'clientSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            defaultCsvExportParams:{ allColumns: true, fileName: 'export_numsubst.csv' },
            onGridReady: (params: GridReadyEvent) => {
                this.gridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
                this.faxSrv.numSubstMachines().subscribe(machines => {
                    let entries: IMachine[] = [{ id: null, label : 'All' }];
                    machines.forEach(e => {entries.push({id: e, label: e})});
                    this.machines = entries;
                    this.machine = machines ? machines[0] : null;
                    this.LoadViewState(params.api);
                    this.refresh();
                    this.isReady = true;
                });
            },
            onCellDoubleClicked: params => this.OnCellDoubleClicked(params),
            onSelectionChanged: params => this.OnSelectionChanged(params.api),
            onPaginationChanged: params => this.OnPaginationChanged(params),
            onFilterChanged: params => this.OnFilterChanged(params),
            getContextMenuItems: params => this.GetContextMenuItems(params),
            onGridPreDestroyed: params =>this.SaveViewState(params.api)
        };
        this.gridInitialized = true;
    }

    export(): void {
        this.gridApi?.exportDataAsCsv();
    }

    LoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'NumSubstViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    SaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'NumSubstViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    GetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        let sel = params.api.getSelectedNodes()?? [];

        const canEdit = this.auth.isModifiable('Number Substitution');
        return ['copy','separator',
            {
                name: 'New ...',
                action: () => this.neo(),
                disabled: (this.machine === null) || (!canEdit)
            },
            {
                name: canEdit ? 'Edit entry...' : 'View entry...',
                action: () => this.OnCellDoubleClicked(sel[0]),
                disabled: (!sel || sel.length != 1)
            },
            {
                name: 'Remove entries',
                action: () => this.remove(),
                disabled : (!sel || sel.length == 0 ) || (!canEdit)
            },
            'separator',
            {
                name: 'CSV Export',
                action: () => this.export(),
                disabled : false
            }
        ];
    }

    OnSelectionChanged(api: GridApi | null): void {
        let enabled = 0, disabled = 0;
        let sel = api?.getSelectedNodes()?? [];
        this.canRemove = (sel && sel.length > 0);
        _.each(sel, item => {
            if (item.data.Enabled) { enabled++; } else { disabled++; }
        });
        this.canEnable = disabled > 0;
        this.canDisable = enabled > 0;
    }

    OnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger OnSelectionChanged:
        params.api.deselectAll();
    }

    OnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger OnSelectionChanged:
        params.api.deselectAll();
    }

    OnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit(params.data);
        }
    }

    neo(): void {
        if (this.machine) {
            let data: INumSubstScope = {
                editee: null,
                machine: this.machine.toUpperCase()
            };
            const dialogRef = this.dialog.open(NumSubstComponent, { data });
            dialogRef.afterClosed().subscribe(() => {
                this.refresh();
            });
        }
    }

    edit(subst: FaxConfigRestNumSubstDef): void {
        let data: INumSubstScope = {
            editee: subst,
            machine: subst.Machine!
        };
        const dialogRef = this.dialog.open(NumSubstComponent, { data });
        dialogRef.afterClosed().subscribe(() => {
            this.refresh();
        });
    }

    enable(): void { this.updateEnabled(true); };
    disable(): void { this.updateEnabled(false); };

    private updateEnabled(enabled: boolean): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.gridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestNumSubstDef>) => {
            const subst: FaxConfigRestNumSubstDef = item.data!;
            let newdata = _.extend({}, subst, { Enabled: enabled });
            promises.push(this.faxSrv.updateNumSubstDefinition(
                    subst.Machine!,
                    subst.MessageType!,
                    subst.Number!,
                    newdata
                ).pipe(map(res => {
                    if (res.Status === 'ok') {
                        item.updateData(newdata);
                    }
                    return res;
                }
            )));
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => this.fenUtils.afterSave(res),
                error: err => alert(err.message)
            });
        }
    }

    remove(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.gridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestNumSubstDef>) => {
            const subst: FaxConfigRestNumSubstDef = item.data!;
            promises.push(this.faxSrv.deleteNumSubstDefinition(
                    subst.Machine!,
                    subst.MessageType!,
                    subst.Number!,
            ));
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.refresh();
                    }
                },
                error: err => {
                    alert(err.message);
                    this.refresh();
                }
            });
        }
    }

    private refresh(): void {
        this.canRemove = false;
        this.canEnable = false;
        this.canDisable = false;
        this.faxSrv.numSubstDefinitions(this.machine).subscribe((numSubstDefinitions) => {
            // This will trigger OnPaginationChanged and OnSelectionChanged:
            this.gridApi?.setGridOption('rowData', numSubstDefinitions);
        });
    }

    onMachineChange(): void {
        this.refresh();
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number): void {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }
}
