import { AfterViewInit, Component, Inject, inject, ViewChild } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestUser } from '../api/api';
import { NewUserDefaultsModule, NewUserDefaultsComponent } from '../new-user-defaults/new-user-defaults.module';
import * as _ from 'underscore';

@Component({
    selector: 'app-conn-newuserdialog',
    imports: [FormsModule, CommonModule, NewUserDefaultsModule],
    templateUrl: './conn-newuserdialog.component.html',
    styleUrl: './conn-newuserdialog.component.css'
})
export class ConnNewuserdialogComponent implements AfterViewInit {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    isModifiable: boolean = false;
    editee: FaxConfigRestUser;

    @ViewChild(NewUserDefaultsComponent)
    newUser!: NewUserDefaultsComponent;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data as FaxConfigRestUser;
        this.isModifiable = auth.isModifiable('Connectors');
        this.isReady = true;
    }

    ngAfterViewInit(): void {
        this.newUser.init(this.editee, this.isModifiable);
    }

    isValid(): boolean {
        return this.isReady && (this.newUser?.isValid()?? false);
    }

    save(): void {
        let user: FaxConfigRestUser = this.newUser?.getEditee();
        if (user) {
            this.faxSrv.UpdateConnHostsNewUserDefaults('', '', user).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close();
                }
            });
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
