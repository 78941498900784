<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ scope.caption }}</h1>
    </div>
    <form (submit)="doOnOk()" class="form" id="confirmationForm">
        <div class="modal-body" *ngIf="isReady">
            <div style="overflow: auto; ">
                <p><img src="icon-exclamation.png" />{{ scope.message1 }}</p>
            </div>
            <p>{{ scope.message2 }}</p>
            <div style="overflow: auto; ">
                <div style="float: left;">
                    <input type="text" tabindex="1" maxlength="150" [(ngModel)]="entered_text" name="prompt" size="40" placeholder="Type '{{ scope.key_text }}' for confirmation."/>
                </div>
                <div style="float: right;">
                    <img *ngIf="!key_entered()" src="icon-cross.png"/>
                    <img *ngIf="key_entered()" src="icon-checkmark.png" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" tabindex="2" [disabled]="!key_entered()">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
