<section id="monitoring_rule_queue" class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="isModifiable">{{ title }} </span>{{editee.__type}} Rule</h1>
    </div>
    <form id="monitoring_form" class="form" novalidate (submit)="save()" name="monitoringForm">
        <div class="modal-body">
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="editee.Enabled" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
                title="Enable or disable this Rule.">
            </div>
            <div class="field">
                <label>Display Name:</label>
                <input type="text" name="DisplayName" [(ngModel)]="editee.DisplayName" size="40" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
                title="Enter a descriptive friendly name for this Rule definition.">
            </div>
            <div class="field" *ngIf="editee.__type.toLowerCase()=='queueitems'">
                <label>Items: *</label>
                <input type="number" min="1" max="1000000" name="Items" [(ngModel)]="$any(editee).Items" required [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="3"
                title="Enter a number of items. If the total items in the Faxination Queue reaches this number, the Notification Sender(s) are triggered.">
            </div>
            <div class="field" *ngIf="editee.__type.toLowerCase()=='queueage'">
                <label>Age: *</label>
                <input type="number" min="1" max="1000000" name="Age" [(ngModel)]="$any(editee).Age" required [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
                title="Enter an age threshold in minutes. If any item in the Faxination Queue reaches this age, the Notification Sender(s) are triggered.">&nbsp;minutes
            </div>
            <div class="field">
                <label>Template:</label>
                <select name="Template" [(ngModel)]="editee.Template" [disabled]="!isModifiable" tabindex="5"
                    (focusin)="focusin($event)" title="Choose a template for the notification message which is sent.">
                    <option *ngFor="let def of ruleTemplates" [value]="def.Name">{{ def.Name }}</option>
                </select>
            </div>

            <h3>Notification Senders</h3>
            <span *ngIf="editee.__type.toLowerCase()=='queueitems'">
            Any Senders that are checked will be activated when the number of items in the Queue reaches the specified amount.
            </span>
            <span *ngIf="editee.__type.toLowerCase()=='queueage'">
            Any Senders that are checked will be activated when an item in the Queue reaches the specified age.
            </span>

            <table id="monitoring_senders" class="table table-condensed table-bordered">
                <thead>
                    <tr>
                        <th width="25px"></th>
                        <th>Display Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sender of monitoringSenders; let idx = index;" (focusin)="focusin($event)" (click)="focusin($event)"
                            title="If checked, use this Sender to deliver notifications.">
                        <td><input type="checkbox" [name]="'Sender'+idx" [(ngModel)]="senderChecked[sender.ID!]" [disabled]="!isModifiable"
                            (ngModelChange)="listCheckboxHandler(senderChecked[sender.ID!], sender)" tabindex="6"></td>
                        <td>{{ sender.DisplayName }}</td>
                        <td>{{ sender.__type }} Sender</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="monitoring_footer">
            <strong>Description</strong>
            <p id="monitoring_description" class="well">{{ Description }}</p>

            <div class="buttons">
                <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
                <button *ngIf="isModifiable" type="button" class="btn" [disabled]="!submitEnabled()" (click)="apply()">Apply</button>
                <button type="button" class="btn" (click)="close()">Close</button>
            </div>
        </div>
    </form>
</section>
