<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="isAccountsModifiable">Edit </span>Account '{{ editee.OrigUsername }}'</h1>
    </div>
    <form id="account_edit_form" #accountEditForm="ngForm" class="form" novalidate (submit)="submit()">
        <div class="modal-body" *ngIf="isReady">

            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="enabled" [(ngModel)]="editee.Enabled" [disabled]="!isAccountsModifiable">
            </div>

            <div class="field" *ngIf="session.isMultiTenant() && isOrganizationsViewable">
                <label>Organization:</label>
                <span>{{ editee.OrganizationName }}</span>
            </div>
			<div [ngSwitch]="editee.Source" class="field">
				<label>Account type:</label>
                <span *ngSwitchCase="'SQLDB'">Local account</span>
                <span *ngSwitchCase="'AzureAD'">Fenestrae account on Microsoft online</span>
                <span *ngSwitchCase="'AzureAD_MultiTenant'">Work or school or Microsoft personal account</span>
                <span *ngSwitchCase="'AzureAD_Social'">Social account</span>
                <span *ngSwitchDefault="">{{ editee.Source }}</span>
            </div>

            <div class="field">
            @if (editee.Source==='AzureAD') {
                <label>Email address:</label>
                <span>{{ editee.Alias }}&#64;{{ editee.Domain }}</span>
            }
            @else if (isAccountsModifiable) {
                <label>Username:&nbsp;*</label>
                @if (editee.IsEmailUsername) {
                    <input type="email" email maxlength="100" #name="ngModel" name="name" [(ngModel)]="editee.Username" required size="40"
                            title="Enter the username for the account. Must be in the form of an email address.">&ngsp;
                    <span class="text-danger" *ngIf="name.dirty && name.errors?.['required']">&nbsp;A username is required</span>
                    <span class="text-danger" *ngIf="name.dirty && name.errors?.['email']">&nbsp;Not a valid email address</span>
                } @else {
                    <input type="text" maxlength="100" #name="ngModel" name="name" [(ngModel)]="editee.Username" required size="40"
                            title="Enter the username for the account. It is recommended to be in the form of an email address.">&ngsp;
                    <span class="text-danger" *ngIf="name.dirty && name.errors?.['required']">&nbsp;A username is required</span>
                }
            } @else {
                <label>Username:&nbsp;</label>
                <input type="text" disabled name="name" maxlength="100" [(ngModel)]="editee.Username" required size="40">
            }
            </div>

            @if (isAccountsModifiable && !editee.IsAzureUser) {
                @if (editee.IsSelf) {
                    <div class="field">
                        <label>Current Password:</label>
                        <input type="password" autocomplete="off" [(ngModel)]="editee.Password" name="password"
                            title="Enter the current password for the account" />
                    </div>
                    <div class="field">
                        <label>New Password:</label>
                        <input type="password" autocomplete="off" maxlength="100" [(ngModel)]="editee.NewPassword"
                                title="Enter a new password for the account" name="NewPassword" />
                    </div>
                    <div class="field">
                        <label>Verify Password:</label>
                        <input type="password" autocomplete="off" maxlength="100" [(ngModel)]="editee.Verify"
                            title="Verify the new password for the account" name="verifyPassword"/>
                    </div>
                } @else {
                    <div class="field">
                        <label>New Password:</label>
                        <input type="password" autocomplete="off" maxlength="100" [(ngModel)]="editee.NewPassword"
                                title="Enter a new password for the account" name="NewPassword" />
                    </div>
                }
            }
            @if (editee.IsEmailUsername && editee.Source==='SQLDB') {
                <div class="field">
                    <label>MFA Enabled:</label>
                    <input type="checkbox" name="MfaEnabled" id="MfaEnabled"
                    [(ngModel)]="mfaEnabled" [disabled]="!isAccountsModifiable || mfaEnforced"
                    title="Enable or disable Multi-Factor Authentication for the account">
                </div>
            }
            <div class="field">
                <label>Department:</label>
                <input type="text" maxlength="100" [(ngModel)]="editee.Department" size="40"
                    [disabled]="!isAccountsModifiable" title="Enter an optional Department name for the account" name="department"/>
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" maxlength="100" [(ngModel)]="editee.Description" size="40"
                    [disabled]="!isAccountsModifiable" title="Enter an optional Description of the account" name="description" />
            </div>

            @if (!editee.IsSelf && isAccountsModifiable) {
                <br>
                <div class="field">
                    This user will have the following role:
                </div>
                <div class="field-indented" >
                    @for (role of editee.AllowedRoles; track role) {
                        <input type="radio" name="role" [(ngModel)]="editee.Role" id="role_{{ role }}" [value]="role" [attr.value]="role">
                        <label for="role_{{ role }}" style="margin-bottom: 0;">{{ role }}</label><br>
                    }
                </div>
            } @else {
                <div class="field">
                    <label>Role:</label>
                    <span>{{ editee.Role }}</span>
                </div>
            }

            <br>
            <div class="field" style="margin-bottom: 0;">
                <label>Last login:</label>
				<span>{{ editee.LastLogin | date:'yyyy-MM-dd' }}</span>
            </div>

            <div class="field" style="margin-bottom: 0;">
                <label>Created:</label>
				<span>{{ editee.Created | date:'yyyy-MM-dd' }}</span>
            </div>

            <div class="field">
                <label>Modified:</label>
				<span>{{ editee.Modified | date:'yyyy-MM-dd' }}</span>
            </div>

            @if (editee.IsSelf && isAccountsModifiable && editee.Source!=='AzureAD') {
                <br>
                <p class="alert-info">
                    If you change your Username, you will need to login again with your new credentials.
                </p>
            }
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" name="save" *ngIf="isAccountsModifiable" [disabled]="!accountEditForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()" name="close">Close</button>
        </div>
    </form>
</section>
