import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestAccountBase, FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";

export interface IAccountPassword {
    OldPassword: string,
    NewPassword: string,
    VerifyPassword: string
}

@Component({
    selector: 'app-account-password',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-password.component.html',
    styleUrl: './account-password.component.css'
})
export class AccountPasswordComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    modified: boolean = false;
    editee: IAccountPassword;
    isReset: boolean;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountPassword, isReset?: boolean }
    ) {
        this.editee = data.editee;
        this.isReset = data.isReset?? false;
    }

    doSave(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestAccountBase = {
            ValidationPassword: this.editee.OldPassword,
            Password: this.editee.NewPassword
        };
        return this.faxSrv.PutAccountsPassword(def);
    }

    submit(): void {
        if (this.editee.VerifyPassword !== this.editee.NewPassword) {
            alert('The verify password you entered does not match the new password.');
        } else {
            this.doSave().subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.modified = true;
                        this.close();
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
