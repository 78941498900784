<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Create Account</h1>
    </div>
    <form id="account_create_form" #accountCreateForm="ngForm" class="form" novalidate (submit)="submit()">
        <div class="modal-body" *ngIf="isReady">
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="enabled" [(ngModel)]="editee.Enabled">
            </div>
            @if (editee.IsAzureUser && hasMultipleAllowedSources()) {
                <div class="field">Account type:</div>
                <div class="field-indented" *ngIf="editee.AllowLocal" >
                    <input type="radio" name="Source" [(ngModel)]="editee.Source" id="Source_SQLDB" value="SQLDB">
                    <label for="Source_SQLDB">Local account</label>
                </div>
                <div class="field-indented" *ngIf="editee.AllowFenTenant" >
                    <input type="radio" name="Source" [(ngModel)]="editee.Source" id="Source_AzureAD" value="AzureAD">
                    <label for="Source_AzureAD">Fenestrae account on Microsoft online</label>
                </div>
                <div class="field-indented" *ngIf="editee.AllowMultiTenant" >
                    <input type="radio" name="Source" [(ngModel)]="editee.Source" id="Source_AzureAD_MultiTenant" value="AzureAD_MultiTenant">
                    <label for="Source_AzureAD_MultiTenant">Work or school account or Microsoft personal account</label>
                </div>
                <div class="field-indented" *ngIf="editee.AllowSocial" >
                    <input type="radio" name="Source" [(ngModel)]="editee.Source" id="Source_AzureAD_Social" value="AzureAD_Social">
                    <label for="Source_AzureAD_Social">Social account</label>
                </div>
            } @else {
                <div [ngSwitch]="editee.Source" class="field">
                    <label>Account type:</label>
                    <span *ngSwitchCase="'SQLDB'">Local account</span>
                    <span *ngSwitchCase="'AzureAD'">Fenestrae account on Microsoft online</span>
                    <span *ngSwitchCase="'AzureAD_MultiTenant'">Work or school or Microsoft personal account</span>
                    <span *ngSwitchCase="'AzureAD_Social'">Social account</span>
                    <span *ngSwitchDefault>{{ editee.Source }}</span>
                </div>
            }
            <br>
            <div class="field" *ngIf="editee.Source==='AzureAD_MultiTenant'">
                <span class="text-info">Please enter the <u>primary</u> email address of the account in the Username field.</span>
            </div>
			<div class="field">
                @if (editee.Source==='AzureAD') {
				    <label>Email address:&nbsp;*</label>
				    <input type="text" maxlength="100" #alias="ngModel" name="alias" [(ngModel)]="editee.Alias" required size="20"
				    title="Enter the email address of the user.">
        			<span>&nbsp;&#64;&nbsp;</span>
				    <select *ngIf="hasMultipleDomains()" name="domain" [(ngModel)]="editee.Domain">
                        <option *ngFor="let d of editee.Domains" title="Select the email domain of the user.">{{ d }}</option>
                    </select>
				    <span *ngIf="!hasMultipleDomains()">{{ editee.Domain }}</span>
                    &ngsp;
				    <span class="text-danger" *ngIf="alias.dirty && alias.errors?.['required']">&nbsp;An email address is required</span>
                } @else {
				    <label>Username:&nbsp;*</label>
				    <input type="email" email maxlength="100" #username="ngModel" name="username" [(ngModel)]="editee.Username" required size="40"
				    title="Enter the username for the account. Must be in the form of an email address.">&ngsp;
				    <span class="text-danger" *ngIf="username.dirty && username.errors?.['required']">&nbsp;A username is required</span>
				    <span class="text-danger" *ngIf="username.dirty && username.errors?.['email']">&nbsp;Not a valid email address</span>
                }
		    </div>
            <div class="field" *ngIf="!editee.IsAzureUser || editee.Source=='SQLDB' || editee.Source=='AzureAD'">
                <label>Password:&nbsp;*</label>
                <input type="password" autocomplete="off" maxlength="100" #password="ngModel" name="password" [(ngModel)]="editee.Password" required
                title="Enter the password for the account">&ngsp;
                <span class="text-danger" *ngIf="password.dirty && password.errors?.['required']">&nbsp;A password is required</span>
            </div>
            @if (editee.Source==='SQLDB') {
                <div class="field">
                    <label>MFA Enabled:</label>
                    <input type="checkbox" name="MfaEnabled" id="MfaEnabled"
                    [(ngModel)]="mfaEnabled" [disabled]="mfaEnforced"
                    title="Enable or disable Multi-Factor Authentication for the account">
                </div>
            }
            <div class="field">
                <label>Department:</label>
                <input type="text" name="department" maxlength="100" [(ngModel)]="editee.Department" size="40"
                title="Enter an optional Department name for the account">
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" name="description" maxlength="100" [(ngModel)]="editee.Description" size="40"
                title="Enter an optional Description of the account">
            </div>
            <br>
            <div class="field">
                This user will have the following role:
            </div>
            <div class="field-indented">
                @for (role of editee.AllowedRoles; track role) {
                    <input type="radio" name="role" [(ngModel)]="editee.Role" id="role_{{ role }}" [value]="role" [attr.value]="role">
                    <label for="role_{{ role }}" style="margin-bottom: 0;">&nbsp;{{ role }}</label><br>
                }
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!accountCreateForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
