<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="cancel()">&times;</button>
        <h1>{{ scope.title }}</h1>
    </div>
    <form id="queue_job_reroute_form" class="form" [hidden]="!isReady" novalidate (submit)="submit()" name="queueJobRerouteForm">
        <div class="modal-body">
            @if (txtRoutingCodeEnabled) {
                <div>
                    <p>Enter a new inbound routing code:</p>
                    <input type="text" name="newRoutingCode" [(ngModel)]="editee.txtRoutingCode" placeholder="{{ scope.jobRoutingCode }}" [required]="txtRoutingCodeEnabled" style="width: calc(100% - 15px);" >
                </div>
            } @else {
                <div id="queue_reroute_org_select" *ngIf="editee.currentOrg">
                    <span><img src="icon-organizations.png" /></span>&nbsp; {{ editee.currentOrg.Name }} &nbsp; <i *ngIf="auth.isViewable('Organizations')" class="glyphicon glyphicon-chevron-down"></i> &nbsp;
                    <ul class="dropdown-menu" *ngIf="auth.isViewable('Organizations')" >
                        <li class="btn-link" (click)="selectOrganization();">Select other organization</li>
                        <li *ngIf="editee.previousOrg" class="btn-link" (click)="changeOrganization(editee.previousOrg.ID, editee.previousOrg.Name);">{{ editee.previousOrg.Name }}</li>
                        <li class="btn-link" (click)="changeOrganization(session.currentUserOrgId!, session.currentUserOrgName!);">{{ session.currentUserOrgName }}</li>
                        <li class="btn-link" (click)="changeOrganization(-1, 'All organizations');">All organizations</li>
                    </ul>
                </div>
                @if (orgHasNoNumbers) {
                    <div [ngStyle]="pagerPadding()"></div>
                    <p>You cannot re-route to organization &quot;{{ activeOrganization() }}&quot; because it does not have any phone numbers assigned to users.</p>
                } @else {
                    <form role="form" (submit)="searchRoutingCodes()">
                        <div [ngClass]="searchClass()">
                            <i class="form-control-feedback glyphicon glyphicon-search" (click)="searchRoutingCodes()"></i>&ngsp;
                            <input type="text" class="form-control" name="searchText" [(ngModel)]="searchText" placeholder="Search" />
                        </div>
                    </form>
                    <div [ngStyle]="pagerPadding()"></div>
                }
            }
            <div [hidden]="txtRoutingCodeEnabled || orgHasNoNumbers">
                <app-pagination [limit]="session.portalCfg.items_per_page" [query]="queryRoutingCodes"></app-pagination>
                <table *ngIf="isReady" id="routing_code_list" class="table table-click table-condensed table-bordered">
                    <thead>
                        <tr>
                            <th width="25px"></th>
                            <th>Number</th>
                            <th>Users</th>
                            <th *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')">Description</th>
                            <th *ngIf="editee.currentOrg && (editee.currentOrg.ID === -1)">Organization</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pagerRoutingCodes.items" (click)="editee.selRoutingCode=item.Address">
                            <td><input type="radio" name="group" [ngModel]="editee.selRoutingCode" [value]="item.Address" [attr.value]="item.Address"></td>
                            <td>{{ item.Address }}</td>
                            <td>{{ item.UserNames }}</td>
                            <td *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')">{{ item.Description }}</td>
                            <td *ngIf="editee.currentOrg && (editee.currentOrg.ID === -1)">{{ item.OrganizationName }}</td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="isReady && pagerRoutingCodes?.isEmpty()">No Results</div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">OK</button>
            <button type="button" class="btn" (click)="cancel()">Cancel</button>
        </div>
    </form>
</section>
