export enum PermissionSet  {
    All                     = 'All',
    Accounts                = 'Accounts',
    Licenses                = 'Licenses',
    Services                = 'Services',
    Coversheets             = 'Coversheets',                  /* 5 */
    ECR                     = 'Extended Custom Routing',      /* 6 */
    Log                     = 'Log',
    OSM                     = 'Outbound Service Management',  /* 8 */
    OSR                     = 'Outbound Service Routing',     /* 9 */
    Queue                   = 'Queue',
    Users                   = 'Users',
    FaxinationServerMonitor = 'Faxination Server Monitor',    /* 12 */
    NumberSubstitution      = 'Number Substitution',          /* 13 */
    ServiceRequest          = 'Service Request',
    Connectors              = 'Connectors',                   /* 15 */
    KernelProperties        = 'Kernel Properties',            /* 16 */
    FaxLineLimits           = 'Fax Line Limits',              /* 17 */
    FaxinationKernelConfig  = 'FaxinationKernelConfig',       /* 18 */
    AuditLog                = 'AuditLog',                     /* 19 */
    SDXProperties           = 'SDX Properties',               /* 20 */
    Organizations           = 'Organizations',
    Addresses               = 'Addresses',
    OrganizationProperties  = 'Organization Properties',
    KernelContracts         = 'Kernel Contracts',
    APIKeys                 = 'APIKeys',
    AddressRegistration     = 'AddressRegistration'
}

interface storageKeylist {
    readonly currentUser : string
}


interface  ConstantTable {
    readonly storageKeys: storageKeylist
}

export const Constants : ConstantTable = {
    storageKeys : {
        currentUser : ''
    }
} 