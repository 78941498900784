<h1>Overview</h1>

<p>
    This website is designed for managing and monitoring your Faxination server.
    This tool is not designed to troubleshoot Faxination or advanced configuration.
    Please use the Faxination Server Administrator when this is required.<br/><br/>
</p>

<h3 *ngIf="auth.isViewable('Organizations') || auth.isViewable('Accounts') || auth.isViewable('Licenses') || auth.isViewable('Log') || auth.isViewable('Queue') || auth.isViewable('Services') || auth.isViewable('Service Request') || auth.isViewable('Users') || ((auth.isViewable('Addresses') ||  auth.isViewable('AddressRegistration')) && session.usePhonenumberAdministration())"><span *ngIf="hasAdminRole()">Admin </span>Shortcuts</h3>

<div class="overview_shortcuts_wrapper">
    <nav>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Organizations') && session.isMultiTenant()">
        <div class="overview_shortcut_icon"><img src="icon-organizations.png"></div>
        <div  class="overview_shortcut_text">
            <a routerLink="/organizations">Organizations</a><br/>
            Define the organizations that can use the Faxination Cloud services
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Accounts')">
        <div class="overview_shortcut_icon"><img src="icon-accounts.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/accounts">Accounts</a><br/>
            Control who has access to the Faxination Management Portal
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isModifiable('All') || ( (auth.isViewable('Addresses') ||  auth.isViewable('AddressRegistration')) && session.usePhonenumberAdministration() )">
        <div class="overview_shortcut_icon"><img src="icon-addresses.png"></div>
        <div *ngIf="session.usePhonenumberAdministration(); else noPhoneAdmin" class="overview_shortcut_text">
            <a href="#/addresses">Phone Numbers</a><br/>
            See all phone numbers available
        </div>
        <ng-template #noPhoneAdmin>
            <div class="overview_shortcut_text">
                <a href="#/addresses">Phone Numbers</a><br/>
                Phone number administration is disabled
            </div>
        </ng-template>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Licenses')">
        <div class="overview_shortcut_icon"><img src="icon-license.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/license">License</a><br/>
            Check the status of your license
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Log')">
        <div class="overview_shortcut_icon"><img src="icon-log.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/log">Log</a><br/>
            View your log<span *ngIf="auth.isModifiable('Log')"> and perform actions</span>
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Log')">
        <div class="overview_shortcut_icon"><img src="icon-reporting.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/reporting">Reporting</a><br/>
            View reports
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Queue')">
        <div class="overview_shortcut_icon"><img src="icon-queue.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/queue">Queue</a><br/>
            View<span *ngIf="auth.isModifiable('Queue')"> and manage</span> your Faxination Queue
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Services')">
        <div class="overview_shortcut_icon"><img src="icon-services.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/nt_services">Services</a><br/>
            See if your Faxination services are running<span *ngIf="auth.isModifiable('Services')"> and start/stop them when necessary</span>
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Services')">
        <div class="overview_shortcut_icon"><img src="icon-systeminfo.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/system_info">System Info</a><br/>
            List the file versions of Faxination system components
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('Users')">
        <div class="overview_shortcut_icon"><img src="icon-users.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/users">Users</a><br/>
            Control who can use the Faxination Cloud services
        </div>
    </div>
    <div class="overview_shortcut"  *ngIf="auth.isViewable('APIKeys')">
        <div class="overview_shortcut_icon"><img src="icon-apikey.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/apikeys">API Keys</a><br/>
            Manage the keys for accessing API's from programs 
        </div>
    </div>

    <div class="overview_shortcut"   *ngIf="auth.isViewable('Service Request')">
        <div class="overview_shortcut_icon"><img src="icon-service.png"></div>
        <div  class="overview_shortcut_text">
            <a href="#/service_request">Service Request</a><br/>
            For questions or issues please use this
        </div>
    </div>

    </nav>
</div>


