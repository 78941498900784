import { Component, Input } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

export interface IPaginationQuery{
  invoke(offset:number, limit:number): Observable<any[]>;
}

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrl: './pagination.component.css',
    standalone: false
})
export class PaginationComponent implements IPaginationQuery{
  @Input() limit: number = 50
  @Input() query: IPaginationQuery =this; // Use stub by default

  offset: number;
  ready : boolean = false;
  items : any[] = []
  more: boolean = false;
  
  constructor (){
    this.offset = 0
    this.items = []
  }

  lo(): number { 
    return this.offset +1;
  }
  hi(): number | null {
    return  this.items ? this.offset+ this.items.length : null ;
  }

  isFirst(): boolean {
    return this.items ? this.offset ===0: true;
  }
  
  isLast(): boolean {
    return this.ready? !this.more :true;
  }

  isEmpty():boolean{
    return this.items ===null || this.items.length === 0;
  }

  next(): void {
    if (!this.isLast()) {
        this.ready = false;
        this.items = []
        this.offset += this.limit
        this._doQuery()
    }
}
prev():void {
    if (!this.isFirst()) {
        this.ready = false;
        this.items = []
        this.offset -= this.limit
        this._doQuery()
    }
}
// go to the first page
reset():void {
    this.ready = false;
    this.items = [];
    this.offset = 0;
    this._doQuery()
}
// refresh the current page
refresh():void {
    this.items = [];
    this.ready = false;
    this._doQuery()
}

  invoke(offset: number, limit:number): Observable<any[]> { // stub
    let e =new Subject<[]>()
    timer(10).subscribe(() => e.next( []));
    return e;
  };

  private _doQuery() {
      let pager = this;
      this.ready = false;
      // Ask one item too many, so that we know if there are more items.
      pager.query.invoke(pager.offset, pager.limit + 1).subscribe(function(items) {
          pager.more = items.length > pager.limit;
          pager.items = pager.more ? items.slice(0, pager.limit) : items;
          pager.ready = true;
      })
  }

}

