<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>
            {{ action }} {{ editee.RuleType }} Rule Template
        </h1>
    </div>
    <form class="form" name="monitoringTemplates" novalidate (submit)="save()">

        <div class="modal-body">
            <div class="field">
                <label>Filename:</label>&nbsp;
                <input type="text" [(ngModel)]="editee.Name" name="Filename" size="40" required [disabled]="!isModifiable || !bIsNew">
            </div>

            <textarea [(ngModel)]="editee.Content" name="Content" rows="15" spellcheck="false" (ngModelChange)="templateChange()" [disabled]="!isModifiable"></textarea>

            <h3 (click)="toggleSection('placeholders')" style="cursor:pointer;"><i [ngClass]="['glyphicon', sectionActionCssClass('placeholders')]"></i> Placeholders</h3>
            <div class="section" *ngIf="sections['placeholders']">

            <p>The following placeholders may be used in the template text:</p>
            <table>
                <tbody>
                    <tr>
                        <td valign="top"><strong style="white-space:nowrap">%Rule.DisplayName%&nbsp;</strong></td>
                        <td>The DisplayName of the Rule which was triggered</td>
                    </tr>
                    <tr>
                        <td valign="top"><strong style="white-space:nowrap">%servername%&nbsp;</strong></td>
                        <td>The machine name of the Faxination server which is monitoring this Rule</td>
                    </tr>
                    <tr>
                        <td valign="top"><strong style="white-space:nowrap">%time%&nbsp;</strong></td>
                        <td>The local time on the Faxination server when this Rule was triggered</td>
                    </tr>
                    <tr>
                        <td valign="top"><strong style="white-space:nowrap">%date%&nbsp;</strong></td>
                        <td>The local date on the Faxination server when this Rule was triggered</td>
                    </tr>
                    <tr *ngIf="is_type('QueueItems')">
                        <td valign="top"><strong style="white-space:nowrap">%QueueItems.Items%&nbsp;</strong></td>
                        <td>For a QueueItems rule, the number of Items which will trigger this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('QueueItems')">
                        <td valign="top"><strong style="white-space:nowrap">%QueueItems.Detected%&nbsp;</strong></td>
                        <td>For a QueueItems rule, the actual number of items in the Faxination Queue when this Rule was triggered</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%QueueAge.Age%&nbsp;</strong></td>
                        <td>For a QueueAge rule, the job age in minutes which will trigger this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%QueueAge.Detected%&nbsp;</strong></td>
                        <td>For a QueueAge rule, the detected age of the oldest job in the Faxination Queue</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%Job.JobNr%&nbsp;</strong></td>
                        <td>If applicable, the job number of the job which triggered this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%Job.CreationTime%&nbsp;</strong></td>
                        <td>If applicable, the UTC creation time of the job which triggered this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%Job.CreationTimeLocal%&nbsp;</strong></td>
                        <td>If applicable, the local creation time of the job which triggered this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('QueueAge')">
                        <td valign="top"><strong style="white-space:nowrap">%Job.MsgType%&nbsp;</strong></td>
                        <td>If applicable, the message type of the job which triggered this Rule</td>
                    </tr>
                    <tr *ngIf="is_type('MessageCheck')">
                        <td valign="top"><strong style="white-space:nowrap">%MessageCheck.CreateTime%&nbsp;</strong></td>
                        <td>The UTC Faxination Server time when the message check was started</td>
                    </tr>
                    <tr *ngIf="is_type('MessageCheck')">
                        <td valign="top"><strong style="white-space:nowrap">%MessageCheck.Value%&nbsp;</strong></td>
                        <td>The total duration in seconds of the message check</td>
                    </tr>
                    <tr *ngIf="is_type('MessageCheck')">
                        <td valign="top"><strong style="white-space:nowrap">%MessageCheck.ResultCode%&nbsp;</strong></td>
                        <td>If nonzero, a code representing a message check failure</td>
                    </tr>
                    <tr *ngIf="is_type('MessageCheck')">
                        <td valign="top"><strong style="white-space:nowrap">%MessageCheck.ResultString%&nbsp;</strong></td>
                        <td>If not 'OK', a description of the message check failure</td>
                    </tr>
                </tbody>
            </table>

            </div>
        </div>

        <div class="modal-footer">
            <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
            <button *ngIf="isModifiable" type="button" class="btn" [disabled]="!submitEnabled()" (click)="apply()">Apply</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
