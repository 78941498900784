import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestSMTPStackConnectorId, FaxConfigRestSMTPStackServerDef } from '../api/api';

export interface IDkimServerScope {
    conn: FaxConfigRestSMTPStackConnectorId;
    editee: FaxConfigRestSMTPStackServerDef
}

@Component({
    selector: 'app-dkim-server-config',
    imports: [FormsModule, CommonModule],
    templateUrl: './dkim-server-config.component.html',
    styleUrl: './dkim-server-config.component.css'
})
export class DkimServerConfigComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isModifiable: boolean;
    conn: FaxConfigRestSMTPStackConnectorId;
    editee: FaxConfigRestSMTPStackServerDef

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IDkimServerScope
    ) {
        this.conn = data.conn;
        this.editee = data.editee;
        this.isModifiable = auth.isModifiable('Kernel Properties');
    }

    submit(): void {
        var def: FaxConfigRestSMTPStackServerDef = {
            DKIMValidation: this.editee.DKIMValidation
        };
        this.faxSrv.UpdateSMTPStackServer(this.conn.Identifier!, def).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
