import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

// Restrict an input element to accept only digits 0-9.
@Directive({
    selector: '[fen-uint-only]',
    standalone: true
})
export class FenUintOnlyDirective {
    constructor(private control: NgControl) { }

    @HostListener('input', ['$event'])
    ngModelChange(event: Event) {
        let item = event.target as HTMLInputElement;
        if (item) {
            let processedInput = item.value.replace(/\D/g,'');
            this.control.control?.setValue(processedInput, { emit: false });
        }
    }
}
