<h1>Accounts</h1>

<div>
    <ul class="nav nav-tabs" *ngIf="pageTabs.length > 1">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab=pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>
    <div [hidden]="state.activePageTab!=='Accounts'">
        <ng-container *ngTemplateOutlet="page1"></ng-container>
    </div>
    <div *ngIf="state.activePageTab==='Groups'">
        <ng-container *ngTemplateOutlet="page2"></ng-container>
    </div>
</div>

<!-- Accounts tab -->
<ng-template #page1>
    <button type="button" class="btn pull-right" *ngIf="!azureConfig?.Enforced && auth.isModifiable('All')" (click)="setup_accounts()">Configure...</button>
    <p id="accounts_help" *ngIf="auth.isModifiable('Accounts')">You can create individual accounts with pre-defined roles to control who has access
        to the Faxination Management Portal.<span *ngIf="!azureConfig?.Enforced && auth.isModifiable('All')"> You can configure a minimum password length, and
        whether accounts are locked due to multiple unsuccessful logins.</span><span *ngIf="!azureConfig?.Enforced"> If an account
        becomes locked, it is displayed in a different color.</span></p>
    <p *ngIf="!auth.isModifiable('Accounts')">You can view the accounts that have access to the Faxination Management Portal.</p>

    <div class="filterbar">
        <form role="form" (submit)="search_accounts()">
            <div class="form-group has-feedback pull-right">
                <i class="form-control-feedback glyphicon glyphicon-search" (click)="search_accounts()"></i>&ngsp;
                <input type="text" class="form-control" [(ngModel)]="formDataAccounts.searchKeyAccounts" name="searchKeyAccounts" placeholder="Search" />
            </div>
        </form>
    </div>

    <div id="list_content">
        <div class="btn-toolbar" *ngIf="isReady && pagerAccounts && auth.isModifiable('Accounts')">
            <button type="button" class="btn" (click)="new_account()" [disabled]="session.contextAllOrganizations()">New</button>
            <button type="button" class="btn" (click)="remove_account()" [disabled]="!remove_account_enabled()">Remove</button>
            <button type="button" class="btn" *ngIf="isAnyAccountLocked()" [disabled]="!unlock_account_enabled()" (click)="unlock_account()">Unlock</button>
        </div>

        <app-pagination [limit]="session.portalCfg.items_per_page" [query]="accountsQuery"></app-pagination>

        <table *ngIf="isReady && pagerAccounts" id="accounts_list" class="table table-click table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="25px" id="Checkbox"></th>
                    <th id="Enabled">Enabled</th>
                    <th id="Username">Username</th>
                    <th id="Organization" *ngIf="session.contextAllOrganizations()">Organization</th>
                    <th id="Type" *ngIf="auth.isModifiable('All')">Type</th>
                    <th id="Description">Description</th>
                    <th id="Role">Role</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of pagerAccounts.items" (click)="edit_account(account)" [class.account_locked]="isAccountLocked(account)">
                    <td><input type="checkbox" *ngIf="auth.isModifiable('Accounts')" [(ngModel)]="account.selected" (click)="$event.stopPropagation()"></td>
                    <td>{{ account.Enabled | boolean }}</td>
                    <td>{{ account.Username }}</td>
                    <td *ngIf="session.contextAllOrganizations()">{{ account.OrganizationName }}</td>
                    <td *ngIf="auth.isModifiable('All')" [ngSwitch]="account.Source">
                        <span *ngSwitchCase="'SQLDB'">Local</span>
                        <span *ngSwitchCase="'AzureAD'">Fenestrae</span>
                        <span *ngSwitchCase="'AzureAD_MultiTenant'">Work or school</span>
                        <span *ngSwitchCase="'AzureAD_Social'">Social</span>
                        <span *ngSwitchDefault>{{ account.Source }}</span>
                    </td>
                    <td>{{ account.Description }}</td>
                    <td>{{ account.Role }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="isReady && pagerAccounts?.isEmpty()">No Results</div>
    </div>
</ng-template>

<!-- Groups tab -->
<ng-template #page2>
    <p *ngIf="!auth.isModifiable('All') || !General?.LDAPEnabled">
        Active Directory groups are <span *ngIf="!General?.LDAPEnabled">not </span>enabled.
    </p>
    <p *ngIf="auth.isModifiable('All') && General?.LDAPEnabled">
        You can add one or more Active Directory groups with a pre-defined role so that all
        members of the group will have access to the Faxination Management Portal.
    </p>

    <label title="Enable or disable Active Directory groups support" *ngIf="auth.isModifiable('All')">
        <input type="checkbox" [(ngModel)]="General.LDAPEnabled" (change)="onEnableGroups()">
        Enable Active Directory groups
    </label>

    <div class="btn-toolbar" *ngIf="auth.isModifiable('All') && General.LDAPEnabled">
        <button type="button" class="btn" (click)="new_group()">New...</button>
        <button type="button" class="btn" (click)="remove_group()" [disabled]="remove_group_disabled()">Remove</button>
        <div class="btn-group pull-right">
            <button type="button" class="btn" (click)="setup_groups()">Configure...</button>
        </div>
    </div>

    <table id="groups_list" class="table table-condensed table-bordered" [class.table-click]="auth.isModifiable('All')" *ngIf="General.LDAPEnabled">
        <thead>
            <tr>
                <th width="25px"></th>
                <th>AD Group</th>
                <th>Description</th>
                <th>Role</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let group of lstGroups" (click)="edit_group(group)">
                <td><input type="checkbox" *ngIf="auth.isModifiable('All')" [(ngModel)]="group.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ group.ADGroup }}</td>
                <td>{{ group.Description }}</td>
                <td>{{ group.Role }}</td>
            </tr>
        </tbody>
    </table>
</ng-template>
