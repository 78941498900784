<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Upload Coversheet</h1>
    </div>
    <div class="modal-body" id="choose_coversheet_body">
        <p>Upload a coversheet definition (*.cs) file to the server.  Optionally, you can also upload background images that the coversheet needs, and/or a disclaimer page.</p>
        <form class="form" name="cs_file_form" #cs_file_form="ngForm" id="choose_coversheet_file" novalidate>
            <div class="field">
                <span><i class="glyphicon glyphicon-file"></i></span>
                <label>Coversheet definition file:</label>
                <input type="file" name="cs_file" accept=".cs" [(ngModel)]="cs_file" required>
            </div>
        </form>
        <form class="form" name="cover_back_form" id="choose_coversheet_back" novalidate>
            <div class="field">
                <span><i class="glyphicon glyphicon-picture"></i></span>
                <label>Cover page background:</label>
                <input type="file" name="cover_back" accept="{{ editee.coversheetAccept }}" [(ngModel)]="cover_back" required>
            </div>
        </form>
        <form class="form" name="continuous_back_form" id="choose_continuous_back" novalidate>
            <div class="field">
                <span><i class="glyphicon glyphicon-picture"></i></span>
                <label>Continuous page background:</label>
                <input type="file" name="continuous_back" accept="{{ editee.coversheetAccept }}" [(ngModel)]="continuous_back" required>
            </div>
        </form>
        <form class="form" name="disclaimer_page_form" id="choose_disclaimer_page" novalidate>
            <div class="field">
                <span><i class="glyphicon glyphicon-picture"></i></span>
                <label>Disclaimer page:</label>
                <input type="file" name="disclaimer_page" accept="{{ editee.coversheetAccept }}" [(ngModel)]="disclaimer_page" required>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button *ngIf="!isUploading" type="button" class="btn btn-primary" (click)="upload()" [disabled]="!cs_file_form.valid">Upload</button>
        <span *ngIf="isUploading">Uploading ...</span>
    </div>
</section>
