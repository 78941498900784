import { Component } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-monitoring-newtemplate',
    imports: [FormsModule],
    templateUrl: './monitoring-newtemplate.component.html',
    styleUrl: './monitoring-newtemplate.component.css'
})
export class MonitoringNewtemplateComponent {

    newtype: string = 'QueueItems';

    constructor(private dialogRef: DialogRef) {
    }

    submit(): void {
        this.dialogRef.close(this.newtype);
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
