import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelFontsDefs } from '../api/api';
import * as _ from 'underscore';

export interface ICoversheetFontEditee {
    fontItem: string;
    fontName: string;
    fontSize: number;
}

interface IFontStyle {
    'font-family': string;
    'font-size': string;
}

@Component({
    selector: 'app-coversheet-font-dialog',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-font-dialog.component.html',
    styleUrl: './coversheet-font-dialog.component.css'
})
export class CoversheetFontDialogComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);

    fontSizes: number[] = [ 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72 ];
    fontStyle: IFontStyle = { 'font-family': 'Times New Roman', 'font-size': '16px' };
    fontNames: FaxConfigRestKernelFontsDefs = [];

    editee: ICoversheetFontEditee;
    isReady: boolean = false;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetFontEditee;
        this.init();
    }

    init(): void {
        this.faxSrv.getFontsDefs().subscribe(server_fonts => {
            this.fontNames = server_fonts;
            let match = this.editee.fontName.toUpperCase();
            _.find(server_fonts, font => {
                if (font.Name?.toUpperCase() === match) {
                    this.editee.fontName = font.Name;
                    return true;
                }
                return false;
            });
            this.font_change();
            this.isReady = true;
        });
    }

    font_change(): void {
        this.fontStyle['font-family'] = this.editee.fontName;
    }

    save(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
