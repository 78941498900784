import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestSessionContext, FaxConfigRestResult, FaxConfigRestOrganizationDef } from '../api/api';
import { Router } from '@angular/router';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component'
import { DialogService } from '../dialog/dialog.service';

@Component({
    selector: 'app-organization-tools',
    imports: [CommonModule],
    templateUrl: './organization-tools.component.html',
    styleUrl: './organization-tools.component.css'
})
export class OrganizationToolsComponent {
    public session: Session = inject(Session);
    public auth : AuthService = inject(AuthService);
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public router: Router = inject(Router);
    private dialog: DialogService = inject(DialogService);

    constructor( ) {
    }

    get currentOrgId() :number {
      return this.auth.getContextOrgId();
    }

    get currentOrgName(): string {
      return this.session.currentOrgName?? '';
    }

    get currentUserOrgId() :number {
      return this.auth.getUserOrgId();
    }

    get currentUserOrgName(): string {
      return this.session.currentUserOrgName?? '';
    }

    get prevOrgId() :number|null {
      return this.session.prevOrgId;
    }

    get prevOrgName(): string {
      return this.session.prevOrgName?? '';
    }

    selectOrganization(): void {
        let def: FaxConfigRestOrganizationDef | null = null;
        if (this.currentOrgId && this.currentOrgId !== -1) {
            def = { ID: this.currentOrgId };
        }
        const dialogRef = this.dialog.open(OrganizationSelectComponent, {
            data: { editee: def, switchSessionContext: true }
        });
        dialogRef.afterClosed().subscribe((res: FaxConfigRestOrganizationDef) => {
            if (res && res.ID !== undefined) {
                // Due to switchSessionContext = true, the dialog has already invoked
                // the SessionContext API request and received a successful response.
                let currentUrl: string = this.router.url;
                this.session.setOrganizationContext(res.ID);
                this.router.navigateByUrl('/', {skipLocationChange: true, onSameUrlNavigation: 'ignore'})
                    .then(() => this.router.navigateByUrl(currentUrl, {skipLocationChange: true, replaceUrl: true, onSameUrlNavigation: 'reload'})
                );
            }
        });
    }

    setContextOrganization(orgId: number): void {
      let def: FaxConfigRestSessionContext = {
        SelectedOrganizationId: orgId
      }
      this.faxSrv.updateSessionContext(def).subscribe((res: FaxConfigRestResult) => {
        if (res.Status === "ok") {
          // Update the session context, then reload the current page
          let currentUrl: string = this.router.url;
          this.session.setOrganizationContext(orgId);
          this.router.navigateByUrl('/', {skipLocationChange: true, onSameUrlNavigation: 'ignore'})
            .then(() => this.router.navigateByUrl(currentUrl, {skipLocationChange: true, replaceUrl: true, onSameUrlNavigation: 'reload'})
          );
        }
      });
    }
}
