<h1>Users</h1>

<p *ngIf="!dataSources">
    Loading...
</p>
<p *ngIf="dataSources && dataSources.length > 1">
    Data Sources can connect to your Active Directory or user database.
    To configure how your users are stored and other advanced configuration like
    Faxination limits, use the Address Manager tool.
</p>

<div class="filterbar" *ngIf="dataSources && dataSources.length > 1">
    <!-- wait for faxSrv.dataSources -->
    <label>Data Source:</label>&nbsp;
    <select *ngIf="dataSources.length > 1" name="dataSources" [(ngModel)]="dataSource" (ngModelChange)="dataSourceChanged()">
        <option *ngFor="let system of dataSources" [ngValue]="system">{{ system.Name }}</option>
    </select>
</div>
<br />

<div [hidden]="!faxSrv.isDbProvider(dataSource?.ProviderName) || !isViewableUsers">
    <ul class="nav nav-tabs" *ngIf="isModifiableUsers && pageTabs.length > 1">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="setActivePageTab(pageTab);false">{{ pageTab }}</a>
        </li>
    </ul>
    <div [hidden]="state.activePageTab !== 'Manage'">
        <ng-container *ngTemplateOutlet="users_db_manage"></ng-container>
    </div>
    <div *ngIf="state.activePageTab === 'Import' && isModifiableUsers">
        <ng-container *ngTemplateOutlet="users_db_import"></ng-container>
    </div>
</div>

<div [hidden]="!faxSrv.isLdapProvider(dataSource?.ProviderName) || !isViewableUsers">
    <ng-container *ngTemplateOutlet="users_ad"></ng-container>
</div>

<!-- 'Manage' tab (database users) -->
<ng-template #users_db_manage>
    <p *ngIf="isModifiableUsers">
        Configure the permissions of your users and set the inbound routing code(s) per user.
    </p>
    <p *ngIf="!isModifiableUsers">
        View the permissions of your users and view the inbound routing code(s) per user.
    </p>
    <br />

    <div class="filterbar">
        <form role="form" (submit)="search()">
            <div class="form-group has-feedback" [class.pull-right]="isModifiableUsers">
                <i class="form-control-feedback glyphicon glyphicon-search" (click)="search()"></i>&ngsp;
                <input type="text" name="searchKeyUsers" class="form-control" [(ngModel)]="formDataUsers.searchKeyUsers" placeholder="Search" />
                &ngsp;
                <select name="searchFilter" [(ngModel)]="formDataUsers.searchFilter" title="Search for">
                    <option *ngFor="let filter of searchFilters" [value]="filter">{{ filter }}</option>
                </select>
            </div>
        </form>
    </div>

    <div id="list_content">
        <div *ngIf="isModifiableUsers" class="btn-toolbar">
            <button type="button" class="btn" (click)="neo()" [disabled]="session.contextAllOrganizations()">New</button>
            <button type="button" class="btn" (click)="remove()" [disabled]="!removeEnabled">Remove</button>
        </div>
        <div *ngIf="usersGridInitialized" id="usersGrid" class="ag-theme-alpine">
            <ag-grid-angular [style]="cssGridHeight" [gridOptions]="usersGridOptions"></ag-grid-angular>
        </div>
    </div>
</ng-template>

<!-- Import tab (database users) -->
<ng-template #users_db_import>
    @if (session.contextAllOrganizations()) {
        <p>
            To import users, please select an organization context.
        </p>
    } @else {
        <p>
            Bulk import users from a comma-separated values (csv) file.
        </p>
        <br />
        <div id="import_csv">
            <form class="form" name="importForm" novalidate (submit)="import()">
                <div class="field">
                    <input type="file" id="csv_file" required accept=".csv" (change)="filechange($event)">
                </div>
                <div class="buttons">
                    <button class="btn" type="submit" [disabled]="!ImportEnabled">Import</button>&ngsp;
                    <span>{{ ImportDescription }}</span>
                </div>
            </form>
        </div>
    }

    <div id="import_jobs">
        <div class="text pull-right">
            Refresh every&nbsp;
            <input type="text" name="importRefreshEvery" style="width:40px" [(ngModel)]="formDataUsers.importRefreshEvery" (ngModelChange)="import_refresh_change()" fen-uint-only>
            seconds

            <img src="gear-icon.png" class="pull-right" style="cursor:pointer;" (click)="userimport_config()" title="Preferences..." />&nbsp;
        </div>
        <br /><br />

        <app-pagination #pager_imports [limit]="session.portalCfg.items_per_page" [query]="query_imports"></app-pagination>
        <table *ngIf="pagerImportsReady" class="table table-condensed table-bordered">
            <thead>
                <tr>
                    <th>ID</th>
                    <th width="75px">Date</th>
                    <th width="55px">Time</th>
                    <th>Status</th>
                    <th *ngIf="session.contextAllOrganizations()">Organization</th>
                    <th>Description</th>
                    <th>Files</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let job of pagerImports?.items">
                    <td>{{ job.ID }}</td>
                    <td>{{ job.CreationTime | date:'yyyy-MM-dd' }}</td>
                    <td>{{ job.CreationTime | date:'HH:mm:ss' }}</td>
                    <td>{{ job.Status }}</td>
                    <td *ngIf="session.contextAllOrganizations()">{{ job.OrganizationName }}</td>
                    <td>{{ job.Description }}</td>
                    <td>
                        <span class="btn-link" *ngIf="job.HasData" (click)="showCsv(job)">csv</span>
                        &nbsp;
                        <span class="btn-link" *ngIf="job.HasLog" (click)="showLog(job)">log</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- Tree view (Active Directory users) -->
<ng-template #node_view let-node="child">
    <i [ngClass]="['glyphicon', nodeActionCssClass(node), 'tree-node-toggle']" (click)="toggleNode(node)"></i>
    <span (click)="selectNode(node)">
        <i [ngClass]="['glyphicon', nodeTypeCssClass(node)]"></i>&nbsp;
        <span [class.selected]="node === selectedNode">{{ node.name }}</span>
    </span>
    <ul class="tree tree-subtree" *ngIf="node.expanded">
        <li *ngFor="let item of node.nodes">
            <ng-container *ngTemplateOutlet="node_view; context: { child: item }"></ng-container>
        </li>
    </ul>
</ng-template>

<ng-template #users_ad>
    <div class="filterbar">

        <form role="form" (submit)="search()">
            <div class="form-group has-feedback pull-right">
                <i class="form-control-feedback glyphicon glyphicon-search" (click)="search()"></i>&ngsp;
                <input type="text" name="searchKeyUsersAD" class="form-control" [(ngModel)]="formDataUsers.searchKeyUsers" placeholder="Search" />
                &ngsp;
                <select name="searchFilterAD" [(ngModel)]="formDataUsers.searchFilter" title="Search for">
                    <option *ngFor="let filter of searchFilters" [value]="filter">{{ filter }}</option>
                </select>
            </div>
        </form>

        <input type="checkbox" name="showHiddenUsers" style="margin-left: 20px;" [(ngModel)]="formDataUsers.showHiddenUsers" (ngModelChange)="onShowHiddenUsers()">
        <span>&nbsp;Show hidden users</span>
    </div>

    <div id="tree_content" *ngIf="nodes">
        <ul id="tree" class="tree">
            <li *ngFor="let item of nodes">
                <ng-container *ngTemplateOutlet="node_view; context: { child: item }"></ng-container>
            </li>
        </ul>

        <div id="tree_users" [hidden]="!selectedNode">
            <app-pagination #pager_users [limit]="session.portalCfg.items_per_page" [query]="query_users"></app-pagination>
            <table class="table table-condensed table-bordered table-enabled-click">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of pagerUsers?.items?? []" (click)="edit(user)" [ngClass]="{ enabled: isEditable(user) }">
                        <td>{{ user.Name }}</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="loadingUsers">Loading users....</div>
            <div *ngIf="pagerUsersReady && pagerUsers.isEmpty()">No Results</div>
        </div>
    </div>
</ng-template>
