import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigRestCSImageExtensions } from '../api/api';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import * as _ from 'underscore';

export interface ICoversheetBackgroundEditee {
    name: string;
    coversheetFileExtensions: FaxConfigRestCSImageExtensions;
    coversheetAccept: string;
    isFirstPage: boolean;
}

@Component({
    selector: 'app-coversheet-background',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-background.component.html',
    styleUrl: './coversheet-background.component.css'
})
export class CoversheetBackgroundComponent {
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: ICoversheetBackgroundEditee;
    formAction: string;
    isUploading: boolean = false;
    file: string | null = null;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetBackgroundEditee;
        this.formAction = this.session.portalCfg.prefix + 'Coversheet/Definition/' + encodeURIComponent(this.editee.name) +
                        '/Background?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                        '&FirstPage=' + (this.editee.isFirstPage ? 'True' : 'False') +
                        '&contentType=text%2Fplain';
    }

    private getFileExtension(filespec: string): string {
        if (filespec) {
            let idx = filespec.lastIndexOf('.');
            if (idx >= 0) {
                return filespec.substring(idx + 1);
            }
        }
        return '';
    }

    submit(): void {
        // Check file extension
        let ext = this.getFileExtension(this.file!).toLowerCase();
        if (_.contains(this.editee.coversheetFileExtensions, ext)) {
            this.isUploading = true;
            let form = document.getElementById('choose_background') as HTMLFormElement;
            this.fenUtils.handleUpload(form, this.formAction).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.close(true);
                    } else {
                        // afterSave() has already displayed the error.
                        this.isUploading = false;
                    }
                },
                error: err => this.handleError(err)
            });
        } else {
            alert("File extension '." + ext + "' is not supported for " + (this.editee.isFirstPage ? "Cover page" : "Continuous page") + " upload.");
        }
    }

    private handleError(err?: string | null) {
        alert(err || 'An unknown error occurred. Please try again later.');
        this.isUploading = false;
    }

    close(ok: boolean): void {
        this.dialogRef.close(ok);
    }
}
