<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Configure Accounts Options</h1>
    </div>
    <form id="account_setup_form" #accountSetupForm="ngForm" class="form" (submit)="submit()">
        <div class="modal-body">
            <p>You can enforce password complexity requirements here. A 'strong password' must be at
            least {{ STRONG_PASSWORD_MINIMUM }} characters containing a mix of uppercase and lowercase
            letters, at least one digit, and at least one character that is not a letter nor digit.
            Password restrictions will only apply to newly entered passwords; previously stored
            passwords are not affected.
            </p>
            <div>
                <label>Require a minimum password length of&nbsp;
                <input type="number" style="width:50px" min="{{ editee.PasswordStrong? STRONG_PASSWORD_MINIMUM: 1 }}" max="100" name="PasswordMinimum" #passwordMinimum="ngModel" [(ngModel)]="editee.PasswordMinimum" required >
                characters
                </label>
            </div>
            <div *ngIf="!passwordMinimum.valid" class="text-danger">The minimum password length must be a number in the range {{ editee.PasswordStrong? STRONG_PASSWORD_MINIMUM: 1 }} to 100.</div>
            <br>
            <div>
                <input type="checkbox" name="PasswordStrong" [(ngModel)]="editee.PasswordStrong" (change)="onStrongPwChange()">
                <label> Enforce strong passwords</label>
            </div>
            <br>
            <div>
                <input type="checkbox" name="EnableLockout" [(ngModel)]="editee.EnableLockout">
                <label>
                Lock accounts for&nbsp;
                <input type="number" style="width:50px" min="1" max="999" name="LockoutMinutes" [(ngModel)]="editee.LockoutMinutes"
                    #lockoutMinutes="ngModel" [disabled]="!editee.EnableLockout" [required]="editee.EnableLockout">
                minutes after&nbsp;
                <input type="number" style="width:50px" min="1" max="100" name="LockoutTrigger" [(ngModel)]="editee.LockoutTrigger"
                    #lockoutTrigger="ngModel" [disabled]="!editee.EnableLockout" [required]="editee.EnableLockout">
                unsuccessful login attempts
                </label>
            </div>
            <div *ngIf="editee.EnableLockout && !lockoutMinutes.valid" class="text-danger">The lockout minutes must be a number in the range 1 to 999.</div>
            <div *ngIf="editee.EnableLockout && !lockoutTrigger.valid" class="text-danger">The lockout attempts must be a number in the range 1 to 100.</div>
            <br>
            <p><strong>Accounts Database</strong></p>
            <div class="field">
                <label>Source:</label>
                <input type="text" required style="width:400px" name="DBSource" [(ngModel)]="editee.DBSource">
            </div>
            <div class="field">
                <label>Username:</label>
                <input type="text" name="DBUsername" [(ngModel)]="editee.DBUsername">
            </div>
            <div class="field">
                <label>New Password:</label>
                <input type="password" autocomplete="off" maxlength="100" name="DBPassword" [(ngModel)]="editee.DBPassword">
            </div>
            <div class="field">
                <label>Verify Password:</label>
                <input type="password" autocomplete="off" name="DBVerifyPassword" [(ngModel)]="editee.DBVerifyPassword">&nbsp;
                <button class="btn" type="button" (click)="test()" [disabled]="!submitEnabled()"
                title="Test the database connection parameters">Test</button>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!accountSetupForm.valid || !submitEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
