<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Coversheet Time Format</h1>
    </div>
    <form id="coversheet_timeformat" class="form" novalidate (submit)="save()">
        <div class="modal-body">
            <p>Choose how the time will be displayed on the coversheet.</p>
            <div class="field">
                <label>Time Format:</label><br>
                <div class="field-indented">
                    <input type="radio" name="timeFormat" [(ngModel)]="editee.timeFormat" id="timeFormat_12"
                        (ngModelChange)="format_change()" [value]="12" [attr.value]="'12'">
                    <label for="timeFormat_12">12-hour format</label>
                </div>
                <div class="field-indented">
                    <input type="radio" name="timeFormat" [(ngModel)]="editee.timeFormat" id="timeFormat_24"
                        (ngModelChange)="format_change()" [value]="24" [attr.value]="'24'">
                    <label for="timeFormat_24">24-hour format</label>
                </div>
            </div>
            <p></p>
            <br>
            <strong>Preview:</strong>
            <p class="well">{{ timePreview }}</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
