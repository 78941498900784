<div id="coversheet_header" >
    <h1>Coversheet {{ decodedName }}</h1>
    <button type="button" class="btn btn-primary" (click)="save()" *ngIf="auth.isModifiable('Coversheets') && isReady">Save</button>
</div>

<div *ngIf="isReady">
    <ul class="nav nav-tabs">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === coversheetState.activePageTab">
            <a href="" (click)="coversheetState.activePageTab = pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>

    <!-- Lazy loading. Wait until isReady -->
    <div [hidden]="coversheetState.activePageTab !== 'Cover Page'">
        <ng-container *ngComponentOutlet="coverPage; inputs: { parent: this }"></ng-container>
    </div>
    <div [hidden]="coversheetState.activePageTab !== 'Continuous Page'">
        <ng-container *ngComponentOutlet="contPage; inputs: { parent: this }"></ng-container>
    </div>
    <div [hidden]="coversheetState.activePageTab !== 'Disclaimer Page'">
        <ng-container *ngTemplateOutlet="disclaimer_page"></ng-container>
    </div>
    <div [hidden]="coversheetState.activePageTab !== 'Settings'">
        <ng-container *ngTemplateOutlet="coversheet_settings"></ng-container>
    </div>
</div>

<ng-template #disclaimer_page>
    <div class="field-indented">
        <label>
            <input type="checkbox" name="UseDisclaimer" [(ngModel)]="coversheet!.General!.UseDisclaimer" [disabled]="!auth.isModifiable('Coversheets')">
            Add the selected TIFF file to the end of the fax.
        </label>
        <br>
        <br>
        <label>Choose a Disclaimer Page:</label><br>
        <select style="width:400px;" name="DisclaimerFilename" [(ngModel)]="coversheet!.Disclaimer!.Filename" [disabled]="!(coversheet!.General!.UseDisclaimer && auth.isModifiable('Coversheets'))">
            <option *ngFor="let f of lstDisclaimers" [value]="f.FileName">{{ f.DisplayName }}</option>
        </select>
    </div>
</ng-template>

<ng-template #coversheet_settings>
    <form id="coversheet_settings" class="form" novalidate>
        <p>Configure general settings that apply to the whole coversheet.</p>
        <br>
        <div class="field">
            <label>Message Font:</label>
            <button type="button" class="btn" (click)="setMessageFont()" *ngIf="auth.isModifiable('Coversheets')">Configure...</button>
            {{ coversheet!.General!.MessageFont }}&nbsp;({{ coversheet!.General!.MessageFontSize }})
        </div>
        <div class="field">
            <label>Field Font:</label>
            <button type="button" class="btn" (click)="setFieldFont()" *ngIf="auth.isModifiable('Coversheets')">Configure...</button>
            {{ coversheet!.General!.FieldFont }}&nbsp;({{ coversheet!.General!.FieldFontSize }})
        </div>
        <div class="field">
            <label>Date Format:</label>
            <button type="button" class="btn" (click)="setDateFormat()" *ngIf="auth.isModifiable('Coversheets')">Configure...</button>
            {{ coversheet!.General!.DateFormat }}
        </div>
        <div class="field">
            <label>Time Format:</label>
            <button type="button" class="btn" (click)="setTimeFormat()" *ngIf="auth.isModifiable('Coversheets')">Configure...</button>
            {{ coversheet!.General!.TimeFormat }}<span *ngIf="coversheet!.General!.TimeFormat">-hour format</span>
        </div>
        <div class="field" *ngIf="showDigitalSignatures()">
            <label>Digital Signature:</label>
            <button type="button" class="btn" (click)="setDigitalSignature()" *ngIf="auth.isModifiable('Coversheets')">Configure...</button>
            {{ coversheet!.General!.DigitalSignature?? '' }}
        </div>
    </form>
</ng-template>
