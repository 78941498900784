<h1>License</h1>

<p>
    See what license is active on your Faxination server.
    When you need to re-activate your existing or new contract key
    please use the Fenestrae License Manager from the Fenestrae Server Administrator.
    <span *ngIf="auth.isViewable('Service Request')">If you have questions about your license see the
    <a href="#/service_request">Service Request</a> for how to contact us.</span>
</p>

<p>
    <strong>Product:</strong> {{ license?.ProductName }}
    <strong *ngIf="license">Family:</strong> {{ license?.ProductFamily }}
    <strong *ngIf="license">Version:</strong> {{ license?.InstalledVersion }}
</p>

<p><strong>Activation key(s):</strong>
<ul>
    <li *ngFor="let activationKey of license?.ActivationKeys">{{ activationKey }}
</ul>

<table *ngIf="license" id="license_items" class="table table-condensed">
    <thead>
        <tr>
            <th>Name</th>
            <th>Value</th>
            <th>Eval. Days</th>
        </tr>
    </thead>
    <tbody *ngFor="let group of licenseGroups">
        <tr>
            <td colspan="3"><h4>{{ group.KeyType }}</h4></td>
        </tr>
        <tr *ngFor="let item of group.licenseItems" ng-click="view(item)">
            <td>{{ item.DisplayName }}</td>
            <td>{{ item.Value }} {{ item.ValueUnit }}</td>
            <td>{{ getEvalText(item) }}</td>
        </tr>
    </tbody>
</table>
