import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { FaxConfigRestResult, FenRestErrors } from '../api/api';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root'
})
export class FenUtilsService {

    constructor() {
    }

    formatErrors(errors: FenRestErrors): string {
        return errors? _.pluck(errors, 'Text').join('\n') : '';
    }

    afterSave(res: FaxConfigRestResult | FaxConfigRestResult[]): number {
        // Returns a count of successful results
        let msg: string = '';
        let count: number = 0;
        if (!_.isArray(res)) {
            res = [res];
        }
        _.each(res, (err:FaxConfigRestResult) => {
            if (err.Status === 'ok') {
                count++;
            } else {
                let fmt = this.formatErrors(err.Errors?? []);
                if (fmt) {
                    if (msg) { msg += '\n'; }
                    msg += fmt;
                }
            }
        });
        if (msg) alert(msg);
        return count;
    }

    // list.indexOf() is case-sensitive and not supported by all browsers
    listIndexOf(list: string[], value: string): number {
        let idx,
            valueLower = value.toLowerCase();
        for (idx = 0; idx < list.length; idx+=1) {
            if (list[idx].toLowerCase() === valueLower) {
                return idx;
            }
        }
        return -1;
    }

    nothingSelected(items: any[] | null | undefined): boolean {
        if (items) {
            return _.all(items, function(item) { return !item.selected; });
        }
        return false;
    }

    anySelection(items: any[] | null | undefined, prop: string, value: boolean): boolean {
        if (items) {
            let selection = _.filter(items, (item) => { return item.selected; });
            if (selection.length > 0) {
                return _.any(selection, (item) => { return item[prop] === value; });
            }
        }
        return false;
    }

    // POST a form to FaxConfigSvc.  It is assumed that the API returns a FaxConfigRestResult.
    // When the response is in the 2xx range, the Observable emits the FaxConfigRestResult,
    // otherwise it triggers an error which may contain a response string if applicable.
    handleUpload(form: HTMLFormElement, action: string): Observable<FaxConfigRestResult> {
        let subj = new AsyncSubject<FaxConfigRestResult>();
        let promise: Promise<Response>;

        try {
            promise = fetch(action, {
                method: 'POST',
                body: new FormData(form)
            });
        } catch {
            subj.error(undefined);
            subj.complete();
            return subj;
        }

        promise.then(
            (response: Response) => {
                if (response.ok) {  // status 2xx, deserialize the response
                    return response.json();
                } else {            // 500 Internal Server Error (for example)
                    throw new Error(`An error occurred (${response.status} ${response.statusText})`);
                }
            }
        ).then(
            (result: FaxConfigRestResult) => {
                if (result?.Status) {
                    subj.next(result);
                    subj.complete();
                } else {
                    throw new Error();
                }
            }
        ).catch(
            (err?: Error) => {
                subj.error(err?.message);
                subj.complete();
            }
        );

        return subj;
    }
}
