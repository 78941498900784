import { Component, Inject, inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestStringList, FaxConfigRestOSMDef } from '../api/api';
import * as _ from 'underscore';

@Component({
    selector: 'app-osm-rule',
    imports: [FormsModule, CommonModule],
    templateUrl: './osm-rule.component.html',
    styleUrl: './osm-rule.component.css'
})
export class OsmRuleComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: FaxConfigRestOSMDef;
    action: string;
    isReady: boolean = false;
    modified: boolean = false;
    isModifiable: boolean;
    messageTypes: FaxConfigRestStringList = [];
    criteriaTypes: { [key: string]: { display: string } } = this.faxSrv.outboundManagementCriteriaTypes();

    rule: FaxConfigRestOSMDef = {
        Enabled: false,
        Name: '',
        Description: '',
        MessageType: '',
        CriteriaType: '',
        CriteriaValue: '',
        RoutedService: '',
        DestinationService: ''
    };

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as FaxConfigRestOSMDef;
        this.action = (this.editee === null) ? 'New' : 'Edit';
        this.isModifiable = auth.isModifiable('Outbound Service Management');
        this.init();
    }

    init(): void {
        let promises: {
            p1: Observable<FaxConfigRestStringList>,
            p2?: Observable<FaxConfigRestOSMDef>
        } = {
            p1: this.faxSrv.messageTypes()
        };

        if (this.action === 'New') {
            this.rule.MessageType = 'FAX';
        } else {
            promises.p2 = this.faxSrv.osmDefinition(this.editee.Name?? '');
        }

        forkJoin(promises).subscribe({
            next: res => {
                this.messageTypes = res.p1;
                if (res.p2) this.rule = res.p2;
                this.isReady = true;
            },
            error: err => alert(err.message)
        });
    }

    save(): void {
        let promise: Observable<FaxConfigRestResult>;
        if (this.action === 'New') {
            promise = this.faxSrv.createOsmDefinition(this.rule);
        } else {
            promise = this.faxSrv.updateOsmDefinition(this.editee.Name?? '', this.rule);
        }
        promise.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => alert(err.message)
        });
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
