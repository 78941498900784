import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestOSRRoutingOrder } from '../api/api';
import * as _ from 'underscore';

export interface IOsrOrder {
    RouteType: string,
    DisplayName: string
}

@Component({
    selector: 'app-osr-order',
    imports: [FormsModule, CommonModule],
    templateUrl: './osr-order.component.html',
    styleUrl: './osr-order.component.css'
})
export class OsrOrderComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IOsrOrder[];
    modified: boolean = false;
    selectedRouteType: string = '';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as IOsrOrder[];
    }

    private doSave(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestOSRRoutingOrder = [];
        _.each(this.editee, item => {
            def.push( {Key: item.RouteType, Value: item.DisplayName} );
        });
        return this.faxSrv.putOsrRoutingOrder(def);
    }

    save(): void {
        this.doSave().subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => alert(err.message)
        });
    }

    moveup_disabled(): boolean {
        return (this.selectedRouteType === '' || this.editee[0].RouteType === this.selectedRouteType);
    }

    movedown_disabled(): boolean {
        return (this.selectedRouteType === '' || this.editee[this.editee.length - 1].RouteType === this.selectedRouteType);
    }

    moveup(): void {
        let idx: number;
        for (idx = 1; idx < this.editee.length; idx++) {
            if (this.editee[idx].RouteType === this.selectedRouteType) {
                let tmp: IOsrOrder = this.editee[idx - 1];
                this.editee[idx - 1] = this.editee[idx];
                this.editee[idx] = tmp;
                break;
            }
        }
    }

    movedown(): void {
        let idx: number;
        for (idx = this.editee.length - 1; idx > 0; idx--) {
            if (this.editee[idx - 1].RouteType === this.selectedRouteType) {
                let tmp: IOsrOrder = this.editee[idx - 1];
                this.editee[idx - 1] = this.editee[idx];
                this.editee[idx] = tmp;
                break;
            }
        }
    }

    is_default(): boolean {
        let idx: number;
        for (idx = 0; idx < this.editee.length; idx++) {
            let val = parseInt(this.editee[idx].RouteType, 10);
            if (val != idx) { break; }
        }
        return (idx === 4);
    }

    reset_to_default(): void {
        this.editee = _.sortBy(this.editee, 'RouteType');
    }

    selectRouteType(routetype: IOsrOrder): void {
        this.selectedRouteType = routetype.RouteType;
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
