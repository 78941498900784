import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelMonitoringRuleBase, FaxConfigRestKernelMonitoringRuleQueueAge,
    FaxConfigRestKernelMonitoringRuleQueueItems, FaxConfigRestKernelMonitoringSenderBase,
    FaxConfigRestKernelMonitoringSendersList, FaxConfigRestKernelMonitoringTemplatesList,
    FaxConfigRestStringList
} from '../api/api';
import * as _ from 'underscore';

export interface IMonitoringQueueScope {
    title: string;
    editee: FaxConfigRestKernelMonitoringRuleBase;
    senders: FaxConfigRestKernelMonitoringSendersList;
}

@Component({
    selector: 'app-monitoring-rule-queue',
    imports: [FormsModule, CommonModule],
    templateUrl: './monitoring-rule-queue.component.html',
    styleUrl: './monitoring-rule-queue.component.css'
})
export class MonitoringRuleQueueComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: FaxConfigRestKernelMonitoringRuleBase;
    isModifiable: boolean;
    title: string;
    ruleTemplates: FaxConfigRestKernelMonitoringTemplatesList = [];
    monitoringSenders: FaxConfigRestKernelMonitoringSendersList = [];
    senderChecked: { [key:string]: boolean } = {};
    Description: string = 'Click or hover over an option to view its description.';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IMonitoringQueueScope
    ) {
        this.title = data.title;
        this.editee = _.clone(data.editee);
        this.monitoringSenders = data.senders;
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
        _.each(this.monitoringSenders, sender => {
            const senders: FaxConfigRestStringList = this.editee.Senders?? [];
            this.senderChecked[sender.ID!] = senders.toString() === '*' || _.contains(senders, sender.ID);
        });
        this.faxSrv.monitoringTemplatesDefinitions().subscribe(monTemplates => {
            this.ruleTemplates = _.sortBy(monTemplates, 'Name');
        });
    }

    listCheckboxHandler(chk: boolean, sender: FaxConfigRestKernelMonitoringSenderBase): void {
        const allSenders: string[] = _.pluck<FaxConfigRestKernelMonitoringSendersList,string>(this.monitoringSenders, 'ID');
        const senders: FaxConfigRestStringList = this.editee.Senders?? [];
        if (chk) {
            if (senders.toString() !== '*') {
                this.editee.Senders = _.union(senders, [sender.ID!]);
            }
        } else {
            if (senders.toString() === '*') {
                this.editee.Senders = _.without(allSenders, sender.ID!);
            } else {
                this.editee.Senders = _.without(senders, sender.ID!);
            }
        }
        if (_.every(_.values(this.senderChecked), item => { return item; })) {
            this.editee.Senders = ['*'];
        }
    }

    submitEnabled(): boolean {
        const rule_type: string = this.editee.__type.toLowerCase();
        if (rule_type === 'queueage') {
            const rule: FaxConfigRestKernelMonitoringRuleQueueAge = this.editee;
            return (rule.Senders && rule.Senders.length > 0
                 && rule.Age && rule.Age > 0)? true: false;
        }
        if (rule_type === 'queueitems') {
            const rule: FaxConfigRestKernelMonitoringRuleQueueItems = this.editee;
            return (rule.Senders && rule.Senders.length > 0
                 && rule.Items && rule.Items > 0)? true: false;
        }
        return false;
    }

    save(): void {
        this.faxSrv.updateMonitoringRule(this.editee).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    apply(): void {
        this.faxSrv.updateMonitoringRule(this.editee).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    focusin(event: any): void {
        this.Description = event.currentTarget.title;
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
