import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, HostListener } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestLogResults, FaxConfigRestLogResubmit, FaxConfigRestResultCount } from '../api/api';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, GridOptions, GridReadyEvent, GetContextMenuItemsParams,
    MenuItemDef, GetRowIdParams, ValueFormatterParams, IServerSideDatasource, IServerSideGetRowsParams,
    ICellRendererParams, IsServerSideGroupOpenByDefaultParams, CellClickedEvent, LoadSuccessParams,
    SetFilterValuesFuncParams, RowSelectedEvent, CellDoubleClickedEvent, ISetFilter, AgPromise,
    CellContextMenuEvent, GetMainMenuItemsParams, FirstDataRenderedEvent,
    FilterModel, IContextMenuParams, RowNode
} from 'ag-grid-community';

import { DialogService } from '../dialog/dialog.service';
import { LogEntryComponent, ILogEntryScope, ILogEntryTab } from '../log-entry/log-entry.component';
import { LogFormatConfigComponent, ILogFormatConfig } from '../log-format-config/log-format-config.component';

interface IDateTimeFilter {
    Period_Field: string;
    Period_Date_Min: string;    // ex: "2024-08-01"
    Period_Date_Max: string;    // ex: "2024-08-31"
    Period_Time_Min?: string;   // ex: "09:30:00"
    Period_Time_Max?: string;   // ex: "23:59:59"
    Date_Preset: string;
}

@Component({
    selector: 'app-log',
    imports: [FormsModule, CommonModule, AgGridAngular],
    providers: [DatePipe],
    templateUrl: './log.component.html',
    styleUrl: './log.component.css'
})
export class LogComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    dateFormat: string = 'yyyy-MM-dd';
    timeFormat: string = 'HH:mm:ss';
    cssGridHeight = { 'height': '600px' };

    gridInitialized: boolean = false;
    gridOptions: GridOptions = {};
    gridApi?: GridApi;

    filter: IDateTimeFilter = {
        Period_Field: 'CreationTime',
        Period_Date_Min: '',
        Period_Date_Max: '',
        Date_Preset: ''
    };
    logOrder: string[] = ['Creation_Time_Desc'];
    searchkey: string = '';
    groupKeySuffix: number = 0; // for child row groups
    rowIdSuffix: number = 0;    // for unique row IDs

    msgTypes: string[] = [];

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private datePipe: DatePipe) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    columnDefs: ColDef<any>[] = [
        {
            headerName: 'Job Number',
            field: 'Job_Number',
            hide: true,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.JobNumber?.value?? '';
            }
        },
        {
            headerName: 'Creation Time',
            field: 'Creation_Time',
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let dt: string = params.data.creationTime || params.data.creationTimeLocal;
                return dt? this.datePipe.transform(dt, this.dateFormat + ', ' + this.timeFormat)?? '': '';
            }
        },
        {
            headerName: 'Completion Time',
            field: 'Completion_Time',
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            hide: true,
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let dt: string = params.data.completionTime || params.data.completionTimeLocal;
                return dt? this.datePipe.transform(dt, this.dateFormat + ', ' + this.timeFormat)?? '': '';
            }
        },
        {
            headerName: 'Submission Time',
            field: 'Submission_Time',
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            hide: true,
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let dt: string = params.data.submissionTime || params.data.submissionTimeLocal;
                return dt? this.datePipe.transform(dt, this.dateFormat + ', ' + this.timeFormat)?? '': '';
            }
        },
        {
            headerName: 'Received Time',
            field: 'Start_Receive_Time',
            sortable: true,
            sortingOrder: ['desc', 'asc', null],
            hide: true,
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let dt: string = params.data.startRecvTime || params.data.startRecvTimeLocal;
                return dt? this.datePipe.transform(dt, this.dateFormat + ', ' + this.timeFormat)?? '': '';
            }
        },
        {
            field: 'From',
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let val: string = params.data.fields?.From_Name?.value?? '';
                return val || (params.data.fields?.From_Address?.value?? '');
            }
        },
        {
            headerName: 'From Name',
            field: 'From_Name',
            sortable: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.From_Name?.value?? '';
            }
        },
        {
            headerName: 'From Address',
            field: 'From_Address',
            sortable: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.From_Address?.value?? '';
            }
        },
        {
            field: 'To',
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let val: string = params.data.fields?.To_Name?.value?? '';
                return val || (params.data.fields?.To_Address?.value?? '');
            }
        },
        {
            headerName: 'To Name',
            field: 'To_Name',
            sortable: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.To_Name?.value?? '';
            }
        },
        {
            headerName: 'To Address',
            field: 'To_Address',
            sortable: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.To_Address?.value?? '';
            }
        },
        {
            headerName: 'Organization',
            field: 'Organization_Name',
            hide: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.OrganizationName?.value?? '';
            }
        },
        {
            field: 'Status',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [ 'Failed', 'OK' ],
                suppressMiniFilter: true,
                suppressSorting: true,
                suppressSelectAll: true
            },
            cellRenderer: (params: ICellRendererParams) => {
                let val: string = params.data.fields?.Status?.value;
                let msg: string = params.data.fields?.Message?.value;
                if (typeof(val) === 'string') {
                    let img = document.createElement("img");
                    if (val === '0') {
                        img.src ='icon-checkmark.png';
                    } else {
                        img.src ='icon-cross.png';
                    }
                    if (msg) {
                        img.title = msg;
                    }
                    return img.outerHTML;
                }
                return '';
            }
        },
        {
            field: 'Direction',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [ 1, 2 ],
                valueFormatter: (params: ValueFormatterParams) => {
                    return (params.value === 2)? 'Outbound': 'Inbound';
                },
                suppressMiniFilter: true,
                suppressSorting: true,
                suppressSelectAll: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return (params.data.fields?.Direction?.value === 2)? 'Outbound': 'Inbound';
            }
        },
        {
            headerName: 'Type',
            field: 'Message_Type',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params: SetFilterValuesFuncParams) => { params.success(this.msgTypes); },
                suppressMiniFilter: true,
                suppressSorting: true,
                suppressSelectAll: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.fields?.MsgType?.value?? '';
            }
        }
    ];

    private forceColumn(field: string, show: boolean) {
        let col = _.find(this.columnDefs, item => { return item.field === field; });
        if (col) {
            col.suppressColumnsToolPanel = true;
            col.hide = !show;
        }
    }

    private init(): void {
        let user: string | null = this.auth.getUserName();
        if (user) {
            let val: string | null;
            user = user.toLowerCase();
            val = localStorage.getItem(user + 'LogDateFormat');
            if (val) { this.dateFormat = val; }
            val = localStorage.getItem(user + 'LogTimeFormat');
            if (val) { this.timeFormat = val; }
        }

        this.msgTypes = _.map(this.session.messageTypes!, item => {
            return (item === 'FAX')? 'Fax' : item;
        });

        // Show/hide various columns based on context.
        this.forceColumn('Organization_Name', this.session.contextAllOrganizations());

        this.gridOptions = {
            columnDefs: this.columnDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: false,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            autoGroupColumnDef: {
                headerName: '',
                flex: 1,
                sortable: false,
                resizable: false,
                lockPosition: 'left',
                minWidth: 52,
                maxWidth: 52,
                suppressHeaderMenuButton: true,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
                suppressSizeToFit: true,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                cellRenderer: (params: ICellRendererParams) => this.toggleIconRenderer(params)
            },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            cacheBlockSize: this.session.portalCfg.items_per_page * 2,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'multiple',
            treeData: true,
            suppressCsvExport: true,
            suppressExcelExport: true,
            onGridReady: (params: GridReadyEvent) => {
                this.gridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 100) + 'px' };

                this.loadViewState(params.api);
                params.api.setGridOption('serverSideDatasource', this.myDataSource);

                if (this.auth.isModifiable('Queue')) {
                    this.gridOptions.rowSelection = 'multiple';
                } else {
                    this.gridOptions.rowSelection = 'single';
                    this.gridOptions.suppressRowClickSelection = true;
                }
            },
            rowClassRules: { 'log-child-entry': 'node.level > 0' },
            getRowId: params => this.cbGetRowId(params),
            onRowSelected: params => this.cbOnRowSelected(params),
            onCellClicked: params => this.cbOnCellClicked(params),
            onCellDoubleClicked: params => this.cbOnCellDoubleClicked(params),
            getMainMenuItems: params => this.cbGetMainMenuItems(params),
            getContextMenuItems: params => this.cbGetContextMenuItems(params),
            onCellContextMenu: params => this.cbOnCellContextMenu(params),
            isServerSideGroup: data => this.cbIsServerSideGroup(data),
            isServerSideGroupOpenByDefault: params => this.cbIsServerSideGroupOpenByDefault(params),
            getServerSideGroupKey: data => this.cbGetServerSideGroupKey(data),
            onFirstDataRendered: params => this.cbOnFirstDataRendered(params),
            onGridPreDestroyed: params =>this.cbSaveViewState(params.api)
        };
        this.gridInitialized = true;
    }

    private myDataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            let filter: any;
            let successData: LoadSuccessParams;
            let count = params.request.endRow! - params.request.startRow!;

            if (params.request.groupKeys && params.request.groupKeys.length > 0) {
                // Get child entries from server using parent JobNumber, the first 8 digits of the groupKey
                filter = { ParentJobNumber: params.request.groupKeys[0].split('_', 2)[0] };
                this.faxSrv.logEntries(0, 10, filter, ['Completion_Time_Desc'], '').subscribe({
                    next: (res: FaxConfigRestLogResults) => {
                        successData = { rowData: res.Results?? [] };
                        if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                            successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                        }
                        // Log entries do not have any fields that are guaranteed to be unique,
                        // so generate an ID by combining the JobNumber plus an incremental value.
                        _.each(successData.rowData, item => {
                            if (this.rowIdSuffix >= 2147483647) this.rowIdSuffix = 0;
                            this.rowIdSuffix += 1;
                            item.ID = '' + item.fields.JobNumber.value + this.rowIdSuffix;
                        });
                        params.success(successData);
                    },
                    error: () => {
                        alert('Cannot get log entries; probably the network connection was closed.');
                        params.fail();
                    }
                });
            } else {
                // Get all parent entries from server, with filtering
                filter = { ParentsOnly: 1 };
                let model;

                model = params.request.sortModel;
                if (model && model.length > 0) {
                    this.logOrder = [];
                    _.each(model, item => {
                        this.logOrder.push(item.colId + '_' + item.sort);
                    });
                } else {
                    // No sortModel specified; use default.
                    this.logOrder = ['Creation_Time_Desc'];
                }

                if (params.request.filterModel) {
                    model = (params.request.filterModel as FilterModel)['Direction'];
                    if (model && model.values) {
                        if (model.values.length == 0) {
                            params.success({ rowData: [], rowCount: 0 });
                            return;
                        }
                        filter.Direction = model.values[0];
                    }

                    model = (params.request.filterModel as FilterModel)['Message_Type'];
                    if (model && model.values) {
                        if (model.values.length <= 0) {
                            params.success({ rowData: [], rowCount: 0 });
                            return;
                        }
                        filter.message_type = model.values;
                    }

                    model = (params.request.filterModel as FilterModel)['Status'];
                    if (model && model.values) {
                        if (model.values.length == 0) {
                            params.success({ rowData: [], rowCount: 0 });
                            return;
                        }
                        if (model.values[0] == 'Failed') {
                            filter.Status_Not = 0;
                        } else {
                            filter.Status = 0;
                        }
                    }

                    _.each(['Job_Number','From_Name','From_Address','To_Name','To_Address'], function(col) {
                        model = (params.request.filterModel as FilterModel)[col];
                        if (model && model.filterType === 'text' && model.filter) {
                            filter[col+'_'+model.type] = model.filter;
                        }
                    });
                }

                this.makeDateFilter(filter);

                if (this.searchkey) {
                    filter.Search = this.searchkey;
                }

                this.faxSrv.logEntries(params.request.startRow!, count, filter, this.logOrder, 'ColumnChildCount').subscribe({
                    next: (res: FaxConfigRestLogResults) => {
                        successData = { rowData: res.Results?? [] };
                        if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                            successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                        }
                        // Log entries do not have any fields that are guaranteed to be unique,
                        // so generate an ID by combining the JobNumber plus an incremental value.
                        _.each(successData.rowData, item => {
                            if (this.rowIdSuffix >= 2147483647) this.rowIdSuffix = 0;
                            this.rowIdSuffix += 1;
                            item.ID = '' + item.fields.JobNumber.value + this.rowIdSuffix;
                        });
                        params.success(successData);
                    },
                    error: () => {
                        alert('Cannot get log entries; probably the network connection was closed.');
                        params.fail();
                    }
                });
            }
        }
    };

    dateChange(): void {
        this.filter.Date_Preset = '';
        if (!this.filter.Period_Date_Min) {
            delete this.filter.Period_Time_Min;
        }
        if (!this.filter.Period_Date_Max) {
            delete this.filter.Period_Time_Max;
        }
    }

    datePresetChange(): void {
        let dtMin: Date;
        let dtMax: Date;
        let dateMin: string | null = null;
        let dateMax: string | null = null;

        let dtNow = new Date();

        // Make sure the preset is an inclusive range because makeDateFilter()
        // will convert it to an exclusive range when it calls the FaxConfig API.
        // Also note that (except for "Last Month") it isn't strictly necessary
        // to set the dtMax value, but we do it anyway to make the range clear.

        switch (this.filter.Date_Preset) {

        case 'Today':
            dtMin = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate());
            dtMax = new Date(dtMin);
            break;

        case 'Yesterday':
            dtMin = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate());
            dtMin.setDate(dtMin.getDate() - 1);
            dtMax = new Date(dtMin);
            break;

        case 'Last 7 days':
            dtMax = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate());
            dtMin = new Date(dtMax);
            dtMin.setDate(dtMin.getDate() - 7);
            break;

        case 'This month':
            dtMin = new Date(dtNow.getFullYear(), dtNow.getMonth());
            dtMax = new Date(dtMin);
            dtMax.setMonth(dtMax.getMonth() + 1);
            dtMax.setDate(dtMax.getDate() - 1);
            break;

        case 'Last month':
            dtMax = new Date(dtNow.getFullYear(), dtNow.getMonth());
            dtMin = new Date(dtMax);
            dtMin.setMonth(dtMin.getMonth() - 1);
            dtMax.setDate(dtMax.getDate() - 1);
            break;

        default:
            return;
        }

        dateMin = this.datePipe.transform(dtMin, 'yyyy-MM-dd');
        if (dateMin) {
            this.filter.Period_Date_Min = dateMin;
            delete this.filter.Period_Time_Min;
        }

        dateMax = this.datePipe.transform(dtMax, 'yyyy-MM-dd');
        if (dateMax) {
            this.filter.Period_Date_Max = dateMax;
            delete this.filter.Period_Time_Max;
        }
    }

    private makeDateFilter(filter: any): void {
        let time: string;
        let local: Date;
        let dateMin: string | null = null;
        let dateMax: string | null = null;

        if (this.filter.Period_Date_Min) {
            // This interprets the string as local time and converts it to UTC for the API query.
            time = this.filter.Period_Time_Min || '00:00:00';
            local = new Date(this.filter.Period_Date_Min + 'T' + time);
            dateMin = local.toISOString();
        }
        if (this.filter.Period_Date_Max) {
            time = this.filter.Period_Time_Max || '23:59:59';
            local = new Date(this.filter.Period_Date_Max + 'T' + time);
            // The query range is exclusive (dt >= Min && dt < Max), so add one second to span the whole day.
            local.setSeconds(local.getSeconds() + 1);
            dateMax = local.toISOString();
        }

        switch (this.filter.Period_Field) {
            case 'CreationTime':
                filter.Creation_Time_Min = dateMin;
                filter.Creation_Time_Max = dateMax;
                break;
            case 'SubmissionTime':
                filter.Submission_Time_Min = dateMin;
                filter.Submission_Time_Max = dateMax;
                break;
            case 'StartRecvTime':
                filter.Start_Receive_Time_Min = dateMin;
                filter.Start_Receive_Time_Max = dateMax;
                break;
        }
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 100) + 'px' };
    }

    private setDefaultView(): void {
        if (this.gridApi) {
            this.gridApi.autoSizeAllColumns(false);
            this.gridApi.applyColumnState({
                state: [{ colId: 'Creation_Time', sort: 'desc', sortIndex: 0, width: 180 }]
            });
        }
    }

    private cbSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'LogViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    private loadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'LogViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                if (val) {
                    try {
                        // Ignore the 'hide' property for columns that are forced.
                        val = _.map(val, item => {
                            let def = _.find(this.columnDefs, col => { return col.field === item.colId; });
                            if (def && def.hasOwnProperty('suppressColumnsToolPanel')) {
                                return _.omit(item, 'hide');
                            }
                            return item;
                        });
                        // Do not apply this state unless at least one column is visible!
                        if (_.find(val, col => { return col.colId !== 'ag-Grid-AutoColumn' && col.hide === false; })) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    } catch (err) {
                        // Invalid ColumnState; do not apply it.
                    }
                }
            }
        }
        this.setDefaultView();
    };

    private hasChildren(logEntry: any): boolean {
        if (logEntry && logEntry.fields && logEntry.fields.Child_Count) {
            return (logEntry.fields.Child_Count.value > 0);
        }
        return false;
    }

    private toggleIconRenderer(params: ICellRendererParams): string {
        let img: string;
        if (this.hasChildren(params.data)) {
            if (params.node.expanded) {
                img = '<img src="icon-toggle.png" alt="collapse">';
            } else {
                img = '<img src="icon-toggle-expand.png" alt="expand">';
            }
        } else {
            img = '';
        }
        return '<span style="cursor: default;">' + img + '</span>';
    }

    private cbGetRowId(params: GetRowIdParams): string {
        return params.data.ID;
    }

    private cbOnRowSelected(params: RowSelectedEvent): void {
        // Outbound jobs and child entries cannot be selected for resubmit
        if (params.node.level > 0 || params.data.fields.Direction.value === 2) {
            params.node.setSelected(false);
        }
    }

    private cbOnCellClicked(params: CellClickedEvent): void {
        if (params.column.getColId() === 'ag-Grid-AutoColumn') {
            if (params.data && this.hasChildren(params.data)) {
                params.node.setExpanded(!params.node.expanded);
                params.api.refreshCells({ rowNodes: [params.node], force: true });
            }
        }
    }

    private cbOnCellDoubleClicked(params: CellDoubleClickedEvent): void {
        if (params.column.getColId() !== 'ag-Grid-AutoColumn' && params.data) {
            this.view(params.data);
        }
    }

    private cbGetMainMenuItems(params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
        let menuItems: (string | MenuItemDef)[];
        menuItems = _.reject(params.defaultItems, item => { return item == 'resetColumns'; });
        if (menuItems.length > 0 && menuItems[menuItems.length - 1] !== 'separator') {
            menuItems.push('separator');
        }
        menuItems.push({
            name: 'Reset Columns',
            action: () => { this.setDefaultView(); }
        });
        menuItems.push('separator');
        menuItems.push({
            name: 'Predefined Views',
            subMenu: [
                {
                    name: 'All jobs',
                    action: () => this.setPredefinedView('All jobs')
                },
                {
                    name: 'All inbound faxes',
                    action: () => this.setPredefinedView('All inbound faxes')
                },
                {
                    name: 'Failed outbound faxes',
                    action: () => this.setPredefinedView('Failed outbound faxes')
                },
                {
                    name: 'Successful outbound faxes',
                    action: () => this.setPredefinedView('Successful outbound faxes')
                }
            ]
        });
        return menuItems;
    }

    private setPredefinedView(view: string): void {
        if (this.gridApi && view) {

            let modelDirection: { values: string[] } | null = null;
            let modelMsgType: { values: string[] } | null = null;
            let modelStatus: { values: string[] } | null = null;

            switch (view) {

            case 'All jobs':
                modelDirection = null;
                modelMsgType = null;
                modelStatus = null;
                break;

            case 'All inbound faxes':
                modelDirection = { values: ['1'] };
                modelMsgType = { values: ['Fax'] };
                modelStatus = null;
                break;

            case 'Failed outbound faxes':
                modelDirection = { values: ['2'] };
                modelMsgType = { values: ['Fax'] };
                modelStatus = { values: ['Failed'] };
                break;

            case 'Successful outbound faxes':
                modelDirection = { values: ['2'] };
                modelMsgType = { values: ['Fax'] };
                modelStatus = { values: ['OK'] };
                break;

            default:
                return;
            }

            let promises: {
                fiDirection: Promise<ISetFilter | null | undefined>,
                fiMsgType: Promise<ISetFilter | null | undefined>,
                fiStatus: Promise<ISetFilter | null | undefined>
            } = {
                fiDirection: this.gridApi.getColumnFilterInstance('Direction'),
                fiMsgType: this.gridApi.getColumnFilterInstance('Message_Type'),
                fiStatus: this.gridApi.getColumnFilterInstance('Status')
            }

            forkJoin(promises).subscribe(res => {

                let agPromises: AgPromise<void>[] = [];
                this.filter.Period_Field = 'CreationTime';

                if (res.fiDirection) {
                    agPromises.push(res.fiDirection.setModel(modelDirection).then(() => {
                        res.fiDirection!.applyModel();
                    }));
                }
                if (res.fiMsgType) {
                    agPromises.push(res.fiMsgType.setModel(modelMsgType).then(() => {
                        res.fiMsgType!.applyModel();
                    }));
                }
                if (res.fiStatus) {
                    agPromises.push(res.fiStatus.setModel(modelStatus).then(() => {
                        res.fiStatus!.applyModel();
                    }));
                }

                AgPromise.all(agPromises).then(() => {
                    this.gridApi!.onFilterChanged();
                });
            });
        }
    };

    private cbGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let menuItems: (string | MenuItemDef)[] = [];
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        if (params.node && params.node.data) {
            menuItems.push({
                name: 'View...',
                action: () => this.view(params.node!.data)
            });
            if (this.resubmit_enabled()) {
                menuItems.push({
                    name: 'Resubmit...',
                    action: () => this.resubmit_ask()
                });
            }
        }
        return menuItems;
    }

    private cbOnCellContextMenu(params: CellContextMenuEvent): void {
        // Determine whether to extend the context menu with a 'Resubmit' option.
        if (this.auth.isModifiable('Queue')) {
            let promises: Observable<boolean>[] = [];

            params.api.forEachNode(node => {
                if (node.isSelected() && node.data && !node.data.hasOwnProperty('backupFileExists') && node.data.fields.Direction.value === 1) {
                    let jobnr = (node.data.fields.LogJobNumber.value) ? node.data.fields.LogJobNumber.value : node.data.fields.JobNumber.value;
                    promises.push(
                        this.faxSrv.logBackupFileExists(jobnr).pipe(map(res => {
                            node.data.backupFileExists = res;
                            return res;
                        }))
                    );
                }
            });

            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    if (_.all(res, item => { return item === true; })) {
                        // All selected entries are resubmittable, so re-launch the context
                        // menu, hopefully at the same (x,y) location.  This will re-trigger
                        // the cbGetContextMenuItems callback, and the callback will take
                        // care of adding a 'Resubmit' option to the context menu.
                        let icmp: IContextMenuParams = {
                            rowNode: params.node as RowNode,
                            column: params.column,
                            value: params.value
                        };
                        let ev: any = params.event as any;
                        if (ev && ev.x !== undefined && ev.y !== undefined) {
                            icmp.x = ev.x;
                            icmp.y = ev.y;
                        }
                        params.api.showContextMenu(icmp);
                    }
                });
            }
        }
    }

    filters_reset(): void {
        this.gridApi?.onFilterChanged();
    }

    private cbIsServerSideGroup(data: any): boolean {
        return this.hasChildren(data);
    }

    private cbIsServerSideGroupOpenByDefault(params: IsServerSideGroupOpenByDefaultParams): boolean {
        return false;
    }

    private cbGetServerSideGroupKey(data: any): string {
        if (!data.hasOwnProperty('groupKey')) {
            // Construct group key from parent JobNumber plus an incremental suffix '1' to '7FFFFFFF'.
            if (this.groupKeySuffix >= 2147483647) this.groupKeySuffix = 0;
            this.groupKeySuffix += 1;
            data.groupKey = data.fields.JobNumber.value + '_' + this.groupKeySuffix.toString(16);
        }
        return data.groupKey;
    }

    private cbOnFirstDataRendered(params: FirstDataRenderedEvent): void {
        this.isReady = true;
    }

    private resubmit_enabled(): boolean {
        let selected: boolean = false;  // at least one parent entry is selected
        let enabled: boolean = true;    // all selected entries are resubmittable
        if (this.gridApi && this.auth.isModifiable('Queue')) {
            this.gridApi.forEachNode(node => {
                if (node.level <= 0 && node.isSelected() && node.data) {
                    selected = true;
                    enabled = enabled && this.isResubmitable(node.data);
                }
            });
        }
        return (selected && enabled);
    }

    private isResubmitable(logEntry: any): boolean {
        let enabled: boolean = false;
        if (logEntry && logEntry.fields) {
            if (logEntry.fields.Direction.value !== 1 ||
                !logEntry.fields.BackupFile.value ||
                logEntry.fields.backupFileExists === false)
            {
                enabled = false;
            }
            else {
                enabled = logEntry.backupFileExists? true : false;
            }
        }
        return enabled;
    }

    private itemsSelection(): any[] {
        let items: any[] = [];
        if (this.gridApi) {
            this.gridApi.forEachNode(node => {
                if (node.level <= 0 && node.isSelected() && this.isResubmitable(node.data)) {
                    items.push(node.data);
                }
            });
        }
        return items;
    }

    private resubmit_ask(): void {
        let items = this.itemsSelection();
        if (!items || items.length == 0) {
            return;
        }

        // Compose a cloned log entry to display info about all selected items.
        // Properties that are the same for all selected items will show their value, otherwise 'various values' is displayed.
        let logEntry: {
            fields: { [key: string]: { value: string } }
        } = {
            fields: { }
        }
        _.each(items, item => {
            for (let field_key in item.fields as string[]) {
                if (typeof(logEntry.fields[field_key]) === 'undefined') {
                    logEntry.fields[field_key] = _.clone(item['fields'][field_key]);
                }
                else if (logEntry.fields[field_key].value !== item.fields[field_key].value) {
                    logEntry.fields[field_key].value = 'various values';
                }
            }
        });

        // This is a subset of the fields normally displayed for a log entry. Includes only fields used in the re-submit.
        let tabs: ILogEntryTab[] = [
            { 'title' : 'General',          'fields' : ['StartRecvTime','Message','RoutingCode','Serviceroutingid'] },
            { 'title' : 'From',             'fields' : ['From_Address','From_AddrType','From_Company','From_Department','From_Location','From_Name'] },
            { 'title' : 'To',               'fields' : ['To_Address','To_AddrType'] },
            { 'title' : 'Message',          'fields' : ['MsgType','Priority','Description'] },
            { 'title' : 'Transmission',     'fields' : ['CSID','OurCSID','LineId','ModemID','Duration','Baudrate','Cost','ItemsRecv','TotPages'] },
            { 'title' : 'Re-route',         'fields' : [], 'custom' : true }
        ];
        if (this.session.contextAllOrganizations()) {
            tabs[0].fields!.unshift('Organization_Name');
            tabs[0].fields!.unshift('OrganizationName');
        }

        let params: ILogEntryScope = {
            title: 'Resubmit ' + ((items.length > 1) ? 'Multiple Jobs' : ('Job ' + items[0].fields.JobNumber.value)),
            showSubTitle: false,
            textSubmit: 'Resubmit',
            textCancel: 'Cancel',
            logEntry: logEntry,
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat,
            selectedTab: 0,
            selectedTabTitle: tabs[0].title,
            tabs: tabs,
            getRoutingCodes: true
        };

        const dialogRef = this.dialog.open(LogEntryComponent, {
            data: { scope: params }
        });
        dialogRef.afterClosed().subscribe(res => {
            this.resubmit(res);
        });
    }

    private resubmit(def: FaxConfigRestLogResubmit): void {
        if (def) {
            let promises: Observable<FaxConfigRestResultCount>[] = [];
            let items = this.itemsSelection();

            let jobnumbers = _.uniq(_.compact(_.map(items, entry => {
                if (entry.fields.hasOwnProperty('LogJobNumber')) {
                    return entry.fields.LogJobNumber.value;
                }
                else if (entry.fields.hasOwnProperty('JobNumber')) {
                    return entry.fields.JobNumber.value;
                }
                else {
                    return null;
                }
            })));

            _.map(jobnumbers, jobnr => {
                promises.push(this.faxSrv.logResubmit(jobnr, def));
            });

            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    this.fenUtils.afterSave(res);
                });
            }
        }
    }

    logag_format_config(): void {
        let editee: ILogFormatConfig = {
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat
        };
        const dialogRef = this.dialog.open(LogFormatConfigComponent, {data: { editee: editee }});
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.dateFormat = res.dateFormat;
                this.timeFormat = res.timeFormat;
                let user: string | null = this.auth.getUserName();
                if (user) {
                    user = user.toLowerCase();
                    localStorage.setItem(user + 'LogDateFormat', this.dateFormat);
                    localStorage.setItem(user + 'LogTimeFormat', this.timeFormat);
                }
                this.gridApi?.onFilterChanged();
            }
        });
    }

    private exportEnabled(): boolean {
        return this.gridApi? (this.gridApi.getDisplayedRowCount() > 0): false;
    }

    logag_exportToCsv(): void {
        let time: string;
        let dateMin: Date | null = null;
        let dateMax: Date | null = null;

        if (this.filter.Period_Date_Min) {
            // This interprets the string as local time and converts it to UTC for the API query.
            time = this.filter.Period_Time_Min || '00:00:00';
            dateMin = new Date(this.filter.Period_Date_Min + 'T' + time);
        }
        if (this.filter.Period_Date_Max) {
            time = this.filter.Period_Time_Max || '23:59:59';
            dateMax = new Date(this.filter.Period_Date_Max + 'T' + time);
            // The query range is exclusive (dt >= Min && dt < Max), so add one second to span the whole day.
            dateMax.setSeconds(dateMax.getSeconds() + 1);
        }

        let url = this.session.portalCfg.prefix + 'log/export?Token=' + encodeURIComponent(this.auth.getAuthToken()!);
        if (dateMin) {
            url += '&StartDate=' + encodeURIComponent(dateMin.toISOString());
        }
        if (dateMax) {
            url += '&EndDate=' + encodeURIComponent(dateMax.toISOString());
        }
        window.open(url, 'LogExport');
    }

    private view(logEntry: any): void {
        if (!logEntry) return;

        let tabs: ILogEntryTab[] = [
            { 'title' : 'General',      'fields' : ['JobNumber','LogJobNumber','StartRecvTime','CreationTime','SubmissionTime','CompletionTime','LogType','Message','Status','Address','SystemAddress','ChargeCode','RoutingCode','Serviceroutingid','ServerID','Key'] },
            { 'title' : 'From',         'fields' : ['From_Address','From_AddrType','From_Company','From_Department','From_Location','From_Name'] },
            { 'title' : 'To',           'fields' : ['To_Address','To_AddrTyp','To_Company','To_Department','To_Name'] },
            { 'title' : 'OBO',          'fields' : ['OBO_Address','OBO_AddrType','OBO_Company','OBO_Department','OBO_Location','OBO_Name'] },
            { 'title' : 'Message',      'fields' : ['MessageID','MsgType','Priority','Description','Subject','Summary_text','Attachments','Template','PagesOCR','BackupFile','FileNameFriendly','FileContent','FileContentOCR','FileContentPDF','FileContentPDFOCR','FileContentTiffOCR'] },
            { 'title' : 'Transmission', 'fields' : ['Direction','CSID','OurCSID','DeviceID','LineId','ModemID','Attempts','Duration','Baudrate','Cost','ItemsRecv','ItemsSent','TotPages'] }
        ];

        if (this.session.contextAllOrganizations()) {
            tabs[0].fields.unshift('Organization_Name');
            tabs[0].fields.unshift('OrganizationName');
        }

        let params: ILogEntryScope = {
            title: 'Log Entry ' + logEntry.fields.JobNumber.value,
            showSubTitle: true,
            textSubmit: null,
            textCancel: 'Close',
            logEntry: logEntry,
            dateFormat: this.dateFormat,
            timeFormat: this.timeFormat,
            selectedTab: 0,
            selectedTabTitle: tabs[0].title,
            tabs: tabs,
            getRoutingCodes: false
        };

        const dialogRef = this.dialog.open(LogEntryComponent, {
            data: { scope: params }
        });
        dialogRef.afterClosed().subscribe();
    }
}
