import { Component, Inject, inject, ViewChild } from '@angular/core';
import { Observable, AsyncSubject, forkJoin, of, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { BooleanPipe } from '../utils/boolean.pipe';
import { IPaginationQuery } from '../pagination/pagination.component';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestConnectionInfoList, FaxConfigRestConnectionInfo } from '../api/api';
import * as _ from 'underscore';

interface IInEdit {
    ID: number,
    Enabled: boolean,
    OrganizationId: number | null,
    ServiceName: string,
    ServiceNameInbound: string,
    ConnectionName: string
}

@Component({
    selector: 'app-addresses-setup',
    imports: [FormsModule, CommonModule, PaginationModule, BooleanPipe],
    templateUrl: './addresses-setup.component.html',
    styleUrl: './addresses-setup.component.css'
})
export class AddressesSetupComponent implements IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    pagerProviders!: PaginationComponent;
    public query: IPaginationQuery = this;
    pagerReady: boolean = false;

    isReady: boolean = false;        
    inEdit: IInEdit | null = null;
    modified: boolean = false;
    lstProviders: FaxConfigRestConnectionInfoList = [];
    connections: FaxConfigRestConnectionInfoList | null = null;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        session.rootPromises.subscribe(res => { this.init(); });
    }

    init(): void {
        this.refresh_connections().subscribe(res => {
            this.isReady = true;
        });
    }

    refresh_connections(): Observable<boolean> {
        let subj = new AsyncSubject<boolean>;
        this.faxSrv.GetConnections({}).subscribe({
            next: res => {
                this.inEdit = null;
                this.connections = _.isArray(res)? res: [];
                let lst = this.connections.filter( item => { return item.ID !== -1 } );    // remove the No Provider
                this.lstProviders = _.sortBy(lst, item => { return item.ConnectionName?.toLowerCase(); } );
                this.pagerProviders?.refresh();
                subj.next(_.isArray(res));
                subj.complete();
            },
            error: res => {
                this.fenUtils.afterSave(res);
                subj.next(false);
                subj.complete();
            }
        });
        return subj;
    }
    
    invoke(offset: number, limit: number) {
        return of(this.lstProviders.slice(offset, offset+limit)).pipe(map(res => {
            this.pagerReady = true;
            return res;     // return array as observable
        }));
    }

    provider_add(): void {
        if (this.inEdit)
        {
            alert("Please apply or revert changes first.");
            return;
        }
        this.inEdit = {
            ID: -1,
            Enabled: true,
            OrganizationId: !this.session.contextAllOrganizations()? this.session.currentOrgId: null,
            ServiceName: '',
            ServiceNameInbound: '',
            ConnectionName: ''
        };
    }

    provider_edit(item: FaxConfigRestConnectionInfo) {
        if (this.inEdit) {
            if (this.inEdit.ID == item.ID) return;
            alert("Please apply or revert changes first.");
            return;
        }
        this.inEdit = {
            ID: item.ID!,
            Enabled: item.Enabled?? false,
            OrganizationId: item.OrganizationId?? null,
            ServiceName: item.ServiceName?? '',
            ServiceNameInbound: item.ServiceNameInbound?? '',
            ConnectionName: item.ConnectionName?? ''
        };
    }

    provider_cancel_edit(): void {
        this.inEdit = null;
        this.pagerProviders.refresh();
    }

    provider_del(): void {
        if (this.pagerProviders && confirm("Are you sure you want to delete the selected connections?")) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.pagerProviders.items, (item: FaxConfigRestConnectionInfo) => {
                if (item.selected && !item.Used && item.ID !== -1) {
                    promises.push(this.faxSrv.DeleteConnection(item));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: (res) => {
                        this.modified = true;
                        this.fenUtils.afterSave(res)
                        this.refresh_connections().subscribe();
                    },
                    error: (err) => alert(err.message)
                });
            }
        }
    }

    provider_del_disabled(): boolean {
        if (this.pagerProviders) {
            let nSel: number     = 0;
            let nSelUsed: number = 0;
            _.each(this.pagerProviders.items, (item: FaxConfigRestConnectionInfo) => {
                if (item.selected) nSel++;
                if (item.selected && item.Used) nSelUsed++;
            });
            return (nSel === 0 || nSelUsed > 0);
        }
        return true;
    }

    toggle_private_connection(item: FaxConfigRestConnectionInfo, isChecked: any) {
        item.OrganizationId = (isChecked.currentTarget.checked)? this.session.currentOrgId: null;
    }

    isEditing(item: FaxConfigRestConnectionInfo): boolean {
        return (this.inEdit !== null && item.ID == this.inEdit.ID);
    }

    isProvidersInvalid(item: IInEdit): boolean {
        return (item.ServiceName == '' || item.ConnectionName == '');
    }

    doSave(item: IInEdit): Observable<FaxConfigRestResult> {
        let def = {
            ID: item.ID,
            ConnectionName: item.ConnectionName,
            ServiceName: item.ServiceName,
            ServiceNameInbound: item.ServiceNameInbound,
            OrganizationId: item.OrganizationId,
            Enabled: item.Enabled
        };
        if (item.ID === -1) {
            return this.faxSrv.PostConnection(def);
        } else {
            return this.faxSrv.PutConnection(def);
        }

    }

    submit(): void {
        if (!this.isReady || this.inEdit === null) {
            return;
        }
        if (this.isProvidersInvalid(this.inEdit)) { 
            alert("Missing Service Name or display-name.");
            return; 
        }
        this.doSave(this.inEdit).subscribe({
            next: res => this.afterSubmit(res),
            error: res => this.afterSubmit(res)
        });
    }

    private afterSubmit(res: FaxConfigRestResult | string) {
        if (_.isString(res)) {
            alert(res?? 'An unexpected error occurred.');
        } else {
            if (res.Status === 'ok') {
                this.modified = true;
                this.refresh_connections().subscribe();
            } else {
                this.fenUtils.afterSave(res);
            }
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
