import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { ComboboxComponent } from '../combobox/combobox.component';

export interface ICoversheetDateformatEditee {
    dateFormat: string;
}

@Component({
    selector: 'app-coversheet-dateformat',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    providers: [DatePipe],
    templateUrl: './coversheet-dateformat.component.html',
    styleUrl: './coversheet-dateformat.component.css'
})
export class CoversheetDateformatComponent {

    dateFormats: string[] = [
        'M/D/YY',
        'M/D/YYYY',
        'M/DD/YY',
        'M/DD/YYYY',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'D-M-YY',
        'D-M-YYYY',
        'D-MM-YY',
        'D-MM-YYYY',
        'DD-MM-YY',
        'DD-MM-YYYY'
    ];

    currentDateTime: Date = new Date(new Date().getFullYear(),0,31,0,0,0,0);
    datePreview: string = '';
    editee: ICoversheetDateformatEditee;

    constructor(
        private dialogRef: DialogRef,
        private datePipe: DatePipe,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetDateformatEditee;
        this.format_change();
    }

    format_change(): void {
        this.datePreview = this.getPreview();
    }

    save(): void {
        this.dialogRef.close(true);
    }

    private getPreview(): string {
        let localBuf = this.datePipe.transform(this.currentDateTime, 'MM/dd/YY')!.split('');
        let szDateFormat = this.editee.dateFormat.toUpperCase().split('');
        let sDate = '';

        for (let x = 0; x < szDateFormat.length; x+=1)
        {
            if( x > 9) {
                break;
            }
            switch( szDateFormat[x]) {
                case 'M':
                    // decide if 09 or 9 as examp.
                    if( localBuf[0] !== '0' ) {
                        sDate += localBuf[0];
                        if( szDateFormat[x+1] === szDateFormat[x] ) {
                            x+=1;
                        }
                    }
                    else if( szDateFormat[x+1] === szDateFormat[x] ) {
                        sDate += localBuf[0];
                        x+=1;
                    }
                    sDate += localBuf[1];
                    break;
                case 'D':
                    // decide if 09 or 9 as examp.
                    if( szDateFormat[x+1] === szDateFormat[x] ) {
                        sDate += localBuf[3];
                        x+=1;
                    }
                    else if( localBuf[3] !== '0' ) {
                        sDate += localBuf[3];
                    }
                    sDate += localBuf[4];
                    break;
                case 'Y':
                    if( szDateFormat[x+2] === szDateFormat[x]) {
                        if( localBuf[6] < '9') {
                            sDate += '2';
                            sDate += '0';
                        }
                        else {
                            sDate += '1';
                            sDate += '9';
                        }
                        x+=2;
                    }
                    sDate += localBuf[6];
                    sDate += localBuf[7];
                    x+=1;
                    break;
                case '-':
                case '/':
                case '.':
                    sDate += szDateFormat[x];
                    break;
            }
        }
        return sDate;
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
