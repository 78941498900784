import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { FaxConfigRestCSDefinition } from '../api/api';
import { IFieldDef } from '../coversheet/coversheet.component';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import * as _ from 'underscore';

export interface ICoversheetFieldEditee {
    Def: IFieldDef;
    Verb: string;
    Field: string;
    Prefix: string;
    Lines: number;
    Format: string;
}

export interface ICoversheetFieldData {
    editee: ICoversheetFieldEditee;
    activeFieldDefs: IFieldDef[];
    coversheet: FaxConfigRestCSDefinition;
}

interface IPropertyField {
    DisplayName: string;
    Value: string;
    Example: string;
}

interface IFieldState {
    fieldDefs: IFieldDef[];
    fieldDefSelec: IFieldDef;
    propFieldSelec: string;
    activePageTab: string;
    Format: string;
    More: string;
}

@Component({
    selector: 'app-coversheet-field',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-field.component.html',
    styleUrl: './coversheet-field.component.css'
})
export class CoversheetFieldComponent {

    editee: ICoversheetFieldEditee;
    isReady: boolean = false;

    pageTabs: string[] = ['General'];

    propertyFields: IPropertyField[] = [
        { DisplayName: '% character', Value: '%%',           Example: '%' },
        { DisplayName: 'Address',     Value: '%Address%',    Example: '221B Baker Street' },
        { DisplayName: 'Assistant',   Value: '%Assistant%',  Example: 'Jane Doe' },
        { DisplayName: 'City',        Value: '%City%',       Example: 'The Hague' },
        { DisplayName: 'Company',     Value: '%Company%',    Example: 'Contoso' },
        { DisplayName: 'Country',     Value: '%Country%',    Example: 'Netherlands' },
        { DisplayName: 'Department',  Value: '%Department%', Example: 'Sales' },
        { DisplayName: 'Location',    Value: '%Location%',   Example: 'Room 438' },
        { DisplayName: 'Name',        Value: '%Name%',       Example: 'John Doe' },
        { DisplayName: 'State',       Value: '%State%',      Example: 'Zuid-Holland' },
        { DisplayName: 'Title',       Value: '%Title%',      Example: 'Drs.' },
        { DisplayName: 'Zipcode',     Value: '%Zipcode%',    Example: '2491 AJ' }
    ];

    fieldState: IFieldState;

    coversheet: FaxConfigRestCSDefinition;
    activeFieldDefs: IFieldDef[];

    Title: string = '';
    Separator: string = '';
    formatPreview: string = '';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetFieldEditee;
        this.activeFieldDefs = data.activeFieldDefs;
        this.coversheet = data.coversheet;

        this.fieldState = {
            fieldDefs: this.activeFieldDefs.concat([this.editee.Def]),
            fieldDefSelec: this.editee.Def,
            propFieldSelec: this.propertyFields[0].Value,
            activePageTab: this.pageTabs[0],
            Format: '',
            More: ''
        }

        if (this.editee.Verb === 'Add') {
            this.Title = 'Add New Field';
        }

        if (this.isListType(this.editee.Def.Field)) {
            this.pageTabs.push('Format');
            if (this.editee.Verb === 'Edit') {
                this.Title = 'Edit List Field';
            }
        } else {
            if (this.editee.Verb === 'Edit') {
                this.Title = 'Edit Coversheet Field';
            }
        }
        this.initListOpts();
        this.isReady = true;
    }

    isListType(field: string): boolean {
        return (
            field === 'IPM.CcList' ||
            field === 'IPM.OrList' ||
            field === 'IPM.ToList' );
    }

    findSeparator(): string {
        let fields = this.editee.Format.split('%', 3);
        return fields[2] || '';
    }

    initListOpts(): void {
        let idx;
        let fields = this.editee.Format.split('%M%', 2);
        this.fieldState.Format = fields[0] || '';
        this.fieldState.More = fields[1] || '';

        idx = this.fieldState.More.indexOf('%');
        if (idx >= 0 && idx == this.fieldState.More.length - 1) {
            this.fieldState.More = this.fieldState.More.substring(0, this.fieldState.More.length - 1);
        }

        this.Separator = this.findSeparator();
        this.formatPreview = this.doFillPreview();
    }

    doFillPreview(): string {
        let csList = this.fieldState.Format;
        let csResult = '';
        let pos = 0;
        let item;
        let field;
        while (pos < csList.length)
        {
            while ((pos < csList.length) && (csList[pos] !== '%')) {
                //Find the first field (starts with a '%' sign)
                csResult += csList[pos];
                pos+=1;
            }
            if (pos == csList.length) {
                break;  //End of format string, a '%' sign always!!
            }

            if (csList[pos] === '%') {
                //Find the end of the field
                field = '%';
                pos+=1;
                while ((pos < csList.length) && (csList[pos] !== '%')) {
                    //Find the first position of the field (starts with a '%' sign)
                    field += csList[pos];
                    pos+=1;
                }
                if(pos < csList.length) {
                    field += csList[pos];   //Add the '%' sign, ending the field
                    pos+=1;
                }

                item = _.findWhere(this.propertyFields, { Value: field });
                if (item) {
                    csResult += item.Example;
                } else {
                    //if IsField() -> %naampje% dan letterlijk weergeven (is dus een custom field)
                    if (field[0] === '%' && field[field.length - 1] === '%') {
                        csResult += field;
                    } else {
                        csResult += '<ERROR>';
                    }
                }
            }
        }
        return csResult;
    }

    fillPreview(): void {
        this.formatPreview = this.doFillPreview();
    }

    addPropertyField(): void {
        if (this.fieldState.propFieldSelec) {
            this.fieldState.Format += this.Separator + this.fieldState.propFieldSelec;
            this.fillPreview();
        }
    }

    fieldChange(): void {
        if (this.fieldState.fieldDefSelec) {
            this.editee.Def = this.fieldState.fieldDefSelec;
            this.editee.Field = this.fieldState.fieldDefSelec.Field;
            this.editee.Prefix = this.fieldState.fieldDefSelec.DefaultPrefix;
            if (this.isListType(this.editee.Field)) {
                this.pageTabs.splice(1, 1, 'Format');
                if (!this.fieldState.Format && !this.fieldState.More)
                {
                    if (this.editee.Field === 'IPM.CcList') {
                        this.editee.Format = this.coversheet.General?.CCListFormat?? '';
                    } else if (this.editee.Field === 'IPM.OrList') {
                        this.editee.Format = this.coversheet.General?.ORListFormat?? '';
                    } else if (this.editee.Field === 'IPM.ToList') {
                        this.editee.Format = this.coversheet.General?.TOListFormat?? '';
                    }
                    this.initListOpts();
                }
            } else {
                this.pageTabs.splice(1, 1);
            }
        }
    }

    submitEnabled(): boolean {
        if (this.isListType(this.editee.Field)) {
            return (this.editee.Lines >= 1 && this.editee.Lines <= 30);
        }
        return true;
    }

    save(): void {
        if (this.fieldState.More) {
            this.editee.Format = this.fieldState.Format + '%M%' + this.fieldState.More;
            if (this.fieldState.More.indexOf('%') < 0) {
                this.editee.Format += '%';
            }
        } else {
            this.editee.Format = this.fieldState.Format;
        }
        this.close(true);
    }

    close(modified: boolean): void {
        this.dialogRef.close(modified);
    }
}
