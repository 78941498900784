import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestHostChannel } from '../api/api';

export interface INewChannelScope {
    MailSystem: string;
    FriendlyName: string;
}

@Component({
    selector: 'app-conn-newchannel',
    imports: [FormsModule, CommonModule],
    templateUrl: './conn-newchannel.component.html',
    styleUrl: './conn-newchannel.component.css'
})
export class ConnNewchannelComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    scope: INewChannelScope;
    editee: FaxConfigRestHostChannel = {
        FriendlyName: '',
        SystemAddress: ''
    };

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data as INewChannelScope;
    }

    save(): void {
        this.faxSrv.AddConnHostChannel(this.scope.MailSystem, this.editee).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
