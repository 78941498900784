<section class="modal" id="coversheet_field">
    <div class="modal-header">
        <button type="button" class="close" (click)="close(false)">&times;</button>
        <h1>{{ Title }}</h1>
    </div>

    <form id="coversheet_field_form" class="form" novalidate (submit)="save()" name="coversheetFieldForm">
        <div class="modal-body" *ngIf="isReady">
            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === fieldState.activePageTab">
                    <a href="" (click)="this.fieldState.activePageTab = pageTab;false">{{ pageTab }}</a>
                </li>
            </ul>

            <div [hidden]="fieldState.activePageTab !== 'General'">
                <ng-container *ngTemplateOutlet="field_general"></ng-container>
            </div>
            <div [hidden]="fieldState.activePageTab !== 'Format'">
                <ng-container *ngTemplateOutlet="field_format"></ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">OK</button>
            <button type="button" class="btn" (click)="close(false)">Cancel</button>
        </div>
    </form>
</section>

<ng-template #field_general>
    <div class="field" *ngIf="editee.Verb === 'Add'">
        <label>Field:</label>
        <select name="fieldDefSelec" [(ngModel)]="fieldState.fieldDefSelec" (ngModelChange)="fieldChange()">
            <option *ngFor="let fd of activeFieldDefs" [ngValue]="fd">{{ fd.DisplayName }}</option>
        </select>
    </div>
    <p></p>
    <div class="field">
        <label>Prefix:</label>
        <input type="text" name="Prefix" style="width: 400px;" [(ngModel)]="editee.Prefix">
    </div>
    <p></p>
    <div class="field" *ngIf="isListType(editee.Field)">
        <label>Lines:</label>
        <input type="number" name="Lines" min="1" max="30" required style="width: 60px;" [(ngModel)]="editee.Lines">
    </div>
    <p></p>
    <div class="field" *ngIf="isListType(editee.Field)">
        <label>&apos;More...&apos; text:</label>
        <input type="text" name="More" style="width: 400px;" [(ngModel)]="fieldState.More">
    </div>
    <p></p>
</ng-template>

<ng-template #field_format>
    <div class="field">
        <label>Format:</label>
        <input type="text" name="Format" style="width: 400px;" [(ngModel)]="fieldState.Format" (ngModelChange)="fillPreview()" />
    </div>
    <p></p>
    <strong>Preview:</strong>
    <p class="well">{{ formatPreview }}</p>
    <div>
        <label>Property fields:</label>&ngsp;
        <select name="propFieldSelec" [(ngModel)]="fieldState.propFieldSelec">
            <option *ngFor="let f of propertyFields" [value]="f.Value">{{ f.DisplayName }}</option>
        </select>&ngsp;
        <button type="button" class="btn" (click)="addPropertyField()">Add</button>
    </div>
</ng-template>
