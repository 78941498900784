import { Component, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-combobox',
    imports: [FormsModule],
    templateUrl: './combobox.component.html',
    styleUrl: './combobox.component.css'
})
export class ComboboxComponent {
    // TODO: Can we improve this by implementing ControlValueAccessor?
    // see: https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html

    @Input({ required: true }) model!: string | null | undefined;
    @Input({ required: true }) options!: string[];
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Output() modelChange = new EventEmitter<string>();

    expanded: boolean = false;

    constructor() {}

    toggleExpanded(event: Event): void {
        if (!this.disabled) this.setExpanded(!this.expanded);
        event.stopPropagation();
    }

    private setExpanded(expanded: boolean): void {
        this.expanded = expanded;
    }

    @HostListener('document:click')
    clickout(): void {
        // clicked something other than the list or down button
        this.setExpanded(false);
    }

    onChange(): void {
        this.modelChange.emit(this.model?? '');
    }

    onSelect(event: Event, selected: string): void {
        this.model = selected?? '';
        this.modelChange.emit(this.model);
        this.setExpanded(false);
        event.stopPropagation();
    }
}
