<h1>Outbound Service Routing</h1>

<p>
    When multiple outbound services are available,
    Outbound Service Routing allows you to configure rules that will be used to determine which
    service will be used for a particular message.
    These rules can be based on the charge code of the sender, the email address of the sender, the destination number, etc.
    You can also create a default rule that will be used when an outbound message cannot be matched to any other rule.
</p>

<div *ngIf="auth.isModifiable('Outbound Service Routing')" class="btn-toolbar">
    <button type="button" class="btn" (click)="neo()">New</button>
    <div class="btn-group">
        <button type="button" class="btn" (click)="enable()" [disabled]="!enableEnabled">Enable</button>
        <button type="button" class="btn" (click)="disable()" [disabled]="!disableEnabled">Disable</button>
    </div>
    <button type="button" class="btn" (click)="remove()" [disabled]="!removeEnabled">Remove</button>
    <button type="button" class="btn btn-link" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
    <div class="btn-group pull-right">
        <button type="button" class="btn" (click)="order()">Order</button>
    </div>
</div>

<div *ngIf="osrGridInitialized" id="osrGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="osrGridOptions"></ag-grid-angular>
</div>
