import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NavigationComponent } from './navigation/navigation.component'
import { OrganizationToolsComponent } from './organization-tools/organization-tools.component'
import { UserToolsComponent } from './user-tools/user-tools.component'
import { FormsModule } from '@angular/forms';
import * as version_info from './version';

@Component({
    selector: 'app-root',
    imports: [CommonModule, RouterOutlet, OrganizationToolsComponent, UserToolsComponent, NavigationComponent, FormsModule],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
  version_info = version_info;
  title = 'FaxAdmin';

  constructor( public auth: AuthService ) {}

}
