import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule, NgForm } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import {
    FaxConfigRestResult, FaxConfigRestOrganizationDef, FaxConfigRestOrganizationSmtpDomainInfo,
    FaxConfigRestOrganizationSmtpDomainOptions, FaxConfigRestResultOrganizationSmtpDomains,
    FaxConfigRestOrganizationsViewOptions, FaxConfigRestOrganizationPdfTimeStamp,
    FaxConfigRestNumSubstCountryList, FaxConfigRestOrganizationsTimeZoneList,
    FaxConfigRestOrganizationMfaOptions
} from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert.component';
import { FilenameTemplateComponent } from '../filename-template/filename-template.component';
import { PdftimestampConfigComponent } from '../pdftimestamp-config/pdftimestamp-config.component';
import { DialogDatetimeFormatComponent, IDateTimeFormatEditee, IDateTimeFormatScope } from '../dialog-datetime-format/dialog-datetime-format.component';
import { FenUintOnlyDirective } from '../fen-uint-only.directive';
import { PermissionSet } from '../constants/app.constants';
import * as _ from 'underscore';

interface IOrganization extends FaxConfigRestOrganizationDef {
    AutoAddNewUsers?: boolean;
}

interface ISmtpDomainInfo extends FaxConfigRestOrganizationSmtpDomainInfo {
    autoAdd?: { key: number, value: string },
    selected?: boolean;
    Error?: string;
}

@Component({
    selector: 'app-org-settings',
    imports: [FormsModule, CommonModule, FilenameTemplateComponent, FenUintOnlyDirective],
    templateUrl: './org-settings.component.html',
    styleUrl: './org-settings.component.css'
})
export class OrgSettingsComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    isOrgPropsViewable: boolean = false;
    isOrgPropsModifiable: boolean = false;
    isOrganizationsModifiable: boolean = false;

    pageTabs: string[] = [];
    state = { activePageTab: '' };

    SmtpDomains: ISmtpDomainInfo[] = [];
    SmtpDomainsNew: ISmtpDomainInfo[] = [];
    autoAddOptions = [
        { key: 0, value: "<Default>" },
        { key: 1, value: "Enabled"   },
        { key: 2, value: "Disabled"  }
    ];

    attachmentOptions = [
        { Flags: -1, Description: "<Default>" },
        { Flags: 0, Description: "Do not attach the original fax to any reports" },
        { Flags: 1, Description: "Attach the original fax to Delivery reports" },
        { Flags: 2, Description: "Attach the original fax to Non-Delivery reports" },
        { Flags: 3, Description: "Attach the original fax to Delivery and Non-Delivery reports" }
    ];
    attachmentOption = this.attachmentOptions[0];

    MFA_CODE_LENGTH_MINIMUM: number = 4;    // digits
    MFA_CODE_LENGTH_MAXIMUM: number = 10;   // digits
    MFA_CODE_LIFETIME_MINIMUM: number = 1;  // minutes
    MFA_CODE_LIFETIME_MAXIMUM: number = 15; // minutes

    MFA = {
        Enforced: false,
        CodeLength: 6,
        CodeLifetime: 5
    };

    Organization: IOrganization = { };
    OrganizationViewOptions: FaxConfigRestOrganizationsViewOptions = { };
    numSubstCountries: FaxConfigRestNumSubstCountryList | null = null;
    TimeZones: FaxConfigRestOrganizationsTimeZoneList | null = null;

    BackupAge: string = '';
    BackupAgeInfinite: boolean = true;
    BackupEnabled: boolean = false;

    // These defaults are for single-tenant mode:
    BackupAgeMax: number = 0;   // infinite
    BackupAllowed: boolean = true;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    init(): void {
        this.isOrgPropsViewable = this.auth.isViewable(PermissionSet.OrganizationProperties);
        this.isOrgPropsModifiable = this.auth.isModifiable(PermissionSet.OrganizationProperties);
        this.isOrganizationsModifiable = this.auth.isModifiable(PermissionSet.Organizations);

        if (this.isOrgPropsViewable) {
            this.pageTabs.push('General');
            if (this.session.isMultiTenant()) {
                this.pageTabs.push('SMTP Domains');
            }
            this.pageTabs.push('Fax');
            this.pageTabs.push('PDF Options');
            this.pageTabs.push('MFA');
            this.state.activePageTab = this.pageTabs[0];
        }
        this.refresh();
    }

    refresh(): void {
        let promises: Observable<any>[] = [];

        if (this.isOrgPropsViewable && this.session.currentOrgId !== null && this.session.currentOrgId !== -1) {
            promises.push(
                this.faxSrv.GetOrganizationDefinition(this.session.currentOrgId).pipe(map(res => {
                    this.Organization = res;
                    if (!res.Fax) this.Organization.Fax = { };
                    if (!res.General) this.Organization.General = { };
                    if (!res.Inbound) this.Organization.Inbound = { };

                    this.MFA.Enforced       = res.MFA?.Enforced?? false;
                    this.MFA.CodeLength     = res.MFA?.CodeLength?? 6;
                    this.MFA.CodeLifetime   = res.MFA?.CodeLifetime?? 5;

                    this.SmtpDomains = _.map(
                        res.SmtpDomainsInfo?? [], item => {
                            return _.extend(item, { selected: false, autoAdd: this.autoAddOptions[0] })
                        }
                    );
                    this.SmtpDomainsNew = [];
                    this.checkDomainsValid(this.SmtpDomains, this.SmtpDomainsNew);

                    // Note: BackupAgeMax can be used by a Global Admin to limit the BackupAge that can be set by an Admin.
                    // Likewise BackupAllowed can be used by a Global Admin to prevent BackupEnabled to be set by an Admin.
                    // (Currently all Logging properties are filled by the API call; they are never null/undefined.)
                    if (this.session.isMultiTenant()) {
                        this.BackupAgeMax = this.Organization.Logging?.BackupAgeMax?? 0;
                        this.BackupAllowed = this.Organization.Logging?.BackupAllowed?? false;
                    }
                    this.BackupEnabled = this.Organization.Logging?.BackupEnabled?? false;

                    let age: number | null = this.Organization.Logging?.BackupAge?? null;
                    if (age !== null) {
                        this.BackupAgeInfinite = (age === 0 && (this.BackupAgeMax === 0 || !this.session.isMultiTenant()));
                        this.BackupAge = (age !== 0) ? age.toString(10) : ((this.BackupAgeMax !== 0) ? this.BackupAgeMax.toString(10) : '');
                    }

                    this.Organization.AutoAddNewUsers = true;
                    if (res.SmtpDomainOptions) {
                        let f = _.find(res.SmtpDomainOptions, item => { return !item.SmtpDomain; });
                        if (f && f.AutoAddNewUsers !== undefined && f.AutoAddNewUsers !== null) {
                            this.Organization.AutoAddNewUsers = f.AutoAddNewUsers;
                        }
                        _.each(this.SmtpDomains, domain => {
                            let f = _.find(res.SmtpDomainOptions?? [], item => {
                                return (item.SmtpDomain && item.SmtpDomain.toUpperCase() == domain.Name?.toUpperCase())? true: false;
                            });
                            if (f && f.AutoAddNewUsers !== undefined && f.AutoAddNewUsers !== null) {
                                domain.autoAdd = f.AutoAddNewUsers? this.autoAddOptions[1]: this.autoAddOptions[2];
                            }
                        });
                    }

                    if (res.NotificationOptions) {
                        let found = _.find(res.NotificationOptions, item => {
                            return item.MailSystemName === 'SMTP'
                        });
                        if (!found) {
                            found = _.find(res.NotificationOptions, item => {
                                return item.MailSystemName === '*'
                            });
                        }
                        if (found) {
                            let idx = this.attachmentOptions.findIndex(item => {
                                return item.Flags === found!.ReturnOriginal;
                            });
                            if (idx >= 0) {
                                this.attachmentOption = this.attachmentOptions[idx];
                            }
                        }
                    }
                    return res;
                }))
            );
        }

        if (this.isOrgPropsViewable) {
            promises.push(
                this.faxSrv.GetOrganizationViewOptions().pipe(map(opts => {
                    this.OrganizationViewOptions = opts;
                    return opts;
                }))
            );
            if (!this.numSubstCountries) {
                promises.push(
                    this.faxSrv.numSubstCountries().pipe(map(countries => {
                        this.numSubstCountries = countries.slice(1);
                        return countries;
                    }))
                );
            }
            if (!this.TimeZones) {
                promises.push(
                    this.faxSrv.GetOrganizationsTimeZones().pipe(map(res => {
                        this.TimeZones = res;
                        return res;
                    }))
                );
            }
        }

        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    let idx: number = _.findIndex(this.pageTabs, item => { return item === 'Logging'; });
                    if (this.OrganizationViewOptions.ViewBackup &&
                        (!this.session.isMultiTenant() || this.BackupAllowed)) {
                        // Add Logging tab if not already present.
                        if (idx < 0) this.pageTabs.push('Logging');
                    } else {
                        // Remove Logging tab if present.
                        if (idx >= 0) this.pageTabs.splice(idx, 1);
                    }
                    this.isReady = true;
                }
            });
        } else {
            this.isReady = true;
        }
    }

    //
    // SMTP Domains tab
    //

    changeDefaultAutoAddNewUsers(): void {
        let def: FaxConfigRestOrganizationDef = {
            SmtpDomainOptions: [{ SmtpDomain: '', AutoAddNewUsers: this.Organization.AutoAddNewUsers }]
        };
        this.faxSrv.PutOrganizationValues(def).subscribe({
            next: res => this.fenUtils.afterSave(res),
            error: err => alert(err.message)
        });
    }

    changeAutoAddNewUsers(domain: ISmtpDomainInfo): void {
        if (domain.Name) {
            let def: FaxConfigRestOrganizationDef = {
                SmtpDomainOptions: [{ SmtpDomain: domain.Name }]
            };
            if (domain.autoAdd?.key === 0) {
                // omit the AutoAddNewUsers member to trigger deletion
            } else if (domain.autoAdd?.key === 1) {
                def.SmtpDomainOptions![0].AutoAddNewUsers = true;
            } else if (domain.autoAdd?.key === 2) {
                def.SmtpDomainOptions![0].AutoAddNewUsers = false;
            } else {
                return;
            }
            this.faxSrv.PutOrganizationValues(def).subscribe({
                next: res => this.fenUtils.afterSave(res),
                error: err => alert(err.message)
            });
        }
    }

    domain_status_style(item: ISmtpDomainInfo): object {
        if (item.Status === 'active') {
            return { "color": "green" };
        } else {
            return { "color": "red" };
        }
    }

    domain_add_enabled(): boolean {
        return _.all(this.SmtpDomainsNew, x => { return (x.Name != ''); })
    }

    domain_add(): void {
        _.each(this.SmtpDomains, x => { x.selected = false; });
        _.each(this.SmtpDomainsNew, x => { x.selected = false; });
        this.SmtpDomainsNew.push({ Name: '', Status: '', selected: true, autoAdd: this.autoAddOptions[0] });
    }

    domain_remove(): void {
        // Delete all selected domains plus any domain options
        let deleted: FaxConfigRestOrganizationSmtpDomainOptions[] = [];
        _.each(this.SmtpDomains, domain => {
            if (domain.selected) deleted.push({ SmtpDomain: domain.Name! });
        });
        _.each(this.SmtpDomainsNew, domain => {
            if (domain.selected) deleted.push({ SmtpDomain: domain.Name! });
        });
        if (deleted.length > 0) {
            let domains    = _.reject(this.SmtpDomains, x => { return x.selected?? false; });
            let domainsNew = _.reject(this.SmtpDomainsNew, x => { return x.selected?? false; });
            let sub = this.domain_save(domains, domainsNew);
            this.domain_save(domains, domainsNew).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.SmtpDomains = domains;
                    this.SmtpDomainsNew = domainsNew;
                    this.faxSrv.PutOrganizationValues({ SmtpDomainOptions: deleted }).subscribe();
                }
            });
        }
    }

    domain_verify(bypass: boolean): void {
        let domainsNew = _.filter(this.SmtpDomainsNew, domain => {
            return domain.Status === 'pending' || domain.Status === 'active';
        });
        let lst = this.SmtpDomains.concat(domainsNew);
        let showHelpCount = 0;
        let showHelpItem  = '';
        let promises: Observable<FaxConfigRestResultOrganizationSmtpDomains>[] = [];

        _.each(lst, item => {
            item.Error = '';
            if (item.selected && item.Name != '') {
                if (!this.isDomainValid(item.Name)) {
                    item.Status = 'invalid domain';
                } else {
                    promises.push(
                        this.faxSrv.VerifyOrganizationSmtpDomain(item.Name!, bypass).pipe(map(res => {
                            if (res.Status === 'ok') {
                                if (res.SmtpDomainsInfo && res.SmtpDomainsInfo.length > 0) {
                                    item.Status = res.SmtpDomainsInfo[0].Status;
                                    if (item.Status == 'pending') {
                                        showHelpCount++;
                                        showHelpItem = item.Name?? '';
                                    }
                                }
                            } else {
                                item.Status = 'invalid domain';
                                if (res.Errors && res.Errors.length > 0 && res.Errors[0].Text) {
                                    item.Error = res.Errors[0].Text;
                                }
                            }
                            return res;
                        }))
                    );
                }
            }
        });

        forkJoin(promises).subscribe(() => {
            if (showHelpCount > 0) {
                let str = '';
                str += (showHelpCount == 1) ? "Domain '" + showHelpItem + "' failed verification." : "Some of the selected domains failed verification.";
                str += "\n\n";
                str += "The most likely reasons for this are:\n"
                str += " 1. The verification TXT record was not added with your domain name registrar.\n";
                str += " 2. An old TXT record is still cached on the internet. Wait until the TTL expires (usually one hour) and try again.";
                this.dialog.open(DialogAlertComponent, {
                    data: { title: "Verify failed", text: str }
                });
            }
        });
    }

    domain_save(domains: ISmtpDomainInfo[], domainsNew: ISmtpDomainInfo[]): Observable<FaxConfigRestResult> {
        this.checkDomainsValid(domains, domainsNew);
        let domainsFiltered = _.filter(domainsNew, domain => {
            return domain.Status === 'pending' || domain.Status === 'active';
        });
        let def = {
            SmtpDomains: _.compact(_.pluck( domains.concat(domainsFiltered), "Name"))
        };
        return this.faxSrv.PutOrganizationValues(def);
    }

    isDomainValid(dom: string | null | undefined): boolean {
        let i: number;
        let forbid: string = " !@#$%^&*()+=|\\[]{}:;'\",<>/?";
        if (!dom) { return false; }
        if (dom.length < 3) { return false; }
        i = dom.indexOf(".");
        if (i < 1 || i >= (dom.length - 1)) { return false; }
        for (i = 0; i < forbid.length; i++) {
            if (dom.indexOf(forbid.charAt(i)) >= 0) { return false; }
        }
        return true;
    }

    checkDomainsValid(domains: ISmtpDomainInfo[], domainsNew: ISmtpDomainInfo[]): void {
        _.each(domains, item => {
            if (item.Status === 'pending' && !this.isDomainValid(item.Name)) {
                item.Status = 'invalid domain';
            }
        });
        // Mark any duplicate domains within the new list.
        let idx1, idx2;
        for (idx1 = 1; idx1 < domainsNew.length; idx1++) {
            for (idx2 = 0; idx2 < idx1; idx2++) {
                if (domainsNew[idx1].Name?.toUpperCase() === domainsNew[idx2].Name?.toUpperCase()) {
                    domainsNew[idx1].Status = 'duplicate domain';
                    break;
                }
            }
        }
        _.each(domainsNew, item => {
            if (item.Status != 'duplicate domain') {
                if (!item.Name) {
                    item.Status = '';
                } else if (!this.isDomainValid(item.Name)) {
                    item.Status = 'invalid domain';
                } else if (_.find(domains, old => { return old.Name?.toUpperCase() === item.Name?.toUpperCase(); })) {
                    item.Status = 'duplicate domain';
                } else if (!item.Status) {
                    item.Status = 'pending';
                }
            }
        });
    }

    /* PDF Options */
    
    pdfxmp_change(): void {
        let def: FaxConfigRestOrganizationDef = {
            PdfXmp: { Enabled: this.Organization.PdfXmp?.Enabled }
        };
        if (def.PdfXmp?.Enabled !== undefined) {
            this.faxSrv.PutOrganizationValues(def).subscribe({
                next: res => this.fenUtils.afterSave(res),
                error: err => alert(err.message)
            });
        }
    }

    pdftimestamp_change(): void {
        let def: FaxConfigRestOrganizationDef = {
            PdfTimeStamp: this.Organization.PdfTimeStamp
        }
        if (def.PdfTimeStamp) {
            this.faxSrv.PutOrganizationValues(def).subscribe({
                next: res => this.fenUtils.afterSave(res),
                error: err => alert(err.message)
            });
        }
    }

    pdftimestamp_config(): void {
        if (this.Organization.PdfTimeStamp) {
            let editee: FaxConfigRestOrganizationPdfTimeStamp = this.Organization.PdfTimeStamp;
            const dialogRef = this.dialog.open(PdftimestampConfigComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(() => { this.refresh() });
        }
    }

    /* Logging */

    logging_backup_enable(): void {
        let def: FaxConfigRestOrganizationDef = {
            Logging: { BackupEnabled: this.BackupEnabled }
        };
        this.faxSrv.PutOrganizationValues(def).subscribe(res => {
            if (this.fenUtils.afterSave(res) <= 0) {
                // Request to toggle BackupEnabled failed; revert it.
                this.BackupEnabled = !this.BackupEnabled;
            }
        });
    }

    logging_backupage_valid(): number | null {
        // This returns the numeric value parsed from the BackupAge string,
        // or null if the value is invalid or not allowed per BackupAgeMax.
        let age: number = parseInt(this.BackupAge, 10);
        if (!isNaN(age)) {
            if (this.session.isMultiTenant() && this.BackupAgeMax) {    // some limit is imposed
                return (age > 0 && age <= this.BackupAgeMax)? age: null;
            } else {    // Single tenant, or BackupAgeMax is 0: no limit is imposed
                return (age >= 0)? age: null;
            }
        }
        return null;
    }

    logging_change(): void {
        let age: number | null;
        age = this.BackupAgeInfinite ? 0 : this.logging_backupage_valid();
        if (age !== null) {
            let def: FaxConfigRestOrganizationDef = {
                Logging: { BackupAge: age }
            };
            this.faxSrv.PutOrganizationValues(def).subscribe();
        }
    }

    /* Fax */

    faxFormApply(): void {
        let def: FaxConfigRestOrganizationDef = {
            Inbound: { FilenameTemplate: this.Organization.Inbound!.FilenameTemplate }
        };
        if (this.Organization.Fax) {
            def.Fax = { CSID: this.Organization.Fax.CSID };
        }
        if (this.attachmentOption.Flags >= 0) {
            def.NotificationOptions = [{ MailSystemName: 'SMTP', ReturnOriginal: this.attachmentOption.Flags }];
        } else {
            def.NotificationOptions = [{ MailSystemName: 'SMTP' }];
        }
        this.faxSrv.PutOrganizationValues(def).subscribe({
            next: res => this.fenUtils.afterSave(res),
            error: err => alert(err.message)
        });
    }

    generalFormApply(): void {
        let def = {
            General: {
                CountryCode: this.Organization.General?.CountryCode?? null,
                CountryISO2: this.Organization.General?.CountryISO2?? null,
                TimeZone:    this.Organization.General?.TimeZone?? null
            }
        };
        this.faxSrv.PutOrganizationValues(def).subscribe({
            next: res => this.fenUtils.afterSave(res),
            error: err => alert(err.message)
        });
    }

    // These are the [options] for the <app-filename-template> component:
    FilenameTemplateFields = [
        { Group: 'Common' },
        { Field: '{ourcsid}',       Display: 'Our CSID',                Preview: 'OurCSID' },
        { Field: '{csid}',          Display: 'Received CSID',           Preview: 'TheirCSID' },
        { Field: '{from.address}',  Display: 'Calling party number',    Preview: 'Caller' },
        { Field: '{to.address}',    Display: 'Called party number',     Preview: 'Callee' },
        { Field: '{routingcode}',   Display: 'Routing code',            Preview: 'Routing' },
        { Field: '{chargecode}',    Display: 'Charge code',             Preview: 'Charge' },
        { Field: '{jobnumber}',     Display: 'Job ID',                  Preview: 'JobId' },
        { Field: '{totpages}',      Display: 'Pages received',          Preview: 'Pages' },
        { Field: '{duration}',      Display: 'Duration',                Preview: 'Duration' },
        { Group: 'DateTime (Local)' },
        { Field: '{startrecvtimelocal}', Display: 'Start receive time',     Preview: 'Start' },
        { Field: '{creationtimelocal}',  Display: 'Creation time',          Preview: 'Create' },
        { Field: '{timelocal}',          Display: 'Current time',           Preview: 'Now' },
        { Group: 'DateTime (Universal)' },
        { Field: '{startrecvtime}',      Display: 'UTC Start receive time', Preview: 'UTC_Start' },
        { Field: '{creationtime}',       Display: 'UTC Creation time',      Preview: 'UTC_Create' },
        { Field: '{time}',               Display: 'UTC Current time',       Preview: 'UTC_Now' },
    ];

    fileNameDateTimeFormat(): void {
        let scope: IDateTimeFormatScope = {
            title: 'Datetime configuration',
            text: 'Choose the way the date and time fields are formatted within the filename template',
            editee: {
                dateFormat: this.Organization.Inbound!.FilenameDateFormat?? 'd-MM-yyyy',
                timeFormat: this.Organization.Inbound!.FilenameTimeFormat?? 'H-mm',
                dateTimeSeparator: this.Organization.Inbound!.FilenameDateTimeSeparator?? ' '
            },
            dateFormats: [
                'd-MM-yyyy',
                'dd-MM-yyyy',
                'M-dd-yyyy',
                'MM-dd-yyyy',
                'yyyy-MM-dd',
                'yyyyMMdd'
            ],
            timeFormats: [
                'H-m',
                'H-mm',
                'HH-mm',
                'HHmm',
                'H-mm-ss',
                'HH-mm-ss',
                'HHmmss',
                'h-mm t',
                'hh-mm t',
                'hhmm t',
                'h-mm-ss t',
                'hh-mm-ss t',
                'hhmmss t',
                'h-mm tt',
                'hh-mm tt',
                'hhmm tt',
                'h-mm-ss tt',
                'hh-mm-ss tt',
                'hhmmss tt'
            ]
        };
        const dialogRef = this.dialog.open(DialogDatetimeFormatComponent, { data: scope });
        dialogRef.afterClosed().subscribe({
            next: (res: IDateTimeFormatEditee) => {
                if (res) {
                    this.Organization.Inbound!.FilenameDateFormat = res.dateFormat;
                    this.Organization.Inbound!.FilenameTimeFormat = res.timeFormat;
                    this.Organization.Inbound!.FilenameDateTimeSeparator = res.dateTimeSeparator;
                    let def = {
                        Inbound: { 
                            FilenameDateFormat: res.dateFormat,
                            FilenameTimeFormat: res.timeFormat,
                            FilenameDateTimeSeparator: res.dateTimeSeparator
                        }
                    };
                    this.faxSrv.PutOrganizationValues(def).subscribe({
                        next: res => this.fenUtils.afterSave(res),
                        error: err => alert(err.message)
                    });
                }
            },
            error: err => alert(err.message)
        });
    }

    /* Multi-Factor Authentication */

    mfa_Apply(form: NgForm): void {
        let def: FaxConfigRestOrganizationMfaOptions = {
            Enforced: this.MFA.Enforced,
            CodeLength: this.MFA.CodeLength,
            CodeLifetime: this.MFA.CodeLifetime
        };
        // Note that setting an MFA value to its default will remove it from the OrganizationValues table.
        this.faxSrv.PutOrganizationValues({ MFA: def }).subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    // Reset the form to 'not dirty'
                    form.reset(this.MFA);
                }
            },
            error: err => alert(err.message)
        });
    }
}
