<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Import preferences</h1>
    </div>
    <form id="userimport_config_form" #userImportConfigForm="ngForm" class="form" novalidate (submit)="save()">
        <div class="modal-body">
            <p>Choose how import jobs are processed and results are presented.</p>

            <div class="field">
                <label>Keep jobs for</label>
                <span [ngSwitch]="editee.ImportJobAgeMax">
                    <input *ngSwitchCase="0" type="number" name="ageZero" min="1" max="2147483647" required style="width: 60px;" [(ngModel)]="editee.ImportJobAge" (ngModelChange)="change()">
                    <input *ngSwitchDefault type="number" name="ageDefault" min="1" max="{{ editee.ImportJobAgeMax }}" required style="width: 60px;" [(ngModel)]="editee.ImportJobAge" (ngModelChange)="change()">
                </span>
                <span>days</span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!userImportConfigForm.valid || !submitEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
