import { CommonModule } from '@angular/common';
import { Component, inject, Input, Renderer2, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestCSDefinition } from '../api/api';
import { CoversheetComponent, ICoversheetState, ICoversheetConfig, ITextArea } from '../coversheet/coversheet.component';
import { UtlVec } from '../utils/utl';
import { DialogService } from '../dialog/dialog.service';
import { CoversheetBackgroundComponent, ICoversheetBackgroundEditee } from '../coversheet-background/coversheet-background.component';

@Component({
    selector: 'app-coversheet-contpage',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-contpage.component.html',
    styleUrl: './coversheet-contpage.component.css'
})
export class CoversheetContpageComponent implements OnInit {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);

    @Input({ required: true }) parent!: CoversheetComponent;

    coversheet!: FaxConfigRestCSDefinition;
    coversheetState!: ICoversheetState;
    config!: ICoversheetConfig;

    backgroundSrc: string = '';

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.config = this.parent.config;
        this.coversheet = this.parent.coversheet!;
        this.coversheetState = this.parent.coversheetState;
        this.backgroundSrc = this.coversheet.NextPages?.Background ? this.parent.buildBackgroundSrc(false) : '';
        // convert to 'gui' format
        let textArea = this.coversheet.NextPages?.TextArea?? { x1:0, y1:0, x2:0, y2:0 }
        this.coversheetState.contPageTextArea = {
            definition: null,
            offset: new UtlVec(textArea.x1, textArea.y1).scale(this.config.scale),
            dim: new UtlVec((textArea.x2?? 0) - textArea.x1, (textArea.y2?? 0) - textArea.y1).scale(this.config.scale),
            prefix: ''
        };
        this.initTextArea(this.coversheetState.contPageTextArea);
    }

    initTextArea(textArea: ITextArea) {
        if (this.auth.isModifiable('Coversheets')) {
            let element = document.getElementById("contpage_textarea") as HTMLElement;
            this.parent.draggable(element, textArea);
            this.parent.resizable(element, textArea);
        }
    }

    uiDim(vec: UtlVec): object {
        return { width: vec.x + 'px', height: vec.y + 'px' };
    }

    uiRect(off: UtlVec, dim: UtlVec): object {
        return { left: off.x + 'px', top: off.y + 'px', width: dim.x + 'px', height: dim.y + 'px' };
    }

    chooseBackground(): void {
        let editee: ICoversheetBackgroundEditee = {
            name: this.parent.name!,
            coversheetFileExtensions: this.parent.coversheetFileExtensions,
            coversheetAccept: this.parent.coversheetAccept,
            isFirstPage: false
        };
        const dialogRef = this.dialog.open(CoversheetBackgroundComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.coversheet.NextPages!.Background = this.parent.utl.baseName(this.parent.name!, '.cs') + '_cont.tif';
                this.backgroundSrc = this.parent.buildBackgroundSrc(false);
            }
        });
    }

    removeBackground(): void {
        this.backgroundSrc = '';
        this.coversheet.NextPages!.Background = '';
        this.faxSrv.coversheetDeleteBackground(this.parent.name!, false).subscribe();
    };
}
