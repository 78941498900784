<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Create New Monitoring Sender</h1>
    </div>
    <form class="form" name="monitoringNewSender" novalidate (submit)="submit()">
        <div class="modal-body">
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_SMTP" value="SMTP">
                <label for="newType_SMTP"><b>SMTP Sender</b></label>
                <p>This type of sender can deliver email notifications via a specified mail server.</p>
            </div>
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_SqlLogger" value="SqlLogger">
                <label for="newType_SqlLogger"><b>SqlLogger Sender</b></label>
                <p>This type of sender can log notifications in a 'FenMonitoring' SQL table.</p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!newtype">Next</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
