import { Component, Inject, inject } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestConnectionInfoList, FaxConfigRestAddressUploadResult } from '../api/api';

import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, IRowNode, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { isString } from 'underscore';

interface IEditee {
    ConnectionId: number,
    Description: string
}

interface IScope {
    editee: IEditee,
    Connections: FaxConfigRestConnectionInfoList
}

@Component({
    selector: 'app-addresses-batch-upload',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './addresses-batch-upload.component.html',
    styleUrl: './addresses-batch-upload.component.css'
})
export class AddressesBatchUploadComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    cssGridHeight = { 'height': '300px' };
    gridApi: GridApi | null = null;
    scope: IScope;

    uploadTitle: string = 'Upload numbers';
    uploadFile: File | null = null;
    uploadPromise: Observable<string> | null = null;
    uploadEnabled: boolean = false;
    uploadActive: boolean = false;
    uploadFinished: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data as IScope;
    }

    statusCellRenderer(params: ICellRendererParams): string {
        var val = params.data? params.data.Status: null;
        if (val === 'ok') {
            var img = document.createElement('img');
            img.src = 'icon-checkmark.png';
            return img.outerHTML;
        } else {
            return val || 'unknown';
        }
    }

    statusComparator(valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean): number {
        // For sorting, 'ok' is considered the lowest value.
        let dispA: string = nodeA.data.Status;
        let dispB: string = nodeB.data.Status;
        if (dispA === 'ok') {
            return (dispB === 'ok')? 0: -1;
        } else if (dispB === 'ok') {
            return 1;
        } else {
            return dispA.localeCompare(dispB);
        }
    }

    addrUploadGridOptions: GridOptions = {
        columnDefs: [
            {
                field: 'Number',
                tooltipField: 'Number'
            },
            {
                field: 'Status',
                tooltipField: 'Status',
                cellRenderer: (params: ICellRendererParams) => this.statusCellRenderer(params),
                comparator: (valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean) => this.statusComparator(valA, valB, nodeA, nodeB, isDesc)
            }
        ],
        defaultColDef: {
            flex: 1,
            resizable: true,
            sortable: true,
            minWidth: 100,
            menuTabs: ['generalMenuTab', 'filterMenuTab'],
            filter: 'agTextColumnFilter'
        },
        rowModelType: 'clientSide',
        suppressCsvExport: false,
        suppressExcelExport: true,
        defaultCsvExportParams: { allColumns: true, fileName: 'export_upload_results.csv' },
        onGridReady: (params: GridReadyEvent) => {
            let height: number = 300;
            let elem: HTMLElement | null = document.getElementById('address_upload_form');
            if (elem) { height = elem.clientHeight; }
            this.cssGridHeight = { 'height': Math.max(300, height - 50) + 'px' };
            this.gridApi = params.api;
        }
    };

    filechange(event: Event) {
        let target: HTMLInputElement = event.target as HTMLInputElement;
        this.uploadFile = target.files? target.files[0]: null;
        if (this.uploadFile) {
            this.uploadEnabled = true;
            this.uploadPromise = this.fileRead(this.uploadFile);
        }
    }

    private fileRead(file: File): Observable<string> {
        let subj = new AsyncSubject<string>();
        let reader = new FileReader();
        reader.onload = () => { subj.next(reader.result as string); };
        reader.onerror = () => { subj.next(reader.error?.message?? 'An unexpected error occurred.'); };
        reader.onabort = () => { subj.next(''); };
        reader.onloadend = () => { subj.complete(); };
        reader.readAsDataURL(file);
        return subj;
    }

    uploadResultsExport(): void {
        this.gridApi?.exportDataAsCsv();
    }

    doUpload(): Observable<FaxConfigRestAddressUploadResult | string> {
        let subj = new AsyncSubject<FaxConfigRestAddressUploadResult | string>();
        if (this.uploadPromise)
        {
            this.uploadPromise.subscribe({
                next: data => {
                    let def = {
                        ConnectionId: this.scope.editee.ConnectionId,
                        Description: this.scope.editee.Description,
                        OrganizationId: null,
                        Filename: this.uploadFile?.name,
                        Base64data: data.split('base64,')[1]
                    };
                    this.uploadActive = true;
                    this.uploadEnabled = false;
                    this.faxSrv.PostAddressesUpload(def).subscribe({
                        next: res => {
                            subj.next(res);
                            subj.complete();
                        },
                        error: res => {
                            subj.error(res);
                            subj.complete();
                        }
                    });
                },
                error: msg => {
                    subj.error(msg);
                    subj.complete();
                }
            });
        }
        else {
            subj.next('');
            subj.complete();
        }
        return subj;
    }

    submit(): void {
        this.doUpload().subscribe({
            next: res => this.afterSubmit(res),
            error: res => this.afterSubmit(res)
        });
    }

    private afterSubmit(res: FaxConfigRestAddressUploadResult | string) {
        this.uploadActive = false;
        if (isString(res)) {
            this.uploadEnabled = true;
            alert(res?? 'An unknown error occurred.');
        } else {
            if (res.Status === 'ok') {
                this.uploadFinished = true;
                this.uploadTitle = 'Upload results';
                this.gridApi?.setGridOption('rowData', res.Results!);
            } else {
                this.fenUtils.afterSave(res);
                this.uploadEnabled = true;
            }
        }
    }

    close(): void {
        this.dialogRef.close(this.uploadFinished);
    }
}
