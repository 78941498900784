import { Component, Inject, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestAccountBase, FaxConfigRestAccountValues } from '../api/api';

export interface IAccountCreate extends FaxConfigRestAccountBase {
    IsAzureUser: boolean;
    AllowedRoles: string[];
    Alias?: string;
    Domains?: string[];
    Domain?: string;
    AllowLocal?: boolean;
    AllowFenTenant?: boolean;
    AllowMultiTenant?: boolean;
    AllowSocial?: boolean;
}

@Component({
    selector: 'app-account-create',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-create.component.html',
    styleUrl: './account-create.component.css'
})
export class AccountCreateComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    editee: IAccountCreate;
    modified: boolean = false;
    mfaEnforced: boolean = false;
    mfaEnabled: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountCreate }
    ) {
        this.editee = data.editee;
        this.faxSrv.GetOrganizationDefinition(this.editee.OrganizationId!).subscribe(res => {
            this.mfaEnforced = res.MFA?.Enforced?? false;
            this.mfaEnabled = this.mfaEnforced;
            this.isReady = true;
        });
    }

    private hasInvalidChars(name: string): boolean {
        return (
            name.indexOf('?') >= 0 ||
            name.indexOf('\\') >= 0 ||
            name.indexOf('/') >= 0
        );
    }

    private isInvalidAlias(name: string): boolean {
        return (name.indexOf('@') >= 0 || this.hasInvalidChars(name));
    }

    hasMultipleDomains(): boolean {
        return (this.editee.Domains && this.editee.Domains.length > 1)? true: false;
    };

    hasMultipleAllowedSources(): boolean {
        var count = 0;
        if (this.editee.AllowLocal) { count++ }
        if (this.editee.AllowFenTenant) { count++ }
        if (this.editee.AllowMultiTenant) { count++ }
        if (this.editee.AllowSocial) { count++ }
        return (count > 1);
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let def: FaxConfigRestAccountBase = {};
        if (this.editee.IsAzureUser) {
            if (this.editee.Source === 'AzureAD') {
                if (!this.editee.Alias || !this.editee.Domain) {
                    alert('The email address is not valid.');
                    return null;
                }
                if (this.isInvalidAlias(this.editee.Alias)) {
                    alert('The email alias cannot contain the characters \"@\", \"?\", \"\\\", or \"/\".');
                    return null;
                }
                def.Username = this.editee.Alias + '@' + this.editee.Domain;
            } else {
                // ($scope.editee.Source === 'AzureAD_MultiTenant' || $scope.editee.Source === 'AzureAD_Social')
                if (!this.editee.Username) {
                    alert('The username is not valid.');
                    return null;
                }
                if (this.hasInvalidChars(this.editee.Username)) {
                    alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                    return null;
                }
                def.Username = this.editee.Username;
            }
            def.Source = this.editee.Source;
        } else {
            if (!this.editee.Username) {
                alert('The username is not valid.');
                return null;
            }
            if (this.hasInvalidChars(this.editee.Username)) {
                alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                return null;
            }
            def.Username = this.editee.Username;
            def.Source = 'SQLDB';
        }
        def.Password        = this.editee.Password;
        def.OrganizationId  = this.editee.OrganizationId;
        def.Enabled         = this.editee.Enabled;
        def.Role            = this.editee.Role;
        def.Department      = this.editee.Department;
        def.Description     = this.editee.Description;
        return this.faxSrv.PostAccountsDefinition(def).pipe(map(res => {
            // Save MFA option if applicable
            if (res.Status === 'ok' && def.Source === 'SQLDB' && !this.mfaEnforced) {
                let mfa: FaxConfigRestAccountValues = { MfaEnabled: this.mfaEnabled };
                this.faxSrv.PutAccountValues(def.Username!, mfa).subscribe({
                    next: res => this.fenUtils.afterSave(res),
                    error: err => alert(err.message)
                });
            }
            return res;
        }));
    }

    private myAfterSave(res: FaxConfigRestResult): void {
        if (res.Errors && res.Errors[0]?.Id === 36) { // ERR_ALREADY_EXISTS
            if (this.editee.Source === 'AzureAD') {
                alert(this.editee.Alias + '@' + this.editee.Domain + ' already exists.  The Password was not changed.');
            } else {
                // ($scope.editee.Source === 'AzureAD_MultiTenant' || $scope.editee.Source === 'AzureAD_Social')
                alert(this.editee.Username + ' already exists.  The Password was not changed.');
            }
        } else if (this.fenUtils.afterSave(res) > 0) {
            this.modified = true;
            this.close();
        }
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = this.doSave();
        sub?.subscribe({
            next:  res => { this.myAfterSave(res); },
            error: err => { alert(err.message); }
        });
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
