import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, catchError, throwError, timer, Subject, AsyncSubject, forkJoin, first, map, of } from 'rxjs';
import * as api from './api';
import { UtlClass } from '../utils/utl';
import { Injectable } from '@angular/core';
import { PortalConfig } from '../services/portalconfig.service';
import * as _ from 'underscore';


@Injectable()
export class FaxConfigApi{

    constructor(private http: HttpClient, private config: PortalConfig) {
    }

    range(offset:number, limit:number): string {
        return (offset + 1) + '-' + (offset + limit); // FaxConfig range is 1-based, offset is 0-based.
    }

    GET<T>(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        context?: HttpContext;
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        transferCache?: {
            includeHeaders?: string[];
        } | boolean;
    }): Observable<HttpResponse<T>>{ return this.http.get<T>(this.config.prefix + url, options); }

    LOGIN<T>(url: string, body: any | null, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'events';
        context?: HttpContext;
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        transferCache?: {
            includeHeaders?: string[];
        } | boolean;
    }): Observable<HttpEvent<T>> { return this.http.post<T>(this.config.prefix + url, body, options); }

    POST<T>(url: string, body: any | null, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        context?: HttpContext;
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        transferCache?: {
            includeHeaders?: string[];
        } | boolean;
    }): Observable<HttpResponse<T>> { return this.http.post<T>(this.config.prefix + url, body, options); }

    PUT<T>(url: string, body: any | null, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        context?: HttpContext;
        params?: HttpParams | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        transferCache?: {
            includeHeaders?: string[];
        } | boolean;
    }): Observable<HttpResponse<T>> { return this.http.put<T>(this.config.prefix + url, body, options); }

    DELETE<T>(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        context?: HttpContext;
        params?: HttpParams | {
            [param: string]: string | number | boolean | (string | number | boolean)[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        body?: any | null;
    }): Observable<HttpResponse<T>> { return this.http.delete<T>(this.config.prefix + url, options); }

    getAzureADConfig() :Observable<api.FaxConfigRestAzureADConfig> {
        return this.GET<api.FaxConfigRestAzureADConfig>('AzureAD/Config',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    configGeneral() : Observable<api.FaxConfigRestConfigGeneral> {
        return this.GET<api.FaxConfigRestConfigGeneral>('Config/General',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateConfigGeneral(phoneNumberAdmin: boolean) : Observable<api.FaxConfigRestResult> {
        let data = { PhonenumberAdministrationEnabled: phoneNumberAdmin };
        return this.PUT<api.FaxConfigRestResult>('Config/General', data, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateSessionContext(def: api.FaxConfigRestSessionContext): Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('SessionContext', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    messageTypes() : Observable<api.FaxConfigRestStringList> {
        return this.GET<api.FaxConfigRestStringList>('Kernel/MessageTypes',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    kernelTemplateDefinitions(organizationId: number, messageType: string) : Observable<api.FaxConfigRestKernelCoversheetTemplates> {
        let params: { [key: string]: string } = {};
        if (organizationId) params['OrganizationId'] = '' + organizationId;
        if (messageType) params['MessageType'] = messageType;
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestKernelCoversheetTemplates>('Kernel/Template/Definitions',{params: httpParams, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    kernelMailTemplateDefinitions(organizationId: number) : Observable<api.FaxConfigRestKernelMailTemplates> {
        let params: { [key: string]: string } = {};
        if (organizationId) params['OrganizationId'] = '' + organizationId;
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestKernelMailTemplates>('Kernel/MailTemplate/Definitions',{params: httpParams, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    kernelAddressTypes() : Observable<api.FaxConfigRestKernelAddressTypes> {
        return this.GET<api.FaxConfigRestKernelAddressTypes>('Kernel/AddressTypes',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getCaptcha() : Observable<api.FaxConfigRestResultCaptcha> {
        return this.GET<api.FaxConfigRestResultCaptcha>('Captcha', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    postLoginReset(def: api.FaxConfigRestResetDef) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('LoginReset', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Queue */

    queueGeneral() : Observable<api.FaxConfigRestQueueGeneral> {
        return this.GET<api.FaxConfigRestQueueGeneral>('Queue/General', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    jobs(queue: string, offset: number, limit: number, filter: { [key:string]: string }) : Observable<api.FaxConfigRestJobsResults> {
        let p = _.extend({ range: this.range(offset, limit) }, _.clone(filter));
        let params = new HttpParams({ fromObject: _.extend({ range: this.range(offset, limit) }, _.clone(filter)) });
        return this.GET<api.FaxConfigRestJobsResults>('Queue/' + encodeURIComponent(queue) + '/Jobs', {params: params, observe: 'response'})
            .pipe(first(), map(response => {
                if (response.body!.Results) {
                    // normalize data
                    _.each(response.body!.Results, (job: api.FaxConfigRestJob) => {
                        job.isPaused = !!((job.Status as number) & 0x80000000); // pause bit.
                    });
                }
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    jobsCount(queue: string, filter: any) : Observable<api.FaxConfigRestQueueCounts> {
        let params = new HttpParams({ fromObject: _.clone(filter) });
        return this.GET<api.FaxConfigRestQueueCounts>('Queue/' + encodeURIComponent(queue) + '/Count', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    queueStatistics(queue: string) : Observable<api.FaxConfigRestQueueCounts> {
        let params = new HttpParams({ fromObject: { Statistics: 1 } });
        return this.GET<api.FaxConfigRestQueueCounts>('Queue/' + encodeURIComponent(queue) + '/Count', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    job(queue: string, jobNumber: string) : Observable<api.FaxConfigRestJob> {
        return this.GET<api.FaxConfigRestJob>('Queue/' + encodeURIComponent(queue) + '/Job/' + encodeURIComponent(jobNumber), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateJob(queue: string, jobNumber: string, job: api.FaxConfigRestJob) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Queue/' + encodeURIComponent(queue) + '/Job/' + encodeURIComponent(jobNumber), job, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    rerouteJob(queue: string, jobNumber: string, routingCode: string) : Observable<api.FaxConfigRestResult> {
        let def: api.FaxConfigRestRerouteJob = { RoutingCode: routingCode };
        return this.PUT<api.FaxConfigRestResult>('Queue/' + encodeURIComponent(queue) + '/Reroute/' + encodeURIComponent(jobNumber), def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteJob(queue: string, jobNumber: string, mode: string) : Observable<api.FaxConfigRestResult> {
        let params = new HttpParams({ fromObject: { mode: mode } });
        return this.DELETE<api.FaxConfigRestResult>('Queue/' + encodeURIComponent(queue) + '/Job/' + encodeURIComponent(jobNumber), {params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Number Substitution */

    numSubstDefinitions(machine?: string | null) : Observable<api.FaxConfigRestNumSubstDefList> {
        let params: { [key: string]: string } = {};
        if (machine) params['Machine'] = machine;
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestNumSubstDefList>('NumSubst/Definitions', {params: httpParams, observe: 'response'})
            .pipe(first(), map(response => {
                const utl: UtlClass = new UtlClass();
                const comparator = (numSubst: api.FaxConfigRestNumSubstDef) => {
                    return numSubst.Number? numSubst.Number.length: 0;
                };
                return utl.sorted(response.body!, utl.comparatorBy(['MessageType', comparator]));
            }),
            catchError(err => { return this.handleError(err); }));
    }

    createNumSubstDefinition(subst: api.FaxConfigRestNumSubstDef) : Observable<api.FaxConfigRestResult> {
        const fields: string[] = ['Machine', 'Enabled', 'Description', 'MessageType', 'Number', 'Substitute', 'Block'];
        let data = _.pick(subst, fields);
        return this.POST<api.FaxConfigRestResult>('NumSubst/Definition', data, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    numSubstDefinition(machine: string, messageType: string, number: string): Observable<api.FaxConfigRestNumSubstDef> {
        let params = new HttpParams({ fromObject: { Machine: machine, MessageType: messageType, Number: number } });
        return this.GET<api.FaxConfigRestNumSubstDef>('NumSubst/Definition', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateNumSubstDefinition(machine: string, messageType: string, number: string, subst: api.FaxConfigRestNumSubstDef) : Observable<api.FaxConfigRestResult> {
        const fields: string[] = ['Machine', 'Enabled', 'Description', 'MessageType', 'Number', 'Substitute', 'Block'];
        let data = _.pick(subst, fields);
        let params: HttpParams = new HttpParams({ fromObject: {
            Machine: machine,
            MessageType: messageType,
            Number: number
        } });
        return this.PUT<api.FaxConfigRestResult>('NumSubst/Definition', data, {params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteNumSubstDefinition(machine: string, messageType: string, number: string) : Observable<api.FaxConfigRestResult> {
        let params = new HttpParams({ fromObject: { Machine: machine, MessageType: messageType, Number: number } });
        return this.DELETE<api.FaxConfigRestResult>('NumSubst/Definition', {params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    numSubstMachines(): Observable<api.FaxConfigRestNumSubstMachineList> {
        return this.GET<api.FaxConfigRestNumSubstMachineList>('NumSubst/Machines', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    numSubstCountries(): Observable<api.FaxConfigRestNumSubstCountryList> {
        return this.GET<api.FaxConfigRestNumSubstCountryList>('NumSubst/Countries', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Log */

    logEntries(offset: number, limit: number, filter: any, order: string[], flags: string) : Observable<api.FaxConfigRestLogResults> {
        let params: { [key: string]: string | ReadonlyArray<string> } = {
            Range: this.range(offset, limit),
            Order: order,
            Flags: flags
        }
        // set filter
        _.map(filter, (value, key) => {
            if (value === 0 || value) {
                params[key] = value;
            }
        });
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestLogResults>('Log', {params: httpParams, observe: 'response'})
            .pipe(first(), map(response => {
                // normalize data
                const utl: UtlClass = new UtlClass();
                response.body!.Results = _.map(response.body!.Results?? [], (obj: api.FaxConfigRestPropertyList) => {
                    let entry: any = {
                        creationTime: null,
                        creationTimeLocal: null,
                        completionTime: null,
                        completionTimeLocal: null,
                        fields: {},
                        childEntries: [],
                        expanded: false,
                        show: true
                    }
                    _.each(obj, (prop: api.FaxConfigRestPropertyItem) => {
                        if (prop.Name !== null) {
                            let name: string = prop.Name;
                            if (name === 'CreationTime') {
                                entry.creationTime = utl.parseRFC3339(prop.Value)
                            } else if (name === 'CreationTimeLocal') {
                                entry.creationTimeLocal = utl.parseRFC3339Local(prop.Value)
                            } else if (name === 'CompletionTime') {
                                entry.completionTime = utl.parseRFC3339(prop.Value)
                            } else if (name === 'CompletionTimeLocal') {
                                entry.completionTimeLocal = utl.parseRFC3339Local(prop.Value)
                            } else if (name === 'SubmissionTime') {
                                entry.submissionTime = utl.parseRFC3339(prop.Value)
                            } else if (name === 'SubmissionTimeLocal') {
                                entry.submissionTimeLocal = utl.parseRFC3339Local(prop.Value)
                            } else if (name === 'StartRecvTime') {
                                entry.startRecvTime = utl.parseRFC3339(prop.Value)
                            } else if (name === 'StartRecvTimeLocal') {
                                entry.startRecvTimeLocal = utl.parseRFC3339Local(prop.Value)
                            } else if (name === 'Organization_Id') {
                                prop.DisplayName = 'Organization Id';
                            } else if (name === 'Organization_Name') {
                                prop.DisplayName = 'Organization Name';
                            }
                            (entry.fields as { [key: string]: any })[name] = {
                                displayName: prop.DisplayName,
                                value: prop.Value
                            }
                        }
                    });
                    return entry;
                });
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    logBackupFileExists(jobNumber: string) : Observable<boolean> {
        let httpParams = new HttpParams({ fromObject: { Test: true } });
        return this.GET<boolean>('Log/' + encodeURIComponent(jobNumber) + '/BackupFile', {params: httpParams, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    logResubmit(jobNumber: string, def: api.FaxConfigRestLogResubmit) : Observable<api.FaxConfigRestResultCount> {
        return this.PUT<api.FaxConfigRestResultCount>('Log/' + encodeURIComponent(jobNumber) + '/Resubmit', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Datasources */

    dataSourceNames() : Observable<api.FaxConfigRestDataSourceList> {
        return this.GET<api.FaxConfigRestDataSourceList>('DataSources', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    dataSource(name: string) : Observable<api.FaxConfigRestDataSourceDef> {
        return this.GET<api.FaxConfigRestDataSourceDef>('Datasource/' + encodeURIComponent(name), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    private dataSourcesCache: api.FaxConfigRestDataSourceDef[] | null = null;
    private dataSourcesLoadError: Error | null = null;
    private dataSourcesLoadState = 0;

    resetDataSources()  {
        this.dataSourcesCache = null;
        this.dataSourcesLoadError = null;
        this.dataSourcesLoadState = 0;
    }

    initDataSources(force:boolean) : void {
        if (force) { this.resetDataSources(); }
        timer(500).subscribe(() => this.dataSources());
    }

    dataSources() : Observable<api.FaxConfigRestDataSourceDef[]>  {
        let _sub = new AsyncSubject<api.FaxConfigRestDataSourceDef[]>();
        switch (this.dataSourcesLoadState)
        {
        case 0:
        default:
            this.dataSourcesCache = [];
            this.dataSourcesLoadState = 1;
            this.dataSourceNames().subscribe(systemNames => {
                let _chain: Observable<api.FaxConfigRestDataSourceDef>[] = [];
                systemNames.forEach( (n: string) => {
                    _chain.push(this.dataSource(n));
                });
                forkJoin(_chain)
                .pipe(catchError(e => { 
                    this.dataSourcesLoadState = 3;
                    this.dataSourcesLoadError = e;
                    _sub.error(e);
                    return this.handleError(e);
                }))
                .subscribe((res: api.FaxConfigRestDataSourceDef[]) => {
                    this.dataSourcesLoadState = 2;
                    this.dataSourcesCache = res;
                    _sub.next(this.dataSourcesCache?? []);
                    _sub.complete();
                });
            });
            break;
        case 1:
            // answer not available yet, create a delaying promise that will re-trigger this function 
            timer(100).subscribe(() => this.dataSources().subscribe({
                next:  n => { _sub.next(n); _sub.complete(); },
                error: e => { _sub.error(e); }
            }));
            break;
        case 2:
            _sub.next(this.dataSourcesCache?? []);
            _sub.complete();
            break;
        case 3:
            // request failed, emit the cached error.
            _sub.error(this.dataSourcesLoadError);
            // reset the state so a next call will start over.
            this.dataSourcesLoadState = 0;
            break;
        }
        return _sub;
    }

    dataSourceUsers(dataSourceName: string, parent: string, bHidden: boolean, offset: number, limit: number): Observable<api.FaxConfigRestUsersResults> {
        let params: { [key: string]: string | boolean } = {
            range: this.range(offset, limit),
            hidden: bHidden
        };
        if (parent) { params['Parent'] = parent; }
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestUsersResults>('DataSource/' + encodeURIComponent(dataSourceName) + '/Users', {params: httpParams, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    dataSourceDbUsers(dataSourceName: string, offset: number, limit: number, fields: string[], orderBy: string[], filters: any): Observable<api.FaxConfigRestDbUsersResults> {
        let params: { [key: string]: string | string[] } = {
            range: this.range(offset, limit),
            field: _.without(fields, 'Name', 'AddressType', 'Address'),
            orderby: orderBy
        };
        if (filters) {
            _.each(filters, filter => {
                params[filter.field] = filter.value;
            });
        }
        let httpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestDbUsersResults>('DataSource/' + encodeURIComponent(dataSourceName) + '/DbUsers', {params: httpParams, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    dataSourceUser(dataSourceName: string, userId: string): Observable<api.FaxConfigRestUser> {
        let params = new HttpParams({ fromObject: { UserId: userId } });
        return this.GET<api.FaxConfigRestUser>('DataSource/' + encodeURIComponent(dataSourceName) + '/User/Definition', {params: params, observe: 'response' })
            .pipe(first(), map(response => {
                let user: api.FaxConfigRestUser = response.body!;
                let maybeUpperCase = (v?: string | null): string => { return v? v.toUpperCase(): ''; };
                user.MailTemplate = maybeUpperCase(user.MailTemplate);
                user.Fax!.Coversheet = maybeUpperCase(user.Fax?.Coversheet);
                user.SMS!.Template = maybeUpperCase(user.SMS?.Template);
                user.MMS!.Template = maybeUpperCase(user.MMS?.Template);
                return user;
            }),
            catchError(err => { return this.handleError(err); }));
    }

    updateDataSourceUser(dataSourceName: string, userId: string, def: api.FaxConfigRestUser) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { UserId: userId } });
        return this.PUT<api.FaxConfigRestResult>('DataSource/' + encodeURIComponent(dataSourceName) + '/User/Definition', def, {params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    createDataSourceUser(dataSourceName: string, user: api.FaxConfigRestUser) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('DataSource/' + encodeURIComponent(dataSourceName) + '/User', user, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteDataSourceUser(dataSourceName: string, userId: string) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { UserId: userId } });
        return this.DELETE<api.FaxConfigRestResult>('DataSource/' + encodeURIComponent(dataSourceName) + '/User/Definition', {params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    dataSourceContainers(dataSourceName: string, parent?: string | null): Observable<api.FaxConfigRestUserContainerList> {
        let params: HttpParams = new HttpParams({ fromObject: parent? { Parent: parent }: {} });
        return this.GET<api.FaxConfigRestUserContainerList>('DataSource/' + encodeURIComponent(dataSourceName) + '/Containers', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getDataSourcesGeneral(): Observable<api.FaxConfigRestDataSourcesGeneral> {
        return this.GET<api.FaxConfigRestDataSourcesGeneral>('DataSources/General',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateDataSourcesGeneral(def: api.FaxConfigRestDataSourcesGeneral) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('DataSources/General', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    searchUsers(source: string, text: string, flags: number) : Observable<api.FaxConfigRestUserResultList> {
        let params: HttpParams = new HttpParams({ fromObject: {
            DataSource: source?? '',
            Keyword: text,
            SearchFlags: flags
        }});
        return this.GET<api.FaxConfigRestUserResultList>('Users/Search', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    userViewOptions(): Observable<api.FaxConfigRestUsersViewOptions> {
        return this.GET<api.FaxConfigRestUsersViewOptions>('Users/ViewOptions',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    userRouteTypes(): Observable<api.FaxConfigRestUserRouteTypeList> {
        return this.GET<api.FaxConfigRestUserRouteTypeList>('Users/RouteTypes',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostDataSourceUsersImportDef(dataSourceName: string, def: api.FaxConfigRestUsersImportDef) : Observable<api.FaxConfigRestResultId> {
        return this.POST<api.FaxConfigRestResultId>('DataSource/' + encodeURIComponent(dataSourceName) + '/UsersImportDef', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    dataSourceUsersImports(dataSourceName: string, offset: number, limit: number): Observable<api.FaxConfigRestUsersImportList> {
        let params: HttpParams = new HttpParams({ fromObject: { Range: this.range(offset, limit) }});
        return this.GET<api.FaxConfigRestUsersImportResults>('DataSource/' + encodeURIComponent(dataSourceName) + '/UsersImports', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body?.Results?? []),
            catchError(err => { return this.handleError(err); }));
    }

    PostDataSourceUsersExport(dataSourceName: string, lstUsers: string[]) : Observable<api.FaxConfigRestUsersExportResult> {
        const lstFields = [
            "FullName",
            "Address",
            "AddressType",
            "FaxAllowed",
            "Coversheet",
            "ChargeCode",
            "FileFormat",
            "ProxyAddresses"
        ];
        let def: api.FaxConfigRestUsersExportDef = {
            DataSource: dataSourceName,
            Users: lstUsers,
            Fields: lstFields
        };
        return this.POST<api.FaxConfigRestUsersExportResult>('Users/Export', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Organizations */

    static normalizeOrganizationDefinition(item: api.FaxConfigRestOrganizationDef){
        const utl: UtlClass = new UtlClass();
        item.CreatedDate = utl.parseRFC3339(item.Created);
        item.ModifiedDate = utl.parseRFC3339(item.Modified);
        item.SmtpDomains = (item.SmtpDomains) ? _.sortBy(item.SmtpDomains, function (domain) { return domain.toUpperCase(); }) : [];
    }

    GetOrganizationDefinitions(offset: number, limit:number, filter:{ parent: number|null, search: string|null })
        : Observable<api.FaxConfigRestOrganizationList> {
        let params: HttpParams = new HttpParams({ fromObject: {
            range: this.range(offset, limit),
            ParentOrgId: filter.parent ?? '',
            Search: filter.search ?? ''
        }});
        return this.GET<api.FaxConfigRestOrganizationList>('Organizations/Definitions', {params: params, observe: 'response'})
            .pipe(first(), map(response => {
                _.each(response.body!, def => { FaxConfigApi.normalizeOrganizationDefinition(def); })
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    GetOrganizationDefinition(orgID: number) :Observable<api.FaxConfigRestOrganizationDef> {
        return this.GET<api.FaxConfigRestOrganizationDef>('Organizations/Definition/' + encodeURIComponent(orgID),{observe: 'response'})
            .pipe(first(), map(response => {
                FaxConfigApi.normalizeOrganizationDefinition(response.body!);
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    PutOrganizationDefinition(id: number, def: api.FaxConfigRestOrganizationDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Organizations/Definition/' + encodeURIComponent(id), def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostOrganizationDefinition(def: api.FaxConfigRestOrganizationDef) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Organizations/Definition', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutOrganizationValues(def: api.FaxConfigRestOrganizationDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Organizations/Values', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    VerifyOrganizationSmtpDomain(domain: string, bypass: boolean) : Observable<api.FaxConfigRestResultOrganizationSmtpDomains> {
        let def = { SmtpDomains: [ domain ] };
        let params: HttpParams = new HttpParams({ fromObject: bypass ? { bypass: true } : {} });
        return this.PUT<api.FaxConfigRestResultOrganizationSmtpDomains>('Organizations/SmtpDomains', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteOrganizationDefinition(id: number) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Organizations/Definition/' + encodeURIComponent(id), { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetOrganizationViewOptions(): Observable<api.FaxConfigRestOrganizationsViewOptions> {
        return this.GET<api.FaxConfigRestOrganizationsViewOptions>('Organizations/ViewOptions',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetOrganizationsTimeZones(): Observable<api.FaxConfigRestOrganizationsTimeZoneList> {
        return this.GET<api.FaxConfigRestOrganizationsTimeZoneList>('Organizations/TimeZones',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* License */

    license() : Observable<api.FaxConfigRestLicenseProductInfo> {
        return this.GET<api.FaxConfigRestLicenseProductInfo>('License/ProductInfo',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Accounts */

    GetAccountsGeneral(): Observable<api.FaxConfigRestAccountsGeneral> {
        return this.GET<api.FaxConfigRestAccountsGeneral>('Accounts/General',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAccountsGeneral(def: api.FaxConfigRestAccountsGeneral) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Accounts/General', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    TestAccountsGeneral(def: api.FaxConfigRestAccountsGeneral) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Test: 1 } });
        return this.PUT<api.FaxConfigRestResult>('Accounts/General', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAccountsPassword(def: api.FaxConfigRestAccountBase) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Accounts/Password', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetAccountsDefinitions(source: string, offset: number, limit: number, filter:{ search: string|null }) : Observable<api.FaxConfigRestAccountsList> {
        let params: HttpParams = new HttpParams({ fromObject: {
            Source: source,
            Range: this.range(offset, limit),
            Search: filter.search ?? ''
        }});
        return this.GET<api.FaxConfigRestAccountsList>('Accounts/Definitions', { params: params, observe: 'response' })
            .pipe(first(), map(response => {
                let utl: UtlClass = new UtlClass();
                _.each(response.body!, (item: api.FaxConfigRestAccountBase) => {
                    item.CreatedDate  = utl.parseRFC3339(item.Created);
                    item.ModifiedDate = utl.parseRFC3339(item.Modified);
                });
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    PutAccountsDefinition(source: string, name: string, def: api.FaxConfigRestAccountBase) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Source: source } });
        return this.PUT<api.FaxConfigRestResult>('Accounts/Definition/' + encodeURIComponent(name), def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAccountsDefinition(def: api.FaxConfigRestAccountBase) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Accounts/Definition', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteAccountsDefinition(source: string, name: string): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Source: source } });
        return this.DELETE<api.FaxConfigRestResult>('Accounts/Definition/' + encodeURIComponent(name), {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetADGroupsDefinitions(): Observable<api.FaxConfigRestADGroupsList> {
        return this.GET<api.FaxConfigRestADGroupsList>('ADGroups/Definitions',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutADGroupsDefinition(name: string, def: api.FaxConfigRestADGroup) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { name: name } });
        return this.PUT<api.FaxConfigRestResult>('ADGroups/Definition', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostADGroupsDefinition(def: api.FaxConfigRestADGroup) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('ADGroups/Definition', def, {observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteADGroupsDefinition(name: string): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { name: name } });
        return this.DELETE<api.FaxConfigRestResult>('ADGroups/Definition', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetAccountValues(name: string) : Observable<api.FaxConfigRestAccountValues> {
        return this.GET<api.FaxConfigRestAccountValues>('Accounts/Definition/' + encodeURIComponent(name) + '/Values', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAccountValues(name: string, def: api.FaxConfigRestAccountValues) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Accounts/Definition/' + encodeURIComponent(name) + '/Values', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Addresses */

    GetConnections(filter: any): Observable<api.FaxConfigRestConnectionInfoList> {
        let params: HttpParams = new HttpParams({ fromObject:
            _.extend({ }, filter)
        });
        // Note: This API may return a FaxConfigRestResult object on failure!
        return this.GET<api.FaxConfigRestConnectionInfoList>('Addresses/Connections', { params: params, observe: 'response' })
            .pipe(first(), map(response => {
                return _.sortBy(response.body!, (item: api.FaxConfigRestConnectionInfo) => {
                    return item.ConnectionName?.toLowerCase();
                });
            }),
            catchError(err => { return this.handleError(err); }));
    }

    PutConnection(def: api.FaxConfigRestConnectionDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Addresses/Connections/' + encodeURIComponent(def.ID!), def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostConnection(def: api.FaxConfigRestConnectionDef) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Addresses/Connections', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteConnection(def: api.FaxConfigRestConnectionDef) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Addresses/Connections/' + encodeURIComponent(def.ID!), { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetAddresses(offset: number, limit: number, filter: any): Observable<api.FaxConfigRestAddressList> {
        let params = _.extend({ Range: this.range(offset, limit) }, _.omit(filter, 'Filters'))
        if (filter.Filters) {
            _.each(filter.Filters, item => {
                params[item.field] = item.value;
            });
        }
        let httpParams: HttpParams = new HttpParams ({ fromObject: params });
        return this.GET<api.FaxConfigRestAddressList>('Addresses/Definitions', { params: httpParams, observe: 'response' })
            .pipe(first(), map(response => {
                // normalize data
                let utl: UtlClass = new UtlClass();
                _.each(response.body!, (item: api.FaxConfigRestAddressInfo) => {
                    item.CreatedDate  = utl.parseRFC3339(item.Created);
                    item.ModifiedDate = utl.parseRFC3339(item.Modified);
                });
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    PutAddressesDefinition(ID: number, def: api.FaxConfigRestAddressDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Addresses/Definition/' + encodeURIComponent(ID), def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAddressesBatch(def: api.FaxConfigRestAddressBatchDef) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Addresses/Batch', def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAddressesBatch(def: api.FaxConfigRestAddressBatchDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Addresses/Batch', def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteAddressesBatch(addr_type: string, addr_start: string, addr_end: string, forceDelete: boolean) : Observable<api.FaxConfigRestResult> {
        if (!addr_end) {
            addr_end = addr_start;
        }
        let params: HttpParams = new HttpParams({ fromObject: {
            AddrStart: addr_start,
            AddrEnd: addr_end,
            AddrType: addr_type,
            Force: forceDelete
        }});
        return this.DELETE<api.FaxConfigRestResult>('Addresses/Batch', { params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAddressesUpload(def: api.FaxConfigRestAddressUploadDef) : Observable<api.FaxConfigRestAddressUploadResult> {
        return this.POST<api.FaxConfigRestAddressUploadResult>('Addresses/Upload', def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Address Registrations*/

    GetAddressRegistrations(offset: number, limit: number, filter:any) : Observable<api.FaxConfigRestAddressRegistrationList> {
        let params: HttpParams = new HttpParams({ fromObject:
            _.extend({ Range: this.range(offset, limit) }, filter)
        });
        return this.GET<api.FaxConfigRestAddressRegistrationList>('AddressRegistrations/Definitions', { params: params, observe: 'response' })
            .pipe(first(), map(response => {
                // normalize data
                let utl: UtlClass = new UtlClass();
                _.each(response.body!, (item: api.FaxConfigRestAddressRegistrationInfo) => {
                    item.CreatedDate  = utl.parseRFC3339(item.Created);
                    item.ModifiedDate = utl.parseRFC3339(item.Modified);
                });
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    PostAddressRegistration(def: api.FaxConfigRestNewAddressRegistrationSettings) : Observable<api.FaxConfigRestResultId> {
        return this.POST<api.FaxConfigRestResultId>('AddressRegistrations/Definition', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAddressRegistration(ID: number, def: api.FaxConfigRestAddressRegistrationSettings) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('AddressRegistrations/Definition/'+encodeURIComponent(ID), def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteAddressRegistration(ID: number) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('AddressRegistrations/Definition/'+encodeURIComponent(ID), { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAddressRegistrationBatch(def: api.FaxConfigRestAddressRegistrationBatchDef) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('AddressRegistrations/Batch', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAddressRegistrationBatch(def: api.FaxConfigRestAddressRegistrationBatchDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('AddressRegistrations/Batch', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAddressRegistrationUpload(def: api.FaxConfigRestAddrRegUploadDef) : Observable<api.FaxConfigRestAddrRegUploadResult> {
        return this.POST<api.FaxConfigRestAddrRegUploadResult>('AddressRegistrations/Upload', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* PDF date/time stamp options */

    GetPdfTimeStampPreview(sDateFormat: string, sTimeFormat: string, sGmtFormat: string, bLocalServerTime: boolean): Observable<api.FaxConfigRestKernelPdfTimeStampPreview> {
        let params: any = { UseLocalServerTime: bLocalServerTime };
        if (sDateFormat) params.DateFormat = sDateFormat;
        if (sTimeFormat) params.TimeFormat = sTimeFormat;
        if (sGmtFormat) params.GmtFormat = sGmtFormat;
        let httpParams: HttpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestKernelPdfTimeStampPreview>('Kernel/PdfTimeStamp/Preview', {params: httpParams, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getFontsDefs(): Observable<api.FaxConfigRestKernelFontsDefs> {
        return this.GET<api.FaxConfigRestKernelFontsDefs>('Kernel/Fonts/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Kernel database defaults */

    GetKernelDatabaseDefaults(): Observable<api.FaxConfigRestKernelDatabaseDefaults> {
        return this.GET<api.FaxConfigRestKernelDatabaseDefaults>('Kernel/Database/Defaults',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* SMTP options */

    GetSmtpGeneral(): Observable<api.FaxConfigRestSmtpGeneral> {
        return this.GET<api.FaxConfigRestSmtpGeneral>('SMTP/General', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutSmtpGeneral(def: api.FaxConfigRestSmtpGeneral) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('SMTP/General', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Number Format options */

    GetKernelNumberFormat(): Observable<api.FaxConfigRestKernelNumberFormat> {
        return this.GET<api.FaxConfigRestKernelNumberFormat>('Kernel/NumberFormat', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutKernelNumberFormat(def: api.FaxConfigRestKernelNumberFormat) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/NumberFormat', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Fax Retry Speed options */

    GetKernelFaxRetrySpeeds(): Observable<api.FaxConfigRestKernelFaxRetrySpeeds> {
        return this.GET<api.FaxConfigRestKernelFaxRetrySpeeds>('Kernel/FaxRetrySpeeds', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutKernelFaxRetrySpeeds(def: api.FaxConfigRestKernelFaxRetrySpeeds) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/FaxRetrySpeeds', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Connectors */

    GetConnHosts(qry: {}): Observable<api.FaxConfigRestHostMailSystemList> {
        let params: HttpParams = new HttpParams({ fromObject: qry });
        return this.GET<api.FaxConfigRestHostMailSystemList>('Connectors/Hosts', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetConnHostsNewUserDefaults(host: string, chan: string): Observable<api.FaxConfigRestUser> {
        let params: HttpParams = new HttpParams({ fromObject: { MailSystem: host, SystemAddress: chan } });
        return this.GET<api.FaxConfigRestUser>('Connectors/Hosts/NewUserDefaults', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateConnHostsNewUserDefaults(host: string, chan: string, def: api.FaxConfigRestUser) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { MailSystem: host, SystemAddress: chan } });
        return this.PUT<api.FaxConfigRestResult>('Connectors/Hosts/NewUserDefaults', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetConnHostGeneral(host: string): Observable<api.FaxConfigRestHostGeneral> {
        return this.GET<api.FaxConfigRestHostGeneral>('Connectors/Host/' + encodeURIComponent(host) + '/General', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateConnHostGeneral(host: string, def: api.FaxConfigRestHostGeneral) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Connectors/Host/' + encodeURIComponent(host) + '/General', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetConnHostChannels(host: string): Observable<api.FaxConfigRestHostChannelList> {
        return this.GET<api.FaxConfigRestHostChannelList>('Connectors/Host/' + encodeURIComponent(host) + '/Channels', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetConnHostChannel(host: string, chan: string): Observable<api.FaxConfigRestHostChannel> {
        let params: HttpParams = new HttpParams({ fromObject: { SystemAddress: chan }});
        return this.GET<api.FaxConfigRestHostChannel>('Connectors/Host/' + encodeURIComponent(host) + '/Channel', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    AddConnHostChannel(host: string, def: api.FaxConfigRestHostChannel): Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Connectors/Host/' + encodeURIComponent(host) + '/Channel', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateConnHostChannel(host: string, chan: string, def: api.FaxConfigRestHostChannel) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { SystemAddress: chan }});
        return this.PUT<api.FaxConfigRestResult>('Connectors/Host/' + encodeURIComponent(host) + '/Channel', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteConnHostChannel(host: string, chan: string) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { SystemAddress: chan }});
        return this.DELETE<api.FaxConfigRestResult>('Connectors/Host/' + encodeURIComponent(host) + '/Channel', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* ATNA Logging */

    GetAtnaLogging(): Observable<api.FaxConfigRestKernelAtnaLogging> {
        return this.GET<api.FaxConfigRestKernelAtnaLogging>('Kernel/Atna/Logging', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateAtnaLogging(def: api.FaxConfigRestKernelAtnaLogging) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/Atna/Logging', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostAtnaCertificateFile(def: api.FaxConfigRestKernelAtnaCertificateData): Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Kernel/Atna/CertificateFile', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetAtnaStoreCertificates(): Observable<api.FaxConfigRestKernelAtnaCertificateStoreList> {
        return this.GET<api.FaxConfigRestKernelAtnaCertificateStoreList>('Kernel/Atna/StoreCertificates', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Fax Line Limits */

    GetFaxLineLimitsDefinitions(offset: number, limit: number): Observable<api.FaxConfigRestFaxLineLimitsDefList> {
        let params: HttpParams = new HttpParams({ fromObject: { Range: this.range(offset, limit) }});
        return this.GET<api.FaxConfigRestFaxLineLimitsResults>('Kernel/FaxLineLimits/Definitions', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body?.Results?? []),
            catchError(err => { return this.handleError(err); }));
    }

    PutFaxLineLimitsDefinition(number: string, def: api.FaxConfigRestFaxLineLimitsDef) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { FaxNumber: number }});
        return this.PUT<api.FaxConfigRestResult>('Kernel/FaxLineLimits/Definition', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PostFaxLineLimitsDefinition(def: api.FaxConfigRestFaxLineLimitsDef): Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Kernel/FaxLineLimits/Definition', def, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteFaxLineLimitsDefinition(number: string) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { FaxNumber: number }});
        return this.DELETE<api.FaxConfigRestResult>('Kernel/FaxLineLimits/Definition', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Audit Log */

    GetAuditLogDBConfig(): Observable<api.FaxConfigRestAuditLogOptions> {
        return this.GET<api.FaxConfigRestAuditLogOptions>('AuditLog/Options',{observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    PutAuditLogDBConfig(def: api.FaxConfigRestAuditLogOptions): Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('AuditLog/Options', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    TestAuditLogDBConfig(def: api.FaxConfigRestAuditLogOptions): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Test: 1 } });
        return this.PUT<api.FaxConfigRestResult>('AuditLog/Options', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteAuditLogDBConfig(): Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('AuditLog/Options', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetAuditLogItems(offset: number, limit: number, filters: any): Observable<api.FaxConfigRestAuditLogItemsResults> {
        let params: { [key: string]: string } = {
            Range: this.range(offset, limit)
        };
        let httpParams: HttpParams = new HttpParams({ fromObject: _.extend(params, filters) });
        return this.GET<api.FaxConfigRestAuditLogItemsResults>('AuditLog/Items', {params: httpParams, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Outbound Service Management */

    osmDefinitions(): Observable<api.FaxConfigRestOSMDefList> {
        return this.GET<api.FaxConfigRestOSMDefList>('OSM/Definitions', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    osmDefinition(name: string): Observable<api.FaxConfigRestOSMDef> {
        let params: HttpParams = new HttpParams({ fromObject: { Name: name } });
        return this.GET<api.FaxConfigRestOSMDef>('OSM/Definition', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    createOsmDefinition(rule: api.FaxConfigRestOSMDef): Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('OSM/Definition', rule, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateOsmDefinition(name: string, rule: api.FaxConfigRestOSMDef): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Name: name } });
        return this.PUT<api.FaxConfigRestResult>('OSM/Definition', rule, { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteOsmDefinition(name: string): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Name: name } });
        return this.DELETE<api.FaxConfigRestResult>('OSM/Definition', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Outbound Service Routing */

    osrDefinitions(offset: number, limit: number, filters: any[], sortKey: string, sortDir: string): Observable<api.FaxConfigRestOSRDefListRange> {
        let params: { [key: string]: string } = {
            Range: this.range(offset, limit),
            SortKey: sortKey,
            SortDir: sortDir
        };
        if (filters) {
            _.each(filters, item => {
                params[item.field] = item.value;
            });
        }
        let httpParams: HttpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestOSRDefListRange>('OSR/DefinitionsRange', { params: httpParams, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    osrDefinition(routeType: string, messageType: string, routeValue: string): Observable<api.FaxConfigRestOSRDef> {
        let params: HttpParams = new HttpParams({ fromObject: {
            RouteType: routeType,
            MessageType: messageType,
            RouteValue: routeValue
        }});
        return this.GET<api.FaxConfigRestOSRDef>('OSR/Definition', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateOsrDefinition(routeType: string, messageType: string, routeValue: string, route: api.FaxConfigRestOSRDef): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: {
            RouteType: routeType,
            MessageType: messageType,
            RouteValue: routeValue
        }});
        return this.PUT<api.FaxConfigRestResult>('OSR/Definition', route, { params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    createOsrDefinition(route: api.FaxConfigRestOSRDef): Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('OSR/Definition', route, { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteOsrDefinition(routeType: string, messageType: string, routeValue: string): Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: {
            RouteType: routeType,
            MessageType: messageType,
            RouteValue: routeValue
        }});
        return this.DELETE<api.FaxConfigRestResult>('OSR/Definition', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getOsrRoutingOrder(): Observable<api.FaxConfigRestOSRRoutingOrder> {
        return this.GET<api.FaxConfigRestOSRRoutingOrder>('OSR/RoutingOrder', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    putOsrRoutingOrder(def: api.FaxConfigRestOSRRoutingOrder): Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('OSR/RoutingOrder', def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Fax loopback services */

    getKernelLoopbackServices(): Observable<api.FaxConfigRestKernelLoopbackServices> {
        return this.GET<api.FaxConfigRestKernelLoopbackServices>('Kernel/LoopbackServices', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateKernelLoopbackServices(def: api.FaxConfigRestKernelLoopbackServices): Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/LoopbackServices', def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Extended Custom Routing */

    ecrFaxFormats(): Observable<api.FaxConfigRestECRFaxFormatList> {
        return this.GET<api.FaxConfigRestECRFaxFormatList>('ECR/FaxFormats', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    ecrHostNames(): Observable<api.FaxConfigRestECRHostList> {
        return this.GET<api.FaxConfigRestECRHostList>('ECR/Hosts', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    ecrDefinitions(): Observable<api.FaxConfigRestECRDefList> {
        return this.GET<api.FaxConfigRestECRDefList>('ECR/Definitions', { observe: 'response' })
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateEcrDefinition(def: api.FaxConfigRestECRDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('ECR/Definition/' + encodeURIComponent(def.Index!), def, { observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    ecrRules(index: number, offset: number, limit: number): Observable<api.FaxConfigRestECRRuleList> {
        let params: HttpParams = new HttpParams({ fromObject: {
            Range: this.range(offset, limit)
        }});
        return this.GET<api.FaxConfigRestECRRuleResults>('ECR/Definition/' + encodeURIComponent(index) + '/Rules', { params: params, observe: 'response' })
            .pipe(first(), map(response => response.body?.Results?? []),
            catchError(err => { return this.handleError(err); }));
    }

    ecrRule(index: number, ruleKey: number): Observable<api.FaxConfigRestECRRule> {
        return this.GET<api.FaxConfigRestECRRule>('ECR/Definition/' + encodeURIComponent(index) + '/Rule/' + encodeURIComponent(ruleKey), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    ecrRuleDescription(index: number, ruleKey: number): Observable<api.FaxConfigRestECRDescription> {
        return this.GET<api.FaxConfigRestECRDescription>('ECR/Definition/' + encodeURIComponent(index) + '/Rule/' + encodeURIComponent(ruleKey) + '/Description', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateEcrRule(index: number, ruleKey: number, rule: api.FaxConfigRestECRRule) : Observable<api.FaxConfigRestECRDescription> {
        return this.PUT<api.FaxConfigRestECRDescription>('ECR/Definition/' + encodeURIComponent(index) + '/Rule/' + encodeURIComponent(ruleKey), rule, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    createEcrRule(index: number, rule: api.FaxConfigRestECRRule) : Observable<api.FaxConfigRestECRDescription> {
        return this.POST<api.FaxConfigRestECRDescription>('ECR/Definition/' + encodeURIComponent(index) + '/Rule', rule, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteEcrRule(index: number, ruleKey: number) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('ECR/Definition/' + encodeURIComponent(index) + '/Rule/' + encodeURIComponent(ruleKey), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Coversheets */

    coversheets(): Observable<api.FaxConfigRestCSCoversheets> {
        return this.GET<api.FaxConfigRestCSCoversheets>('Coversheet/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getCoversheetImageExtensions(): Observable<api.FaxConfigRestCSImageExtensions> {
        return this.GET<api.FaxConfigRestCSImageExtensions>('Coversheet/ImageExtensions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheet(name: string): Observable<api.FaxConfigRestCSDefinition> {
        return this.GET<api.FaxConfigRestCSDefinition>('Coversheet/Definition/' + encodeURIComponent(name), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    createCoversheet(name: string, coversheet: api.FaxConfigRestCSDefinition) : Observable<api.FaxConfigRestResult> {
        return this.POST<api.FaxConfigRestResult>('Coversheet/Definition/' + encodeURIComponent(name), coversheet, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateCoversheet(name: string, coversheet: api.FaxConfigRestCSDefinition) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Coversheet/Definition/' + encodeURIComponent(name), coversheet, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteCoversheet(name: string) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Coversheet/Definition/' + encodeURIComponent(name), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetFieldDefinitions(): Observable<api.FaxConfigRestCSFieldDefList> {
        return this.GET<api.FaxConfigRestCSFieldDefList>('Coversheet/Fields', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetDeleteBackground(name: string, isFirstPage: boolean) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { FirstPage: isFirstPage ? 'True' : 'False' } });
        return this.DELETE<api.FaxConfigRestResult>('Coversheet/Definition/' + encodeURIComponent(name) + '/Background', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetGetDigitalSignatures(): Observable<api.FaxConfigRestCSDigitalSignatures> {
        return this.GET<api.FaxConfigRestCSDigitalSignatures>('Coversheet/DigitalSignatures', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetGetDisclaimers(): Observable<api.FaxConfigRestCSDisclaimers> {
        return this.GET<api.FaxConfigRestCSDisclaimers>('Coversheet/Disclaimers', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetPostDisclaimer(name: string) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { FileName: name } });
        return this.POST<api.FaxConfigRestResult>('Coversheet/Disclaimer', params, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    coversheetDeleteDisclaimer(name: string) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Coversheet/Disclaimer/' + encodeURIComponent(name), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Mail Templates */

    getMailTemplatesArchive(orgId: number): Observable<api.FaxConfigRestMailTemplatesDownload> {
        let params: HttpParams = new HttpParams({ fromObject: { OrganizationId: orgId }});
        return this.GET<api.FaxConfigRestMailTemplatesDownload>('MailTemplates/Archive', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    postMailTemplatesArchive(def: api.FaxConfigRestMailTemplatesUpload, orgId: number, overwrite: boolean) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { OrganizationId: orgId, Overwrite: overwrite }});
        return this.POST<api.FaxConfigRestResult>('MailTemplates/Archive', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Monitoring */

    monitoringGeneral(): Observable<api.FaxConfigRestKernelMonitoringGeneral> {
        return this.GET<api.FaxConfigRestKernelMonitoringGeneral>('Kernel/Monitoring/General', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateMonitoringGeneral(def: api.FaxConfigRestKernelMonitoringGeneral) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/Monitoring/General', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    monitoringRules(): Observable<api.FaxConfigRestKernelMonitoringRulesList> {
        return this.GET<api.FaxConfigRestKernelMonitoringRulesList>('Kernel/Monitoring/Rules/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateMonitoringRule(rule: api.FaxConfigRestKernelMonitoringRuleBase) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/Monitoring/Rules/Definition/' + encodeURIComponent(rule.ID!), rule, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteMonitoringRule(rule: api.FaxConfigRestKernelMonitoringRuleBase) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Kernel/Monitoring/Rules/Definition/' + encodeURIComponent(rule.ID!), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    monitoringSenders(): Observable<api.FaxConfigRestKernelMonitoringSendersList> {
        return this.GET<api.FaxConfigRestKernelMonitoringSendersList>('Kernel/Monitoring/Senders/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateMonitoringSender(sender: api.FaxConfigRestKernelMonitoringSenderBase) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/Monitoring/Senders/Definition/' + encodeURIComponent(sender.ID!), sender, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    testMonitoringSender(sender: api.FaxConfigRestKernelMonitoringSenderBase) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { Test: 1 }});
        return this.PUT<api.FaxConfigRestResult>('Kernel/Monitoring/Senders/Definition/' + encodeURIComponent(sender.ID!), sender, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteMonitoringSender(sender: api.FaxConfigRestKernelMonitoringSenderBase) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Kernel/Monitoring/Senders/Definition/' + encodeURIComponent(sender.ID!), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    monitoringTemplatesDefinitions(): Observable<api.FaxConfigRestKernelMonitoringTemplatesList> {
        return this.GET<api.FaxConfigRestKernelMonitoringTemplatesList>('Kernel/Monitoring/Templates/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateMonitoringTemplate(template: api.FaxConfigRestKernelMonitoringTemplate) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/Monitoring/Templates/Definition/' + encodeURIComponent(template.Name!), template, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    deleteMonitoringTemplate(template: api.FaxConfigRestKernelMonitoringTemplate) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('Kernel/Monitoring/Templates/Definition/' + encodeURIComponent(template.Name!), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* API Keys */

    GetApiKeys(offset: number, limit: number) : Observable<api.FaxConfigRestApiKeyList> {
        let params: HttpParams = new HttpParams({ fromObject: {
            Range: this.range(offset, limit)
        }});
        return this.GET<api.FaxConfigRestApiKeyList>('APIKeys', { params: params, observe: 'response' })
            .pipe(first(), map(response => {
                let utl: UtlClass = new UtlClass();
                _.each(response.body!, (item: api.FaxConfigRestApiKey) => {
                    item.CreatedDate = utl.parseRFC3339(item.Created);
                    item.ExpiresDate = utl.parseRFC3339(item.Expires);
                });
                return response.body!
            }),
            catchError(err => { return this.handleError(err); }));
    }

    AddApiKey(def: api.FaxConfigRestApiKey) : Observable<api.FaxConfigRestApiKey> {
        return this.POST<api.FaxConfigRestApiKey>('APIKeys', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    ActivateApiKey(id: number) : Observable<api.FaxConfigRestApiKey> {
        return this.PUT<api.FaxConfigRestApiKey>('APIKeys/' + encodeURIComponent(id) + '/Activate', {}, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    RevokeApiKey(id: number) : Observable<api.FaxConfigRestApiKey> {
        return this.PUT<api.FaxConfigRestApiKey>('APIKeys/' + encodeURIComponent(id) + '/Revoke', {}, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteApiKey(id: number) : Observable<api.FaxConfigRestResult> {
        return this.DELETE<api.FaxConfigRestResult>('APIKeys/' + encodeURIComponent(id), {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    };

    /* NT Services */

    getServicesGeneral(): Observable<api.FaxConfigRestServicesGeneral> {
        return this.GET<api.FaxConfigRestServicesGeneral>('Services/General', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    getServicesKernels(): Observable<api.FaxConfigRestServicesKernels> {
        return this.GET<api.FaxConfigRestServicesKernels>('Services/Kernels', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    services(server: string): Observable<api.FaxConfigRestNTServiceList> {
        let params: HttpParams = new HttpParams({ fromObject: { Server: server }});
        return this.GET<api.FaxConfigRestNTServiceList>('Services', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    updateServiceStatus(server: string, serviceName: string, status: string): Observable<api.FaxConfigRestResult> {
        let data: api.FaxConfigRestNTService = { Status: status };
        let params: HttpParams = new HttpParams({ fromObject: { Server: server }});
        return this.PUT<api.FaxConfigRestResult>('Service/' + encodeURIComponent(serviceName), data, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Reporting */

    GetReports(): Observable<api.FaxConfigReportList> {
        return this.GET<api.FaxConfigReportList>('Reports', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetReport(reportName: string, monthsBack?: string, startDate?: string, endDate?: string): Observable<api.FaxConfigRestLogReportResults> {
        let params: {
            months_back?: string,
            start_date?: string,
            until?: string
        } = { };
        if (monthsBack) {
            params.months_back = monthsBack;
        }
        if (startDate) {
            params.start_date = startDate;
        }
        if (endDate) {
            params.until = endDate;
        }
        let httpParams: HttpParams = new HttpParams({ fromObject: params });
        return this.GET<api.FaxConfigRestLogReportResults>('Reports/' + encodeURIComponent(reportName), {params: httpParams, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* System Information */

    GetSystemInfo(category: string, server: string): Observable<api.FaxConfigRestSystemInfo> {
        let params: HttpParams = new HttpParams({ fromObject: { Server: server }});
        return this.GET<api.FaxConfigRestSystemInfo>('SystemInfo/' + encodeURIComponent(category), {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Some metadata methods */

    isDbProvider(dataSourceName: string | null | undefined): boolean {
        return (dataSourceName === 'OLE DB');
    }

    isLdapProvider(dataSourceName: string | null | undefined): boolean {
        return (dataSourceName === 'GC' || dataSourceName === 'LDAP');
    }

    outboundRouteTypes(): { list: any[], byCode: { [key: string]: any } } {
        const list = [
            { 'code': '00', display: 'Destination Address', valueLabel: 'Destination *' },
            { 'code': '01', display: 'ChargeCode', valueLabel: 'ChargeCode *' },
            { 'code': '02', display: 'Sender', valueLabel: 'Sender *' },
            { 'code': '03', display: 'Proxy fax address', valueLabel: 'Proxy fax *' },
            { 'code': '05', display: 'Fax loopback', valueLabel: '-' },
            { 'code': '99', display: 'Default', valueLabel: '-' }
        ];
        return {
            list: _.sortBy(list, 'display'),
            byCode: _.object(_.map(list, type => { return [type.code, type] }))
        }
    }

    outboundManagementCriteriaTypes(): { [key: string]: { display: string } } {
        return {
            'DT': { display: 'Time' },
            'FA': { display: 'Failed Attempts' },
            'IM': { display: 'Inactive Minutes' }
        };
    }

    /* SMTPStack */

    GetSMTPStackClientIds(): Observable<api.FaxConfigRestSMTPStackConnectorIdList> {
        return this.GET<api.FaxConfigRestSMTPStackConnectorIdList>('Kernel/SMTPStack/Client/Identifiers', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetSMTPStackServerIds(): Observable<api.FaxConfigRestSMTPStackConnectorIdList> {
        return this.GET<api.FaxConfigRestSMTPStackConnectorIdList>('Kernel/SMTPStack/Server/Identifiers', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetSMTPStackClient(id: string): Observable<api.FaxConfigRestSMTPStackClientDef> {
        return this.GET<api.FaxConfigRestSMTPStackClientDef>('Kernel/SMTPStack/Client/' + encodeURIComponent(id) + '/Definition', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateSMTPStackClient(id: string, def: api.FaxConfigRestSMTPStackClientDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/SMTPStack/Client/' + encodeURIComponent(id) + '/Definition', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetSMTPStackServer(id: string): Observable<api.FaxConfigRestSMTPStackServerDef> {
        return this.GET<api.FaxConfigRestSMTPStackServerDef>('Kernel/SMTPStack/Server/' + encodeURIComponent(id) + '/Definition', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateSMTPStackServer(id: string, def: api.FaxConfigRestSMTPStackServerDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/SMTPStack/Server/' + encodeURIComponent(id) + '/Definition', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetSMTPStackNewDKIMKeys(): Observable<api.FaxConfigRestSMTPStackNewDKIMKeys> {
        return this.GET<api.FaxConfigRestSMTPStackNewDKIMKeys>('Kernel/SMTPStack/NewDKIMKeys', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    TestSMTPStackClientDKIM(id: string, def: api.FaxConfigRestSMTPStackClientDef) : Observable<api.FaxConfigRestResult> {
        return this.PUT<api.FaxConfigRestResult>('Kernel/SMTPStack/Client/' + encodeURIComponent(id) + '/Test/DKIM', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Connector Contracts */

    GetContractConnectors(): Observable<api.FaxConfigRestKernelConnectorList> {
        return this.GET<api.FaxConfigRestKernelConnectorList>('Kernel/Contract/Connectors', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    GetContracts(): Observable<api.FaxConfigRestKernelContractList> {
        return this.GET<api.FaxConfigRestKernelContractList>('Kernel/Contract/Definitions', {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    AddContract(def: api.FaxConfigRestKernelContract) : Observable<api.FaxConfigRestResultID> {
        return this.POST<api.FaxConfigRestResultID>('Kernel/Contract/Definition', def, {observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    UpdateContract(def: api.FaxConfigRestKernelContract) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { ContractID: def.ContractID! }});
        return this.PUT<api.FaxConfigRestResult>('Kernel/Contract/Definition', def, {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    DeleteContract(id: string) : Observable<api.FaxConfigRestResult> {
        let params: HttpParams = new HttpParams({ fromObject: { ContractID: id } });
        return this.DELETE<api.FaxConfigRestResult>('Kernel/Contract/Definition', {params: params, observe: 'response'})
            .pipe(first(), map(response => response.body!),
            catchError(err => { return this.handleError(err); }));
    }

    /* Error Handling */
    // Note that this does not trigger for FaxConfigRestResult responses where Status === 'nok'!

    private handleError(error: HttpErrorResponse) {
        // This triggers if the backend returns a failed status, generally any code not 2xx.
        // In theory this may also trigger with a status of 0 if there is a network error,
        // or an unhandled exception occurs in our client-side code when mapping a response.
        // The HTML body (if any) may contain clues as to what went wrong, so try to log it.
        const status: number = error?.status?? 0;
        const errmsg: string = error?.message?? '';
        const errhtm: string = error?.error?? '';
        console.error(`Error ${status}: `, errhtm || errmsg);
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('An unexpected error occurred; please try again later.'));
    }
}
