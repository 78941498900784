<form id="conn_newuserdefaults" class="form" novalidate name="connNewUserDefaultsForm" #connNewUserDefaultsForm="ngForm">

    <ul class="nav nav-tabs">
        <li *ngFor="let pageTab of visiblePageTabs()" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab.Caption }}</a>
        </li>
    </ul>
    <div [hidden]="!pageTabs[0].showTab || state.activePageTab.Caption !== pageTabs[0].Caption">
        <ng-container *ngTemplateOutlet="page0"></ng-container>
    </div>
    <div [hidden]="!pageTabs[1].showTab || state.activePageTab.Caption !== pageTabs[1].Caption">
        <ng-container *ngTemplateOutlet="page1"></ng-container>
    </div>
    <div [hidden]="!pageTabs[2].showTab || state.activePageTab.Caption !== pageTabs[2].Caption">
        <ng-container *ngTemplateOutlet="page2"></ng-container>
    </div>
    <div [hidden]="!pageTabs[3].showTab || state.activePageTab.Caption !== pageTabs[3].Caption">
        <ng-container *ngTemplateOutlet="page3"></ng-container>
    </div>

<!-- General tab -->
<ng-template #page0>
    <div *ngIf="pageTabs[0].isReady">
        <div class="field">
            <label>System Address:</label>
            <input type="text" maxlength="20" name="systemAddress" [(ngModel)]="editee.SystemAddress" [disabled]="!isModifiable">
        </div>
        <div class="field">
            <label>Charge Code:</label>
            <input type="text" maxlength="16" name="chargeCode" [(ngModel)]="editee.ChargeCode" [disabled]="!isModifiable">
        </div>
        <div class="field">
            <label>Mail Template:</label>
            <select name="mailTemplate" [(ngModel)]="editee.MailTemplate" [disabled]="!isModifiable">
                <option *ngFor="let mt of mailTemplates" [value]="mt">{{ mt }}</option>
            </select>
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideChargeCode" [(ngModel)]="editee.AllowToOverrideChargeCode" [disabled]="!isModifiable">
            User is allowed to override charge code
        </div>
    </div>
</ng-template>

<!-- FAX tab -->
<ng-template #page1>
    <div *ngIf="pageTabs[1].isReady">
        <div class="field">
            <label>Coversheet:</label>
            <select name="faxCoversheet" [(ngModel)]="editee.Fax!.Coversheet" [disabled]="!isModifiable">
                <option *ngFor="let cs of coversheets" [value]="cs">{{ cs }}</option>
            </select>
        </div>
        <div class="field">
            <label>File Type:</label>
            <select name="faxFormat" [(ngModel)]="editee.Fax!.FileFormat" [disabled]="!isModifiable">
                <option *ngFor="let f of faxFormats" [value]="f.FaxFormat">{{ f.DisplayName }}</option>
            </select>
        </div>
        <div class="field">
            <label>CSID:</label>
            <input type="text" maxlength="20" name="CSID" [(ngModel)]="editee.Fax!.CSID" [disabled]="!isModifiable">
        </div>
        <div>
            <input type="checkbox" name="allowToSendFax" [(ngModel)]="editee.Fax!.AllowToSendFax" [disabled]="!isModifiable">
            User is allowed to send fax messages
        </div>
        <div class="field" id="allowToSendFaxInt">
            <input type="checkbox" name="allowToSendFaxInt" [(ngModel)]="editee.Fax!.AllowToSendFaxInt" [disabled]="!isModifiable || editee.Fax?.AllowToSendFax !== true">
            International
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideCoversheet" [(ngModel)]="editee.Fax!.AllowToOverrideCoversheet" [disabled]="!isModifiable">
            User is allowed to override fax coversheet
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideCSID" [(ngModel)]="editee.Fax!.AllowToOverrideCSID" [disabled]="!isModifiable">
            User is allowed to override the CSID
        </div>
        <div class="fieldset" *ngIf="userViewOptions.TrafficFaxLimitationEnabled">
            <label><input type="checkbox" name="limitsFaxIsActive" [(ngModel)]="limits!.fax.isActive" [disabled]="!isModifiable"> Limit to</label>&ngsp;
            <input type="number" style="width: 60px;" min="0" max="999999" name="limitsFaxAmount" [(ngModel)]="limits!.fax.amount" [disabled]="!isModifiable || limits!.fax.isActive !== true">
            &ngsp;messages per&ngsp;
            <input type="number" style="width: 90px;" min="1" max="999" name="limitsFaxPeriodDuration" [(ngModel)]="limits!.fax.period.duration" [disabled]="!isModifiable || limits!.fax.isActive !== true">
            &ngsp;
            <select name="limitsFaxPeriodUnit" [(ngModel)]="limits!.fax.period.unit" [disabled]="!isModifiable || limits!.fax.isActive !== true">
                <option *ngFor="let u of periodUnits" [ngValue]="u">{{ u.unit }}</option>
            </select>
        </div>
    </div>
</ng-template>

<!-- SMS tab -->
<ng-template #page2>
    <div *ngIf="pageTabs[2].isReady">
        <div class="field">
            <label>Template:</label>
            <select name="smsTemplate" [(ngModel)]="editee.SMS!.Template" [disabled]="!isModifiable">
                <option *ngFor="let tpl of smsTemplates" [value]="tpl">{{ tpl }}</option>
            </select>
        </div>
        <div>
            <input type="checkbox" name="allowToSendSMS" [(ngModel)]="editee.SMS!.AllowToSendSMS" [disabled]="!isModifiable">
            User is allowed to send SMS messages
        </div>
        <div class="field" id="allowToSendSMSInt">
            <input type="checkbox" name="allowToSendSMSInt" [(ngModel)]="editee.SMS!.AllowToSendSMSInt" [disabled]="!isModifiable || editee.SMS!.AllowToSendSMS !== true">
            International
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideTemplate" [(ngModel)]="editee.SMS!.AllowToOverrideTemplate" [disabled]="!isModifiable">
            User is allowed to override template
        </div>
        <div class="fieldset" *ngIf="userViewOptions.TrafficSmsLimitationEnabled">
            <label><input type="checkbox" name="limitsSmsIsActive" [(ngModel)]="limits!.sms.isActive"> Limit to</label>&ngsp;
            <input type="number" style="width: 60px;" min="0" max="999999" name="limitsSmsAmount" [(ngModel)]="limits!.sms.amount" [disabled]="!isModifiable || limits!.sms.isActive !== true">
            &ngsp;messages per&ngsp;
            <input type="number" style="width: 90px;" min="1" max="999" name="limitsSmsPeriodDuration" [(ngModel)]="limits!.sms.period.duration" [disabled]="!isModifiable || limits!.sms.isActive !== true">
            &ngsp;
            <select name="limitsSmsPeriodUnit" [(ngModel)]="limits!.sms.period.unit" [disabled]="!isModifiable || limits!.sms.isActive !== true">
                <option *ngFor="let u of periodUnits" [ngValue]="u">{{ u.unit }}</option>
            </select>
        </div>
        <div class="fieldset">
            <label>Split large SMS content into at most</label>&ngsp;
            <input type="number" style="width: 60px;" min="1" max="255" name="userSMSMaxSplit" [(ngModel)]="editee.SMS!.MaxSplit" [disabled]="!isModifiable">&ngsp;
            <span>SMS messages</span>
        </div>
        <div class="fieldset">
            <label><input type="checkbox" name="userSMSSegmentation" [(ngModel)]="editee.SMS!.Segmentation" [disabled]="!isModifiable"> Mobile device supports SMS concatenation</label>
        </div>
    </div>
</ng-template>

<!-- Miscellaneous tab -->
<ng-template #page3>
    <div *ngIf="pageTabs[3].isReady">
        <div class="field">
            <label>Location:</label>
            <select name="countryISO2" [(ngModel)]="editee.CountryISO2" [disabled]="!isModifiable">
                <option *ngFor="let c of numSubstCountries" [value]="c.ISO2">{{ c.Name }}</option>
            </select>
        </div>
    </div>
</ng-template>

</form>
