import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

export interface IDialogPromptData {
    title: string;
    text?: string;
    retval: string;
}

@Component({
    selector: 'app-dialog-prompt',
    imports: [FormsModule, CommonModule],
    templateUrl: './dialog-prompt.component.html',
    styleUrl: './dialog-prompt.component.css'
})
export class DialogPromptComponent implements OnInit {

    title: string;
    text: string;
    retval: string;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IDialogPromptData
    ) {
        this.title = data.title;
        this.text = data.text?? '';
        this.retval = data.retval;
    }

    ngOnInit(): void {
        let elem = document.getElementById('fen_dialog_prompt_retval');
        if (elem) elem.focus();
    }

    submit(): void {
        this.dialogRef.close(this.retval);
    }

    cancel(): void {
        this.dialogRef.close(null);
    }
}
