import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComboboxComponent } from '../combobox/combobox.component';
import * as _ from 'underscore';

export interface IDateTimeFormatEditee {
    dateFormat: string;
    timeFormat: string;
    dateTimeSeparator: string;
}

export interface IDateTimeFormatScope {
    title: string;
    text: string;
    editee: IDateTimeFormatEditee;
    dateFormats?: string[];
    timeFormats?: string[];
}

interface IDateTimeSeparator {
    Char: string;
    Name: string;
}

@Component({
    selector: 'app-dialog-datetime-format',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    templateUrl: './dialog-datetime-format.component.html',
    styleUrl: './dialog-datetime-format.component.css'
})
export class DialogDatetimeFormatComponent {

    isReady: boolean = false;
    changed: boolean = false;
    scope: IDateTimeFormatScope;
    currentDateTime: Date = new Date();

    // defaults
    dateFormats = [
        'd-MM-yyyy',
        'dd-MM-yyyy',
        'M-dd-yyyy',
        'MM-dd-yyyy',
        'yyyy-MM-dd'
    ];
    timeFormats = [
        'H:mm',
        'HH:mm',
        'H:mm:ss',
        'HH:mm:ss',
        'h:mm tt',
        'hh:mm tt',
        'h:mm:ss tt',
        'hh:mm:ss tt'
    ];
    dateTimeSeparators: IDateTimeSeparator[] = [
        { Char: '',  Name: '<none>' },
        { Char: '@', Name: 'at sign' },
        { Char: ',', Name: 'comma' },
        { Char: '.', Name: 'dot' },
        { Char: '-', Name: 'hyphen' },
        { Char: ';', Name: 'semicolon' },
        { Char: ' ', Name: 'space' },
        { Char: '_', Name: 'underscore' }
    ];

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data as IDateTimeFormatScope;
        if (this.scope.dateFormats) {
            this.dateFormats = this.scope.dateFormats;
        }
        if (this.scope.timeFormats) {
            this.timeFormats = this.scope.timeFormats;
        }
        this.init();
    }

    init(): void {
        if (!_.contains(this.dateFormats, this.scope.editee.dateFormat)) {
            this.dateFormats.push(this.scope.editee.dateFormat);
        }
        if (!_.contains(this.timeFormats, this.scope.editee.timeFormat)) {
            this.timeFormats.push(this.scope.editee.timeFormat);
        }
        if (!_.find(this.dateTimeSeparators, item => {
            return (item.Char === this.scope.editee.dateTimeSeparator);
        })) {
            this.scope.editee.dateTimeSeparator = ' ';
        }
        this.isReady = true;
    }

    formatTime(tm: Date, fmt: string): string {
        let res = fmt;
        res = res.replace(/HH/g, (tm.getHours()).toString(10).padStart(2,"0"));
        res = res.replace(/H/g, (tm.getHours()).toString(10));
        res = res.replace(/hh/g, ((tm.getHours()%12)||12).toString(10).padStart(2,"0"));
        res = res.replace(/h/g, ((tm.getHours()%12)||12).toString(10));
        res = res.replace(/mm/g, (tm.getMinutes()).toString(10).padStart(2,"0"));
        res = res.replace(/m/g, (tm.getMinutes()).toString(10));
        res = res.replace(/ss/g, (tm.getSeconds()).toString(10).padStart(2,"0"));
        res = res.replace(/s/g, (tm.getSeconds()).toString(10));
        res = res.replace(/tt/g, (tm.getHours() < 12 ? "AM":"PM"));
        res = res.replace(/t/g, (tm.getHours() < 12 ? "A":"P"));
        return res; 
    }

    format_change(): void {
        this.changed = true;
    }

    submitEnabled(): boolean {
        return (this.isReady && this.changed);
    }

    submit(): void {
        this.dialogRef.close(this.scope.editee);
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
