<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="cancel()">&times;</button>
        <h1>{{ title }}</h1>
    </div>
    <form id="fen_dialog_prompt" class="form" novalidate (submit)="submit()">
        <div class="modal-body">
            <p>{{ text }}</p>
            <input type="text" name="retval" id="fen_dialog_prompt_retval" style="width: calc(100% - 15px);" [(ngModel)]="retval" required>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!retval">OK</button>
            <button type="button" class="btn" (click)="cancel()">Cancel</button>
        </div>
    </form>
</section>
