import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigRestResult, FaxConfigRestCSImageExtensions } from '../api/api';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import * as _ from 'underscore';

export interface ICoversheetDisclaimerEditee {
    coversheetFileExtensions: FaxConfigRestCSImageExtensions;
    coversheetAccept: string;
}

@Component({
    selector: 'app-coversheet-disclaimer',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-disclaimer.component.html',
    styleUrl: './coversheet-disclaimer.component.css'
})
export class CoversheetDisclaimerComponent {
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: ICoversheetDisclaimerEditee;
    isUploading: boolean = false;
    file: string = '';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetDisclaimerEditee;
    }

    private getFileExtension(filespec: string): string {
        if (filespec) {
            let idx = filespec.lastIndexOf('.');
            if (idx >= 0) {
                return filespec.substring(idx + 1);
            }
        }
        return '';
    }

    submit(): void {
        // Check file extension
        let ext = this.getFileExtension(this.file!).toLowerCase();
        if (_.contains(this.editee.coversheetFileExtensions, ext)) {
            let form = document.getElementById('choose_disclaimer') as HTMLFormElement;
            let action = this.session.portalCfg.prefix + 'Coversheet/Disclaimer' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FileName=' + encodeURIComponent(this.file) +
                            '&contentType=text%2Fplain';

            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            this.isUploading = true;
        } else {
            alert("File extension '." + ext + "' is not supported for Disclaimer page upload.");
        }
    }

    private handleError(err?: string | null) {
        alert(err || 'An unknown error occurred. Please try again later.');
        this.isUploading = false;
    }

    private afterUpload(res: FaxConfigRestResult): void {
        if (res.Status === 'ok') {
            this.close(true);
        } else if (res.Errors) {
            // Check for ERRBASE_FILE_CREATE, means it already exists
            if (res.Errors[0].Id == 0x201) {
                if (confirm('A disclaimer file already exists with that name. Overwrite it?')) {
                    // Try to overwrite
                    let form = document.getElementById('choose_disclaimer') as HTMLFormElement;
                    let action = this.session.portalCfg.prefix + 'Coversheet/Disclaimer' +
                                    '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                                    '&FileName=' + encodeURIComponent(this.file) +
                                    '&contentType=text%2Fplain&Overwrite=true';

                    this.fenUtils.handleUpload(form, action).subscribe({
                        next: res => {
                            if (this.fenUtils.afterSave(res) > 0) {
                                this.close(true);
                            } else {
                                // afterSave() has already displayed the error.
                                this.isUploading = false;
                            }
                        },
                        error: err => this.handleError(err)
                    });
                }
            } else {
                this.fenUtils.afterSave(res);
                this.isUploading = false;
            }
        }
    }

    close(ok: boolean): void {
        this.dialogRef.close(ok);
    }
}
