
/*The version_info may hold the following properties
 *
 * version   : The actual file version ; if not set, no version info will be included
 * description : File description  
 * comments : Comments
 * company_name: "Fenestrae B.V.", 
 * copyright: u"Copyrights \u00a9 2009", 
 * trademarks"  
 * name: "Fenestrae SMS Server with Communicator", 
 * product_version: The product version (if omitted, equal to the file version)
 */

const version_info = {
    "version"   : "12.0.211.0",
    "description" : "Fenestrae Admin portal", 
    "comments" : "", 
    "company_name": "Fenestrae B.V.", 
    "copyright": "Copyright \u00a9 2012-2025", 
    "trademarks": "", 
    "name": "Fenestrae Admin portal", 
    "product_version": "12.0.0.0"
}

module.exports = version_info;