import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestKernelMonitoringRuleMessageCheck, FaxConfigRestKernelMonitoringSenderBase,
    FaxConfigRestKernelMonitoringSendersList, FaxConfigRestKernelMonitoringTemplatesList, FaxConfigRestStringList
} from '../api/api';
import * as _ from 'underscore';

export interface IMonitoringMessageCheckScope {
    title: string;
    editee: FaxConfigRestKernelMonitoringRuleMessageCheck;
    senders: FaxConfigRestKernelMonitoringSendersList;
}

interface IState {
    activePageTab: string;
}

@Component({
    selector: 'app-monitoring-rule-messagecheck',
    imports: [FormsModule, CommonModule],
    templateUrl: './monitoring-rule-messagecheck.component.html',
    styleUrl: './monitoring-rule-messagecheck.component.css'
})
export class MonitoringRuleMessagecheckComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    isModifiable: boolean;
    editee: FaxConfigRestKernelMonitoringRuleMessageCheck;
    orgUsername: string;
    ToNumber: string = '';

    pageTabs: string[] = ['General', 'Service' , 'Message', 'Senders'];
    state: IState = { activePageTab: this.pageTabs[0] };
    Description: string = '';
    title: string = '';

    messageTypes: string[] = [];
    ruleTemplates: FaxConfigRestKernelMonitoringTemplatesList = [];
    monitoringSenders: FaxConfigRestKernelMonitoringSendersList = [];
    serverAddress: { [key: string]: string } = {};
    senderChecked: { [key:string]: boolean } = {};

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IMonitoringMessageCheckScope
    ) {
        this.title = data.title;
        this.editee = _.clone(data.editee);
        this.monitoringSenders = data.senders;
        this.pageTabClick(this.state.activePageTab);

        this.orgUsername = this.editee.Props?.Username?? '';
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
        this.serverAddress['EWS']  = 'https://<server>/ews/exchange.asmx';
        this.serverAddress['REST'] = 'http://localhost:18385/FaxRESTConn';
        this.serverAddress['WS']   = 'http://localhost/FenMessageDotNet3/FenMessage.asmx?wsdl';
        this.onChangeServerAddressType();

        _.each(this.monitoringSenders, sender => {
            const senders: FaxConfigRestStringList = this.editee.Senders?? [];
            this.senderChecked[sender.ID!] = senders.toString() === '*' || _.contains(senders, sender.ID);
        });
        this.faxSrv.monitoringTemplatesDefinitions().subscribe(monTemplates => {
            this.ruleTemplates = _.sortBy(monTemplates, 'Name');
        });
        this.faxSrv.messageTypes().subscribe(res => {
            this.messageTypes = res;
            this.isReady = true;
            if (this.fenUtils.listIndexOf(res, this.editee.Props?.MessageType?? '') >= 0) {
                this.ToNumber = this.editee.Props?.ToAddress?? '';
            } else {
                this.editee.Props!.MessageType = 'FAX'
                this.ToNumber = '';
            }
            if (this.editee.Props?.ServerAddressType === 'EWS') {
                const prefix: string = 'IMCEA' + this.editee.Props.MessageType + '-';
                if (this.ToNumber.indexOf(prefix) === 0) {
                    // IMCEAFAX-12345@domain.com
                    // 0123456789    |
                    //               14
                    let idx = this.ToNumber.indexOf('@');
                    if (idx > prefix.length) {
                        this.ToNumber = this.ToNumber.substring(prefix.length, idx);
                    }
                }
                this.serverAddress['EWS'] = this.editee.Props.ServerAddress?? '';
            }
            else if (this.editee.Props?.ServerAddressType === 'REST' && this.editee.Props?.ServerAddress)
            {
                this.serverAddress['REST'] = this.editee.Props.ServerAddress;
            }
            else if (this.editee.Props?.ServerAddressType === 'WS' && this.editee.Props.ServerAddress)
            {
                this.serverAddress['WS'] = this.editee.Props.ServerAddress;
            }
        });
    }

    pageTabClick(pageTab: string): void {
        this.state.activePageTab = pageTab;
        this.Description = 'Click or hover over an option to view its description.';
    }

    listCheckboxHandler(chk: boolean, sender: FaxConfigRestKernelMonitoringSenderBase): void {
        const allSenders: string[] = _.pluck<FaxConfigRestKernelMonitoringSendersList,string>(this.monitoringSenders, 'ID');
        const senders: FaxConfigRestStringList = this.editee.Senders?? [];
        if (chk) {
            if (senders.toString() !== '*') {
                this.editee.Senders = _.union(senders, [sender.ID!]);
            }
        } else {
            if (senders.toString() === '*') {
                this.editee.Senders = _.without(allSenders, sender.ID!);
            } else {
                this.editee.Senders = _.without(senders, sender.ID!);
            }
        }
        if (_.every(_.values(this.senderChecked), item => { return item; })) {
            this.editee.Senders = ['*'];
        }
    }

    submitEnabled(): boolean {
        return true;
    }

    onChangeServerAddressType(): void {
        if (this.editee.Props?.ServerAddressType === 'EWS') {
            if (this.pageTabs[2] !== 'Authentication') this.pageTabs.splice(2, 0, 'Authentication');
        } else {
            if (this.pageTabs[2] === 'Authentication') this.pageTabs.splice(2, 1);
        }
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let idx: number;
        let def: FaxConfigRestKernelMonitoringRuleMessageCheck = _.clone(this.editee);
        def.Props = _.extend({}, this.editee.Props?? {});
        if (!this.ToNumber) {
            if (def.Props!.ServerAddressType === 'EWS') {
                alert('The ' + this.editee.Props!.MessageType + ' Address field cannot be empty.');
            } else {
                alert('The ' + this.editee.Props!.MessageType + ' Number field cannot be empty.');
            }
            return null;
        }
        def.Props!.ServerAddress = this.serverAddress[this.editee.Props?.ServerAddressType!];
        if (this.title !== 'New' && this.editee.Props?.Password) {
            def.Props!.Password = null;
        }
        if (def.Props!.ServerAddressType === 'EWS') {
            // Get the from name from the username (email address)
            if (def.Props!.Username) {
                idx = def.Props!.Username.indexOf('@');
            } else {
                idx = 0;
            }
            if (idx < 1) {
                alert('The Username is not a valid email address.');
                return null;
            }
            def.Props!.FromName = this.editee.Props?.FromName || (def.Props!.Username?? '').substring(0, idx);
            def.Props!.FromAddress = this.editee.Props?.FromAddress;
            def.Props!.FromAddressType = 'SMTP';
            // If necessary convert the destination to a valid email address
            def.Props!.ToAddress = this.ToNumber;
            if (def.Props!.ToAddress.indexOf('@') < 1) {
                def.Props!.ToAddress = 'IMCEA' + def.Props!.MessageType
                    + '-' + def.Props!.ToAddress + (def.Props!.Username?? '').substring(idx);
            }
            def.Props!.ToAddressType = 'SMTP';
        } else {
            def.Props!.FromName = this.editee.Props?.FromName;
            def.Props!.FromAddress = this.editee.Props?.FromAddress;
            def.Props!.FromAddressType = this.editee.Props?.FromAddressType || 'SMTP';
            def.Props!.SystemAddress = this.editee.Props?.SystemAddress;
            if (def.Props!.FromAddress) {
                idx = def.Props!.FromAddress.indexOf('@');
            } else {
                idx = 0;
            }
            if (idx < 1) {
                alert('The From address is not a valid email address.');
                return null;
            }
            def.Props!.ToAddress = this.ToNumber;
            idx = def.Props!.ToAddress.indexOf('@');
            if (idx < 1) {
                def.Props!.ToName = this.editee.Props?.ToName;
                def.Props!.ToAddressType = def.Props!.MessageType;
            } else {
                def.Props!.ToAddressType = 'SMTP';
                def.Props!.ToName = def.Props!.ToAddress.substring(0, idx);
            }
        }
        if (def.Props?.MessageType && def.Props.MessageType.toUpperCase() !== 'FAX') {
            // This property is only valid for FAX messages
            delete def.Props.GetInbound;
        }
        return this.faxSrv.updateMonitoringRule(def);
    }

    save(): void {
        let promise: Observable<FaxConfigRestResult> | null = this.doSave();
        if (promise) {
            promise.subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close(true);
                }
            });
        }
    }

    apply(): void {
        let promise: Observable<FaxConfigRestResult> | null = this.doSave();
        if (promise) {
            promise.subscribe(res => {
                this.fenUtils.afterSave(res);
            });
        }
    }

    focusin(event: any): void {
        this.Description = event.currentTarget.title;
    }

    mirror_username(event: any): void {
        if (this.editee.Props?.ServerAddressType === 'EWS' && this.orgUsername !== this.editee.Props.Username) {
            // when using EWS, the from-address is (probably) equal to the username
            // copy the username into the from address when it has changed 
            let idx: number;
            this.orgUsername = this.editee.Props.Username?? '';
            this.editee.Props.FromAddress = this.editee.Props.Username;
            if (this.editee.Props.Username) {
                idx = this.editee.Props.Username.indexOf('@');
                if (idx < 0) { idx = this.editee.Props.Username.length; }
                this.editee.Props.FromName = this.editee.Props.Username.substring(0, idx);
            }
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
