<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close(false)">&times;</button>
        <h1>Upload Disclaimer Page</h1>
    </div>
    <form class="form" name="uploadForm" #uploadForm="ngForm" id="choose_disclaimer" novalidate (submit)="submit()">
        <div class="modal-body">
            <p>Upload an image or document file to the server. This will be converted to a TIFF image, which can then be selected as the disclaimer page for one or more coversheets.</p>
            <div class="field">
                <span><i class="glyphicon glyphicon-picture"></i></span>
                <label>Disclaimer page:</label>
                <input type="file" id="file" name="file" [(ngModel)]="file" accept="{{ editee.coversheetAccept }}" required>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="!isUploading" class="btn btn-primary" type="submit" [disabled]="!uploadForm.valid">Upload</button>
            <span *ngIf="isUploading">Uploading ...</span>
        </div>
    </form>
</section>
