<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1 *ngIf="!editee.IsNew">Edit AD Group '{{ editee.OrigADGroup }}'</h1>
        <h1 *ngIf="editee.IsNew">Add Active Directory Group</h1>
    </div>
    <form id="group_edit_form" #accountGroupEditForm="ngForm" class="form" novalidate (submit)="submit()">
        <div class="modal-body">
            <p>Enter the name of the Active Directory group that you want to have access to the Faxination Management Portal,
            and optionally a description of the group.</p><br>

            <div class="field">
                <label>AD Group:</label>
                <input type="text" maxlength="100" name="ADGroup" [(ngModel)]="editee.ADGroup" size="40" required
                title="Enter the name of the Active Directory group">
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" maxlength="100" name="Description" [(ngModel)]="editee.Description" size="40"
                title="Enter an optional Description for this group">
            </div>

            <br>
            <div class="field">
                All members of this group will have the following role:
            </div>
            <div class="field-indented">
                @for (role of editee.AllowedRoles; track role) {
                    <input type="radio" name="role" [(ngModel)]="editee.Role" id="role_{{ role }}" [value]="role" [attr.value]="role">
                    <label for="role_{{ role }}" style="margin-bottom: 0;">{{ role }}</label><br>
                }
            </div>
            <br>
            <p>The Global Administrator role has full access to all areas of the Faxination Management Portal.
            The Service Administrator role has full access to the License, Services, Coversheets, Log, Queue, Users, and Monitoring Settings.
            The Administrator role has full access to the Coversheets, Queue, and the Users and can view the Log.
            The Monitor role has read-only access to the Coversheets, Log, Queue, and the Users.</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!accountGroupEditForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
