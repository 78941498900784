<h1>Connector Contracts</h1>

<div id="contracts_help">
    <p>This page shows the connectors with contracts on the platform.</p>
    <p>A contract represents an agreement between the Kernel and a Connector and is required in order for the connector to communicate with Faxination Server. 
        It describes security settings, type of connector installed, and how the Kernel can contact the connector in case there is a job. 
        The Name and Machine must be equal to the corresponding fields in the Kernel contract configured on the connector.
    </p>
    <p *ngIf="contractsModifiable">To edit a contract, double-click the row of the contract to edit. To delete one or more contracts, select the contract(s) and press the remove button.</p>
</div>
<div class="btn-toolbar">
    <button type="button" class="btn" *ngIf="contractsModifiable" (click)="add_contract()">Add</button>&ngsp;
    <button type="button" class="btn" *ngIf="contractsModifiable" (click)="delete_contracts()" [disabled]="!canDelete">Remove</button>&ngsp;
    <button type="button" class="btn btn-link" [disabled]="!exportEnabled" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
</div>

<div *ngIf="contractsGridInitialized" id="contractsGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="contractsGridOptions"></ag-grid-angular>
</div>
