<h1>Queue</h1>

<div>
    <ul class="nav nav-tabs" *ngIf="pageTabs.length > 1">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === activePageTab">
            <a href="" (click)="switchTab(pageTab);false">{{ pageTab }}</a>
        </li>
    </ul>
    <div [hidden]="activePageTab !== 'Jobs'">
        <ng-container *ngTemplateOutlet="queue_jobs"></ng-container>
    </div>
    <div [hidden]="!isReady || activePageTab !== 'Status'">
        <ng-container *ngTemplateOutlet="queue_status"></ng-container>
    </div>
</div>

<!-- Jobs tab -->
<ng-template #queue_jobs>
    <div class="pull-right" style="margin-left:50px;">
        Refresh every&nbsp;
        <input type="text" style="width:40px" [(ngModel)]="queueOptions.queueRefreshEvery" (ngModelChange)="refresh_change()" fen-uint-only>
        seconds
    </div>
    <p style="overflow:hidden;">View your active queue.
    <span *ngIf="auth.isModifiable('Queue')"> You can also select a job to pause/continue or delete it.
	    If a job is paused and/or scheduled for a future time, then continuing it will change its SubmissionTime to the current time.
        See the properties of the jobs for more information.
        In some cases, for example when a job is in process by a fax device,
        you cannot delete the job until the job is released by the device.
    </span></p>

    <div class="btn-toolbar" *ngIf="auth.isModifiable('Queue')">
        <div class="btn-group">
            <button type="button" class="btn" (click)="pause()" [disabled]="!canPause">Pause</button>
            <button type="button" class="btn" (click)="kontinue()" [disabled]="!canContinue">Continue</button>
            <button type="button" class="btn" (click)="reroute_ask()" [disabled]="!canReroute">Re-route</button>
        </div>
        <button type="button" class="btn" id="queue_priority_select" [disabled]="!canPrioritize">
            Priority&nbsp;<i class="glyphicon glyphicon-chevron-down"></i>
            <ul *ngIf="canPrioritize" id="queue-priority-dropdown" class="dropdown-menu">
                <li (click)="prioritize('High')"><span [ngStyle]="priorityStyle(1)">&#x2714;</span>&nbsp;High</li>
                <li (click)="prioritize('Normal')"><span [ngStyle]="priorityStyle(2)">&#x2714;</span>&nbsp;Normal</li>
                <li (click)="prioritize('Low')"><span [ngStyle]="priorityStyle(4)">&#x2714;</span>&nbsp;Low</li>
            </ul>
        </button>
        <button type="button" class="btn" (click)="del()" [disabled]="!canDelete">Delete</button>
    </div>
    <div class="fen_ag_grid_align" *ngIf="!auth.isModifiable('Queue')"></div>
    <div *ngIf="queueGridInitialized" id="queueGrid" class="ag-theme-alpine">
        <ag-grid-angular [style]="cssGridHeight" [gridOptions]="queueGridOptions"></ag-grid-angular>
    </div>
</ng-template>

<!-- Status tab -->
<ng-template #queue_status>
    <p>Display the overall status of the queue.</p>

    <div class="text pull-right">
        Refresh every&nbsp;
        <input type="text" style="width:40px" [(ngModel)]="queueOptions.queueRefreshEvery" (change)="refresh_change()" fen-uint-only>
        seconds
    </div>

    <table class="table table-condensed " >
        <thead>
            <tr>
                <th></th>
                <th>In Process</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let statistic of validCounterData()">
                <td>{{ statistic.label }}</td>
                <td>{{ CounterData(statistic.key).InProcess }} </td>
                <td>{{ CounterData(statistic.key).Total }} </td>
            </tr>
        </tbody>
    </table>
</ng-template>
