import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Constants } from './constants/app.constants';
import { AuthService } from './services/auth.service';

export const AuthGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const token = auth.getAuthToken();
  if (token) {
    return true;
  } else {
    return router.parseUrl('/login');
  }
};

export const UnauthGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const token = auth.getAuthToken();
  if (token) {
    return router.parseUrl('/overview');
  } else {
    return true;
  }
};