<section id="monitoring_rule_messagecheck" class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="isModifiable">{{ title }} </span>{{editee.__type}} Rule</h1>
    </div>

    <form class="form" id="messagecheck_form" (submit)="save()" novalidate>
        <div class="modal-body" *ngIf="isReady">
            <p>This rule periodically sends an outbound test message ({{ messageTypes.join('/') }}) from Faxination using a selected host, and optionally waits for a result.</p>
            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="pageTabClick(pageTab);false">{{ pageTab }}</a>
                </li>
            </ul>
            <form *ngIf="state.activePageTab === 'General'" class="form" novalidate>
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </form>
            <form *ngIf="state.activePageTab === 'Service'" class="form" novalidate>
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </form>
            <form *ngIf="state.activePageTab === 'Message'" class="form" novalidate>
                <ng-container *ngTemplateOutlet="page3"></ng-container>
            </form>
            <form *ngIf="state.activePageTab === 'Senders'" class="form" novalidate>
                <ng-container *ngTemplateOutlet="page4"></ng-container>
            </form>
            <form *ngIf="state.activePageTab === 'Authentication'" class="form" novalidate>
                <ng-container *ngTemplateOutlet="page5"></ng-container>
            </form>
        </div>
        <div class="monitoring_footer">
            <strong>Description</strong>
            <div id="monitoring_description" class="well">{{ Description }}</div>
            <div class="buttons">
                <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
                <button *ngIf="isModifiable" type="button" class="btn" [disabled]="!submitEnabled()" (click)="apply()">Apply</button>
                <button type="button" class="btn" (click)="close()">Close</button>
            </div>
        </div>
    </form>
</section>

<!-- General tab -->
<ng-template #page1>
    <p style="margin-bottom: 20px;">General options for this rule.</p>
    <div class="field">
        <label>Enabled:</label>
        <input type="checkbox" name="Enabled" [(ngModel)]="editee.Enabled" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
        title="Enable or disable the message check rule.">
    </div>
    <div class="field">
        <label>Display Name:</label>
        <input type="text" size="40" name="DisplayName" [(ngModel)]="editee.DisplayName" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
        title="Enter a descriptive friendly name for this Rule definition.">
    </div>
    <div class="field">
        <label>Template:</label>
        <select name="Template" [(ngModel)]="editee.Template" [disabled]="!isModifiable" tabindex="3" (focusin)="focusin($event)"
            title="In case an SMTP sender is triggered by this rule, choose an email template for the notification that is sent.">
            <option *ngFor="let def of ruleTemplates" [value]="def.Name">{{ def.Name }}</option>
        </select>
    </div>
    <div class="field">
        <label>Check every</label>
        <input type="number" style="width: 100px;" min="1" max="1000000" name="Interval" [(ngModel)]="editee.Interval" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
        title="Enter how often (in minutes) to send a test message.">&nbsp;minutes
    </div>
    <div class="field">
        <label>Notify on success</label>
        <input type="checkbox" name="SendOnSuccess" [(ngModel)]="editee.SendOnSuccess" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="5"
        title="If enabled, the Sender(s) are triggered after every message check completes.  Otherwise, the Sender(s) are triggered only if the message check is unsuccessful.">
    </div>
</ng-template>

<!-- Service tab -->
<ng-template #page2>
    <p style="margin-bottom: 20px;">Configure the type of test message to send and the web service or host to use.<br/>
        Please note that certain settings are related to details entered on the Message tab.</p>

    <div class="field">
        <label>Service Type:</label>
        <select name="ServerAddressType" [(ngModel)]="editee.Props!.ServerAddressType" (ngModelChange)="onChangeServerAddressType()" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
        title="Select the type of service that is listening at the specified Service address.">
            <option value="EWS">Microsoft Exchange Web Services</option>
            <option value="REST">Faxination REST Connector</option>
            <option value="WS">Faxination Web Service Connector</option>
        </select>
    </div>
    <div class="field">
        <label>Service Address:</label>
        <input type="text" style="width: 600px;" name="ServiceAddress" [(ngModel)]="serverAddress[editee.Props!.ServerAddressType!]" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
        title="Enter the web service or REST address of the service to use for sending/receiving test messages.  This can be blank for Microsoft Exchange Web Services, in which case Faxination will attempt to Autodiscover the address.">
    </div>
    <div class="field">
        <label>Username:</label>
        <input type="text" style="width: 600px;" name="Username" [(ngModel)]="editee.Props!.Username" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" (focusout)="mirror_username($event)" tabindex="3"
        title="Enter the user name of an account with permission to send and receive messages via the specified service address.  For Microsoft Exchange Web Services, this must be a valid email address.">
    </div>
    <div class="field">
        <label>Password:</label>
        <input type="password" autocomplete="off" style="width: 300px;" name="Password" [(ngModel)]="editee.Props!.Password" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
        title="Enter the password of the user account.">
    </div>
    <div class="field">
        <label>System Address:</label>
        <input type="text" style="width: 300px;" name="SystemAddress" [(ngModel)]="editee.Props!.SystemAddress" [disabled]="!isModifiable || editee.Props?.ServerAddressType === 'EWS'" (focusin)="focusin($event)" tabindex="6"
        title="Enter the System Address">
    </div>
</ng-template>

<!-- Message tab -->
<ng-template #page3>
    <p style="margin-bottom: 20px;">Configure the addressing and the type of test message to send.<br/>
        Please note that certain settings are related to details entered on the Service tab.</p>
    <h4>From</h4>
    <div class="field">
        <label>Name:</label>
        <input type="text" style="width: 300px;" name="FromName" [(ngModel)]="editee.Props!.FromName" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
        title="Enter the name of the sender.">
    </div>
    <div class="field">
        <label>Address:</label>
        <input type="text" style="width: 300px;" name="FromAddress" [(ngModel)]="editee.Props!.FromAddress" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
        title="Enter the from-address. This address will be used as sender of the monitoring message. Depending on the Service being used, this may be restricted to a specific address.">
    </div>
    <h4>To</h4>
    <div class="field">
        <label>Message Type:</label>
        <select name="MessageType" [(ngModel)]="editee.Props!.MessageType" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="3"
            title="Select the type of test message to send when performing the message check.">
            <option *ngFor="let mt of messageTypes">{{ mt }}</option>
        </select>
    </div>
    <div class="field" *ngIf="editee.Props?.ServerAddressType === 'EWS'">
        <label *ngIf="editee.Props?.MessageType === 'FAX'">Fax Address:</label>
        <label *ngIf="editee.Props?.MessageType !== 'FAX'">{{ editee.Props?.MessageType }} Address:</label>
        <input type="text" style="width: 300px;" name="ToNumber" [(ngModel)]="ToNumber" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
        title="Enter the destination telephone number to send the test message to.  This can also be a virtual SMTP address like '1234567@fax.sys' to use the Fenestrae SMTP Stack.">
    </div>
    <div class="field" *ngIf="editee.Props?.ServerAddressType !== 'EWS'">
        <label>{{ editee.Props?.MessageType }} Number</label>
        <input type="text" style="width: 300px;" name="ToNumber" [(ngModel)]="ToNumber" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
        title="Enter the destination telephone number to send the test message to.">
    </div>
    <h4>Handling</h4>
    <div class="field">
        <label>Wait for report</label>
        <input type="checkbox" name="GetNotification" [(ngModel)]="editee.Props!.GetNotification" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="5"
        title="If enabled, wait for a delivery report to be received after the test message is sent.">
    </div>
    <div class="field" *ngIf="editee.Props?.MessageType === 'FAX' && editee.Props?.ServerAddressType === 'EWS'">
        <label>Wait for inbound</label>
        <input type="checkbox" name="GetInbound" [(ngModel)]="editee.Props!.GetInbound" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="6"
        title="If enabled, wait for a test fax to be received as an inbound fax.  You must have a device that will bounce the fax, and an inbound routing that delivers the fax to the same Exchange mailbox.">
    </div>
    <div class="field" *ngIf="editee.Props?.MessageType === 'FAX' && editee.Props?.ServerAddressType !== 'EWS'">
        <label>Wait for inbound</label>
        <input type="checkbox" name="GetInbound" [(ngModel)]="editee.Props!.GetInbound" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="7"
        title="If enabled, wait for a test fax to be received as an inbound fax.  You must have a device that will bounce the fax, and the incoming fax must be routed in such a way that it will stay in the Queue and not be picked up by a host connector.">
    </div>
    <div class="field" *ngIf="editee.Props?.GetNotification || (editee.Props?.GetInbound && editee.Props?.MessageType === 'FAX')">
        <label>Timeout:</label>
        <input type="number" style="width: 100px;" min="1" max="1000000" name="Timeout" [(ngModel)]="editee.Props!.Timeout" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="8"
        title="Enter the maximum amount of time (in seconds) to wait for a report and/or inbound fax.  If either is not received within the specified time, the message check is considered unsuccessful.">&nbsp;seconds
    </div>
</ng-template>

<!-- Senders tab -->
<ng-template #page4>
    <p style="margin-bottom: 20px;">All Senders that are checked will be activated when the message check completes.</p>
    <table id="monitoring_senders" class="table table-condensed table-bordered">
        <thead>
            <tr>
                <th width="25px"></th>
                <th>Display Name</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sender of monitoringSenders; let idx = index;" (focusin)="focusin($event)" (click)="focusin($event)"
                    title="If checked, use this Sender to deliver the message check result.">
                <td><input type="checkbox" [name]="'Sender'+idx" [(ngModel)]="senderChecked[sender.ID!]" [disabled]="!isModifiable"
                    (ngModelChange)="listCheckboxHandler(senderChecked[sender.ID!], sender)" tabindex="15"></td>
                <td>{{ sender.DisplayName }}</td>
                <td>{{ sender.__type }} Sender</td>
            </tr>
        </tbody>
    </table>
</ng-template>

<!-- Authentication tab -->
<ng-template #page5>
    <p style="margin-bottom: 10px;">Configure the type of authentication to use for Exchange Web Services.</p>
    <div style="margin-left: 120px">
        <input type="radio" name="AuthMode" [(ngModel)]="editee.Props!.AuthenticationMode" id="AuthMode_Basic" value="Basic" [disabled]="!isModifiable"
            (focusin)="focusin($event)" title="Basic authentication using the account credentials configured on the 'Service' tab.">
        <label for="AuthMode_Basic"><b>Basic</b></label>
    </div>
    <div style="margin-left: 120px; margin-bottom: 10px;">
        <input type="radio" name="AuthMode" [(ngModel)]="editee.Props!.AuthenticationMode" id="AuthMode_OAuth2" value="OAuth2" [disabled]="!isModifiable"
            (focusin)="focusin($event)" title="OAuth2 authentication using an app registration created in Azure Active Directory.">
        <label for="AuthMode_OAuth2"><b>OAuth2</b></label>
    </div>

    <div class="field">
        <label [class.disabled]="this.editee.Props?.AuthenticationMode !== 'OAuth2'">Tenant:</label>
        <input type="text" style="width: 600px;" name="Tenant" [(ngModel)]="editee.Props!.OAuth2_Tenant" [disabled]="!isModifiable || editee.Props?.AuthenticationMode !== 'OAuth2'" (focusin)="focusin($event)"
        title="The Directory (tenant) ID for OAuth2 authentication, copied from the app registration in Azure. The value can be a tenant name like 'mycompany.com' or a GUID like: '355721f6-d3d7-4918-8111-5f2ebe37f1ed'.">
    </div>
    <div class="field">
        <label [class.disabled]="this.editee.Props?.AuthenticationMode !== 'OAuth2'">Client ID:</label>
        <input type="text" style="width: 600px;" name="ClientId" [(ngModel)]="editee.Props!.OAuth2_ClientId" [disabled]="!isModifiable || editee.Props?.AuthenticationMode !== 'OAuth2'" (focusin)="focusin($event)"
        title="The Application (client) ID for OAuth2 authentication, copied from the app registration in Azure. The value should be a GUID like: '22c362d3-a363-4960-81b8-6f1ccf05bf33'.">
    </div>
    <div class="field">
        <label [class.disabled]="this.editee.Props?.AuthenticationMode !== 'OAuth2'">Client Secret:</label>
        <input type="text" autocomplete="off" disabled="disabled" style="display:none">
        <input type="password" autocomplete="off" style="width: 600px;" name="ClientSecret" [(ngModel)]="editee.Props!.OAuth2_ClientSecret" [disabled]="!isModifiable || editee.Props?.AuthenticationMode !== 'OAuth2'" (focusin)="focusin($event)"
        title="The Client secret for OAuth2 authentication, copied from the app registration in Azure. The value should be a password like: 'pLG8Q~W7xBo_Yen6GVHYXplG2Db1UPhHwRNXwcI4'.">
    </div>
    <div class="field">
        <label [class.disabled]="this.editee.Props?.AuthenticationMode !== 'OAuth2'">Exchange Impersonation:</label>
        <input type="text" style="width: 600px;" name="ExchangeImpersonation" [(ngModel)]="editee.Props!.OAuth2_ExchangeImpersonation" [disabled]="!isModifiable || editee.Props?.AuthenticationMode !== 'OAuth2'" (focusin)="focusin($event)"
        title="The Exchange account to impersonate when accessing the mailbox. The value should be a primary email address like 'user@company.com'.">
    </div>
</ng-template>
