import { CommonModule, DatePipe } from '@angular/common';
import { Observable, Subscription, forkJoin, timer } from 'rxjs';
import { Component, inject, HostListener, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestStringList, FaxConfigRestQueueGeneral,
    FaxConfigRestQueueCounts, FaxConfigRestCountDetails, FaxConfigRestJob, FaxConfigRestJobList
} from '../api/api';
import { FenUintOnlyDirective } from '../fen-uint-only.directive';
import { DialogService } from '../dialog/dialog.service';
import { QueueJobComponent } from '../queue-job/queue-job.component';
import { QueueJobRerouteComponent, IJobRerouteScope } from '../queue-job-reroute/queue-job-reroute.component';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColDef, ColumnState, IRowNode, GridOptions, GridReadyEvent, RowDoubleClickedEvent,
    ITooltipParams, GetContextMenuItemsParams, GetRowIdParams, MenuItemDef, PaginationChangedEvent,
    ValueFormatterParams, IServerSideDatasource, LoadSuccessParams, FilterChangedEvent
} from 'ag-grid-community';

interface ICounterOptions {
    key: keyof FaxConfigRestQueueCounts,
    label: string
}

@Component({
    selector: 'app-queue',
    imports: [FormsModule, CommonModule, AgGridAngular, FenUintOnlyDirective],
    providers: [DatePipe],
    templateUrl: './queue.component.html',
    styleUrl: './queue.component.css'
})
export class QueueComponent implements OnDestroy {

    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    cssGridHeight = { 'height': '300px' };
    queueGridApi?: GridApi;

    queue: string = 'Online';
    timer: Subscription | null = null;
    refreshKey: string = '';
    refreshVal: number = 30;
    queueTabKey: string = '';
    queueOptions = { queueRefreshEvery: '' };

    canPause: boolean = false;
    canContinue: boolean = false;
    canReroute: boolean = false;
    canPrioritize: boolean = false;
    canDelete: boolean = false;

    messageTypes: FaxConfigRestStringList = [];
    queueGeneral: FaxConfigRestQueueGeneral = {};
    queueGridOptions?: GridOptions;
    queueGridInitialized: boolean = false;

    pageTabs: string[] = ['Jobs', 'Status']
    activePageTab: string = this.pageTabs[0];
    dateFormat: string = 'yyyy-MM-dd HH:mm:ss';
    
    counterSelection: string = 'Total';
    statusCounterData?: FaxConfigRestQueueCounts;
    counterOptions: ICounterOptions[] = [
        { key: 'Total'                , label: 'Total' },
        { key: 'Outbound'             , label: '- Outbound' },
        { key: 'Inbound'              , label: '- Inbound' },
        { key: 'Conversion'           , label: 'Conversion' },
        { key: 'Paused'               , label: 'Jobs on hold' },
        { key: 'ReadyForHost'         , label: 'Ready for internal delivery' },
        { key: 'ReadyForFaxDevice'    , label: 'Ready for transmitting Fax' },
        { key: 'ReadyForSDXDevice'    , label: 'Ready for transmitting SDX' },
        { key: 'ReadyForMobileDevice' , label: 'Ready for transmitting SMS' }
    ];

    priorityOptions: string[] = [ 'High', 'Normal', 'Low' ];
    priority = { Selection: this.priorityOptions[1] };
    priorityMask: number = 0;
    priorityStyle(bit: number) {
        return (this.priorityMask & bit)? { color:'black' }: { color:'transparent' };
    }

    constructor (public auth: AuthService, public session: Session, private datePipe: DatePipe, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    private queueDateFormatter(params: ValueFormatterParams | ITooltipParams) {
        return params.value? this.datePipe.transform(params.value, this.dateFormat)?? '': '';
    }

    private queueRecipientFormatter(params: ValueFormatterParams | ITooltipParams) {
        return params.value? (params.value.Name || params.value.Address || ''): '';
    }

    private queueStatusFormatter(params: ValueFormatterParams | ITooltipParams) {
        return params.data? (params.data.StatusText || ''): '';
    }

    private queueColDefs: ColDef[] = [
        {
            headerTooltip: 'Job Number',
            field: 'JobNumber',
            tooltipField: 'JobNumber',
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: [
                {
                    displayKey: 'relatedjobs',
                    displayName: 'Find all jobs related to an ID',
                    predicate: () => { return true; },
                    numberOfInputs: 1
                },
                {
                    displayKey: 'parentjobonly',
                    displayName: 'Find a single job by ID',
                    predicate: () => { return true; },
                    numberOfInputs: 1
                }],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            headerName: 'Date',
            headerTooltip: 'Creation Date and Time',
            field: 'CreationTime',
            tooltipValueGetter: (params: ITooltipParams) => this.queueDateFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueDateFormatter(params)
        },
        {
            headerName: 'Submission Date',
            headerTooltip: 'Submission Date and Time',
            field: 'SubmissionTime',
            tooltipValueGetter: (params: ITooltipParams) => this.queueDateFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueDateFormatter(params),
            hide: true
        },
        {
            headerName: 'Completion Date',
            headerTooltip: 'Completion Date and Time',
            field: 'CompletionTime',
            tooltipValueGetter: (params: ITooltipParams) => this.queueDateFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueDateFormatter(params),
            hide: true
        },
        {
            headerTooltip: 'From Name or Address',
            field: 'From',
            tooltipValueGetter: (params: ITooltipParams) => this.queueRecipientFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueRecipientFormatter(params)
        },
        {
            headerTooltip: 'To Name or Address',
            field: 'To',
            tooltipValueGetter: (params: ITooltipParams) => this.queueRecipientFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueRecipientFormatter(params)
        },
        {
            headerName: 'Organization',
            headerTooltip: 'Organization',
            field: 'OrganizationName',
            tooltipField: 'OrganizationName',
            hide: true,
            filter: null,
            filterParams: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            headerTooltip: 'Direction',
            field: 'Direction',
            tooltipField: 'Direction',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['empty',
                {
                    displayKey: 'inbound',
                    displayName: 'Inbound',
                    predicate: () => { return true; },
                    numberOfInputs: 0
                },
                {
                    displayKey: 'outbound',
                    displayName: 'Outbound',
                    predicate: () => { return true; },
                    numberOfInputs: 0
                }],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            headerName: 'Type',
            headerTooltip: 'Message Type',
            field: 'MessageType',
            tooltipField: 'MessageType',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['empty'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'Priority',
            headerTooltip: 'Priority',
            hide: true
        },
        {
            field: 'Status',
            headerTooltip: 'Status',
            tooltipValueGetter: (params: ITooltipParams) => this.queueStatusFormatter(params),
            valueFormatter: (params: ValueFormatterParams) => this.queueStatusFormatter(params)
        },
        {
            headerTooltip: 'Charge Code',
            field: 'ChargeCode',
            tooltipField: 'ChargeCode',
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['equals'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
    ];

    ngOnDestroy() {
        this.timer?.unsubscribe();
        if (this.queueTabKey) {
            localStorage.setItem(this.queueTabKey, this.activePageTab);
        }
    }

    private init(): void {
        let user: string | null = this.auth.getUserName();
        if (user) {
            this.refreshKey = user.toLowerCase() + 'RefreshInterval';
            this.queueTabKey = user.toLowerCase() + 'QueueLastTab';
            let val: string | null = localStorage.getItem(this.queueTabKey);
            if (val && this.pageTabs.includes(val)) { this.activePageTab = val; }
        }

        this.queueGridOptions = {
            columnDefs: this.queueColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: false,
                minWidth: 80,
                cellClassRules: {
                    'job_marked_for_deletion': params => this.isMarkedForDeletion(params.data)
                },
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                filter: null,
                filterParams: null
            },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: true,
            suppressExcelExport: true,
            suppressMultiSort: true,
            onGridReady: (params: GridReadyEvent) => {
                this.queueGridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
                this.queueLoadViewState(params.api);
                params.api.setGridOption('serverSideDatasource', this.queueDataSource);
                this.queueLoadRefreshInterval();
                this.timer = timer(this.refreshVal * 1000).subscribe(() => this.refreshView());
                this.getQueueStatistics();
                this.isReady = true;
            },
            getRowId: params => this.queueGetRowId(params),
            onCellDoubleClicked: params => this.queueOnCellDoubleClicked(params),
            onSelectionChanged: params => this.queueOnSelectionChanged(params.api),
            onStoreRefreshed: params => this.queueOnSelectionChanged(params.api),
            onPaginationChanged: params => this.queueOnPaginationChanged(params),
            onFilterChanged: params => this.queueOnFilterChanged(params),
            getContextMenuItems: params => this.queueGetContextMenuItems(params),
            onGridPreDestroyed: params =>this.queueSaveViewState(params.api)
        };

        this.faxSrv.queueGeneral().subscribe(res => {
            this.queueGeneral = res;
        });

        this.faxSrv.messageTypes().subscribe(res => {
            this.messageTypes = res;
            this.initColumnDefinitions();
            // Now we are ready to show the grid.
            this.queueGridInitialized = true;
        });
    }

    private initColumnDefinitions(): void {
        // Show/hide the organization column based on context.
        let col: ColDef | undefined;
        col = this.queueColDefs.find(item => {
            return item.field === 'OrganizationName';
        });
        if (col) {
            col.hide = !this.session.contextAllOrganizations();
        }
        // Set message type filter parameters.
        col = this.queueColDefs.find(item => {
            return (item.field === 'MessageType');
        });
        if (col) {
            _.each(this.messageTypes, item => {
                col!.filterParams.filterOptions.push({
                    displayKey: item.toLowerCase(),
                    displayName: item,
                    predicate: () => { return true; },
                    numberOfInputs: 0
                });
            });
        }
    }

    private queueLoadRefreshInterval(): void {
        let num: number = 0;
        if (this.refreshKey) {
            let val: string | null = localStorage.getItem(this.refreshKey);
            if (val) {
                let min: number = this.queueGeneral.QueueRefreshMinimum || 5;
                let max: number = this.queueGeneral.QueueRefreshMaximum || 300;
                num = parseInt(val, 10);
                if (!Number.isInteger(num) || num < min || num > max) {
                    num = this.queueGeneral.QueueRefreshDefault || 30;
                }
            }
        }
        this.refreshVal = num || 30;
        this.queueOptions.queueRefreshEvery = this.refreshVal.toString(10);
    }

    private queueLoadViewState(api: GridApi) {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'QueueViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Show/hide the organization column based on context.
                        let org = val.find(item => { return item.colId === 'OrganizationName'; });
                        if (org) {
                            org.hide = !this.session.contextAllOrganizations();
                        }
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
            api.resetColumnState();
        }
    }

    private queueSaveViewState(api: GridApi) {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'QueueViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        let height: number = Math.max(300, innerHeight - 50);
        this.cssGridHeight = { 'height': height + 'px' };
    }

    private queueGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        let sel: IRowNode<FaxConfigRestJob>[] = params.api.getSelectedNodes()?? [];
        this.queueOnSelectionChanged(params.api);

        const canModify = this.auth.isModifiable('Queue');
        const plural = (sel && sel.length > 1)? 's': '';

        return [
            'copy', 'separator',
            {
                name: 'View job...',
                action: () => this.view(sel[0].data!),
                disabled: (!sel || sel.length !== 1)
            },
            {
                name: 'Pause job' + plural,
                action: () => this.pause(),
                disabled : !(canModify && this.canPause)
            },
            {
                name: 'Continue job' + plural,
                action: () => this.kontinue(),
                disabled : !(canModify && this.canContinue)
            },
            {
                name: 'Re-route job' + plural,
                action: () => this.reroute_ask(),
                disabled : !(canModify && this.canReroute)
            },
            {
                name: 'Priority',
                disabled : !(canModify && this.canPrioritize),
                subMenu: [
                    {
                        name: 'High',
                        checked: (this.priorityMask & 1)? true: false,
                        action: () => this.prioritize('High')
                    },
                    {
                        name: 'Normal',
                        checked: (this.priorityMask & 2)? true: false,
                        action: () => this.prioritize('Normal')
                    },
                    {
                        name: 'Low',
                        checked: (this.priorityMask & 4)? true: false,
                        action: () => this.prioritize('Low')
                    },
                ]
            },
            {
                name: 'Delete job' + plural,
                action: () => this.del(),
                disabled : !(canModify && this.canDelete)
            }
        ];
    }

    private getQueueStatistics(): void {
        this.faxSrv.queueStatistics(this.queue).subscribe(res => {
            this.statusCounterData = res;
        });
    }

    switchTab(pageTab: string): void {
        this.activePageTab = pageTab;
        if (this.queueTabKey) localStorage.setItem(this.queueTabKey, pageTab);
        this.refreshView();
    }

    validCounterData(): ICounterOptions[] {
        let data: ICounterOptions[] = [];
        if (this.counterOptions && this.statusCounterData) {
            _.each(this.counterOptions, item => {
                if (item.key && this.HasCounterData(item.key)) {
                    data.push(item);
                }
            });
        }
        return data;
    }

    private HasCounterData(sel: keyof FaxConfigRestQueueCounts): boolean {
        return this.statusCounterData? (this.statusCounterData[sel] !== undefined): false;
    }

    CounterData(sel: keyof FaxConfigRestQueueCounts): FaxConfigRestCountDetails {
        let res: FaxConfigRestCountDetails | undefined;
        if (this.statusCounterData) {
            res = this.statusCounterData[sel] as FaxConfigRestCountDetails;
        } else {
            res = undefined;
        }
        return (res?? { Total: 0, InProcess: 0 });
    }
    
    private refreshView(): void {
        this.timer?.unsubscribe();
        if (this.activePageTab === 'Jobs') {
            this.queueGridApi?.refreshServerSide({purge: false});
        } else {
            this.getQueueStatistics();
        }
        this.timer = timer(this.refreshVal * 1000).subscribe(() => this.refreshView());
    }

    refresh_change(): void {
        let min: number = this.queueGeneral.QueueRefreshMinimum || 5;
        let max: number = this.queueGeneral.QueueRefreshMaximum || 300;
        let val: number = parseInt(this.queueOptions.queueRefreshEvery, 10);
        if (Number.isInteger(val) && val >= min) {
            this.timer?.unsubscribe();
            this.refreshVal = Math.min(val, max);
            if (this.refreshKey) localStorage.setItem(this.refreshKey, this.refreshVal.toString(10));
            this.timer = timer(this.refreshVal * 1000).subscribe(() => this.refreshView());
        }
    }

    private queueDataSource: IServerSideDatasource = {
        getRows: params => {
            let successData: LoadSuccessParams;
            let count: number = params.request.endRow! - params.request.startRow!;
            let filter: { [key: string]: string } = {};

            if (params.request.filterModel) {
                _.each(params.request.filterModel, (model, key) => {
                    if (model.filterType === 'text') {
                        switch (key) {
                            case 'Direction':
                            case 'MessageType':
                                filter[key] = model.type;
                                break;
                            default:
                                if (model.filter) {
                                    filter[key] = model.filter;
                                }
                                break;
                        }
                        if (key === 'JobNumber' && model.type === 'parentjobonly') {
                            filter['ParentOnly'] = '1';
                        }
                    }
                });
            }

            this.faxSrv.jobsCount(this.queue, filter).subscribe(res => {
                let total: number = res.Count?? 0;
                this.faxSrv.jobs(this.queue, params.request.startRow!, count, filter).subscribe({
                    next: res => {
                        successData = { rowData: res.Results?? [] };
                        if (total) {
                            successData.rowCount = total;
                        } else if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                            successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                        }
                        params.success(successData);
                    },
                    error: err => {
                        params.fail();
                    }
                });
            });
        }
    };

    private queueGetRowId(params: GetRowIdParams<FaxConfigRestJob>): string {
        return params.data.JobNumber!;
    }

    private isMarkedForDeletion(job: FaxConfigRestJob) {
        // "Soft deleting a job sets the job to status 950. Any job with status 340 and up
        // or jobs that are in process by a device (320-329) should have special notice before deleting.
        // 'Hard' delete, deletes the job from the queue without proper cleanup by the product."
        return job.Status === 950; // 'Marked for deletion.'
    }

    private jobSelection(): FaxConfigRestJobList {
        return _.pluck(this.queueGridApi?.getSelectedNodes()?? [], 'data');
    }

    private queueOnPaginationChanged(params: PaginationChangedEvent) {
        if (params.newPage) params.api.deselectAll();
    }

    private queueOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger queueOnSelectionChanged:
        params.api.deselectAll();
    }

    private queueOnSelectionChanged(api: GridApi | null) {
        let jobs: FaxConfigRestJob[] = _.pluck(api?.getSelectedNodes()?? [], 'data');
        let mask: number = 0;
        this.canPause = _.any(jobs, item => { return !item.isPaused; });
        this.canContinue = this.kontinue_enabled(jobs);
        this.canReroute = this.reroute_enabled(jobs);
        this.canPrioritize = (jobs && jobs.length > 0);
        this.canDelete = this.canPrioritize;
        _.each(jobs, item => {
            if      (item.Priority === 'High')   { mask |= 1; }
            else if (item.Priority === 'Normal') { mask |= 2; }
            else if (item.Priority === 'Low')    { mask |= 4; }
        });
        this.priorityMask = mask;
    }

    pause(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.jobSelection(), job => {
            if (job.JobNumber && !job.isPaused) {
                // set only status field
                promises.push(this.faxSrv.updateJob(this.queue, job.JobNumber, { Status: 'Pause' }));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => this.refreshView(),
                error: err => alert(err.message)
            });
        }
    }

    // 'continue' is a keyword
    kontinue(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.jobSelection(), job => {
            if (job.JobNumber) {
                let props = { };
                if (job.isPaused) {
                    props = _.extend(props, { Status: 'Continue' });
                }
                if (job.SubmissionTime) {
                    let dtNow: number = Date.now();
                    if (new Date(job.SubmissionTime).getTime() > dtNow) {
                        let dt: string = this.datePipe.transform(dtNow, 'YYYY-MM-ddTHH:mm:ss') + 'Z';
                        props = _.extend(props, { SubmissionTime: dt });
                    }
                }
                if (_.size(props) > 0) {
                    promises.push(this.faxSrv.updateJob(this.queue, job.JobNumber, props));
                }
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => this.refreshView(),
                error: err => alert(err.message)
            });
        }
    }

    kontinue_enabled(jobs: FaxConfigRestJobList): boolean {
        if (jobs) {
            let idx: number;
            for (idx = 0; idx < jobs.length; idx++) {
                if (jobs[idx].isPaused) return true;
                if (jobs[idx].SubmissionTime) {
                    if (new Date(jobs[idx].SubmissionTime!).getTime() > Date.now()) return true;
                }
            }
        }
        return false;
    }

    reroute_enabled(jobs: FaxConfigRestJobList): boolean {
        if (jobs && jobs.length > 0) {
            let idx: number;
            for (idx = 0; idx < jobs.length; idx++) {
                let status: number = (jobs[idx].Status as number)?? 0;
                if ((status & 0x7FFFFFFF) != 920 &&   /* (Paused while) 'waiting for host system...' */
                    (status & 0x7FFFFFFF) != 930 &&   /* (Paused while) 'stored for host system...' */
                    status != 2147484458)             /* Paused while 'submitting to mail' */
                {
                    return false;
                }
                if (jobs[idx].OrganizationId != jobs[0].OrganizationId)
                {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    private reroute(routingCode: string): void {
        // Note: the timer is stopped on entry, so restart it before leaving.
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.jobSelection(), job => {
            if (job.JobNumber) {
                promises.push(this.faxSrv.rerouteJob(this.queue, job.JobNumber, routingCode));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    this.fenUtils.afterSave(res);
                    this.refreshView();  // restarts the timer
                },
                error: err => {
                    alert(err.message);
                    this.refreshView();
                }
            });
        } else {
            this.refreshView();
        }
    }

    prioritize(priority: string): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.jobSelection(), job => {
            if (job.JobNumber) {
                // set only priority field
                promises.push(this.faxSrv.updateJob(this.queue, job.JobNumber, { Priority: priority }));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: () => {
                    this.queueGridApi?.deselectAll();
                    this.refreshView();
                },
                error: err => alert(err.message)
            });
        }
    }

    reroute_ask(): void {
        let jobs: FaxConfigRestJob[] = this.jobSelection();
        if (jobs.length > 0) {
            let orgId: number | undefined = undefined;
            let msgType: string = '';
            if (_.every(jobs, job => { return (job.OrganizationId === jobs[0].OrganizationId) }) ) {
                orgId = jobs[0].OrganizationId?? undefined;
            }
            if (_.every(jobs, job => { return (job.MessageType === jobs[0].MessageType) }) ) {
                msgType = jobs[0].MessageType? jobs[0].MessageType.toUpperCase(): '';
            }

            this.timer?.unsubscribe();
            let def: IJobRerouteScope = {
                title: 'Re-route ' + ((jobs.length > 1) ? 'Multiple Jobs' : ('Job ' + jobs[0].JobNumber)),
                jobOrgId: orgId,
                jobMsgType: msgType,
                jobRoutingCode: (jobs.length === 1)? (jobs[0].RoutingCode?? '') : ''
            }
            const dialogRef = this.dialog.open(QueueJobRerouteComponent, { data: { scope: def }});
            dialogRef.afterClosed().subscribe((res: string) => {
                if (res) this.reroute(res);
            });
        }
    }

    del(): void { // delete is a keyword
        let jobs: FaxConfigRestJob[] = this.jobSelection();
        if (jobs.length > 0) {
            let markedForDeletion = _.filter(jobs, job => { return this.isMarkedForDeletion(job); });
            let ok: boolean = true;
            if (markedForDeletion.length > 0) {
                ok = confirm('You are about to permanently delete ' + markedForDeletion.length + ' jobs.\n' +
                                'Are you sure?');
            }
            if (ok) {
                let promises: Observable<FaxConfigRestResult>[] = [];
                _.each(this.jobSelection(), job => {
                    if (job.JobNumber) {
                        promises.push(this.faxSrv.deleteJob(this.queue, job.JobNumber, this.isMarkedForDeletion(job)? 'hard': 'soft'));
                    }
                });
                if (promises.length > 0) {
                    forkJoin(promises).subscribe({
                        next: res => this.refreshView(),
                        error: err => alert(err.message)
                    });
                }
            }
        }
    }

    private queueOnCellDoubleClicked(params: RowDoubleClickedEvent) {
        this.view(params.node.data);
    }

    view(job: FaxConfigRestJob): void {
        if (job.JobNumber) {
            this.faxSrv.job(this.queue, job.JobNumber).subscribe({
                next: res => {
                    const dialogRef = this.dialog.open(QueueJobComponent, { data: { job: res }});
                    dialogRef.afterClosed().subscribe(() => this.refreshView());
                },
                error: err => alert(err.message)
            });
        }
    }
}
