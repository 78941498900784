import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestOSMDef } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { OsmRuleComponent } from '../osm-rule/osm-rule.component';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, ICellRendererParams, GridOptions, GridReadyEvent,
    GetContextMenuItemsParams, MenuItemDef, PaginationChangedEvent, ValueFormatterParams,
    CellDoubleClickedEvent, ValueGetterParams, FilterChangedEvent
} from 'ag-grid-community';

@Component({
    selector: 'app-osm',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './osm.component.html',
    styleUrl: './osm.component.css'
})
export class OsmComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    cssGridHeight = { 'height': '300px' };
    osmGridApi: GridApi | null = null;
    osmGridOptions?: GridOptions;
    osmGridInitialized: boolean = false;

    canRemove: boolean = false;
    canEnable: boolean = false;
    canDisable: boolean = false;
    criteriaTypes: { [key: string]: { display: string } } = this.faxSrv.outboundManagementCriteriaTypes();

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    colEnabledCellRenderer(params: ICellRendererParams): string {
        let img = document.createElement("img");
        if (params.value === 'true') {
            img.src ='icon-checkmark.png';
        } else {
            img.src ='icon-cross.png';
        }
        return img.outerHTML;
    }

    criteriaTypeComparator(valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean): number {
        let dispA = this.criteriaTypes[valA]?.display?? '';
        let dispB = this.criteriaTypes[valB]?.display?? '';
        return dispA.localeCompare(dispB);
    }

    osmGridColDefs: ColDef<FaxConfigRestOSMDef>[] = [
        {
            field: 'Enabled',
            headerTooltip: 'Enabled',
            cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
            valueGetter: (params: ValueGetterParams) => {
                return params.data.Enabled? 'true': 'false';
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
                suppressMiniFilter : true,
                suppressSelectAll : true
            }
        },
        {
            field: 'Name',
            headerTooltip: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                filterOptions: ['contains','notContains','equals','notEqual','startsWith','endsWith']
            }
        },
        {
            field: 'Description',
            headerTooltip: 'Description',
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'RoutedService',
            headerTooltip: 'Routed Service',
            filter: 'agSetColumnFilter'
        },
        {
            field: 'DestinationService',
            headerTooltip: 'Destination Service',
            filter: 'agSetColumnFilter'
        },
        {
            field: 'CriteriaType',
            headerTooltip: 'Criteria Type',
            valueFormatter: (params: ValueFormatterParams) => {
                return this.criteriaTypes[params.data.CriteriaType]?.display?? '';
            },
            comparator: (valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean) => this.criteriaTypeComparator(valA, valB, nodeA, nodeB, isDesc),
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressMiniFilter : true,
                values: _.keys(this.criteriaTypes),
                valueFormatter: (params: ValueFormatterParams) => {
                    return this.criteriaTypes[params.value]?.display?? '';
                }
            }
        },
        {
            field: 'CriteriaValue',
            headerTooltip: 'Criteria Value',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                filterOptions: ['contains','notContains','equals','notEqual','startsWith','endsWith']
            }
        },
        {
            field: 'MessageType',
            headerTooltip: 'Message Type',
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressMiniFilter : true
            }
        }
    ];

    init(): void {
        this.osmGridOptions = {
            columnDefs: this.osmGridColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            rowModelType: 'clientSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            defaultCsvExportParams: { allColumns: true, fileName: 'export_osm.csv' },
            onGridReady: (params: GridReadyEvent) => {
                this.osmGridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
                this.osmLoadViewState(params.api);
                this.refresh();
                this.isReady = true;
            },
            onCellDoubleClicked: params => this.osmOnCellDoubleClicked(params),
            onSelectionChanged: params => this.osmOnSelectionChanged(params.api),
            onPaginationChanged: params => this.osmOnPaginationChanged(params),
            onFilterChanged: params => this.osmOnFilterChanged(params),
            getContextMenuItems: params => this.osmGetContextMenuItems(params),
            onGridPreDestroyed: params =>this.osmSaveViewState(params.api)
        };
        this.osmGridInitialized = true;
    }

    osmLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'OsmViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    osmSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'OsmViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    refresh(): void {
        this.canRemove = false;
        this.canEnable = false;
        this.canDisable = false;

        this.faxSrv.osmDefinitions().subscribe({
            next: rules => {
                // This will trigger osmOnPaginationChanged and osmOnSelectionChanged:
                this.osmGridApi?.setGridOption('rowData', rules);
            },
            error: err => alert(err.message)
        });
    }

    osmGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        let sel = params.api.getSelectedNodes()?? [];

        const canEdit = this.auth.isModifiable('Outbound Service Management');
        return ['copy','separator',
            {
                name: 'New ...',
                action: () => this.neo(),
                disabled: !canEdit
            },
            {
                name: canEdit ? 'Edit entry...' : 'View entry...',
                action: () => this.osmOnCellDoubleClicked(sel[0]),
                disabled: (!sel || sel.length != 1)
            },
            {
                name: 'Remove entries',
                action: () => this.remove(),
                disabled : (!sel || sel.length == 0 ) || (!canEdit)
            },
            'separator',
            {
                name: 'CSV Export',
                action: () => this.export(),
                disabled : false
            }
        ];
    }

    osmOnSelectionChanged(api: GridApi | null): void {
        let enabled = 0, disabled = 0;
        let sel = api?.getSelectedNodes()?? [];
        this.canRemove = (sel && sel.length > 0);
        _.each(sel, item => {
            if (item.data.Enabled) { enabled++; } else { disabled++; }
        });
        this.canEnable = disabled > 0;
        this.canDisable = enabled > 0;
    }

    private updateEnabled(enabled: boolean): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.osmGridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestOSMDef>) => {
            let newdata = _.extend({}, item.data, { Enabled: enabled });
            promises.push(this.faxSrv.updateOsmDefinition(
                newdata.Name?? '',
                newdata
            ));
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        _.each(sel, (item: IRowNode<FaxConfigRestOSMDef>) => {
                            item.updateData(_.extend({}, item.data, { Enabled: enabled }));
                        });
                        this.osmOnSelectionChanged(this.osmGridApi);
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    enable(): void { this.updateEnabled(true); };
    disable(): void { this.updateEnabled(false); };

    remove(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.osmGridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestOSMDef>) => {
            if (item.data?.Name) {
                promises.push(this.faxSrv.deleteOsmDefinition(item.data.Name));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.refresh();
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    neo(): void {
        const dialogRef = this.dialog.open(OsmRuleComponent, { data: { editee: null }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh(); }
        });
    }

    osmOnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger osmOnSelectionChanged:
        params.api.deselectAll();
    }

    osmOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger osmOnSelectionChanged:
        params.api.deselectAll();
    }

    osmOnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit(params.data);
        }
    }

    edit(rule: FaxConfigRestOSMDef): void {
        const dialogRef = this.dialog.open(OsmRuleComponent, { data: { editee: rule }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh(); }
        });
    }

    export(): void {
        this.osmGridApi?.exportDataAsCsv();
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number): void {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }
}
