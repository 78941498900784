<section id="ecr_rule" class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ displayAction }} Extended Custom Routing Rule</h1>
    </div>
    <form id="ecr_form" class="form" #ecrForm="ngForm" novalidate (submit)="submit()">
        <div class="modal-body">
            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="state.activePageTab=pageTab;false">{{ pageTab }}</a>
                </li>
            </ul>

            <div [hidden]="!isReady || state.activePageTab !== 'General'">
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </div>
            <div [hidden]="!isReady || state.activePageTab !== 'Route To'">
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </div>
            <div [hidden]="!isReady || state.activePageTab !== 'Conditions'">
                <ng-container *ngTemplateOutlet="page3"></ng-container>
            </div>
            <br>

            <p #Paragraph class="well" *ngIf="ruleDescriptionLines">
                @for(line of ruleDescriptionLines; track $index) {
                    <span>{{ line }}<br></span>
                }
            </p>
        </div>
        <div class="modal-footer">
            <button *ngIf="!isReadOnly" type="submit" class="btn btn-primary" [disabled]="!submitEnabled(ecrForm)">Save</button>
            <button *ngIf="!isReadOnly" type="button" class="btn" [disabled]="!submitEnabled(ecrForm)" (click)="apply()">Apply</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>

<!-- General tab -->
<ng-template #page1>
    <div class="field">
        <label>Name: *</label>
        <input type="text" maxlength="50" name="RuleName" [(ngModel)]="rule.RuleName" [disabled]="isReadOnly" required>
    </div>
    <div class="field">
        <label>Description:</label>
        <input type="text" maxlength="150" name="RuleComment" [(ngModel)]="rule.RuleComment" [disabled]="isReadOnly" size="40">
    </div>
    <div class="field">
        <label>Fax Format:</label>
        <select name="FaxFormat" [(ngModel)]="rule.FaxFormat" [disabled]="isReadOnly">
            <option *ngFor="let f of faxFormats" [ngValue]="f.FaxFormat">{{ f.DisplayName }}</option>
        </select>
    </div>
    <div class="field">
        <label>Enabled:</label>
        <input type="checkbox" name="RuleEnabled" [(ngModel)]="rule.RuleEnabled">
    </div>
</ng-template>

<!-- Route To tab -->
<ng-template #page2>
    <div class="field">
        <label>Host Name: *</label>
        <app-combobox name="MailSystem" [model]="rule.MailSystem" (modelChange)="onMailSystemChanged($event)" [options]="hostNames" [required]="true" [disabled]="isReadOnly"></app-combobox>
    </div>
    <div class="field">
        <label>Address Type: *</label>
        <app-combobox name="AddressType" [(model)]="rule.AddressType" [options]="hostAddressTypes[rule.MailSystem!]" [required]="true" [disabled]="isReadOnly"></app-combobox>
    </div>
    <div class="field">
        <label>Route Address: *</label>
        <input type="text" maxlength="150" name="RouteAddress" [(ngModel)]="rule.RouteAddress" size="40" [disabled]="isReadOnly" required>
    </div>
    <div class="field">
        <label>System Address:</label>
        <input type="text" maxlength="20" name="SystemAddress" [(ngModel)]="rule.SystemAddress" [disabled]="isReadOnly">
    </div>
    <div class="field">
        <label>Message Class:</label>
        <input type="text" maxlength="100" name="MessageClass" [(ngModel)]="rule.MessageClass" [disabled]="isReadOnly">
    </div>
</ng-template>

<!-- Conditions tab -->
<ng-template #page3>
    <div class="field">
        <label>Message Type: *</label>
        <select name="MessageType" [(ngModel)]="rule.MessageType" [disabled]="isReadOnly">
            <option *ngFor="let mt of messageTypes">{{ mt }}</option>
        </select>
    </div>
    <div class="field">
        <label>
            <input type="checkbox" name="RouteOnType" [(ngModel)]="rule.RouteOnType" [disabled]="isReadOnly">
            Routing Type
        </label>
        <select name="RoutingType" [(ngModel)]="rule.RoutingType" [disabled]="isReadOnly || !rule.RouteOnType">
            <option *ngFor="let rt of routingTypes[rule.MessageType!]">{{ rt }}</option>
        </select>&ngsp;
        <input type="text" maxlength="{{ rule.RoutingType==='CSID'? 20: 150 }}" name="RoutingCode" #RoutingCode="ngModel" size="10"
            [(ngModel)]="rule.RoutingCode" [disabled]="isReadOnly || !rule.RouteOnType" [required]="!(isReadOnly || !rule.RouteOnType)">
        @if (RoutingCode.errors?.['required']) {
            <span class="text-danger">&nbsp;a value is required</span>
        } @else if (RoutingCode.errors?.['maxlength']) {
            <span class="text-danger">&nbsp;maximum {{ rule.RoutingType==='CSID'? 20: 150 }} characters</span>
        }

    </div>
    <div class="field">
        <label>
            <input type="checkbox" name="RouteOnPages" [(ngModel)]="rule.RouteOnPages" [disabled]="isReadOnly">
            Number of Pages
        </label>
        <select name="PagesCompare" [(ngModel)]="rule.PagesCompare" [disabled]="isReadOnly || !rule.RouteOnPages">
            <option *ngFor="let o of compareOptions">{{ o }}</option>
        </select>&ngsp;
        <input type="number" min="1" max="1000" name="RoutePages" #RoutePages="ngModel" [(ngModel)]="rule.RoutePages"
            [disabled]="isReadOnly || !rule.RouteOnPages" [required]="!(isReadOnly || !rule.RouteOnPages)">
        @if (RoutePages.errors?.['required']) {
            <span class="text-danger">&nbsp;a value is required</span>
        } @else if (RoutePages.errors?.['min'] || RoutePages.errors?.['max']) {
            <span class="text-danger">&nbsp;valid values are 1 to 1000</span>
        }
    </div>
    <div class="field">
        <label>
            <input type="checkbox" name="RouteOnMsgSize" [(ngModel)]="rule.RouteOnMsgSize" [disabled]="isReadOnly">
            Message Size
        </label>
        <select name="SizeCompare" [(ngModel)]="rule.SizeCompare" [disabled]="isReadOnly || !rule.RouteOnMsgSize">
            <option *ngFor="let o of compareOptions">{{ o }}</option>
        </select>&ngsp;
        <input type="number" min="1" max="100000000" name="RouteSize" #RouteSize="ngModel" [(ngModel)]="rule.RouteSize"
            [disabled]="isReadOnly || !rule.RouteOnMsgSize" [required]="!(isReadOnly || !rule.RouteOnMsgSize)">
        @if (RouteSize.errors?.['required']) {
            <span class="text-danger">&nbsp;a value is required</span>
        } @else if (RouteSize.errors?.['min'] || RouteSize.errors?.['max']) {
            <span class="text-danger">&nbsp;valid values are 1 to 100000000</span>
        }
    </div>
</ng-template>

    </form>
</section>
