<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ action }} Number Substitution Rule</h1>
    </div>
    <form id="num_subst_new" class="form" #form="ngForm" novalidate (submit)="save()" *ngIf="isReady">
        <div class="modal-body">
            <div class="field">
                <label>Machine:</label>
                <span>{{ subst?.Machine }}</span>
            </div>

            <div class="field">
                <label>Description:</label>
                <input type="text" maxlength="255" name="Description" [(ngModel)]="subst!.Description" size="40" [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Start of Number: *</label>
                <input type="text" maxlength="40" name="Number" [(ngModel)]="subst!.Number" required [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Message Type: *</label>
                <select name="MessageType" [(ngModel)]="subst!.MessageType" required [disabled]="!isModifiable">
                    <option *ngFor="let mt of messageTypes">{{ mt }}</option>
                </select>
            </div>
            <div class="field">
                <input type="radio" name="Block" [(ngModel)]="subst!.Block" id="Block_true" [value]="true" [attr.value]="'true'"
                    [disabled]="!isModifiable">
                <label for="Block_true">Block this range of numbers</label>
            </div>
            <div class="field">
                <label>
                    <input type="radio" name="Block" [(ngModel)]="subst!.Block" id="Block_false" [value]="false" [attr.value]="'false'"
                        [disabled]="!isModifiable">
                    <label for="Block_false">Substitute with</label>
                </label>
                <input type="text" maxlength="40" name="Substitute" [(ngModel)]="subst!.Substitute" [disabled]="!isModifiable || subst?.Block === true">
            </div>
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="subst!.Enabled" [disabled]="!isModifiable">
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
