<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="closeDialog()">&times;</button>
        <h1>New API Key</h1>
    </div>
    <form id="new_api_key_form" #newApiKeyForm="ngForm" class="form" (submit)="CreateKey()" name="newApiKeyForm">
        <div class="modal-body" >
            <div class="text-danger" *ngIf="Error">
                <p>{{ Error }}</p>
            </div>

            <div class="field">
                <label>Active:&nbsp;</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="key.Enabled" title="Is the key enabled." [disabled]="hasKey()">
            </div>

            <div class="field">
                <label>Description:&nbsp;*</label>
                <input type="text" name="Description" [(ngModel)]="key.Description" maxlength="150" required title="A description for this key." [disabled]="hasKey()">
            </div>

            <div class="field" style="margin-bottom: 0;">
                <label>Expiration:&nbsp;*</label>
                <div style="display: inline-block;" title="The key never expires.">
                    <input type="radio" name="expiration" [(ngModel)]="expiration" id="expiration_infinite" value="infinite"
                        (click)="set_expirationmode(0)" [disabled]="hasKey()">&ngsp;
                    <label [class.disabled]="hasKey()" for="expiration_Infinite">Infinite</label>
                </div>
            </div>
            <div class="field">
                <label></label>
                <div style="display: inline-block;" title="{{ dateTitle }}">
                    <input type="radio" name="expiration" [(ngModel)]="expiration" id="expiration_expires" value="expires"
                        (click)="set_expirationmode(1)" [disabled]="hasKey()">&nbsp;
                    <input type="date" name="Date" [(ngModel)]="key.Expires" [disabled]="hasKey() || infinite">
                </div>
            </div>

            <p>After successfully creating the API Key, the key will be shown here. Copy the key before closing the screen, once this screen is 
                closed there is no way it can be retrieved.</p>
            <div>
                <div class="pull-right"><img src="{{ copyImage }}" class="clickable" title="Copy to clipboard" *ngIf="hasKey()" (click)="copyToClipboard()">
                </div>
                <div class="field">
                    <label>API&nbsp;Key:</label><br>
                    <textarea id="NewApiKey" name="NewApiKey" type="text" [(ngModel)]="key.Key" readonly title="The resulting key." rows="5" cols="60"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" *ngIf="auth.isModifiable('APIKeys') && !key.Key" class="btn btn-primary" [disabled]="!CreateKeyEnabled()">Create</button>
            <button type="button" *ngIf="auth.isModifiable('APIKeys') && !key.Key" class="btn" (click)="close()">Cancel</button>
            <button type="button" *ngIf="hasKey()" class="btn btn-primary" (click)="closeDialog()">Close</button>
        </div>
    </form>
</section>
