<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ uploadTitle }}</h1>
    </div>
    <form id="address_reg_upload_form" class="form" novalidate (submit)="submit()" name="addressRegForm">
        <div class="modal-body" *ngIf="!uploadFinished">
            <p>Use this dialog to create address registration requests from a list of phone numbers. The list must be
 a plain text file or a CSV file. For a plain text file, each line must consist of a single phone number or routing code.
 You can prefix the value with an optional address type and colon (like 'FAX:+1234567'), otherwise the FAX address type is assumed.</p>
            <p>For a CSV file, the recognized columns are 'Number', 'Address Type', 'UserAddress', 'UserAddrType', and 'Description'.
 Only the 'Number' column is required. The 'Address Type' is assumed to be FAX if not provided. The 'UserAddrType' is assume to be SMTP
 if not provided. An optional 'Description' column can be included with a value for each individual number, otherwise the Description
 below is used for all numbers.</p>
            <p>FAX and SMS numbers must be in E.164 format. If any of the numbers already exist or are already pending
 registration, they are silently skipped.</p>

            <br />
            <div class="field">
                <label>Connection:&nbsp;*</label>
                <select name="connections" [(ngModel)]="scope.editee.ConnectionId">
                    <option *ngFor="let p of scope.Connections" [value]="p.ID">{{ p.ConnectionName }}</option>
                </select>
            </div>
            <div class="field" *ngIf="(!scope.Connections || scope.Connections.length <= 1) && auth.isModifiable('Addresses')">
                <span class="text-info">Add providers via the 'Configure' button on the Phone Numbers page.</span>
            </div>
            <div class="field">
                <label>Description:</label>
                <input name="description" type="text" maxlength="500" [(ngModel)]="scope.editee.Description" size="40" title="An optional description">
            </div>
            <div class="field">
                <label>Numbers:&nbsp;*</label>
                <input type="file" id="numbers_file" required accept=".txt,.csv" (change)="filechange($event)">
            </div>
        </div>
        <div class="modal-footer" *ngIf="!uploadFinished">
            <span *ngIf="uploadActive">Processing...</span>
            <button type="submit" class="btn btn-primary" *ngIf="!uploadActive" [disabled]="!uploadEnabled || scope.editee.ConnectionId < 0">Upload</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
    <div class="modal-body" [hidden]="!uploadFinished">
        <p>This list shows the status after requesting to add each uploaded number. You can save these results to CSV by clicking
        <span class="btn-link" (click)="uploadResultsExport()" title="Export the results into a .csv formatted file">here</span>.</p>

        <div id="addrRegUploadGrid" class="ag-theme-alpine">
            <ag-grid-angular [gridOptions]="addrRegUploadGridOptions" [style]="cssGridHeight"></ag-grid-angular>
        </div>
    </div>
    <div class="modal-footer" *ngIf="uploadFinished">
        <button type="button" class="btn" (click)="close()">Close</button>
    </div>
</section>
