import { CommonModule, NgFor } from '@angular/common';
import { Observable, Subscription, forkJoin, finalize, timer } from 'rxjs';
import { Component, inject, OnDestroy, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestServicesKernels, FaxConfigRestServicesGeneral, FaxConfigRestNTServiceList, FaxConfigRestNTService } from '../api/api';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColDef, ColumnState, IRowNode, CellClassParams, GridOptions, GridReadyEvent, GetContextMenuItemsParams,
    GetRowIdParams, MenuItemDef, PaginationChangedEvent, CsvExportParams, FilterChangedEvent } from 'ag-grid-community';

@Component({
    imports: [FormsModule, CommonModule, NgFor, AgGridAngular],
    templateUrl: './nt-services.component.html',
    styleUrl: './nt-services.component.css'
})
export class NtServicesComponent implements OnDestroy {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    cssGridHeight = { 'height': '300px' };
    gridApi: GridApi<FaxConfigRestNTService> | null = null;
    ntServicesGridOptions?: GridOptions<FaxConfigRestNTService> = undefined;

    timerSubscription: Subscription | null = null;
    refreshSubscription: Subscription | null = null;
    servicesRefreshEvery: string = '';
    refreshVal: number = 30;

    currentKernel: string = '';
    selectedKernel: string = '';
    availableKernels: string[] = [];
    services: FaxConfigRestNTServiceList = [];
    settingsServicesGeneral: FaxConfigRestServicesGeneral = {};

    isInitialized: boolean = false;
    isReady: boolean = false;
    canStart: boolean = false;
    canStop: boolean = false;
    canRestart: boolean = false;
    canPause: boolean = false;
    canResume: boolean = false;

    ntServicesGridColDefs: ColDef<FaxConfigRestNTService>[] = [
        {
            field: 'Status'
        },
        {
            field: 'DisplayName',
            tooltipField: 'DisplayName',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'ServiceName',
            hide: true,
            tooltipField: 'ServiceName',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'ImagePath',
            hide: true,
            tooltipField: 'ImagePath',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'Description',
            hide: true,
            tooltipField: 'Description',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'StartMode',
            hide: true
        },
        {
            field: 'LogonAccount',
            hide: true,
            tooltipField: 'LogonAccount'
        }
    ];

    ntServicesDefaultColDef: ColDef<FaxConfigRestNTService> = {
        flex: 1,
        resizable: true,
        sortable: true,
        minWidth: 100,
        cellClass: (params: CellClassParams<FaxConfigRestNTService>) => {
            return (params.data && this.isReadOnly(params.data))? 'text-info': '';
        },
        columnChooserParams: {
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true
        },
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressMiniFilter : true
        }
    };

    constructor (public auth: AuthService, public session: Session) {
        this.session.rootPromises.subscribe(res => this.init());
    }

    ngOnDestroy() {
        this.cancelRefresh();
    }

    private cancelRefresh(): void {
        // Cancel any pending timer or refresh action.
        this.timerSubscription?.unsubscribe();
        this.refreshSubscription?.unsubscribe();
        this.timerSubscription = null;
        this.refreshSubscription = null;
    }

    private init(): void {
        this.ntServicesGridOptions = {
            columnDefs: this.ntServicesGridColDefs,
            defaultColDef: this.ntServicesDefaultColDef,
            rowModelType: 'clientSide',
            onGridReady: params => this.ntServicesOnGridReady(params),
            onGridPreDestroyed: params =>this.ntServicesSaveViewState(params.api),
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            defaultCsvExportParams: { allColumns: true, fileName: 'export_services.csv' },
            getContextMenuItems: params => this.ntServicesGetContextMenuItems(params),
            onPaginationChanged: params => this.ntServicesOnPaginationChanged(params),
            onFilterChanged: params => this.ntServicesOnFilterChanged(params),
            onSelectionChanged: params => this.ntServicesOnSelectionChanged(params.api),
            getRowId: params => this.ntServicesGetRowId(params)
        };
        this.isInitialized = true;  // triggers ntServicesOnGridReady
    }

    private ntServicesOnGridReady(params: GridReadyEvent): void {
        this.gridApi = params.api;
        this.onResize(window.innerHeight);
        this.ntServicesLoadViewState(params.api);
        this.ntServicesLoadRefreshInterval();
        this.isReady = true;

        let promises: {
            p1: Observable<FaxConfigRestServicesKernels>,
            p2: Observable<FaxConfigRestServicesGeneral>
        } = {
            p1: this.faxSrv.getServicesKernels(),
            p2: this.faxSrv.getServicesGeneral()
        };
        forkJoin(promises)
            .subscribe({
                next: (res) => {
                    if (res.p1.LocalKernel) {
                        this.currentKernel = this.session.servicesCurrentKernel || res.p1.LocalKernel;
                        this.availableKernels.push(this.currentKernel);
                        if (res.p1.RemoteKernels) {
                            _.each(res.p1.RemoteKernels, (name) => {
                                this.availableKernels.push(name);
                            });
                        }
                        this.ntServicesOnKernelChanged(this.currentKernel);
                    }
                    this.settingsServicesGeneral = res.p2;
                },
                error: (err) => alert(err.message)
            });
    }

    private ntServicesLoadViewState(api: GridApi<FaxConfigRestNTService>): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'NtServicesViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx: number = val.findIndex((item: ColumnState) => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    private ntServicesSaveViewState(api: GridApi<FaxConfigRestNTService>): void {
        if (this.isReady && api) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'NtServicesViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    private ntServicesLoadRefreshInterval(): void {
        let num: number = 0;
        let key: string | null = this.auth.getUserName();
        if (key) {
            let val: string | null = localStorage.getItem(key.toLowerCase() + 'ServicesRefreshInterval');
            if (val) {
                let min: number = this.settingsServicesGeneral.ServicesRefreshMinimum || 5;
                let max: number = this.settingsServicesGeneral.ServicesRefreshMaximum || 300;
                num = parseInt(val, 10);
                if (!Number.isInteger(num) || num < min || num > max) {
                    num = this.settingsServicesGeneral.ServicesRefreshDefault || 30;
                }
            }
        }
        this.refreshVal = num || 30;
        this.servicesRefreshEvery = this.refreshVal.toString(10);
    }

    refresh_change(interval: string): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'ServicesRefreshInterval';
            let min: number = this.settingsServicesGeneral.ServicesRefreshMinimum || 5;
            let max: number = this.settingsServicesGeneral.ServicesRefreshMaximum || 300;
            let num: number = parseInt(interval, 10);
            if (Number.isInteger(num) && num >= min) {
                this.cancelRefresh();
                this.refreshVal = Math.min(num, max);
                localStorage.setItem(key, this.refreshVal.toString(10));
                this.refreshServices();
            }
        }
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        let height = (innerHeight < 300) ? 300 : (innerHeight - 50);
        this.cssGridHeight = { 'height': height + 'px' };
    }

    private ntServicesGetRowId(params: GetRowIdParams): string {
        return params.data.ServiceName;
    }

    ntServicesGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let items: (string | MenuItemDef)[] = ['copy','separator'];
        if (params.node) {
            if (!params.node.isSelected()) {
                params.api.deselectAll();
                params.node.setSelected(true);
                this.ntServicesOnSelectionChanged(params.api);
            }
            if (params.node.data && !this.isReadOnly(params.node.data)) {
                items.push({
                    name: 'Start',
                    action: () => this.start(),
                    disabled: !this.canStart
                });
                items.push({
                    name: 'Stop',
                    action: () => this.stop(),
                    disabled: !this.canStop
                });
                items.push({
                    name: 'Restart',
                    action: () => this.restart(),
                    disabled: !this.canRestart
                });
                items.push({
                    name: 'Pause',
                    action: () => this.pause(),
                    disabled: !this.canPause
                });
                items.push({
                    name: 'Resume',
                    action: () => this.resume(),
                    disabled: !this.canResume
                });
                items.push('separator');
            }
        }
        items.push({
            name: 'CSV Export',
            action: () => this.export(),
            disabled : false
        });
        return items;
    }

    private ntServicesOnPaginationChanged(params: PaginationChangedEvent<FaxConfigRestNTService>): void {
        if (params.newPage) params.api.deselectAll();
    }

    ntServicesOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger ntServicesOnSelectionChanged:
        params.api.deselectAll();
    }

    private ntServicesOnSelectionChanged(api: GridApi<FaxConfigRestNTService>): void {
        let running = 0, stopped = 0, paused = 0;
        let canpause = 0, canstop = 0;
        let sel = api.getSelectedNodes();
        _.each(sel, (item) => {
            if (item.data && !this.isReadOnly(item.data)) {
                if (item.data.Status === 'Running') { running++; }
                if (item.data.Status === 'Stopped') { stopped++; }
                if (item.data.Status === 'Paused')  { paused++; }
                if (item.data.CanPause) { canpause++; }
                if (item.data.CanStop)  { canstop++; }
            }
        });
        this.canStart = (stopped > 0);
        this.canStop = ((running > 0 || paused > 0) && canstop > 0);
        this.canRestart = this.canStop;
        this.canPause = (running > 0 && canpause > 0);
        this.canResume = (paused > 0);
    }

    ntServicesOnKernelChanged(kernel: string): void {
        this.cancelRefresh();
        this.selectedKernel = kernel;
        this.currentKernel = this.selectedKernel;
        if (this.gridApi) { this.gridApi.deselectAll(); }
        this.refreshServices();
    }

    private refreshServices(): void {
        this.cancelRefresh();
        if (this.currentKernel) {
            this.refreshSubscription = this.faxSrv.services(this.currentKernel).subscribe({
                next: (res) => {
                    this.services = _.reject(res, (n) => { return n.StartMode === 'Disabled'; });
                    if (this.gridApi) {
                        this.gridApi.setGridOption('rowData', this.services);
                        this.ntServicesOnSelectionChanged(this.gridApi);
                    }
                    this.session.servicesCurrentKernel = this.currentKernel;
                    this.timerSubscription = timer(this.refreshVal * 1000).subscribe(() => this.refreshServices());
                },
                error: (err) => {
                    this.services = [];
                    if (this.gridApi) {
                        this.gridApi.setGridOption('rowData', this.services);
                        this.ntServicesOnSelectionChanged(this.gridApi);
                    }
                    alert("Cannot access the services on remote kernel machine '" + this.currentKernel +
                        "'. Make sure that the Faxination Config Service is running on the machine."
                    );
                }
            });
        }
    }

    hasMultipleKernels(): boolean {
        return (this.availableKernels.length > 1);
    }

    isReadOnly(service: FaxConfigRestNTService): boolean {
        return service.ServiceName === 'Faxination Config Service';
    }

    start():   void { this.updateStatus('Start'); }
    stop():    void { this.updateStatus('Stop'); }
    restart(): void { this.updateStatus('Restart'); }
    pause():   void { this.updateStatus('Pause'); }
    resume():  void { this.updateStatus('Resume'); }

    updateStatus(stat: string): void {
        if (this.currentKernel) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            let sel: IRowNode<FaxConfigRestNTService>[] | undefined = this.gridApi?.getSelectedNodes();
            if (sel) {
                _.each(sel, (item: IRowNode<FaxConfigRestNTService>) => {
                    if (item.data && !this.isReadOnly(item.data) && item.data.ServiceName) {
                        promises.push(this.faxSrv.updateServiceStatus(this.currentKernel, item.data.ServiceName, stat));
                    }
                });
                if (promises.length > 0) {
                    forkJoin(promises)
                        .pipe(finalize(() => {
                            this.refreshServices();
                        }))
                        .subscribe({
                            next:  (res) => { this.fenUtils.afterSave(res) },
                            error: (err) => alert(err.message)
                        });
                }
            }
        }
    }

    export(): void {
        if (this.currentKernel && this.gridApi) {
            let params: CsvExportParams = { fileName: 'export_services_' + this.currentKernel + '.csv' };
            this.gridApi.exportDataAsCsv(params);
        }
    }
}
