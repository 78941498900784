<h1>General</h1>

<div *ngIf="session.contextAllOrganizations()">Please select an organization context to show its settings.</div>
<div *ngIf="!session.contextAllOrganizations()">
    <h3 *ngIf="pageTabs.length == 1">{{ state.activePageTab }}</h3>
    <ul class="nav nav-tabs" *ngIf="pageTabs.length > 1">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab===state.activePageTab">
            <a href="" (click)="state.activePageTab=pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>
    <div *ngIf="isReady && state.activePageTab==='General' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page0"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab==='SMTP Domains' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page1"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab==='Fax' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page2"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab==='PDF Options' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page3"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab==='Logging' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page4"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab==='MFA' && isOrgPropsViewable">
        <ng-container *ngTemplateOutlet="page5"></ng-container>
    </div>
</div>

<!-- General tab -->
<ng-template #page0>
    <form class="form" id="org_general_form" name="org_general_form" novalidate>
        <h4>International traffic</h4>
        <p>Specify the calling code of your country without +. This code will be used to recognize international traffic for users of your organization and therefore require permission to send international messages.<br/>
            For example: enter 1 for USA and Canada, 44 for the UK or 31 for The Netherlands.</p>
        <div class="field">
            <label>Country code:</label>
            +<input type="text" name="countryCode" [(ngModel)]="Organization.General!.CountryCode" size="20" list="country_codes" [disabled]="!isOrgPropsModifiable" />
            <datalist id="country_codes">
                <option value="93">Afghanistan (+93)</option>
                <option value="355">Albania (+355)</option>
                <option value="213">Algeria (+213)</option>
                <option value="684">American Samoa (+684)</option>
                <option value="376">Andorra (+376)</option>
                <option value="244">Angola (+244)</option>
                <option value="809">Anguilla (+809)</option>
                <option value="268">Antigua (+268)</option>
                <option value="54">Argentina (+54)</option>
                <option value="374">Armenia (+374)</option>
                <option value="297">Aruba (+297)</option>
                <option value="247">Ascension Island (+247)</option>
                <option value="61">Australia (+61)</option>
                <option value="672">Australian External Territories (+672)</option>
                <option value="43">Austria (+43)</option>
                <option value="994">Azerbaijan (+994)</option>
                <option value="242">Bahamas (+242)</option>
                <option value="246">Barbados (+246)</option>
                <option value="973">Bahrain (+973)</option>
                <option value="880">Bangladesh (+880)</option>
                <option value="375">Belarus (+375)</option>
                <option value="32">Belgium (+32)</option>
                <option value="501">Belize (+501)</option>
                <option value="229">Benin (+229)</option>
                <option value="809">Bermuda (+809)</option>
                <option value="975">Bhutan (+975)</option>
                <option value="591">Bolivia (+591)</option>
                <option value="387">Bosnia and Hercegovina (+387)</option>
                <option value="267">Botswana (+267)</option>
                <option value="55">Brazil (+55)</option>
                <option value="284">British Virgin Islands (+284)</option>
                <option value="673">Brunei Darussalm (+673)</option>
                <option value="359">Bulgaria (+359)</option>
                <option value="226">Burkina Faso (+226)</option>
                <option value="257">Burundi (+257)</option>
                <option value="855">Cambodia (+855)</option>
                <option value="237">Cameroon (+237)</option>
                <option value="1">Canada (+1)</option>
                <option value="238">Cape Verde Islands (+238)</option>
                <option value="1">Caribbean Nations (+1)</option>
                <option value="345">Cayman Islands (+345)</option>
                <option value="236">Central African Republic (+236)</option>
                <option value="235">Chad (+235)</option>
                <option value="56">Chile (+56)</option>
                <option value="86">China (People's Republic) (+86)</option>
                <option value="886">China-Taiwan (+886)</option>
                <option value="57">Colombia (+57)</option>
                <option value="269">Comoros and Mayotte (+269)</option>
                <option value="242">Congo (+242)</option>
                <option value="682">Cook Islands (+682)</option>
                <option value="506">Costa Rica (+506)</option>
                <option value="385">Croatia (+385)</option>
                <option value="53">Cuba (+53)</option>
                <option value="357">Cyprus (+357)</option>
                <option value="420">Czech Republic (+420)</option>
                <option value="45">Denmark (+45)</option>
                <option value="246">Diego Garcia (+246)</option>
                <option value="767">Dominca (+767)</option>
                <option value="809">Dominican Republic (+809)</option>
                <option value="253">Djibouti (+253)</option>
                <option value="593">Ecuador (+593)</option>
                <option value="20">Egypt (+20)</option>
                <option value="503">El Salvador (+503)</option>
                <option value="240">Equatorial Guinea (+240)</option>
                <option value="291">Eritrea (+291)</option>
                <option value="372">Estonia (+372)</option>
                <option value="251">Ethiopia (+251)</option>
                <option value="500">Falkland Islands (+500)</option>
                <option value="298">Faroe (Faeroe) Islands (Denmark) (+298)</option>
                <option value="679">Fiji (+679)</option>
                <option value="358">Finland (+358)</option>
                <option value="33">France (+33)</option>
                <option value="596">French Antilles (+596)</option>
                <option value="594">French Guiana (+594)</option>
                <option value="241">Gabon (Gabonese Republic) (+241)</option>
                <option value="220">Gambia (+220)</option>
                <option value="995">Georgia (+995)</option>
                <option value="49">Germany (+49)</option>
                <option value="233">Ghana (+233)</option>
                <option value="350">Gibraltar (+350)</option>
                <option value="30">Greece (+30)</option>
                <option value="299">Greenland (+299)</option>
                <option value="473">Grenada/Carricou (+473)</option>
                <option value="671">Guam (+671)</option>
                <option value="502">Guatemala (+502)</option>
                <option value="224">Guinea (+224)</option>
                <option value="245">Guinea-Bissau (+245)</option>
                <option value="592">Guyana (+592)</option>
                <option value="509">Haiti (+509)</option>
                <option value="504">Honduras (+504)</option>
                <option value="852">Hong Kong (+852)</option>
                <option value="36">Hungary (+36)</option>
                <option value="354">Iceland (+354)</option>
                <option value="91">India (+91)</option>
                <option value="62">Indonesia (+62)</option>
                <option value="98">Iran (+98)</option>
                <option value="964">Iraq (+964)</option>
                <option value="353">Ireland (Irish Republic; Eire) (+353)</option>
                <option value="972">Israel (+972)</option>
                <option value="39">Italy (+39)</option>
                <option value="225">Ivory Coast (La Cote d'Ivoire) (+225)</option>
                <option value="876">Jamaica (+876)</option>
                <option value="81">Japan (+81)</option>
                <option value="962">Jordan (+962)</option>
                <option value="7">Kazakhstan (+7)</option>
                <option value="254">Kenya (+254)</option>
                <option value="855">Khmer Republic (Cambodia/Kampuchea) (+855)</option>
                <option value="686">Kiribati Republic (Gilbert Islands) (+686)</option>
                <option value="850">Korea, People's Republic of (North Korea) (+850)</option>
                <option value="82">Korea, Republic of (South Korea) (+82)</option>
                <option value="965">Kuwait (+965)</option>
                <option value="996">Kyrgyz Republic (+996)</option>
                <option value="856">Laos (+856)</option>
                <option value="371">Latvia (+371)</option>
                <option value="961">Lebanon (+961)</option>
                <option value="266">Lesotho (+266)</option>
                <option value="231">Liberia (+231)</option>
                <option value="218">Libya (+218)</option>
                <option value="423">Liechtenstein (+423)</option>
                <option value="370">Lithuania (+370)</option>
                <option value="352">Luxembourg (+352)</option>
                <option value="853">Macao (+853)</option>
                <option value="389">Macedonia (+389)</option>
                <option value="261">Madagascar (+261)</option>
                <option value="265">Malawi (+265)</option>
                <option value="60">Malaysia (+60)</option>
                <option value="960">Maldives (+960)</option>
                <option value="223">Mali (+223)</option>
                <option value="356">Malta (+356)</option>
                <option value="692">Marshall Islands (+692)</option>
                <option value="596">Martinique (French Antilles) (+596)</option>
                <option value="222">Mauritania (+222)</option>
                <option value="230">Mauritius (+230)</option>
                <option value="269">Mayolte (+269)</option>
                <option value="52">Mexico (+52)</option>
                <option value="691">Micronesia (F.S. of Polynesia) (+691)</option>
                <option value="373">Moldova (+373)</option>
                <option value="33">Monaco (+33)</option>
                <option value="976">Mongolia (+976)</option>
                <option value="473">Montserrat (+473)</option>
                <option value="212">Morocco (+212)</option>
                <option value="258">Mozambique (+258)</option>
                <option value="95">Myanmar (former Burma) (+95)</option>
                <option value="264">Namibia (former South-West Africa) (+264)</option>
                <option value="674">Nauru (+674)</option>
                <option value="977">Nepal (+977)</option>
                <option value="31">Netherlands (+31)</option>
                <option value="599">Netherlands Antilles (+599)</option>
                <option value="869">Nevis (+869)</option>
                <option value="687">New Caledonia (+687)</option>
                <option value="64">New Zealand (+64)</option>
                <option value="505">Nicaragua (+505)</option>
                <option value="227">Niger (+227)</option>
                <option value="234">Nigeria (+234)</option>
                <option value="683">Niue (+683)</option>
                <option value="850">North Korea (+850)</option>
                <option value="1670">North Mariana Islands (Saipan) (+1670)</option>
                <option value="47">Norway (+47)</option>
                <option value="968">Oman (+968)</option>
                <option value="92">Pakistan (+92)</option>
                <option value="680">Palau (+680)</option>
                <option value="507">Panama (+507)</option>
                <option value="675">Papua New Guinea (+675)</option>
                <option value="595">Paraguay (+595)</option>
                <option value="51">Peru (+51)</option>
                <option value="63">Philippines (+63)</option>
                <option value="48">Poland (+48)</option>
                <option value="351">Portugal (includes Azores) (+351)</option>
                <option value="1787">Puerto Rico (+1787)</option>
                <option value="974">Qatar (+974)</option>
                <option value="262">Reunion (France) (+262)</option>
                <option value="40">Romania (+40)</option>
                <option value="7">Russia (+7)</option>
                <option value="250">Rwanda (Rwandese Republic) (+250)</option>
                <option value="670">Saipan (+670)</option>
                <option value="378">San Marino (+378)</option>
                <option value="239">Sao Tome and Principe (+239)</option>
                <option value="966">Saudi Arabia (+966)</option>
                <option value="221">Senegal (+221)</option>
                <option value="381">Serbia and Montenegro (+381)</option>
                <option value="248">Seychelles (+248)</option>
                <option value="232">Sierra Leone (+232)</option>
                <option value="65">Singapore (+65)</option>
                <option value="421">Slovakia (+421)</option>
                <option value="386">Slovenia (+386)</option>
                <option value="677">Solomon Islands (+677)</option>
                <option value="252">Somalia (+252)</option>
                <option value="27">South Africa (+27)</option>
                <option value="34">Spain (+34)</option>
                <option value="94">Sri Lanka (+94)</option>
                <option value="290">St. Helena (+290)</option>
                <option value="869">St. Kitts/Nevis (+869)</option>
                <option value="508">St. Pierre &(et) Miquelon (France) (+508)</option>
                <option value="249">Sudan (+249)</option>
                <option value="597">Suriname (+597)</option>
                <option value="268">Swaziland (+268)</option>
                <option value="46">Sweden (+46)</option>
                <option value="41">Switzerland (+41)</option>
                <option value="963">Syrian Arab Republic (Syria) (+963)</option>
                <option value="689">Tahiti (French Polynesia) (+689)</option>
                <option value="886">Taiwan (+886)</option>
                <option value="7">Tajikistan (+7)</option>
                <option value="255">Tanzania (includes Zanzibar) (+255)</option>
                <option value="66">Thailand (+66)</option>
                <option value="228">Togo (Togolese Republic) (+228)</option>
                <option value="690">Tokelau (+690)</option>
                <option value="676">Tonga (+676)</option>
                <option value="1868">Trinidad and Tobago (+1868)</option>
                <option value="216">Tunisia (+216)</option>
                <option value="90">Turkey (+90)</option>
                <option value="993">Turkmenistan (+993)</option>
                <option value="688">Tuvalu (Ellice Islands) (+688)</option>
                <option value="256">Uganda (+256)</option>
                <option value="380">Ukraine (+380)</option>
                <option value="971">United Arab Emirates (+971)</option>
                <option value="44">United Kingdom (+44)</option>
                <option value="598">Uruguay (+598)</option>
                <option value="1">USA (+1)</option>
                <option value="7">Uzbekistan (+7)</option>
                <option value="678">Vanuatu (New Hebrides) (+678)</option>
                <option value="39">Vatican City (+39)</option>
                <option value="58">Venezuela (+58)</option>
                <option value="84">Viet Nam (+84)</option>
                <option value="1340">Virgin Islands (+1340)</option>
                <option value="681">Wallis and Futuna (+681)</option>
                <option value="685">Western Samoa (+685)</option>
                <option value="381">Yemen (People's Democratic Republic of) (+381)</option>
                <option value="967">Yemen Arab Republic (North Yemen) (+967)</option>
                <option value="381">Yugoslavia (discontinued) (+381)</option>
                <option value="243">Zaire (+243)</option>
                <option value="260">Zambia (+260)</option>
                <option value="263">Zimbabwe (+263)</option>
            </datalist>
        </div>
        <p>Specify a default location and time zone for your organization.  This information is used
           when logging local times, and for displaying local times on fax coversheets and notifications.
           If desired, these defaults can be overruled in the user settings for each individual user.</p>
        <div class="field">
            <label>Default location:</label>
            <select name="countryISO2" [(ngModel)]="Organization.General!.CountryISO2" [disabled]="!isOrgPropsModifiable">
                <option *ngFor="let c of numSubstCountries" [value]="c.ISO2">{{ c.Name }}</option>
            </select>
        </div>
        <div class="field">
            <label>Default time zone:</label>
            <select name="timeZone" [(ngModel)]="Organization.General!.TimeZone" [disabled]="!isOrgPropsModifiable">
                <option *ngFor="let tz of TimeZones" [value]="tz.Id">{{ tz.DisplayName }}</option>
            </select>
        </div>
        <button type="button" class="btn btn-primary" (click)="generalFormApply()" [disabled]="!isOrgPropsModifiable">Apply</button>
    </form>
</ng-template>

<!-- SMTP Domains tab -->
<ng-template #page1>
    <form id="smtp_domains_form" class="form" novalidate>
        <p>These domains are used when the kernel receives an email message from a yet unknown user. When the user's email domain is in the list then the user is automatically added to this organization based on the checkbox below:</p>
        <p>
            <input type="checkbox" name="autoAddNewUsers" [(ngModel)]="Organization.AutoAddNewUsers" [disabled]="!isOrgPropsModifiable" (ngModelChange)="changeDefaultAutoAddNewUsers()" >
            <label>&nbsp;Automatically add new users (organization default).</label>
        </p>
        <p><span *ngIf="isOrgPropsModifiable">If desired, the organization default can be overruled for each individual domain. </span>
        Newly added or edited domains first need to be verified before they become active. This is an essential step to ensure that the domain you added is valid, not expired and that the user who added the domain has the required privileges to access DNS for the domain.</p>

        <div class="btn-toolbar">
            <button type="button" class="btn" (click)="domain_add()" [disabled]="!isOrgPropsModifiable || !domain_add_enabled()">New</button>
            <button type="button" class="btn" (click)="domain_remove()" [disabled]="!isOrgPropsModifiable || (this.fenUtils.nothingSelected(SmtpDomains) && this.fenUtils.nothingSelected(SmtpDomainsNew))">Remove</button>
            <button type="button" class="btn" (click)="domain_verify(false)" [disabled]="!isOrgPropsModifiable || (this.fenUtils.nothingSelected(SmtpDomains) && this.fenUtils.nothingSelected(SmtpDomainsNew))">Verify</button>
            <button type="button" class="btn" (click)="domain_verify(true)" *ngIf="isOrganizationsModifiable" [disabled]="(this.fenUtils.nothingSelected(SmtpDomains) && this.fenUtils.nothingSelected(SmtpDomainsNew))">Verify Bypass</button>
        </div>

        <table id="smtp_domains" class="table table-click table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="25px"></th>
                    <th>Domain</th>
                    <th width="120px">Status</th>
                    <th width="120px" *ngIf="isOrgPropsModifiable">Automatically Add New Users</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let domain of SmtpDomains; let idx = index;">
                    <td><input type="checkbox" name="domainSelected{{ idx }}" [(ngModel)]="domain.selected" (click)="$event.stopPropagation()"></td>
                    <td>{{ domain.Name }}</td>
                    <td><span [ngStyle]="domain_status_style(domain)">{{ domain.Status }}</span></td>
                    <td *ngIf="isOrgPropsModifiable">
                        <select *ngIf="domain.Status==='active'" name="domainAutoAdd{{ idx }}" [(ngModel)]="domain.autoAdd" (ngModelChange)="changeAutoAddNewUsers(domain)">
                            <option *ngFor="let c of autoAddOptions" [ngValue]="c">{{ c.value }}</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="smtp_domains_new" class="table-condensed" *ngIf="SmtpDomainsNew.length>0">
			<tbody *ngFor="let domain of SmtpDomainsNew; let idx = index;">
				<tr>
					<td width="25px"><input type="checkbox" name="domainNewSelected{{ idx }}" [(ngModel)]="domain.selected" (click)="$event.stopPropagation()"></td>
					<td><input type="text" style="width: 400px;" name="domainNewName{{ idx }}" [(ngModel)]="domain.Name" (ngModelChange)="domain.Status=''" (focusin)="domain.Error=''" (focusout)="domain_save(SmtpDomains, SmtpDomainsNew).subscribe()"></td>
					<td width="120px"><span [ngStyle]="domain_status_style(domain)">{{ domain.Status }}</span></td>
                    <td width="120px" *ngIf="isOrgPropsModifiable">
                        <select *ngIf="domain.Status==='active'" name="domainNewAutoAdd{{ idx }}" [(ngModel)]="domain.autoAdd" (ngModelChange)="changeAutoAddNewUsers(domain)">
                            <option *ngFor="let c of autoAddOptions" [ngValue]="c">{{ c.value }}</option>
                        </select>
                    </td>
				</tr>
				<tr *ngIf="domain.Error">
					<td></td>
					<td><span class="text-danger">{{ domain.Error }}</span></td>
				</tr>
			</tbody>
        </table>
        <div *ngIf="(!SmtpDomains || SmtpDomains.length===0) && (!SmtpDomainsNew || SmtpDomainsNew.length===0)">
            <span>No Results</span>
        </div>

        <div id="smtp_verify_help" *ngIf="isOrgPropsModifiable">
            <br />
            <p>Before you click on the Verify button, first create a new TXT record with your domain name registrar using the info below:</p>
            <table class="table table-condensed table-bordered">
                <thead>
                    <tr>
                        <th>Record Type</th>
                        <th>Name / Host / Alias</th>
                        <th>Value / Points To / Destination</th>
                        <th>TTL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>TXT</td>
                        <td>&#64;&nbsp;&nbsp;or&nbsp;&nbsp;domain name</td>
                        <td>{{ Organization.SmtpDomainsChallenge }}</td>
                        <td>3600</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </form>
</ng-template>

<!-- Fax tab -->
<ng-template #page2>
    <!-- This min-height creates empty space for the FilenameTemplate options to expand into: -->
    <form class="form" style="min-height: 800px;" id="fax_form" name="faxForm" novalidate>
        <h4>Identification</h4>
        <p>The Called Subscriber Identification (CSID) defined here will be used unless a specific CSID is defined for the user.</p>
        <div class="field">
            <label>CSID:</label>
            <input type="text" name="CSID" #CSID="ngModel" [(ngModel)]="Organization.Fax!.CSID" size="40" maxlength="20" [disabled]="!isOrgPropsModifiable" />
            <span class="text-danger" *ngIf="CSID.errors?.['maxlength']">&nbsp;maximum 20 characters</span>
        </div>
        <h4>Inbound</h4>
        <p>Messages routed to users via SMTP will use the filename template set below. Filenames will automatically receive a proper extension.</p>
        <div class="field">
            <label>Template:</label>
            <app-filename-template name="FilenameTemplate" [(model)]="Organization.Inbound!.FilenameTemplate" [options]="FilenameTemplateFields"
                title="Enter the filename format." [disabled]="!isOrgPropsModifiable">
            </app-filename-template>&nbsp;
            <img src="gear-icon.png" class="clickable" (click)="fileNameDateTimeFormat()" 
                title="Datetime configuration..." >
        </div>
        <h4>Outbound</h4>
        <p>For faxes sent via SMTP, you can overrule whether the original fax will be included in delivery and/or non-delivery reports. The default is to use the currently configured SMTP options of the kernel.</p>
        <div class="field">
            <label>Attachment options:</label>
            <select [(ngModel)]="attachmentOption" name="AttachmentOptions" [disabled]="!isOrgPropsModifiable">
                <option *ngFor="let c of attachmentOptions" [ngValue]="c">{{ c.Description }}</option>
            </select>
        </div>
        <button type="button" class="btn btn-primary" (click)="faxFormApply()" [disabled]="!isOrgPropsModifiable || !CSID.valid">Apply</button>
    </form>
</ng-template>

<!-- PDF Options tab -->
<ng-template #page3>
    <p>Options for the PDF files created by Faxination.</p>
    <form id="pdf_options" class="form" novalidate>
        <div class="field">
            <input type="checkbox" name="PdfXmpEnabled" [(ngModel)]="Organization.PdfXmp!.Enabled" (ngModelChange)="pdfxmp_change()" [disabled]="!isOrgPropsModifiable" >
            Write fax information into XMP metadata when delivering PDFs.
        </div>
        <div class="field">
            <input type="checkbox" name="PdfTimeStampEnabled" [(ngModel)]="Organization.PdfTimeStamp!.Enabled" (ngModelChange)="pdftimestamp_change()" [disabled]="!isOrgPropsModifiable" >
            Add a date/time stamp on the first page when delivering received faxes as PDFs.
        </div>
        <div class="field">
            <label><br /></label>
            <button type="button" class="btn" (click)="pdftimestamp_config()" [disabled]="!isOrgPropsModifiable">Configure...</button>
        </div>
    </form>
</ng-template>

<!-- Logging tab -->
<ng-template #page4>
    <p>Options for logging the jobs that are processed by the system.</p>
    <form id="log_options" class="form" novalidate>
        <h4>Backup</h4>
        <div class="field">
            <input type="checkbox" name="LoggingBackupEnabled" [(ngModel)]="BackupEnabled" (change)="logging_backup_enable()" [disabled]="!isOrgPropsModifiable" >
            Enable backup of all incoming and outgoing messages. Stored backups can be retrieved via the Log page.<br/>
        </div>
        <div class="field" *ngIf="!session.isMultiTenant() || !BackupAgeMax" style="margin-bottom: 0;">
            <input type="radio" name="LoggingBackupAgeInfinite" [(ngModel)]="BackupAgeInfinite" id="LoggingBackupAgeInfinite_true"
                [value]="true" [attr.value]="'true'" [disabled]="!isOrgPropsModifiable" (change)="logging_change()">
            <label for="LoggingBackupAgeInfinite_true">Keep stored backups.</label>
        </div>
        <div class="field" *ngIf="!session.isMultiTenant() || !BackupAgeMax">
            <input type="radio" name="LoggingBackupAgeInfinite" [(ngModel)]="BackupAgeInfinite" id="LoggingBackupAgeInfinite_false"
                [value]="false" [attr.value]="'false'" [disabled]="!isOrgPropsModifiable" (change)="logging_change()">
            <label for="LoggingBackupAgeInfinite_false">Remove stored backups after &nbsp;</label>
            <input type="text" name="LoggingBackupAge" [(ngModel)]="BackupAge" [disabled]="!isOrgPropsModifiable || BackupAgeInfinite"
                fen-uint-only required size="5" (focusout)="logging_change()" />&nbsp;<span> day(s).</span>
        </div>
        <div class="field" *ngIf="session.isMultiTenant() && BackupAgeMax">
            <span>Remove stored backups after &nbsp;</span>
            <input type="text" name="LoggingBackupAge" [(ngModel)]="BackupAge" [disabled]="!isOrgPropsModifiable"
                fen-uint-only required size="5" (focusout)="logging_change()" />&nbsp;<span> day(s).</span>
            <span class="text-danger" *ngIf="logging_backupage_valid()===null">&nbsp;Please specify a value, maximum is {{ BackupAgeMax }}</span>
        </div>
    </form>
</ng-template>

<!-- MFA tab -->
<ng-template #page5>
    <h4>Global Multi-Factor Authentication (MFA) options</h4>
    <p>You can enforce the following MFA options for Faxination Management Portal accounts of the "Local" account type.
       These options apply globally to the organization and overrule any per-account MFA options. When MFA is enabled and a user attempts
       to login, the portal will email a code consisting of a random sequence of digits to the mailbox associated with the user account.
       The user must enter that code in order to complete the login.
    </p>
    <form class="form" id="mfa_form" name="mfaForm" #mfaForm="ngForm" (submit)="mfa_Apply(mfaForm)" novalidate>
        <div class="field">
            <input type="checkbox" name="Enforced" id="MfaEnforced"
            [(ngModel)]="MFA.Enforced" [disabled]="!isOrgPropsModifiable">
            <label for="MfaEnforced">&nbsp;Enable multi-factor authentication for all Local accounts.</label>
        </div>
        <div class="field">
            <label class="mfa-align">Set a code length of &nbsp;</label>
            <input type="number" name="CodeLength" #CodeLength="ngModel" [(ngModel)]="MFA.CodeLength"
                size="2" min="{{ MFA_CODE_LENGTH_MINIMUM }}" max="{{ MFA_CODE_LENGTH_MAXIMUM }}"
                [disabled]="!isOrgPropsModifiable" [required]="isOrgPropsModifiable"
                title="The length of the MFA codes to generate">
            <span>&nbsp;digit(s).</span>
            @if (CodeLength.errors?.['min'] || CodeLength.errors?.['max']) {
                <span class="text-danger">&nbsp;&nbsp;Please specify a value from {{ MFA_CODE_LENGTH_MINIMUM }} to {{ MFA_CODE_LENGTH_MAXIMUM }}</span>
            }
        </div>
        <div class="field">
            <label class="mfa-align">Set a code lifetime of &nbsp;</label>
            <input type="number" name="CodeLifetime" #CodeLifetime="ngModel" [(ngModel)]="MFA.CodeLifetime"
                size="2" min="{{ MFA_CODE_LIFETIME_MINIMUM }}" max="{{ MFA_CODE_LIFETIME_MAXIMUM }}"
                [disabled]="!isOrgPropsModifiable" [required]="isOrgPropsModifiable"
                title="The lifetime until an MFA code expires">
            <span>&nbsp;minute(s).</span>
            @if (CodeLifetime.errors?.['min'] || CodeLifetime.errors?.['max']) {
                <span class="text-danger">&nbsp;&nbsp;Please specify a value from {{ MFA_CODE_LIFETIME_MINIMUM }} to {{ MFA_CODE_LIFETIME_MAXIMUM }}</span>
            }
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!mfaForm.dirty || !mfaForm.valid">Apply</button>
    </form>
</ng-template>
