import { ApplicationConfig } from '@angular/core';
import { provideRouter, withDebugTracing, withHashLocation } from '@angular/router';
import { routes } from './app.routes';
import { httpInterceptorProviders } from './http-interceptors';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FaxConfigApi } from './api/faxconfig';
import { PortalConfig } from './services/portalconfig.service';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalService, MsalBroadcastService } from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: '00000000-0000-0000-0000-000000000000',
            authority: 'https://login.microsoftonline.com/common',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
            allowPlatformBroker: false    // Disables WAM Broker, which only exists on Windows
        }
    });
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes,withDebugTracing(),withHashLocation()), 
        provideHttpClient(withInterceptorsFromDi()),
        httpInterceptorProviders,
        FaxConfigApi,
        PortalConfig,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        MsalService,
        MsalBroadcastService
    ]
};


