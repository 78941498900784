import { Component, inject, HostListener } from '@angular/core';
import { CommonModule, NgFor, DatePipe } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestServicesKernels, FaxConfigRestSystemInfoList } from '../api/api';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, GridOptions, GridReadyEvent, GetContextMenuItemsParams,
    MenuItemDef, PaginationChangedEvent, CsvExportParams, ValueGetterParams, ICellRendererParams
} from 'ag-grid-community';

interface ICategory {
    Category: string,
    Description: string
}

@Component({
    selector: 'app-system-info',
    imports: [FormsModule, CommonModule, NgFor, AgGridAngular],
    providers: [DatePipe],
    templateUrl: './system-info.component.html',
    styleUrl: './system-info.component.css'
})
export class SystemInfoComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    constructor (public auth: AuthService, public session: Session, private datePipe: DatePipe) {
        this.session.rootPromises.subscribe(res => this.init());
    }

    isReady: boolean = false;
    systemInfo: { [key: string]: FaxConfigRestSystemInfoList } = {};
    cssGridHeight = { 'height': '300px' };

    servicesKernels: FaxConfigRestServicesKernels = {};

    availableKernels: string[] = [];
    availableCategories: ICategory[] = [
        { Category: 'Services', Description: 'Installed services' },
        { Category: 'ActiveX', Description: 'Shared ActiveX components' },
        { Category: 'DotNet', Description: 'Shared .Net assemblies' },
        { Category: 'Tools', Description: 'Tools and configurators' }
    ];

    currentKernel: string = '';
    selectedKernel: string = '';
    currentCategory: string = '';
    selectedCategory: string = '';

    systemInfoGridInitialized: boolean = false;
    systemInfoGridOptions: GridOptions = {};
    gridApi?: GridApi;

    versionComparator(valA: string, valB: string): number {
        let splitA = valA.split('.');
        let splitB = valB.split('.');
        while (splitA.length < splitB.length) { splitA.push('0'); }
        while (splitB.length < splitA.length) { splitB.push('0'); }
        for (let idx = 0; idx < splitA.length; idx++) {
            const numA = parseInt(splitA[idx]);
            const numB = parseInt(splitB[idx]);
            if (numA < numB) return -1;
            if (numA > numB) return 1;
        }
        return 0;
    }

    systemInfoGridColDefs: ColDef<any>[] = [
        {
            headerName: 'Name',
            field: 'FileName',
            tooltipField: 'FullName',
            headerTooltip: 'File Name',
            comparator: (valA: string, valB: string): number => {
                return valA.toLowerCase().localeCompare(valB.toLowerCase());
            },
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            field: 'FullName',
            hide: true,
            filter: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            headerName: 'Date',
            field: 'LastModified',
            tooltipField: 'LastModified',
            headerTooltip: 'Last Modified Date',
            cellRenderer: (params: ICellRendererParams) => {
                let val = params.data? params.data.LastModified: null;
                if (val) {
                    let date = new Date(val);
                    let options: Intl.DateTimeFormatOptions = {
                        year: "numeric",
                        month: "short",
                        day: "2-digit"
                    };
                    return new Intl.DateTimeFormat(undefined, options).format(date);
                }
                return '';
            },
            filter: 'agDateColumnFilter',
            filterParams: { buttons: ['reset'] },
            filterValueGetter: (params: ValueGetterParams) => {
                let val = params.data? params.data.LastModified: null;
                if (val) {
                    let date = new Date(val);
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    return date;
                }
                return new Date(0);
            }
        },
        {
            headerName: 'Size',
            field: 'FileSize',
            headerTooltip: 'File Size',
            cellClass: 'ag-right-aligned-cell',
            valueFormatter: function(params) {
                return params.value.toLocaleString();
            },
            filter: 'agNumberColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
        {
            headerName: 'Version',
            field: 'FileVersion',
            headerTooltip: 'File Version',
            cellClass: 'ag-right-aligned-cell',
            comparator: this.versionComparator,
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['reset'] }
        },
    ];

    init(): void {
        this.systemInfoGridOptions = {
            columnDefs: this.systemInfoGridColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            rowModelType: 'clientSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            defaultCsvExportParams: { allColumns: true, fileName: 'export_systeminfo.csv' },
            onGridReady: (params: GridReadyEvent) => {
                this.gridApi = params.api;
                let height = Math.max(300, window.innerHeight - 50);
                this.cssGridHeight = { 'height': height + 'px' };
                this.systemInfoLoadViewState(params.api);
                this.faxSrv.getServicesKernels().subscribe(res => {
                    this.servicesKernels = res;
                    this.availableKernels.push(res.LocalKernel!);
                    if (res.RemoteKernels) {
                        _.each(res.RemoteKernels, name => {
                            this.availableKernels.push(name);
                        });
                    }
                    this.selectedKernel = this.availableKernels[0];
                    this.isReady = true;
                    this.refresh();
                });
            },
            onPaginationChanged: params => this.systemInfoOnPaginationChanged(params),
            getContextMenuItems: params => this.systemInfoGetContextMenuItems(params),
            onGridPreDestroyed: params =>this.systemInfoSaveViewState(params.api)
        };
        this.systemInfoGridInitialized = true;
    }

    private systemInfoLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'SystemInfoViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    };

    private systemInfoSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'SystemInfoViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    refresh(): void {
        if (this.selectedKernel != this.currentKernel) {
            this.currentKernel = this.selectedKernel;
            this.currentCategory = '';
            this.selectedCategory = this.availableCategories[0].Category;
            this.systemInfo = {};
        }
        if (this.selectedCategory != this.currentCategory) {
            this.currentCategory = this.selectedCategory;
            if (this.systemInfo) {
                if (this.systemInfo[this.currentCategory]) {
                    this.gridApi?.setGridOption('rowData', this.systemInfo[this.currentCategory]);
                } else {
                    this.gridApi?.setGridOption('rowData', null);
                    this.gridApi?.showLoadingOverlay();
                    this.faxSrv.GetSystemInfo(this.currentCategory, this.currentKernel).subscribe({
                        next: info => {
                            this.systemInfo[this.currentCategory] = info.Files?? [];
                            this.gridApi?.setGridOption('rowData', this.systemInfo[this.currentCategory]);
                        },
                        error: err => {
                            this.currentCategory = '';
                            this.gridApi?.setGridOption('rowData', []);
                        }
                    });
                }
            }
        }
    };

    hasMultipleKernels(): boolean {
        return (this.availableKernels && this.availableKernels.length > 1);
    };

    private systemInfoGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        return ['copy','separator',
            {
                name: 'CSV Export',
                action: () => { this.export(); },
                disabled : false
            }
        ];
    }

    private systemInfoOnPaginationChanged(params: PaginationChangedEvent): void {
        params.api.deselectAll();
    }

    export(): void {
        if (this.systemInfo && this.gridApi) {
            // Temporarily push all info that we have into the grid.
            let info: FaxConfigRestSystemInfoList = [];
            _.each(this.availableCategories, item => {
                if (this.systemInfo[item.Category]) {
                    info = info.concat(this.systemInfo[item.Category]);
                }
            });
            if (info.length > 0) {
                let params: CsvExportParams = { fileName: 'export_systeminfo_' + this.currentKernel + '.csv' };
                this.gridApi.setGridOption('rowData', info);
                this.gridApi.exportDataAsCsv(params);
                this.gridApi.setGridOption('rowData', this.systemInfo[this.currentCategory]);
            }
        }
    };

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }
}
