//import {PermissionSet} from '../constants/app.constants'
import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
//import {CookieService} from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //$cookies = inject(CookieService);
  authSvc = inject(AuthService);

  constructor( ) { 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.authSvc.getAuthHeader();
    const router = inject(Router);
    

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq = authToken ? req.clone({
      headers: req.headers.set('Authorization', authToken)
    }): req;

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe( 
      tap(
        {
          next: (event) => {
            if (event instanceof HttpResponse){
              if (event.headers.has('x-fen-auth-jwt') ){
                this.authSvc.updateAuthToken(event.headers.get('x-fen-auth-jwt'));
              }
              var newOrgContext = event.headers.get('x-fen-org-context');
              if (newOrgContext) {
                let newOrgContextId = parseInt(newOrgContext, 10);
                if (newOrgContextId != this.authSvc.getContextOrgId()) { // refresh context
                    this.authSvc.setOrganization(this.authSvc.getUserOrgId(), newOrgContextId);
                }
              }
            }
          },
          error: (event) => {
            if (event instanceof HttpErrorResponse){
              if (event.status === 401){
                this.authSvc.setCredentials(null,null);
                router.navigate(['/login']);
              }
            }
          }
        }
      )
    );
  }

}

