<h1>API Keys</h1>

<div>
    <div id="apikeys_help">
        <p *ngIf="auth.isViewable('APIKeys')">This page shows all API Keys. An API Key acts as a password to access API-connectors such as the Faxination REST Connector.</p>
        <p *ngIf="auth.isModifiable('APIKeys')">
            <span *ngIf="!session.contextAllOrganizations()">You can add, revoke, reactivate, or delete API Keys.</span>
            <span *ngIf="session.contextAllOrganizations()">You can revoke, reactivate, or delete existing API Keys. To add a new API Key, please select an organization context.</span>
            <br/><b>Note: </b>An API Key can only be viewed upon creation and is not stored anywhere.
        </p>
        <br/>
    </div>
    <div class="bar-row">
        <div class="btn-toolbar" *ngIf="auth.isModifiable('APIKeys')">
            <button type="button" class="btn" (click)="new_api_key()" [disabled]="session.contextAllOrganizations()">Add</button>
            <button type="button" class="btn" (click)="revoke_api_key()" [disabled]="!isAnyActivated()">Revoke</button>
            <button type="button" class="btn" (click)="activate_api_key()" [disabled]="!isAnyRevoked()">Reactivate</button>
            <button type="button" class="btn" (click)="delete_api_key()" [disabled]="!isAnySelected()">Delete</button>
        </div>
    </div>
    <div *ngIf="!auth.isModifiable('APIKeys')">
        <br/>
    </div>

    <div id="list_content">
        <app-pagination [limit]="session.portalCfg.items_per_page" [query]="query_api_keys"></app-pagination>

        <table *ngIf="pagerReady" id="apikeylist" class="table table-click table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="200px">Description</th>
                    <th width="60px" style="text-align: center">Expires</th>
                    <th width="25px" style="text-align: center" >Active</th>
                    <th width="100px" *ngIf="session.contextAllOrganizations()">Organization</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of pagerApiKeys.items" (click)="select_item(item)" [class.selected]="item.selected?? false">
                    <td>{{ item.Description }}</td>
                    <td style="text-align: right">
                        <div *ngIf="isInfinite(item)" >Never</div>
                        <div *ngIf="!isInfinite(item)">{{ item.Expires | date:'yyyy-MM-dd' }}</div>
                    </td>
                    <td style="text-align: center">{{ (item.Enabled!='') | boolean }}</td>
                    <td *ngIf="session.contextAllOrganizations()">{{ item.OrganizationName }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="pagerReady && pagerApiKeys.isEmpty()">No Results</div>
    </div>

</div>
