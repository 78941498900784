<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="!scope.channel.SystemAddress">Default </span>Channel
        <span *ngIf="scope.channel.SystemAddress"> {{ scope.channel.SystemAddress }}</span>
        <span *ngIf="scope.hostGeneral"> on {{ scope.hostGeneral.FriendlyName }}</span></h1>
    </div>
    <form id="channel_form" class="form" #channelForm="ngForm" novalidate (submit)="save()">
        <div class="modal-body" *ngIf="isReady" style="min-height:400px;">
            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab }}</a>
                </li>
            </ul>
            <div [hidden]="state.activePageTab !== 'Name'">
                <ng-container *ngTemplateOutlet="page0"></ng-container>
            </div>
            <div [hidden]="state.activePageTab !== 'SMTP Domains'">
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </div>
            <div [hidden]="state.activePageTab !== 'Attachments'">
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </div>
            <div [hidden]="state.activePageTab !== 'Body Types'">
                <ng-container *ngTemplateOutlet="page3"></ng-container>
            </div>
            <div [hidden]="state.activePageTab !== 'New User Defaults'">
                <ng-container *ngTemplateOutlet="page4"></ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="isInvalid()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>

<!-- Name tab -->
<ng-template #page0>
    <p>Enter an optional friendly name to describe this channel.</p>
    <div class="field">
        <label>Friendly Name:</label>
        <input type="text" maxlength="4000" name="FriendlyName" [(ngModel)]="scope.channel.FriendlyName">
    </div>
</ng-template>

<!-- SMTP Domains tab -->
<ng-template #page1>
    <p>List of SMTP domains which can be used to route messages to this channel.</p>
    <div class="btn-toolbar">
        <button type="button" class="btn" (click)="domain_remove()" [disabled]="fenUtils.nothingSelected(SmtpDomains)">Remove</button>
    </div>
    <table id="smtp_domains" class="table table-click table-condensed table-bordered">
        <tbody>
            <tr *ngFor="let domain of SmtpDomains">
                <td width="25px"><input type="checkbox" [(ngModel)]="domain.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ domain.Name }}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="NewSmtpDomains.length > 0">
        <table class="table-condensed" style="table-layout: fixed; margin-top: 10px;">
            <tbody>
                <tr *ngFor="let domain of NewSmtpDomains">
                    <td><input type="text" maxlength="4000" name="domainName" [(ngModel)]="domain.Name"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!hasAnySmtpDomain()">
        <button class="btn btn-link" type="button" (click)="domain_add()">+ Add SMTP Domain</button>
    </div>
</ng-template>

<!-- Attachments tab -->
<ng-template #page2>
    <p>Attachment types supported by this channel.</p>
    <div *ngIf="hasAny">
        <div>
            <input type="radio" name="ActiveAtt" [(ngModel)]="state.activeAttRadio" id="ActiveAtt_any" value="any">
            <label for="ActiveAtt_any">Any</label>
        </div>
        <div>
            <input type="radio" name="ActiveAtt" [(ngModel)]="state.activeAttRadio" id="ActiveAtt_specify" value="specify">
            <label for="ActiveAtt_specify">Specify</label>
        </div>
        <div style="margin-left: 20px;">
            <table class="table table-click table-condensed table-bordered" *ngIf="!is_Any_selected()">
                <tbody>
                    <tr *ngFor="let att of AttTypes; let idx = index;">
                        <td width="25px"><input type="checkbox" name="AttSelected{{ idx }}" [(ngModel)]="att.selected" (click)="$event.stopPropagation()"></td>
                        <td>{{ att.Description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <table class="table table-click table-condensed table-bordered" *ngIf="!hasAny">
        <tbody>
            <tr *ngFor="let att of AttTypes; let idx = index;">
                <td width="25px"><input type="checkbox" name="AttSelected{{ idx }}" [(ngModel)]="att.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ att.Description }}</td>
            </tr>
        </tbody>
    </table>
</ng-template>

<!-- Body Types tab -->
<ng-template #page3>
    <p>Message body types supported by this channel.</p>
    <table class="table table-click table-condensed table-bordered">
        <tbody>
            <tr *ngFor="let body of BodyTypes; let idx = index;">
                <td width="25px"><input type="checkbox" name="BodySelected{{ idx }}" [(ngModel)]="body.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ body.Description }}</td>
            </tr>
        </tbody>
    </table>
</ng-template>

<!-- New User Defaults tab -->
<ng-template #page4>
    <p>You can optionally specify custom defaults to use when a new Faxination user is created for this channel.</p>
    <div style="margin-bottom: 20px;">
        <input type="checkbox" name="UseCustomUserDefaults" [(ngModel)]="scope.channel.UseCustomUserDefaults">
        <label>Use custom new user defaults</label>
    </div>
    <app-new-user-defaults [hidden]="!scope.channel.UseCustomUserDefaults"></app-new-user-defaults>
</ng-template>
