import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Buffer } from 'buffer/';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { DialogService } from '../dialog/dialog.service';
import { MailTemplatesUploadComponent, IEditee } from '../mail-templates-upload/mail-templates-upload.component';

@Component({
    selector: 'app-mail-templates',
    imports: [FormsModule, CommonModule],
    templateUrl: './mail-templates.component.html',
    styleUrl: './mail-templates.component.css'
})
export class MailTemplatesComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
    }

    private b64toBlob(b64Data: string, contentType: string): Blob {
        const bytes: Uint8Array = Buffer.from(b64Data, 'base64');
        return new Blob([bytes], {type: contentType});
    }

    download(): void {
        let id: number = this.session.currentOrgId?? 0;
        if (id && id !== -1) {
            let filename: string = "MailTpl-" + id.toString(10) + ".zip";
            this.faxSrv.getMailTemplatesArchive(id).subscribe({
                next: res => {
                    if (res.Status === 'ok') {
                        let file: Blob = this.b64toBlob(res.Base64data!, 'application/x-zip-compressed');
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(file);
                        link.download = filename;
                        link.hidden = true;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        let msg = this.fenUtils.formatErrors(res.Errors!);
                        if (msg) alert(msg);
                    }
                },
                error: err => { alert(err.message); }
            });
        }
    }

    upload(): void {
        let id: number = this.session.currentOrgId?? 0;
        if (id && id !== -1) {
            let def: IEditee = {
                organizationId: id,
                overwrite: false
            };
            const dialogRef = this.dialog.open(MailTemplatesUploadComponent, { data: { editee: def }});
            dialogRef.afterClosed().subscribe();
        }
    }
}
