<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>New {{ scope.FriendlyName }} Channel</h1>
    </div>
    <form id="Form1" class="form" novalidate (submit)="save()" name="ConnEditBoxForm">
        <div class="modal-body">
            <div class="field">
                <label>System Address:</label>
                <input type="text" maxlength="20" name="SystemAddress" [(ngModel)]="editee.SystemAddress" required>
            </div>
            <div class="field">
                <label>Friendly Name:</label>
                <input type="text" maxlength="4000" name="FriendlyName" [(ngModel)]="editee.FriendlyName">
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!editee.SystemAddress">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
