import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BooleanPipe } from '../utils/boolean.pipe';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestHostAddrType, FaxConfigRestHostGeneral, FaxConfigRestResult,
    FaxConfigRestSMTPStackConnectorId, FaxConfigRestSMTPStackConnectorIdList
} from '../api/api';
import { FilenameTemplateComponent } from '../filename-template/filename-template.component';
import { DialogDatetimeFormatComponent, IDateTimeFormatScope, IDateTimeFormatEditee } from '../dialog-datetime-format/dialog-datetime-format.component';
import { ConnChannelComponent, IChannelScope } from '../conn-channel/conn-channel.component';
import { ConnNewchannelComponent, INewChannelScope } from '../conn-newchannel/conn-newchannel.component';
import { DialogService } from '../dialog/dialog.service';
import { DkimClientConfigComponent, IDkimClientScope } from '../dkim-client-config/dkim-client-config.component';
import { DkimServerConfigComponent, IDkimServerScope } from '../dkim-server-config/dkim-server-config.component';
import * as _ from 'underscore';

interface IHostChannel {
    SystemAddress: string;
    FriendlyName: string;
    selected: boolean;
}

@Component({
    selector: 'app-conn-host',
    imports: [FormsModule, CommonModule, BooleanPipe, FilenameTemplateComponent],
    templateUrl: './conn-host.component.html',
    styleUrl: './conn-host.component.css'
})
export class ConnHostComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    private router = inject(Router);

    isReady: boolean = false;
    isConnectorsModifiable: boolean = false;
    isKernelPropsModifiable: boolean = false;
    mailSystem: string = '';

    hostGeneral: FaxConfigRestHostGeneral = {};
    hostChannels: IHostChannel[] = [];
    availableSendConnectors: FaxConfigRestSMTPStackConnectorIdList = [];
    availableReceiveConnectors: FaxConfigRestSMTPStackConnectorIdList = [];

    pageTabs = ['General', 'Channels'];
    state = { activePageTab: this.pageTabs[0] };

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private route: ActivatedRoute) {
        this.session.rootPromises.subscribe(() => {
            this.isConnectorsModifiable = auth.isModifiable('Connectors');
            this.isKernelPropsModifiable = auth.isModifiable('Kernel Properties');
            this.route.params.subscribe(params => {
                this.mailSystem = params['host']?? '';
                this.init();
            });
        });
    }

    private init(): void {
        if (this.mailSystem === 'SMTP') {
            this.pageTabs.push('Inbound');
            if (this.auth.isViewable('Kernel Properties')) {
                this.pageTabs.push('DKIM');
            }
        }
        this.faxSrv.GetConnHostGeneral(this.mailSystem).subscribe(res => {
            this.hostGeneral = res;
            this.refresh_channels();
            this.refresh_smtp();
        });
    }

    goBack(): void {
        // Go back to Host Connector Settings
        this.router.navigate(['/conn_settings']);
    }

    supportsAllAttTypes(): FaxConfigRestHostAddrType | undefined {
        let found = _.find(this.hostGeneral.AttTypes?? [], x => {
            return (x.InternalName == '*');
        });
        return found;
    }

    add_channel(): void {
        let scope: INewChannelScope = {
            MailSystem: this.mailSystem,
            FriendlyName: this.hostGeneral.FriendlyName?? ''
        };
        const dialogRef = this.dialog.open(ConnNewchannelComponent, {
            data: scope
        });
        dialogRef.afterClosed().subscribe(() => {
            this.refresh_channels();
        });
    }

    edit_channel(chan: IHostChannel | null): void {
        this.faxSrv.GetConnHostChannel(this.mailSystem, chan?.SystemAddress?? '').subscribe(res => {
            let scope: IChannelScope = {
                MailSystem: this.mailSystem,
                hostGeneral: this.hostGeneral,
                channel: res
            };
            const dialogRef = this.dialog.open(ConnChannelComponent, {
                data: scope
            });
            dialogRef.afterClosed().subscribe(() => {
                this.refresh_channels();
            });
        });
    }

    remove_channel(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.hostChannels, ch => {
            if (ch.selected) {
                promises.push(this.faxSrv.DeleteConnHostChannel(this.mailSystem, ch.SystemAddress));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.refresh_channels();
                }
            });
        }
    }

    refresh_channels(): void {
        this.faxSrv.GetConnHostChannels(this.mailSystem).subscribe(res => {
            this.hostChannels = _.sortBy(_.map(res, x => {
                return _.extend(x, { selected: false });
            }), 'SystemAddress');
            this.isReady = true;
        });
    }

    /* Filename Template */

    FilenameTemplateFields = [
        { Group: 'Common' },
        { Field: '{ourcsid}',       Display: 'Our CSID',                Preview: 'OurCSID' },
        { Field: '{csid}',          Display: 'Received CSID',           Preview: 'TheirCSID' },
        { Field: '{from.address}',  Display: 'Calling party number',    Preview: 'Caller' },
        { Field: '{to.address}',    Display: 'Called party number',     Preview: 'Callee' },
        { Field: '{routingcode}',   Display: 'Routing code',            Preview: 'Routing' },
        { Field: '{chargecode}',    Display: 'Charge code',             Preview: 'Charge' },
        { Field: '{jobnumber}',     Display: 'Job ID',                  Preview: 'JobId' },
        { Field: '{totpages}',      Display: 'Pages received',          Preview: 'Pages' },
        { Field: '{duration}',      Display: 'Duration',                Preview: 'Duration' },
        { Group: 'DateTime (Local)' },
        { Field: '{startrecvtimelocal}', Display: 'Start receive time',     Preview: 'Start' },
        { Field: '{creationtimelocal}',  Display: 'Creation time',          Preview: 'Create' },
        { Field: '{timelocal}',          Display: 'Current time',           Preview: 'Now' },
        { Group: 'DateTime (Universal)' },
        { Field: '{startrecvtime}',      Display: 'UTC Start receive time', Preview: 'UTC_Start' },
        { Field: '{creationtime}',       Display: 'UTC Creation time',      Preview: 'UTC_Create' },
        { Field: '{time}',               Display: 'UTC Current time',       Preview: 'UTC_Now' },
    ];

    fileNameFormApply(): void {
        let def: FaxConfigRestHostGeneral = {
            Inbound: {
                FilenameTemplate: this.hostGeneral.Inbound!.FilenameTemplate
            }
        };
        this.faxSrv.UpdateConnHostGeneral(this.mailSystem, def).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    fileNameDateTimeFormat(): void {
        let scope: IDateTimeFormatScope = {
            title: 'Datetime configuration',
            text: 'Choose the way the date and time fields are formatted within the filename template',
            editee: {
                dateFormat: this.hostGeneral.Inbound!.FilenameDateFormat?? 'd-MM-yyyy',
                timeFormat: this.hostGeneral.Inbound!.FilenameTimeFormat?? 'H-mm',
                dateTimeSeparator: this.hostGeneral.Inbound!.FilenameDateTimeSeparator?? ' '
            },
            dateFormats: [
                'd-MM-yyyy',
                'dd-MM-yyyy',
                'M-dd-yyyy',
                'MM-dd-yyyy',
                'yyyy-MM-dd',
                'yyyyMMdd'
            ],
            timeFormats: [
                'H-m',
                'H-mm',
                'HH-mm',
                'HHmm',
                'H-mm-ss',
                'HH-mm-ss',
                'HHmmss',
                'h-mm t',
                'hh-mm t',
                'hhmm t',
                'h-mm-ss t',
                'hh-mm-ss t',
                'hhmmss t',
                'h-mm tt',
                'hh-mm tt',
                'hhmm tt',
                'h-mm-ss tt',
                'hh-mm-ss tt',
                'hhmmss tt'
            ]
        };
        const dialogRef = this.dialog.open(DialogDatetimeFormatComponent, { data: scope });
        dialogRef.afterClosed().subscribe({
            next: (res: IDateTimeFormatEditee) => {
                if (res) {
                    this.hostGeneral.Inbound!.FilenameDateFormat = res.dateFormat;
                    this.hostGeneral.Inbound!.FilenameTimeFormat = res.timeFormat;
                    this.hostGeneral.Inbound!.FilenameDateTimeSeparator = res.dateTimeSeparator;
                    let def = {
                        Inbound: { 
                            FilenameDateFormat: res.dateFormat,
                            FilenameTimeFormat: res.timeFormat,
                            FilenameDateTimeSeparator: res.dateTimeSeparator
                        }
                    };
                    this.faxSrv.UpdateConnHostGeneral(this.mailSystem, def).subscribe({
                        next: res => this.fenUtils.afterSave(res),
                        error: err => alert(err.message)
                    });
                }
            },
            error: err => alert(err.message)
        });
    }

    /* SMTP Stack */

    refresh_smtp() {
        let promises: Observable<boolean>[] = [
            this.faxSrv.GetSMTPStackClientIds().pipe(map(res => {
                this.availableSendConnectors = res;
                return true;
            })),
            this.faxSrv.GetSMTPStackServerIds().pipe(map(res => {
                this.availableReceiveConnectors = res;
                return true;
            })),
        ];
        forkJoin(promises).subscribe();
    }

    editSendConnector(sc: FaxConfigRestSMTPStackConnectorId): void {
        if (sc.Identifier) {
            this.faxSrv.GetSMTPStackClient(sc.Identifier).subscribe(res => {
                let scope: IDkimClientScope = { conn: sc, editee: res };
                const dialogRef = this.dialog.open(DkimClientConfigComponent, {
                    data: scope
                });
                dialogRef.afterClosed().subscribe(() => {
                    this.refresh_smtp();
                });
            });
        }
    }

    editReceiveConnector(rc: FaxConfigRestSMTPStackConnectorId): void {
        if (rc.Identifier) {
            this.faxSrv.GetSMTPStackServer(rc.Identifier).subscribe(res => {
                let scope: IDkimServerScope = { conn: rc, editee: res };
                const dialogRef = this.dialog.open(DkimServerConfigComponent, {
                    data: scope
                });
                dialogRef.afterClosed().subscribe(() => {
                    this.refresh_smtp();
                });
            });
        }
    }
}
