<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Coversheet Date Format</h1>
    </div>
    <form id="coversheet_dateformat" class="form" novalidate (submit)="save()">
        <div class="modal-body">
            <p>Choose how the date will be displayed on the coversheet.</p>
            <div class="field">
                <label>Date Format:</label>
                <app-combobox name="DateFormat" [(model)]="this.editee.dateFormat" (modelChange)="format_change()" [options]="dateFormats" [required]="true"
                title="Enter the date format, for example 'DD/MM/YYYY'. The format is not case-sensitive.">
                </app-combobox>
            </div>
            <p></p>
            <br>
            <strong>Preview (January 31 of current year):</strong>
            <p class="well">{{ datePreview }}</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
