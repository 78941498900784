<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ headerText }}</h1>
    </div>
    <form id="contract_edit_form" name="contractEditForm" #form="ngForm" class="form" novalidate (submit)="submit()">
        <div class="modal-body" *ngIf="isReady">
            <b>Identity</b>
            <p>The fields Name, Connector and Machine must match the fields Connector name and Machine in the connector contract and the type of the installed connector.</p>
            <div class="field" >
                <label>Connector:&nbsp;*</label>
                <select name="EncodedType" [(ngModel)]="editee.encodedType" [disabled]="mode==='edit'" required title="Select a connector from the list of available connectors.">
                    <option *ngFor="let p of connectors" [ngValue]="p.encodedType">{{ p.Name }}</option>
                </select>
            </div>
            <div class="field">
                <label>Name:&nbsp;*</label>
                <input type="text" name="Name" maxlength="100" [(ngModel)]="editee.ConnectorName" required size="40" title="The name of the Connector, this must be equal to the Connector name in the Configurator." />
            </div>
            <div class="field">
                <label>Machine:&nbsp;*</label>
                <input type="text" name="Machine" maxlength="100" [(ngModel)]="editee.Machine" required size="40" title="The host-name where the Connector runs, this must be equal to the name of the machine in the Configurator." />
            </div>
            <b>Wake-up</b>
            <p>The Wakeup Address and port must resolve to the UDP-endpoint where the Kernel can notify the connector there is a job waiting. 
                When the connection is made over the internet, make sure that the firewall permits the kernel to pass through. 
                Also, if the firewall performs address- or port- translations, the values must correspond to the external values, when using a fully qualified domain name (FQDN), make sure it can be resolved via public DNS.</p>
            <div class="field">
                <label>Address:<span *ngIf="!isMobileProtocol()">&nbsp;*</span></label>
                <input type="text" name="WakeUpIP" maxlength="100" [(ngModel)]="editee.WakeUpIP" [required]="!isMobileProtocol()" size="40" title="The (external) IP-address or hostname where the connector can be contacted on for a Wake-up signal." />
            </div>
            <div class="field">
                <label>Port:<span *ngIf="!isMobileProtocol()">&nbsp;*</span></label>
                <input type="number" name="WakeUpPort" maxlength="5" min="1024" max="65535" [(ngModel)]="editee.WakeUpPort" [required]="!isMobileProtocol()" size="10" title="The external UDP port where the connector can be contacted on for a Wake-up signal." />
            </div>
            <b>Security</b>
            <div class="field">
                <label>UserID:</label>
                <input type="text" name="UserID" maxlength="50" [(ngModel)]="editee.UserID" [required]="editee.Password? true: false" size="40" title="The username the connector will log on with." />
            </div>
            <div class="field">
                <label>Password:</label>
                <input type="password" name="Password" autocomplete="off" maxlength="38" [(ngModel)]="editee.Password" size="40" title="The password the connector will log on with." />
            </div>
            <p>When enforcing SSL, the Kernel must be contacted over SSL, otherwise the connector will be refused. When using technology such as a reverse proxy to handle SSL, this option must be turned off.</p>
            <div class="field">
                <label>Enforce SSL:</label>
                <input type="checkbox" name="SSLUsage" [(ngModel)]="editee.SSLUsage" title="Indicates if SSL must be enforced." />
            </div>
            <b>Advanced</b>
            <div class="field">
                <label>Monitor:</label>
                <input type="checkbox" name="Monitor" [(ngModel)]="editee.monitor" title="Indicates if the kernel must check and report if the connector isn't running." />
            </div>
            <div class="field">
                <label>Require routing before accept:</label>
                <input type="checkbox" name="CheckInbound" [(ngModel)]="editee.device_check_inbound_via_kernel" [disabled]="!isDevice(editee.encodedType)" title="If this feature is activated the device connector is overruled to check whether the called number or a part of it can be routed by the kernel via FAX and/or DIR routings before accepting the call." />
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" *ngIf="auth.isModifiable('Kernel Contracts')" class="btn btn-primary" [disabled]="!isValid() || !form.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
