<h1>Mail Templates</h1>

<div *ngIf="session.contextAllOrganizations()">
    Please select an organization context to configure its mail templates.
</div>
<div *ngIf="!session.contextAllOrganizations()">
    <p>You can download a zip file containing the current set of mail
    templates<span *ngIf="auth.isModifiable('All')">, or upload a zip file containing a new set of mail templates</span>.
    These mail templates apply only to the currently selected organization.
    </p>
    <button type="button" class="btn" (click)="download()">Download...</button>
    <p></p>
    <button type="button" class="btn" *ngIf="auth.isModifiable('All')" (click)="upload()"><i class="glyphicon glyphicon-plus"></i>&nbsp;Upload...</button>
</div>
