import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestKernelDatabaseDefaults, FaxConfigRestAuditLogOptions } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";

export interface IAuditlogConfig {
    DBSource: string,
    DBUsername: string,
    DBPassword: string,
    DBVerifyPassword: string,
    IsUsingDefaults: boolean,
    Enabled: boolean
}

@Component({
    selector: 'app-auditlog-config',
    imports: [FormsModule, CommonModule],
    templateUrl: './auditlog-config.component.html',
    styleUrl: './auditlog-config.component.css'
})
export class AuditlogConfigComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    modified: boolean = false;
    editee: IAuditlogConfig;
    isReadOnly: boolean;
    DefaultsClicked: boolean = false;
    DBDefaults?: FaxConfigRestKernelDatabaseDefaults;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAuditlogConfig }
    ) {
        this.editee = data.editee;
        this.isReadOnly = !auth.isModifiable("AuditLog");
        this.faxSrv.GetKernelDatabaseDefaults().subscribe(res => {
            this.DBDefaults = res;
        });
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let def: FaxConfigRestAuditLogOptions = {};

        if (this.editee.DBSource) {
            def.DBSource = this.editee.DBSource;
        } else {
            alert('The database source connection string cannot be empty.');
            return null;
        }
        def.DBUsername = this.editee.DBUsername;
        if (this.editee.DBPassword) {
            if (this.editee.DBVerifyPassword !== this.editee.DBPassword) {
                alert('The verify password you entered does not match the new password.');
                return null;
            }
            def.DBPassword = this.editee.DBPassword;
        }
        def.Enabled = this.editee.Enabled;
        return this.faxSrv.PutAuditLogDBConfig(def);
    }

    test(): void {
        let def: FaxConfigRestAuditLogOptions = {
            DBSource:   this.editee.DBSource,
            DBUsername: this.editee.DBUsername?? '',
            DBPassword: this.editee.DBPassword?? ''
        };
        this.faxSrv.TestAuditLogDBConfig(def).subscribe({
            next: res => {
                if (res.Status === 'ok') {
                    alert('Successfully connected to the database.');
                } else {
                    if (res.Errors) {
                        let msg = this.fenUtils.formatErrors(res.Errors);
                        if (msg) alert(msg);
                    } else {
                        alert('Cannot connect to the database using the current parameters.');
                    }
                }
            },
            error: err => { alert(err.message); }
        });
    };

    defaults(): void {
        if (this.DBDefaults) {
            this.editee.DBSource = this.DBDefaults.DBSource?? '';
            this.editee.DBUsername = this.DBDefaults.DBUsername?? '';
            this.editee.DBPassword = '';
            this.editee.DBVerifyPassword = '';
            this.DefaultsClicked = true;
        }
    };

    private doSubmit(): void {
        let promise: Observable<FaxConfigRestResult> | null = this.doSave();
        promise?.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    submit(): void {
        if (this.DefaultsClicked) {
            this.faxSrv.DeleteAuditLogDBConfig().subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.doSubmit();
                    }
                },
                error: err => { alert(err.message); }
            });
        } else {
            this.doSubmit();
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
