<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Audit Log Database</h1>
    </div>
    <form id="auditlog_config_form" #auditLogConfigForm="ngForm" class="form" novalidate (submit)="submit()">
        <div class="modal-body">
            <p>
                <span *ngIf="editee.IsUsingDefaults">The AuditLog table is currently stored in the default database and is accessed using default credentials. </span>
                <span *ngIf="!isReadOnly">You can change the database and credentials here. A fresh table will be created if it does not already exist.</span>
            </p>
			<div class="field">
				<label>Enabled:</label>
				<input type="checkbox" name="Enabled" [disabled]="isReadOnly" [(ngModel)]="editee.Enabled"
					   title="Globally enable or disable the audit log.">
			</div>
			<div class="field">
				<label>Source:</label>
				<input type="text" name="DBSource" [disabled]="isReadOnly" required style="width:400px" [(ngModel)]="editee.DBSource">
			</div>
			<div class="field">
				<label>Username:</label>
				<input type="text" name="DBUsername" [disabled]="isReadOnly" [(ngModel)]="editee.DBUsername">
			</div>
			<div class="field">
				<label>New Password:</label>
				<input type="password" autocomplete="off" name="DBPassword" [disabled]="isReadOnly" maxlength="100" [(ngModel)]="editee.DBPassword">
			</div>
			<div class="field">
				<label>Verify Password:</label>
				<input type="password" autocomplete="off" name="DBVerifyPassword" [disabled]="isReadOnly" [required]="!isReadOnly && editee.DBPassword.length > 0" [(ngModel)]="editee.DBVerifyPassword">&nbsp;
                <button *ngIf="!isReadOnly" type="button" class="btn" (click)="test()" [disabled]="!auditLogConfigForm.valid || (editee.DBVerifyPassword !== editee.DBPassword)"
                title="Test the database connection parameters">Test</button>
			</div>
        </div>
        <div class="modal-footer">
            <button *ngIf="!isReadOnly" type="submit" class="btn btn-primary" [disabled]="!auditLogConfigForm.valid || (editee.DBVerifyPassword !== editee.DBPassword)">Save</button>
            <button *ngIf="!isReadOnly" type="button" class="btn" (click)="defaults()"
                title="Revert to the default database connection parameters">Defaults</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
