<section class="modal wide">
	<div class="modal-header">
		<button type="button" class="close" (click)="close()">&times;</button>
		<h1>Audit Log Item Details</h1>
	</div>
    <form id="auditLogItem_form" class="form" novalidate>
        <div class="modal-body">
            <div *ngFor="let item of editee">
				<button type="button" [ngClass]="{accordion: true, acc_active : is_active(item)}" *ngIf="editee.length > 1" (click)="toggle_section(item)">{{ item.Summary }}</button>
				<div [ngClass]="{acc_panel: true, acc_panel_active: is_active(item), acc_panel_inactive: !is_active(item)}">
					<table *ngIf="showAdvancedInfo" class="table table-bordered table-condensed">
                        <tbody>
                            <tr>
                                <td>ID</td>
                                <td>{{ item.ID }}</td>
                            </tr>
                            <tr>
                                <td>Parent ID</td>
                                <td>{{ item.ParentID?? '' }}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>{{ item.Type }}</td>
                            </tr>
                            <tr>
                                <td>Action</td>
                                <td>{{ item.Action }}</td>
                            </tr>
                            <tr>
                                <td>Target</td>
                                <td>{{ item.Target }}</td>
                            </tr>
                        </tbody>
					</table>
					<hr *ngIf="showAdvancedInfo" />

					<table class="table table-bordered table-condensed">
                        <tbody>
                            <tr>
                                <td>UTC Time</td>
                                <td>{{ item.UTCTime }}</td>
                            </tr>
                            <tr>
                                <td>Machine</td>
                                <td>{{ item.Machine }}</td>
                            </tr>
                            <tr>
                                <td>Summary</td>
                                <td>{{ item.Summary }}</td>
                            </tr>
                            <tr *ngIf="session.isMultiTenant() && auth.isViewable('Organizations')">
                                <td>Organization</td>
                                <td>{{ item.OrganizationName }}</td>
                            </tr>
                            <tr>
                                <td>User Name</td>
                                <td>{{ item.UserName }}</td>
                            </tr>
                            <tr>
                                <td>User Role</td>
                                <td>{{ item.UserRole }}</td>
                            </tr>
                            <tr>
                                <td>Client IP</td>
                                <td>{{ item.ClientIP }}</td>
                            </tr>
                            <!--
                                <tr>
                                    <td>Tenant</td>
                                    <td>{{ item.Tenant }}</td>
                                </tr>
                            -->
                            <tr>
                                <td>Error Message</td>
                                <td>{{ item.ErrorMessage }}</td>
                            </tr>
                            <tr>
                                <td>Details</td>
                                <td><div>{{ item.Details }}</div></td>
                            </tr>
                        </tbody>
					</table>
				</div>
			</div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
