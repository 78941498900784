// A dialog service based on: https://johnbwoodruff.com/posts/angular-cdk-dialog/

import { Overlay, ComponentType, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from './dialog-tokens';

export interface DialogConfig {
    data?: any;
    anchorID?: string | null
}

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private overlay: Overlay, private injector: Injector) {}

    /**
    * Open a custom component in an overlay
    */
    open<T>(component: ComponentType<T>, config?: DialogConfig): DialogRef {

        let anchorElement: HTMLElement | null;
        let overlayConfig: OverlayConfig;

        if (config?.anchorID !== undefined) {
            if (config.anchorID) {
                anchorElement = document.getElementById(config.anchorID);
            } else {
                anchorElement = null; // explicitly not anchored
            }
        } else {
            // Default, anchor to the 'content' element
            anchorElement = document.getElementById('content');
        }

        if (anchorElement) {
            // Vertically anchored to top of element; horizontally centered
            const positionStrategy = this.overlay.position()
                .flexibleConnectedTo(anchorElement)
                .withLockedPosition(true)
                .withGrowAfterOpen(false)
                .withPush(false)
                .withFlexibleDimensions(false)
                .withPositions([{
                    offsetY: 60,        // compensate for "margin-top: -60px;" of the modal style
                    originX: "center",  // center of the specified element
                    originY: "top",     // top of the specified element
                    overlayX: "center",
                    overlayY: "top"
                }]);

            overlayConfig = {
                positionStrategy,
                hasBackdrop: true,
                backdropClass: 'overlay-backdrop',
                panelClass: 'overlay-panel',
                scrollStrategy: this.overlay.scrollStrategies.reposition()
            };
        } else {
            // Globally centered position strategy
            const positionStrategy = this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically();

            overlayConfig = {
                positionStrategy,
                hasBackdrop: true,
                backdropClass: 'overlay-backdrop',
                panelClass: 'overlay-panel',
                scrollStrategy: this.overlay.scrollStrategies.noop()
            };
        }

        // Create the overlay with customizable options
        const overlayRef = this.overlay.create(overlayConfig);

        // Create dialogRef to return
        const dialogRef = new DialogRef(overlayRef);

        // Create injector to be able to reference the DialogRef from within the component
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: DialogRef, useValue: dialogRef },
                { provide: DIALOG_DATA, useValue: config?.data },
            ],
        });

        // Attach component portal to the overlay
        const portal = new ComponentPortal(component, null, injector);
        overlayRef.attach(portal);

        return dialogRef;
    }
}
