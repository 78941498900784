<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Phone Number Administration</h1>
    </div>
    <form (submit)="enablePhonenumAdminFeature()" class="form" id="addressesAdminEnableForm">
        <div class="modal-body" *ngIf="isReady" id="addressesAdminEnableBody">
            <div style="overflow: auto; ">
                <p>Phone number administration is turned off</p>
                <p>Phone number administration performs outbound service routing using the user's proxy addresses.
                    Each number is assigned a connection and each connection is associated with a service.
                </p>
                <p>
                    When enabling Phone number administration, all applicable routing codes currently assigned to users
                    will be imported in
                    the administration.
                    These still need to be assigned to connections.
                    After enabling Phone number administration all numbers will need to be added to the system by
                    Administrators,
                    Power Administrators or Global Administrators.
                    User Administrators will only be able to assign existing numbers as routing codes to users.
                </p>
                <p>
                    <b>Note:</b> Enabling Phone number administration is a one time operation.
                </p>

            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" tabindex="1" class="btn btn-primary" *ngIf="auth.isModifiable('All')">Enable Phone number administration</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>