import { CommonModule, NgFor } from '@angular/common';
import { Component, inject, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { UtlClass } from '../utils/utl';
import { FaxConfigRestLicenseProductInfo, FaxConfigRestLicenseLicenseKeyList, FaxConfigRestLicenseLicenseKeyInfo } from '../api/api';
import * as _ from 'underscore';

@Component({
    selector: 'app-license',
    imports: [CommonModule, NgFor],
    templateUrl: './license.component.html',
    styleUrl: './license.component.css'
})

export class LicenseComponent implements AfterViewInit {
  public faxSrv: FaxConfigApi = inject(FaxConfigApi);
  constructor (public auth: AuthService, public session: Session) {}

  license: FaxConfigRestLicenseProductInfo | null = null;
  licenseItems: FaxConfigRestLicenseLicenseKeyList = [];
  licenseGroups: any;

  ngAfterViewInit()
  {
    const pThis = this;
    const utl: UtlClass = new UtlClass();
    this.faxSrv.license().subscribe(license => {
      pThis.license = license;
      pThis.licenseItems = utl.sorted(license.Licenses, utl.comparatorBy(['KeyType', 'Name'])); // TODO not used?
      pThis.licenseGroups = _.chain(license.Licenses)
                          .groupBy('KeyType')
                          .pairs()
                          .sortBy(function(pair) { return pair[0] === 'Kernel' ? '' : pair[0]; }) // show Kernel at the top.
                          .map(function(pair) {
                              return {
                                  KeyType: pair[0],
                                  licenseItems: _.sortBy(pair[1], function(item) { return item.Name; })
                              };
                          })
                          .value();
    });
  }

  getEvalText(item: FaxConfigRestLicenseLicenseKeyInfo): string {
    if (!item.EvalDays && !item.EvalUsed) return '-';
    return item.EvalDays + ' (' + item.EvalUsed + ' used)';
  }
}
