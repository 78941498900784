import { CommonModule } from '@angular/common';
import { Observable, forkJoin, map } from 'rxjs';
import { Component, inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { BooleanPipe } from '../utils/boolean.pipe';
import { DialogService } from '../dialog/dialog.service';
import { FaxConfigRestKernelMonitoringGeneral, FaxConfigRestKernelMonitoringRuleBase,
    FaxConfigRestKernelMonitoringRuleMessageCheck, FaxConfigRestKernelMonitoringRuleQueueAge,
    FaxConfigRestKernelMonitoringRuleQueueItems, FaxConfigRestKernelMonitoringRulesList, FaxConfigRestKernelMonitoringSenderBase,
    FaxConfigRestKernelMonitoringSendersList, FaxConfigRestKernelMonitoringSenderSMTP, FaxConfigRestKernelMonitoringSenderSqlLogger,
    FaxConfigRestKernelMonitoringTemplate, FaxConfigRestKernelMonitoringTemplatesList, FaxConfigRestResult
} from '../api/api';
import { MonitoringRuleQueueComponent, IMonitoringQueueScope } from '../monitoring-rule-queue/monitoring-rule-queue.component';
import { MonitoringRuleMessagecheckComponent, IMonitoringMessageCheckScope } from '../monitoring-rule-messagecheck/monitoring-rule-messagecheck.component';
import { MonitoringSenderSmtpComponent, ISenderSmtpScope } from '../monitoring-sender-smtp/monitoring-sender-smtp.component';
import { MonitoringSenderSqlloggerComponent, ISenderSqlLoggerScope } from '../monitoring-sender-sqllogger/monitoring-sender-sqllogger.component';
import { MonitoringCreateRuleComponent } from '../monitoring-create-rule/monitoring-create-rule.component';
import { MonitoringCreateSenderComponent } from '../monitoring-create-sender/monitoring-create-sender.component';
import * as _ from 'underscore';
import { MonitoringNewtemplateComponent } from '../monitoring-newtemplate/monitoring-newtemplate.component';
import { IMonitoringTemplateScope, MonitoringTemplatesComponent } from '../monitoring-templates/monitoring-templates.component';

interface IState {
    activePageTab: string;
    monitoringGeneral: FaxConfigRestKernelMonitoringGeneral;
    monitoringGeneralChanged: boolean;
}

@Component({
    selector: 'app-monitoring-list',
    imports: [FormsModule, CommonModule, BooleanPipe],
    templateUrl: './monitoring-list.component.html',
    styleUrl: './monitoring-list.component.css'
})
export class MonitoringListComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    isModifiable: boolean;
    pageTabs: string[] = ['General', 'Rules', 'Senders', 'Templates'];
    state: IState = {
        activePageTab: this.pageTabs[0],
        monitoringGeneral: {},
        monitoringGeneralChanged: false
    };

    monitoringRules: FaxConfigRestKernelMonitoringRulesList = [];
    monitoringSenders: FaxConfigRestKernelMonitoringSendersList = [];
    monitoringTemplates: FaxConfigRestKernelMonitoringTemplatesList = [];

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
        this.session.rootPromises.subscribe(() => this.init());
    }

    init(): void {
        this.faxSrv.monitoringGeneral().subscribe(monGen => {
            this.state.monitoringGeneral = monGen;
            this.isReady = true;
            this.refresh();
        });
    }

    monitoringGeneralChange(): void {
        this.state.monitoringGeneralChanged = true;
    }

    monitoringGeneralApplyEnabled(): boolean {
        return (this.state.monitoringGeneralChanged === true
            &&  this.state.monitoringGeneral.Interval
            &&  this.state.monitoringGeneral.Interval > 0)? true: false;
    }

    monitoringGeneralApply(): void {
        this.faxSrv.updateMonitoringGeneral(this.state.monitoringGeneral).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.state.monitoringGeneralChanged = false;
            }
        });
    }

    private refresh(): void {
        let promises: Observable<boolean>[] = [
            this.faxSrv.monitoringRules().pipe(map(rules => {
                this.monitoringRules = rules;
                return true;
            })),
            this.faxSrv.monitoringSenders().pipe(map(senders => {
                this.monitoringSenders = senders;
                return true;
            })),
            this.faxSrv.monitoringTemplatesDefinitions().pipe(map(templates => {
                this.monitoringTemplates = templates;
                return true;
            }))
        ];
        forkJoin(promises).subscribe();
    }

    private updateEnabled(enabled: boolean, isSender: boolean): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        if (isSender) {
            _.each(this.monitoringSenders, sender => {
                if (sender.selected) {
                    promises.push(
                        this.faxSrv.updateMonitoringSender(
                            _.extend({}, sender, { Enabled: enabled })
                        )
                    );
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    this.fenUtils.afterSave(res);
                    this.faxSrv.monitoringSenders().subscribe(senders => {
                        this.monitoringSenders = senders;
                    });
                });
            }
        } else {
            _.each(this.monitoringRules, rule => {
                if (rule.selected) {
                    promises.push(
                        this.faxSrv.updateMonitoringRule(
                            _.extend({}, rule, { Enabled: enabled })
                        )
                    );
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    this.fenUtils.afterSave(res);
                    this.faxSrv.monitoringRules().subscribe(rules => {
                        this.monitoringRules = rules;
                    });
                });
            }
        }
    }

    enable_rule(): void { this.updateEnabled(true, false); }
    disable_rule(): void { this.updateEnabled(false, false); }
    enable_sender(): void { this.updateEnabled(true, true); }
    disable_sender(): void { this.updateEnabled(false, true); }

    private isUniqueId(uid: string): boolean {
        let uidLower = uid.toLowerCase();
        if (!_.any(this.monitoringRules, item => { return item.ID?.toLowerCase() === uidLower; })
         && !_.any(this.monitoringSenders, item => { return item.ID?.toLowerCase() === uidLower; }))
        {
            return true;
        }
        return false;
    }

    private generateUid(template: string): string | null {
        let idx: number;
        let uid: string;
        for (idx = 1; idx < 2147483647; idx++) {
            uid = template + idx;
            if (this.isUniqueId(uid)) {
                return uid;
            }
        }
        return null;
    }

    private createRuleOrSender(newtype: string): FaxConfigRestKernelMonitoringRuleBase | FaxConfigRestKernelMonitoringSenderBase | null {
        let uid: string | null;
        let typeLower: string = newtype.toLowerCase();
        if (typeLower === 'queueitems')
        {
            uid = this.generateUid('QueueItems');
            let def: FaxConfigRestKernelMonitoringRuleQueueItems = {
                __type: 'QueueItems',
                Enabled: true,
                ID: uid,
                DisplayName: uid,
                Senders: ['*'],
                Template: 'QueueItems.tpl',
                Items: 200
            };
            return def as FaxConfigRestKernelMonitoringRuleBase;
        }
        if (typeLower === 'queueage')
        {
            uid = this.generateUid('QueueAge');
            let def: FaxConfigRestKernelMonitoringRuleQueueAge = {
                __type: 'QueueAge',
                Enabled: true,
                ID: uid,
                DisplayName: uid,
                Senders: ['*'],
                Template: 'QueueAge.tpl',
                Age: 60 /*minutes*/
            };
            return def as FaxConfigRestKernelMonitoringRuleBase;
        }
        if (typeLower === 'messagecheck')
        {
            uid = this.generateUid('MessageCheck');
            let def: FaxConfigRestKernelMonitoringRuleMessageCheck = {
                __type: 'MessageCheck',
                Enabled: true,
                ID: uid,
                DisplayName: uid,
                Senders: ['*'],
                Template: 'MessageCheck.tpl',
                Interval: 30,
                SendOnSuccess: true,
                Script: this.state.monitoringGeneral.DefaultScript,
                OurCSIDPrefix: 'FenMon',
                Props: {
                    ServerAddress: 'https://<server>/ews/exchange.asmx',
                    ServerAddressType: 'EWS',
                    Domain: '',
                    Username: '',
                    Password: '',
                    MessageType: 'FAX',
                    FromName: 'Monitoring',
                    FromAddress: 'monitoring@fenestrae.sys',
                    FromAddressType: 'SMTP',
                    ToName: '',
                    ToAddress: '',
                    ToAddressType: 'FAX',
                    MessageBody: 'This is a test message from Faxination.',
                    Attachment: '',
                    Priority: 3,
                    GetNotification: true,
                    GetInbound: true,
                    Timeout: 150,
                    Frequency: 10,
                    AuthenticationMode: 'Basic',
                    OAuth2_Tenant: '',
                    OAuth2_ClientId: '',
                    OAuth2_ClientSecret: '',
                    OAuth2_ExchangeImpersonation: ''
                }
            };
            return def as FaxConfigRestKernelMonitoringRuleBase;
        }
        if (typeLower === 'smtp')
        {
            uid = this.generateUid('SMTP');
            let def: FaxConfigRestKernelMonitoringSenderSMTP = {
                __type: 'SMTP',
                Enabled: true,
                ID: uid,
                DisplayName: uid,
                From: '',
                To: '',
                Subject: 'Alert triggered based on rule \'%Rule.DisplayName%\'',
                SmtpServer: '',
                Port: 25,
                AuthUsername: '',
                AuthPassword: '',
                UseTLS: false,
                MinTLSVerAllowed: ''
            };
            return def as FaxConfigRestKernelMonitoringSenderBase;
        }
        if (typeLower === 'sqllogger')
        {
            uid = this.generateUid('SqlLogger');
            let def: FaxConfigRestKernelMonitoringSenderSqlLogger = {
                __type: 'SqlLogger',
                Enabled: true,
                ID: uid,
                DisplayName: uid,
                Source: this.state.monitoringGeneral.DefaultDBSource,
                Username: this.state.monitoringGeneral.DefaultDBUsername,
                Password: ''
            };
            return def as FaxConfigRestKernelMonitoringSenderBase;
        }
        return null;
    }

    new_rule(): void {
        const dialogRef = this.dialog.open(MonitoringCreateRuleComponent);
        dialogRef.afterClosed().subscribe(newtype => {
            if (newtype) {
                let item = this.createRuleOrSender(newtype);
                if (item) {
                    this.edit(item, true);
                }
            }
        });
    }

    new_sender(): void {
        const dialogRef = this.dialog.open(MonitoringCreateSenderComponent);
        dialogRef.afterClosed().subscribe(newtype => {
            if (newtype) {
                let item = this.createRuleOrSender(newtype);
                if (item) {
                    this.edit(item, true);
                }
            }
        });
    }

    remove_rule(): void {
        // Rules can always be deleted
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.monitoringRules, rule => {
            if (rule.selected) {
                promises.push(
                    this.faxSrv.deleteMonitoringRule(rule)
                );
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe(res => {
                this.fenUtils.afterSave(res);
                this.faxSrv.monitoringRules().subscribe(rules => {
                    this.monitoringRules = rules;
                });
            });
        }
    }

    edit(item: FaxConfigRestKernelMonitoringRuleBase | FaxConfigRestKernelMonitoringSenderBase, isNew: boolean): void {
        let item_type: string = item.__type.toLowerCase();
        if (item_type === 'queueitems' || item_type === 'queueage') {
            let scope: IMonitoringQueueScope = {
                title: isNew? 'New': 'Edit',
                editee: item as FaxConfigRestKernelMonitoringRuleBase,
                senders: this.monitoringSenders
            };
            const dialogRef = this.dialog.open(MonitoringRuleQueueComponent, {data: scope});
            dialogRef.afterClosed().subscribe(() => {
                this.refresh();
            });
        } else if (item_type === 'messagecheck') {
            let scope: IMonitoringMessageCheckScope = {
                title: isNew? 'New': 'Edit',
                editee: item as FaxConfigRestKernelMonitoringRuleMessageCheck,
                senders: this.monitoringSenders
            };
            const dialogRef = this.dialog.open(MonitoringRuleMessagecheckComponent, {data: scope});
            dialogRef.afterClosed().subscribe(() => {
                this.refresh();
            });
        } else if (item_type === 'smtp') {
            let scope: ISenderSmtpScope = {
                title: isNew? 'New': 'Edit',
                editee: item as FaxConfigRestKernelMonitoringSenderSMTP
            };
            const dialogRef = this.dialog.open(MonitoringSenderSmtpComponent, {data: scope});
            dialogRef.afterClosed().subscribe(() => {
                this.refresh();
            });
        } else if (item_type === 'sqllogger') {
            let scope: ISenderSqlLoggerScope = {
                title: isNew? 'New': 'Edit',
                editee: item as FaxConfigRestKernelMonitoringSenderSqlLogger
            };
            const dialogRef = this.dialog.open(MonitoringSenderSqlloggerComponent, {data: scope});
            dialogRef.afterClosed().subscribe(() => {
                this.refresh();
            });
        }
    }

    private isUniqueTplName(name: string): boolean {
        let nameLower: string = name.toLowerCase();
        if (!_.any(this.monitoringTemplates, item => { return item.Name?.toLowerCase() === nameLower; })) {
            return true;
        }
        return false;
    }

    private generateTplName(template: string): string | null {
        let idx: number;
        let name: string;
        for (idx = 1; idx < 2147483647; idx+=1) {
            name = template + idx + '.tpl';
            if (this.isUniqueTplName(name)) {
                return name;
            }
        }
        return null;
    }

    private createTemplate(newtype: string): FaxConfigRestKernelMonitoringTemplate | null {
        var typeLower = newtype.toLowerCase();
        if (typeLower === 'queueitems')
        {
            return { // default values
                Name: this.generateTplName('QueueItems'), RuleType: 'QueueItems',
                Content: 'The number of jobs in the Faxination Queue has reached the configured limit.\r\nRule: %Rule.DisplayName%\r\nMachine: %servername%\r\nLocal Time: %date% %time%\r\nConfigured Limit: %QueueItems.Items%\r\nDetected Number of Jobs: %QueueItems.Detected%'
            };
        }
        if (typeLower === 'queueage')
        {
            return { // default values
                Name: this.generateTplName('QueueAge'), RuleType: 'QueueAge',
                Content: '%QueueAge.Total% job(s) in the Faxination Queue have reached the age limit.\r\nMachine: %servername%\r\nLocal Time: %date% %time%\r\nMaximum Age: %QueueAge.Age% minutes\r\n\r\nOldest Job: %Job.JobNr%\r\nDetected Age: %QueueAge.Detected% minutes\r\nCreation Time: %Job.CreationTime%\r\nMessage Type: %Job.MsgType%\r\n'
            };
        }
        if (typeLower === 'messagecheck')
        {
            return { // default values
                Name: this.generateTplName('MessageCheck'), RuleType: 'MessageCheck',
                Content: 'A message check has completed.\r\nRule: %Rule.DisplayName%\r\nMachine: %servername%\r\nLocal Time: %date% %time%\r\nCheck Time (UTC): %MessageCheck.CreateTime%\r\nCheck Duration: %MessageCheck.Duration%\r\nResult Code: %MessageCheck.ResultCode%\r\nResult String: %MessageCheck.ResultString%\r\n'
            };
        }
        return null;
    }

    private doEditTemplate(item: FaxConfigRestKernelMonitoringTemplate, isNew: boolean): void {
        let scope: IMonitoringTemplateScope = {
            editee: item,
            bIsNew: isNew
        };
        const dialogRef = this.dialog.open(MonitoringTemplatesComponent, {data: scope});
        dialogRef.afterClosed().subscribe(() => {
            this.refresh();
        });
    }

    new_template(): void {
        const dialogRef = this.dialog.open(MonitoringNewtemplateComponent);
        dialogRef.afterClosed().subscribe(newtype => {
            if (newtype) {
                let item = this.createTemplate(newtype);
                if (item) {
                    this.doEditTemplate(item, true);
                }
            }
        });
    }

    edit_template(item: FaxConfigRestKernelMonitoringTemplate): void {
        this.doEditTemplate(item, false);
    }

    remove_template(): void {
        // Templates can only be deleted if they are not used by a rule
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.monitoringTemplates, template => {
            if (template.selected) {
                promises.push(
                    this.faxSrv.deleteMonitoringTemplate(template)
                );
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe(res => {
                this.fenUtils.afterSave(res);
                this.faxSrv.monitoringTemplatesDefinitions().subscribe(templates => {
                    this.monitoringTemplates = templates;
                });
            });
        }
    }

    remove_sender(): void {
        // Senders can only be deleted if they are not used by a rule
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.monitoringSenders, sender => {
            if (sender.selected) {
                promises.push(
                    this.faxSrv.deleteMonitoringSender(sender)
                );
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe(res => {
                this.fenUtils.afterSave(res);
                this.faxSrv.monitoringSenders().subscribe(senders => {
                    this.monitoringSenders = senders;
                });
            });
        }
    }
}
