<h1>Monitoring</h1>

<p>
    <span *ngIf="isModifiable">You can define </span>Rules which periodically check the system's health.
 Each Rule performs a specific type of check, and can refer to one or more of the defined notification Senders. When any enabled Rule
 detects a condition that needs attention, its referenced Sender(s) are triggered to deliver a notification and/or log the condition.
</p>

<div>
    <ul class="nav nav-tabs">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>
    <div *ngIf="isReady && state.activePageTab === 'General'">
        <ng-container *ngTemplateOutlet="page1"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab === 'Rules'">
        <ng-container *ngTemplateOutlet="page2"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab === 'Senders'">
        <ng-container *ngTemplateOutlet="page3"></ng-container>
    </div>
    <div *ngIf="isReady && state.activePageTab === 'Templates'">
        <ng-container *ngTemplateOutlet="page4"></ng-container>
    </div>
</div>

<!-- General tab -->
<ng-template #page1>
    <p>
    <span *ngIf="isModifiable">
    You can globally enable or disable the monitoring of all Rules here, and control how often Rule conditions are checked.
    If you change either of these values, you must click the 'Apply' button to save the change.
    </span>
    <span *ngIf="!isModifiable">
    This tab shows general Monitoring settings.
    </span>
    </p>
    <form id="monitoring_rules_form" class="form" novalidate>
        <div class="field">
            <label>Enabled:</label>
            <input type="checkbox" name="GeneralEnabled" [(ngModel)]="state.monitoringGeneral.Enabled" [disabled]="!isModifiable" (ngModelChange)="monitoringGeneralChange()"
            title="Globally enable or disable all monitoring of Rules.">
        </div>
        <div class="field">
            <label>Interval:</label>
            <input type="number" min="1" max="1000000" name="GeneralInterval" [(ngModel)]="state.monitoringGeneral.Interval" [disabled]="!isModifiable" (ngModelChange)="monitoringGeneralChange()"
            title="How often to check the enabled Rules, to see if any of them require attention.">&nbsp;seconds
        </div>
        <div class="field" *ngIf="isModifiable">
            <label></label>
            <button type="button" class="btn btn-primary" (click)="monitoringGeneralApply()" [disabled]="!monitoringGeneralApplyEnabled()">Apply</button>
        </div>
    </form>
</ng-template>

<!-- Rules tab -->
<ng-template #page2>
    <p>
    <span *ngIf="isModifiable">
    You can add, edit, delete, enable, or disable individual Rules here. Click on a Rule to edit its configuration options.
    Click the 'New' button to see the types of Rules that are available.
    </span>
    <span *ngIf="!isModifiable">
    The list of configured Rules. Click on a Rule to view its settings.
    </span>
    </p>
    <div *ngIf="isModifiable" class="btn-toolbar">
        <button type="button" class="btn" (click)="new_rule()">New...</button>
        <div class="btn-group">
            <button type="button" class="btn" (click)="enable_rule()" [disabled]="(fenUtils.nothingSelected(monitoringRules) && fenUtils.nothingSelected(monitoringSenders)) || fenUtils.anySelection(monitoringRules, 'Enabled', true) || fenUtils.anySelection(monitoringSenders, 'Enabled', true)">Enable</button>
            <button type="button" class="btn" (click)="disable_rule()" [disabled]="(fenUtils.nothingSelected(monitoringRules) && fenUtils.nothingSelected(monitoringSenders)) || fenUtils.anySelection(monitoringRules, 'Enabled', false) || fenUtils.anySelection(monitoringSenders, 'Enabled', false)">Disable</button>
        </div>
        <button type="button" class="btn" (click)="remove_rule()" [disabled]="fenUtils.nothingSelected(monitoringRules) && fenUtils.nothingSelected(monitoringSenders)">Remove</button>
    </div>
    <table id="monitoring_rules" class="table table-click table-condensed table-bordered">
        <thead>
            <tr>
                <th *ngIf="isModifiable" width="25px"></th>
                <th>Enabled</th>
                <th>Display Name</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let rule of monitoringRules" (click)="edit(rule, false)">
                <td *ngIf="isModifiable"><input type="checkbox" name="RuleSelected" [(ngModel)]="rule.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ (rule.Enabled?? false) | boolean }}</td>
                <td>{{ rule.DisplayName }}</td>
                <td>{{ rule.__type }} Rule</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="monitoringRules.length <= 0">No Results</div>
</ng-template>

<!-- Senders tab -->
<ng-template #page3>
    <p>
    <span *ngIf="isModifiable">
    You can add, edit, delete, enable, or disable individual Senders here. Click on a Sender to edit its options.
    Click the 'New' button to see the types of Senders that are available.
    Note that at least one Rule must refer to a particular Sender in its configuration options, otherwise that Sender is unused.
    </span>
    <span *ngIf="!isModifiable">
    The list of configured Senders. Click on a Sender to view its settings.
    </span>
    </p>
    <div *ngIf="isModifiable" class="btn-toolbar">
        <button type="button" class="btn" (click)="new_sender()">New...</button>
        <div class="btn-group">
            <button type="button" class="btn" (click)="enable_sender()" [disabled]="fenUtils.nothingSelected(monitoringSenders) || fenUtils.anySelection(monitoringSenders, 'Enabled', true)">Enable</button>
            <button type="button" class="btn" (click)="disable_sender()" [disabled]="fenUtils.nothingSelected(monitoringSenders) || fenUtils.anySelection(monitoringSenders, 'Enabled', false)">Disable</button>
        </div>
        <button type="button" class="btn" (click)="remove_sender()" [disabled]="fenUtils.nothingSelected(monitoringSenders)">Remove</button>
    </div>
    <table id="monitoring_senders" class="table table-click table-condensed table-bordered">
        <thead>
            <tr>
                <th *ngIf="isModifiable" width="25px"></th>
                <th>Enabled</th>
                <th>Display Name</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sender of monitoringSenders" (click)="edit(sender, false)">
                <td *ngIf="isModifiable"><input type="checkbox" name="SenderSelected" [(ngModel)]="sender.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ (sender.Enabled?? false) | boolean }}</td>
                <td>{{ sender.DisplayName }}</td>
                <td>{{ sender.__type }} Sender</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="monitoringSenders.length <= 0">No Results</div>
</ng-template>

<!-- Templates tab -->
<ng-template #page4>
    <p>
    <span *ngIf="isModifiable">
    You can add, edit, or delete individual Rule Templates here. These Templates are plain text files used by the 'SMTP' Sender
    to fill information in the email message body. Click on a Template to edit its content.
    Note that at least one Rule must refer to a Template in its configuration options, otherwise that Template is unused.
    </span>
    <span *ngIf="!isModifiable">
    The list of existing Templates. Click on a Template to view its content.
    </span>
    </p>
    <div *ngIf="isModifiable" class="btn-toolbar">
        <button type="button" class="btn" (click)="new_template()">New...</button>
        <button type="button" class="btn" (click)="remove_template()" [disabled]="fenUtils.nothingSelected(monitoringTemplates)">Remove</button>
    </div>
    <table id="monitoring_templates" class="table table-click table-condensed table-bordered">
        <thead>
            <tr>
                <th *ngIf="isModifiable" width="25px"></th>
                <th>Filename</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let template of monitoringTemplates" (click)="edit_template(template)">
                <td *ngIf="isModifiable"><input type="checkbox" name="TemplateSelected" [(ngModel)]="template.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ template.Name }}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="monitoringTemplates.length <= 0">No Results</div>
</ng-template>
