<h1>Reporting</h1>

<div *ngIf="reports.length > 0">
    <ul class="nav nav-tabs" *ngIf="reports.length >= 1">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab=pageTab;false">{{ pageTab.title }}</a>
        </li>
    </ul>
    <div *ngIf="state.activePageTab?.id==='UsageStatisticsPage'">
        <ng-container *ngTemplateOutlet="UsageStatisticsPage"></ng-container>
    </div>
</div>
<div *ngIf="reports.length == 0">
    <p>
        No Active reports were found
    </p>
</div>

<ng-template #UsageStatisticsPage>
    <p>
        Generate a report on the use of the system.
    </p>
</ng-template>

<div class="filterbar" *ngIf="state.activePageTab?.dateRange || state.activePageTab?.monthsBack">
    <div class="form-group has-feedback" role="form">
        <button type="button" class="btn pull-right" (click)="GenerateReport()">Generate report</button>
        <div *ngIf="state.activePageTab?.monthsBack">
            <input type="radio" name="mode" [(ngModel)]="operationMode" id="mode_byMonth" value="byMonth" *ngIf="state.activePageTab?.monthsBack && state.activePageTab?.dateRange" />
            <label for="mode_byMonth">By month:</label>&ngsp;
            <select name="monthsBack" 
                    id="monthsBack" 
                    [(ngModel)]="monthsBack" 
                    (click)="operationMode='byMonth'">
                <option value="">-- pick a month</option>
                <option *ngFor="let opt of monthOptions" [ngValue]="opt.key">{{ opt.value | date: formatting.month }}</option>
            </select>
        </div>
        <div *ngIf="state.activePageTab?.dateRange">
            <input type="radio" name="mode" [(ngModel)]="operationMode" id="mode_byPeriod" value="byPeriod" *ngIf="state.activePageTab?.monthsBack && state.activePageTab?.dateRange" />
            <label for="mode_byPeriod">Start:</label>&ngsp;<input type="date" class="form-control" [(ngModel)]="startDate" (click)="operationMode='byPeriod'" />
            <label>until:</label>&ngsp;<input type="date" class="form-control" [(ngModel)]="untilDate" (click)="operationMode='byPeriod'" />
        </div>
    </div>
    <p *ngIf="error" class="text-danger">{{ error }}</p>
</div>

<div id="list_content" *ngIf="state.activePageTab?.reportName === 'UsageStatistics' && report?.generalData">
    <table class="table table-condensed ">
        <tbody>
            <tr title="{{report!.generalData.GenerationTime.Description}}">
                <td colspan="2">Generated:</td>
                <td>{{ report!.generalData.GenerationTime.Value | date: formatting.date }} {{ report!.generalData.GenerationTime.Value | date: formatting.time }}</td>
            </tr>
            <tr title="{{report!.generalData.StartDate.Description}}">
                <td>Period:</td>
                <td>Start</td>
                <td>{{ report!.generalData.StartDate.Value | date: formatting.date }}</td>
            </tr>
            <tr title="{{report!.generalData.EndDate.Description}}">
                <td></td>
                <td>Until</td>
                <td>{{ report!.generalData.EndDate.Value | date: formatting.date }}</td>
            </tr>
            <tr *ngIf="report!.generalData.OrganizationCountReported.Value > 1 || report!.generalData.OrganizationCount.Value > 1"
                    title="{{report!.generalData.OrganizationCount.Description}}">
                <td>Organizations:</td>
                <td>Enabled</td>
                <td>{{ report!.generalData.OrganizationCount.Value }}</td>
            </tr>
            <tr *ngIf="report!.generalData.OrganizationCountReported.Value > 1 || report!.generalData.OrganizationCount.Value > 1"
                    title="{{report!.generalData.OrganizationCountReported.Description}}">
                    <td></td>
                <td>Active in report</td>
                <td>{{ report!.generalData.OrganizationCountReported.Value }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div id="list_content" *ngIf="state.activePageTab?.reportName === 'UsageStatistics' && report?.msgTypes">
    <h3>
        Usage
    </h3>
    <ul class="nav nav-tabs" *ngIf="report!.msgTypes.length >= 1">
        <li *ngFor="let msgType of report!.msgTypes" [class.active]="msgType === state.activeMsgTab">
            <a href="" (click)="state.activeMsgTab=msgType;false">{{ msgType }}</a>
        </li>
    </ul>
    <div class="filterbar" *ngIf="report!.byMessageType[state.activeMsgTab]?.devices">
        <label>Results by service:&nbsp;</label>
        <select [(ngModel)]="report!.byMessageType[state.activeMsgTab].activeDevice">
            <option *ngFor="let dev of report!.byMessageType[state.activeMsgTab].devices" [ngValue]="dev">{{ dev.Name }}</option>
        </select>
    </div>
    <table class="table table-condensed" *ngIf="report!.byMessageType[state.activeMsgTab]">
        <tbody>
            <tr *ngFor="let statistic of report!.byMessageType[state.activeMsgTab][report!.byMessageType[state.activeMsgTab].activeDevice.Key]" title="{{ statistic.Description }}">
                <td>{{ statistic.Title || statistic.Property }}</td>
                <td>{{ statistic.Value }}</td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="reports.length > 0 && (!report?.byMessageType[state.activeMsgTab] || !report!.byMessageType[state.activeMsgTab][report!.byMessageType[state.activeMsgTab].activeDevice.Key] || report!.byMessageType[state.activeMsgTab][report!.byMessageType[state.activeMsgTab].activeDevice.Key].length <= 0)">No Results</div>
