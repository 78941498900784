import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestOSRDef, FaxConfigRestOSRRoutingOrder } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { OsrRouteComponent, IOsrRouteEditee } from '../osr-route/osr-route.component';
import { OsrOrderComponent, IOsrOrder } from '../osr-order/osr-order.component';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, ICellRendererParams, GridOptions, GridReadyEvent,
    GetContextMenuItemsParams, GetRowIdParams, MenuItemDef, PaginationChangedEvent,
    SetFilterValuesFuncParams, ValueFormatterParams, IServerSideGetRowsParams, CellDoubleClickedEvent,
    IServerSideDatasource, LoadSuccessParams, FilterChangedEvent
} from 'ag-grid-community';
import { SortModelItem } from 'ag-grid-enterprise';


@Component({
    selector: 'app-osr',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './osr.component.html',
    styleUrl: './osr.component.css'
})
export class OsrComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    cssGridHeight = { 'height': '300px' };
    osrGridApi: GridApi | null = null;
    osrGridOptions?: GridOptions;
    osrGridInitialized: boolean = false;

    loadingRules: boolean = false;
    enableEnabled: boolean = false;
    disableEnabled: boolean = false;
    removeEnabled: boolean = false;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    colEnabledCellRenderer(params: ICellRendererParams): string {
        let img = document.createElement("img");
        if (params.value && params.value !== 'F') {
            img.src ='icon-checkmark.png';
        } else {
            img.src ='icon-cross.png';
        }
        return img.outerHTML;
    }

    osrColDefs: ColDef<FaxConfigRestOSRDef>[] = [
        {
            field: 'Enabled',
            headerTooltip: 'Enabled',
            cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: [],
                cellRenderer: (params: ICellRendererParams) => this.colEnabledCellRenderer(params),
                suppressMiniFilter : true,
                suppressSelectAll : true,
                values: [ 'F', 'T' ]
            }
        },
        {
            field: 'Block',
            hide: true,
            filter: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            field: 'RouteValue',
            headerTooltip: 'Route Value'
        },
        {
            field: 'RouteType',
            headerTooltip: 'Route Type',
            useValueFormatterForExport: true,
            valueFormatter: (params: ValueFormatterParams) => {
                return this.faxSrv.outboundRouteTypes().byCode[params.data.RouteType].display;
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressMiniFilter : true,
                values: _.pluck(this.faxSrv.outboundRouteTypes().list, 'code'),
                valueFormatter: (params: ValueFormatterParams) => {
                    return this.faxSrv.outboundRouteTypes().byCode[params.value as string].display;
                },
            }
        },
        {
            field: 'MessageType',
            headerTooltip: 'Message Type',
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: [],
                suppressMiniFilter : true,
                values: (params: SetFilterValuesFuncParams) => {
                    this.faxSrv.messageTypes().subscribe(messageTypes => {
                        params.success(messageTypes);
                    });
                }
            }
        },
        {
            field: 'Service',
            headerTooltip: 'Service',
            valueFormatter: (params: ValueFormatterParams) => {
                return params.data.Block? '<Blocked>': params.data.Service;
            },
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual',
                {
                    displayKey: 'blocked',
                    displayName: 'Blocked',
                    predicate: (x: any, y: any) => { return true; },
                    numberOfInputs: 0
                },
                {
                    displayKey: 'notBlocked',
                    displayName: 'Not blocked',
                    predicate: (x: any, y: any) => { return true; },
                    numberOfInputs: 0
                }],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'Description',
            headerTooltip: 'Description'
        }
    ];

    init(): void {
        this.osrGridOptions = {
            columnDefs: this.osrColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['apply','reset','cancel'],
                    filterOptions: ['contains','notContains','equals','notEqual'],
                    maxNumConditions: 1,
                    closeOnApply: true
                }
            },
            defaultCsvExportParams: { allColumns: true, fileName: 'export_osr.csv' },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            suppressMultiSort: true,
            onGridReady: (params: GridReadyEvent) => {
                this.osrGridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
                params.api.setGridOption('serverSideDatasource', this.osrDataSource);
                this.osrLoadViewState(params.api);
                this.isReady = true;
            },
            getRowId: params => this.osrGetRowId(params),
            onCellDoubleClicked: params => this.osrOnCellDoubleClicked(params),
            onSelectionChanged: params => this.osrOnSelectionChanged(params.api),
            onPaginationChanged: params => this.osrOnPaginationChanged(params),
            getContextMenuItems: params => this.osrGetContextMenuItems(params),
            onGridPreDestroyed: params =>this.osrSaveViewState(params.api)
        };
        this.osrGridInitialized = true;
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }

    osrLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'OsrViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    osrSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'OsrViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    osrDataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            let successData: LoadSuccessParams;
            let model: SortModelItem[];
            let sortDir: string = '';
            let sortKey: string = '';
            let filters: { field: string, value: string }[] = [];
            let count: number = params.request.endRow! - params.request.startRow!;
            this.loadingRules = true;

            model = params.request.sortModel;
            if (model && model.length > 0) {
                sortKey = model[0].colId;
                sortDir = model[0].sort;
            }

            if (params.request.filterModel) {
                let foundEmptySet = _.find(params.request.filterModel, (model, key) => {
                    if (model.filterType === 'text')
                    {
                        if (model.type === 'blocked') {
                            filters.push({ field: 'Block_equals', value: 'T' });
                        } else if (model.type === 'notBlocked') {
                            filters.push({ field: 'Block_equals', value: 'F' });
                        } else if (model.filter) {
                            filters.push({ field: key+'_'+model.type, value: model.filter });
                        }
                    }
                    else if (model.filterType === 'set' && model.values)
                    {
                        if (model.values.length > 0) {
                            filters.push({ field: key, value: model.values });
                        } else {
                            // Empty set
                            return true;
                        }
                    }
                    return false;
                });
                // Return an empty list if an empty 'set' filter is selected in the UI.
                if (foundEmptySet) {
                    params.success({ rowData: [], rowCount: 0 });
                    return;
                }
            }

            this.faxSrv.osrDefinitions(params.request.startRow!, count, filters, sortKey, sortDir).subscribe({
                next: res => {
                    this.loadingRules = false;
                    successData = { rowData: res.Results?? [] };
                    if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                        successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                    }
                    params.success(successData);
                },
                error: err => {
                    this.loadingRules = false;
                    params.fail();
                }
            });
        }
    };

    refresh(): void {
        // This will trigger osrOnPaginationChanged and osrOnSelectionChanged:
        this.osrGridApi?.refreshServerSide({purge: true});
    }

    refreshPage(): void {
        if (this.osrGridApi) {
            let page = this.osrGridApi.paginationGetCurrentPage();
            // This will trigger osrOnPaginationChanged and osrOnSelectionChanged:
            this.osrGridApi.refreshServerSide({purge: true});
            if (this.osrGridApi.paginationGetCurrentPage() != page) {
                this.osrGridApi.paginationGoToPage(page);
            }
        }
    }

    clearFilters(): void {
        this.osrGridApi?.applyColumnState({
            defaultState: { sort: null },
        });
    }

    osrGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        let sel = params.api.getSelectedNodes();

        const canEdit = this.auth.isModifiable('Outbound Service Routing');
        return [
            'copy', 'separator',
            {
                name: 'New ...',
                action: () => this.neo(),
                disabled: !canEdit
            },
            {
                name: canEdit? 'Edit entry...': 'View entry...',
                action: () => this.osrOnCellDoubleClicked(sel[0]),
                disabled: (!sel || sel.length != 1)
            },
            {
                name: 'Remove entries',
                action: () => this.remove(),
                disabled : (!sel || sel.length == 0 ) || (!canEdit)
            },
            'separator',
            {
                name: 'CSV Export',
                action: () => this.export(),
                disabled : false
            }
        ];
    }

    osrGetRowId(params: GetRowIdParams<FaxConfigRestOSRDef>): string {
        // Compose unique ID as: 'RouteType:MessageType[:RouteValue]'
        let value: string = params.data.RouteValue? (':' + params.data.RouteValue): '';
        return (params.data.RouteType! + ':' + params.data.MessageType + value);
    }

    osrOnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit(params.data);
        }
    }

    osrOnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger osrOnSelectionChanged:
        params.api.deselectAll();
    }

    osrOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger osrOnSelectionChanged:
        params.api.deselectAll();
    }

    osrOnSelectionChanged(api: GridApi | null): void {
        let enabled = 0, disabled = 0;
        let sel = api? api.getSelectedNodes()?? []: [];
        this.removeEnabled = (sel && sel.length > 0);
        _.each(sel, item => {
            if (item.data.Enabled) { enabled++; } else { disabled++; }
        });
        this.enableEnabled = disabled > 0;
        this.disableEnabled = enabled > 0;
    }

    updateEnabled(enabled: boolean): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.osrGridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestOSRDef>) => {
            let newdata = _.extend({}, item.data, { Enabled: enabled });
            promises.push(this.faxSrv.updateOsrDefinition(
                newdata.RouteType?? '',
                newdata.MessageType?? '',
                newdata.RouteValue?? '',
                newdata
            ));
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        _.each(sel, (item: IRowNode<FaxConfigRestOSRDef>) => {
                            item.updateData(_.extend({}, item.data, { Enabled: enabled }));
                        });
                        this.osrOnSelectionChanged(this.osrGridApi);
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    enable(): void { this.updateEnabled(true); };
    disable(): void { this.updateEnabled(false); };

    order(): void {
        this.faxSrv.getOsrRoutingOrder().subscribe((res: FaxConfigRestOSRRoutingOrder) => {
            let editee: IOsrOrder[] = [];
            _.each(res, item => {
                editee.push({ RouteType: item.Key, DisplayName: item.Value });
            });
            const dialogRef = this.dialog.open(OsrOrderComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) {
                    this.clearFilters();
                    this.refresh();
                }
            });
        });
    }

    remove(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        let sel = this.osrGridApi?.getSelectedNodes()?? [];
        _.each(sel, (item: IRowNode<FaxConfigRestOSRDef>) => {
            const route: FaxConfigRestOSRDef | undefined = item.data;
            if (route) {
                promises.push(this.faxSrv.deleteOsrDefinition(
                    route.RouteType?? '',
                    route.MessageType?? '',
                    route.RouteValue?? ''
                ));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.refreshPage();
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    neo(): void {
        const dialogRef = this.dialog.open(OsrRouteComponent, { data: { editee: null }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refreshPage(); }
        });
    }

    edit(route: FaxConfigRestOSRDef): void {
        let editee: IOsrRouteEditee = {
            RouteType: route.RouteType?? '',
            MessageType: route.MessageType?? '',
            RouteValue: route.RouteValue?? ''
        };
        const dialogRef = this.dialog.open(OsrRouteComponent, { data: { editee: editee }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refreshPage(); }
        });
    }

    osrCsvSource = {
        getRows: (params: IServerSideGetRowsParams) => {
            let successData: LoadSuccessParams;
            let count: number = params.api.getGridOption('cacheBlockSize')?? 0;
            this.loadingRules = true;

            this.faxSrv.osrDefinitions(params.request.startRow!, count, [], '', '').subscribe({
                next: res => {
                    this.loadingRules = false;
                    successData = { rowData: res.Results?? [] };
                    if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                        successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                    }
                    params.success(successData);
                    params.api.exportDataAsCsv();

                    // Restore the old block size after CSV export.
                    params.api.setGridOption('cacheBlockSize', this.session.portalCfg.items_per_page);
                    params.api.setGridOption('serverSideDatasource', this.osrDataSource);
                },
                error: err => {
                    this.loadingRules = false;
                    params.fail();

                    params.api.setGridOption('cacheBlockSize', this.session.portalCfg.items_per_page);
                    params.api.setGridOption('serverSideDatasource', this.osrDataSource);
                }
            });
        }
    };

    export(): void {
        if (this.osrGridApi) {
            // Temporarily increase the block size to export all database rows.
            this.osrGridApi.setGridOption('cacheBlockSize', 1000000);
            this.osrGridApi.setGridOption('serverSideDatasource', this.osrCsvSource);
        }
    }
}
