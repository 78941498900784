import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

export interface ICoversheetTimeformatEditee {
    timeFormat: number;
}

@Component({
    selector: 'app-coversheet-timeformat',
    imports: [FormsModule, CommonModule ],
    providers: [DatePipe],
    templateUrl: './coversheet-timeformat.component.html',
    styleUrl: './coversheet-timeformat.component.css'
})
export class CoversheetTimeformatComponent {

    timeFormats: number[] = [ 12, 24 ];

    currentDateTime: Date = new Date(new Date().getFullYear(),0,1,21,15,35,0);
    timePreview: string = '';
    editee: ICoversheetTimeformatEditee;

    constructor(
        private dialogRef: DialogRef,
        private datePipe: DatePipe,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetTimeformatEditee;
        this.format_change();
    }

    format_change() {
        this.timePreview = this.getPreview();
    }

    getPreview(): string {
        let h = this.currentDateTime.getHours();
        let m = this.currentDateTime.getMinutes();
        let a: string;
        if (this.editee.timeFormat == 12) {
            a = (h >= 12)? ' pm': ' am';
            if (h > 12) { h -= 12; }
        } else {
            a = '';
        }
        return (h || 12) + ':' + ((m > 9)? '': '0') + m + a;
    }

    save(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
