import { CommonModule } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { FormsModule, NgForm } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestECRFaxFormatList, FaxConfigRestKernelCoversheetTemplates, FaxConfigRestKernelMailTemplates,
    FaxConfigRestNumSubstCountryList, FaxConfigRestStringList, FaxConfigRestUser, FaxConfigRestUsersViewOptions
} from '../api/api';
import { CFenLimits, IPeriodUnit, IPeriodLimits } from '../utils/utl';
import { FenUtilsService } from '../services/fenutils.service';
import * as _ from 'underscore';

interface IUsersEditTab {
    Caption: string,
    showTab: boolean,
    isReady: boolean
}

@Component({
    selector: 'app-new-user-defaults',
    imports: [FormsModule, CommonModule],
    templateUrl: './new-user-defaults.component.html',
    styleUrl: './new-user-defaults.component.css'
})
export class NewUserDefaultsComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    public fenLimits: CFenLimits = new CFenLimits();

    @ViewChild('connNewUserDefaultsForm') form!: NgForm;

    isReady: boolean = false;
    isModifiable: boolean = false;
    editee: FaxConfigRestUser = {};

    mailTemplates: FaxConfigRestKernelMailTemplates = [];
    coversheets: FaxConfigRestKernelCoversheetTemplates = [];
    smsTemplates: FaxConfigRestKernelCoversheetTemplates = [];
    faxFormats: FaxConfigRestECRFaxFormatList = [];
    msgTypes: FaxConfigRestStringList = [];
    numSubstCountries: FaxConfigRestNumSubstCountryList = [];
    userViewOptions: FaxConfigRestUsersViewOptions = {};

    periodUnits: IPeriodUnit[] = [];
    limits?: IPeriodLimits;

    pageTabs: IUsersEditTab[] = [
        { Caption: 'General',         showTab: true,  isReady: false },
        { Caption: 'FAX',             showTab: false, isReady: false },
        { Caption: 'SMS',             showTab: false, isReady: false },
        { Caption: 'Miscellaneous',   showTab: true,  isReady: false }
    ];
    state = { activePageTab: this.pageTabs[0] };

    constructor() {
    }

    init(editee: FaxConfigRestUser, isModifiable: boolean) {
        let promises: Observable<boolean>[];

        this.editee = editee;
        this.isModifiable = isModifiable;
        this.periodUnits = this.fenLimits.periodUnits;
        this.limits = this.fenLimits.limits(this.editee);

        promises = [
            this.faxSrv.kernelMailTemplateDefinitions(0).pipe(map(res => {
                this.mailTemplates = _.map(res, a => { return a.toUpperCase(); });
                this.pageTabs[0].isReady = true;
                return true;
            })),
            this.faxSrv.kernelTemplateDefinitions(0, 'Fax').pipe(map(res => {
                this.coversheets = _.map(res, a => { return a.toUpperCase(); });
                return true;
            })),
            this.faxSrv.kernelTemplateDefinitions(0, 'SMS').pipe(map(res => {
                this.smsTemplates = _.map(res, a => { return a.toUpperCase(); });
                return true;
            })),
            this.faxSrv.ecrFaxFormats().pipe(map(res => {
                this.faxFormats = res;
                return true;
            })),
            this.faxSrv.messageTypes().pipe(map(res => {
                this.msgTypes = res;
                this.pageTabs[1].showTab = _.contains(this.msgTypes, 'FAX');
                this.pageTabs[2].showTab = _.contains(this.msgTypes, 'SMS');
                this.pageTabs[1].isReady = true;
                this.pageTabs[2].isReady = true;
                this.isReady = true;
                return true;
            })),
            this.faxSrv.numSubstCountries().pipe(map(countries => {
                this.numSubstCountries = countries;
                this.pageTabs[3].isReady = true;
                return true;
            })),
            this.faxSrv.userViewOptions().pipe(map(opts => {
                this.userViewOptions = opts;
                return true;
            }))
        ];
        forkJoin(promises).subscribe();
    }

    visiblePageTabs(): IUsersEditTab[] {
        return this.pageTabs.filter(tab => { return tab.showTab; });
    }

    isValid(): boolean {
        return (this.form?.valid?? false) && this.fenLimits.areLimitsValid(this.limits);
    }

    getEditee(): FaxConfigRestUser {
        let editee: FaxConfigRestUser = _.clone(this.editee);
        // We need to clone the Fax/SMS objects in order to detach them from the UI.
        if (editee.Fax) editee.Fax = _.clone(this.editee.Fax);
        if (editee.SMS) editee.SMS = _.clone(this.editee.SMS);
        // Force International to be disabled when saving settings with a msgtype disabled.
        if (editee.Fax?.AllowToSendFax === false) editee.Fax.AllowToSendFaxInt = false;
        if (editee.SMS?.AllowToSendSMS === false) editee.SMS.AllowToSendSMSInt = false;
        this.fenLimits.setLimits(editee, this.limits!);
        return editee;
    }
}
