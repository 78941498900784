<h1 id="ecrHeader">Extended Custom Routing</h1>

<p>
    Extended Custom Routing (ECR) gives you the possibility to define rules for routing incoming messages based on
    fax number, number of pages, or the size of the message to any Faxination application connector.
</p>

<div class="filterbar">
    <select style="min-width:250px;" name="ecrDefinitions" [(ngModel)]="ecrDefinition" (change)="onEcrDefinitionChange()">
        <option *ngFor="let def of ecrDefinitions" [ngValue]="def">{{ ecrDefinitionDisplay(def) }}</option>
    </select>
    <label *ngIf="ecrDefinition && auth.isModifiable('Extended Custom Routing')">Enabled: <input type="checkbox" name="enabled" [(ngModel)]="ecrDefinition.Enabled" (change)="onEcrDefinitionEnable()"></label>
</div>

<div *ngIf="pager && ecrDefinition && auth.isModifiable('Extended Custom Routing')" class="btn-toolbar">
    <button type="button" class="btn" (click)="neo()">New</button>
    <div class="btn-group">
        <button type="button" class="btn" (click)="enable()" [disabled]="!fenUtils.anySelection(pager.items, 'RuleEnabled', false)">Enable</button>
        <button type="button" class="btn" (click)="disable()" [disabled]="!fenUtils.anySelection(pager.items, 'RuleEnabled', true)">Disable</button>
    </div>
    <button type="button" class="btn" (click)="remove()" [disabled]="fenUtils.nothingSelected(pager.items)">Remove</button>
</div>

<app-pagination [limit]="session.portalCfg.items_per_page" [query]="query"></app-pagination>

<table id="ecr_rules" class="table table-click table-condensed table-bordered" *ngIf="pager && ecrDefinition">
    <thead>
        <tr>
            <th *ngIf="auth.isModifiable('Extended Custom Routing')" width="25px"></th>
            <th>Enabled</th>
            <th>Name</th>
            <th>Description</th>
            <th>Address Type</th>
            <th>Address</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rule of pager.items" (click)="edit(rule)">
            <td *ngIf="auth.isModifiable('Extended Custom Routing')"><input type="checkbox" [(ngModel)]="rule.selected" (click)="$event.stopPropagation()"></td>
            <td>{{ rule.RuleEnabled | boolean }}</td>
            <td>{{ rule.RuleName }}</td>
            <td>{{ rule.RuleComment }}</td>
            <td>{{ rule.AddressType }}</td>
            <td>{{ rule.RouteAddress }}</td>
        </tr>
    </tbody>
</table>
<div *ngIf="ecrDefinition && pager?.isEmpty()">No Results</div>
