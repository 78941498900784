<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Create New Monitoring Rule</h1>
    </div>
    <form class="form" name="monitoringNewRule" novalidate (submit)="submit()">
        <div class="modal-body">
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_QueueItems" value="QueueItems">
                <label for="newType_QueueItems"><b>QueueItems Rule</b></label>
                <p>This type of rule monitors the number of items in the Faxination Queue, and can trigger a notification if the total reaches a specified amount.</p>
            </div>
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_QueueAge" value="QueueAge">
                <label for="newType_QueueAge"><b>QueueAge Rule</b></label>
                <p>This type of rule monitors how long items have been in the Faxination Queue, and can trigger a notification if any item reaches a specified age.</p>
            </div>
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_MessageCheck" value="MessageCheck">
                <label for="newType_MessageCheck"><b>MessageCheck Rule</b></label>
                <p>This type of rule sends a test fax and waits for the result, and can trigger a notification if the fax is not delivered within a specified time.</p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!newtype">Next</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
