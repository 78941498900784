import { CommonModule, NgFor } from '@angular/common';
import { Observable, forkJoin, finalize } from 'rxjs';
import { Component, inject, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestAzureADConfig, FaxConfigRestAccountsGeneral, FaxConfigRestAccountBase, FaxConfigRestADGroupsList, FaxConfigRestADGroup } from '../api/api';
import { BooleanPipe } from '../utils/boolean.pipe';
import { IPaginationQuery } from '../pagination/pagination.component';
import * as _ from 'underscore';
import { AccountEditComponent, IAccountEdit } from '../account-edit/account-edit.component';
import { AccountCreateComponent, IAccountCreate } from '../account-create/account-create.component';
import { AccountGroupEditComponent, IAccountGroupEdit } from '../account-group-edit/account-group-edit.component';
import { AccountSetupComponent, IAccountSetup } from '../account-setup/account-setup.component';
import { AccountGroupSetupComponent, IAccountGroupSetup } from '../account-group-setup/account-group-setup.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
    imports: [FormsModule, CommonModule, PaginationModule, NgFor, BooleanPipe],
    templateUrl: './accounts.component.html',
    styleUrl: './accounts.component.css'
})

export class AccountsComponent implements AfterViewInit, IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    pagerAccounts!: PaginationComponent;
    public accountsQuery: IPaginationQuery = this;

    General: FaxConfigRestAccountsGeneral = {};
    azureConfig: FaxConfigRestAzureADConfig | null = null;
    lstGroups: FaxConfigRestADGroupsList = [];
    isReady: boolean = false;

    pageTabs = ['Accounts'];
    state = { activePageTab: '' };
    formDataAccounts = { searchKeyAccounts: '' };

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
    }

    ngAfterViewInit() {
        this.session.rootPromises.subscribe(res => { this.init(); });
    }

    private init(): void {
        let promises: {
            p1: Observable<FaxConfigRestAccountsGeneral>,
            p2: Observable<FaxConfigRestADGroupsList>,
            p3: Observable<FaxConfigRestAzureADConfig>
        } = {
            p1: this.faxSrv.GetAccountsGeneral(),
            p2: this.faxSrv.GetADGroupsDefinitions(),
            p3: this.faxSrv.getAzureADConfig()
        };

        forkJoin(promises)
            .pipe(finalize(() => {
                if (!this.session.isMultiTenant() && !this.azureConfig?.Enforced) {
                    this.pageTabs.push('Groups');
                }
                this.state.activePageTab = this.pageTabs[0];
                this.pagerAccounts.reset();
                this.isReady = true;
            }))
            .subscribe({
                next: (res) => {
                    this.General = res.p1;
                    this.lstGroups = res.p2;
                    this.azureConfig = res.p3;
                },
                error: (err) => alert(err.message)
            });
    }

    refresh_general(): void {
        this.faxSrv.GetAccountsGeneral().subscribe(res => {
            this.General = res;
            // Always refresh the accounts list, in case settings changed that were unseen by us.
            this.refresh_accounts();
        });
    }

    refresh_accounts(): void {
        this.pagerAccounts.refresh();
    }

    refresh_groups(): void {
        this.faxSrv.GetADGroupsDefinitions().subscribe(res => {
            this.lstGroups = res;
        });
    }

    GetAllowedRoles(): string[] {
        let knownRoles: string[] = _.keys(this.auth.knownRoles());
        let decodedRole: number = this.auth.decodeRole(this.auth.getRole());
        let allowedRoles: string[] = [];
        _.each(knownRoles, (roleName: string) => {
            if (decodedRole <= this.auth.decodeRole(roleName)) {
                allowedRoles.push(roleName);
            }
        });
        return allowedRoles;
    }

    invoke(offset: number, limit: number) {
        let source = (this.azureConfig?.Enabled && this.azureConfig?.Enforced)
                        ? 'AzureAD,AzureAD_MultiTenant,AzureAD_Social'
                        : '';
        let filter = {
            search: this.formDataAccounts.searchKeyAccounts
        };
        return this.faxSrv.GetAccountsDefinitions(source, offset, limit, filter);
    }

    search_accounts() {
        this.pagerAccounts?.reset();
    }

    new_account(): void {
        if (this.session.contextAllOrganizations()) {
            alert('Please select an organization context first.');
            return;
        }
        let account: IAccountCreate = {
            OrganizationId: this.session.currentOrgId!,
            OrganizationName: this.session.currentOrgName,
            Source: '',
            Username: '',
            Password: '',
            Department: '',
            Description: '',
            Enabled: true,
            Role: this.auth.getRole(),
            IsAzureUser: this.azureConfig?.Enabled?? false,
            AllowedRoles: this.GetAllowedRoles()
        };
        if (this.azureConfig?.Enabled) {
            account.Alias            = '';
            account.Domains          = this.azureConfig.Domains?? [];
            account.Domain           = account.Domains[0]?? '';
            account.AllowLocal       = !this.azureConfig.Enforced;
            account.AllowFenTenant   = this.azureConfig.AllowFenTenant;
            account.AllowMultiTenant = this.azureConfig.AllowMultiTenant;
            account.AllowSocial      = this.azureConfig.AllowSocial;
            if (account.Source === '' && account.AllowLocal) {
                account.Source = 'SQLDB';
            }
            if (account.Source === '' && account.AllowFenTenant) {
                account.Source = 'AzureAD';
            }
            if (account.Source === '' && account.AllowMultiTenant) {
                account.Source = 'AzureAD_MultiTenant';
            }
            if (account.Source === '' && account.AllowSocial) {
                account.Source = 'AzureAD_Social';
            }
        } else {
            if (account.Source === '') {
                account.Source = 'SQLDB';
            }
        }
        const dialogRef = this.dialog.open(AccountCreateComponent, {data: { editee: account }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh_accounts(); }
        });
    }

    edit_account(item: FaxConfigRestAccountBase) {
        let account: IAccountEdit = {
            OrganizationId: item.OrganizationId,
            OrganizationName: item.OrganizationName,
            Source: item.Source,
            OrigUsername: item.Username?? '',
            Username: item.Username,
            Password: '',
            NewPassword: '',
            Verify: '',
            Enabled: item.Enabled,
            Department: item.Department,
            Description: item.Description,
            Role: item.Role,
            LastLogin: item.LastLogin,
            Created: item.Created,
            Modified: item.Modified,
            IsSelf: (item.Username?.toLowerCase() === this.session.currentUser!.toLowerCase()),
            IsAzureUser: (item.Source === 'AzureAD' || item.Source === 'AzureAD_MultiTenant' || item.Source === 'AzureAD_Social'),
            IsEmailUsername: item.Username?.match(/^.+@[^. ]+(\.[^. ]+)+$/)? true: false,
            AllowedRoles: this.GetAllowedRoles()
        };
        if (this.azureConfig?.Enabled) {
            account.Alias            = item.Username?.split('@')[0];
            account.Domain           = item.Username?.split('@')[1];
            account.AllowLocal       = !this.azureConfig.Enforced;
            account.AllowFenTenant   = this.azureConfig.AllowFenTenant;
            account.AllowMultiTenant = this.azureConfig.AllowMultiTenant;
            account.AllowSocial      = this.azureConfig.AllowSocial;
        }
        const dialogRef = this.dialog.open(AccountEditComponent, {data: { editee: account }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh_accounts(); }
        });
    }

    new_group(): void {
        let group: IAccountGroupEdit = {
            OrigADGroup: '',
            ADGroup: '',
            Description: '',
            Role: this.auth.getRole(),
            IsNew: true,
            AllowedRoles: this.GetAllowedRoles()
        };
        const dialogRef = this.dialog.open(AccountGroupEditComponent, {data: { editee: group }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh_groups(); }
        });
    }

    edit_group(item: FaxConfigRestADGroup): void {
        if (this.auth.isModifiable('All')) {
            let group = {
                OrigADGroup: item.ADGroup,
                ADGroup: item.ADGroup,
                Description: item.Description,
                Role: item.Role,
                IsNew: false,
                AllowedRoles: this.GetAllowedRoles()
            };
            const dialogRef = this.dialog.open(AccountGroupEditComponent, {data: { editee: group }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.refresh_groups(); }
            });
        }
    }

    setup_accounts() {
        this.faxSrv.GetAccountsGeneral().subscribe({
            next: res => {
                this.General = res;
                const lockoutEnabled = (this.General.LockoutTrigger && this.General.LockoutTrigger > 0)? true: false;
                let setup: IAccountSetup = {
                    PasswordMinimum: this.General.PasswordMinimum?? 8,
                    PasswordStrong: this.General.PasswordStrong? true: false,
                    EnableLockout: lockoutEnabled,
                    LockoutTrigger: lockoutEnabled? (this.General.LockoutTrigger || 5): 5,
                    LockoutMinutes: lockoutEnabled? (this.General.LockoutMinutes || 30): 30,
                    DBSource: this.General.DBSource?? '',
                    DBUsername: this.General.DBUsername?? '',
                    DBPassword: '',
                    DBVerifyPassword: ''
                };
                const dialogRef = this.dialog.open(AccountSetupComponent, {data: { editee: setup }});
                dialogRef.afterClosed().subscribe(modified => {
                    // Always refresh the settings and accounts list, in case settings changed that were unseen by us.
                    this.refresh_general();
                });
            },
            error: err => alert(err.message)
        });
    }

    onEnableGroups() {
        let def: FaxConfigRestAccountsGeneral = { LDAPEnabled: this.General.LDAPEnabled };
        this.faxSrv.PutAccountsGeneral(def).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    };

    setup_groups() {
        this.faxSrv.GetAccountsGeneral().subscribe({
            next: res => {
                this.General = res;
                let setup: IAccountGroupSetup = {
                    LDAPEnabled: this.General.LDAPEnabled?? false,
                    LDAPServer: this.General.LDAPServer?? '',
                    LDAPDomain: this.General.LDAPDomain?? '',
                    LDAPProvider: this.General.LDAPProvider?? 'LDAP'
                };
                const dialogRef = this.dialog.open(AccountGroupSetupComponent, {data: { editee: setup }});
                dialogRef.afterClosed().subscribe(modified => {
                    // Always refresh the settings and groups list, in case settings changed that were unseen by us.
                    this.refresh_general();
                    this.refresh_groups();
                });
            },
            error: err => alert(err.message)
        });
    }

    remove_account_enabled(): boolean {
        if (this.pagerAccounts) {
            // Do not allow deleting yourself
            let selfLower = this.session.currentUser?.toLowerCase();
            if (_.find(this.pagerAccounts.items, (account) => {
                return account.selected && account.Username.toLowerCase() === selfLower;
            })) return false;
            // Return true if any accounts are selected
            return _.some(this.pagerAccounts.items, (account) => { return account.selected; });
        }
        return false;
    }

    remove_account() {
        if (this.pagerAccounts) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.pagerAccounts.items, (account: FaxConfigRestAccountBase) => {
                if (account.selected && account.Username) {
                    promises.push(this.faxSrv.DeleteAccountsDefinition(account.Source?? '', account.Username));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) { this.refresh_accounts(); }
                    },
                    error: err => alert(err.message)
                });
            }
        }
    }

    isAccountLocked(account: FaxConfigRestAccountBase): boolean {
        return account.LockedUntil? true: false;
    }

    isAnyAccountLocked(): boolean {
        return _.find(this.pagerAccounts?.items, (account) => {
            return account.LockedUntil;
        });
    }

    unlock_account_enabled(): boolean {
        return _.find(this.pagerAccounts?.items, (account) => {
            return (account.selected && account.LockedUntil);
        });
    }

    unlock_account() {
        if (this.pagerAccounts) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.pagerAccounts.items, (account: FaxConfigRestAccountBase) => {
                if (account.selected && account.Username) {
                    promises.push(this.faxSrv.PutAccountsDefinition('SQLDB', account.Username, { FailedLogins: 0 }));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) { this.refresh_accounts(); }
                    },
                    error: err => alert(err.message)
                });
            }
        }
    }

    remove_group_disabled(): boolean {
        return this.fenUtils.nothingSelected(this.lstGroups);
    }

    remove_group() {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.lstGroups, (group: FaxConfigRestADGroup) => {
            if (group.selected && group.ADGroup) {
                promises.push(this.faxSrv.DeleteADGroupsDefinition(group.ADGroup));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) { this.refresh_groups(); }
                },
                error: err => alert(err.message)
            });
        }
    }
}
