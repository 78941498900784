import { AfterViewInit, Component, Inject, inject, ViewChild } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestHostAttType, FaxConfigRestHostBodyType, FaxConfigRestHostChannel,
    FaxConfigRestHostGeneral, FaxConfigRestOrganizationSmtpDomainInfo, FaxConfigRestUser
} from '../api/api';
import { NewUserDefaultsModule, NewUserDefaultsComponent } from '../new-user-defaults/new-user-defaults.module';
import * as _ from 'underscore';

export interface IChannelScope {
    MailSystem: string;
    hostGeneral: FaxConfigRestHostGeneral;
    channel: FaxConfigRestHostChannel;
}

interface IState {
    activePageTab: string,
    activeAttRadio: string
}

interface ISmtpDomainInfo extends FaxConfigRestOrganizationSmtpDomainInfo {
    selected?: boolean;
}

interface IAttType extends FaxConfigRestHostAttType {
    selected?: boolean;
}

interface IBodyType extends FaxConfigRestHostBodyType {
    selected?: boolean;
}

@Component({
    selector: 'app-conn-channel',
    imports: [FormsModule, CommonModule, NewUserDefaultsModule],
    templateUrl: './conn-channel.component.html',
    styleUrl: './conn-channel.component.css'
})
export class ConnChannelComponent implements AfterViewInit {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(NewUserDefaultsComponent)
    newUser!: NewUserDefaultsComponent;

    isReady: boolean = false;
    isModifiable: boolean = false;
    scope: IChannelScope;

    hasAny?: FaxConfigRestHostAttType;
    pageTabs: string[] = ['SMTP Domains']; //, 'Attachments', 'Body Types', 'New User Defaults'];
    state: IState = {
        activePageTab: this.pageTabs[0],
        activeAttRadio: ''
    };

    SmtpDomains: ISmtpDomainInfo[] = [];
    NewSmtpDomains: ISmtpDomainInfo[] = [];
    SmtpSplit: boolean = false;

    BodyTypes: IAttType[] | null = null;
    AttTypes: IBodyType[] | null = null;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data as IChannelScope;
        this.isModifiable = auth.isModifiable('Connectors');
        if (this.scope.channel.SystemAddress) {
            this.pageTabs.splice(0, 0, 'Name');
            this.state.activePageTab = this.pageTabs[0];
        }
        this.session.rootPromises.subscribe(() => this.init() );
    }

    ngAfterViewInit(): void {
        this.newUser.init(this.scope.channel.NewUserDefaults?? {}, this.isModifiable);
    }

    init(): void {
        const chan: FaxConfigRestHostChannel = this.scope.channel;
        this.hasAny = _.findWhere(this.scope.hostGeneral.AttTypes?? [], {InternalName: '*'});
        this.state.activeAttRadio = (!chan.AttTypes || _.findWhere(chan.AttTypes, {InternalName: '*'}))? 'any': 'specify';
        this.AttTypes = _.map(
            _.reject(this.scope.hostGeneral.AttTypes?? [], x => { return x.InternalName == '*'; }),
            x => {
                return {
                    InternalName: x.InternalName,
                    Description: x.Description,
                    selected: !chan.AttTypes || this.state.activeAttRadio == 'any' || this.contains_type(chan.AttTypes, x.InternalName)
                };
            }
        );
        if (this.AttTypes && this.AttTypes.length > 0) {
            this.pageTabs.push('Attachments');
        }
        this.BodyTypes = _.map(this.scope.hostGeneral.BodyTypes?? [], x => {
            return {
                InternalName: x.InternalName,
                Description: x.Description,
                selected: !chan.BodyTypes || this.contains_type(chan.BodyTypes, x.InternalName)
            };
        });
        if (this.BodyTypes && this.BodyTypes.length > 0) {
            this.pageTabs.push('Body Types');
        }
        if (this.scope.hostGeneral.AutoUserCreation) {
            this.pageTabs.push('New User Defaults');
        }
        if (chan.SmtpDomains) {
            this.SmtpDomains = _.map(chan.SmtpDomains, x => {
                return { Name: x, selected: false };
            });
        }
        this.NewSmtpDomains = [];
        this.SmtpSplit = (chan.SmtpSplit === true);
        this.isReady = true;
    }

    contains_type(lst: (IAttType | IBodyType)[], typ?: string | null): boolean {
        return typ? _.some(lst, x => { return (x.InternalName === typ); }): false;
    }

    is_Any_selected(): boolean {
        return (this.hasAny? this.state.activeAttRadio === 'any': false);
    }

    hasAnySmtpDomain(): boolean {
        let doms: (string | null | undefined)[] = [];
        if (this.SmtpDomains !== null) {
            doms = _.pluck(this.SmtpDomains, "Name").concat( _.pluck(this.NewSmtpDomains, "Name"));
        } else if (this.NewSmtpDomains.length > 0) {
            doms = _.pluck(this.NewSmtpDomains, "Name");
        }
        return _.any(doms, x => { return x === '*'; });
    }

    domain_add(): void {
        if (!this.isSmtpDomainsInvalid()) {
            this.NewSmtpDomains.push({ Name: '' });
        }
    }

    domain_remove(): void {
        this.SmtpDomains = _.reject(this.SmtpDomains, x => { return x.selected === true; });
    }

    isDomainValid(dom: string | null | undefined): boolean {
        let i;
        const forbid = "!@#$%^&*()+=|\\[]{}:;'\",<>/?";
        if (dom) {
            if (dom === "*") { return true; }
            if (dom.length < 3) { return false; }
            i = dom.indexOf(".");
            if (i < 1 || i >= (dom.length - 1)) { return false; }
            for (i = 0; i < forbid.length; i++) {
                if (dom.indexOf(forbid.charAt(i)) >= 0) { return false; }
            }
            return true;
        } 
        return false;
    }

    isSmtpDomainsInvalid(): boolean {
        return _.any(_.pluck(this.NewSmtpDomains, "Name"), x => {
            return !this.isDomainValid(x);
        });
    }

    isInvalid(): boolean {
        if (!this.isReady) { return true; }
        if (this.AttTypes && this.AttTypes.length > 0) {
            if (this.BodyTypes && this.BodyTypes.length > 0) {
                if (this.fenUtils.nothingSelected(this.AttTypes) && this.fenUtils.nothingSelected(this.BodyTypes)) return true;
            } else {
                if (this.fenUtils.nothingSelected(this.AttTypes)) return true;
            }
        } else if (this.BodyTypes && this.BodyTypes.length > 0) {
            if (this.fenUtils.nothingSelected(this.BodyTypes)) return true;
        }
        if (this.isSmtpDomainsInvalid()) { return true; }
        if (this.newUser?.isValid() === false) { return true; }
        return false;
    }

    save(): void {
        let user: FaxConfigRestUser;
        let chan: FaxConfigRestHostChannel = {};

        if (this.scope.channel.FriendlyName !== null) {
            chan.FriendlyName = this.scope.channel.FriendlyName;
        }
        if (this.is_Any_selected()) {
            chan.AttTypes = [this.hasAny!];
        } else {
            chan.AttTypes = _.map(_.filter(this.AttTypes?? [], x => { return x.selected === true; }), x => {
                return {
                    InternalName: x.InternalName,
                    Description: x.Description
                };
            });
        }
        chan.BodyTypes = _.map(_.filter(this.BodyTypes?? [], x => { return x.selected === true; }), x => {
            return {
                InternalName: x.InternalName,
                Description: x.Description
            };
        });

        if (this.SmtpDomains !== null) {
            chan.SmtpDomains = _.pluck(this.SmtpDomains, "Name").concat( _.pluck(this.NewSmtpDomains, "Name")) as string[];
        } else if (this.NewSmtpDomains.length > 0) {
            chan.SmtpDomains = _.pluck(this.NewSmtpDomains, "Name") as string[];
        }

        user = this.newUser?.getEditee();
        if (user) { chan.NewUserDefaults = user; }
        chan.UseCustomUserDefaults = this.scope.channel.UseCustomUserDefaults;

        this.faxSrv.UpdateConnHostChannel(this.scope.MailSystem, this.scope.channel.SystemAddress?? '', chan).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
