import { Component, Inject, inject } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestDataSourceDef, FaxConfigRestUser, FaxConfigRestOrganizationDef,
    ProxyAddressItem, RoutingItem, FaxConfigRestHostMailSystemList, FaxConfigRestHostMailSystemItem,
    FaxConfigRestUsersViewOptions, FaxConfigRestUserRouteTypeList, FaxConfigRestAddressList, FaxConfigRestConnectionInfoList,
    FaxConfigRestECRFaxFormatList, FaxConfigRestNumSubstCountryList, FaxConfigRestOrganizationsTimeZoneList,
    FaxConfigRestStringList, FaxConfigRestKernelMailTemplates, FaxConfigRestNumSubstCountryItem, FaxConfigRestOrganizationsTimeZone,
    FaxConfigRestAddressBatchDef, FaxConfigRestConnectionInfo
} from '../api/api';
import { CFenLimits, IPeriodUnit, IPeriodLimits } from '../utils/utl';
import * as _ from 'underscore';

export interface IUsersEditEditee {
    dataSourceName: string;
    userId?: string | null;
    userName?: string | null;
}

interface IUsersEditTab {
    Caption: string;
    showTab: boolean;
    isReady: boolean;
}

interface IUser extends FaxConfigRestUser {
    MailSystemItem?: FaxConfigRestHostMailSystemItem;
    AddressAlias?: string;
    AddressDomain?: string;
    hasDirAll?: boolean;
    hasDirDef?: boolean;
}

interface IOrganization extends FaxConfigRestOrganizationDef {
    FaxNumbers?: FaxConfigRestAddressList;
    DirNumbers?: FaxConfigRestAddressList;
    SmsNumbers?: FaxConfigRestAddressList;
    coversheets?: string[];
    smsTemplates?: string[];
}

interface IRouting {
    type: string;
    code: string;
    isPrimary: boolean;
    newAddress?: INewAddress;
    selected?: boolean;
}

interface INewRouting {
    type: string | null;
    code: string | null;
    isPrimary: boolean;
    new_code: string;
    new_provider?: FaxConfigRestConnectionInfo;
    ErrorMessage: string;
}

interface INewAddress {
    type: string;
    address: string;
    connection: FaxConfigRestConnectionInfo;
}

interface IProxy {
    MailSystem: string;
    Address: string;
    AddrType: string;
    isPrimary: boolean;
    selected?: boolean;
}

interface INewProxy {
    AddrType: string;
    Address: string;
    MailSystem: FaxConfigRestHostMailSystemItem;
    isPrimary: boolean;
    ErrorMessage: string;
}

@Component({
    selector: 'app-users-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './users-edit.component.html',
    styleUrl: './users-edit.component.css'
})
export class UsersEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    public fenLimits: CFenLimits = new CFenLimits();

    editee: IUsersEditEditee;
    isModifiableUsers: boolean = false;
    isModifiableAddresses: boolean = false;
    modified: boolean = false;

    periodUnits: IPeriodUnit[] = this.fenLimits.periodUnits;
    allReady: boolean = false;
    isNew: boolean;

    pageTabs: IUsersEditTab[] = [
        { Caption: 'General',         showTab: true,  isReady: false },
        { Caption: 'Routing',         showTab: true,  isReady: false },
        { Caption: 'Proxy Addresses', showTab: true,  isReady: false },
        { Caption: 'FAX',             showTab: false, isReady: false },
        { Caption: 'SMS',             showTab: false, isReady: false },
        { Caption: 'Miscellaneous',   showTab: true,  isReady: false }
    ];
    state = { activePageTab: this.pageTabs[0] };

    msgTypes: string[] = [];
    routing: IRouting[] = [];
    proxyAddress: IProxy[] = [];
    newRoute: INewRouting | undefined = undefined;
    backupRoutes: IRouting[] | undefined = undefined;
    newProxy: INewProxy | undefined = undefined;
    selectedProxy: IProxy | undefined = undefined;
    backupProxys: IProxy[] | undefined = undefined;
    faxFormats: FaxConfigRestECRFaxFormatList = [];

    dataSource: FaxConfigRestDataSourceDef = {};
    user: IUser = {};
    organization: IOrganization = {};
    isLdapUser: boolean = false;
    messageTypes: FaxConfigRestStringList = [];
    mailTemplates: FaxConfigRestKernelMailTemplates = [];
    userMailSystems: FaxConfigRestHostMailSystemList = [];
    userRoutingTypes: FaxConfigRestUserRouteTypeList = [];
    userViewOptions: FaxConfigRestUsersViewOptions = {};
    Connections: FaxConfigRestConnectionInfoList = [];
    numSubstCountries: FaxConfigRestNumSubstCountryList = [];
    orgTimeZones: FaxConfigRestOrganizationsTimeZoneList = [];
    limits?: IPeriodLimits;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as IUsersEditEditee;
        this.isNew = !this.editee.userId;
        this.session.rootPromises.subscribe(() => {
            this.isModifiableUsers = auth.isModifiable('Users');
            this.isModifiableAddresses = auth.isModifiable('Addresses');
            this.msgTypes = this.session.messageTypes?? [];
            this.pageTabs[3].showTab = this.isValidMsgType('FAX');
            this.pageTabs[4].showTab = this.isValidMsgType('SMS');
            this.initUser();
        });
    }

    visiblePageTabs(): IUsersEditTab[] {
        return this.pageTabs.filter(tab => { return tab.showTab; });
    }

    private initUser(): void {
        var afterPromise = () => {
            this.pageTabs[0].isReady = true;
            this.routing = this.routingModel(this.user);
            this.proxyAddress = this.proxyModel(this.user);
            this.limits = this.fenLimits.limits(this.user);
            this.faxSrv.GetConnHosts({ FilterOnContracts: true }).subscribe(systems => {
                this.userMailSystems = systems;
                this.user.MailSystemItem = _.findWhere(this.userMailSystems, { FriendlyName: this.user.MailSystem });
                this.initTabs();
            });
        };

        if (this.isNew) {
            // We need to resolve the DataSource now to retrieve custom user defaults.
            this.faxSrv.dataSource(this.editee.dataSourceName).subscribe((source: FaxConfigRestDataSourceDef) => {
                this.dataSource = source;
                this.isLdapUser = this.faxSrv.isLdapProvider(source.ProviderName);
                this.user = this.dataSource.CustomUserDefaults?? {};
                this.user.MailSystem = 'SMTP Stack';
                this.user.AddressType = 'email address';
                afterPromise();
            });
        } else {
            // Resolve the User information now; the DataSource can wait until later.
            this.faxSrv.dataSourceUser(this.editee.dataSourceName, this.editee.userId!).subscribe((user: FaxConfigRestUser) => {
                this.user = user;
                // Favor displayable address, in case of escaping, DisplayAddress will have this decoded.
                this.user.Address = user.DisplayAddress? user.DisplayAddress: user.Address?? '';

                if (this.session.isMultiTenant()) {
                    let pos = this.user.Address.lastIndexOf('@');
                    if (pos > 0) {
                        this.user.AddressAlias  = this.user.Address.substring(0, pos);
                        this.user.AddressDomain = this.user.Address.substring(pos + 1);
                    } else {
                        this.user.AddressAlias  = this.user.Address;
                        this.user.AddressDomain = '';
                    }
                }
                afterPromise();
            });
        }
    }

    private isValidMsgType(name: string): boolean {
        return _.contains(this.msgTypes, name);
    }

    private initTabs(): void {
        let promises: Observable<void>[] = [];
        let orgId: number = (this.isNew? this.session.currentOrgId: this.user.OrganizationId)?? 0;

        promises.push(this.faxSrv.kernelMailTemplateDefinitions(orgId).pipe(map(res => {
            this.mailTemplates = _.map(res, a => { return a.toUpperCase(); });
        })));
        promises.push(this.faxSrv.userRouteTypes().pipe(map(res => {
            if (this.session.isMultiTenant()) {
                // In multi-tenant mode, only FAX, DIR, and SMS are supported (see WrapUsers.ValidateUserForOrganization)
                this.userRoutingTypes = _.filter(res, item => {
                    return item === 'FAX' || item === 'DIR' || item === 'SMS';
                });
            } else {
                this.userRoutingTypes = res;
            }
        })));
        promises.push(this.faxSrv.numSubstCountries().pipe(map(res => {
            this.numSubstCountries = res;
        })));
        promises.push(this.faxSrv.GetOrganizationsTimeZones().pipe(map(res => {
            this.orgTimeZones = res;
        })));
        promises.push(this.faxSrv.userViewOptions().pipe(map(res => {
            this.userViewOptions = res;
        })));

        if (!this.isNew) {
            // In this case we still need to retrieve the DataSource provider.
            promises.push(this.faxSrv.dataSource(this.editee.dataSourceName).pipe(map(res => {
                this.dataSource = res;
                this.isLdapUser = this.faxSrv.isLdapProvider(this.dataSource.ProviderName);
            })));
        }

        if (!this.session.isMultiTenant() && this.session.usePhonenumberAdministration() && this.isModifiableAddresses){
            // Only accounts with the permission to manage addresses need the connection list
            // Accounts without this permission only can assign existing addresses
            promises.push(this.faxSrv.GetConnections({IncludeGlobal: true}).pipe(map(res => {
                this.Connections = res;
            })));
        }

        forkJoin(promises).subscribe({
            next: () => {
                _.each(this.pageTabs, tab => { tab.isReady = tab.showTab; });
                this.initOrganization(orgId);
            },
            error: err => {
                console.error(err.message);
            }
        });
    }

    private initOrganization(orgId: number): void {
        let promises: Observable<void>[] = [];

        promises.push(this.faxSrv.GetOrganizationDefinition(orgId).pipe(map(res => {
            this.organization.SmtpDomains = _.map(res.SmtpDomains?? [], a => { return a.toUpperCase() });
            this.user.OrganizationId      = res.ID;
            this.user.OrganizationName    = res.Name;
            this.user.AddressDomain       = this.user.AddressDomain || this.organization.SmtpDomains[0] || '';
            this.initDefaultSelections(res);
        })));

        if (_.contains(this.msgTypes, 'FAX')) {
            // These are only used on the Fax tab
            promises.push(this.faxSrv.kernelTemplateDefinitions(orgId, 'Fax').pipe(map(res => {
                this.organization.coversheets = _.map(res, a => { return a.toUpperCase(); });
            })));
            promises.push(this.faxSrv.ecrFaxFormats().pipe(map(res => {
                this.faxFormats = res;
            })));
        }

        if (_.contains(this.msgTypes, 'SMS')) {
            // These are only used on the SMS tab
            promises.push(this.faxSrv.kernelTemplateDefinitions(orgId, 'SMS').pipe(map(res => {
                this.organization.smsTemplates = _.map(res, a => { return a.toUpperCase(); });
            })));
        }

        if (this.session.usePhonenumberAdministration() && this.auth.isViewable('Addresses')) {
            // These are only used on the Routing tab
            if (_.contains(this.userRoutingTypes, 'FAX')) {
                promises.push(this.faxSrv.GetAddresses(0, 10000, { OrganizationId: orgId, AddrType: 'FAX', IncludeAttached: true, IncludeUnattached: false }).pipe(map(res => {
                    this.organization.FaxNumbers = res;
                })));
            }
            if (_.contains(this.userRoutingTypes, 'DIR')) {
                promises.push(this.faxSrv.GetAddresses(0, 10000, { OrganizationId: orgId, AddrType: 'DIR', IncludeAttached: true, IncludeUnattached: false }).pipe(map(res => {
                    this.organization.DirNumbers = res;
                })));
            }
            if (_.contains(this.userRoutingTypes, 'SMS')) {
                promises.push(this.faxSrv.GetAddresses(0, 10000, { OrganizationId: orgId, AddrType: 'SMS', IncludeAttached: true, IncludeUnattached: false }).pipe(map(res => {
                    this.organization.SmsNumbers = res;
                })));
            }
        }

        forkJoin(promises).subscribe({
            next: () => {
                this.allReady = true;
            },
            error: err => {
                console.error(err.message);
            }
        });
    }

    private initDefaultSelections(org: FaxConfigRestOrganizationDef): void {
        // NumSubst already fills in the default entry, but let the org default overrule if present.
        let name: string = '';

        if (org.General && org.General.CountryISO2) {
            let found: FaxConfigRestNumSubstCountryItem | undefined;
            found = _.findWhere(this.numSubstCountries, { ISO2: org.General.CountryISO2 });
            if (found) this.numSubstCountries[0].Name = '<DEFAULT: ' + found.Name + '>';
        }

        if (org.General && org.General.TimeZone) {
            let found: FaxConfigRestOrganizationsTimeZone | undefined;
            found =_.findWhere(this.orgTimeZones, { Id: org.General.TimeZone });
            if (found) { name = found.DisplayName?? ''; }
        }
        name = name ? (': ' + name) : '';
        this.orgTimeZones.splice(0, 0, { Id: '', DisplayName: '<DEFAULT' + name + '>' });
    }

    /* Flattens routes. Convert user routes to the format suitable for the UI. */
    private routingModel(user: IUser): IRouting[] {
        let routing: IRouting[] = [];
        _.each(user.Routing?? [], r => {
            if (r.Type) {
                if (r.Primary) {
                    routing.push({ type: r.Type, isPrimary: true, code: r.Primary });
                    _.each(r.Secondary?? [], code => {
                        if (code) {
                            routing.push({ type: r.Type!, isPrimary: false, code: code });
                        }
                    });
                }
            }
        });

        /* Move DIR=ALL/DEFAULT from the list into checkboxes */
        user.hasDirAll = _.find(routing, r => { return (r.type==='DIR' && r.code==='ALL'); })? true : false;
        user.hasDirDef = _.find(routing, r => { return (r.type==='DIR' && r.code==='DEFAULT'); })? true : false;
        routing = _.reject(routing, r => {
            return (r.type==='DIR' && (r.code==='ALL' || r.code==='DEFAULT'));
        });
        return routing;
    }

    /* Convert routing model back to the form suitable for FaxConfig api. */
    private userRouting(): RoutingItem[] {
        // userRoutes[Type] -> {Type: string, ...}
        // Init empty. If all codes for some routing type were deleted,
        // need to send an empty routing object to indicate the deletion,
        // This means that the 'Primary' field must not be present at all,
        // and the 'Secondary' field must be an empty list or not present,
        // i.e. {Type: 'FAX'} or {Type: 'FAX', Secondary: []}
        let userRoutes = _.reduce(this.user.Routing?? [],
                                (accu: { [key: string]: RoutingItem }, r) => {
                                    accu[r.Type!] = { Type: r.Type, Secondary: [] };
                                    return accu;
                                }, {});

        /* Add special DIR=ALL and DIR=DEFAULT routes if selected */
        var addSpecial = (code: string): void => {
            let userRoute: RoutingItem = userRoutes['DIR'];
            if (!userRoute) {
                userRoute = { Type: 'DIR', Secondary: [] };
                userRoutes['DIR'] = userRoute;
            }
            if (userRoute.Primary) {
                userRoute.Secondary!.push(code);
            } else {
                userRoute.Primary = code;
            }
        };
        if (this.user.hasDirAll) addSpecial('ALL');
        if (this.user.hasDirDef) addSpecial('DEFAULT');

        // Convert to FaxConfig API compatible form
        _.each(this.routing, route => {
            let userRoute: RoutingItem = userRoutes[route.type];
            if (!userRoute) {
                userRoute = { Type: route.type!, Secondary: [] };
                userRoutes[route.type!] = userRoute;
            }
            if (route.isPrimary) {
                userRoute.Primary = route.code;
            } else {
                userRoute.Secondary!.push(route.code);
            }
        });

        return _.values(userRoutes);
    }

    /* Flattens proxy addresses. Convert to the format suitable for the UI. */
    private proxyModel(user: IUser): IProxy[] {
        let proxy: IProxy[] = [];
        if (user.ProxyAddress) {
            _.each(user.ProxyAddress, r => {
                if (r.MailSystem && r.AddrType) {
                    let primary = r.DisplayPrimary?? r.Primary;
                    if (primary) {
                        proxy.push({ MailSystem: r.MailSystem, AddrType: r.AddrType, isPrimary: true, Address: primary});
                    }
                    let secondary = r.DisplaySecondary?? r.Secondary;
                    if (secondary) {
                        _.each(secondary, addr => {
                            proxy.push({ MailSystem: r.MailSystem!, AddrType: r.AddrType!, isPrimary: false, Address: addr });
                        });
                    }
                }
            });
        }
        return proxy;
    }

    /* Convert proxy address model back to the form suitable for FaxConfig api. */
    private userProxyAddress(): {}[] {
        let userProxys = _.reduce(this.user.ProxyAddress?? [],
                                (accu: { [key: string]: {} }, r) => {
                                    accu[r.MailSystem! + r.AddrType!] = {
                                        MailSystem: r.MailSystem, AddrType: r.AddrType, Secondary: []
                                    };
                                    return accu;
                                }, {});
        _.each(this.proxyAddress, (proxy: IProxy) => {
            let userProxy: ProxyAddressItem = userProxys[proxy.MailSystem + proxy.AddrType];
            if (proxy.isPrimary) {
                userProxy.Primary = proxy.Address;
            } else {
                userProxy.Secondary!.push(proxy.Address);
            }
        });
        if (this.isValidNewProxy()) {
            const proxy = this.newProxy!;
            let userProxy: ProxyAddressItem = userProxys[proxy.MailSystem.FriendlyName + proxy.AddrType];
            if (userProxy) {
                if (proxy.isPrimary) {  // updated primary code for the existing MailSystem+AddrType
                    userProxy.Primary = proxy.Address.trim();
                } else {                // new secondary code for the existing MailSystem+AddrType
                    userProxy.Secondary!.push(proxy.Address.trim());
                }
            } else { // new MailSytem+AddrType
                userProxys[proxy.MailSystem.FriendlyName + proxy.AddrType] = {
                    MailSystem: proxy.MailSystem.FriendlyName,
                    AddrType: proxy.AddrType,
                    Primary: proxy.Address.trim(),
                    Secondary: []
                };
            }
        }
        return _.values(userProxys);
    }

    useAddressAliasAndDomain(): boolean {
        return (this.session.isMultiTenant() && this.user && this.user.AddressType === 'email address');
    }

    isValid(): boolean {
        return (this.user && this.userViewOptions
            && this.user.Name && this.user.MailSystemItem && this.user.AddressType 
            && (this.useAddressAliasAndDomain() || this.user.Address)
            && (!this.useAddressAliasAndDomain() || (this.user.AddressAlias && this.user.AddressDomain) )
            && this.fenLimits.areLimitsValid(this.limits)
            && (!this.userViewOptions.ChargeCodeRequired || this.user.ChargeCode) 
            && !this.newRoute && !this.newProxy)? true: false;
    }

    onMailSystemChanged(): void {
        if (this.user && this.user.MailSystemItem && this.user.MailSystemItem.AddrTypes) {
            let addrType: string | undefined;
            // default to 'email address' for the SMTP Stack
            if (this.user.MailSystemItem.MailSystem === 'SMTP') {
                addrType = _.find(this.user.MailSystemItem.AddrTypes, item => { return item === 'email address'; });
            }
            this.user.AddressType = addrType?? this.user.MailSystemItem.AddrTypes[0]?? '';
        }
    }

    private hasInvalidChars(name: string): boolean {
        return (
            name.indexOf('?') >= 0 ||
            name.indexOf('\\') >= 0 ||
            name.indexOf('/') >= 0
        );
    }

    private isInvalidAlias(name: string): boolean {
        return (name.indexOf('@') >= 0 || this.hasInvalidChars(name));
    }

    save(): void {
        let addressPromises: Observable<FaxConfigRestResult>[] = [];
        let user = _.omit(this.user, 'MailSystemItem');
        if (this.useAddressAliasAndDomain()) {
            if (this.user.MailSystemItem?.MailSystem !== 'INBOUND_DIRECTORY')
            {
                if (this.isInvalidAlias(this.user.AddressAlias?? '')) {
                    alert('The address alias cannot contain the characters \"@\", \"?\", \"\\\", or \"/\".');
                    return;
                }
            } else {
                // In case of Inbound directory a path will be filled in,
                // Due to the use of \ the path must be in quotes "<path>",
                // and <space> + \ (backslash)  must be escaped (prefixed with a backslash).
                // UI will show the actual path, backend will apply the rules outlined.
            }
            user.Address = this.user.AddressAlias + '@' + this.user.AddressDomain;
        }
        user.MailSystem = this.user.MailSystemItem!.FriendlyName;

        // Convert Routes and Proxy Addresses to FaxConfig API compatible form
        user.Routing = this.userRouting();
        user.ProxyAddress = this.userProxyAddress();
        this.fenLimits.setLimits(user, this.limits!);

        // Force International to be disabled when saving settings with a msgtype disabled.
        // Also we need to clone the Fax/SMS/MMS objects in order to detach them from the UI.
        if (user.Fax?.AllowToSendFax === false) {
            user.Fax = _.extend(_.clone(user.Fax), { AllowToSendFaxInt: false });
        }
        if (user.SMS?.AllowToSendSMS === false) {
            user.SMS = _.extend(_.clone(user.SMS), { AllowToSendSMSInt: false });
        }
        if (user.MMS?.AllowToSendMMS === false) {
            user.MMS = _.extend(_.clone(user.MMS), { AllowToSendMMSInt: false });
        }

        // Add new routes via batch so it will silently ignore if number already exists
        _.each(this.routing, r => {
            const item = r.newAddress;
            if (item) {
                let batch: FaxConfigRestAddressBatchDef = {
                    AddrType: item.type,
                    AddressStart: item.address,
                    AddressEnd: item.address,
                    Enabled: true,
                    ConnectionId: item.connection.ID,
                    Description: '',
                    OrganizationId: this.session.currentOrgId
                };
                addressPromises.push( this.faxSrv.PostAddressesBatch(batch) );
            }
        });

        var afterAddressSave = (): void => {
            let savePromise: Observable<FaxConfigRestResult>;
            if (this.isNew) {
                savePromise = this.faxSrv.createDataSourceUser(this.editee.dataSourceName, user);
            } else {
                savePromise = this.faxSrv.updateDataSourceUser(this.editee.dataSourceName, this.editee.userId!, user);
            }
            savePromise.subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.modified = true;
                        this.close();
                    }
                },
                error: err => {
                    console.error(err.message);
                }
            });
        };

        if (addressPromises.length > 0) {
            forkJoin(addressPromises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        afterAddressSave();
                    }
                },
                error: err => {
                    console.error(err.message);
                }
            });
        } else {
            afterAddressSave();
        }
    }

    addNewRoute(): void {
        this.newRoute = {
            type: null,
            code: null,
            new_code: '',
            isPrimary: false,
            ErrorMessage: ''
        };
        this.routeTypeChanged(this.newRoute);
    }

    routeTypeChanged(route: INewRouting): void {
        // For a new route, or an existing route that has its type changed,
        // it will become primary only if no other routes of its type exist.
        route.isPrimary = _.find(this.routing, p => {
            return (p.type === route.type);
        })? false: true;
        // Erase new number information if the selected routing type does not allow it.
        if (route.code === '_new_' && route.type !== 'FAX' && route.type !== 'DIR' && route.type !== 'SMS') {
            route.code = '';
            route.new_code = '';
            route.new_provider = undefined;
        }
    }

    removeRoutes(): void {
        let routing: IRouting[] = this.routing;
        let removedPrimaries = _.reduce(routing, (accu: { [key: string]: boolean }, route: IRouting) => {
                if (route.isPrimary) {
                    accu[route.type] = true;
                }
                return accu;
            }, { });
        routing = _.reject(routing, route => { return route.selected?? false; }); // remove selected
        // Choose a new primary code if necessary
        _.each(removedPrimaries, (_true, type) => {
            let route = _.findWhere(routing, { type: type }); // first secondary code
            if (route) {
                route.isPrimary = true;
            }
        });
        this.routing = routing;
    }

    setPrimaryRoute(): void {
        let routing: IRouting[] = this.routing;
        let route = _.findWhere(routing, { selected: true }); // there must be only one selected
        if (route) {
            let currentPrimary = _.findWhere(routing, { type: route.type, isPrimary: true });
            if (currentPrimary) {
                currentPrimary.isPrimary = false;
            }
            route.isPrimary = true;
            route.selected = false;
        }
    }

    editRoute(): void {
        let route = _.findWhere(this.routing, { selected: true }); // there must be only one selected
        if (route) {
            // Make a backup of the current routes in case the user cancels the edit.
            this.backupRoutes = _.map(this.routing, item => { return _.clone(item); });
            this.routing = _.without(this.routing, route);
            this.newRoute = {
                type: route.type,
                code: route.newAddress? '_new_': route.code,
                new_code: route.newAddress? route.newAddress.address: '',
                new_provider: route.newAddress?.connection,
                isPrimary: route.isPrimary,
                ErrorMessage: ''
            };
        }
    }

    mergeRoute(): void {
        if (this.backupRoutes) {
            // We only arrive here if we are editing an existing route; find it:
            let selectedRoute = _.find(this.backupRoutes, r => { return r.selected === true; });
            if (selectedRoute?.isPrimary) {
                if (this.newRoute!.type !== selectedRoute.type) {
                    // selectedRoute was a primary but we are moving it to another routing type;
                    // in this case we need to promote a new primary for the previous routing type.
                    let promote = _.find(this.routing, r => {
                        return (r.type === selectedRoute!.type && !r.isPrimary);
                    });
                    if (promote) promote.isPrimary = true;
                }
            }
        }

        // Add the new route
        const route = this.newRoute!;
        const isNewAddr = (route.code === '_new_');
        // Trim leading and trailing whitespace from all routes except CSID and SCAN
        if (route.type !== 'CSID' && route.type !== 'SCAN') {
            route.code = route.code!.trim();
            route.new_code = route.new_code.trim();
        }
        let new_route: IRouting = {
            type: route.type!,
            code: isNewAddr? route.new_code: route.code!,
            isPrimary: route.isPrimary
        };
        if (isNewAddr) {
            new_route.newAddress = {
                type: route.type!,
                address: route.new_code,
                connection: route.new_provider!
            };
        }
        this.routing.push(new_route);

        // Sort the list into groups by routing type
        let groups: { [key: string]: IRouting[] } = {};
        _.each(this.routing, r => {
            let codes: IRouting[] = groups[r.type];
            if (codes) {    // add to existing group
                codes.push(r);
            } else {        // make new group
                groups[r.type] = [r];
            }
        });
        let sorted: IRouting[] = [];
        _.each(_.values(groups), codes => { sorted.push(...codes); });

        this.routing = sorted;
        this.backupRoutes = undefined;
        this.newRoute = undefined;
    }

    cancelRoute(): void {
        if (this.backupRoutes) {
            this.routing = _.map(this.backupRoutes, item => { return _.omit(item, 'selected'); });
            this.backupRoutes = undefined;
        }
        this.newRoute = undefined;
    }

    routeSelection(): IRouting[] {
        return _.filter(this.routing, route => { return route.selected?? false; });
    }

    isValidNewRoute(): boolean {
        let route = this.newRoute;
        if (route) {
            let code: string;
            let codeOrig: string;
            route.ErrorMessage = '';    // no error yet
            if (!route.type || !route.code) {
                return false;
            }
            // Trim leading and trailing whitespace from all routes except CSID and SCAN.
            if (route.code === '_new_') {
                codeOrig = route.new_code;
                if (!codeOrig) {
                    return false;   // not an error
                }
            } else {
                // Note: we already know route.code is not empty.
                codeOrig = route.code;
            }
            if (route.type === 'CSID' || route.type === 'SCAN') {
                code = codeOrig;    // do not trim
            } else {
                code = codeOrig.trim();
                if (code !== codeOrig) {
                    // The route is invalid due to the surrounding whitespace.
                    route.ErrorMessage = 'The ' + route.type + ' route cannot begin or end with whitespace';
                    return false;
                }
            }
            if (route.code === '_new_') {
                // Single-tenant mode with Phone Number Administration enabled
                let regex: RegExp;
                if (route.type === 'FAX' || route.type === 'SMS') {
                    // These must conform to E.164.
                    regex = /^\+?([0-9] ?){5,15}$/;
                } else {
                    // CSID, DIR, FSR, MSISDN, SCAN, SR, XDC
                    // The batch API will only accept digits 0-9 in these address types.
                    regex = /^[0-9]+$/;
                }
                if (!code.match(regex)) {
                    route.ErrorMessage = 'Invalid ' + route.type + ' number';
                    return false;
                }
            } else {
                if (route.type === 'DIR' && (code.toUpperCase() === 'ALL' || code.toUpperCase() === 'DEFAULT')) {
                    route.ErrorMessage = 'The DIR code \'' + code + '\' is not allowed';
                    return false;
                }
            }
            if (this.isDuplicateNewRoute(route.type, code)) {
                route.ErrorMessage = 'The ' + route.type + ' route already exists';
                return false;
            }
            if (route.code === '_new_' && !route.new_provider) {
                return false;   // route is OK but provider has not been selected yet.
            }
            return true;
        }
        return false;
    }

    private isDuplicateNewRoute(routeType: string, routeCode: string): boolean {
        // Check whether the specified route already exists in this.routing.
        // In FEN_IRT, the 'SMS' routing type is considered case-sensitive.
        // In Active Directory, all routing types are considered case-insensitive.
        let found: IRouting | undefined;
        if (!this.isLdapUser && routeType === 'SMS') {
            found =_.find(this.routing, item => {
                if (item.type === routeType) {
                    if (item.newAddress) {
                        return item.newAddress.address === routeCode;
                    } else {
                        return item.code === routeCode;
                    }
                }
                return false;
            });
        } else {
            const codeUpper: string = routeCode.toUpperCase();
            found =_.find(this.routing, item => {
                if (item.type === routeType) {
                    if (item.newAddress) {
                        return item.newAddress.address.toUpperCase() === codeUpper;
                    } else {
                        return item.code.toUpperCase() === codeUpper;
                    }
                }
                return false;
            });
        }
        return found? true: false;
    }

    getNewRouteDescription(routeType?: string | null): string {
        // Single-tenant mode with Phone Number Administration enabled
        if (routeType) {
            if (routeType === 'FAX' || routeType === 'SMS') {
                return 'Enter the new ' + routeType + ' number in E.164 format, e.g. +19995550123';
            } else {
                // CSID, DIR, FSR, MSISDN, SCAN, SR, XDC
                return 'Enter the new ' + routeType + ' number (only digits 0-9 are allowed)';
            }
        }
        return '';
    }

    addNewProxy(): void {
        // Initialize with SMTP Stack / email address by default
        let ms = _.find(this.userMailSystems, item => {
            return item.MailSystem === 'SMTP';
        });
        let new_proxy: INewProxy = {
            MailSystem: ms?? this.userMailSystems[0],
            AddrType: '',
            Address: '',
            isPrimary: false,
            ErrorMessage: ''
        };
        this.proxyMailSystemChange(new_proxy);
        this.newProxy = new_proxy;
    }

    proxyAddrTypeChanged(proxy: INewProxy): void {
        // For a new proxy, or an existing proxy that has its MailSystem or AddrType changed,
        // it will become primary only if no other routes exist with the same Mailsystem+AddrType.
        proxy.isPrimary = _.find(this.proxyAddress, p => {
            return (p.MailSystem === proxy.MailSystem.FriendlyName &&
                p.AddrType === proxy.AddrType && p.isPrimary === true);
        })? false: true;
    }

    proxyMailSystemChange(proxy: INewProxy): void {
        if (proxy.MailSystem.AddrTypes) {
            proxy.AddrType = proxy.MailSystem.AddrTypes[0];
            if (proxy.MailSystem.MailSystem === 'SMTP') {
                // Prefer 'email address'
                if (_.contains(proxy.MailSystem.AddrTypes, 'email address')) {
                    proxy.AddrType = 'email address';
                }
            } else {
                // Prefer other than 'email address'
                let addrType = _.find(proxy.MailSystem.AddrTypes, x => { return x !== 'email address'; });
                if (addrType) {
                    proxy.AddrType = addrType;
                }
            }
            this.proxyAddrTypeChanged(proxy);
        }
    }

    removeProxys(): void {
        let proxyAddress: IProxy[] = this.proxyAddress;
        let removedPrimaries = _.reduce(proxyAddress, (accu: { [key: string]: boolean }, proxy: IProxy) => {
                if (proxy.isPrimary) {
                    accu[proxy.MailSystem + proxy.AddrType] = true;
                }
                return accu;
            }, { });
        proxyAddress = _.reject(proxyAddress, proxy => { return proxy.selected?? false; }); // remove selected
        // Choose a new primary address if necessary
        _.each(removedPrimaries, (_true, type) => {
            let proxy = _.find(proxyAddress, r => { return (r.MailSystem + r.AddrType) === type; });
            if (proxy) {
                proxy.isPrimary = true;
            }
        });
        this.proxyAddress = proxyAddress;
    }

    setPrimaryProxy(): void {
        let proxyAddress: IProxy[] = this.proxyAddress;
        let proxy = _.findWhere(proxyAddress, { selected: true }); // there must be only one selected
        if (proxy) {
            let currentPrimary = _.findWhere(proxyAddress, { MailSystem: proxy.MailSystem, AddrType: proxy.AddrType, isPrimary: true });
            if (currentPrimary) {
                currentPrimary.isPrimary = false;
                proxy.isPrimary = true;
                proxy.selected = false;
            }
        }
    }

    editProxy(): void {
        let proxy = _.findWhere(this.proxyAddress, { selected: true }); // there must be only one selected
        if (proxy) {
            // Make a backup of the current proxys in case the user cancels the edit.
            this.backupProxys = _.map(this.proxyAddress, item => { return _.clone(item); });
            this.proxyAddress = _.without(this.proxyAddress, proxy);
            this.newProxy = {
                AddrType: proxy.AddrType,
                Address: proxy.Address,
                isPrimary: proxy.isPrimary,
                MailSystem: _.findWhere(this.userMailSystems, { FriendlyName: proxy.MailSystem })!,
                ErrorMessage: ''
            };
        }
    }

    mergeProxy(): void {
        if (this.backupProxys) {
            // We only arrive here if we are editing an existing proxy; find it:
            let selectedProxy = _.find(this.backupProxys, p => { return p.selected === true; });
            if (selectedProxy?.isPrimary) {
                if (this.newProxy!.AddrType !== selectedProxy.AddrType ||
                    this.newProxy!.MailSystem.FriendlyName! !== selectedProxy.MailSystem)
                {
                    // selectedProxy was a primary but we are moving it to another MailSystem+AddrType;
                    // in this case we need to promote a new primary for the previous MailSystem+AddrType.
                    let promote = _.find(this.proxyAddress, p => {
                        return (p.MailSystem === selectedProxy!.MailSystem &&
                                p.AddrType === selectedProxy!.AddrType && !p.isPrimary);
                    });
                    if (promote) promote.isPrimary = true;
                }
            }
        }
        this.user.ProxyAddress = this.userProxyAddress();
        this.proxyAddress = this.proxyModel(this.user);
        this.backupProxys = undefined;
        this.newProxy = undefined;
    }

    cancelProxy(): void {
        if (this.backupProxys) {
            this.proxyAddress = _.map(this.backupProxys, item => { return _.omit(item, 'selected'); });
            this.backupProxys = undefined;
        }
        this.newProxy = undefined;
    }

    proxySelection(): IProxy[] {
        return _.filter(this.proxyAddress, addr => { return addr.selected?? false; });
    }

    isValidNewProxy(): boolean {
        let proxy = this.newProxy;
        if (proxy) {
            proxy.ErrorMessage = '';    // no error yet
            if (!proxy.MailSystem.FriendlyName || !proxy.AddrType || !proxy.Address) {
                return false;
            }
            // Trim leading and trailing whitespace from all proxy addresses.
            let addrTrimmed: string = proxy.Address.trim();
            if (addrTrimmed !== proxy.Address) {
                // The proxy address is invalid due to the surrounding whitespace.
                proxy.ErrorMessage = 'The ' + proxy.AddrType + ' cannot begin or end with whitespace';
                return false;
            }
            proxy.ErrorMessage = this.checkNewProxyAddress(proxy.AddrType, addrTrimmed);
            if (proxy.ErrorMessage) {
                return false;
            }
            if (this.isDuplicateNewProxy(proxy.MailSystem.FriendlyName, proxy.AddrType, addrTrimmed)) {
                proxy.ErrorMessage = 'The ' + proxy.AddrType + ' already exists';
                return false;
            }
            return true;
        }
        return false;
    }

    private checkNewProxyAddress(addrType: string, address: string): string {
        const EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

        if (addrType === 'email address') {
            if (!EMAIL_REGEXP.test(address)) {
                return 'Invalid email address';
            }
        }
        else if (addrType === 'Fenestrae SMTP address') {
            let bFoundSMTP = false;
            let parts = address.split(';');
            for (let i = 0; i < parts.length; i++) {
                let pp = parts[i].split('=');
                let key = (pp.length > 1) ? pp[0] : 'SMTP';
                let val = (pp.length > 1) ? pp[1] : pp[0];
                if (key == 'SMTP' && EMAIL_REGEXP.test(val)) {
                    bFoundSMTP = true;
                }
            }
            if (!bFoundSMTP) {
                return 'Invalid FSMTP address. example: SMTP=user@domain.com;SD=domain.com';
            }
        }
        else {
            // (addrType is other, no checks
        }
        return '';
    }

    private isDuplicateNewProxy(mailSystem: string, addrType: string, address: string): boolean {
        // Check whether the specified proxy already exists in this.proxyAddress
        // All proxy addresses are case-insensitive, because the 'SMS' address type only applies to routes.
        const addrUpper: string = address.toUpperCase();
        const found: IProxy | undefined = _.find(this.proxyAddress, item => {
            if (item.MailSystem === mailSystem && item.AddrType === addrType) {
                return (item.Address.toUpperCase() === addrUpper);
            }
            return false;
        });
        return found? true: false;
    }

    usesSystemAddress(mail_system?: string | null, address_type?: string | null): boolean {
        if (mail_system && address_type) {
            const scopes: { [key: string]: string[] } = {
                'REST': ['email address'] ,
                'INBOUND_DIRECTORY':  ['*'] ,
                'SAP_DOTNET': ['*'] ,
                'MFP_CONNECTOR' : ['*'] ,
            };
            if (scopes[mail_system] !== undefined) {
                return _.any(scopes[mail_system], item => { return item === '*' ||  item === address_type }) 
            }
        }
        return false;
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
