<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Configure Active Directory Server</h1>
    </div>
    <form id="group_setup_form" class="form" novalidate (submit)="submit()" #accountGroupSetupForm="ngForm">
        <div class="modal-body">
            <p>Enter the Active Directory server and/or domain that will be consulted to determine the group memberships of users,
            and whether the specified server is a Global Catalog.  The Server field can be left blank to use serverless binding.
            The Domain field should normally be left blank.</p><br>
            <div title="Select this option if the specified Server is a Global Catalog">
                <input type="radio" name="LDAPProvider" [(ngModel)]="editee.LDAPProvider" id="LDAPProvider_GC" value="GC">
                <label for="LDAPProvider_GC">Global Catalog</label>
            </div>
            <div title="Select this option if the specified Server is not a Global Catalog">
                <input type="radio" name="LDAPProvider" [(ngModel)]="editee.LDAPProvider" id="LDAPProvider_LDAP" value="LDAP">
                <label for="LDAPProvider_LDAP">Active Directory</label>
            </div>
            <br>
            <div class="field">
                <label>Server:</label>
                <input type="text" name="LDAPServer" [(ngModel)]="editee.LDAPServer"
                title="Enter the name of the server to use for LDAP queries (this can be blank for serverless binding)">
            </div>
            <div class="field">
                <label>Domain:</label>
                <input type="text" name="LDAPDomain" [(ngModel)]="editee.LDAPDomain"
                title="Enter the domain of the user for LDAP queries (normally this should be left blank)">
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!accountGroupSetupForm.dirty">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
