import { Component, Inject, inject } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult } from '../api/api';
import { isString } from 'underscore';

export interface IEditee {
    organizationId: number;
    overwrite: boolean;
}

@Component({
    selector: 'app-mail-templates-upload',
    imports: [FormsModule, CommonModule],
    templateUrl: './mail-templates-upload.component.html',
    styleUrl: './mail-templates-upload.component.css'
})
export class MailTemplatesUploadComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IEditee;
    uploadTitle: string = 'Upload Mail Templates';
    uploadFile: File | null = null;
    uploadPromise: Observable<string> | null = null;
    uploadEnabled: boolean = false;
    uploadActive: boolean = false;
    uploadFinished: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as IEditee;
    }

    filechange(event: Event) {
        let target: HTMLInputElement = event.target as HTMLInputElement;
        this.uploadFile = target.files? target.files[0]: null;
        if (this.uploadFile) {
            this.uploadEnabled = true;
            this.uploadPromise = this.fileRead(this.uploadFile);
        }
    }

    private fileRead(file: File): Observable<string> {
        let subj = new AsyncSubject<string>();
        let reader = new FileReader();
        reader.onload = () => { subj.next(reader.result as string); };
        reader.onerror = () => { subj.next(reader.error?.message?? 'An unexpected error occurred.'); };
        reader.onabort = () => { subj.next(''); };
        reader.onloadend = () => { subj.complete(); };
        reader.readAsDataURL(file);
        return subj;
    }

    doUpload(): Observable<FaxConfigRestResult | string> {
        let subj = new AsyncSubject<FaxConfigRestResult | string>();
        if (this.uploadPromise)
        {
            this.uploadPromise.subscribe({
                next: data => {
                    let def = {
                        Base64data: data.split('base64,')[1]
                    };
                    this.uploadActive = true;
                    this.uploadEnabled = false;
                    this.faxSrv.postMailTemplatesArchive(def, this.editee.organizationId, this.editee.overwrite).subscribe({
                        next: res => {
                            subj.next(res);
                            subj.complete();
                        },
                        error: res => {
                            subj.error(res);
                            subj.complete();
                        }
                    });
                },
                error: msg => {
                    subj.error(msg);
                    subj.complete();
                }
            });
        }
        else {
            subj.next('');
            subj.complete();
        }
        return subj;
    }

    submit(): void {
        this.doUpload().subscribe({
            next: res => this.afterSubmit(res),
            error: res => this.afterSubmit(res)
        });
    }

    private afterSubmit(res: FaxConfigRestResult | string) {
        this.uploadActive = false;
        if (isString(res)) {
            this.uploadEnabled = true;
            alert(res?? 'An unexpected error occurred.');
        } else {
            if (res.Status === 'ok') {
                this.uploadFinished = true;
                this.close();
            } else {
                this.fenUtils.afterSave(res);
                this.uploadEnabled = true;
            }
        }
    }

    close(): void {
        this.dialogRef.close(this.uploadFinished);
    }
}
