import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FaxConfigRestCSDigitalSignatures } from '../api/api';
import * as _ from 'underscore';

export interface ICoversheetDigitalSignatureEditee {
    DigitalSignatures: FaxConfigRestCSDigitalSignatures;
    digsig: string;
}

interface IDigitalSignature {
    Name: string;
    Style: object | null;
}

@Component({
    selector: 'app-coversheet-digitalsignature',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-digitalsignature.component.html',
    styleUrl: './coversheet-digitalsignature.component.css'
})
export class CoversheetDigitalsignatureComponent {

    lstDigSigs: IDigitalSignature[] = [{ Name: 'None', Style: null }];
    isReady: boolean = false;

    editee: ICoversheetDigitalSignatureEditee;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetDigitalSignatureEditee;
        this.init();
    }

    init(): void {
        if (this.editee.DigitalSignatures && this.editee.DigitalSignatures.length > 0) {
            this.lstDigSigs.push({ Name: 'Default', Style: null });
            _.each(this.editee.DigitalSignatures, sig => {
                this.lstDigSigs.push({ Name: sig, Style: null });
            });
        }
        if (this.editee.digsig) {
            let found = _.find(this.lstDigSigs, sig => {
                return (sig.Name.toUpperCase() === this.editee.digsig.toUpperCase());
            });
            if (found) {
                this.editee.digsig = found.Name;
            } else {
                this.lstDigSigs.push({
                    Name: this.editee.digsig,
                    Style: { 'color': 'red' }
                });
            }
        }
        this.isReady = true;
    }

    save(): void {
        this.dialogRef.close(true);
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
