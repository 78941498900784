<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Global New User Defaults</h1>
    </div>
    <div class="modal-body">
        <p *ngIf="isModifiable">Specify the defaults to use when a new Faxination user is created.</p>
        <p *ngIf="!isModifiable">View the defaults that are used when a new Faxination user is created.</p>
        <app-new-user-defaults *ngIf="isReady"></app-new-user-defaults>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" *ngIf="isModifiable" (click)="save()" [disabled]="!isValid()">Save</button>
        <button type="button" class="btn" (click)="close()">Close</button>
    </div>
</section>
