<div *ngIf="isSearching">
    <h3>Searching...</h3>
</div>
<div *ngIf="!isSearching">
    <form role="form" (submit)="search()">
        <div class="form-group has-feedback pull-right">
            <i class="form-control-feedback glyphicon glyphicon-search" (click)="search()"></i>&ngsp;
            <input type="text" name="newKeyword" class="form-control" [(ngModel)]="newkeyword" placeholder="Search" />
            &ngsp;
            <select name="searchFilter" [(ngModel)]="searchFilter" title="Search for">
                <option *ngFor="let filter of searchFilters" [value]="filter">{{ filter }}</option>
            </select>
        </div>
    </form>
    <h3>Search results for '{{ keyword }}'</h3>
</div>

<div [hidden]="isSearching">
    <div class="btn-toolbar">
        <button type="button" class="btn" *ngIf="isModifiableUsers && !faxSrv.isLdapProvider(providerName)" (click)="neo()" [disabled]="session.contextAllOrganizations()">New</button>
        <button type="button" class="btn" *ngIf="isModifiableUsers && !faxSrv.isLdapProvider(providerName)" (click)="remove()" [disabled]="!removeEnabled">Remove</button>
        <button type="button" class="btn btn-link" *ngIf="searchResults && searchResults.length > 0" (click)="export_search()" title="Export the results into a .csv formatted file">Export</button>
    </div>
    <a id="hidden_csv_link" href="" style="visibility:hidden" download="export_users.csv"></a>

    <div id="list_content">
        <div *ngIf="gridInitialized" id="searchResultsGrid" class="ag-theme-alpine">
            <ag-grid-angular [style]="cssGridHeight" [gridOptions]="gridOptions"></ag-grid-angular>
        </div>
    </div>
</div>
