<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ headerText }}</h1>
    </div>

    <form id="organization_edit_form" class="form" novalidate (submit)="submit()" #organizationEditForm="ngForm">
        <div class="modal-body" *ngIf="isReady">
            <ul class="nav nav-tabs">
                <li *ngFor=" let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="state.activePageTab=pageTab;false">{{ pageTab }}</a>
                </li>
            </ul>

            <div [hidden]="state.activePageTab !== 'General'">
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </div>
            <div *ngIf="!ID" [hidden]="state.activePageTab !== 'Administrator'">
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </div>
            <div [hidden]="state.activePageTab !== 'Contacts'">
                <ng-container *ngTemplateOutlet="page3"></ng-container>
            </div>
            <div *ngIf="ID" [hidden]="state.activePageTab !== 'Logging'">
                <ng-container *ngTemplateOutlet="page4"></ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="auth.isModifiable('Organizations')" [disabled]="!isReady || !organizationEditForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>

<!-- General tab -->
<ng-template #page1>
    <div class="field">
        <label>Enabled:</label>
        <input type="checkbox" name="orgEnabled" [(ngModel)]="editee.Enabled" [disabled]="!auth.isModifiable('Organizations')" >
    </div>
    <div class="field">
        <label>Name:&nbsp;*</label>
        <input type="text" #orgName="ngModel" name="orgName" [(ngModel)]="editee.Name" maxlength="100" required size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter the name for the organization. Must not be already used by another organization.">&ngsp;
		<span class="text-danger" *ngIf="orgName.dirty && orgName.errors?.['required']">&nbsp;A name is required</span>
    </div>
    <div class="field">
        <label>Description:</label>
        <input type="text" name="orgDescription" [(ngModel)]="editee.Description" maxlength="500" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional Description of the organization">
    </div>
    <div class="field" *ngIf="editee.ID">
        <label>Created:</label>
		<span>{{ editee.Created | date:'yyyy-MM-dd' }}</span>
    </div>
    <div class="field" *ngIf="editee.ID">
        <label>Modified:</label>
		<span>{{ editee.Modified | date:'yyyy-MM-dd' }}</span>
    </div>
</ng-template>

<!-- Administrator tab -->
<ng-template #page2>
    @if (editee.Administrator?.IsAzureUser && hasMultipleAllowedSources()) {
        <div class="field">Account type:</div>
        <div class="field-indented" *ngIf="editee.Administrator!.AllowLocal">
            <input type="radio" name="adminSource" [(ngModel)]="editee.Administrator!.Source" id="adminSource_SQLDB" value="SQLDB">
            <label for="adminSource_SQLDB">Local account</label>
        </div>
        <div class="field-indented" *ngIf="editee.Administrator!.AllowFenTenant">
            <input type="radio" name="adminSource" [(ngModel)]="editee.Administrator!.Source" id="adminSource_AzureAD" value="AzureAD">
            <label for="adminSource_AzureAD">Fenestrae account on Microsoft online</label>
        </div>
        <div class="field-indented" *ngIf="editee.Administrator!.AllowMultiTenant">
            <input type="radio" name="adminSource" [(ngModel)]="editee.Administrator!.Source" id="adminSource_AzureAD_MultiTenant" value="AzureAD_MultiTenant">
            <label for="adminSource_AzureAD_MultiTenant">Work or school account or Microsoft personal account</label>
        </div>
        <div class="field-indented" *ngIf="editee.Administrator!.AllowSocial" >
            <input type="radio" name="adminSource" [(ngModel)]="editee.Administrator!.Source" id="adminSource_AzureAD_Social" value="AzureAD_Social">
            <label for="adminSource_AzureAD_Social">Social account</label>
        </div>
    } @else {
        <div [ngSwitch]="editee.Administrator!.Source" class="field">
            <label>Account type:</label>
            <span *ngSwitchCase="'SQLDB'">Local account</span>
            <span *ngSwitchCase="'AzureAD'">Fenestrae account on Microsoft online</span>
            <span *ngSwitchCase="'AzureAD_MultiTenant'">Work or school or Microsoft personal account</span>
            <span *ngSwitchCase="'AzureAD_Social'">Social account</span>
            <span *ngSwitchDefault>{{ editee.Administrator!.Source }}</span>
        </div>
    }
    <br>
    <div class="field">
        @if (editee.Administrator?.IsAzureUser && editee.Administrator?.Source==='AzureAD') {
			<label>Email address:&nbsp;*</label>
			<input type="text" maxlength="100" #adminAlias="ngModel" name="adminAlias" [(ngModel)]="editee.Administrator!.Alias" required size="20"
			title="Enter the email address of the user.">
			<span>&nbsp;&#64;&nbsp;</span>
            <select *ngIf="hasMultipleDomains()" name="adminDomain" [(ngModel)]="editee.Administrator!.Domain">
                <option *ngFor="let d of editee.Administrator!.Domains">{{ d }}</option>
            </select>
			<span *ngIf="!hasMultipleDomains()">{{ editee.Administrator!.Domain }}</span>
            &ngsp;
			<span class="text-danger" *ngIf="adminAlias.dirty && adminAlias.errors?.['required']">&nbsp;An email address is required</span>
        } @else {
			<label>Username:&nbsp;*</label>
			<input type="email" email maxlength="100" #adminName="ngModel" name="adminName" [(ngModel)]="editee.Administrator!.Username" required size="40"
			title="Enter the username for the account. Must be in the form of an email address.">&ngsp;
			<span class="text-danger" *ngIf="adminName.dirty && adminName.errors?.['required']">&nbsp;A username is required</span>
			<span class="text-danger" *ngIf="adminName.dirty && adminName.errors?.['email']">&nbsp;Not a valid email address</span>
        }
    </div>
    <div class="field" *ngIf="!editee.Administrator?.IsAzureUser || editee.Administrator?.Source==='SQLDB' || editee.Administrator?.Source==='AzureAD'">
        <label>Password:&nbsp;*</label>
        <input type="password" autocomplete="off" maxlength="100" #adminPass="ngModel" name="adminPass" [(ngModel)]="editee.Administrator!.Password" required
        title="Enter the password for the account">&ngsp;
        <span class="text-danger" *ngIf="adminPass.dirty && adminPass.errors?.['required']">&nbsp;A password is required</span>
    </div>
    <div class="field">
        <label>Department:</label>
        <input type="text" maxlength="100" name="adminDepartment" [(ngModel)]="editee.Administrator!.Department" size="40"
        title="Enter an optional Department name for the account">
    </div>
    <div class="field">
        <label>Description:</label>
        <input type="text" maxlength="100" name="adminDescription" [(ngModel)]="editee.Administrator!.Description" size="40"
        title="Enter an optional Description of the account">
    </div>
</ng-template>

<!-- Contacts tab -->
<ng-template #page3>
    <h4>Technical</h4>
    <div class="field">
        <label>Name:</label>
        <input type="text" maxlength="100" name="contactTechnicalName" [(ngModel)]="editee.ContactTechnical!.Name" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional name of the technical contact person within this organization">
    </div>
    <div class="field">
        <label>Email:</label>
        <input type="text" maxlength="100" name="contactTechnicalEmail" [(ngModel)]="editee.ContactTechnical!.Email" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional email address of the technical contact person within this organization">
    </div>
    <div class="field">
        <label>Phone:</label>
        <input type="text" maxlength="100" name="contactTechnicalPhone" [(ngModel)]="editee.ContactTechnical!.Phone" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional phone number of the technical contact person within this organization">
    </div>
    <div class="field">
        <label>Note:</label>
        <textarea maxlength="500" rows="3" name="contactTechnicalNote" [(ngModel)]="editee.ContactTechnical!.Note" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional note about the technical contact person within this organization"></textarea>
    </div>
    <br/>
    <h4>Commercial</h4>
    <div class="field">
        <label>Name:</label>
        <input type="text" maxlength="100" name="ContactCommercialName" [(ngModel)]="editee.ContactCommercial!.Name" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional name of the commercial contact person within this organization">
    </div>
    <div class="field">
        <label>Email:</label>
        <input type="text" maxlength="100" name="ContactCommercialEmail" [(ngModel)]="editee.ContactCommercial!.Email" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional email address of the commercial contact person within this organization">
    </div>
    <div class="field">
        <label>Phone:</label>
        <input type="text" maxlength="100" name="ContactCommercialPhone" [(ngModel)]="editee.ContactCommercial!.Phone" size="40" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional phone number of the commercial contact person within this organization">
    </div>
    <div class="field">
        <label>Note:</label>
        <textarea maxlength="500" rows="3" name="ContactCommercialNote" [(ngModel)]="editee.ContactCommercial!.Note" [disabled]="!auth.isModifiable('Organizations')"
                title="Enter an optional note about the commercial contact person within this organization"></textarea>
    </div>
</ng-template>

<!-- Logging tab -->
<ng-template #page4>
    <p>Options for logging the jobs that are processed for the organization.</p>
    <h4><p>Backup</p></h4>
    <div class="field">
        <input type="checkbox" name="logAllowed" [(ngModel)]="editee.Logging!.BackupAllowed" [disabled]="!auth.isModifiable('Organizations')">
        Allow to store backups. Backup must still be enabled in the general settings page.<br/>
    </div>
    <div class="field" style="margin-bottom: 0;">
        <input type="radio" name="logMax" [(ngModel)]="editee.Logging!.BackupAgeMaxInfinite!" id="logMax_true"
            [value]="true" [attr.value]="'true'" [disabled]="!auth.isModifiable('Organizations')">
        <label for="logMax_true">Allow to keep backups forever</label>
    </div>
    <div class="field">
        <input type="radio" name="logMax" [(ngModel)]="editee.Logging!.BackupAgeMaxInfinite!" id="logMax_false"
            [value]="false" [attr.value]="'false'" [disabled]="!auth.isModifiable('Organizations')">
        <label for="logMax_false">Allow to keep backups for a maximum of &nbsp;</label>
        <input type="text" name="logAge" [(ngModel)]="editee.Logging!.BackupAgeMaxString"
            [disabled]="!auth.isModifiable('Organizations') || editee.Logging!.BackupAgeMaxInfinite!"
            fen-uint-only [required]="!editee.Logging!.BackupAgeMaxInfinite!" size="5">&nbsp;<span> day(s).</span>
    </div>
</ng-template>

    </form>
</section>
