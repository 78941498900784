import { AfterViewInit, Component, Inject, ViewChild, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { IPaginationQuery, PaginationComponent } from '../pagination/pagination.component';
import { FaxConfigApi } from '../api/faxconfig';
import { PaginationModule } from '../pagination/pagination.module';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FenUtilsService } from '../services/fenutils.service';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { FaxConfigRestOrganizationList, FaxConfigRestOrganizationDef, FaxConfigRestSessionContext, FaxConfigRestResult } from '../api/api';
import _ from 'underscore';

@Component({
    selector: 'dlg-organization-select',
    imports: [FormsModule, CommonModule, PaginationModule],
    templateUrl: './organization-select.component.html',
    styleUrl: './organization-select.component.css'
})
export class OrganizationSelectComponent implements AfterViewInit, IPaginationQuery  {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    private fenUtils: FenUtilsService = inject(FenUtilsService);
    public page: IPaginationQuery = this;
    public session: Session = inject (Session);
    public editee?: FaxConfigRestOrganizationDef;
    private switchSessionContext: boolean = false;
  
    @ViewChild(PaginationComponent)
    pagerOrganizations!: PaginationComponent;
    searchkeyOrganizations: string | null = null;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        if (data?.editee) {
            this.editee = data.editee as FaxConfigRestOrganizationDef;
            this.switchSessionContext = data.switchSessionContext? true: false;
        }
    }

    ngAfterViewInit() {
        this.refresh_organizations();
    }
  
    get isReady(): boolean {
        return this.pagerOrganizations?.ready?? false;
    }

    refresh_organizations() {
        this.pagerOrganizations.refresh();
    }

    search_organizations(): void {
        this.pagerOrganizations.reset();
    }

    invoke(offset: number, limit: number): Observable<FaxConfigRestOrganizationList> {
        let filter = {
            parent: null,
            search: this.searchkeyOrganizations
        };
        return this.faxSrv.GetOrganizationDefinitions(offset, limit, filter);
    }

    close() {
        this.dialogRef.close();
    }

    submitEnabled(): boolean {
        if (this.editee?.ID && this.pagerOrganizations?.items) {
            // Enable the 'Select' button only if the current selection is in the (filtered) list.
            return _.any(this.pagerOrganizations.items, item => { return item.ID === this.editee!.ID; });
        }
        return false;
    }

    select_org() {
        if (this.switchSessionContext && this.editee?.ID) {
            // Wait for context change confirmation before closing the dialog.
            let def: FaxConfigRestSessionContext = {
                SelectedOrganizationId: this.editee.ID
            }
            this.faxSrv.updateSessionContext(def).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.dialogRef.close(this.editee);
                    }
                },
                error: err => alert(err.message)
            });
        } else {
            this.dialogRef.close(this.editee)
        }
    }
}
