<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Receive Connector '{{ conn.FriendlyName }}'</h1>
    </div>
    <form id="dkim_server_config_form" class="form" novalidate (submit)="submit()" name="dkimServerConfigForm">
        <div class="modal-body">
            <p *ngIf="isModifiable">You can configure this SMTP receive connector to require a DKIM
            signature on all incoming messages, or only validate those messages which have a DKIM signature.</p>
            <p *ngIf="!isModifiable">View the current SMTP receive connector DKIM validation settings.</p>

            <div class="field-indented">
                <input type="radio" name="DKIMValidation" [(ngModel)]="editee.DKIMValidation" id="DKIMValidation_0"
                    [value]="0" [attr.value]="'0'" [disabled]="!isModifiable">&ngsp;
                <label for="DKIMValidation_0">Ignore DKIM signatures in messages</label>
            </div>
            <div class="field-indented">
                <input type="radio" name="DKIMValidation" [(ngModel)]="editee.DKIMValidation" id="DKIMValidation_2"
                    [value]="2" [attr.value]="'2'" [disabled]="!isModifiable">&ngsp;
                <label for="DKIMValidation_2">Only validate messages which have a DKIM signature</label>
            </div>
            <div class="field-indented">
                <input type="radio" name="DKIMValidation" [(ngModel)]="editee.DKIMValidation" id="DKIMValidation_1"
                    [value]="1" [attr.value]="'1'" [disabled]="!isModifiable">&ngsp;
                <label for="DKIMValidation_1">Require all messages to have a valid DKIM signature</label>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="isModifiable">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
