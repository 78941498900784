<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span class="text-muted">User</span> {{ user.Name }}</h1>
    </div>

    <form id="user_edit" class="form" #userForm="ngForm" novalidate name="userForm" (submit)="save()">
        <div class="modal-body">
            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of visiblePageTabs()" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab.Caption }}</a>
                </li>
            </ul>

            <div [hidden]="!pageTabs[0].showTab || state.activePageTab.Caption !== pageTabs[0].Caption">
                <ng-container *ngTemplateOutlet="page0"></ng-container>
            </div>
            <div [hidden]="!pageTabs[1].showTab || state.activePageTab.Caption !== pageTabs[1].Caption">
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </div>
            <div [hidden]="!pageTabs[2].showTab || state.activePageTab.Caption !== pageTabs[2].Caption">
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </div>
            <div [hidden]="!pageTabs[3].showTab || state.activePageTab.Caption !== pageTabs[3].Caption">
                <ng-container *ngTemplateOutlet="page3"></ng-container>
            </div>
            <div [hidden]="!pageTabs[4].showTab || state.activePageTab.Caption !== pageTabs[4].Caption">
                <ng-container *ngTemplateOutlet="page4"></ng-container>
            </div>
            <div [hidden]="!pageTabs[5].showTab || state.activePageTab.Caption !== pageTabs[5].Caption">
                <ng-container *ngTemplateOutlet="page5"></ng-container>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="isModifiableUsers" type="submit" class="btn btn-primary"
                [disabled]="!allReady || !userForm.valid || !isValid()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>

<!-- General tab -->
<ng-template #page0>
    <p *ngIf="!pageTabs[0].isReady">Loading User information...</p>
    <div *ngIf="pageTabs[0].isReady">
        <div class="field" *ngIf="session.isMultiTenant() && auth.isViewable('Organizations')">
            <label>Organization:</label>
            <span>{{ user.OrganizationName }}</span>
        </div>
        <div class="field">
            <label>Name: *</label>
            <input type="text" name="name" maxlength="50" [(ngModel)]="user.Name" size="40" [disabled]="!isModifiableUsers">
        </div>
        <div class="field" *ngIf="!useAddressAliasAndDomain()">
            <label>Address: *</label>
            <input type="text" name="address" maxlength="150" [(ngModel)]="user.Address" size="40" [disabled]="!isModifiableUsers || isLdapUser">
        </div>
        <div class="field" *ngIf="useAddressAliasAndDomain()">
            <label>Address: *</label>
            <input type="text" name="addressAlias" [(ngModel)]="user.AddressAlias" maxlength="100" size="20" [disabled]="!isModifiableUsers" title="Enter the email alias of the user.">
		    <span>&ngsp;&#64;&ngsp;</span>
            <select disabled *ngIf="!organization.SmtpDomains">
                <option value="user.AddressDomain">{{user.AddressDomain}}</option>
            </select>
            <select disabled *ngIf="organization.SmtpDomains && organization.SmtpDomains.length===1">
                <option value="organization.SmtpDomains[0]">{{organization.SmtpDomains[0]}}</option>
            </select>
            <select *ngIf="organization.SmtpDomains && organization.SmtpDomains.length!==1" name="addressDomain" [(ngModel)]="user.AddressDomain" title="Select the email domain of the user." [disabled]="!isModifiableUsers">
                <option *ngFor="let d of organization.SmtpDomains" [value]="d">{{ d }}</option>
            </select>
        </div>
        <div class="field">
            <label>Mail System: *</label>
            <select name="mailSystem" style="min-width:250px;" [(ngModel)]="user.MailSystemItem" (ngModelChange)="onMailSystemChanged()" [disabled]="!isModifiableUsers || isLdapUser">
                <option *ngFor="let x of userMailSystems" [ngValue]="x">{{ x.FriendlyName }}</option>
            </select>
        </div>
        <div class="field">
            <label>Address Type: *</label>
            <select name="addressType" style="min-width:250px;" [(ngModel)]="user.AddressType" [disabled]="!isModifiableUsers || isLdapUser">
                <option *ngFor="let x of user.MailSystemItem?.AddrTypes" [value]="x">{{ x }}</option>
            </select>
        </div>
        <div class="field" *ngIf="(!session.isMultiTenant() && auth.isViewable('Connectors')) || (session.isMultiTenant() && usesSystemAddress(user.MailSystemItem?.MailSystem, user.AddressType) )">
            <label>System Address:</label>
            <input type="text" name="systemAddress" maxlength="20" [(ngModel)]="user.SystemAddress" [disabled]="!isModifiableUsers">
        </div>
        <div class="field">
            <label>Charge Code:<span *ngIf="userViewOptions.ChargeCodeRequired"> *</span></label>
            <input type="text" name="chargeCode" maxlength="16" [(ngModel)]="user.ChargeCode" [disabled]="!isModifiableUsers">
            <span class="text-danger" *ngIf="isModifiableUsers && userViewOptions.ChargeCodeRequired && !user.ChargeCode">&nbsp;A charge code is required</span>
        </div>
        <div class="field">
            <label>Mail Template:</label>
            <select name="mailTemplate" [(ngModel)]="user.MailTemplate" [disabled]="!isModifiableUsers">
                <option *ngFor="let mt of mailTemplates" [value]="mt">{{ mt }}</option>
            </select>
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideChargeCode" [(ngModel)]="user.AllowToOverrideChargeCode" [disabled]="!isModifiableUsers">
            User is allowed to override charge code
        </div>
    </div>
</ng-template>

<!-- Routing tab -->
<ng-template #page1>
    <p *ngIf="!pageTabs[1].isReady">Loading Routing information...</p>
    <div *ngIf="pageTabs[1].isReady">
        <div class="pull-right">
            <input type="checkbox" name="viewFaxRouteCopyAll" *ngIf="userViewOptions.ViewFaxRouteCopyAll" [(ngModel)]="user.hasDirAll" [disabled]="!isModifiableUsers">
            <label style="margin-bottom: 0;" *ngIf="userViewOptions.ViewFaxRouteCopyAll">&ngsp;This user gets a copy of all inbound faxes</label><br>
            <input type="checkbox" name="viewFaxRouteDefault" *ngIf="userViewOptions.ViewFaxRouteDefault" [(ngModel)]="user.hasDirDef" [disabled]="!isModifiableUsers || user.hasDirAll === true">
            <label style="margin-bottom: 0;" *ngIf="userViewOptions.ViewFaxRouteDefault">&ngsp;Deliver unroutable inbound faxes to this user</label>
        </div>

        <div *ngIf="isModifiableUsers" class="btn-toolbar">
            <button class="btn" type="button" (click)="removeRoutes()" [disabled]="routeSelection().length == 0 || newRoute">
                Remove
            </button>
            <button class="btn" type="button" (click)="setPrimaryRoute()"
                    [disabled]="routeSelection().length != 1 || routeSelection()[0].isPrimary || newRoute">
                Set Primary
            </button>
            <button class="btn" type="button" (click)="editRoute()" [disabled]="routeSelection().length != 1 || newRoute">
                Edit
            </button>
        </div>

        <table id="user_routing_types" class="table table-condensed">
            <thead>
                <tr>
                    <th *ngIf="isModifiableUsers" width="25px"></th>
                    <th width="120px">Type</th>
                    @if (!session.isMultiTenant() && session.usePhonenumberAdministration() && isModifiableAddresses) {
                        <th width="140px">Code</th>
                        <th width="140px">New number</th>
                        <th>Connection</th>
                    } @else {
                        <th width="280px">Code</th>
                        <th></th>
                    }
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let route of routing; let idx = index" [ngClass]="{start_group: idx == 0 || route.type != routing[idx - 1].type}">
                    <td *ngIf="isModifiableUsers"><input type="checkbox" [disabled]="newRoute? true: false" [ngModelOptions]="{standalone: true}" [(ngModel)]="route.selected"></td>
                    <td>{{ route.type }}</td>
                    @if (!session.isMultiTenant() && session.usePhonenumberAdministration() && isModifiableAddresses && route.newAddress) {
                        <td>New number</td>
                        <td [class.primary]="route.isPrimary">{{ route.newAddress.address }}</td>
                        <td>{{ route.newAddress.connection.ConnectionName }}</td>
                    } @else {
                        <td [class.primary]="route.isPrimary">{{ route.code }}</td>
                    }
                </tr>
            </tbody>
        </table>
        <div *ngIf="isModifiableUsers && newRoute">
            <table id="user_new_route" class="table-condensed" style="table-layout: fixed; margin-top: 10px;">
                <tbody>
                    <tr>
                        <td width="25px"></td>
                        <td width="120px">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.type" (ngModelChange)="routeTypeChanged(newRoute)" style="width: 102px;">
                                <option *ngFor="let rt of userRoutingTypes" [value]="rt">{{ rt }}</option>
                            </select>
                        </td>
                        @if (session.usePhonenumberAdministration()) {
                            <td [width]="(!session.isMultiTenant() && isModifiableAddresses)? '140px': '280px'">
                            @switch (newRoute.type) {
                                @case ("FAX") {
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.code" style="width: 100%;" [class.primary]="newRoute.isPrimary && newRoute.code !== '_new_'">
                                        <option *ngFor="let num of organization.FaxNumbers" [value]="num.Address">{{ num.Address }}</option>
                                        <option *ngIf="!session.isMultiTenant() && isModifiableAddresses" value="_new_">New number</option>
                                    </select>
                                }
                                @case ("DIR") {
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.code" style="width: 100%;" [class.primary]="newRoute.isPrimary && newRoute.code !== '_new_'">
                                        <option *ngFor="let num of organization.DirNumbers" [value]="num.Address">{{ num.Address }}</option>
                                        <option *ngIf="!session.isMultiTenant() && isModifiableAddresses" value="_new_">New number</option>
                                    </select>
                                }
                                @case ("SMS") {
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.code" style="width: 100%;" [class.primary]="newRoute.isPrimary && newRoute.code !== '_new_'">
                                        <option *ngFor="let num of organization.SmsNumbers" [value]="num.Address">{{ num.Address }}</option>
                                        <option *ngIf="!session.isMultiTenant() && isModifiableAddresses" value="_new_">New number</option>
                                    </select>
                                }
                                @default {
                                    <input type="text" [class.primary]="newRoute.isPrimary" maxlength="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.code" required style="width: 100%;">
                                }
                            }
                            </td>
                        } @else {
                            <td width="280px">
                                <input type="text" [class.primary]="newRoute.isPrimary" maxlength="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.code" required style="width: 100%;">
                            </td>
                        }
                        @if (!session.isMultiTenant() && session.usePhonenumberAdministration() && isModifiableAddresses && newRoute.code==='_new_') {
                            <td width="140px">
                                <input type="text" [class.primary]="newRoute.isPrimary" maxlength="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.new_code" required style="width: 100%;"
                                    title="{{ getNewRouteDescription(newRoute.type) }}">
                            </td>
                            <td width="200px">
                                <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newRoute.new_provider" style="width: 100%;">
                                    <option *ngFor="let p of Connections" [ngValue]="p">{{ p.ConnectionName }}</option>
                                </select>
                            </td>
                        }
                        <td>
                            &nbsp;<button class="btn btn-sm" type="button" (click)="mergeRoute()" [disabled]="!isValidNewRoute()">OK</button>
                            &ngsp;<button class="btn btn-sm" type="button" (click)="cancelRoute()">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table-condensed" style="table-layout: fixed;">
                <tbody>
                    <tr>
                        <td width="25px">&nbsp;</td>
                        <td>
                            <span class="text-danger">{{ newRoute.ErrorMessage }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isModifiableUsers && !newRoute">
            <button class="btn btn-link" type="button" (click)="addNewRoute()">+ New Route</button>
        </div>
    </div>
</ng-template>

<!-- Proxy Addresses tab -->
<ng-template #page2>
    <p *ngIf="!pageTabs[2].isReady">Loading Proxy Addresses...</p>
    <div *ngIf="pageTabs[2].isReady">
        <div *ngIf="isModifiableUsers" class="btn-toolbar">
            <button class="btn" type="button" (click)="removeProxys()" [disabled]="proxySelection().length <= 0 || newProxy">
                Remove
            </button>
            <button class="btn" type="button" (click)="setPrimaryProxy()"
                    [disabled]="proxySelection().length != 1 || proxySelection()[0].isPrimary || newProxy">
                Set Primary
            </button>
            <button class="btn" type="button" (click)="editProxy()" [disabled]="proxySelection().length != 1 || newProxy">
                Edit
            </button>
        </div>

        <table id="user_proxy_addresses" class="table table-condensed">
            <thead>
                <tr>
                    <th *ngIf="isModifiableUsers" width="25px"></th>
                    <th width="200px">Mail System</th>
                    <th width="160px">Address Type</th>
                    <th >Address</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let proxy of proxyAddress; index as idx" [ngClass]="{start_group: idx == 0 || proxy.AddrType != proxyAddress[idx - 1].AddrType || proxy.MailSystem != proxyAddress[idx - 1].MailSystem}" >
                    <td *ngIf="isModifiableUsers"><input type="checkbox" [disabled]="newProxy? true: false" [ngModelOptions]="{standalone: true}" [(ngModel)]="proxy.selected"></td>
                    <td>{{ proxy.MailSystem }}</td>
                    <td>{{ proxy.AddrType }}</td>
                    <td [class.primary]="proxy.isPrimary">{{ proxy.Address }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="isModifiableUsers && newProxy">
            <table id="user_new_proxy" class="table-condensed" style="table-layout: fixed; margin-top: 10px;">
                <tbody>
                    <tr>
                        <td width="25px"></td>
                        <td width="200px">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newProxy.MailSystem" style="width: 192px;" (ngModelChange)="proxyMailSystemChange(newProxy)">
                                <option *ngFor="let x of userMailSystems" [ngValue]="x">{{ x.FriendlyName }}</option>
                            </select>
                        </td>
                        <td width="160px">
                            <select [ngModelOptions]="{standalone: true}" [(ngModel)]="newProxy.AddrType" style="width: 152px;" (ngModelChange)="proxyAddrTypeChanged(newProxy)">
                                <option *ngFor="let x of newProxy.MailSystem.AddrTypes" [value]="x">{{ x }}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" [class.primary]="newProxy.isPrimary" [ngModelOptions]="{standalone: true}" [(ngModel)]="newProxy.Address" required maxlength="150">
                            &nbsp;<button class="btn btn-sm" type="button" (click)="mergeProxy()" [disabled]="!isValidNewProxy()">OK</button>
                            &ngsp;<button class="btn btn-sm" type="button" (click)="cancelProxy()">Cancel</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table-condensed" style="table-layout: fixed;">
                <tbody>
                    <tr>
                        <td width="25px">&nbsp;</td>
                        <td>
                            <span class="text-danger">{{ newProxy.ErrorMessage }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isModifiableUsers && !newProxy">
            <button class="btn btn-link" type="button" (click)="addNewProxy()">+ New Address</button>
        </div>
    </div>
</ng-template>

<!-- FAX tab -->
<ng-template #page3>
    <p *ngIf="!pageTabs[3].isReady">Loading FAX options...</p>
    <div *ngIf="pageTabs[3].isReady">
        <div class="field">
            <label>Coversheet:</label>
            <select name="faxCoversheet" [(ngModel)]="user.Fax!.Coversheet" [disabled]="!isModifiableUsers">
                <option *ngFor="let cs of organization.coversheets" [value]="cs">{{ cs }}</option>
            </select>
        </div>
        <div class="field">
            <label>File Type:</label>
            <select name="faxFormat" [(ngModel)]="user.Fax!.FileFormat" [disabled]="!isModifiableUsers">
                <option *ngFor="let f of faxFormats" [value]="f.FaxFormat">{{ f.DisplayName }}</option>
            </select>
        </div>
        <div class="field">
            <label>CSID:</label>
            <input type="text" name="CSID" maxlength="20" [(ngModel)]="user.Fax!.CSID" [disabled]="!isModifiableUsers">
        </div>
        <div>
            <input type="checkbox" name="allowToSendFax" [(ngModel)]="user.Fax!.AllowToSendFax" [disabled]="!isModifiableUsers">
            User is allowed to send fax messages
        </div>
        <div class="field" id="allowToSendFaxInt">
            <input type="checkbox" name="allowToSendFaxInt" [(ngModel)]="user.Fax!.AllowToSendFaxInt" [disabled]="!isModifiableUsers || user.Fax?.AllowToSendFax !== true">
            International
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideCoversheet" [(ngModel)]="user.Fax!.AllowToOverrideCoversheet" [disabled]="!isModifiableUsers">
            User is allowed to override fax coversheet
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideCSID" [(ngModel)]="user.Fax!.AllowToOverrideCSID" [disabled]="!isModifiableUsers">
            User is allowed to override the CSID
        </div>
        <div class="fieldset" *ngIf="userViewOptions.TrafficFaxLimitationEnabled">
            <label><input type="checkbox" name="limitsFaxIsActive" [(ngModel)]="limits!.fax.isActive"> Limit to</label>&ngsp;
            <input type="number" style="width: 60px;" min="0" max="999999" name="limitsFaxAmount" [(ngModel)]="limits!.fax.amount" [disabled]="!isModifiableUsers || limits!.fax.isActive !== true">
            &ngsp;messages per&ngsp;
            <input type="number" style="width: 90px;" min="1" max="999" name="limitsFaxPeriodDuration" [(ngModel)]="limits!.fax.period.duration" [disabled]="!isModifiableUsers || limits!.fax.isActive !== true">
            &ngsp;
            <select name="limitsFaxPeriodUnit" [(ngModel)]="limits!.fax.period.unit" [disabled]="!isModifiableUsers || limits!.fax.isActive !== true">
                <option *ngFor="let u of periodUnits" [ngValue]="u">{{ u.unit }}</option>
            </select>
        </div>
    </div>
</ng-template>

<!-- SMS tab -->
<ng-template #page4>
    <p *ngIf="!pageTabs[4].isReady">Loading SMS options...</p>
    <div *ngIf="pageTabs[4].isReady">
        <div class="field">
            <label>Template:</label>
            <select name="smsTemplate" [(ngModel)]="user.SMS!.Template" [disabled]="!isModifiableUsers">
                <option *ngFor="let tpl of organization.smsTemplates" [value]="tpl">{{ tpl }}</option>
            </select>
        </div>
        <div>
            <input type="checkbox" name="allowToSendSMS" [(ngModel)]="user.SMS!.AllowToSendSMS" [disabled]="!isModifiableUsers">
            User is allowed to send SMS messages
        </div>
        <div class="field" id="allowToSendSMSInt">
            <input type="checkbox" name="allowToSendSMSInt" [(ngModel)]="user.SMS!.AllowToSendSMSInt" [disabled]="!isModifiableUsers || user.SMS!.AllowToSendSMS !== true">
            International
        </div>
        <div class="field">
            <input type="checkbox" name="allowToOverrideTemplate" [(ngModel)]="user.SMS!.AllowToOverrideTemplate" [disabled]="!isModifiableUsers">
            User is allowed to override template
        </div>
        <div class="fieldset" *ngIf="userViewOptions.TrafficSmsLimitationEnabled">
            <label><input type="checkbox" name="limitsSmsIsActive" [(ngModel)]="limits!.sms.isActive"> Limit to</label>&ngsp;
            <input type="number" style="width: 60px;" min="0" max="999999" name="limitsSmsAmount" [(ngModel)]="limits!.sms.amount" [disabled]="!isModifiableUsers || limits!.sms.isActive !== true">
            &ngsp;messages per&ngsp;
            <input type="number" style="width: 90px;" min="1" max="999" name="limitsSmsPeriodDuration" [(ngModel)]="limits!.sms.period.duration" [disabled]="!isModifiableUsers || limits!.sms.isActive !== true">
            &ngsp;
            <select name="limitsSmsPeriodUnit" [(ngModel)]="limits!.sms.period.unit" [disabled]="!isModifiableUsers || limits!.sms.isActive !== true">
                <option *ngFor="let u of periodUnits" [ngValue]="u">{{ u.unit }}</option>
            </select>
        </div>
        <div class="fieldset">
            <label>Split large SMS content into at most</label>&ngsp;
            <input type="number" style="width: 60px;" min="1" max="255" name="userSMSMaxSplit" [(ngModel)]="user.SMS!.MaxSplit" [disabled]="!isModifiableUsers">&ngsp;
            <span>SMS messages</span>
        </div>
        <div class="fieldset">
            <label><input type="checkbox" name="userSMSSegmentation" [(ngModel)]="user.SMS!.Segmentation" [disabled]="!isModifiableUsers"> Mobile device supports SMS concatenation</label>
        </div>
    </div>
</ng-template>

<!-- Miscellaneous tab -->
<ng-template #page5>
    <p *ngIf="!pageTabs[5].isReady">Loading Miscellaneous options...</p>
    <div *ngIf="pageTabs[5].isReady">
        <p>The location and time zone information is used for displaying local times on fax coversheets
           and message notifications.  The &quot;&lt;DEFAULT&gt;&quot; selections indicate to use the
           <span *ngIf="session.isMultiTenant()"> default values for your organization.</span>
           <span *ngIf="!session.isMultiTenant()"> Faxination Kernel location and time zone.</span>
        </p>
        <div class="field">
            <label>Location:</label>
            <select name="countryISO2" [(ngModel)]="user.CountryISO2" [disabled]="!isModifiableUsers">
                <option *ngFor="let c of numSubstCountries" [value]="c.ISO2">{{ c.Name }}</option>
            </select>
        </div>
        <div class="field" *ngIf="!isLdapUser">
            <label>Time zone:</label>
            <select name="timeZone" [(ngModel)]="user.TimeZone" [disabled]="!isModifiableUsers">
                <option *ngFor="let tz of orgTimeZones" [value]="tz.Id">{{ tz.DisplayName }}</option>
            </select>
        </div>
    </div>
</ng-template>

    </form>
</section>
