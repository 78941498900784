<h1>Services</h1>

<p>
    Check here the status of your Faxination services.
<span *ngIf="auth.isModifiable('Services')"> Start/stop or restart the services.
    <strong>Please be careful</strong> because stopping and starting Faxination services can mean
    that your Faxination server is not available during this period.
    Please do note that there are dependencies between services.
    Stopping a service can stop other services as well.
    Check the Faxination administrator guide or use the Fenestrae Server Administrator tool.
</span></p>

<div style="min-height:40px;">
    <strong>Services on machine:</strong>&nbsp;
    <select *ngIf="hasMultipleKernels()" style="width:200px;" [ngModel]="selectedKernel" (ngModelChange)="ntServicesOnKernelChanged($event)">
        <option *ngFor="let k of availableKernels" [value]="k">{{ k }}</option>
    </select>
    <strong *ngIf="!hasMultipleKernels()">{{ selectedKernel }}</strong>
    <div *ngIf="isReady" class="text pull-right">
        Refresh every&nbsp;
        <input type="text" style="width:40px" [(ngModel)]="servicesRefreshEvery" (ngModelChange)="refresh_change($event)">
        seconds
    </div>
</div>

<div class="btn-toolbar" *ngIf="auth.isModifiable('Services')">
    <div class="btn-group">
        <button type="button" class="btn" (click)="start()" [disabled]="!canStart">Start</button>
        <button type="button" class="btn" (click)="stop()" [disabled]="!canStop">Stop</button>
        <button type="button" class="btn" (click)="restart()" [disabled]="!canRestart">Restart</button>
        <button type="button" class="btn" (click)="pause()" [disabled]="!canPause">Pause</button>
        <button type="button" class="btn" (click)="resume()" [disabled]="!canResume">Resume</button>
    </div>
    <button type="button" class="btn btn-link" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
</div>

<div *ngIf="isInitialized" id="ntServicesGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="ntServicesGridOptions"></ag-grid-angular>
</div>
