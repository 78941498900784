<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Coversheet Font ({{ editee.fontItem }})</h1>
    </div>
    <div class="modal-body" *ngIf="!isReady">
        <p class="alert-info">Getting font list from server, please wait...</p>
    </div>
    <form id="coversheet_font_dialog" class="form" novalidate (submit)="save()">
        <div class="modal-body" *ngIf="isReady">
            <p>Choose the font and font size to use for the {{ editee.fontItem }} on the coversheet.</p>
            <div class="field">
                <label>Font name:</label>
                <select name="FontName" [(ngModel)]="editee.fontName" (ngModelChange)="font_change()" >
                    <option *ngFor="let f of fontNames" [value]="f.Name" >{{ f.Name }}</option>
                </select>
            </div>
            <p></p>
            <div class="field">
                <label>Font size:</label>
                <select style="width:60px;" name="FontSize" [(ngModel)]="editee.fontSize">
                    <option *ngFor="let f of fontSizes">{{ f }}</option>
                </select>
            </div>
            <p></p>
            <br>
            <strong>Preview (font name only):</strong>
            <p class="well" [ngStyle]="fontStyle">The quick brown fox jumps over the lazy dog 1234567890</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
