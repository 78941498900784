<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ headerText }}</h1>
    </div>
    <form id="faxLineLimits_edit_form" class="form" #faxLineLimitsEditForm="ngForm" novalidate (submit)="submit()">
        <div class="modal-body">
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [disabled]="!isModifiable" [(ngModel)]="editee.Enabled"
                    title="Enable or disable this entry">
            </div>
            <div class="field">
                <label>Fax number: *</label>
				<input type="text" name="FaxNumber" [disabled]="!isModifiable" maxlength="20" [(ngModel)]="editee.FaxNumber" size="40" required
                    title="Enter the fax number as it would appear after Number Substitution">
            </div>
			<div class="field">
				<label>Max lines: *</label>
				<input type="number" name="MaxLines" [disabled]="!isModifiable" maxlength="10" [(ngModel)]="editee.MaxLines" required min="1" max="2147483647"
                    title="Enter the maximum number of faxes that can be sent to this number simultaneously">
			</div>
            <div class="field">
                <label>Description:</label>
                <input type="text" name="Description" [disabled]="!isModifiable" maxlength="100" [(ngModel)]="editee.Description" size="40"
                    title="Enter an optional Description for this entry">
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="isModifiable" [disabled]="!faxLineLimitsEditForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
