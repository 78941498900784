<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ uploadTitle }}</h1>
    </div>
    <form id="mail_templates_upload_form" class="form" novalidate (submit)="submit()" name="mailTemplatesUploadForm">
        <div class="modal-body">
            <p>Upload a zip file archive containing a complete set of mail templates.</p>
            <p>The &quot;Overwrite files&quot; checkbox indicates what to do if any new files in the zip
            conflict with the already existing templates. If checked, then the new files will replace
            the existing files, otherwise the conflicting new files will be skipped.</p>
            <br>
            <div class="field">
                <label title="Choose a zip archive">Zip archive:&nbsp;*</label>
                <input type="file" id="mail_templates_archive" required accept=".zip" (change)="filechange($event)">
            </div>
            <div class="field">
                <label>Overwrite files:</label>
                <input type="checkbox" name="overwrite" [(ngModel)]="editee.overwrite" title="Overwrite existing templates">
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!uploadEnabled">Upload</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
