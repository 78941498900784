<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ action }} Outbound Service Routing Rule</h1>
    </div>
    <form id="outbound_route" class="form" (submit)="save()" novalidate *ngIf="isReady">
        <div class="modal-body">
            <div [ngSwitch]="route.RouteType" style="min-height: 72px;">
            <span *ngSwitchCase="'00'">The Destination Address rule applies if the destination number matches the 'Destination' value configured for the rule.</span>
            <span *ngSwitchCase="'01'">The ChargeCode rule applies if the sender's charge code matches the 'ChargeCode' value configured for the rule.</span>
            <span *ngSwitchCase="'02'">The Sender rule applies if the sender's email address or X.500 address matches the 'Sender' value configured for the rule.</span>
            <span *ngSwitchCase="'03'">The Proxy fax address rule applies if the sender has a fax number that is within the 'Proxy fax' range configured for the rule.</span>
            <span *ngSwitchCase="'05'">The Fax loopback rule applies if the destination fax number matches the 'DIR' or 'FAX' routing code of an existing user.  For this to be useful, you should enter the 'Service Name' of a device service which can send faxes to itself.</span>
            <span *ngSwitchCase="'99'">The Default rule is used only when all other rules do not apply.</span>
            <span *ngSwitchDefault></span>
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" name="Description" maxlength="255" [(ngModel)]="route.Description" [disabled]="!isModifiable" size="40">
            </div>
            <div class="field">
                <label>Routing Type: *</label>
                <select name="RouteType" [(ngModel)]="route.RouteType" (ngModelChange)="onRouteTypeChanged()" [disabled]="!isModifiable" required>
                    <option *ngFor="let rt of routeTypes.list" [value]="rt.code">{{ rt.display }}</option>
                </select>
            </div>
            <div class="field">
                <label>Message Type: *</label>
                <select name="MessageType" [(ngModel)]="route.MessageType"
                    [disabled]="!isModifiable || route.RouteType === '03' || route.RouteType === '05'"
                    [required]="isModifiable && route.RouteType !== '03' && route.RouteType !== '05'">
                    <option *ngFor="let mt of messageTypes" [value]="mt">{{ mt }}</option>
                </select>
            </div>
            <div class="field">
                <label>{{ routeTypes.byCode[route.RouteType!].valueLabel }}</label>
                <input type="text" name="routeValue0" maxlength="255" [(ngModel)]="routeValue[0]"
                    [disabled]="!isModifiable || route.RouteType === '05' || route.RouteType === '99'"
                    [required]="isModifiable && route.RouteType !== '05' && route.RouteType !== '99'">
                <!-- Proxy FAX -->
                <span *ngIf="route.RouteType === '03'">&ngsp;To&ngsp;</span>
                <input *ngIf="route.RouteType === '03'" type="text" name="routeValue1" maxlength="255" [(ngModel)]="routeValue[1]" [disabled]="!isModifiable">
            </div>
            <div class="field">
                <input type="radio" name="Block" [(ngModel)]="route.Block" id="Block_true" [value]="true" [attr.value]="'true'" [disabled]="!isModifiable">
                <label for="Block_true">Block this combination</label>
            </div>
            <div class="field">
                <label>
                    <input type="radio" name="Block" [(ngModel)]="route.Block" id="Block_false" [value]="false" [attr.value]="'false'" [disabled]="!isModifiable">
                    <label for="Block_false" >Service Name<span *ngIf="!route.Block"> *</span></label>
                </label>
                <input type="text" name="Service" maxlength="100" [(ngModel)]="route.Service"
                    *ngIf="route.RouteType !== '05' || !loopbackServices.length"
                    [disabled]="!isModifiable || route.Block"
                    [required]="isModifiable && !route.Block">
                <app-combobox name="LoopbackService" [(model)]="route.Service"
                    *ngIf="route.RouteType === '05' && loopbackServices.length"
                    [disabled]="!isModifiable || route.Block"
                    [required]="isModifiable && !route.Block"
                    [options]="loopbackServices">
                </app-combobox>
            </div>
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="route.Enabled" [disabled]="!isModifiable">
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!isValid()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
