<div class="login-form">
  <div>
<h1 *ngIf="isReady">Login</h1>

<form id="login" #f="ngForm" class="form" name="form" *ngIf="isReady" novalidate>
    @if (!Enforced && mfaPending) {
        <p>A multi-factor authentication code has been sent to the mailbox associated with your account. Please enter the code below to complete your login.</p>
        <p class="text-danger">{{ error }}</p>
        <div class="field">
            <label>Code:</label>
            <input type="text" name="mfaCode" required [(ngModel)]="mfaCode">
        </div>
        <div class="buttons">
            <button type="submit" class="btn btn-primary" [disabled]="!f.valid" (click)="login(f)">Login</button> &nbsp;
            <button type="button" class="btn" (click)="cancelMfa()">Cancel</button> &nbsp;
        </div>
    } @else {
        <p class="text-danger">{{ error }}</p>

        <div class="field" *ngIf="!Enforced">
            <label>Username:</label>
            <input type="text" name="userName" required [(ngModel)]="userName">
        </div>
        <div class="field" *ngIf="!Enforced">
            <label>Password:</label>
            <input type="password" autocomplete="off" [(ngModel)]="password" name="password" required>
        </div>

        <div class="buttons" *ngIf="!Enforced">
            <button type="submit" class="btn btn-primary" [disabled]="!f.valid" (click)="login(f)">Login</button> &nbsp;
            <a href="" style="font-size:0.8em;" (click)="login_reset()">Forgot my password</a>
            <br />
            <br />
            <br />
        </div>

        <p *ngIf="azureConfig?.Enabled && Enforced">
            <span>Click below to login.</span>
            <br />
            <br />
        </p>

        <p *ngIf="azureConfig?.Enabled && azureConfig?.AllowFenTenant">
            <span *ngIf="!Enforced">or&nbsp;</span>
            <a href="" (click)="login_azure();false">Login with your Fenestrae account on Microsoft online</a>
        </p>

        <p *ngIf="azureConfig?.Enabled && azureConfig?.AllowMultiTenant">
            <span *ngIf="!Enforced || azureConfig?.AllowFenTenant">or&nbsp;</span>
            <a href="" (click)="login_azure();false">Login with your work or school or personal account on Microsoft online</a>
        </p>

        <p *ngIf="azureConfig?.Enabled && azureConfig?.AllowSocial">
            <span *ngIf="!Enforced || azureConfig?.AllowFenTenant || azureConfig?.AllowMultiTenant">or&nbsp;</span>
            <a href="" (click)="login_azure();false">Login with a social account</a>
        </p>
    }
</form>
</div>
</div>