<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Outbound Service Routing Order</h1>
    </div>
    <form id="osr_order" class="form" (submit)="save()" novalidate>
        <div class="modal-body">
            <p>
            Outbound Service Routings are checked in the following order. The first route type
            that returns a match will overrule the route types which are below it in the list.
            To change the position of a route type, select it and use the movement buttons.
            </p>

            <div class="btn-toolbar">
                <div class="btn-group">
                    <button type="button" class="btn" (click)="moveup()" [disabled]="moveup_disabled()">Move Up</button>
                    <button type="button" class="btn" (click)="movedown()" [disabled]="movedown_disabled()">Move Down</button>
                </div>
                <div class="btn-group pull-right">
                    <button type="button" class="btn" [disabled]="is_default()" (click)="reset_to_default()">Default</button>
                </div>
            </div>

            <table class="table table-click table-condensed table-bordered">
                <tbody>
                    <tr *ngFor="let routetype of editee" (click)="selectRouteType(routetype)">
                        <td width="25px"><input type="radio" name="routetype" [(ngModel)]="selectedRouteType" id="routetype_{{ routetype.RouteType }}" [value]="routetype.RouteType" [attr.value]="routetype.RouteType"></td>
                        <td>{{ routetype.DisplayName }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
