import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestConnectorDef, FaxConfigRestKernelContract, FaxConfigRestKernelContractInfo, FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";
import * as _ from 'underscore';

export enum EContractFlags {
    CONTRACT_FLAG_HOST = 0x1,
    CONTRACT_FLAG_DEVICE = 0x2,
    CONTRACT_FLAG_ADDON = 0x4,
    CONTRACT_FLAG_CONNECTORTYPE = CONTRACT_FLAG_DEVICE | CONTRACT_FLAG_HOST | CONTRACT_FLAG_ADDON,
    CONTRACT_FLAG_MONITOR = 0x8,
    CONTRACT_FLAG_DEV_CHECK_VIA_KERNEL = 0x10000
}

enum EDeviceTypes {
    // Mobile Device protocols
    DEVICE_TYPE_MOBILE_CIMD = 20,
    DEVICE_TYPE_MOBILE_UCP  = 21,
    DEVICE_TYPE_MOBILE_OIS  = 22,
    DEVICE_TYPE_MOBILE_SMPP = 23
}

export interface IConnector extends FaxConfigRestConnectorDef {
    encodedType: number;
}

export interface IContract extends FaxConfigRestKernelContractInfo {
    ConnectorTypeName: string | null;
    encodedType: number;
    selected?: boolean;
}

export interface IContractScope {
    mode: string;
    editee: IContract;
    connectors: IConnector[];
}

interface IEditee extends IContract {
    monitor: boolean;
    device_check_inbound_via_kernel: boolean;
}

@Component({
    selector: 'app-contract-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './contract-edit.component.html',
    styleUrl: './contract-edit.component.css'
})
export class ContractEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = true;        
    editee: IEditee;
    connectors: IConnector[];
    headerText: string = '';
    submitText: string = '';
    mode: string;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IContractScope
    ) {
        this.mode = data.mode;
        this.editee = _.extend({}, data.editee);
        this.connectors = data.connectors;
        this.init();
    }

    private init(): void {
        if (this.mode == 'add') {
            this.headerText = 'Add Contract';
            this.submitText = 'Add';
        }
        else if (this.mode == 'edit') {
            this.headerText = 'Modify Contract';
            this.submitText = 'Modify';
        }

        this.editee.monitor = (this.editee.Flags & EContractFlags.CONTRACT_FLAG_MONITOR) != 0;
        // The following flags are specific to connector type
        this.editee.device_check_inbound_via_kernel = this.isDevice(this.editee.encodedType) && ((this.editee.Flags & EContractFlags.CONTRACT_FLAG_DEV_CHECK_VIA_KERNEL ) != 0);
    }


    private apply_masked_value(cur_value: number, mask: number, value: number): number {
        return (cur_value & ~mask) | value;
    }

    private encode_flags(): number {
        let flags: number = this.editee.Flags;
        flags = this.apply_masked_value(flags, EContractFlags.CONTRACT_FLAG_CONNECTORTYPE, (this.editee.encodedType & 0xff00) >> 8);
        flags = this.apply_masked_value(flags, EContractFlags.CONTRACT_FLAG_MONITOR, this.editee.monitor ? EContractFlags.CONTRACT_FLAG_MONITOR : 0);
        
        let val = (this.isDevice(this.editee.encodedType) && this.editee.device_check_inbound_via_kernel) ? EContractFlags.CONTRACT_FLAG_DEV_CHECK_VIA_KERNEL: 0;
        flags = this.apply_masked_value(flags, EContractFlags.CONTRACT_FLAG_DEV_CHECK_VIA_KERNEL, val);
        return flags;
    }

    private doAdd(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestKernelContract = {
            ContractID: null,
            ConnectorName : this.editee.ConnectorName,
            Machine : this.editee.Machine,
            WakeUpIP : this.editee.WakeUpIP,
            WakeUpPort : this.editee.WakeUpPort?? '',
            ConnectorType : this.editee.encodedType & 0xff,
            Flags : this.encode_flags(),
            SSLUsage: this.editee.SSLUsage,
            LicenseKey : null,  
            UserID: this.editee.UserID,
            Password : this.editee.Password,
            OrganizationID: this.editee.OrganizationID
        };
        return this.faxSrv.AddContract(def);
    }

    private doEdit(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestKernelContract = {
            ContractID: this.editee.ContractID,
            ConnectorName : this.editee.ConnectorName,
            Machine : this.editee.Machine,
            WakeUpIP : this.editee.WakeUpIP,
            WakeUpPort : this.editee.WakeUpPort?? '',
            ConnectorType : this.editee.encodedType & 0xff,
            Flags : this.encode_flags(),
            SSLUsage: this.editee.SSLUsage,
            UserID: this.editee.UserID,
            Password : this.editee.Password,
            OrganizationID: this.editee.OrganizationID
        };
        def.LicenseKey = (def.ConnectorType == this.editee.ConnectorType)? this.editee.LicenseKey: null
        return this.faxSrv.UpdateContract(def);
    }

    isValid(): boolean {
        let re_ip = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
        let re_host = /^[a-z\d]([a-z\d\-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d\-]{0,61}[a-z\d])?)*$/i;

        if (this.editee.ConnectorName && this.editee.Machine) {
            if (this.editee.WakeUpIP) {
                return (this.editee.WakeUpIP.match(re_ip) || this.editee.WakeUpIP.match(re_host))? true: false;
            } else if (this.isMobileProtocol()) {
                return true;    // WakeUpIP is not required for the Mobile protocols
            }
        }
        return false;
    }

    isDevice(encodedType: number): boolean {
        return (encodedType >> 8) === EContractFlags.CONTRACT_FLAG_DEVICE;
    }

    isMobileProtocol(): boolean {
        // WakeUpIP and WakeUpPort are optional for the Mobile device protocols
        const  devType: number = this.editee.encodedType & 0xff;
        return devType === EDeviceTypes.DEVICE_TYPE_MOBILE_CIMD ||
               devType === EDeviceTypes.DEVICE_TYPE_MOBILE_UCP  ||
               devType === EDeviceTypes.DEVICE_TYPE_MOBILE_OIS  ||
               devType === EDeviceTypes.DEVICE_TYPE_MOBILE_SMPP ;
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = null;
        if (this.mode == 'add') {
            sub = this.doAdd();
        }
        else if (this.mode == 'edit') {
            sub = this.doEdit();
        }
        sub?.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close(true);
                }
            },
            error: err => alert(err.message)
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
