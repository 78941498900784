import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestADGroup } from '../api/api';

export interface IAccountGroupEdit extends FaxConfigRestADGroup {
    OrigADGroup: string,
    IsNew: boolean,
    AllowedRoles: string[]
}

@Component({
    selector: 'app-account-group-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-group-edit.component.html',
    styleUrl: './account-group-edit.component.css'
})
export class AccountGroupEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IAccountGroupEdit;
    modified: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountGroupEdit }
    ) {
        this.editee = data.editee;
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let def: FaxConfigRestADGroup = {
            ADGroup: this.editee.ADGroup,
            Description: this.editee.Description,
            Role: this.editee.Role
        };
        if (this.editee.IsNew) {
            return this.faxSrv.PostADGroupsDefinition(def);
        } else {
            return this.faxSrv.PutADGroupsDefinition(this.editee.OrigADGroup, def);
        }
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = this.doSave();
        sub?.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
