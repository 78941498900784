import { Component, ViewChild, Inject, inject, AfterViewInit } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { IPaginationQuery } from '../pagination/pagination.component';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestAddressList, FaxConfigRestOrganizationDef } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component';
import _ from 'underscore';

export interface IJobRerouteScope {
    title: string;
    jobOrgId?: number;
    jobMsgType: string;
    jobRoutingCode: string;
}

interface IJobRerouteOrg {
    ID: number;
    Name: string;
}

interface IJobRerouteEditee {
    txtRoutingCode: string;
    selRoutingCode: string;
    currentOrg: IJobRerouteOrg | null;
    previousOrg: IJobRerouteOrg | null;
}

interface IJobRerouteFilter {
    AddrFilter: string;
    AddrType: string[];
    IncludeAttached?: boolean;
    IncludeUnattached?: boolean;
    Search?: string;
    OrganizationId?: number;
}

@Component({
    selector: 'app-queue-job-reroute',
    imports: [FormsModule, CommonModule, PaginationModule],
    templateUrl: './queue-job-reroute.component.html',
    styleUrl: './queue-job-reroute.component.css'
})
export class QueueJobRerouteComponent implements IPaginationQuery, AfterViewInit {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);

    @ViewChild(PaginationComponent)
    pagerRoutingCodes!: PaginationComponent;
    public queryRoutingCodes: IPaginationQuery = this;

    isReady: boolean = false;
    scope: IJobRerouteScope;
    editee: IJobRerouteEditee = {
        txtRoutingCode: '',
        selRoutingCode: '',
        currentOrg: null,
        previousOrg: null
    };
    lstRoutingCodes: FaxConfigRestAddressList = [];
    searchText: string = '';
    searchKeyRoutingCodes: string = '';
    txtRoutingCodeEnabled: boolean = false;
    orgHasNoNumbers: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialog: DialogService,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data.scope as IJobRerouteScope;
        this.session.rootPromises.subscribe(res => this.init());
    }

    init(): void {
        this.txtRoutingCodeEnabled = !this.session.usePhonenumberAdministration();
        if (!this.txtRoutingCodeEnabled) {
            this.editee.selRoutingCode = this.scope.jobRoutingCode;
        }
        if (this.session.isMultiTenant() && this.auth.isModifiable('All') && this.scope.jobOrgId === undefined ) {
            this.editee.currentOrg = { ID: this.session.currentUserOrgId?? 0, Name: this.session.currentUserOrgName?? '' };
        }
    }

    ngAfterViewInit(): void {
        this.pagerRoutingCodes!.reset();
    }

    searchClass(): string {
        if (this.editee.currentOrg) {
            // Alignment: organization select on the left, search box on the right
            return 'has-feedback pull-right';
        } else {
            // Alignment: search box on the left, no organization select
            return 'form-group has-feedback pull-left';
        }
    }

    pagerPadding(): object {
        if (this.editee.currentOrg) {
            // Alignment: search box on the right, pager controls underneath it
            return { height: '45px' };
        } else {
            // Alignment: search box on the left, pager controls on the right
            return { height: '15px' };
        }
    }

    selectOrganization(): void {
        if (this.editee) {
            let def: FaxConfigRestOrganizationDef | null = null;
            if (this.editee.currentOrg && this.editee.currentOrg.ID !== -1 && this.editee.currentOrg.ID !== 0) {
                def = { ID: this.editee.currentOrg.ID };
            }
            const dialogRef = this.dialog.open(OrganizationSelectComponent, { data: { editee: def }});
            dialogRef.afterClosed().subscribe(res => {
                if (res) this.changeOrganization(res.ID, res.Name);
            });
        }
    }

    changeOrganization(id: number, name: string): void {
        if (id !== this.editee.currentOrg?.ID) {
            this.editee.selRoutingCode = '';
            this.editee.txtRoutingCode = '';
            this.searchKeyRoutingCodes = '';
            this.searchText = '';
            if (this.editee.currentOrg && this.editee.currentOrg.ID !== -1 &&
                this.editee.currentOrg.ID !== this.session.currentUserOrgId)
            {
                this.editee.previousOrg = this.editee.currentOrg;
            }
            this.editee.currentOrg = { ID: id, Name: name };
            this.pagerRoutingCodes?.reset();
        }
    }

    activeOrganization(): string {
        return this.editee.currentOrg ? this.editee.currentOrg.Name : (this.session.currentUserOrgName?? '');
    }

    invoke(offset: number, limit: number): Observable<FaxConfigRestAddressList> {
        let subj = new AsyncSubject<FaxConfigRestAddressList>();
        if (this.txtRoutingCodeEnabled) {
            this.lstRoutingCodes = [];
            this.isReady = true;
            subj.next(this.lstRoutingCodes);    // return array as observable
            subj.complete();
        } else {
            let def: IJobRerouteFilter = {
                AddrFilter: 'Used',
                AddrType: ['DIR']
            };
            if (this.session.isMultiTenant()) {
                def.IncludeAttached = true;
                def.IncludeUnattached = false;
            }
            if (this.searchKeyRoutingCodes) {
                def.Search = this.searchKeyRoutingCodes.toLowerCase();
            }
            if (this.editee.currentOrg) {
                def.OrganizationId = this.editee.currentOrg.ID;
            } else if (this.scope.jobOrgId !== undefined) {
                def.OrganizationId = this.scope.jobOrgId;
            }
            if (this.scope.jobMsgType === 'FAX' || this.scope.jobMsgType === 'SDX') {
                def.AddrType.push('FAX');
            } else if (this.scope.jobMsgType === 'SMS') {
                def.AddrType.push('SMS');
            }
            this.faxSrv.GetAddresses(offset, limit, def).subscribe({
                next: numbers => {
                    this.lstRoutingCodes = numbers;
                    this.orgHasNoNumbers = (this.session.isMultiTenant() && this.lstRoutingCodes.length === 0 && !this.searchKeyRoutingCodes);
                    this.isReady = true;
                    subj.next(this.lstRoutingCodes);    // return array as observable
                    subj.complete();
                },
                error: err => subj.error(err)
            });
        }
        return subj;
    }

    searchRoutingCodes(): void {
        this.searchKeyRoutingCodes = this.searchText;
        this.pagerRoutingCodes?.reset();
    }

    submitEnabled(): boolean {
        if (this.txtRoutingCodeEnabled) {
            return this.editee.txtRoutingCode? true: false;
        } else if (this.editee.selRoutingCode && this.pagerRoutingCodes?.items) {
            // Enable the 'OK' button only if the current selection is in the (filtered) list.
            return _.any(this.pagerRoutingCodes.items, item => { return item.Address === this.editee.selRoutingCode; });
        }
        return false;
    }

    submit(): void {
        let retval: string = this.txtRoutingCodeEnabled ? this.editee.txtRoutingCode : this.editee.selRoutingCode;
        this.close(retval);
    }

    cancel() {
        this.close(null);
    }

    close(retval: string | null): void {
        this.dialogRef.close(retval);
    }
}
