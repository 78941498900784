import { Component } from '@angular/core';

@Component({
    selector: 'app-service-request',
    imports: [],
    templateUrl: './service-request.component.html',
    styleUrl: './service-request.component.css'
})

export class ServiceRequestComponent {
}
