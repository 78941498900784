<h1>Audit Log</h1>
<button type="button" class="btn pull-right" *ngIf="auth.isModifiable('AuditLog')" (click)="config()">Configure...</button>

<p id="auditLog_help">
    The Faxination audit log shows changes to the system, when the changes were made, which account made them, and their IP address.
	You can apply filters to the list by entering a value and pressing the &quot;Filter&quot; button. Click an item to view extended information.
	<span *ngIf="showAdvancedInfo">Some items contain related sub-items; if so click the plus icon to expand them.</span>
</p>

<form novalidate (submit)="refresh()" id="auditLog_form">
    <p>
        <label>From:&nbsp;</label>
        <input type="date" name="fromDate" placeholder="From date" size="10" [(ngModel)]="minDate" title="Enter a starting date like: &quot;2024-07-14&quot;">
        <span>&nbsp;to:&nbsp;</span>
        <input type="date" name="toDate" placeholder="To date" size="10" [(ngModel)]="maxDate" title="Enter an ending date like: &quot;2024-10-31&quot;">
        <span>&nbsp;</span>
        <button type="submit" class="btn" name="filter" title="Apply the date filters and refresh the list">Filter</button>
	</p>
</form>

<div *ngIf="gridInitialized" id="auditlogGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="gridOptions"></ag-grid-angular>
</div>
