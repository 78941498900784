import { Component, Inject, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestAccountBase, FaxConfigRestAccountValues } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";
import { PermissionSet } from '../constants/app.constants';

export interface IAccountEdit extends FaxConfigRestAccountBase {
    OrigUsername: string;
    NewPassword: string;
    Verify: string;
    IsSelf: boolean;
    IsAzureUser: boolean;
    IsEmailUsername: boolean;
    AllowedRoles: string[];
    Alias?: string | null;
    Domain?: string | null;
    AllowLocal?: boolean;
    AllowFenTenant?: boolean;
    AllowMultiTenant?: boolean;
    AllowSocial?: boolean;
}

@Component({
    selector: 'app-account-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-edit.component.html',
    styleUrl: './account-edit.component.css'
})
export class AccountEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    modified: boolean = false;
    editee: IAccountEdit;
    isAccountsModifiable: boolean;
    isOrganizationsViewable: boolean;
    mfaEnforced: boolean = false;
    mfaEnabled: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountEdit }
    ) {
        this.editee = data.editee;
        this.isAccountsModifiable = this.auth.isModifiable(PermissionSet.Accounts);
        this.isOrganizationsViewable = this.auth.isViewable(PermissionSet.Organizations);
        if (this.editee.IsEmailUsername && this.editee.Source === 'SQLDB') {
            this.faxSrv.GetAccountValues(this.editee.Username!).subscribe(res => {
                this.mfaEnforced = (res.MfaEnforced === true);
                this.mfaEnabled = this.mfaEnforced? true: (res.MfaEnabled?? false);
                this.isReady = true;
            });
        } else {
            this.isReady = true;
        }
    }

    private hasInvalidChars(name: string): boolean {
        return (
              name.indexOf('?') >= 0 ||
              name.indexOf('\\') >= 0 ||
              name.indexOf('/') >= 0
        );
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let def: FaxConfigRestAccountBase = {
            Enabled: this.editee.Enabled,
            Role: this.editee.Role,
            Department: this.editee.Department,
            Description: this.editee.Description
        };
        if (this.editee.IsAzureUser) {
            if (this.editee.Source !== 'AzureAD') {
                // You can change 'AzureAD_MultiTenant' and 'AzureAD_Social' usernames,
                // but doing so only modifies the value stored in the FmpAccounts table!
                if (this.editee.Username && this.editee.Username !== this.editee.OrigUsername) {
                    if (this.hasInvalidChars(this.editee.Username)) {
                        alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                        return null;
                    }
                    def.Username = this.editee.Username;
                }
            }
            return this.faxSrv.PutAccountsDefinition(this.editee.Source!, this.editee.OrigUsername, def);
        } else {
            if (this.editee.Username && this.editee.Username !== this.editee.OrigUsername) {
                if (this.hasInvalidChars(this.editee.Username)) {
                    alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                    return null;
                }
                def.Username = this.editee.Username;
            }
            if (this.editee.NewPassword) {
                def.Password = this.editee.NewPassword;
            }
            if (this.editee.IsSelf) {
                if (this.editee.Verify !== this.editee.NewPassword) {
                    alert('The verify password you entered does not match the new password.');
                    return null;
                }
                def.ValidationPassword = this.editee.Password;
            }
            return this.faxSrv.PutAccountsDefinition('SQLDB', this.editee.OrigUsername, def).pipe(map(res => {
                // Save MFA option if applicable
                if (res.Status === 'ok' && !this.mfaEnforced && this.editee.IsEmailUsername) {
                    let name = def.Username? def.Username: this.editee.OrigUsername;
                    let mfa: FaxConfigRestAccountValues = { MfaEnabled: this.mfaEnabled };
                    this.faxSrv.PutAccountValues(name, mfa).subscribe({
                        next: res => this.fenUtils.afterSave(res),
                        error: err => alert(err.message)
                    });
                }
                return res;
            }));
        }
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = this.doSave();
        sub?.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
