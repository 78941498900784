import { Component, Inject, inject } from '@angular/core';
import { Observable, forkJoin, finalize } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import {
    FaxConfigRestResult, FaxConfigRestConnectionInfoList, FaxConfigRestDataSourcesGeneral, FaxConfigRestDataSourceDef,
    FaxConfigRestUserResultList, FaxConfigRestUserResultItem, FaxConfigRestAddressRegistrationSettings,
    FaxConfigRestAddressRegistrationBatchDef, FaxConfigRestNewAddressRegistrationSettings
} from '../api/api';
import { isArray } from 'underscore';


interface ISearchResult {
    Name: string,
    AddrType: string,
    Address: string
}

interface IEditee {
    ID: number,
    AddrType: string,
    AddressStart: string,
    AddressEnd: string,
    ConnectionId?: number,
    ConnectionName?: string,
    Description?: string,
    UserAddress?: string,
    UserAddrType?: string,
    UserName?: string,
    SearchUser?: string,
    SearchResults?: ISearchResult[]
}

interface IScope {
    mode: string,
    editee: IEditee,
    Connections: FaxConfigRestConnectionInfoList,
    formattedConnectionName: string
}

@Component({
    selector: 'app-address-registration-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './address-registration-edit.component.html',
    styleUrl: './address-registration-edit.component.css'
})
export class AddressRegistrationEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    modified: boolean = false;
    operationPending: boolean = false;
    view: { batch: boolean, pick_user: boolean, search?: HTMLElement };
    validation: { error: string, count: number } = { error: '', count: 0 };

    Connections: FaxConfigRestConnectionInfoList = [];
    settingsDataSourcesGeneral: FaxConfigRestDataSourcesGeneral = {};
    dataSources: FaxConfigRestDataSourceDef[] = [];
    dataSource: FaxConfigRestDataSourceDef | null = null;

    headerText: string = '';
    rangeText: string = '';
    submitText: string = '';
    isDIRNumber: boolean = false;
    scope: IScope;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.isReady = false;
        this.scope = data as IScope;
        this.view = {
            batch: this.scope.editee.AddressEnd? true: false,
            pick_user : false
        }
        this.faxSrv.dataSources().subscribe({
            next: res => {
                this.dataSources = res;
                this.dataSource = (res.length > 0)? this.dataSources[0]: null;
                this.isReady = true;
                this.session.rootPromises.subscribe(() => this.init());
            },
            error: err => {
                alert(err.message?? 'An error occurred while getting the data sources.');
                this.close();
            }
        });
    }

    private init(): void {
        const addrTypeUpper = this.scope.editee.AddrType.toUpperCase();
        this.isDIRNumber = (addrTypeUpper === 'DIR');

        if (this.scope.mode == 'add') {
            this.headerText = 'Add ' + addrTypeUpper + ' number request(s)';
            this.rangeText = 'Any existing numbers within this range will not be touched.';
            this.submitText = 'Add';
        }
        else if (this.scope.mode == 'edit') {
            this.headerText = 'Edit ' + addrTypeUpper + ' number request(s)';
            this.rangeText = 'Any non-existing numbers within this range will be silently ignored.';
            this.submitText = 'Modify';
        }
        else if (this.scope.mode == 'view') {
            this.headerText = 'View ' + addrTypeUpper + ' number request(s)';
            this.rangeText = '';
            this.submitText = 'OK';
        }
        else {
            console.warn('unknown scope.mode');
        }

        if (this.scope.editee.UserAddress) {
            // flags is: 1=Names, 2=Addresses, 4=Routing codes, 8=Charge codes
            this.faxSrv.searchUsers('', this.scope.editee.UserAddress, 2).subscribe(res => {
                res.forEach((entry: FaxConfigRestUserResultItem) => {
                    if (entry.Address) {
                        let separator: number = entry.Address.indexOf(':');
                        if (separator >= 0 &&
                            this.scope.editee.UserAddrType === entry.Address.slice(0,separator) &&
                            this.scope.editee.UserAddress === entry.Address.slice(separator+1) )
                        {
                            this.scope.editee.UserName = entry.Name?? '';
                        }
                    }
                });
            });
        }

        let promises: {
            p1: Observable<FaxConfigRestConnectionInfoList>,
            p2: Observable<FaxConfigRestDataSourcesGeneral>
        } = {
            p1: this.faxSrv.GetConnections({ExcludeLoopback: true, IncludeGlobal: this.auth.isViewable('Organizations')}),
            p2: this.faxSrv.getDataSourcesGeneral()
        };

        forkJoin(promises)
            .pipe(finalize(() => {
                this.validate();
            }))
            .subscribe({
                next: (res) => {
                    this.Connections = isArray(res.p1)? res.p1: [];
                    this.settingsDataSourcesGeneral = res.p2;
                },
                error: (err) => {
                    alert(err.message);
                }
            });
    }

    doAdd(): Observable<FaxConfigRestResult> {
        if (!this.view.batch || !this.scope.editee.AddressEnd || this.scope.editee.AddressEnd.match(/^\s*$/) ) { // AddressEnd is empty or whitespace
            let def: FaxConfigRestNewAddressRegistrationSettings = {
                AddrType: this.scope.editee.AddrType,
                Address: this.scope.editee.AddressStart,
                Enabled: true,
                ConnectionId: this.scope.editee.ConnectionId!,
                Description: this.scope.editee.Description,
                OrganizationId: 0,
                UserAddress: this.scope.editee.UserAddress,
                UserAddrType: this.scope.editee.UserAddrType
            };
            return this.faxSrv.PostAddressRegistration(def);
        } else {
            let def: FaxConfigRestAddressRegistrationBatchDef = {
                AddrType: this.scope.editee.AddrType,
                AddressStart: this.scope.editee.AddressStart,
                AddressEnd: this.scope.editee.AddressEnd,
                Enabled: true,
                ConnectionId: this.scope.editee.ConnectionId,
                Description: this.scope.editee.Description,
                OrganizationId: null,
                UserAddress: this.scope.editee.UserAddress,
                UserAddrType: this.scope.editee.UserAddrType
            };
            return this.faxSrv.PostAddressRegistrationBatch(def);
        }
    }

    doEdit(): Observable<FaxConfigRestResult> {
        if (!this.view.batch || !this.scope.editee.AddressEnd || this.scope.editee.AddressEnd.match(/^\s*$/) ) { // AddressEnd is empty or whitespace
            let def: FaxConfigRestAddressRegistrationSettings = this.canEditConnectionDetails()? {
                AddrType: this.scope.editee.AddrType,
                Address: this.scope.editee.AddressStart,
                ConnectionId: this.scope.editee.ConnectionId,
                Description: this.scope.editee.Description
            } : {
                Description: this.scope.editee.Description,
            };
            if (this.session.currentOrgId !== -1) {
                def.UserAddress = this.scope.editee.UserAddress;
                def.UserAddrType = this.scope.editee.UserAddrType;
            }
            return this.faxSrv.PutAddressRegistration(this.scope.editee.ID, def);
        } else {
            let def: FaxConfigRestAddressRegistrationBatchDef = {
                AddrType: this.scope.editee.AddrType,
                AddressStart: this.scope.editee.AddressStart,
                AddressEnd: this.scope.editee.AddressEnd,
                ConnectionId: this.scope.editee.ConnectionId,
                Description: this.scope.editee.Description
            };
            if (this.session.currentOrgId !== -1) {
                def.UserAddress = this.scope.editee.UserAddress;
                def.UserAddrType = this.scope.editee.UserAddrType;
            }
            return this.faxSrv.PutAddressRegistrationBatch(def);
        }
    }

    canEditConnectionDetails(): boolean {
        return this.scope.mode == 'add' || (this.Connections && this.Connections.find((conn) => { return conn.ID === this.scope.editee.ConnectionId }) !== undefined);
    }

    validate(): void {
        if (this.isDIRNumber) {
            this.validation = this.validate_dir();
        } else {
            this.validation = this.validate_e164();
        }
    }

    validate_dir(): { error: string, count: number} {
        let regex = /^[0-9]([0-9] ?)*$/;
        if (!this.scope.editee.AddressStart || !this.scope.editee.AddressStart.match(regex)) {   // not allowed empty
            return { error: this.view.batch
                ? 'The start is not a valid number.'
                : 'The number is not valid.', count: 0 };
        }
        if (this.view.batch && this.scope.editee.AddressEnd) {  // allowed empty
            if (!this.scope.editee.AddressEnd.match(regex)) {   
                return { error: 'The end is not a valid number', count: 0 };
            } else if (this.scope.editee.AddressEnd.length != this.scope.editee.AddressStart.length) {
                return { error: 'The end number must have same length as start number.', count: 0 };
            } else {
                let nStart: number = parseInt(this.scope.editee.AddressStart.replace(/\D/g,''), 10);
                let nEnd: number   = parseInt(this.scope.editee.AddressEnd.replace(/\D/g,''), 10);

                let nStartHead = Math.floor(nStart / 10000);
                let nEndHead   = Math.floor(nEnd / 10000);
                let nStartTail = nStart % 10000;
                let nEndTail   = nEnd % 10000;
                
                if (nStartHead != nEndHead) {
                    return { error: 'The end number must differ from the start number only in the last 4 digits.', count: 0 };
                } else if (nEndTail < nStartTail) {
                    return { error: 'The end number must be larger than the start number.', count: 0 };
                }
                return { error: '', count: nEndTail - nStartTail + 1 };
            }
        } else {
            return { error: '', count: 1 };
        }
    }

    validate_e164(): { error: string, count: number } {
        let regexE164 = /^\+?([0-9] ?){5,15}$/;
        if (!this.scope.editee.AddressStart || !this.scope.editee.AddressStart.match(regexE164)) {   // not allowed empty
            return { error: this.view.batch
                        ? 'The start number is not in E.164 format, i.e. +19995550123'
                        : 'The phone number is not in E.164 format, i.e. +19995550123' , count: 0 };
        }
        if (this.view.batch && this.scope.editee.AddressEnd) {  // allowed empty
            if (!this.scope.editee.AddressEnd.match(regexE164)) {   
                return { error: 'The end number is not in E.164 format, i.e. +19995550123', count: 0 };
            } else if (this.scope.editee.AddressEnd.length != this.scope.editee.AddressStart.length) {
                return { error: 'The end number must have same length as start number.', count: 0 };
            } else {
                let nStart: number = parseInt(this.scope.editee.AddressStart.replace(/\D/g,''), 10);
                let nEnd: number   = parseInt(this.scope.editee.AddressEnd.replace(/\D/g,''), 10);

                let nStartHead = Math.floor(nStart / 10000);
                let nEndHead   = Math.floor(nEnd / 10000);
                let nStartTail = nStart % 10000;
                let nEndTail   = nEnd % 10000;
                
                if (nStartHead != nEndHead) {
                    return { error: 'The end number must differ from the start number only in the last 4 digits.', count: 0 };
                } else if (nEndTail < nStartTail) {
                    return { error: 'The end number must be larger than the start number.', count: 0 };
                }
                return { error: '', count: nEndTail - nStartTail + 1 };
            }
        } else {
            return { error: '', count: 1 };
        }
    }

    format_user(): string {
        return this.scope.editee.UserAddress?
            this.scope.editee.UserName? this.scope.editee.UserName + ' (' + this.scope.editee.UserAddress + ')':
            this.scope.editee.UserAddrType + ':' + this.scope.editee.UserAddress : "No user selected";
    }

    toggle_batch(): void { 
        this.view.batch = !this.view.batch;
        this.validate();
    }

    toggle_pick_user(): void { 
        this.view.pick_user =!this.view.pick_user;
        if (this.view.pick_user){
            let search = document.getElementById('pick_user_search');
            if (search && search != this.view.search) {
                this.view.search = search;
                search.addEventListener('input', this.search_users);
            }
        }
    }

    pick_user(entry: ISearchResult | null): void {
        if (entry === null){
            this.scope.editee.UserName ="No user selected";
            this.scope.editee.UserAddress = "";
            this.scope.editee.UserAddrType = "";
        } else {
            this.scope.editee.UserName = entry.Name;
            this.scope.editee.UserAddress = entry.Address;
            this.scope.editee.UserAddrType = entry.AddrType;
        }
        this.view.pick_user = false; // collapse section
    }

    submit(): void {
        this.validate();
        if (this.validation.error) {
            alert(this.validation.error);
            return;
        }
        this.operationPending = true;

        let onComplete = (res: FaxConfigRestResult): number => {
            this.operationPending = false;    
            return this.fenUtils.afterSave(res);
        };

        if (this.scope.mode == 'add') {
            this.doAdd().subscribe({
                next: res => {
                    if (onComplete(res) > 0) {
                        this.modified = true;
                        this.close();
                    }
                },
                error: res => {
                    onComplete(res);
                }
            });
        }
        else if (this.scope.mode == 'edit') {
            this.doEdit().subscribe({
                next: res => {
                    if (onComplete(res) > 0) {
                        this.modified = true;
                        this.close();
                    }
                },
                error: res => {
                    onComplete(res);
                }
            });
        }
        else {
            console.warn('unknown scope.mode');
            this.operationPending = false;
        }
    }

    dataSourceChanged = (ev: Event): void => {
        this.search_users(ev);
    }

    search_users = (ev: Event): void => {
        if (this.scope.editee.SearchUser && this.scope.editee.SearchUser.length > 1) {
            // Pre-assign results; this way last invocation will hold the active result-set. In case of multiple pending searches (when entereing multiple characters) 
            // the last resultset will be show. Otherwise the last completed invocation will be shown.
            let results: ISearchResult[] = [];
            this.scope.editee.SearchResults = results;
            let dataSourceName: string = this.dataSource? (this.dataSource.Name?? ''): '';
            // Names: 1 ; Addresses: 2; Routing codes: 4; Charge codes: 8;
            // searchUser returns address in format <AddrType>:<Address>
            this.faxSrv.searchUsers(dataSourceName, this.scope.editee.SearchUser, 11).subscribe((res: FaxConfigRestUserResultList) => {
                res.forEach((entry: FaxConfigRestUserResultItem) => {
                    if (entry.Address) {
                        let separator: number = entry.Address.indexOf(':');
                        if (separator >= 0) {
                            results.push({ Name: entry.Name?? '', AddrType: entry.Address.slice(0,separator), Address: entry.Address.slice(separator+1) });
                        }
                    }
                });
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
