import { Injectable } from '@angular/core';

export interface PortalConfigData{
    prefix: string | undefined;
    items_per_page: number| undefined;
    items_per_dialog: number| undefined;
    log_date_format: string| undefined;
    log_time_format: string| undefined;
}

@Injectable()
export class PortalConfig implements PortalConfigData{

    constructor() { 
    }
    prefix: string = '/FaxConfig/';
    items_per_page: number = 20;
    items_per_dialog: number = 15;;
    log_date_format: string = 'yyyy-MM-dd';
    log_time_format: string = 'HH:mm:ss';


}