import { CommonModule, NgFor } from '@angular/common';
import { Observable, forkJoin, finalize } from 'rxjs';
import { Component, inject, AfterViewInit, ViewChild} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { PaginationModule,PaginationComponent } from '../pagination/pagination.module';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestOrganizationDef, FaxConfigRestResult } from '../api/api';
import { BooleanPipe } from '../utils/boolean.pipe';
import { IPaginationQuery } from '../pagination/pagination.component';
import { DialogService } from '../dialog/dialog.service';
import { OrganizationEditComponent } from '../organization-edit/organization-edit.component';
import * as _ from 'underscore';

@Component({
    imports: [FormsModule, CommonModule, PaginationModule, NgFor, BooleanPipe],
    templateUrl: './organizations.component.html',
    styleUrl: './organizations.component.css'
})
export class OrganizationsComponent implements AfterViewInit, IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    pagerOrganizations!: PaginationComponent;
    public query_organizations: IPaginationQuery = this;

    searchkeyOrganizations: string = '';
    azureConfig = {};

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {}

    ngAfterViewInit()
    {
        this.refresh_azure_config();
        this.refresh_organizations();
    }

    get isReady(): boolean { return this.pagerOrganizations?.ready || false }

    refresh_organizations() {
        this.pagerOrganizations?.refresh();
    }

    refresh_azure_config() {
        this.faxSrv.getAzureADConfig().subscribe(res => {
            this.azureConfig = res;
        });
    }

    invoke(offset: number, limit: number) {
        let filter = {
            parent: null,
            search: this.searchkeyOrganizations
        };
        return this.faxSrv.GetOrganizationDefinitions(offset, limit, filter);
    }

    search_organizations(): void {
        this.pagerOrganizations?.reset();
    }

    new_organization(): void {
        const dialogRef = this.dialog.open(OrganizationEditComponent, {data: { azureConfig: this.azureConfig }});
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_organizations(); }
        });
    }

    edit_organization(item: FaxConfigRestOrganizationDef) {
        const dialogRef = this.dialog.open(OrganizationEditComponent, {data: { editee: item }});
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_organizations(); }
        });
    }

    can_remove_organization(): boolean {
        if (this.pagerOrganizations) {
            // Only allow to remove one organization at a time and it cannot be the system organization
            let sel = _.filter(this.pagerOrganizations.items, function (org) { return org.selected; });
            return sel.length === 1 && !sel[0].System;
        }
        return false;
    }

    remove_organization(): void {
        if (this.pagerOrganizations) {
            // Only allow to remove one organization at a time and it cannot be the system organization
            let sel: FaxConfigRestOrganizationDef[] = _.filter(this.pagerOrganizations.items, (org: FaxConfigRestOrganizationDef) => {
                return org.selected;
            });
            if (sel.length === 1 && sel[0].ID !== undefined && !sel[0].System) {
                let ok = confirm("You are about to permanently delete organization '" + sel[0].Name + "'.\n" +
                                    "Are you sure?");
                if (ok) {
                    this.faxSrv.DeleteOrganizationDefinition(sel[0].ID).subscribe({
                        next: res => {
                            if (this.fenUtils.afterSave(res) > 0) {
                                this.pagerOrganizations.refresh();
                            }
                        },
                        error: err => alert(err.message)
                    });
                }
            }
        }
    }
}
