import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewUserDefaultsComponent } from './new-user-defaults.component';

@NgModule({
    exports: [NewUserDefaultsComponent, CommonModule],
    imports: [NewUserDefaultsComponent, CommonModule]
})
export class NewUserDefaultsModule { }
export { NewUserDefaultsComponent };

