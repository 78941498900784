<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Send Connector '{{ conn.FriendlyName }}'</h1>
    </div>
    <form id="dkim_client_config_form" class="form" novalidate (submit)="submit()" #dkimClientConfigForm="ngForm">
        <div class="modal-body">
            <p>To configure this Send connector to add a DKIM signature to outgoing messages, <span *ngIf="!isModifiable">your Administrator</span>
            <span *ngIf="isModifiable">you</span> must create a public key and enter it as a TXT record in your DNS settings.
            The recipient can retrieve the public key from your Domain Name Server and check it against the DKIM signature in the message, to verify that
            the message was sent by the SMTP Stack and not altered in transit.  The DKIM signature includes your domain name and a selector name which
            identifies the specific DNS record containing this public key.</p>

            <div class="field">
                <label>Enable DKIM:</label>
                <input type="checkbox" name="DKIMEnabled" [(ngModel)]="editee.DKIMEnabled" [disabled]="!isModifiable"
                    title="Enable or disable the DKIM signing feature for all outgoing mail.">
            </div>
			<div class="field" *ngIf="editee.DKIMEnabled">
				<label>Domain name:</label>
				<input type="text" name="DKIMDomain" #DKIMDomain="ngModel" [(ngModel)]="editee.DKIMDomain" required [disabled]="!isModifiable"
                    title="Enter the domain name where this public key will be stored.">
                <span class="text-danger" *ngIf="isModifiable && !DKIMDomain.valid">&nbsp;Please enter a domain name.</span>
			</div>
			<div class="field" *ngIf="editee.DKIMEnabled">
				<label>Selector name:</label>
				<input type="text" name="DKIMSelector" [(ngModel)]="editee.DKIMSelector" required [disabled]="!isModifiable"
                    title="Enter a selector name to identify this public key record in DNS.">
                <span class="text-danger" *ngIf="isModifiable">&nbsp;{{ selectorWarning() }}</span>
			</div>
			<div class="field" *ngIf="editee.DKIMEnabled">
				<label>Full DNS path:</label>
				<label>{{ editee.DKIMSelector }}<span *ngIf="editee.DKIMSelector || editee.DKIMDomain">._domainkey.</span>{{ editee.DKIMDomain }}</label>
			</div>
            <br/>
            <div *ngIf="editee.DKIMEnabled">
                <label>Public Key:&nbsp;</label>
                <button type="button" class="btn" name="create" *ngIf="isModifiable" [disabled]="!editee.DKIMSelector || !editee.DKIMDomain" (click)="generateKey()" title="Create a new public key.">Create</button>
				<p>{{ selectorHelp }}</p>
                <p class="well">
                    <span *ngIf="!displayKey && isModifiable" class="text-muted">Click the 'Create' button to create a new public key.</span>
                    <span style="font-family:'Courier'" *ngFor="let line of displayKey">{{ line }}<br/></span>
                </p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn" (click)="validate()" *ngIf="editee.DKIMEnabled && isViewable" [disabled]="!validateEnabled()" title="Validate the proposed settings, but do not save them.">Validate</button>
            <button type="submit" class="btn btn-primary" *ngIf="isModifiable" [disabled]="!saveEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
