import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';

@Component({
    selector: 'app-navigation',
    imports: [CommonModule, RouterLink, RouterLinkActive],
    templateUrl: './navigation.component.html',
    styleUrl: './navigation.component.css'
})
export class NavigationComponent {
  constructor (public auth: AuthService, public session: Session) {}

}
