import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfirmPromptComponent } from '../confirm-prompt/confirm-prompt.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
    selector: 'app-address-admin-enable',
    imports: [FormsModule, CommonModule],
    templateUrl: './address-admin-enable.component.html',
    styleUrl: './address-admin-enable.component.css'
})
export class AddressAdminEnableComponent {

    isReady: boolean = true;
    scope: { onEnable: () => void };

    constructor(
        public auth: AuthService,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any,
        private dialog: DialogService
    ) {
        this.scope = data;
    }

    enablePhonenumAdminFeature(): void {
        const pThis = this;
        this.dialog.open(ConfirmPromptComponent, {
            data: {
                onOK: (): void => {
                    pThis.scope.onEnable();
                    pThis.close();
                },
                key_text: 'yes',
                caption:  'Enable phone number administration',
                message1: 'Are you sure you want to enable phone number administration?',
                message2: 'Enabling this feature is irreversible. Do you want to continue?'
            },
            anchorID: 'addressesAdminEnableBody'
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
