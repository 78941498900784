<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Recover your account</h1>
    </div>
    <form id="login_reset_form" class="form" name="resetForm" #resetForm="ngForm" (submit)="reset()" novalidate>
        <div class="modal-body">

            <p class="text">Enter your username and we will send you a link to reset your password.</p>
            <p class="text-danger">{{ message }}</p>

            <div class="field" *ngIf="!submitted">
                <label>Username:&nbsp;*</label>
                <span>
                    <input type="email" email maxlength="100" #name="ngModel" name="name" [(ngModel)]="editee.Username" required
                           title="Enter the username for the account. Must be in the form of an email address.">&ngsp;
                    <span class="text-danger" *ngIf="name.dirty && name.errors?.['required']">&nbsp;A username is required</span>
                    <span class="text-danger" *ngIf="name.dirty && name.errors?.['email']">&nbsp;Not a valid email address</span>
                </span>
            </div>

            <br />
            <div class="field" *ngIf="!submitted">
                <label></label>
                <span class="captcha">
                    <img [src]="image" />&ngsp;
                    <a href="" (click)="new_captcha()">Refresh</a>
                    <input type="hidden" name="challenge" [(ngModel)]="editee.CaptchaChallenge" />
                </span>
            </div>

            <div class="field" *ngIf="!submitted">
                <label>Captcha:&nbsp;*</label>
                <span>
                    <input type="text" name="answer" #answer="ngModel" [(ngModel)]="editee.CaptchaAnswer" required
                           title="Enter the characters visible in the image.">
                    <span class="text-danger" *ngIf="answer.dirty && answer.errors?.['required']">&nbsp;Please enter the characters from the image</span>
                </span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="!submitted" [disabled]="!resetForm.valid">Next</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
