import { Component, inject, Injector } from '@angular/core';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestAzureADConfig } from '../api/api';
import { AuthService } from '../services/auth.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Session } from '../services/session.service';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Base64 } from '../utils/base64';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { LoginAzure } from './login-azure';
import { LoginResetComponent } from '../login-reset/login-reset.component';
import { DialogService } from '../dialog/dialog.service';

@Component({
    selector: 'app-login',
    imports: [FormsModule, CommonModule],
    templateUrl: './login.component.html',
    styleUrl: './login.component.css'
})
export class LoginComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    private auth: AuthService = inject(AuthService);
    private session: Session = inject(Session);
    private router = inject(Router);

    error: string | undefined;
    isReady: boolean = false;
    userName: string = '';
    password: string = '';
    mfaPending: boolean = false;
    mfaCode: string = '';

    azureConfig: FaxConfigRestAzureADConfig | null = null;

    constructor(
        private injector: Injector,
        private dialog: DialogService
    ) {
        this.faxSrv.getAzureADConfig().subscribe(res => {
            this.isReady = true;
            this.azureConfig = res;
        });
    }

    get Enabled(): boolean {
        return this.azureConfig?.Enabled ?? false;
    }

    get Enforced(): boolean {
        return this.azureConfig?.Enforced ?? false;
    }

    loginError(stat?: number): void {
        this.mfaPending = false;
        if (stat === 403) {
            this.error = 'This account is temporarily locked due to multiple incorrect login attempts.  Please contact your accounts administrator.';
        } else {
            this.error = 'Access denied.  Please make sure that your username and password are correct, and that you have permission to access the portal.';
        }
    }

    loginErrorEx(error?: HttpErrorResponse): void {
        if (error?.status === 403) {
            let mfaStatus: string | null | undefined = error.headers?.get('X-Fen-MfaStatus');
            if (mfaStatus === 'pending') {
                // Awaiting MFA code confirmation
                this.mfaPending = true;
                this.mfaCode = '';
                this.error = '';
            } else if (mfaStatus) {
                // Other status message (MFA code is wrong, expired, etc.)
                this.error = mfaStatus;
            } else {
                // Backward compatibility; no status message means the account is locked.
                this.loginError(403);
            }
        } else {
            this.loginError();
        }
    }

    cancelMfa(): void {
        this.mfaPending = false;
        this.mfaCode = '';
        this.error = ''
    }

    login(f: NgForm): void {
        if (!f.valid) {
            this.loginError();      
            return;
        }
        if (this.azureConfig?.Enforced) {
            this.login_azure();
        } else {
            const codec: Base64 = new Base64();
            let headers: { [key:string]: string } = {
                'Authorization': 'Basic ' + codec.encode(this.userName + ':' + this.password)
            };
            if (this.mfaPending) {
                headers['X-Fen-MfaCode'] = this.mfaCode;
            }
            this.faxSrv.LOGIN<any>( 'Login?result=jwt', null, { headers: headers, observe: "events" }).pipe(
            catchError((error: HttpErrorResponse) => {
                this.loginErrorEx(error);
                return throwError(() => new Error('Authentication failed'));
            })).subscribe((res) => {
                switch(res.type) {
                    case HttpEventType.Response: {
                        if (res.status === 200) {
                            this.auth.setCredentials(this.userName, res.headers.get('x-fen-auth-jwt'));
                            this.auth.setOrganization(res.headers.get('x-fen-org-id') || 0, res.headers.get('x-fen-org-context') || 0);
                            this.auth.setRoleAccess(res.headers.get('x-fen-role')?? '', res.headers.get('x-fen-access')?? '', parseBoolean(res.headers.get('x-fen-adaccount')), '');
                            this.session.afterLogin();
                            this.session.currentUser = this.auth.getUserName();
                            this.session.currentUserOrgId = this.auth.getUserOrgId();
                            this.session.currentOrgId = this.auth.getContextOrgId();
                            this.session.currentUserOrgName = undefined;
                            this.session.currentOrgName = undefined;
                            this.session.prevOrgId = null;
                            this.session.prevOrgName = undefined;
                            this.session.reload(true).subscribe(res => {
                                if (res) { this.router.navigate(['/overview']); }
                            });
                        } else {
                            this.loginError(res.status);
                        }
                        break;
                    }
                }
            });
        }
    }

    login_azure(): void {
        this.mfaPending = false;
        if (this.azureConfig?.Enabled) {
            // Login via MSAL v3 with an Azure online account
            let azure: LoginAzure = new LoginAzure(this.injector);
            azure.acquireToken(this.azureConfig).subscribe({
                next: token => {
                    if (token) {
                        this.verify_azure_token(token);
                    } else {
                        this.loginError();
                    }
                },
                error: () => {
                    this.loginError();
                }
            });
        }
    }

    private verify_azure_token(token: string) {
        this.faxSrv.LOGIN<any>('AzureAD/Login?result=jwt', { Token: token }, { observe: "events" }).pipe(
        catchError((error: HttpErrorResponse) => {
            this.loginError(error.status)
            return throwError(() => new Error('Azure token verification failed'));
        })).subscribe((res) => {
            switch(res.type) {
                case HttpEventType.Response: {
                    if (res.status === 200) {
                        this.auth.setCredentials(res.headers.get('x-fen-azureupn'), res.headers.get('x-fen-auth-jwt'));
                        this.auth.setOrganization(res.headers.get('x-fen-org-id') || 0, res.headers.get('x-fen-org-context') || 0);
                        this.auth.setRoleAccess(res.headers.get('x-fen-role')?? '', res.headers.get('x-fen-access')?? '', parseBoolean(res.headers.get('x-fen-adaccount')), res.headers.get('x-fen-auth-source')?? '');
                        this.session.afterLogin();
                        this.session.currentUser = this.auth.getUserName();
                        this.session.currentUserOrgId = this.auth.getUserOrgId();
                        this.session.currentOrgId = this.auth.getContextOrgId();
                        this.session.currentUserOrgName = undefined;
                        this.session.currentOrgName = undefined;
                        this.session.prevOrgId = null;
                        this.session.prevOrgName = undefined;
                        this.session.reload(true).subscribe(res => {
                            if (res) { this.router.navigate(['/overview']); }
                        });
                    } else {
                        this.loginError(res.status);
                    }
                    break;
                }
            }
        });
    }

    login_reset(): boolean {
        this.mfaPending = false;
        const dialogRef = this.dialog.open(LoginResetComponent, {
            data: this.userName
        });
        dialogRef.afterClosed().subscribe();
        return false;
    }
}


export function parseBoolean(value: any): boolean {
    switch (value) {
       case true:
       case "true":
       case 1:
       case "1":
       case "on":
       case "yes":
           return true;
       default: 
           return false;
    }
}

