import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestSMTPStackClientDef, FaxConfigRestSMTPStackConnectorId } from '../api/api';
import * as _ from 'underscore';

export interface IDkimClientScope {
    conn: FaxConfigRestSMTPStackConnectorId;
    editee: FaxConfigRestSMTPStackClientDef;
}

@Component({
    selector: 'app-dkim-client-config',
    imports: [FormsModule, CommonModule],
    templateUrl: './dkim-client-config.component.html',
    styleUrl: './dkim-client-config.component.css'
})
export class DkimClientConfigComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    conn: FaxConfigRestSMTPStackConnectorId;
    editee: FaxConfigRestSMTPStackClientDef;
    isModifiable: boolean;
    isViewable: boolean;

    displayKey: RegExpMatchArray | null;
    wasValidated: boolean = false;
    generatedKeys: boolean = false;
    selectorHelp: string = '';
    origSelector: string;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IDkimClientScope
    ) {
        this.conn = data.conn;
        this.editee = data.editee;
        this.isModifiable = auth.isModifiable('Kernel Properties');
        this.isViewable = auth.isViewable('Kernel Properties');

        if (this.editee.DKIMPublicKey) {
            this.displayKey = this.editee.DKIMPublicKey.match(/.{1,64}/g);
        } else {
            this.displayKey = null;
        }
        this.origSelector = this.editee.DKIMSelector?? '';
    }

    generateKey(): void {
        let ok: boolean;
        if (!this.generatedKeys && this.editee.DKIMPublicKey) {
            ok = confirm('You are about to permanently replace the public key associated with this send connector, and you will need to update its TXT record in your DNS settings.  Are you sure?');
        } else {
            ok = true;
        }
        if (ok) {
            this.faxSrv.GetSMTPStackNewDKIMKeys().subscribe(res => {
                this.editee.DKIMPublicKey = res.DKIMPublicKey;
                this.editee.DKIMPrivateKey = res.DKIMPrivateKey;
                this.displayKey = this.editee.DKIMPublicKey!.match(/.{1,64}/g);
                this.generatedKeys = true;
                this.selectorHelp = "Copy and paste the following text into a new DNS TXT record at the path shown above, then press the 'Validate' button to test the configuration:";
            });
        }
    }

    validateEnabled(): boolean {
        if (this.editee.DKIMEnabled) {
            if (this.generatedKeys) {
                if (this.editee.DKIMSelector === this.origSelector) {
                    return false;	// must enter new selector
                }
            } else if (this.editee.DKIMSelector !== this.origSelector) {
                return false;	// must generate new public key
            }
            return (this.editee.DKIMDomain
                &&  this.editee.DKIMSelector
                &&  this.editee.DKIMPublicKey)? true: false;
        } else {
            return false;	// DKIM is disabled
        }
    };

    saveEnabled(): boolean {
        if (this.editee.DKIMEnabled) {
            return this.wasValidated;
        } else {
            return true;
        }
    }

    doSave(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestSMTPStackClientDef = {
            DKIMEnabled: this.editee.DKIMEnabled
        };
        if (def.DKIMEnabled) {
            def.DKIMDomain = this.editee.DKIMDomain;
            def.DKIMHeaders = this.editee.DKIMHeaders;
            def.DKIMSelector = this.editee.DKIMSelector;
            if (this.generatedKeys) {
                def.DKIMPublicKey = this.editee.DKIMPublicKey
                def.DKIMPrivateKey = this.editee.DKIMPrivateKey;
            }
        };
        return this.faxSrv.UpdateSMTPStackClient(this.conn.Identifier!, def);
    }

    submit(): void {
        if (!this.editee.DKIMEnabled || this.wasValidated) {
            this.doSave().subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close(true);
                }
            });
        }
    }

    selectorWarning(): string {
        if (!this.editee.DKIMSelector) {
            return 'Please enter a selector name';
        }
        if (this.generatedKeys) {
            if (this.editee.DKIMSelector === this.origSelector) {
                return 'Please enter a new selector name for the new public key';
            }
        } else if (this.editee.DKIMSelector !== this.origSelector) {
            return 'Please create a new public key for the new selector name';
        }
        return '';
    }

    validate(): void {
        let def: FaxConfigRestSMTPStackClientDef = {
            DKIMDomain: this.editee.DKIMDomain,
            DKIMHeaders: this.editee.DKIMHeaders,
            DKIMSelector: this.editee.DKIMSelector,
            DKIMPublicKey: this.editee.DKIMPublicKey
        };
        if (this.generatedKeys) {
            def.DKIMPrivateKey = this.editee.DKIMPrivateKey;
        }
        this.faxSrv.TestSMTPStackClientDKIM(this.conn.Identifier!, def).subscribe(res => {
            this.wasValidated = (res.Status === 'ok');
            if (res.Status === 'ok') {
                alert('The configuration and DNS lookup are OK.  Press Save to keep these settings.');
            } else if (res.Errors) {
                let msg = this.fenUtils.formatErrors(res.Errors);
                if (msg) alert(msg);
            } else {
                alert('There is an unknown problem with the current configuration.');
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
