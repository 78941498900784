import { Component, Inject, inject } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelAtnaCertificateData, FaxConfigRestKernelAtnaCertificateList,
    FaxConfigRestKernelAtnaCertificateStore, FaxConfigRestKernelAtnaCertificateStoreList,
    FaxConfigRestKernelAtnaLogging, FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";
import * as _ from 'underscore';

export interface IAtnaAddCertEditee {
    LocalMachine: string;
    bIsClientCert: boolean;
    Certificates: FaxConfigRestKernelAtnaCertificateList
}

@Component({
    selector: 'app-atna-add-cert',
    imports: [FormsModule, CommonModule],
    templateUrl: './atna-add-cert.component.html',
    styleUrl: './atna-add-cert.component.css'
})
export class AtnaAddCertComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    state = { activeRadio: 'upload' };

    uploadFile: File | null = null;
    uploadPassword: string = '';
    uploadPromise: Observable<string> | null = null;
    uploadEnabled: boolean = false;
    uploadActive: boolean = false;
    uploadFinished: boolean = false;

    editee: IAtnaAddCertEditee;
    AtnaStoreCerts: FaxConfigRestKernelAtnaCertificateStoreList = [];
    selectedStore: FaxConfigRestKernelAtnaCertificateStore | null = null;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAtnaAddCertEditee }
    ) {
        this.editee = data.editee;
        this.init();
    }

    private init(): void {
        this.faxSrv.GetAtnaStoreCertificates().subscribe(res => {
            this.AtnaStoreCerts = _.map(res, cert => {
                return _.extend({}, cert, { selected: false });
            });
            this.isReady = true;
        });
    }

    filechange(event: Event) {
        let target: HTMLInputElement = event.target as HTMLInputElement;
        this.uploadFile = target.files? target.files[0]: null;
        if (this.uploadFile) {
            this.uploadEnabled = true;
            this.uploadPromise = this.fileRead(this.uploadFile);
        }
    }

    private fileRead(file: File): Observable<string> {
        let subj = new AsyncSubject<string>();
        let reader = new FileReader();
        reader.onload = () => { subj.next(reader.result as string); };
        reader.onerror = () => { subj.next(reader.error?.message?? 'An unexpected error occurred.'); };
        reader.onabort = () => { subj.next(''); };
        reader.onloadend = () => { subj.complete(); };
        reader.readAsDataURL(file);
        return subj;
    }

    submitEnabled(): boolean {
        if (this.state.activeRadio == 'upload') {
            return this.uploadFile? true: false;
        }
        if (this.AtnaStoreCerts) {   // 'store'
            return _.some(this.AtnaStoreCerts, store => {
                if (store.Certificates) {
                    return _.some(store.Certificates, crt => { return crt.selected == true; });
                }
                return false;
            });
        }
        return false;
    }

    toggleStore(store: FaxConfigRestKernelAtnaCertificateStore): void {
        store.expanded = (store.expanded !== true);
    }

    selectStore(store: FaxConfigRestKernelAtnaCertificateStore): void {
        this.selectedStore = store;
    }

    nodeActionCssClass(store: FaxConfigRestKernelAtnaCertificateStore): string {
        return store.Certificates && store.Certificates.length === 0 ? 'glyphicon-none' : // no children
            store.expanded ? 'glyphicon-folder-open' : 'glyphicon-folder-close';
    }

    private doUpload(): Observable<FaxConfigRestResult | string> {
        let subj = new AsyncSubject<FaxConfigRestResult | string>();
        if (this.uploadPromise)
        {
            this.uploadPromise.subscribe({
                next: data => {
                    let def: FaxConfigRestKernelAtnaCertificateData = {
                        Filename: this.uploadFile!.name,
                        IsClientCertificate: this.editee.bIsClientCert,
                        Password: this.uploadPassword,
                        Base64data: data.split("base64,")[1]
                    };
                    this.uploadActive = true;
                    this.uploadEnabled = false;
                    this.faxSrv.PostAtnaCertificateFile(def).subscribe({
                        next: res => {
                            subj.next(res);
                            subj.complete();
                        },
                        error: res => {
                            subj.error(res);
                            subj.complete();
                        }
                    });
                },
                error: msg => {
                    subj.error(msg);
                    subj.complete();
                }
            });
        }
        else {
            subj.next('');
            subj.complete();
        }
        return subj;
    }

    private afterSubmit(res: FaxConfigRestResult | string) {
        this.uploadActive = false;
        if (_.isString(res)) {
            this.uploadEnabled = true;
            alert(res?? 'An unexpected error occurred.');
        } else {
            if (res.Status === 'ok') {
                this.uploadFinished = true;
                this.dialogRef.close(true);
            } else {
                this.fenUtils.afterSave(res);
                this.uploadEnabled = true;
            }
        }
    }

    save(): void {
        if (this.state.activeRadio == 'upload') {
            this.doUpload().subscribe({
                next: res => this.afterSubmit(res),
                error: res => this.afterSubmit(res)
            });
        } else if (this.AtnaStoreCerts) {    // 'store'
            let def: FaxConfigRestKernelAtnaLogging;
            if (this.editee.bIsClientCert) {
                def = { ClientCertificates: this.editee.Certificates };
            } else {
                def = { TrustedServerCertificates: this.editee.Certificates };
            }
            _.each(this.AtnaStoreCerts, store => {
                if (store.Certificates) {
                    let certs = _.filter(store.Certificates, crt => { return crt.selected === true; });
                    _.each(certs, crt => {
                        crt.selected = false;
                        if (this.editee.bIsClientCert) {
                            def.ClientCertificates!.push(crt);
                        } else {
                            def.TrustedServerCertificates!.push(crt);
                        }
                    });
                }
            });
            this.faxSrv.UpdateAtnaLogging(def).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close(true);
                }
            });
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
