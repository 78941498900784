<section id="monitoring_sender_sqllogger" class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="isModifiable">{{ title }} </span>{{editee.__type}} Sender</h1>
    </div>
    <form id="monitoring_form" class="form" novalidate (submit)="save()" name="monitoringForm" ng-disabled="!isModifiable('Faxination Server Monitor')">
        <div class="modal-body">

            <h3>General</h3>

            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" [(ngModel)]="editee.Enabled" name="Enabled" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
                title="Enable or disable this Sender.">
            </div>
            <div class="field">
                <label>Display Name:</label>
                <input type="text" [(ngModel)]="editee.DisplayName" name="DisplayName" size="40" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
                title="Enter a descriptive friendly name for this Sender definition.">
            </div>

            <h3>SQL Parameters</h3>

            <div class="field">
                <label>Source:</label>
                <input type="text" [(ngModel)]="editee.Source" name="Source" size="75" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="3"
                title="Enter the full SQL connection string.">
            </div>
            <div class="field">
                <label>Username:</label>
                <input type="text" [(ngModel)]="editee.Username" name="Username" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
                title="Enter the user name to use to authenticate with SQL.">
            </div>
            <div class="field">
                <label>Password:</label>
                <input type="password" autocomplete="off" [(ngModel)]="editee.Password" name="Password" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="5"
                title="Enter the password to use to authenticate with SQL.">&ngsp;
                <button type="button" class="btn" (click)="test()" [disabled]="!submitEnabled()"
                title="Test the database connection parameters">Test</button>
            </div>
        </div>

        <div class="monitoring_footer">
            <strong>Description</strong>
            <p id="monitoring_description" class="well">{{ Description }}</p>
            <div class="buttons">
                <button type="submit" class="btn btn-primary" *ngIf="isModifiable" [disabled]="!submitEnabled()">Save</button>
                <button type="button" class="btn" *ngIf="isModifiable" [disabled]="!submitEnabled()" (click)="apply()">Apply</button>
                <button type="button" class="btn" (click)="close()">Close</button>
            </div>
        </div>
    </form>
</section>
