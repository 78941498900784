<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="cancel()">&times;</button>
        <h1>{{ scope.title }}</h1>
        <div class="modal-subtitle" *ngIf="scope.showSubTitle">
            <div><span class="subtitle_label">Date: </span><span class="subtitle_value">{{ scope.logEntry.creationTime | date: scope.dateFormat }}, {{ scope.logEntry.creationTime | date: scope.timeFormat }}</span></div>
            <div *ngIf="fromDisplay(scope.logEntry) !== ''"><span class="subtitle_label">From:</span> <span class="subtitle_value">{{ fromDisplay(scope.logEntry) }}</span></div>
            <div *ngIf="toDisplay(scope.logEntry) !== ''"><span class="subtitle_label">To:</span> <span class="subtitle_value">{{ toDisplay(scope.logEntry) }}</span></div>
        </div>
    </div>
    <form id="log_entry_resubmit_form" class="form" (submit)="submit()" name="logEntryResubmitForm">
        <div class="modal-body" style="min-height: 400px;" >
            <ul class="nav nav-tabs">
                <li *ngFor="let tab of scope.tabs; let tabIdx = index" [class.active]="scope.selectedTab === tabIdx">
                    <a *ngIf="tab.custom || tab.fields.length > 0" href="" (click)="scope.selectedTab=tabIdx; scope.selectedTabTitle=tab.title; false">{{ tab.title }}</a>
                </li>
            </ul>

            <!-- General, From, To, Message, Transmission tab -->
            <div *ngIf="scope.selectedTabTitle === 'General' || scope.selectedTabTitle === 'From' || scope.selectedTabTitle === 'To' || scope.selectedTabTitle === 'Message' || scope.selectedTabTitle === 'Transmission'" >
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let f of scope.tabs[scope.selectedTab].fields">
                            <td>{{ $any(f).displayName }}</td>
                            <td [ngSwitch]="$any(f).kind">
                                <span *ngSwitchCase="'Field'" style="overflow-wrap:anywhere;">{{ $any(f).value }}</span>
                                <span *ngSwitchCase="'FileContent'"><a href="{{ getFileContentUrl($any(f).name) }}" target="_blank" rel="noopener noreferrer">{{ $any(f).displayName }}</a></span>
                                <span *ngSwitchCase="'BackupFile'"><a href="{{ getBackupFileUrl() }}" target="_blank" rel="noopener noreferrer">{{ $any(f).value }}</a></span> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Re-route tab -->
            <div [hidden]="scope.selectedTabTitle !== 'Re-route'">
                <div *ngIf="lstRoutingCodes.length === 0">
                    <p>Enter a new inbound routing code:</p>
                    <input type="text" name="txtRoutingCode" [(ngModel)]="editee.txtRoutingCode" [required]="lstRoutingCodes.length === 0" style="width: calc(100% - 15px);" >
                </div>
                <div [hidden]="lstRoutingCodes.length === 0">
                    <form role="form" (submit)="searchRoutingCodes()">
                        <div class="form-group has-feedback pull-left">
                            <i class="form-control-feedback glyphicon glyphicon-search" (click)="searchRoutingCodes()"></i>&ngsp;
                            <input type="text" class="form-control" name="searchKeyRoutingCodes" [(ngModel)]="searchKeyRoutingCodes" placeholder="Search" />
                        </div>
                        <div style="height: 15px;"></div>
                    </form>
                    <app-pagination [limit]="session.portalCfg.items_per_page" [query]="queryRoutingCodes"></app-pagination>
                    <table *ngIf="pagerIsReady" id="routing_code_list" class="table table-click table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th width="25px"></th>
                                <th width="100px">Number</th>
                                <th width="100px">Users</th>
                                <th width="100px" *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pagerRoutingCodes?.items" (click)="editee.selRoutingCode=item.Address">
                                <td><input type="radio" name="group" [ngModel]="editee.selRoutingCode" [value]="item.Address" [attr.value]="item.Address"></td>
                                <td>{{ item.Address }}</td>
                                <td>{{ item.UserNames }}</td>
                                <td *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')">{{ item.Description }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!pagerIsReady || pagerRoutingCodes.isEmpty()">No Results</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="scope.textSubmit"
                [disabled]="!submitEnabled()">{{ scope.textSubmit }}</button>
            <button type="button" class="btn" (click)="cancel()" *ngIf="scope.textCancel">{{ scope.textCancel }}</button>
        </div>
    </form>
</section>
