import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestAuditLogItem } from '../api/api';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, GridOptions, GridReadyEvent, GetContextMenuItemsParams,
    MenuItemDef, CsvExportParams, GetRowIdParams,
    IServerSideDatasource, IServerSideGetRowsParams, ITooltipParams, ICellRendererParams,
    IsServerSideGroupOpenByDefaultParams, AdvancedFilterModel,
    CellClickedEvent, LoadSuccessParams,
} from 'ag-grid-community';

import { DialogService } from '../dialog/dialog.service';
import { AuditlogConfigComponent, IAuditlogConfig } from '../auditlog-config/auditlog-config.component';
import { AuditlogItemComponent } from '../auditlog-item/auditlog-item.component';

@Component({
    selector: 'app-auditlog',
    imports: [FormsModule, CommonModule, AgGridAngular],
    providers: [DatePipe],
    templateUrl: './auditlog.component.html',
    styleUrl: './auditlog.component.css'
})
export class AuditlogComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    dateFormat: string = 'yyyy-MM-dd';
    showAdvancedInfo: boolean = false;
    minDate?: string;
    maxDate?: string;

    gridApi?: GridApi;
    gridOptions?: GridOptions;
    gridInitialized: boolean = false;
    cssGridHeight = { 'height': '300px' };

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private datePipe: DatePipe) {
        this.session.rootPromises.subscribe(() => this.auditlogInit());
    }

    toggleIconRenderer(params: ICellRendererParams): string {
        let img: string;
        if (this.showAdvancedInfo && params.node && params.data && params.data.Children) {
            if (params.node.expanded) {
                img = '<img src="icon-toggle.png" alt="collapse">';
            } else {
                img = '<img src="icon-toggle-expand.png" alt="expand">';
            }
        } else {
            img = '';
        }
        return '<span style="cursor: default;">' + img + '</span>';
    }

    dateValueGetter(params: ITooltipParams): string {
        return params.data? this.datePipe.transform(params.data.UTCTime, this.dateFormat)?? '': '';
    }

    columnDefs: ColDef<any>[] = [
        {
            field: 'OrganizationName',
            tooltipField: 'OrganizationName',
            headerName: 'Organization',
            headerTooltip: 'Organization',
            suppressFiltersToolPanel: true
        },
        {
            field: 'UserName',
            tooltipField: 'UserName',
            headerName: 'Account',
            headerTooltip: 'Account',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'Summary',
            tooltipField: 'Details',
            headerTooltip: 'Summary',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'UTCTime',
            tooltipValueGetter: params => this.dateValueGetter(params),
            headerName: 'Date',
            headerTooltip: 'Date',
            valueGetter: (params: any) => this.dateValueGetter(params)
        },
        {
            field: 'ClientIP',
            tooltipField: 'ClientIP',
            headerName: 'IP Address',
            headerTooltip: 'IP Address',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        }
    ];

    forceColumn(field: string, show: boolean): void {
        let col = _.find(this.columnDefs, item => { return item.field === field; });
        if (col) {
            col.suppressColumnsToolPanel = true;
            col.hide = !show;
        }
    }

    auditlogInit(): void {
        this.gridOptions = {
            columnDefs: [], // wait until rootPromises are resolved
            defaultColDef: {
                flex: 1,
                sortable: false,
                resizable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            autoGroupColumnDef: {
                headerName: '',
                flex: 1,
                sortable: false,
                resizable: false,
                lockPosition: 'left',
                minWidth: 52,
                maxWidth: 52,
                suppressHeaderMenuButton: true,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
                suppressSizeToFit: true,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                cellRenderer: (params: ICellRendererParams) => this.toggleIconRenderer(params)
            },
            defaultCsvExportParams: { allColumns: true, fileName: 'export_auditlog.csv' },
            rowModelType: 'serverSide',
            treeData: true,
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'single',
            suppressCsvExport: false,
            suppressExcelExport: true,
            onGridReady: (params: GridReadyEvent) => {
                this.gridApi = params.api;
                let height = Math.max(300, window.innerHeight - 50);
                this.cssGridHeight = { 'height': height + 'px' };
                let role = this.auth.getRole();
                this.showAdvancedInfo = (role === 'Global Administrator' || role === "Service Administrator");
                params.api.setGridOption('serverSideDatasource', this.myDataSource);
                // Show/hide various columns based on context.
                this.forceColumn('OrganizationName', this.session.contextAllOrganizations());
                params.api.setGridOption('columnDefs', this.columnDefs);
                this.auditlogLoadViewState(params.api);
                this.isReady = true;
            },
            rowClassRules: { 'log-child-entry': 'node.level > 0' },
            getRowId: params => this.cbGetRowId(params),
            onCellClicked: params => this.cbOnCellClicked(params),
            getContextMenuItems: params => this.cbGetContextMenuItems(params),
            isServerSideGroupOpenByDefault: params => this.cbIsServerSideGroupOpenByDefault(params),
            isServerSideGroup: (dataItem: FaxConfigRestAuditLogItem) => this.cbIsServerSideGroup(dataItem),
            getServerSideGroupKey: (dataItem: FaxConfigRestAuditLogItem) => this.cbGetServerSideGroupKey(dataItem),
            onGridPreDestroyed: params =>this.cbSaveViewState(params.api)
        };
        this.gridInitialized = true;
    }

    myDataSource: IServerSideDatasource = {
        getRows: params => this.auditlogGetRows(params)
    };

    private auditlogGetRows(params: IServerSideGetRowsParams): void {
        let filter: any;
        let local: Date;
        let successData: LoadSuccessParams;
        let count = params.request.endRow! - params.request.startRow!;

        if (params.request.groupKeys && params.request.groupKeys.length > 0) {
            // Get child entries from server using parent Primary Key
            filter = { ParentID: params.request.groupKeys[0] };
            this.faxSrv.GetAuditLogItems(0, 10, filter).subscribe({
                next: res => {
                    successData = { rowData: res.Results?? [] };
                    if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                        successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                    }
                    params.success(successData);
                },
                error: () => {
                    alert('Cannot get audit log entries; probably the network connection was closed.');
                    params.fail();
                }
            });
        } else {
            // Get only parent entries from server, with filtering
            filter = { ParentID: 'null' };

            if (this.minDate) {
                // Assume the chosen date was local, and convert to UTC.
                local = new Date(this.minDate);
                filter.MinTime = new Date(local.getFullYear(), local.getMonth(), local.getDate()).toISOString();
            }
            if (this.maxDate) {
                // The backend expects an exclusive range like: MinTime up to (but not including) MaxTime.
                // The date picker is only accurate to the day, so add 24 hours to get a proper MaxTime value.
                local = new Date(this.maxDate);
                local.setTime(local.getTime() + (24 * 60 * 60 * 1000));
                filter.MaxTime = new Date(local.getFullYear(), local.getMonth(), local.getDate()).toISOString();
            }

            if (params.request.filterModel) {
                _.each(params.request.filterModel, (model: AdvancedFilterModel, key: string) => {
                    if (model.filterType === 'text' && model.filter) {
                        filter[key] = model.filter;
                    }
                });
            }

            this.faxSrv.GetAuditLogItems(params.request.startRow!, count, filter).subscribe({
                next: res => {
                    successData = { rowData: res.Results?? [] };
                    if (!res.Results || res.Results.length < count || !res.searchInfo!.NextRange) {
                        successData.rowCount = params.request.startRow! + (res.Results?.length?? 0);
                    }
                    params.success(successData);
                },
                error: () => {
                    alert('Cannot get audit log entries; probably the network connection was closed.');
                    params.fail();
                }
            });
        }
    }

    auditlogLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'AuditLogViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Ignore the 'hide' property for columns that are forced.
                        val = _.map(val, item => {
                            let def = _.find(this.columnDefs, col => { return col.field === item.colId; });
                            if (def && def.hasOwnProperty('suppressColumnsToolPanel')) {
                                return _.omit(item, 'hide');
                            }
                            return item;
                        });
                        // Do not apply this state unless at least one column is visible!
                        if (_.find(val, col => { return col.colId !== 'ag-Grid-AutoColumn' && col.hide === false; })) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    cbSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'AuditLogViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    private cbGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        return ['copy', 'separator',
            {
                name: 'CSV Export',
                action: () => { this.export(); },
                disabled: false
            }
        ];
    }

    refresh(): void {
        this.gridApi?.refreshServerSide({purge: true});
    }

    cbOnCellClicked(params: CellClickedEvent): void {
        if (params.column.getColId() === 'ag-Grid-AutoColumn') {
            if (this.showAdvancedInfo && params.data && params.data.Children) {
                params.node.setExpanded(!params.node.expanded);
                params.api.refreshCells({ rowNodes: [params.node], force: true });
            }
        } else if (params.data) {
            this.view(params.data);
        }
    }

    cbIsServerSideGroup(data: FaxConfigRestAuditLogItem): boolean {
        return (data && data.Children)? true: false;
    }

    cbIsServerSideGroupOpenByDefault(params: IsServerSideGroupOpenByDefaultParams): boolean {
        return false;
    }

    cbGetServerSideGroupKey(data: FaxConfigRestAuditLogItem): string {
        return ('' + data.ID!);
    }

    cbGetRowId(params: GetRowIdParams<FaxConfigRestAuditLogItem>): string {
        return ('' + params.data.ID!);
    }

    view(item: FaxConfigRestAuditLogItem): void {
        let disp = (_item: FaxConfigRestAuditLogItem) => {
            let items = _item.ChildItems ? [_item].concat(_item.ChildItems): [_item];
            const dialogRef = this.dialog.open(AuditlogItemComponent, {
                data: { editee: items, showAdvancedInfo: this.showAdvancedInfo }
            });
            dialogRef.afterClosed().subscribe();

        }
        if (this.showAdvancedInfo && item.Children && !item.ChildItems) {
            this.faxSrv.GetAuditLogItems(0, 10, { ParentID: item.ID }).subscribe(res => {
                item.ChildItems = res.Results;
                disp(item);
            });
        } else {
            disp(item);
        } 
    };

    export(): void {
        if (this.gridApi) {
            let params: CsvExportParams = {
                columnKeys: _.pluck(this.columnDefs, 'field') as string[],
                fileName: 'export_auditlog.csv'
            };
            if (!this.session.isMultiTenant()) {
                params.columnKeys = _.without(params.columnKeys!, 'OrganizationName');
            }
            this.gridApi.exportDataAsCsv(params);
        }
    }

    config(): void {
        this.faxSrv.GetAuditLogDBConfig().subscribe(res => {
            // Actual response does not return DBPassword, and DBVerifyPassword is only for UI.
            let config: IAuditlogConfig = {
                DBSource: res.DBSource?? '',
                DBUsername: res.DBUsername?? '',
                DBPassword: '',
                DBVerifyPassword: '',
                IsUsingDefaults: res.IsUsingDefaults?? false,
                Enabled: res.Enabled?? false
            };
            const dialogRef = this.dialog.open(AuditlogConfigComponent, {data: { editee: config }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.refresh(); }
            });

        });
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }
}
