import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049052 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Fenestrae )_is_granted_a_( Single Application )_Developer_License_for_the_application_( FFC )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( FFC )_need_to_be_licensed___( FFC )_has_been_granted_a_Deployment_License_Add-on_for_( 15 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 November 2024 )____[v2]_MTczMTE5NjgwMDAwMA==ddae8a48f7378db721f09c6334ff78c3")

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
