<div class="template_wrapper" (click)="onWrapperClick($event.target)">
    <input type="hidden" class="template_item_style">
    <div class="template_inner">
    </div>
    <div class="template_list">
        <ul class="list hide">
            <li *ngFor="let o of options" [class.selected]="o.Selected?? false">
                <div *ngIf="o.Group" class="group">{{ o.Group }}</div>
                <div *ngIf="!o.Group" (click)="select(o)">&nbsp;&nbsp;{{ o.Display }}</div>
            </li>
        </ul>
    </div>
</div>
