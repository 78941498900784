import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FaxConfigApi } from '../api/faxconfig';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FaxConfigRestResetDef, FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-login-reset',
    imports: [FormsModule, CommonModule],
    templateUrl: './login-reset.component.html',
    styleUrl: './login-reset.component.css'
})
export class LoginResetComponent {
    private session: Session = inject(Session);
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    message: string = '';
    submitted: boolean = false;
    editee: FaxConfigRestResetDef;
    image: SafeResourceUrl = '';

    constructor(
        private sanitizer: DomSanitizer,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: string
    ) {
        this.editee = {
            Username: data,
            CaptchaChallenge: '',
            CaptchaAnswer: ''
        };
        this.session.rootPromises.subscribe(() => {
            this.new_captcha();
            this.isReady = true;
        });
    }

    new_captcha(): boolean {
        this.faxSrv.getCaptcha().subscribe(res => {
            if (res.Status === 'ok') {
                this.editee.CaptchaChallenge = res.Challenge?? '';
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + res.Image);
            }
        });
        return false;
    }

    reset(): void {
        this.faxSrv.postLoginReset(this.editee).subscribe({
            next: (res: FaxConfigRestResult) => {
                this.afterReset(res);
            },
            error: () => {
                this.afterReset();
            }
        });
    }

    private afterReset(res?: FaxConfigRestResult) {
        this.submitted = true;
        if (res?.Status === 'ok') {
            this.message = 'If the specified address was recognized, then an email containing a reset link and further instructions has been sent to it.';
        } else if (res?.Errors) {
            this.message = this.fenUtils.formatErrors(res.Errors);
        } else {
            this.message = 'Unable to contact the server at this moment. Please try again later.';
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
