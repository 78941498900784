<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ headerText }}</h1>
    </div>
    <form id="address_registration_batch_edit_form" class="form" autocomplete="off" novalidate (submit)="submit()" name="addressRegistrationBatchEditForm">
        <div class="modal-body" *ngIf="isReady">
            <p *ngIf="scope.mode!=='view'">
                To work on a batch of numbers, enter the start and end {{ isDIRNumber? 'DIR number': 'phone number' }} of the batch.
                <br />
                To work on a single number, just enter a start number.
            </p>
            <div class="pull-right" *ngIf="scope.mode!=='view' && canEditConnectionDetails()">
                <img src="icon-toggle-expand.png" *ngIf="!view.batch" (click)="toggle_batch()" title="Toggle between single address-entry and address-range"/>
                <img src="icon-toggle.png" *ngIf="view.batch" (click)="toggle_batch()" title="Toggle between single address-entry and address-range"/>
            </div>
            <div class="field_group" *ngIf="view.batch">
                <div class="field">
                    <label>Start number:&nbsp;*</label>
                    <input type="text" maxlength="150" [(ngModel)]="scope.editee.AddressStart" size="40"
                        name="addressStart" [disabled]="scope.mode==='view'" (ngModelChange)="validate()"
                        title="The start of the {{ isDIRNumber? 'DIR number': 'phone number' }} range.">
                </div>
                <div class="field">
                    <label>End number:</label>
                    <input type="text" maxlength="150" [(ngModel)]="scope.editee.AddressEnd" size="40"
                        name="addressEnd" [disabled]="scope.mode==='view'" (ngModelChange)="validate()"
                        title="The end of the {{ isDIRNumber? 'DIR number': 'phone number' }} range, or leave empty for a single number.">
                </div>
                <div class="field">
                    <span class="text-danger" *ngIf="validation.error">{{ validation.error }}</span>
                    <span class="text-info" *ngIf="!validation.error">{{ validation.count }} number(s)
                        potentially in the batch range.</span>
                </div>
                <p style="white-space: pre-wrap">{{ rangeText }}</p>
            </div>
            <div class="field_group" *ngIf="!view.batch">
                <div class="field">
                    <label>{{ isDIRNumber? 'DIR number': 'Phone number' }}:&nbsp;*</label>
                    <input type="text" maxlength="150" *ngIf="canEditConnectionDetails()" [(ngModel)]="scope.editee.AddressStart" size="40"
                        name="phoneNumber" [disabled]="scope.mode==='view'" (ngModelChange)="validate()"
                        title="The {{ isDIRNumber? 'DIR number': 'phone number' }}." />
                    <span *ngIf="!canEditConnectionDetails()">{{ scope.editee.AddressStart }}</span>
                </div>
                <div class="field">
                    <span class="text-danger" *ngIf="validation.error">{{ validation.error }}</span>
                </div>
            </div>
            <br />
            <div class="field">
                <label>Organization:</label>
                <span>{{ session.currentOrgName }}</span>
            </div>
            <div class="field">
                <label>Connection:&nbsp;*</label>
                <select *ngIf="canEditConnectionDetails()" [disabled]="scope.mode==='view'" name="connections" [(ngModel)]="scope.editee.ConnectionId">
                    <option *ngFor="let p of Connections" [value]="p.ID">{{ p.ConnectionName }}</option>
                </select>
                <span *ngIf="!canEditConnectionDetails()">{{ scope.editee.ConnectionName }}</span>
            </div>
            <div class="field" *ngIf="(!Connections || Connections.length<=1) && scope.mode!=='view' && auth.isModifiable('Addresses')">
                <span class="text-info">Add providers via the 'Configure' button on the Phone Numbers page.</span>
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" [disabled]="scope.mode==='view'" name="description" maxlength="500" [(ngModel)]="scope.editee.Description" size="40" title="An optional description"/>
            </div>
            <div class="pull-right" *ngIf="scope.mode!=='view' && session.currentOrgId!==-1">
                <img src="icon-toggle-expand.png" *ngIf="!view.pick_user" (click)="toggle_pick_user()" title="Toggle between single address-entry and address-range"/>
                <img src="icon-toggle.png" *ngIf="view.pick_user" (click)="toggle_pick_user()" title="Toggle between single address-entry and address-range"/>
            </div>
            <div class="field" *ngIf="session.currentOrgId!==-1" (click)="toggle_pick_user()" >
                <label>User:</label><span>{{ format_user() }}</span>
            </div>
            <div class="field_group" *ngIf="scope.mode!=='view' && session.currentOrgId!==-1" [hidden]="!view.pick_user">
                <div class="field" *ngIf="dataSources.length > 1">
                    <label>Data Source:</label>
                    <select name="dataSources" [(ngModel)]="dataSource" (ngModelChange)="dataSourceChanged($event)">
                        <option *ngFor="let system of dataSources" [ngValue]="system">{{ system.Name }}</option>
                    </select>
                </div>
                <div class="field">
                    <label>Search user:</label>
                    <input type="text" id="pick_user_search" name="pickUserSearch" maxlength="500" autocomplete="off"
                        [(ngModel)]="scope.editee.SearchUser" size="40" title="Enter a part of the name or address of the user"
                        placeholder="Enter a part of the name or address" />
                </div>
                <div class="field">
                    <label>Search results:</label>
                    <div class="pick_user_box">
                        <ul class="pick_user_box">
                            <li (click)="pick_user(null)">No user selected</li>
                            <li *ngFor="let entry of scope.editee.SearchResults" (click)="pick_user(entry)">{{ entry.Name }} - {{ entry.AddrType }}:{{ entry.Address }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="scope.mode!=='view'"
                [disabled]="validation.error || ((scope.mode=='add' || scope.mode=='edit') && scope.editee.ConnectionId==-1) || operationPending"
                >{{ submitText }}</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
