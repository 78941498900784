import { CommonModule, NgFor } from '@angular/common';
import { Observable, forkJoin, finalize } from 'rxjs';
import { Component, inject, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestECRDefList, FaxConfigRestECRDef, FaxConfigRestECRRuleList, FaxConfigRestECRRule } from '../api/api';
import { BooleanPipe } from '../utils/boolean.pipe';
import { IPaginationQuery } from '../pagination/pagination.component';
import { EcrRuleComponent, IEditee } from '../ecr-rule/ecr-rule.component';
import { DialogService } from '../dialog/dialog.service';
import * as _ from 'underscore';

@Component({
    selector: 'app-ecr-list',
    imports: [FormsModule, CommonModule, PaginationModule, NgFor, BooleanPipe],
    templateUrl: './ecr.component.html',
    styleUrl: './ecr.component.css'
})
export class EcrComponent implements AfterViewInit, IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    pager!: PaginationComponent;
    public query: IPaginationQuery = this;

    public ecrDefinitions: FaxConfigRestECRDefList = [];
    public ecrDefinition?: FaxConfigRestECRDef;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.ecrDefinitionsRefresh();
    }

    ngAfterViewInit() {
        this.pager?.reset();
    }

    invoke(offset: number, limit: number) {
        return this.faxSrv.ecrRules(this.ecrDefinition?.Index || 1, offset, limit);
    }

    private ecrDefinitionsRefresh(): void {
        this.faxSrv.ecrDefinitions().subscribe(ecrDefs => {
            this.ecrDefinitions = _.sortBy(ecrDefs, 'Index');
            if (this.ecrDefinitions.length > 0) {
                if (this.ecrDefinition) {
                    let found = _.find(this.ecrDefinitions, item => {
                        return (item.Index === this.ecrDefinition!.Index);
                    });
                    this.ecrDefinition = found?? this.ecrDefinitions[0];
                } else {
                    this.ecrDefinition = this.ecrDefinitions[0];
                }
            } else {
                this.ecrDefinition = undefined;
            }
        });
    }

    ecrDefinitionDisplay(def: FaxConfigRestECRDef): string {
        return (def.Enabled? '\u2714 ': '\u2717 ') + def.DisplayName;
    }

    onEcrDefinitionChange() {
        this.pager?.refresh();
    }

    onEcrDefinitionEnable() {
        if (this.ecrDefinition) {
            let def: FaxConfigRestECRDef = {
                Index: this.ecrDefinition.Index,
                Enabled: this.ecrDefinition.Enabled
            };
            this.faxSrv.updateEcrDefinition(def).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) this.ecrDefinitionsRefresh();
                },
                error: err => { alert(err.message); }
            });
        }
    }

    neo() {
        if (this.ecrDefinition?.Index) {
            let editee: IEditee = { index: this.ecrDefinition.Index, rule: null };
            const dialogRef = this.dialog.open(EcrRuleComponent, {
                data: { editee: editee }
            });
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.pager.refresh(); }
            });
        }
    }

    private updateEnabled(enabled: boolean): void {
        if (this.pager && this.ecrDefinition) {
            let index: number = this.ecrDefinition.Index!;
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.pager.items, (rule: FaxConfigRestECRRule) => {
                if (rule.selected && rule.RuleKey !== undefined) {
                    let def: FaxConfigRestECRRule = _.extend({}, rule, { RuleEnabled: enabled });
                    promises.push(this.faxSrv.updateEcrRule(index, rule.RuleKey, def));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) { this.pager.refresh(); }
                },
                error: err => alert(err.message)
            });
            }
        }
    }

    enable()  { this.updateEnabled(true);  }
    disable() { this.updateEnabled(false); }

    edit(rule: FaxConfigRestECRRule) {
        if (this.ecrDefinition?.Index) {
            let editee: IEditee = { index: this.ecrDefinition.Index, rule: rule };
            const dialogRef = this.dialog.open(EcrRuleComponent, {
                data: { editee: editee }
            });
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.pager.refresh(); }
            });
        }
    }

    remove() {
        if (this.pager && this.ecrDefinition) {
            let index: number = this.ecrDefinition.Index!;
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.pager.items, (rule: FaxConfigRestECRRule) => {
                if (rule.selected && rule.RuleKey !== undefined) {
                    promises.push(this.faxSrv.deleteEcrRule(index, rule.RuleKey));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) { this.pager.refresh(); }
                    },
                    error: err => alert(err.message)
                });
            }
        }
    }
}
