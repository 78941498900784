import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FaxConfigRestAzureADConfig } from '../api/api';
import { AsyncSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest, EventMessage, EventType, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

@Injectable({providedIn: 'root'})
export class LoginAzure implements OnDestroy {
    private msalService: MsalService;
    private msalBroadcastService: MsalBroadcastService;

    azureConfig: FaxConfigRestAzureADConfig | null = null;
    azureEvents: Subscription | null = null;

    constructor(private injector: Injector) {
        this.msalService = injector.get(MsalService);
        this.msalBroadcastService = injector.get(MsalBroadcastService);

        // Subscribe to successful Azure login events to set the active account
        this.azureEvents = this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
            const payload = result.payload as AuthenticationResult;
            this.msalService?.instance.setActiveAccount(payload.account);
        });
    }

    ngOnDestroy(): void {
        this.azureEvents?.unsubscribe();
    }

    acquireToken(azureCfg: FaxConfigRestAzureADConfig): Observable<string> {
        this.azureConfig = azureCfg;
        let subj = new AsyncSubject<string>();

        if (this.azureConfig.AllowFenTenant) {
            this.login_azure1_fentenant(subj);
        } else if (this.azureConfig.AllowMultiTenant) {
            this.login_azure2_multitenant(subj);
        } else if (this.azureConfig.AllowSocial) {
            this.login_azure2_social(subj);
        } else {
            subj.next('');
            subj.complete();
        }
        return subj;
    }

    private login_azure1_fentenant(subj: AsyncSubject<string>): void {
        // Login with a Fenestrae account on Microsoft online
        if (this.azureConfig?.ClientID && this.azureConfig.Tenant) {
            this.msalService.instance = new PublicClientApplication({
                auth: {
                    clientId: this.azureConfig.ClientID,
                    authority: 'https://login.microsoftonline.com/' + this.azureConfig.Tenant + '/',
                    redirectUri: window.location.href.split("?")[0].split("#")[0]
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage
                },
                system: {
                    allowPlatformBroker: false    // Disables WAM Broker, which only exists on Windows
                }
            });
            this.msalService.initialize().subscribe({
                next: () => {
                    let req: PopupRequest = {
                        scopes: ['https://graph.windows.net/.default'],
                        prompt: 'select_account',
                    };
                    this.msalService!.acquireTokenPopup(req).subscribe({
                        next: (response: AuthenticationResult) => {
                            if (response.account.idToken) {
                                this.msalService!.instance.setActiveAccount(response.account);
                                subj.next(response.account.idToken);
                                subj.complete();
                            }
                        },
                        error: (err) => {
                            console.error(err.errorMessage?? 'MSAL acquireTokenPopup error');
                            subj.next('');
                            subj.complete();
                        }
                    });
                },
                error: (err) => {
                    console.error(err.errorMessage?? 'MSAL initialize error');
                    subj.next('');
                    subj.complete();
                }
            });
        } else {
            console.error('ClientID and/or Tenant values are not configured');
            subj.next('');
            subj.complete();
        }
    }

    private login_azure2_multitenant(subj: AsyncSubject<string>): void {
        // Login with work, school or MS personal accounts
        if (this.azureConfig?.ClientID) {
            this.msalService.instance = new PublicClientApplication({
                auth: {
                    clientId: this.azureConfig.ClientID,
                    authority: 'https://login.microsoftonline.com/common',
                    redirectUri: window.location.href.split("?")[0].split("#")[0]
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage
                },
                system: {
                    allowPlatformBroker: false    // Disables WAM Broker, which only exists on Windows
                }
            });
            this.msalService.initialize().subscribe({
                next: () => {
                    let req: PopupRequest = {
                        scopes: [], // we get 'openid' and 'profile' scopes for free
                        prompt: 'select_account'
                    };
                    this.msalService!.acquireTokenPopup(req).subscribe({
                        next: (response: AuthenticationResult) => {
                            if (response.account.idToken) {
                                this.msalService!.instance.setActiveAccount(response.account);
                                subj.next(response.account.idToken);
                                subj.complete();
                            }
                        },
                        error: (err) => {
                            console.error(err.errorMessage?? 'MSAL acquireTokenPopup error');
                            subj.next('');
                            subj.complete();
                        }
                    });
                },
                error: (err) => {
                    console.error(err.errorMessage?? 'MSAL initialize error');
                    subj.next('');
                    subj.complete();
                }
            });
        } else {
            console.error('ClientID value is not configured');
            subj.next('');
            subj.complete();
        }
    }

    private login_azure2_social(subj: AsyncSubject<string>): void {
        // Login with third party social acounts
        // Tenant must be the name only ('mytenant3' instead of 'mytenant3.onmicrosoft.com')
        if (this.azureConfig?.ClientID && this.azureConfig.Tenant) {
            this.msalService.instance = new PublicClientApplication({
                auth: {
                    clientId: this.azureConfig.ClientID,
                    authority: 'https://' + this.azureConfig.Tenant + '.b2clogin.com/' + this.azureConfig.Tenant + '.onmicrosoft.com/' + this.azureConfig?.PolicyName,
                    knownAuthorities: [this.azureConfig.Tenant + '.b2clogin.com'], // array of URIs that are known to be valid
                    redirectUri: window.location.href.split("?")[0].split("#")[0]
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage
                },
                system: {
                    allowPlatformBroker: false    // Disables WAM Broker, which only exists on Windows
                }
            });
            this.msalService.initialize().subscribe({
                next: () => {
                    let req: PopupRequest = {
                        scopes: ['email'], // we get 'openid' and 'profile' scopes for free
                        prompt: 'select_account'
                    };
                    this.msalService!.acquireTokenPopup(req).subscribe({
                        next: (response: AuthenticationResult) => {
                            if (response.account.idToken) {
                                this.msalService!.instance.setActiveAccount(response.account);
                                subj.next(response.account.idToken);
                                subj.complete();
                            }
                        },
                        error: (err) => {
                            console.error(err.errorMessage?? 'MSAL acquireTokenPopup error');
                            subj.next('');
                            subj.complete();
                        }
                    });
                },
                error: (err) => {
                    console.error(err.errorMessage?? 'MSAL initialize error');
                    subj.next('');
                    subj.complete();
                }
            });
        } else {
            console.error('ClientID and/or Tenant values are not configured');
            subj.next('');
            subj.complete();
        }
    }
}
