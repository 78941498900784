import { Component, Inject, inject, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription, AsyncSubject, forkJoin, timer, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { IPaginationQuery } from '../pagination/pagination.component';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestAddressList, FaxConfigRestLogResubmit } from '../api/api';
import * as _ from 'underscore';

interface ILogEntryFieldEx {
    kind: string;
    name: string;
    displayName: string;
    value: any;
}

export interface ILogEntryTab {
    title: string;
    fields: (string | ILogEntryFieldEx)[];
    custom?: boolean;
}

export interface ILogEntryScope {
    title: string;
    showSubTitle: boolean;
    textSubmit: string | null;
    textCancel: string | null;
    logEntry: any;
    dateFormat: string;
    timeFormat: string;
    selectedTab: number;
    selectedTabTitle: string;
    tabs: ILogEntryTab[];
    getRoutingCodes: boolean;
}

interface IOrganization {
    FaxNumbers: FaxConfigRestAddressList;
    DirNumbers: FaxConfigRestAddressList;
    SmsNumbers: FaxConfigRestAddressList;
}

interface IEditee {
    txtRoutingCode: string;
    selRoutingCode: string;
}

@Component({
    selector: 'app-log-entry',
    imports: [FormsModule, CommonModule, PaginationModule],
    providers: [DatePipe],
    templateUrl: './log-entry.component.html',
    styleUrl: './log-entry.component.css'
})
export class LogEntryComponent implements IPaginationQuery, OnDestroy {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    pagerIsReady: boolean = false;
    orgId: number;
    organization: IOrganization = {
        FaxNumbers: [],
        DirNumbers: [],
        SmsNumbers: []
    };
    msgType: string;
    scope: ILogEntryScope;
    editee: IEditee;
    authToken: string;

    lstRoutingCodes: FaxConfigRestAddressList = [];
    searchKeyRoutingCodes: string = '';

    @ViewChild(PaginationComponent)
    pagerRoutingCodes!: PaginationComponent;
    public queryRoutingCodes: IPaginationQuery = this;
    private pagerTimer: Subscription | null = null;

    constructor(
        public auth: AuthService,
        public session: Session,
        private datePipe: DatePipe,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { scope: ILogEntryScope }
    ) {
        this.scope = data.scope;
        this.msgType = (this.scope.logEntry.fields.hasOwnProperty('MsgType')) ? this.scope.logEntry.fields['MsgType'].value : '';
        this.orgId = (this.scope.logEntry.fields.hasOwnProperty('OrganizationId')) ? this.scope.logEntry.fields['OrganizationId'].value : -1;
        this.editee = {
            txtRoutingCode: (this.scope.logEntry.fields.hasOwnProperty('RoutingCode') && this.scope.logEntry.fields.RoutingCode.value !== 'various values') ? this.scope.logEntry.fields.RoutingCode.value : '',
            selRoutingCode: (this.scope.logEntry.fields.hasOwnProperty('RoutingCode') && this.scope.logEntry.fields.RoutingCode.value !== 'various values') ? this.scope.logEntry.fields.RoutingCode.value : ''
        };
        this.authToken = encodeURIComponent(this.auth.getAuthToken()?? '');
        this.session.rootPromises.subscribe(() => this.init());
    }

    private rewriteTabs(): void {
        // remove properties that start with 'File_Content_as_' and check whether to show the backupfile link.
        // in case of LogType or various datetimes rewrite value.

        _.each(this.scope.tabs, (tab, tab_idx) => {
            let newFields: ILogEntryFieldEx[] = [];
            _.each(this.scope.tabs[tab_idx].fields as string[], field_name => {
                let field = this.scope.logEntry.fields[field_name];

                if (typeof(field) !== "undefined") {

                    if (field_name.substring(0, 'FileContent'.length) === 'FileContent') {
                        if (field.value) { // only show FileContent* fields if value is true.
                            newFields.push({
                                kind: 'FileContent',
                                name: field_name,
                                // special treatment if internal name is just 'FileContent', without file type suffix.
                                displayName: field_name === 'FileContent' ? 'File Content' : field.displayName,
                                value: field.value
                            });
                        }
                    }
                    else if (field_name === 'BackupFile') {
                        // only show BackupFile if is contains a filename and file exists on disk
                        if (field.value) {
                            let tab_idx_backupFile = tab_idx;
                            this.faxSrv.logBackupFileExists(this.scope.logEntry.fields.JobNumber.value).subscribe(bExists => {
                                if (bExists) {
                                    this.scope.tabs[tab_idx_backupFile].fields.push({
                                        kind: 'BackupFile',
                                        name: field_name,
                                        displayName: field.displayName,
                                        value: field.value.substr(field.value.lastIndexOf('\\') + 1)
                                    });
                                } else {
                                    this.scope.tabs[tab_idx_backupFile].fields.push({
                                        kind: 'Field',
                                        name: field_name,
                                        displayName: field.displayName,
                                        value: 'no longer available'
                                    });
                                }
                            });
                        }
                    }
                    else {
                        let displayName = field.displayName;
                        let displayValue = field.value;

                        if (field_name === 'LogType') {
                            if (this.scope.logEntry.fields.Direction.value == 1) {
                                //inbound
                                if (field.value == 1) {
                                    displayValue = 'Message routed to user';
                                } else if (field.value == 2) {
                                    displayValue = 'Message delivered';
                                } else {
                                    displayValue = 'Unknown (' + field.value + ')';
                                }
                            } else {
                                //outbound
                                if (field.value == 0) {
                                    displayValue = 'Message send attempt';
                                } else if (field.value == 1) {
                                    displayValue = 'Message delivered';
                                } else {
                                    displayValue = 'Unknown (' + field.value + ')';
                                }
                            }
                        }
                        else if (field_name === 'StartRecvTime' || field_name === 'StartRecvTimeLocal' ||
                                    field_name === 'CreationTime' || field_name === 'CreationTimeLocal' ||
                                    field_name === 'CompletionTime' || field_name === 'CompletionTimeLocal' ||
                                    field_name === 'SubmissionTime' || field_name === 'SubmissionTimeLocal') {
                            // change from utc or kernel local time into browser local time
                            displayValue = field.value? (this.datePipe.transform(field.value, this.scope.dateFormat + ' ' + this.scope.timeFormat)?? ''): '';
                        }

                        newFields.push({
                            kind: 'Field',
                            name: field_name,
                            displayName: displayName,
                            value: displayValue
                        });
                    }
                }
            });
            this.scope.tabs[tab_idx].fields = newFields;
        });
    }

    init(): void {
        this.rewriteTabs();
        let promises: Observable<FaxConfigRestAddressList>[] = [];
        if (this.scope.getRoutingCodes && this.session.isMultiTenant() && this.orgId !== -1) {
            promises.push(
                this.faxSrv.GetAddresses(0, 999, { OrganizationId: this.orgId, AddrType: 'FAX', IncludeAttached: true, IncludeUnattached: false })
                .pipe(map(numbers => { this.organization.FaxNumbers = numbers; return numbers; }))
            );
            promises.push(
                this.faxSrv.GetAddresses(0, 999, { OrganizationId: this.orgId, AddrType: 'DIR', IncludeAttached: true, IncludeUnattached: false })
                .pipe(map(numbers => { this.organization.DirNumbers = numbers; return numbers; }))
            );
            promises.push(
                this.faxSrv.GetAddresses(0, 999, { OrganizationId: this.orgId, AddrType: 'SMS', IncludeAttached: true, IncludeUnattached: false })
                .pipe(map(numbers => { this.organization.SmsNumbers = numbers; return numbers; }))
            );
        }
        if (promises.length > 0) {
            forkJoin(promises).subscribe(() => {
                this.lstRoutingCodes = this.organization.DirNumbers;
                if (this.msgType === 'Fax' || this.msgType === 'SDX') {
                    this.lstRoutingCodes = this.lstRoutingCodes.concat(this.organization.FaxNumbers);
                } else if (this.msgType === 'SMS') {
                    this.lstRoutingCodes = this.lstRoutingCodes.concat(this.organization.SmsNumbers);
                }
                // Only show routingcodes that are assigned to user(s)
                this.lstRoutingCodes = _.filter(this.lstRoutingCodes, rc => { return rc.UserNames !== null && rc.UserNames !== ''; });
                // Wait for Angular to create the child pager component
                this.pagerTimer = timer(10).subscribe(() => this.checkPager());
                this.isReady = true;
            });
        } else {
            this.isReady = true;
        }
    }

    private checkPager(): void {
        if (this.pagerRoutingCodes) {
            this.pagerTimer = null;
            this.pagerIsReady = true;
            this.pagerRoutingCodes.refresh();
        } else {
            this.pagerTimer = timer(10).subscribe(() => this.checkPager());
        }
    }

    ngOnDestroy() {
        this.pagerTimer?.unsubscribe();
    }

    invoke(offset: number, limit: number) {
        let subj = new AsyncSubject<FaxConfigRestAddressList>;
        let lst = this.lstRoutingCodes;
        if (this.searchKeyRoutingCodes) {
            let str = this.searchKeyRoutingCodes.toLowerCase();
            lst = _.filter(lst, rc => { return (rc.Address != null && rc.Address.toLowerCase().includes(str)) || (rc.UserNames != null && rc.UserNames.toLowerCase().includes(str)); });
        }
        subj.next(lst.slice(offset, offset + limit));   // return array as observable
        subj.complete();
        return subj;
    }

    searchRoutingCodes(): void {
        this.pagerRoutingCodes?.refresh();
    }

    fromDisplay(logEntry: any): string {
        if (logEntry.fields.From_Name.value) {
            return logEntry.fields.From_Name.value;
        }
        return logEntry.fields.From_Address.value?? '';
    }

    toDisplay(logEntry: any): string {
        if (logEntry.fields.To_Name.value) {
            return logEntry.fields.To_Name.value;
        }
        return logEntry.fields.To_Address.value?? '';
    }

    getFileContentUrl(name: string): string {
        let jobNumber = this.scope.logEntry.fields.JobNumber.value;
        return this.session.portalCfg.prefix + 'Log/' + encodeURIComponent(jobNumber) + '/File?Name=' + encodeURIComponent(name) + '&Token=' + this.authToken;
    }

    getBackupFileUrl(): string {
        let jobNumber = this.scope.logEntry.fields.JobNumber.value;
        return this.session.portalCfg.prefix + 'Log/' + encodeURIComponent(jobNumber) + '/BackupFile' + '?Token=' + this.authToken;
    }

    submitEnabled(): boolean {
        if (this.scope.textSubmit) {
            if (!this.session.usePhonenumberAdministration()) {
                return this.editee.txtRoutingCode? true: false;
            } else if (this.editee.selRoutingCode && this.pagerRoutingCodes?.items) {
                // Enable the 'Resubmit' button only if the current selection is in the (filtered) list.
                return _.any(this.pagerRoutingCodes.items, item => { return item.Address === this.editee.selRoutingCode; });
            }
        }
        return false;
    }

    submit(): void {
        let retval: FaxConfigRestLogResubmit = {};
        if (this.lstRoutingCodes.length > 0) {
            if (this.editee.selRoutingCode) {
                retval.RoutingCode = this.editee.selRoutingCode;
            }
        } else {
            if (this.editee.txtRoutingCode) {
                retval.RoutingCode = this.editee.txtRoutingCode;
            }
        }
        this.dialogRef.close(retval);
    };

    cancel(): void {
        this.dialogRef.close(null);
    }
}
