import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-confirm-prompt',
    imports: [FormsModule, CommonModule],
    templateUrl: './confirm-prompt.component.html',
    styleUrl: './confirm-prompt.component.css'
})
export class ConfirmPromptComponent {
    isReady: boolean = true;
    entered_text: string = '';
    scope: any;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data;
    }

    key_entered(): boolean {
        return this.entered_text === this.scope.key_text;
    }

    doOnOk(): void {
        this.scope.onOK();
        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
    }
}
