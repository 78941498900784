import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestHostMailSystemItem, FaxConfigRestHostMailSystemList } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { ConnNewuserdialogComponent } from '../conn-newuserdialog/conn-newuserdialog.component';
import * as _ from 'underscore';

@Component({
    selector: 'app-conn-settings',
    imports: [FormsModule, CommonModule],
    templateUrl: './conn-settings.component.html',
    styleUrl: './conn-settings.component.css'
})
export class ConnSettingsComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    private router = inject(Router);

    isReady: boolean = false;
    connHosts: FaxConfigRestHostMailSystemList = [];

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    private init(): void {
        this.faxSrv.GetConnHosts({}).subscribe(res => {
            this.connHosts = _.sortBy(res, 'FriendlyName');
            this.isReady = true;
        });
    }

    edit(host: FaxConfigRestHostMailSystemItem): void {
        if (host.MailSystem) {
            this.router.navigate(['/conn_host/' + encodeURIComponent(host.MailSystem)]);
        }
    }

    edit_global_new_user_defaults(): void {
        this.faxSrv.GetConnHostsNewUserDefaults('', '').subscribe(res => {
            const dialogRef = this.dialog.open(ConnNewuserdialogComponent, {
                data: res
            });
            dialogRef.afterClosed().subscribe();
        });
    }
}
