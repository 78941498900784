import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestAccountsGeneral } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";

export interface IAccountSetup {
    PasswordMinimum: number;
    PasswordStrong: boolean;
    EnableLockout: boolean;
    LockoutTrigger: number;
    LockoutMinutes: number;
    DBSource: string;
    DBUsername: string;
    DBPassword: string;
    DBVerifyPassword: string;
}

@Component({
    selector: 'app-account-setup',
    imports: [FormsModule, CommonModule],
    templateUrl: './account-setup.component.html',
    styleUrl: './account-setup.component.css'
})
export class AccountSetupComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    STRONG_PASSWORD_MINIMUM = 8;
    modified: boolean = false;
    editee: IAccountSetup;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IAccountSetup }
    ) {
        this.editee = data.editee;
        this.onStrongPwChange();    // Initialize variables
    }

    private doSave(): Observable<FaxConfigRestResult> | null {
        let def: FaxConfigRestAccountsGeneral = {
            PasswordStrong: this.editee.PasswordStrong? 1: 0
        };

        if (this.editee.PasswordMinimum < 1 || this.editee.PasswordMinimum > 100) {
            alert('The minimum password length must be a number in the range 1 to 100.');
            return null;
        }
        if (def.PasswordStrong && this.editee.PasswordMinimum < this.STRONG_PASSWORD_MINIMUM) {
            alert('The minimum password length must be at least ' + this.STRONG_PASSWORD_MINIMUM + ' when strong passwords are enforced.');
            return null;
        }
        def.PasswordMinimum = this.editee.PasswordMinimum;

        if (this.editee.EnableLockout) {
            if (this.editee.LockoutTrigger < 1 || this.editee.LockoutTrigger > 100) {
                alert('The lockout attempts must be a number in the range 1 to 100.');
                return null;
            }
            def.LockoutTrigger = this.editee.LockoutTrigger;
            if (this.editee.LockoutMinutes <= 0 || this.editee.LockoutMinutes > 2147483647) {
                alert('The lockout minutes must be a number greater than zero.');
                return null;
            }
            def.LockoutMinutes = this.editee.LockoutMinutes;
        } else {
            def.LockoutTrigger = 0;
        }
        if (this.editee.DBSource) {
            def.DBSource = this.editee.DBSource;
        } else {
            alert('The database source connection string cannot be empty.');
            return null;
        }
        def.DBUsername = this.editee.DBUsername;
        if (this.editee.DBPassword) {
            if (this.editee.DBVerifyPassword !== this.editee.DBPassword) {
                alert('The verify password you entered does not match the new password.');
                return null;
            }
            def.DBPassword = this.editee.DBPassword;
        }
        return this.faxSrv.PutAccountsGeneral(def);
    }

    test(): void {
        let def: FaxConfigRestAccountsGeneral = {
            DBSource: this.editee.DBSource,
            DBUsername: this.editee.DBUsername,
            DBPassword: this.editee.DBPassword
        };
        this.faxSrv.TestAccountsGeneral(def).subscribe({
            next: res => {
                if (res.Status === 'ok') {
                    alert('Successfully connected to the database.');
                } else {
                    if (res.Errors) {
                        let msg = this.fenUtils.formatErrors(res.Errors);
                        if (msg) alert(msg);
                    } else {
                        alert('Cannot connect to the database using the current parameters.');
                    }
                }
            },
            error: err => { alert(err.message); }
        });
    }

    onStrongPwChange(): void {
        if (this.editee.PasswordStrong) {
            if (this.editee.PasswordMinimum < this.STRONG_PASSWORD_MINIMUM) {
                this.editee.PasswordMinimum = this.STRONG_PASSWORD_MINIMUM;
            }
        }
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = this.doSave();
        sub?.subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    submitEnabled(): boolean {
        if (!this.editee.DBSource) return false;
        if (this.editee.DBPassword) {
            if (!this.editee.DBUsername) return false;
            if (this.editee.DBVerifyPassword !== this.editee.DBPassword) return false;
        }
        return true;
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
