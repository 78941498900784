<div class="filterbar" *ngIf="auth.isModifiable('Coversheets')">
    <strong>Fields:&nbsp;</strong>
    <button type="button" class="btn btn-sm" (click)="addField()" title="Add a new field to the cover page"><i class="glyphicon glyphicon-plus"></i>&nbsp;Add</button>&ngsp;
    <button type="button" class="btn btn-sm" (click)="editField()" [disabled]="!state.selectedField" title="Edit the properties of the highlighted field">Edit</button>&ngsp;
    <button type="button" class="btn btn-sm" (click)="removeField()" [disabled]="!state.selectedField" title="Remove the highlighted field">Remove</button>&ngsp;
    <span class="badge badge-info" *ngIf="state.selectedField">
        {{ state.selectedField.definition.Field }}
    </span>&ngsp;
    <div class="btn-group">
        <button type="button" class="btn btn-sm" (click)="alignLeft()" title="Align the left edge of the highlighted field to the nearest field to its left"><i class="glyphicon glyphicon-arrow-left"></i></button>
        <button type="button" class="btn btn-sm" (click)="alignRight()" title="Align the left edge of the highlighted field to the nearest field to its right"><i class="glyphicon glyphicon-arrow-right"></i></button>
        <button type="button" class="btn btn-sm" (click)="alignAbove()" title="Align the top edge of the highlighted field to the nearest field above it"><i class="glyphicon glyphicon-arrow-up"></i></button>
        <button type="button" class="btn btn-sm" (click)="alignBelow()" title="Align the top edge of the highlighted field to the nearest field below it"><i class="glyphicon glyphicon-arrow-down"></i></button>
    </div>
    <div class="buttons pull-right" style="margin-top:0px;">
        <strong>Background:&nbsp;</strong>
        <button type="button" class="btn btn-sm" (click)="chooseBackground()" title="Choose a background image for the cover page"><i class="glyphicon glyphicon-plus"></i>&nbsp;Choose</button>&ngsp;
        <button type="button" class="btn btn-sm" (click)="removeBackground()" [disabled]="!coversheet.FirstPage?.Background" title="Remove the background">Remove</button>
    </div>
</div>

<div class="paper"
    [ngStyle]="uiDim(config.paperDim)"
    (contextmenu)="$event.preventDefault()">
    <img class="paper_background img-responsive" src="{{backgroundSrc}}" >
    <div id="cover_paper_inner" class="paper_inner"
        [class.selected]="state.selectedField === null"
        [ngStyle]="uiRect(config.paperInnerOffset, config.paperInnerDim)"
        (selectstart)="$event.preventDefault()">
    </div>
    <div class="textarea" id="coverpage_textarea"
                        [class.selected]="!state.selectedField"
                        [ngStyle]="uiRect(coversheetState.firstPageTextArea!.offset, coversheetState.firstPageTextArea!.dim)"
                        (selectstart)="$event.preventDefault()"> <!-- textarea cannot be removed -->
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
        irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.
    </div>

    <div class="coversheet_field" *ngFor="let field of coversheetState.fields; let idx = index;"
                        [class.selected]="field === state.selectedField"
                        [ngStyle]="uiRect(field.offset, field.dim)"
                        title="{{ field?.definition?.Field?? '' }}"
                        id="coversheet_field_{{ idx }}"
                        (selectstart)="$event.preventDefault()">
        <input type="text" [(ngModel)]="field.props.Prefix" [disabled]="!auth.isModifiable('Coversheets')">
    </div>
</div>
