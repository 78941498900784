import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestFaxLineLimitsDef } from '../api/api';

export interface IFaxLineLimitsEditee {
    Enabled: boolean;
    FaxNumber: string;
    MaxLines: number;
    Description: string;
}

@Component({
    selector: 'app-faxlinelimits-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './faxlinelimits-edit.component.html',
    styleUrl: './faxlinelimits-edit.component.css'
})
export class FaxLineLimitsEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IFaxLineLimitsEditee;
    isModifiable: boolean = false;
    origFaxNumber: string;
    headerText: string;

    constructor(
        public auth: AuthService,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IFaxLineLimitsEditee | null }
    ) {
        this.isModifiable = auth.isModifiable('Fax Line Limits');
        if (data.editee) {
            this.editee = data.editee;
            this.origFaxNumber = this.editee.FaxNumber;
            this.headerText = 'Line Limit for ' + this.origFaxNumber;
        } else {
            this.origFaxNumber = '';
            this.headerText = 'New Line Limit entry';
            this.editee = {
                Enabled: true,
                FaxNumber: '',
                MaxLines: 1,
                Description: ''
            };
        }
    }

    private doSave(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestFaxLineLimitsDef = {
            Enabled: this.editee.Enabled,
            FaxNumber: this.editee.FaxNumber,
            MaxLines: this.editee.MaxLines,
            Description: this.editee.Description
        };
        if (this.origFaxNumber) {
            return this.faxSrv.PutFaxLineLimitsDefinition(this.origFaxNumber, def);
        } else {
            return this.faxSrv.PostFaxLineLimitsDefinition(def);
        }
    }

    submit(): void {
        const regex = /^\+?([0-9] ?){5,15}$/;
        if (this.editee.FaxNumber && this.editee.FaxNumber.match(regex)) {
            if (this.editee.MaxLines > 2147483647) {
                alert('The maximum lines value is too large; enter 0 for unlimited lines.');
            } else {
                this.doSave().subscribe(res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.dialogRef.close(true);
                    }
                });
            }
        } else {
            alert('The fax number is not in E.164 format, i.e. +19995550123');
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
