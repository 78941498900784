<section id="monitoring_sender_smtp" class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1><span *ngIf="isModifiable">{{ title }} </span>{{editee.__type}} Sender</h1>
    </div>
    <form id="monitoring_form_smtp" class="form" novalidate (submit)="save()">
        <div class="modal-body">
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="editee.Enabled" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="1"
                title="Enable or disable this Sender.">
            </div>
            <div class="field">
                <label>Display Name:</label>
                <input type="text" name="DisplayName" [(ngModel)]="editee.DisplayName" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="2"
                title="Enter a descriptive friendly name for this Sender definition.">
            </div>

            <ul class="nav nav-tabs">
                <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
                    <a href="" (click)="pageTabClick(pageTab);false">{{ pageTab }}</a>
                </li>
            </ul>
            <form *ngIf="state.activePageTab === 'Message Properties'">
                <ng-container *ngTemplateOutlet="page1"></ng-container>
            </form>
            <form *ngIf="state.activePageTab === 'Connection Parameters'">
                <ng-container *ngTemplateOutlet="page2"></ng-container>
            </form>
        </div>
        <div class="monitoring_footer">
            <strong>Description</strong>
            <div id="monitoring_description" class="well">{{ Description }}</div>
            <div class="buttons">
                <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
                <button *ngIf="isModifiable" type="button" class="btn" [disabled]="!submitEnabled()" (click)="apply()">Apply</button>
                <button type="button" class="btn" (click)="close()">Close</button>
            </div>
        </div>
    </form>
</section>

<!-- Message Properties tab -->
<ng-template #page1>
    <form id="monitoring_form_msg" class="form" novalidate>
        <div class="field">
            <label>From: *</label>
            <input type="text" name="From" [(ngModel)]="editee.From" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="3"
            title="Enter the email address to use in the From field of the notification. Optionally, a name may be specified using the format: &quot;Name &lt;user@domain.com&gt;&quot;.">
        </div>
        <div class="field">
            <label>To: *</label>
            <input type="text" name="To" [(ngModel)]="editee.To" [required]="isModifiable" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="4"
            title="Enter one or more email address(es) which the notification will be sent to. Optionally, names may be specified using the format: &quot;Name &lt;user@domain.com&gt;&quot;. Separate multiple email addresses with semicolons (;).">
        </div>
        <div class="field">
            <label>Subject:</label>
            <input type="text" name="Subject" [(ngModel)]="editee.Subject" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="5"
            title="Enter the text which will appear in the Subject line of the email.">
        </div>
    </form>
</ng-template>

<!-- Connection Parameters tab -->
<ng-template #page2>
    <form id="monitoring_form_conn" class="form" novalidate>
        <div class="field">
            <label>Server: *</label>
            <input type="text" [(ngModel)]="editee.SmtpServer" name="SmtpServer" [disabled]="!isModifiable" [required]="isModifiable" (focusin)="focusin($event)" tabindex="6"
            title="Enter the IP address or host name of the mail server which will deliver the email notification.">
        </div>
        <div class="field">
            <label>Port: *</label>
            <input type="number" [(ngModel)]="editee.Port" name="Port" min="1" max="65535" [disabled]="!isModifiable" [required]="isModifiable" (focusin)="focusin($event)" tabindex="7"
            title="Enter the IP port to use for connecting to the mail server.">
        </div>
        <div class="field">
            <label>TLS options:</label>
            <select [(ngModel)]="state.tlsOpt" name="tlsOpt" [disabled]="!isModifiable" [required]="isModifiable" (focusin)="focusin($event)" tabindex="8"
            title="Select whether to use Transport Layer Security, and if so the minimum TLS version to allow when establishing a secure connection.">
                <option *ngFor="let item of tlsOptions" [ngValue]="item">{{ item.name }}</option>
            </select>
        </div>
        <div class="field">
            <label>Username:</label>
            <input type="text" [(ngModel)]="editee.AuthUsername" name="AuthUsername" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="9"
            title="If the mail server requires authentication, enter the user name to authenticate as.">
        </div>
        <div class="field">
            <label>Password:</label>
            <input type="password" autocomplete="off" name="AuthPassword" [(ngModel)]="editee.AuthPassword" [disabled]="!isModifiable" (focusin)="focusin($event)" tabindex="10"
            title="If the mail server requires authentication, enter the password of the user to authenticate as.">
        </div>
    </form>
</ng-template>
