<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ scope.title }}</h1>
    </div>
    <form id="datetime_format_form" class="form" novalidate (submit)="submit()">
        <div class="modal-body">
            <p>{{ scope.text }}</p>

            <div class="field-indented">
                <p class="alert-info">Any text within single quotes (like 'text') is ignored in the format strings.</p>
            </div>

            <div class="field">
                <label class="field-indented">Date Format:</label>
                <app-combobox name="DateFormat" [(model)]="scope.editee.dateFormat" (modelChange)="format_change()" [options]="dateFormats" [required]="true"
                title="Enter the date format, for example 'yyyy-MM-dd'. The format is case-sensitive.">
                </app-combobox>
            </div>
            <div class="field">
                <label class="field-indented">Separator:</label>
                <select name="DateTimeSeparator" [(ngModel)]="scope.editee.dateTimeSeparator" (ngModelChange)="format_change()"
                style="width:186px;" title="Choose a character to separate the date and the time.">
                    <option *ngFor="let item of dateTimeSeparators" [ngValue]="item.Char">{{ item.Name }}</option>
                </select>
            </div>
            <div class="field">
                <label class="field-indented">Time Format:</label>
                <app-combobox name="TimeFormat" [(model)]="scope.editee.timeFormat" (modelChange)="format_change()" [options]="timeFormats" [required]="true"
                title="Enter the time format, for example 'HH-mm-ss'. The format is case-sensitive.">
                </app-combobox>
            </div>

            <p class="well"><strong>Preview:</strong><br>{{ currentDateTime | date:scope.editee.dateFormat }}{{ scope.editee.dateTimeSeparator }}{{ formatTime(currentDateTime, scope.editee.timeFormat) }}</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
