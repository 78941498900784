<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ headerText }}</h1>
    </div>
    <form id="address_edit_form" class="form" novalidate (submit)="submit()" name="addressEditForm">
        <div class="modal-body" *ngIf="isReady">
            <p *ngIf="auth.isModifiable('Addresses')">
                To work on a batch of numbers, enter the start and end {{ isDIRNumber? 'DIR number': 'phone number' }} of the batch.
                <br />
                To work on a single number, just enter a start number.
            </p>

            <div class="field">
                <label>Start number:&nbsp;*</label>
                <input type="text" name="addressStart" maxlength="150" [(ngModel)]="scope.editee.AddressStart" size="40"
                    [disabled]="!auth.isModifiable('Addresses')" title="The start of the {{ isDIRNumber? 'DIR number': 'phone number' }} range.">
            </div>
            <div class="field">
                <label>End number:</label>
                <input type="text" name="addressEnd" maxlength="150" [(ngModel)]="scope.editee.AddressEnd" size="40"
                    [disabled]="!auth.isModifiable('Addresses')"
                    title="The end of the {{ isDIRNumber? 'DIR number': 'phone number' }} range, or leave empty for a single number.">
            </div>
            <div class="field">
                <span class="text-danger" *ngIf="validate_batch().error">{{ validate_batch().error }}</span>
                <span class="text-info" *ngIf="!validate_batch().error">{{ validate_batch().count }} number(s)
                    potentially in the batch range.</span>
            </div>
            <p style="white-space: pre-wrap">{{ rangeText }}</p>
            <br />
            <div class="field" *ngIf="scope.mode=='attach'">
                <label>Organization:</label>
                <span>{{ session.currentOrgName }}</span>
            </div>
            <div class="field" *ngIf="scope.mode=='add' || scope.mode=='edit'">
                <label>Connection:&nbsp;*</label>
                <select [disabled]="!auth.isModifiable('Addresses')" name="connections" [(ngModel)]="scope.editee.ConnectionId">
                    <option *ngFor="let p of scope.Connections" [value]="p.ID">{{ p.ConnectionName }}</option>
                </select>
            </div>
            <div class="field" *ngIf="(scope.mode=='add' || scope.mode=='edit') && (!scope.Connections || scope.Connections.length<=1)">
                <span class="text-info">Add providers via the 'Configure' button on the Phone Numbers page.</span>
            </div>
            <div class="field" *ngIf="scope.mode=='add' || scope.mode=='edit'">
                <label>Description:</label>
                <input type="text" name="description" maxlength="500" [(ngModel)]="scope.editee.Description" size="40"
                    [disabled]="!auth.isModifiable('Addresses')" title="An optional description">
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" *ngIf="auth.isModifiable('Addresses')"
                [disabled]="validate_batch().error || ((scope.mode=='add' || scope.mode=='edit') && scope.editee.ConnectionId < 0 ) || operationPending"
                >{{ submitText }}</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
