<h1>Coversheets</h1>
<div>
    <ul class="nav nav-tabs" *ngIf="pageTabs && auth.isModifiable('Coversheets')">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>
    <div [hidden]="state.activePageTab !== 'Coversheets'">
        <ng-container *ngTemplateOutlet="coversheets_tab"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Disclaimer Pages'">
        <ng-container *ngTemplateOutlet="disclaimers_tab"></ng-container>
    </div>
</div>

<ng-template #coversheets_tab>
    <div *ngIf="auth.isModifiable('Coversheets') && !session.contextAllOrganizations()" class="btn-toolbar">
        <button type="button" class="btn" (click)="neo()"><i class="glyphicon glyphicon-plus"></i>&nbsp;New</button>
        <button type="button" class="btn" (click)="del()" [disabled]="!isRemoveEnabled()">Remove</button>
        <button type="button" class="btn" (click)="set_default()" *ngIf="auth.isModifiable('Coversheets')" [disabled]="!isSetDefaultEnabled()">Set As Default</button>
        <button type="button" class="btn" (click)="import_coversheet()"><i class="glyphicon glyphicon-plus"></i>&nbsp;Import</button>
    </div>
    <p *ngIf="!session.contextAllOrganizations() && default_coversheet==='&lt;NO COVERSHEET&gt;'">
		<span class="text-info">When &lt;NO COVERSHEET&gt; is set as default, the content of the email is not sent; only the attachments are sent.
		If the email does not have any attachments, then it will be rejected with the error: &quot;Faxing without a coversheet is only possible if the message has an attachment.&quot;</span>
	</p>
    <table class="table table-condensed table-bordered table-click" *ngIf="!session.contextAllOrganizations()">
        <thead>
            <tr>
                <th *ngIf="auth.isModifiable('Coversheets')" width="25px"></th>
                <th>Name</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cs of coversheets" (click)="edit(cs)">
                <td *ngIf="auth.isModifiable('Coversheets')"><input type="checkbox" [(ngModel)]="cs.selected" (click)="$event.stopPropagation()"></td>
                <td><span [ngStyle]="nameStyle(cs.name)">{{ cs.name }}</span><span *ngIf="isDefaultCoversheet(cs.name)">&nbsp;(default)</span></td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="session.contextAllOrganizations()">Please select an organization context to show its coversheets.</div>
</ng-template>

<ng-template #disclaimers_tab>
    <div class="btn-toolbar" *ngIf="!session.contextAllOrganizations()">
        <button type="button" class="btn" (click)="add_disclaimer()"><i class="glyphicon glyphicon-plus"></i>&nbsp;Add</button>
        <button type="button" class="btn" (click)="remove_disclaimer()" [disabled]="fenUtils.nothingSelected(disclaimers)">Remove</button>
    </div>
    <table class="table table-condensed table-bordered table-click" *ngIf="!session.contextAllOrganizations()">
        <thead>
            <tr>
                <th width="25px"></th>
                <th>Name</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ds of disclaimers">
                <td><input type="checkbox" [(ngModel)]="ds.selected" (click)="$event.stopPropagation()"></td>
                <td>{{ ds.name }}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="session.contextAllOrganizations()">Please select an organization context to show its disclaimers.</div>
</ng-template>
