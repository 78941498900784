import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestCSDefinition, FaxConfigRestCSImageExtensions, FaxConfigRestKernelFontsDefs, FaxConfigRestResult } from '../api/api';
import { FenUtilsService } from "../services/fenutils.service";
import * as _ from 'underscore';

export interface ICoversheetImportEditee {
    coversheetFileExtensions: FaxConfigRestCSImageExtensions;
    coversheetAccept: string;
}

@Component({
    selector: 'app-coversheet-import',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-import.component.html',
    styleUrl: './coversheet-import.component.css'
})
export class CoversheetImportComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    cs_file: string = '';
    cs_file_title: string = '';
    cover_back: string = '';
    continuous_back: string = '';
    disclaimer_page: string = '';
    isUploading: boolean = false;
    private state = 0;

    editee: ICoversheetImportEditee;
    isReady: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as ICoversheetImportEditee;
    }

    private setState(val: number): void {
        var form: HTMLFormElement;
        var action: string;

        this.state = val;
        switch (this.state) {

        case 1:
            // Upload .cs file
            form = document.getElementById('choose_coversheet_file') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/File' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FileName=' + encodeURIComponent(this.cs_file) +
                            '&contentType=text%2Fplain';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 2:
            // .cs file already exists, try to overwrite
            form = document.getElementById('choose_coversheet_file') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/File' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FileName=' + encodeURIComponent(this.cs_file) +
                            '&contentType=text%2Fplain&Overwrite=true';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 3:
            // upload cover page background
            form = document.getElementById('choose_coversheet_back') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/Definition/' +
                            encodeURIComponent(this.cs_file_title) + '/Background' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FirstPage=True' +
                            '&contentType=text%2Fplain';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 4:
            // upload continuous pages background
            form = document.getElementById('choose_continuous_back') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/Definition/' +
                            encodeURIComponent(this.cs_file_title) + '/Background' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FirstPage=False' +
                            '&contentType=text%2Fplain';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 5:
            // upload disclaimer page
            form = document.getElementById('choose_disclaimer_page') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/Disclaimer' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FileName=' + encodeURIComponent(this.disclaimer_page) +
                            '&contentType=text%2Fplain';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 6:
            // disclaimer page already exists, try to overwrite
            form = document.getElementById('choose_disclaimer_page') as HTMLFormElement;
            action = this.session.portalCfg.prefix + 'Coversheet/Disclaimer' +
                            '?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
                            '&FileName=' + encodeURIComponent(this.disclaimer_page) +
                            '&contentType=text%2Fplain&Overwrite=true';
            this.fenUtils.handleUpload(form, action).subscribe({
                next: res => this.afterUpload(res),
                error: err => this.handleError(err)
            });
            break;

        case 7:
            // attach disclaimer page
            this.faxSrv.coversheet(this.cs_file_title).subscribe({
                next: (def: FaxConfigRestCSDefinition) => {
                    if (!def.General) def.General = {};
                    if (!def.Disclaimer) def.Disclaimer = {};
                    let idx = this.disclaimer_page.lastIndexOf('.');
                    def.General.UseDisclaimer = true;
                    def.Disclaimer.Filename = ((idx < 0) ? this.disclaimer_page : this.disclaimer_page.substring(0, idx)) + '.tif';
                    this.faxSrv.updateCoversheet(this.cs_file_title, def).subscribe({
                        next: res => this.afterUpload(res),
                        error: err => this.handleError(err)
                    });
                },
                error: err => this.handleError(err)
            });
            break;

        case 8:
            // all completed successfully
            this.dialogRef.close(true);
            break;

        default:
            this.isUploading = false;
            break;
        }
    }

    private handleError(err?: string | null) {
        alert(err || 'An unknown error occurred. Please try again later.');
        this.isUploading = false;
        this.state = 0;
    }

    private afterUpload(res: FaxConfigRestResult): void {
        switch (this.state) {

        case 1:     // after upload of .cs file
            if (res.Status === 'ok') {
                if (this.cover_back) {
                    this.setState(3);   // upload cover page background
                } else if (this.continuous_back) {
                    this.setState(4);   // upload continuous page background
                } else if (this.disclaimer_page) {
                    this.setState(5);   // upload disclaimer page
                } else {
                    this.setState(8);   // close dialog
                }
            } else if (res.Errors) {
                // Check for ERRBASE_FILE_CREATE, means it already exists
                if (res.Errors[0].Id == 0x201) {
                    if (confirm('A coversheet file already exists with that name.  Do you want to overwrite it?')) {
                        this.setState(2);   // overwrite .cs file
                    } else {
                        this.setState(0);
                    }
                } else {
                    this.handleError(this.fenUtils.formatErrors(res.Errors));
                }
            } else {
                this.handleError();
            }
            break;

        case 2:     // after overwrite of .cs file
            if (res.Status === 'ok') {
                if (this.cover_back) {
                    this.setState(3);   // upload cover page background
                } else if (this.continuous_back) {
                    this.setState(4);   // upload continuous page background
                } else if (this.disclaimer_page) {
                    this.setState(5);   // upload disclaimer page
                } else {
                    this.setState(8);   // close dialog
                }
            } else {
                let msg = res.Errors? this.fenUtils.formatErrors(res.Errors): undefined;
                this.handleError(msg);
            }
            break;

        case 3:     // after upload of cover page background
            if (res.Status === 'ok') {
                if (this.continuous_back) {
                    this.setState(4);   // upload continuous page background
                } else if (this.disclaimer_page) {
                    this.setState(5);   // upload disclaimer page
                } else {
                    this.setState(8);   // close dialog
                }
            } else {
                let msg = res.Errors? this.fenUtils.formatErrors(res.Errors): undefined;
                this.handleError(msg);
            }
            break;

        case 4:     // after upload of continuous page background
            if (res.Status === 'ok') {
                if (this.disclaimer_page) {
                    this.setState(5);   // upload disclaimer page
                } else {
                    this.setState(8);   // close dialog
                }
            } else {
                let msg = res.Errors? this.fenUtils.formatErrors(res.Errors): undefined;
                this.handleError(msg);
            }
            break;

        case 5:     // after upload of disclaimer page
            if (res.Status === 'ok') {
                this.setState(7);   // close dialog
            } else if (res.Errors) {
                // Check for ERRBASE_FILE_CREATE, means it already exists
                if (res.Errors[0].Id == 0x201) {
                    if (confirm('A disclaimer page already exists with that name.  Do you want to overwrite it?')) {
                        this.setState(6);   // overwrite disclaimer page
                    } else {
                        this.setState(0);
                    }
                } else {
                    let msg = this.fenUtils.formatErrors(res.Errors);
                    this.handleError(msg);
                }
            } else {
                this.handleError();
            }
            break;

        case 6:     // after overwrite of disclaimer page
            if (res.Status === 'ok') {
                this.setState(7);   // attach disclaimer page
            } else {
                let msg = res.Errors? this.fenUtils.formatErrors(res.Errors): undefined;
                this.handleError(msg);
            }
            break;

        case 7:     // after all work is finished
            if (res.Status === 'ok') {
                this.setState(8);   // close dialog
            } else {
                let msg = res.Errors? this.fenUtils.formatErrors(res.Errors): undefined;
                this.handleError(msg);
            }
            break;

        default:
            break;
        }
    }

    private getFileTitle(filespec: string): string {
        let idx = filespec.lastIndexOf('\\');
        if (idx < 0) {
            idx = filespec.lastIndexOf('/');
        }
        if (idx < 0) {
            return filespec;
        } else {
            return filespec.substring(idx + 1);
        }
    }

    private checkImageExtension(filespec: string, target: string): boolean {
        let idx = filespec.lastIndexOf('.');
        let ext = (idx < 0) ? '' : filespec.substring(idx + 1).toLowerCase();
        if (_.contains(this.editee.coversheetFileExtensions, ext)) return true;
        alert("File extension '." + ext + "' is not supported for " + target + " upload.");
        return false;
    }

    private checkCsExtension(filespec: string): boolean {
        let idx = filespec.lastIndexOf('.');
        let ext = (idx < 0) ? '' : filespec.substring(idx + 1).toLowerCase();
        if (ext === 'cs') return true;
        alert("File extension '." + ext + "' is not supported for Coversheet definition file upload.");
        return false;
    }

    upload(): void {
        if (this.cs_file && this.checkCsExtension(this.cs_file)) {
            let ok: boolean = true;
            if (!this.cover_back && !this.continuous_back && !this.disclaimer_page) {
                ok = confirm('You have not specified any images for the backgrounds or disclaimer page, therefore all image references will be removed from the coversheet definition (*.cs) file.  Do you want to continue?');
            }
            if (ok) {
                if (this.cover_back && !this.checkImageExtension(this.cover_back, 'Cover page background')) return;
                if (this.continuous_back && !this.checkImageExtension(this.continuous_back, 'Continuous page background')) return;
                if (this.disclaimer_page && !this.checkImageExtension(this.disclaimer_page, 'Disclaimer page')) return;
                this.cs_file_title = this.getFileTitle(this.cs_file);
                this.isUploading = true;
                this.setState(1);   // upload .cs file
            }
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
