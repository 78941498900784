<div class="filterbar clearfix pull-right" *ngIf="auth.isModifiable('Coversheets')">
    <strong>Background:&nbsp;</strong>
    <button type="button" class="btn btn-sm" (click)="chooseBackground()" title="Choose a background image for the continuous page"><i class="glyphicon glyphicon-plus"></i>&nbsp;Choose</button>&ngsp;
    <button type="button" class="btn btn-sm" (click)="removeBackground()" [disabled]="!coversheet.NextPages?.Background" title="Remove the background">Remove</button>
</div>

<div class="paper"
    [ngStyle]="uiDim(config.paperDim)"
    oncontextmenu="return false;">
    <img class="paper_background img-responsive" src="{{backgroundSrc}}" >
    <div id="cont_paper_inner" class="paper_inner selected"
            [ngStyle]="uiRect(config.paperInnerOffset, config.paperInnerDim)"
            onselectstart="return false;">
    </div>
    <div class="textarea selected" id="contpage_textarea"
            [ngStyle]="uiRect(coversheetState.contPageTextArea!.offset, coversheetState.contPageTextArea!.dim)"
            onselectstart="return false;">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
        irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
        deserunt mollit anim id est laborum.
    </div>
</div>
