import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestDataSourcesGeneral } from '../api/api';
import * as _ from 'underscore';

@Component({
    selector: 'app-userimport-config',
    imports: [FormsModule, CommonModule],
    templateUrl: './userimport-config.component.html',
    styleUrl: './userimport-config.component.css'
})
export class UserimportConfigComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: FaxConfigRestDataSourcesGeneral = {};
    isReady: boolean = false;
    changed: boolean = false;
    modified: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = _.extend({}, data.editee);
        this.session.rootPromises.subscribe(() => this.init());
    }

    private init(): void {
        this.isReady = true;
    }

    change(): void {
        this.changed = true;
    }
    
    submitEnabled(): boolean {
        return (this.isReady && this.changed);
    }

    save(): void {
        this.faxSrv.updateDataSourcesGeneral(this.editee).subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    close(): void {
        this.dialogRef.close(this.modified? this.editee: null);
    }
}
