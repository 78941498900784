import { CommonModule } from '@angular/common';
import { timer } from 'rxjs';
import { Component, inject, Input, Renderer2, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestCSDefinition } from '../api/api';
import { CoversheetComponent, ICoversheetConfig, IFieldDef, ICoversheetField, ICoversheetState, ITextArea } from '../coversheet/coversheet.component';
import { UtlVec } from '../utils/utl';
import { DialogService } from '../dialog/dialog.service';
import { CoversheetBackgroundComponent, ICoversheetBackgroundEditee } from '../coversheet-background/coversheet-background.component';
import { CoversheetFieldComponent, ICoversheetFieldEditee } from '../coversheet-field/coversheet-field.component';
import * as _ from 'underscore';

interface IState {
    selectedField: ICoversheetField | null;
}

@Component({
    selector: 'app-coversheet-coverpage',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-coverpage.component.html',
    styleUrl: './coversheet-coverpage.component.css'
})
export class CoversheetCoverpageComponent implements OnInit, AfterViewInit {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);

    @Input({ required: true }) parent!: CoversheetComponent;

    coversheet!: FaxConfigRestCSDefinition;
    coversheetState!: ICoversheetState;
    config!: ICoversheetConfig;

    state: IState = { selectedField: null };
    backgroundSrc: string = '';

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.config = this.parent.config;
        this.coversheet = this.parent.coversheet!;
        this.coversheetState = this.parent.coversheetState;
        this.backgroundSrc = this.coversheet.FirstPage?.Background ? this.parent.buildBackgroundSrc(true) : '';
        // convert to 'gui' format
        this.coversheetState.fields = _.map(this.coversheet.FirstPage?.Fields?? [], f => {
            // For each field on the page, do:
            let fd = _.find(this.parent.fieldDefs, fd => { return fd.Field === f.Field; });
            let scale;
            let is_sizable;
            if (!fd) {
                // The coversheet has a field which does not exist in the known field definitions!
                fd = {
                    DisplayName: f.Field?? '',
                    Field: f.Field?? '',
                    Type: 'string',
                    DefaultPrefix: f.Field?? '',
                    MaxOccurrences: 1
                };
            }
            if (fd.MaxOccurrences !== -1) { // -1 - infinity
                fd.active = false;
            }
            scale = this.config.scale;
            is_sizable = this.isResizable(f.Field);
            return {
                definition: fd,
                props: f,
                sizable: is_sizable,
                offset: new UtlVec(f.x1, f.y1).scale(scale),
                dim: new UtlVec(
                    (f.x2 === 1 || !is_sizable)? (200 / scale): ((f.x2?? 0) - f.x1),
                    (f.y2 === 1 || !is_sizable)? (18 / scale): ((f.y2?? 0) - f.y1)).scale(scale)
            };
        });

        let textArea = this.coversheet.FirstPage?.TextArea?? { x1:0, y1:0, x2:0, y2:0 }
        this.coversheetState.firstPageTextArea = {
            definition: null,
            offset: new UtlVec(textArea.x1, textArea.y1).scale(this.config.scale),
            dim: new UtlVec((textArea.x2?? 0) - textArea.x1, (textArea.y2?? 0) - textArea.y1).scale(this.config.scale),
            prefix: ''
        };
        this.initTextArea(this.coversheetState.firstPageTextArea);
    }

    ngAfterViewInit(): void {
        // Initialize all fields after Angular creates the HTML elements.
        for (let idx = 0; idx < this.coversheetState.fields.length; idx++) {
            this.initField(idx, this.coversheetState.fields[idx]);
        }
    }

    myOnSelectStart(): boolean { return false; }

    uiDim(vec: UtlVec): object {
        return { width: vec.x + 'px', height: vec.y + 'px' };
    }

    uiRect(off: UtlVec, dim: UtlVec): object {
        return { left: off.x + 'px', top: off.y + 'px', width: dim.x + 'px', height: dim.y + 'px' };
    }

    isResizable(field?: string | null): boolean {
        return (field ? (
            field !== 'IPM.To.Address' &&
            field !== 'IPM.From.Address' &&
            field !== 'SYS.Date' &&
            field !== 'SYS.Text' &&
            field !== 'SYS.Time' &&
            (field.substring(0, 4) !== 'DDA.' ||
            field.substring(0, 10) === 'DDA.Route.')
            ) : false
        );
    }

    isListType(field: string | null): boolean {
        return (
            field === 'IPM.CcList' ||
            field === 'IPM.OrList' ||
            field === 'IPM.ToList' );
    }

    activeFieldDefs(): IFieldDef[] {
        return _.filter(this.parent.fieldDefs, def => { return (def.active?? false); });
    }

    initTextArea(textArea: ITextArea): void {
        if (this.auth.isModifiable('Coversheets')) {
            var hookOnMouseDown = () => { this.state.selectedField = null; };
            let element = document.getElementById("coverpage_textarea") as HTMLElement;
            this.parent.draggable(element, textArea, hookOnMouseDown);
            this.parent.resizable(element, textArea, hookOnMouseDown);
        }
    }

    initField(idx: number, field: ICoversheetField): void {
        if (this.auth.isModifiable('Coversheets')) {
            let element = document.getElementById("coversheet_field_" + idx);
            if (element) {
                var hookOnMouseDown = () => { this.state.selectedField = field; };
                this.parent.draggable(element, field, hookOnMouseDown);
                if (field.sizable) {
                    this.parent.resizable(element, field, hookOnMouseDown);
                }
            }
        }
    }

    addField(): void {
        var def = this.activeFieldDefs()[0];
        let editee: ICoversheetFieldEditee = {
            Def:   def,
            Verb:  'Add',
            Field:  def.Field,
            Prefix: def.DefaultPrefix,
            Lines:  1,
            Format: ''
        };
        if (def.Field === 'IPM.CcList') {
            editee.Format = this.coversheet.General?.CCListFormat?? '';
        } else if (def.Field === 'IPM.OrList') {
            editee.Format = this.coversheet.General?.ORListFormat?? '';
        } else if (def.Field === 'IPM.ToList') {
            editee.Format = this.coversheet.General?.TOListFormat?? '';
        }
        const dialogRef = this.dialog.open(CoversheetFieldComponent, {
            data: {
                editee: editee,
                activeFieldDefs: this.activeFieldDefs(),
                coversheet: this.parent.coversheet!
            }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                def = editee.Def;
                let newField = {
                    definition: def,
                    props: {
                        x1: this.config.paperInnerOffset.x / this.config.scale,
                        x2: 1,
                        y1: this.config.paperInnerOffset.y / this.config.scale,
                        y2: 1,
                        Field: def.Field,
                        Lines: editee.Lines,
                        Prefix: editee.Prefix
                    },
                    sizable: this.isResizable(def.Field),
                    offset: this.config.paperInnerOffset,
                    dim: new UtlVec(200, 18)   // x2, y2
                };
                if (def.MaxOccurrences !== -1) { // -1 - infinite
                    def.active = false;
                }
                if (def.Field === 'IPM.CcList') {
                    this.coversheet.General!.CCListFormat = editee.Format;
                } else if (def.Field === 'IPM.OrList') {
                    this.coversheet.General!.ORListFormat = editee.Format;
                } else if (def.Field === 'IPM.ToList') {
                    this.coversheet.General!.TOListFormat = editee.Format;
                }
                this.coversheetState.fields.push(newField);
                this.state.selectedField = this.coversheetState.fields[this.coversheetState.fields.length - 1];

                // Yield to let Angular create the new HTML field element, then initialize it.
                timer(0).subscribe(() => {
                    let idx = this.coversheetState.fields.length - 1;
                    this.initField(idx, this.coversheetState.fields[idx]);
                });
            }
        });
    }

    editField(): void {
        let field = this.state.selectedField;
        if (field) {
            let editee: ICoversheetFieldEditee = {
                Def:   field.definition,
                Verb:  'Edit',
                Field:  field.props.Field?? '',
                Prefix: field.props.Prefix?? '',
                Lines:  field.props.Lines,
                Format: ''
            };
            if (field.props.Field === 'IPM.CcList') {
                editee.Format = this.coversheet.General?.CCListFormat?? '';
            } else if (field.props.Field === 'IPM.OrList') {
                editee.Format = this.coversheet.General?.ORListFormat?? '';
            } else if (field.props.Field === 'IPM.ToList') {
                editee.Format = this.coversheet.General?.TOListFormat?? '';
            }
            const dialogRef = this.dialog.open(CoversheetFieldComponent, {
                data: {
                    editee: editee,
                    activeFieldDefs: this.activeFieldDefs(),
                    coversheet: this.parent.coversheet!
                }
            });
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) {
                    field!.definition   = editee.Def;
                    field!.props.Field  = editee.Field;
                    field!.props.Prefix = editee.Prefix;
                    field!.props.Lines  = editee.Lines;
                    if (field!.props.Field === 'IPM.CcList') {
                        this.coversheet.General!.CCListFormat = editee.Format;
                    } else if (field!.props.Field === 'IPM.OrList') {
                        this.coversheet.General!.ORListFormat = editee.Format;
                    } else if (field!.props.Field === 'IPM.ToList') {
                        this.coversheet.General!.TOListFormat = editee.Format;
                    }
                }
            });
        }
    }

    removeField(): void {
        let field = this.state.selectedField;
        if (field) {
            this.coversheetState.fields = _.without(this.coversheetState.fields, field);
            field.definition.active = true;
            this.state.selectedField = null;
        }
    }

    chooseBackground(): void {
        let editee: ICoversheetBackgroundEditee = {
            name: this.parent.name!,
            coversheetFileExtensions: this.parent.coversheetFileExtensions,
            coversheetAccept: this.parent.coversheetAccept,
            isFirstPage: true
        };
        const dialogRef = this.dialog.open(CoversheetBackgroundComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) {
                this.coversheet.FirstPage!.Background = this.parent.utl.baseName(this.parent.name!, '.cs') + '_first.tif';
                this.backgroundSrc = this.parent.buildBackgroundSrc(true);
            }
        });
    }

    removeBackground(): void {
        this.backgroundSrc = '';
        this.coversheet.FirstPage!.Background = '';
        this.faxSrv.coversheetDeleteBackground(this.parent.name!, true).subscribe();
    }

    findNearest(field: ICoversheetField | ITextArea, func: (field: ICoversheetField | ITextArea, other: ICoversheetField | ITextArea) => number) {
        let least = Number.MAX_VALUE;
        let nearest: ICoversheetField | ITextArea | null = null;
        let distance: number;
        _.each(this.coversheetState.fields, other => {
            if (other !== field) {
                distance = func(field, other);
                if (distance >= 1 && distance < least) {
                    least = distance;
                    nearest = other;
                }
            }
        });
        if (this.coversheetState.firstPageTextArea !== field) {
            distance = func(field, this.coversheetState.firstPageTextArea!);
            if (distance >= 1 && distance < least) {
                nearest = this.coversheetState.firstPageTextArea;
            }
        }
        return nearest;
    }

    applyDelta(field: ICoversheetField | ITextArea, delta: UtlVec): void {
        let elem;
        if (this.state.selectedField) {
            elem = document.getElementsByClassName("coversheet_field selected");
        } else {
            elem = document.getElementsByClassName("textarea selected");
        }
        if (elem && elem.length) {
            let minOff = this.config.paperInnerOffset;
            let maxOff = this.config.paperInnerOffset.plus(this.config.paperInnerDim).minus(field.dim);
            field.offset = field.offset.plus(delta).bound(minOff, maxOff);
            this.parent.utl.offset(elem[0] as HTMLElement, field.offset);
        }
    }

    alignLeft(): void {
        let field: ICoversheetField | ITextArea = this.state.selectedField?? this.coversheetState.firstPageTextArea!;
        let nearest = this.findNearest(field, (field, other) => {
            return (field.offset.x - other.offset.x);
        });
        let xdelta = (nearest ? nearest.offset.x : this.config.paperInnerOffset.x) - field.offset.x;
        this.applyDelta(field, new UtlVec(xdelta, 0));
    }

    alignRight(): void {
        let field: ICoversheetField | ITextArea = this.state.selectedField?? this.coversheetState.firstPageTextArea!;
        let nearest = this.findNearest(field, (field, other) => {
            return (other.offset.x - field.offset.x);
        });
        let xdelta = (nearest ? nearest.offset.x : ((this.config.paperInnerOffset.x + this.config.paperInnerDim.x) - field.dim.x)) - field.offset.x;
        this.applyDelta(field, new UtlVec(xdelta, 0));
    }

    alignAbove(): void {
        let field: ICoversheetField | ITextArea = this.state.selectedField?? this.coversheetState.firstPageTextArea!;
        let nearest = this.findNearest(field, (field, other) => {
            return (field.offset.y - other.offset.y);
        });
        let ydelta = (nearest ? nearest.offset.y : this.config.paperInnerOffset.y) - field.offset.y;
        this.applyDelta(field, new UtlVec(0, ydelta));
    }

    alignBelow(): void {
        let field: ICoversheetField | ITextArea = this.state.selectedField?? this.coversheetState.firstPageTextArea!;
        let nearest = this.findNearest(field, (field, other) => {
            return (other.offset.y - field.offset.y);
        });
        let ydelta = (nearest ? nearest.offset.y : ((this.config.paperInnerOffset.y + this.config.paperInnerDim.y) - field.dim.y)) - field.offset.y;
        this.applyDelta(field, new UtlVec(0, ydelta));
    }
}
