<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Choose Digital Signature</h1>
    </div>
    <form class="form" id="coversheet_digitalsignature" *ngIf="isReady" novalidate (submit)="save()">
        <div class="modal-body">
            <p>Select a Digital Signature provider to be used in combination with this coversheet.</p>
            @for (ds of lstDigSigs; track ds) {
                <div class="field-indented">
                    <input type="radio" name="digsig" [(ngModel)]="editee.digsig"
                        id="digsig_{{ ds.Name }}" value="{{ ds.Name }}" [attr.value]="ds.Name">
                    <label for="digsig_{{ ds.Name }}" [ngStyle]="ds.Style">{{ ds.Name }}</label>
                    <span *ngIf="ds.Style">&nbsp;(not found)</span><br>
                </div>
            }
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="submit">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
