    <dl id="navigation" *ngIf="auth.getUserName()">
        <nav>
            <div *ngIf="auth.isViewable('Organizations') || auth.isViewable('Accounts') || auth.isViewable('Licenses') || auth.isViewable('Services') || auth.isViewable('AuditLog') || auth.isViewable('Users') || ((auth.isViewable('Addresses') ||  auth.isViewable('AddressRegistration')) && session.usePhonenumberAdministration())">
                <dt >Home</dt>
                <dd >
                    <ul class="list">
                        <li routerLink="/overview" routerLinkActive="selected" ariaCurrentWhenActive="page">Overview</li>
                        <li *ngIf="auth.isViewable('Organizations') && session.isMultiTenant()" routerLink="./organizations" routerLinkActive="selected" ariaCurrentWhenActive="page">Organizations</li>
                        <li *ngIf="auth.isViewable('Accounts')"  routerLink="./accounts" routerLinkActive="selected" ariaCurrentWhenActive="page">Accounts</li>
                        <li *ngIf="auth.isModifiable('All') || ( (auth.isViewable('Addresses') ||  auth.isViewable('AddressRegistration')) && session.usePhonenumberAdministration() )"  routerLink="./addresses" routerLinkActive="selected" ariaCurrentWhenActive="page">Phone Numbers</li>
                        <li *ngIf="auth.isViewable('Licenses')"  routerLink="./license" routerLinkActive="selected" ariaCurrentWhenActive="page">License</li>
                        <li *ngIf="auth.isViewable('Services')"  routerLink="./nt_services" routerLinkActive="selected" ariaCurrentWhenActive="page">Services</li>
                        <li *ngIf="auth.isViewable('Services')"  routerLink="./system_info" routerLinkActive="selected" ariaCurrentWhenActive="page">System Info</li>
                        <li *ngIf="auth.isViewable('AuditLog')"  routerLink="./auditlog" routerLinkActive="selected" ariaCurrentWhenActive="page">Audit Log</li>
                    </ul>
                </dd>
            </div>
            <div *ngIf="auth.isViewable('Users') || auth.isViewable('Coversheets') || auth.isViewable('Outbound Service Routing') || auth.isViewable('Outbound Service Management') || auth.isViewable('Queue') || auth.isViewable('Log') || auth.isViewable('Extended Custom Routing') || (auth.isViewable('SDX Properties') && session.hasMessageType('SDX'))">
                <dt >Management</dt>
                <dd >
                    <ul class="list">
                        <li *ngIf="auth.isViewable('Users')"  routerLink="./users" routerLinkActive="selected" ariaCurrentWhenActive="page">Users</li>
                        <li *ngIf="auth.isViewable('Coversheets')"  routerLink="./coversheet_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Coversheets</li>
                        <li *ngIf="auth.isViewable('All') && session.isMultiTenant()" routerLink="./mail_templates" routerLinkActive="selected" ariaCurrentWhenActive="page">Mail Templates</li>
                        <li *ngIf="auth.isViewable('Outbound Service Routing')"  routerLink="./osr_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Outbound Service Routing</li>
                        <li *ngIf="auth.isViewable('Outbound Service Management')"  routerLink="./osm_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Outbound Service Management</li>
                        <li *ngIf="auth.isViewable('Queue')"  routerLink="./queue" routerLinkActive="selected" ariaCurrentWhenActive="page">Queue</li>
                        <li *ngIf="auth.isViewable('Log')"  routerLink="./log" routerLinkActive="selected" ariaCurrentWhenActive="page">Log</li>
                        <li *ngIf="auth.isViewable('Log')"  routerLink="./reporting" routerLinkActive="selected" ariaCurrentWhenActive="page">Reporting</li>
                        <li *ngIf="auth.isViewable('Extended Custom Routing')"  routerLink="./ecr_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Extended Custom Routing</li>
                        <li *ngIf="auth.isViewable('SDX Properties') && session.hasMessageType('SDX')"  routerLink="./sdx" routerLinkActive="selected" ariaCurrentWhenActive="page">Secure Document Exchange</li>
                    </ul>
                </dd>
            </div>
            <div *ngIf="auth.isViewable('All') || auth.isViewable('Organization Properties') || auth.isViewable('Kernel Properties')  || auth.isViewable('Kernel Contracts') || auth.isViewable('Connectors') || auth.isViewable('Faxination Server Monitor') || auth.isViewable('Number Substitution') || auth.isViewable('Fax Line Limits')">
                <dt >Settings</dt>
                <dd >
                    <ul class="list">
                        <li *ngIf="auth.isViewable('Organization Properties')"  routerLink="./org_settings" routerLinkActive="selected" ariaCurrentWhenActive="page">General</li>
                        <li *ngIf="auth.isViewable('Kernel Properties') || auth.isViewable('Fax Line Limits')"  routerLink="./faxination_server" routerLinkActive="selected" ariaCurrentWhenActive="page">Kernel</li>
                        <li *ngIf="auth.isViewable('Connectors')"  routerLink="./conn_settings" routerLinkActive="selected" ariaCurrentWhenActive="page">Connectors</li>
                        <li *ngIf="auth.isViewable('Faxination Server Monitor')"  routerLink="./monitoring_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Monitoring</li>
                        <li *ngIf="auth.isViewable('Number Substitution')"  routerLink="./num_subst_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Number Substitution</li>
                        <li *ngIf="auth.isViewable('Kernel Contracts')"  routerLink="./contract_list" routerLinkActive="selected" ariaCurrentWhenActive="page">Connector Contracts</li>
                        <li *ngIf="auth.isViewable('APIKeys')"  routerLink="./apikeys" routerLinkActive="selected" ariaCurrentWhenActive="page">API Keys</li>
                    </ul>
                </dd>
            </div>
            <div *ngIf="auth.isViewable('Service Request')">
                <dt ng-click="toggleSection('support')"> Support</dt>
                <dd >
                    <ul class="list">
                        <li  routerLink="./service_request" routerLinkActive="selected" ariaCurrentWhenActive="page">Service Request</li>
                    </ul>
                </dd>
            </div>
        </nav>
    </dl>
