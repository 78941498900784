<section class="modal wide" id="atna_add_cert">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Add Certificate</h1>
    </div>
    <form id="atna_certs_form" class="form" novalidate (submit)="save()" name="atnaCertsForm" [hidden]="!isReady">
        <div class="modal-body">
            <div class="field">
                <input type="radio" name="cert" [(ngModel)]="state.activeRadio" id="cert_upload" value="upload">
                <label for="cert_upload">Upload a certificate file to {{ editee.LocalMachine }}</label><br>
            </div>
            <div class="field" [hidden]="state.activeRadio !=='upload'">
                <label>Filename:&nbsp;</label>
                <input type="file" id="cert_file" (change)="filechange($event)">
            </div>
            <div class="field" [hidden]="state.activeRadio !== 'upload'">
                <label>Password:&nbsp;</label>
                <input type="password" name="UploadPassword" autocomplete="off" [(ngModel)]="uploadPassword">
            </div>
            <br>
            <div *ngIf="AtnaStoreCerts.length > 0">
                <div class="field">
                    <input type="radio" name="cert" [(ngModel)]="state.activeRadio" id="cert_store" value="store">
                    <label for="cert_store">Add a reference from the Certificate Store on {{ editee.LocalMachine }}</label><br>
                </div>
                <ul [hidden]="state.activeRadio !== 'store'">
                    <li *ngFor="let store of AtnaStoreCerts" style="list-style:none">
                        <i [ngClass]="['glyphicon', nodeActionCssClass(store), 'tree-node-toggle']" (click)="toggleStore(store)"></i>
                        <span (click)="selectStore(store)">
                            <span [class.selected]="store === selectedStore">&nbsp;&nbsp;{{ store.StoreName }}</span>
                        </span>
                        <table class="table table-condensed table-bordered" *ngIf="store.expanded">
                            <thead>
                                <tr>
                                    <th width="25px"></th>
                                    <th>Issued To</th>
                                    <th style="width:160px">Effective Date</th>
                                    <th style="width:160px">Expiration Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let crt of store.Certificates">
                                    <td><input type="checkbox" name="CertSelected" [(ngModel)]="crt.selected" (click)="$event.stopPropagation()"></td>
                                    <td title="{{ crt.IssuedTo }}">{{ crt.IssuedTo }}</td>
                                    <td>{{ crt.EffectiveDate }}</td>
                                    <td>{{ crt.ExpirationDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">OK</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
