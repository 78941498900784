import { CommonModule } from '@angular/common';
import { Component, inject, AfterViewInit, ViewChild } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { IPaginationQuery } from '../pagination/pagination.component';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestFaxLineLimitsDef, FaxConfigRestKernelAtnaCertificate,
    FaxConfigRestKernelAtnaLogging, FaxConfigRestKernelFaxRetrySpeeds, FaxConfigRestKernelLoopbackServices,
    FaxConfigRestKernelNumberFormat, FaxConfigRestNumSubstCountryList, FaxConfigRestSmtpGeneral } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { AtnaAddCertComponent, IAtnaAddCertEditee } from '../atna-add-cert/atna-add-cert.component';
import * as _ from 'underscore';
import { FaxLineLimitsEditComponent, IFaxLineLimitsEditee } from '../faxlinelimits-edit/faxlinelimits-edit.component';

@Component({
    selector: 'app-faxination-server',
    imports: [FormsModule, CommonModule, PaginationModule],
    templateUrl: './faxination-server.component.html',
    styleUrl: './faxination-server.component.css'
})
export class FaxinationServerComponent implements AfterViewInit, IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    FaxLineLimits_pager!: PaginationComponent;
    public FaxLineLimits_query: IPaginationQuery = this;

    isReady: boolean = false;
    isModifiableKernelProps: boolean = false;
    isModifiableFaxLineLimits: boolean = false;
    PdfXmp = { Enabled: false };
    Smtp: FaxConfigRestSmtpGeneral = {};
    numberFormat: FaxConfigRestKernelNumberFormat = {};
    faxRetrySpeeds: FaxConfigRestKernelFaxRetrySpeeds = {};
    numSubstCountries: FaxConfigRestNumSubstCountryList = [];
    faxLoopbackServices: FaxConfigRestKernelLoopbackServices = [];
    newLoopbackService = { name: '' };

    pageTabs: string[] = [];
    state: { activePageTab: string } = { activePageTab: '' };

    Atna: FaxConfigRestKernelAtnaLogging = {};
    AtnaTimeoutSecs: number = 0;
    AtnaGeneralChanged: boolean = false;

    atnaTabs = {
        pageTabs: ['General', 'Client Certificates', 'Trusted Server Certificates'],
        activePageTab: 'General'
    }

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
    }

    ngAfterViewInit() {
        this.session.rootPromises.subscribe(() => {
            if (this.auth.isViewable('Kernel Properties')) {
                this.pageTabs.push('ATNA Logging');
                this.pageTabs.push('SMTP');
            }
            if (this.auth.isViewable('Fax Line Limits')) {
                this.pageTabs.push('Fax Line Limits');
            }
            if (this.auth.isViewable('Kernel Properties')) {
                this.pageTabs.push('Number Format');
                this.pageTabs.push('Fax Retry Speeds');
                this.pageTabs.push('Fax Loopback Services');
            }
            this.state.activePageTab = this.pageTabs[0];
            this.isModifiableKernelProps = this.auth.isModifiable('Kernel Properties');
            this.isModifiableFaxLineLimits = this.auth.isModifiable('Fax Line Limits');
            this.refresh();
        });
    }

    refresh(): void {
        if (this.auth.isViewable('Kernel Properties')) {
            let promises: Observable<boolean>[] = [
                this.faxSrv.GetAtnaLogging().pipe(map(res => {
                    this.Atna = res;
                    this.AtnaTimeoutSecs = this.Atna.TimeoutMS?? 0;
                    this.AtnaTimeoutSecs = this.AtnaTimeoutSecs / 1000;
                    return true;
                })),
                this.faxSrv.GetSmtpGeneral().pipe(map(res => {
                    this.Smtp = res;
                    return true;
                })),
                this.faxSrv.numSubstCountries().pipe(map(countries => {
                    this.numSubstCountries = countries;
                    this.numSubstCountries[0].Name = '';
                    return true;
                })),
                this.faxSrv.GetKernelNumberFormat().pipe(map(res => {
                    this.numberFormat = res;
                    return true;
                })),
                this.faxSrv.GetKernelFaxRetrySpeeds().pipe(map(res => {
                    this.faxRetrySpeeds = res;
                    return true;
                })),
                this.faxSrv.getKernelLoopbackServices().pipe(map(res => {
                    this.faxLoopbackServices = res;
                    return true;
                }))
            ];
            forkJoin(promises).subscribe({
                next: () => {
                    this.isReady = true;
                    this.FaxLineLimits_pager.reset();
                }
            });
        } else if (this.auth.isViewable('Fax Line Limits')) {
            this.isReady = true;
            this.FaxLineLimits_pager.reset();
        }
    }

    SmtpApply(): void {
        let def: FaxConfigRestSmtpGeneral = { 
            Server: this.Smtp.Server,
            Port: this.Smtp.Port,
            UseSSL: this.Smtp.UseSSL,
            Username: this.Smtp.Username,
            Password: this.Smtp.Password
        };
        this.faxSrv.PutSmtpGeneral(def).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    /* ATNA logging */

    AddClientCert(): void {
        let def: IAtnaAddCertEditee = {
            LocalMachine: this.Atna.LocalMachine?? '',
            bIsClientCert: true,
            Certificates: this.Atna.ClientCertificates?? []
        };
        const dialogRef = this.dialog.open(AtnaAddCertComponent, {data: { editee: def }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh(); }
        });
    }

    DelClientCert(): void {
        if (this.Atna.ClientCertificates) {
            let def: FaxConfigRestKernelAtnaLogging = {
                ClientCertificates: _.reject(this.Atna.ClientCertificates, cert => { return cert.selected === true; })
            };
            this.faxSrv.UpdateAtnaLogging(def).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.Atna.ClientCertificates = def.ClientCertificates;
                }
            });
        }
    }

    AddServerCert(): void {
        let def: IAtnaAddCertEditee = {
            LocalMachine: this.Atna.LocalMachine?? '',
            bIsClientCert: false,
            Certificates: this.Atna.TrustedServerCertificates?? []
        };
        const dialogRef = this.dialog.open(AtnaAddCertComponent, {data: { editee: def }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.refresh(); }
        });
    }

    DelServerCert(): void {
        if (this.Atna.TrustedServerCertificates) {
            let def: FaxConfigRestKernelAtnaLogging = {
                TrustedServerCertificates: _.reject(this.Atna.TrustedServerCertificates, cert => { return cert.selected === true; })
            };
            this.faxSrv.UpdateAtnaLogging(def).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.Atna.TrustedServerCertificates = def.TrustedServerCertificates;
                }
            });
        }
    }

    certCssStyle(cert: FaxConfigRestKernelAtnaCertificate): object {
        if (cert.IssuedTo && cert.IssuedTo.indexOf('<Invalid certificate: ') === 0) {
            return { "color": "red" };
        } else {
            return {};
        }
    }

    AtnaGeneralChange(): void {
        this.AtnaGeneralChanged = true;
    }

    AtnaApply(): void {
        let def: FaxConfigRestKernelAtnaLogging = {
            Enabled:   this.Atna.Enabled,
            Server:    this.Atna.Server,
            Port:      this.Atna.Port,
            Protocol:  this.Atna.Protocol,
            TimeoutMS: this.AtnaTimeoutSecs * 1000
        };
        this.faxSrv.UpdateAtnaLogging(def).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.Atna.TimeoutMS = def.TimeoutMS;
                this.AtnaGeneralChanged = false;
            }
        });
    }

    /* Fax Line Limits */

    invoke(offset: number, limit: number) {
        return this.faxSrv.GetFaxLineLimitsDefinitions(offset, limit);
    }

    FaxLineLimits_add(): void {
        const dialogRef = this.dialog.open(FaxLineLimitsEditComponent, {data: { editee: null }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.FaxLineLimits_pager.refresh(); }
        });
    }

    FaxLineLimits_edit(item: FaxConfigRestFaxLineLimitsDef): void {
        let def: IFaxLineLimitsEditee = {
            Enabled: item.Enabled?? false,
            FaxNumber: item.FaxNumber?? '',
            MaxLines: item.MaxLines?? 1,
            Description: item.Description?? ''
        };
        const dialogRef = this.dialog.open(FaxLineLimitsEditComponent, {data: { editee: def }});
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) { this.FaxLineLimits_pager.refresh(); }
        });
    }

    FaxLineLimits_del(): void {
        if (this.FaxLineLimits_pager) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.FaxLineLimits_pager.items, (item: FaxConfigRestFaxLineLimitsDef) => {
                if (item.selected && item.FaxNumber) {
                    promises.push(this.faxSrv.DeleteFaxLineLimitsDefinition(item.FaxNumber));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    this.fenUtils.afterSave(res);
                    this.FaxLineLimits_pager.refresh();
                });
            }
        }
    }

    private FaxLineLimits_update(enabled: boolean): void {
        if (this.FaxLineLimits_pager) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(this.FaxLineLimits_pager.items, (item: FaxConfigRestFaxLineLimitsDef) => {
                if (item.selected && item.FaxNumber) {
                    promises.push(this.faxSrv.PutFaxLineLimitsDefinition(item.FaxNumber, { Enabled: enabled }));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe(res => {
                    this.fenUtils.afterSave(res);
                    this.FaxLineLimits_pager.refresh();
                });
            }
        }
    }

    FaxLineLimits_enable(): void {
        this.FaxLineLimits_update(true);
    }

    FaxLineLimits_disable(): void {
        this.FaxLineLimits_update(false);
    }

    /* Number Format */

    numberFormatApply(): void {
        let def: FaxConfigRestKernelNumberFormat = {
            E164Conversion: this.numberFormat.E164Conversion,
            E164Required: this.numberFormat.E164Required,
            DefaultCountryISO2: this.numberFormat.DefaultCountryISO2?? ''
        };
        this.faxSrv.PutKernelNumberFormat(def).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    /* Fax Retry Speeds */

    faxRetrySpeedsApply(): void {
        this.faxSrv.PutKernelFaxRetrySpeeds(this.faxRetrySpeeds).subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    /* Fax Loopback Services */

    FaxLoopback_add(): void {
        if (this.newLoopbackService.name) {
            let def = this.faxLoopbackServices.concat(this.newLoopbackService.name.trim().toUpperCase());
            this.faxSrv.updateKernelLoopbackServices(def).subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.faxLoopbackServices = def;
                    this.newLoopbackService.name = '';
                }
            });
        }
    }

    FaxLoopback_duplicate(): boolean {
        if (this.newLoopbackService.name) {
            let upper: string = this.newLoopbackService.name.trim().toUpperCase();
            let found = _.find(this.faxLoopbackServices, item => { return (item.trim().toUpperCase() === upper); });
            return found ? true : false;
        }
        return false;
    }

    FaxLoopback_add_enabled(): boolean {
        let elem: HTMLInputElement;
        elem = document.getElementById('new_loopback_service') as HTMLInputElement;
        if (this.newLoopbackService.name) {
            let isDuplicate = this.FaxLoopback_duplicate();
            elem?.setCustomValidity(isDuplicate? 'duplicate': '');
            return !isDuplicate;
        } else {
            elem?.setCustomValidity('');
            return false;
        }
    }

    FaxLoopback_del(index: number): void {
        let def = this.faxLoopbackServices.filter((item, idx) => { return idx !== index; });
        this.faxSrv.updateKernelLoopbackServices(def).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.faxLoopbackServices = def;
            }
        });
    }
}
