import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Output, HostListener, EventEmitter, afterNextRender, AfterRenderPhase, Renderer2 } from '@angular/core';
import { timer } from 'rxjs';
import { FormsModule } from '@angular/forms';
import * as _ from 'underscore';

interface IFilenameTemplateField {
    Group?: string;
    Field?: string;
    Display?: string;
    Preview?: string;
    Selected?: boolean;
}

@Component({
    selector: 'app-filename-template',
    imports: [FormsModule, CommonModule],
    templateUrl: './filename-template.component.html',
    styleUrl: './filename-template.component.css'
})
export class FilenameTemplateComponent {

    @Input({ required: true }) model: string | null | undefined;
    @Input({ required: true }) options!: IFilenameTemplateField[];
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Output() modelChange = new EventEmitter<string>();

    wrapper!: HTMLDivElement;
    inner!: HTMLDivElement;
    list!: HTMLUListElement;
    expanded: boolean = false;
    text_active_focus_position: number = 0;
    text_active: HTMLTextAreaElement | null = null;

    constructor(private elem: ElementRef, private renderer: Renderer2) {
        afterNextRender(() => {
            this.wrapper = elem.nativeElement.querySelector('.template_wrapper');
            this.inner = this.wrapper.querySelectorAll('div')[0];
            this.list = this.wrapper.querySelectorAll('ul')[0];
            this.model = this.model?? '';
            this.render(this.model);
        }, {phase: AfterRenderPhase.Write});
    }

    onWrapperClick(target: any): void {
        if (target as HTMLElement) {
            timer(0).subscribe(() => {
                if (target.className === 'template_wrapper' || target.className === 'template_inner') {
                    let txtLast = this.inner.querySelectorAll('textarea:last-child')[0] as HTMLTextAreaElement;
                    if (txtLast) {
                        this.setCursorToTextEnd(txtLast);
                        this.text_active = txtLast;
                        this.text_active_focus_position = this.getCursorPosition(txtLast);
                    }
                }
            });
        }
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(target: any): void {
        if (target as HTMLElement) {
            timer(0).subscribe(() => {
                if (!this.isParent(this.wrapper, target) &&
                    !this.isParent(this.list, target)) {
                    this.setExpanded(false);
                }
            });
        }
    }

    private isParent(parent: HTMLElement, element: HTMLElement | null): boolean {
        while (element) {
            element = element.parentElement;
            if (element === parent) return true;
        }
        return false;
    }

    select(o: IFilenameTemplateField): void {
        timer(0).subscribe(() => {
            for (let idx = 0; idx < this.options.length; idx++) {
                if (this.options[idx].Field) {
                    this.options[idx].Selected = (this.options[idx].Field === o.Field);
                }
            }
            if (!this.text_active) {
                let txtLast = this.inner.querySelectorAll('textarea:last-child')[0] as HTMLTextAreaElement;
                if (txtLast) {
                    txtLast.selectionStart = 10000;
                    txtLast.selectionEnd = 10000;
                    txtLast.focus();
                    this.text_active = txtLast;
                    this.text_active_focus_position = this.getCursorPosition(txtLast);
                }
            }
            if (this.text_active && this.text_active_focus_position >= 0) {
                let sPrev = this.text_active.value.substring(0, this.text_active_focus_position);
                let sNext = this.text_active.value.substring(this.text_active_focus_position, this.text_active.value.length);
                this.render_text(sPrev, this.text_active);
                this.render_field(o.Field!, o.Preview!, this.text_active);
                this.text_active.value = sNext;
                this.text_active.selectionStart = 0;
                this.text_active.selectionEnd = 0;
                this.text_active_focus_position = 0;
                this.resize_text(this.text_active, 'focus');
                this.text_active.focus();
                this.update_model();
            }
        });
    }

    setExpanded(expanded: boolean): void {
        this.expanded = expanded;
        if (this.expanded) {
            this.list.classList.remove('hide');
        } else {
            this.list.classList.add('hide');
        }
    }

    remove_field(element: HTMLElement): void {
        this.renderer.removeChild(this.inner, element);
        this.merge_consecutive_texts();
        this.update_model();
    }

    resize_text(element: HTMLTextAreaElement, state: string = '') {
        let elmInput = this.wrapper.querySelector('.template_item_style');
        let styleInput = window.getComputedStyle(elmInput!);
        let elmHelper = this.renderer.createElement('span');
        elmHelper.setAttribute('class', 'calc_space');
        elmHelper.innerText = element.value;
        elmHelper.style.fontFamily = styleInput.fontFamily;
        elmHelper.style.fontSize = styleInput.fontSize;
        elmHelper.style.fontStyle = styleInput.fontStyle;
        elmHelper.style.maxWidth = this.wrapper.offsetWidth + 'pt';
        this.renderer.appendChild(document.body, elmHelper);

        let width = elmHelper.offsetWidth + 3;
        width = (state === 'focus') ? (width + 13) : width;
        width = Math.min(width, this.wrapper.offsetWidth);

        let height = elmHelper.offsetHeight + 3;
        height = (height < 21) ? 21 : height;

        element.style.width = width + 'pt';
        element.style.height = height + 'pt';
        this.renderer.removeChild(document.body, elmHelper);
    }

    merge_consecutive_texts(): void {
        let elmList = this.inner.children;
        let elmCur  = null;
        let elmNext = null;
        for (let idx = elmList.length-1; idx >=0; idx--) {
            elmCur = elmList[idx] as HTMLTextAreaElement;
            if (elmNext && elmNext.className === 'template_item_text' && elmCur.className === 'template_item_text') {
                if (this.text_active == elmNext) {
                    this.text_active = elmCur;
                    this.text_active_focus_position = elmCur.value.length;
                    this.setCursorPosition(elmCur, elmCur.value.length);
                }
                elmCur.value += elmNext.value;
                this.renderer.removeChild(this.inner, elmNext);
                this.resize_text(elmCur);
            }
            elmNext = elmCur;
        } 
    }

    render_text(str: string, elmPos?: HTMLTextAreaElement): HTMLElement {
        let curText = this.renderer.createElement('textarea') as HTMLTextAreaElement;
        curText.setAttribute('class', 'template_item_text');
        curText.rows = 1;
        curText.value = str;
        curText.dir = 'ltr';
        (curText as any).autocorrect = false;   // Safari only?
        curText.autocapitalize = 'off';
        curText.autocomplete = 'off';
        curText.spellcheck = false;
        let elmInput = this.wrapper.querySelector('.template_item_style');
        let styleInput = window.getComputedStyle(elmInput!);
        curText.style.fontFamily = styleInput.fontFamily;
        curText.style.fontSize = styleInput.fontSize;
        curText.style.fontStyle = styleInput.fontStyle;
        this.resize_text(curText);

        curText.addEventListener("focusout", (event: any) => {
            return !(event.relatedTarget?.className === 'template_item_text');
        });

        curText.onclick = () => {
            timer(0).subscribe(() => {
                this.text_active = curText;
                this.text_active_focus_position = this.getCursorPosition(curText);
                this.resize_text(curText, 'focus');
                this.setExpanded(true);
                return false;
            });
        };

        curText.onfocus = () => {
            timer(0).subscribe(() => {
                this.text_active = curText;
                this.text_active_focus_position = this.getCursorPosition(curText);
                this.resize_text(curText, 'focus');
                this.setExpanded(true);
            });
        };

        curText.onblur = (event: any) => {
            timer(0).subscribe(() => {
                this.resize_text(curText, 'blur');
            });
        }

        curText.onkeyup = (event: KeyboardEvent) => {
            return this.process_keyup(curText, event)
        }
    
        curText.onkeydown = (event: KeyboardEvent) => {
            return this.process_keydown(curText, event)
        }
    
        if (!elmPos) {
            this.inner.appendChild(curText);
        } else {
            this.inner.insertBefore(curText, elmPos)
        }
        return curText;
    }

    render_field(field: string, preview: string, elmPos?: HTMLElement): HTMLSpanElement {
        let curField = this.renderer.createElement('span') as HTMLSpanElement;

        curField.setAttribute('class', 'template_item_field');
        curField.setAttribute('tag', field);
        curField.innerText = preview;
        let elmInput = this.wrapper.querySelector('.template_item_style');
        let styleInput = window.getComputedStyle(elmInput!);
        curField.style.fontFamily = styleInput.fontFamily;
        curField.style.fontSize = styleInput.fontSize;
        curField.style.fontStyle = styleInput.fontStyle;
        let curFieldClose = this.renderer.createElement('button') as HTMLButtonElement;
        curFieldClose.setAttribute('type', 'button');
        curFieldClose.setAttribute('class', 'template_item_remove');
        curFieldClose.innerText = 'x';
        curFieldClose.onclick = () => {
            this.remove_field(curField);
        };
        curField.appendChild(curFieldClose);
        if (!elmPos) {
            this.renderer.appendChild(this.inner, curField);
        } else {
            this.renderer.insertBefore(this.inner, curField, elmPos);
        }

        return curField;
    }

    render(str: string): void {
        this.inner.innerHTML = '';
        let buf = '';
        let inEscape = false;
        let inField  = false;
        let field = '';
        for (let idx = 0; idx < str.length; idx++) {
            let c = str.charAt(idx);
            if (c==='\\' && !inEscape) {
                inEscape = true;
            } else if (inEscape) {
                inEscape = false;
                buf += c;
            } else if (c=='{' && !inField) {
                this.render_text(buf);
                inField = true;
                field = '';
            } else if (c=='}' && inField) {
                inField = false;
                buf = '';
                let option = _.findWhere(this.options, { Field: '{'+field+'}' });
                if (option) {
                    this.render_field(option.Field!, option.Preview!);
                }
            } else if (inField) {
                field += c;
            } else {
                buf += c;
            }
        }
        this.render_text(buf);
    }

    update_model(): void {
        timer(0).subscribe(() => {
            let model: string = '';
            let items = this.inner.querySelectorAll('.template_item_text,.template_item_field');
            items.forEach((item: Element) => {
                if (item.className === 'template_item_text') {
                    let str = (item as HTMLTextAreaElement).value?? '';
                    // Note: the string replaceAll method is not supported on Internet Explorer 11.
                    str = str.replace(/\\/g, '\\\\');
                    str = str.replace(/{/g, '\\{');
                    str = str.replace(/}/g, '\\}');
                    model += str;
                }
                else if (item.className === 'template_item_field') {
                    let val: string = item.attributes.getNamedItem('tag')?.value?? '';
                    model += val;
                }
            });
            this.model = model;
            this.modelChange.emit(this.model);
        });
    }

    process_keyup(element: HTMLTextAreaElement, event: KeyboardEvent): boolean {
        this.resize_text(element, 'focus');
        this.text_active = element;
        this.text_active_focus_position = this.getCursorPosition(element);
        this.update_model();
        return true;
    }

    process_keydown(element: HTMLTextAreaElement, event: KeyboardEvent): boolean {
        this.resize_text(element, 'focus');
        this.text_active = element;
        this.text_active_focus_position = this.getCursorPosition(element);

        if (event.key === 'Backspace') {
            if (this.text_active_focus_position === 0) {
                // Check if the previous element is a field, if yes then delete it
                if (element.selectionStart === element.selectionEnd) {
                    let elmPrev = element.previousElementSibling;
                    if (elmPrev && elmPrev.className === 'template_item_field') {
                        this.renderer.removeChild(this.inner, elmPrev);
                        this.merge_consecutive_texts();
                        this.update_model();
                        return false;
                    }
                }
            }
        }

        if (event.key === 'Delete' || event.key === 'Del') {
            if (this.text_active_focus_position === this.text_active?.value.length) {
                // Check if the next element is a field, if yes then delete it
                if (element.selectionStart === element.selectionEnd) {
                    let elmNext = element.nextElementSibling;
                    if (elmNext && elmNext.className === 'template_item_field') {
                        this.renderer.removeChild(this.inner, elmNext);
                        //this.inner.removeChild(elmNext);
                        this.merge_consecutive_texts();
                        this.update_model();
                        return false;
                    }
                }
            }
        }

        if (event.key === 'ArrowLeft' || event.key === 'Left') {
            if (this.text_active_focus_position === 0) {
                // Go to previous textarea
                if (element.selectionStart === element.selectionEnd) {
                    let elmPrev = element.previousElementSibling as HTMLTextAreaElement;
                    while (elmPrev) {
                        if (elmPrev.className !== 'template_item_text') {
                            elmPrev = elmPrev.previousElementSibling as HTMLTextAreaElement;
                        } else {
                            this.setCursorToTextEnd(elmPrev);
                            this.text_active = elmPrev;
                            this.text_active_focus_position = elmPrev.value.length;
                            return false;
                        }
                    }
                }
            }
        }

        if (event.key === 'ArrowRight' || event.key === 'Right') {
            if (this.text_active_focus_position === this.text_active?.value.length) {
                // Go to next textarea
                if (element.selectionStart === element.selectionEnd) {
                    let elmNext = element.nextElementSibling as HTMLTextAreaElement;
                    while (elmNext) {
                        if (elmNext.className !== 'template_item_text') {
                            elmNext = elmNext.nextElementSibling as HTMLTextAreaElement;
                        } else {
                            this.setCursorPosition(elmNext, 0);
                            this.text_active = elmNext;
                            this.text_active_focus_position = 0;
                            return false;
                        }
                    }
                }
            }
        }

        if (event.key === 'ArrowDown' || event.key == 'Down' || event.key === 'ArrowUp' || event.key === 'Up') {
            // Select next/previous item in list of fields
            let idxOld = -1;
            for (let idx = 0; idx < this.options.length; idx++) {
                if (this.options[idx].Selected) {
                    idxOld = idx;
                }
                this.options[idx].Selected = false;
            }
            let idxNew = idxOld;
            if (event.key === 'ArrowDown' || event.key == 'Down') {
                do {
                    idxNew++;
                    if (idxNew >= this.options.length) {
                        idxNew = 0;
                    }
                }
                while (!this.options[idxNew].Field && idxNew != idxOld);
            } else {
                do {
                    idxNew--;
                    if (idxNew < 0) {
                        idxNew = this.options.length-1;
                    }
                }
                while (!this.options[idxNew].Field && idxNew != idxOld);
            }
            this.options[idxNew].Selected = true;
            return false;                
        }     
        
        if (event.key === 'Enter') {
            // Apply the selected field
            let idxSel = -1;
            for (let idx = 0; idx < this.options.length; idx++) {
                if (this.options[idx].Selected) {
                    this.select(this.options[idx]);
                    break;
                }
            }
            return false;
        }

        // if another key than the arrow or enter keys is pressed, then remove the selection in the fields list
        for (let idx = 0; idx < this.options.length; idx++) {
            this.options[idx].Selected = false;
        }

        if (event.key === 'Home') {
            // Go to start of first textarea
            let txtFirst = this.inner.querySelectorAll('textarea:first-child')[0] as HTMLTextAreaElement;
            if (txtFirst) {
                this.setCursorPosition(txtFirst, 0);
                this.text_active = txtFirst;
                this.text_active_focus_position = 0;
            }
        }
        
        if (event.key === 'End') {
            // Go to end of last textarea
            let txtLast = this.inner.querySelectorAll('textarea:last-child')[0] as HTMLTextAreaElement;
            if (txtLast) {
                this.setCursorToTextEnd(txtLast);
                this.text_active = txtLast;
                this.text_active_focus_position = this.getCursorPosition(txtLast);
            }
        }
        return true;
    }

    setCursorToTextEnd(element: HTMLTextAreaElement): void {
        let val = element.value;
        this.setCursorPosition(element, val.length);
    }

    setCursorPosition(element: HTMLTextAreaElement, pos: number): HTMLTextAreaElement {
        element.focus();
        if (element.selectionStart !== null) {
            element.selectionStart = pos;
            element.selectionEnd = pos;
        } else if (element.setSelectionRange) {
            element.setSelectionRange(pos, pos);
        }
        return element;
    }

    getCursorPosition(element: HTMLTextAreaElement): number {
        let pos: number = 0;
        if (element.selectionStart !== null) {
            pos = element.selectionStart;
        }
        return pos;
    }
}
