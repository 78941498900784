import { FaxConfigRestUser, FaxProps, SMSProps, MMSProps } from "../api/api";
import * as _ from 'underscore';

export class UtlClass { 

    constructor() {
    }

    parseRFC3339(s: string | null | undefined ): number | null {
        // This interprets the input string as a UTC timestamp, and returns the number of milliseconds since 1 Jan 1970 UTC.
        var regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?Z$/ // optional milliseconds
        var m = regex.exec(s || "");
        return m
            ? Date.UTC(parseInt(m[1]), parseInt(m[2])-1, parseInt(m[3]), parseInt(m[4]), parseInt(m[5]), parseInt(m[6])) // month is 0-based.
            : null;
    }

    parseRFC3339Local(s: string | null | undefined ): number | null {
        // This interprets the input string as a local time, and returns the number of milliseconds since 1 Jan 1970 UTC.
        var regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?Z$/ // optional milliseconds
        var m = regex.exec(s || "");
        return m
            ? new Date(parseInt(m[1]), parseInt(m[2])-1, parseInt(m[3]), parseInt(m[4]), parseInt(m[5]), parseInt(m[6])).getTime() // month is 0-based.
            : null;
    }

    collect(xs: any[], fn: Function): any[] {
        let res: any[] = [];
        _.each(xs, function(x, index, xs) {
            fn.call(null, function(y: any) { res.push(y) }, x, index, xs);
        });
        return res;
    }

    private cmp(a: number, b: number): number {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    }

    /* Compares two arrays in lexicographic order. */
    private cmpArr(as: any, bs: any): number {
        for (let i = 0, n = Math.min(as.length, bs.length); i < n; i++) {
            var a = as[i],
                b = bs[i];
            if (a < b) { return -1; }
            if (a > b) { return 1; }
        }
        return this.cmp(as.length, bs.length);
    }

    /* Creates a comparator function that compares two objects by a list of properties.
        keys - each element is a key name or a function */
	comparatorBy(keys: any[]): Function {
        const pThis = this;
        keys = _.map(keys, function(k) {
            return typeof k === 'string' ? function(o: { [x: string]: any; }) { return o[k]; } : k;
        });
        function vals(o: any, keys: any) {
            return _.map(keys, function(k) { return k(o) });
        }
        return function(a: any, b: any) {
            return pThis.cmpArr(vals(a, keys), vals(b, keys));
        };
    }

    /* Immutable counterpart of Array.prototype.sort */
    sorted(array: any, comparator: any): any {
        let res = array.slice(); // clone
        return res.sort(comparator);
    }

    /* Return the filespec s without the specified extension */
    baseName(s: string, ext: string): string {
        return s.substring(s.length - ext.length) === ext ? s.substring(0, s.length - ext.length) : s
    }

    /* Set/get the position of the specified element */
    offset(element: HTMLElement, offset?: UtlVec): UtlVec {
        if (offset) { // set
            element.style.left = offset.x + 'px';
            element.style.top = offset.y + 'px';
            return offset;
        } else { // get
            return new UtlVec(element.offsetLeft, element.offsetTop);
        }
    }

    /* Set/get the size of the specified element */
    dim(element: HTMLElement, dim?: UtlVec): UtlVec {
        if (dim) { // set
            element.style.width = dim.x + 'px';
            element.style.height = dim.y + 'px';
            return dim;
        } else { // get
            return new UtlVec(element.clientWidth, element.clientHeight);
        }
    }

    /* Return the mouse position of the specified event */
    eventOffset(event: MouseEvent): UtlVec {
        return new UtlVec(
            event.pageX || event.clientX + document.documentElement.scrollLeft,
            event.pageY || event.clientY + document.documentElement.scrollTop
        );
    }

    /* Handle dragging/dropping coversheet elements */
    drag(mousedownEvent: MouseEvent, handlers: { ondragstart?: Function, ondrag: Function, ondrop: Function }) {
        var onMouseMoveGesture = (e: MouseEvent) => {
            let absDelta = this.eventOffset(e).minus(mousedownOffset).map(Math.abs)
            if (absDelta.x > 1 || absDelta.y > 1) {
                    // stop gesture
                    document.onmousemove = null;
                    document.onmouseup = null;
                    // start dragging
                    if (handlers.ondrag) {
                        document.onmousemove = onMouseMoveDrag;
                    }
                    document.onmouseup = onMouseUpDrag;
                    if (handlers.ondragstart) {
                        handlers.ondragstart(e)
                    }
            }
        }
        var onMouseUpGesture = (/*e*/) => {
            document.onmousemove = null;
            document.onmouseup = null;
        }
        var onMouseMoveDrag = (e: MouseEvent) => {
            handlers.ondrag(e)
        }
        var onMouseUpDrag = (e: MouseEvent) => {
            if (handlers.ondrop) {
                handlers.ondrop(e)
            }
            document.onmousemove = null;
            document.onmouseup = null;
        }
        var mousedownOffset = this.eventOffset(mousedownEvent);
        document.onmousemove = onMouseMoveGesture;
        document.onmouseup = onMouseUpGesture;
    }
}

export interface IPeriodUnit {
    seconds: number,
    unit: string
}

interface IPeriod {
    duration: string,
    unit: IPeriodUnit
}

interface ILimit {
    isActive: boolean,
    amount: string,
    period: IPeriod
}

export interface IPeriodLimits {
    fax: ILimit,
    sms: ILimit,
    mms: ILimit
}

export class CFenLimits {
    /* Class for converting API limits to GUI limits and vice versa */

    periodUnits: IPeriodUnit[] = [
        { seconds: 1, unit: 'second(s)' },
        { seconds: 60, unit: 'minute(s)' },
        { seconds: 60 * 60, unit: 'hour(s)' },
        { seconds: 60 * 60 * 24, unit: 'day(s)' },
        { seconds: 60 * 60 * 24 * 7, unit: 'week(s)' }
    ];

    constructor() {
    }

    /* Convert Limit and LimitPeriod to format suitable for the gui */
    limits(user: FaxConfigRestUser): IPeriodLimits {
        let fwdPeriodUnits: IPeriodUnit[] = this.periodUnits;
        let revPeriodUnits: IPeriodUnit[] = this.periodUnits.slice().reverse();

        let period = (LimitPeriod: number): IPeriod => {
            let u: IPeriodUnit = _.find(revPeriodUnits, pu => { return LimitPeriod % pu.seconds === 0; })?? this.periodUnits[0];
            return {
                duration: '' + LimitPeriod / u.seconds,
                unit: u
            };
        };

        let limit = (Limit: number | null, LimitPeriod: number | null): ILimit => {
            return (Limit === null || Limit === Math.pow(2, 32) - 1) ?
            {
                isActive: false,
                amount: '',
                period: { duration: '', unit: fwdPeriodUnits[0] }
            } : {
                isActive: true,
                amount: '' + Limit,
                period: period(LimitPeriod?? 0)
            };
        };

        return {
            fax: limit(user.Fax?.Limit?? null, user.Fax?.LimitPeriod?? null),
            sms: limit(user.SMS?.Limit?? null, user.SMS?.LimitPeriod?? null),
            mms: limit(user.MMS?.Limit?? null, user.MMS?.LimitPeriod?? null)
        };
    }

    /* Convert limits model back to the form suitable for FaxConfig api. */
    setLimits(user: FaxConfigRestUser, limits: IPeriodLimits): void {
        let setLimit = (obj: FaxProps | SMSProps | MMSProps | null | undefined, limit: ILimit) => {
            if (obj) {
                if (limit.isActive) {
                    obj.Limit = parseInt(limit.amount, 10);
                    obj.LimitPeriod = parseInt(limit.period.duration) * limit.period.unit.seconds;
                } else {
                    obj.Limit = Math.pow(2, 32) - 1;
                    obj.LimitPeriod = Math.pow(2, 32) - 1;
                }
            }
        }
        setLimit(user.Fax, limits.fax);
        setLimit(user.SMS, limits.sms);
        setLimit(user.MMS, limits.mms);
    }

    areLimitsValid(limits?: IPeriodLimits | null): boolean {
        if (limits) {
            let isValidLimit = (limit: ILimit): boolean => {
                return (!limit.isActive || (limit.amount && limit.period.duration))? true: false;
            }
            return isValidLimit(limits.fax)
                && isValidLimit(limits.sms)
                && isValidLimit(limits.mms);
        }
        return false;
    }
}

/* X/Y vector class for use by the coversheet editor */
export class UtlVec {
    x: number;
    y: number;

    constructor(x?: number, y?: number) {
        this.x = x?? 0;
        this.y = y?? 0;
    }

    minus(vec: UtlVec): UtlVec {
        return new UtlVec(this.x - vec.x, this.y - vec.y);
    }

    plus(vec: UtlVec): UtlVec {
        return new UtlVec(this.x + vec.x, this.y + vec.y);
    }

    /* Make sure the vector is inside specified bounds */
    bound(min: UtlVec, max: UtlVec): UtlVec {
        return this.vmax(min).vmin(max);
    }

    vmin(vec: UtlVec): UtlVec {
        return new UtlVec(
                        Math.min(this.x, vec.x),
                        Math.min(this.y, vec.y));
    }

    vmax(vec: UtlVec): UtlVec {
        return new UtlVec(
                        Math.max(this.x, vec.x),
                        Math.max(this.y, vec.y));
    }

    scale(n: number): UtlVec {
        return new UtlVec(this.x * n, this.y * n);
    }

    map(fn: Function): UtlVec {
        return new UtlVec(fn(this.x), fn(this.y))
    }
}
