<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ action }} Outbound Service Management Rule</h1>
    </div>
    <form id="osmForm" class="form" #osmForm="ngForm" novalidate (submit)="save()" *ngIf="isReady">
        <div class="modal-body">
            <div class="field">
                <label>Rule name: *</label>
                <input type="text" maxlength="10" name="Name" [(ngModel)]="rule.Name" required [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Description:</label>
                <input type="text" maxlength="255" name="Description" [(ngModel)]="rule.Description" size="40" [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Routed Service: *</label>
                <input type="text" maxlength="100" name="RoutedService" [(ngModel)]="rule.RoutedService" required [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Destination Service: *</label>
                <input type="text" maxlength="255" name="DestinationService" [(ngModel)]="rule.DestinationService" required [disabled]="!isModifiable">
            </div>
            <div class="field">
                <label>Criteria Type: *</label>
                <select name="CriteriaType" [(ngModel)]="rule.CriteriaType" required [disabled]="!isModifiable">
                    <option *ngFor="let ct of criteriaTypes | keyvalue" [value]="ct.key">{{ ct.value.display }}</option>
                </select>
            </div>
            <div class="field">
                <label>Criteria Value: *</label>
                <input type="text" maxlength="255" name="CriteriaValue" [(ngModel)]="rule.CriteriaValue" size="40" required [disabled]="!isModifiable">
                <div class="text-muted" *ngIf="rule.CriteriaType == 'DT'">e.g. 09:00-12:00</div>
                <div class="text-muted" *ngIf="rule.CriteriaType == 'FA'">e.g. attempts > 10 or e.g. attempts > 1 &amp;&amp; status != 200</div>
                <div class="text-muted" *ngIf="rule.CriteriaType == 'IM'">e.g. minutes >= 10</div>
            </div>
            <div class="field">
                <label>Message Type: *</label>
                <select name="MessageType" [(ngModel)]="rule.MessageType" required [disabled]="!isModifiable">
                    <option *ngFor="let mt of messageTypes" [value]="mt">{{ mt }}</option>
                </select>
            </div>
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="rule.Enabled" [disabled]="!isModifiable">
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="isModifiable" type="submit" class="btn btn-primary" [disabled]="!osmForm.valid">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
