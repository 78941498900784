import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-dialog-alert',
    imports: [FormsModule, CommonModule],
    templateUrl: './dialog-alert.component.html',
    styleUrl: './dialog-alert.component.css'
})
export class DialogAlertComponent {

    title: string;
    text: string;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.title = data.title?? '';
        this.text = data.text?? '';
    }

    close(): void {
        this.dialogRef.close();
    }
}
