<h1>Phone Numbers</h1>

<div *ngIf="isReady && !session.usePhonenumberAdministration()">
    <button type="button" class="btn pull-right" *ngIf="auth.isModifiable('All')" (click)="enablePhonenumberAdministration()">Configure...</button>
    <div class="addresses_help">
        <p>Phone number administration has been disabled.</p>
        <p>Phone number administration allows managing outbound service routing using proxy addresses in combination with multiple connections. 
            It also adds a layer of security.</p>
    </div>
</div>

<div *ngIf="isReady && session.usePhonenumberAdministration()">
    <ul class="nav nav-tabs" *ngIf="visiblePages(tabCtrl) > 1">
        <li *ngFor="let pageTab of visibleTabs(tabCtrl.pageTabs)" [class.active]="pageTab === tabCtrl.activePageTab">
            <a href="" (click)="setActivePageTab(pageTab);false">{{ pageTab.Caption }}</a>
        </li>
    </ul>
    <div *ngIf="tabCtrl.activePageTab?.Caption === tabCtrl.pageTabs[0].Caption">
        <ng-container *ngTemplateOutlet="page_addresses"></ng-container>
    </div>
    <div *ngIf="tabCtrl.activePageTab?.Caption === tabCtrl.pageTabs[1].Caption">
        <ng-container *ngTemplateOutlet="page_address_registration"></ng-container>
    </div>
</div>

<div *ngIf="!isReady">
    <p>Loading...</p>
</div>

<!-- Addresses tab -->
<ng-template #page_addresses>
    <button type="button" class="btn pull-right" *ngIf="auth.isModifiable('All')" (click)="setup_addresses()">Configure...</button>
    <div class="addresses_help">
        <p *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')">This page shows all phone numbers registered with all connections.</p>
        <p *ngIf="session.isMultiTenant() && !auth.isViewable('Organizations')">This page shows all phone numbers that can be assigned to users. Please contact Fenestrae if more phone numbers are required.</p>
        <p *ngIf="auth.isModifiable('Addresses') && auth.isViewable('Organizations')">To edit or delete a batch of numbers, all you need is to click on the first and shift-click on the last number for the batch. Then press the button for the batch operation to open the dialog that guides you through the next step of the batch operation.<br/>Please note, you can only delete phone numbers that are not attached to an organization.</p>
    </div>
    <div class="optionbar" id="addresses_optionbar">
        <form role="form">
            <span class="form-group has-feedback pull-right">
                <select class="form-control" [(ngModel)]="formDataAddresses.showOptions"
                (ngModelChange)="search_addresses()" [ngModelOptions]="{standalone: true}"
                *ngIf="session.isMultiTenant() && auth.isViewable('Organizations')">
                    <option value="1">Show attached</option>
                    <option value="2">Show unattached</option>
                    <option value="4">Show used</option>
                    <option value="5">Show unused</option>
                    <option value="3">Show all</option>
                </select>
                <select class="form-control" [(ngModel)]="formDataAddresses.showOptions"
                (ngModelChange)="search_addresses()" [ngModelOptions]="{standalone: true}"
                *ngIf="!session.isMultiTenant() || !auth.isViewable('Organizations')">
                    <option value="1">Show all</option>
                    <option value="4">Show used</option>
                    <option value="5">Show unused</option>
                </select>
                &ngsp;
                <select class="form-control" [(ngModel)]="formDataAddresses.showAddrType"
                (ngModelChange)="search_addresses()" [ngModelOptions]="{standalone: true}"
                *ngIf="addressTypes && addressTypes.length > 1">
                    <option *ngFor="let t of addressTypes">{{ t }}</option>
                </select>
            </span>
        </form>
    </div>
    <div class="btn-toolbar" *ngIf="auth.isModifiable('Addresses')">
        <div class="btn-group" *ngIf="addrListAddEnabled">
            <button type="button" class="btn" (click)="new_addresses()">Add...</button>
            <button type="button" class="btn" (click)="edit_addresses()" [disabled]="!addrListEditEnabled">Edit...</button>
            <button type="button" class="btn" (click)="delete_addresses()" [disabled]="!addrListRemoveEnabled">Remove...</button>
        </div>
        <button type="button" class="btn" *ngIf="addrListAddEnabled" (click)="upload_addresses()">Upload...</button>
        <button type="button" class="btn btn-link" *ngIf="!session.isMultiTenant() || auth.isViewable('Organizations')" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
    </div>
    <div class="btn-toolbar" *ngIf="auth.isModifiable('Addresses')">
        <div class="btn-group">
            <button type="button" class="btn" (click)="attach_addresses()" *ngIf="session.isMultiTenant() && auth.isViewable('Organizations')" [disabled]="!addrListAttachEnabled">Attach</button>
            <button type="button" class="btn" (click)="detach_addresses()" *ngIf="session.isMultiTenant() && auth.isViewable('Organizations')" [disabled]="!addrListDetachEnabled">Detach</button>
            <button type="button" class="btn" (click)="default_address()" [disabled]="!addrListDefaultEnabled">Set Default</button>
        </div>
    </div>
    <div class="fen_ag_grid_align" *ngIf="!auth.isModifiable('Addresses')"></div>
    <div *ngIf="addrListGridInitialized" id="addrListGrid" class="ag-theme-alpine">
        <ag-grid-angular [style]="addrListGridHeight" [gridOptions]="gridOptions.addressList!"></ag-grid-angular>
    </div>
</ng-template>

<!-- Registrations tab -->
<ng-template #page_address_registration>
    <div class="addresses_help">
        <p>Phone numbers listed here are pending registration. This is an automated mechanism to activate phone numbers the moment the first call to the number is received via the connection.</p>
        <p>It allows administrators to hook up phone numbers that belong to their organization's devices without the help of a system administrator.</p>
    </div>
    <div class="optionbar" id="addresses_optionbar">
        <form role="form">
            <span class="form-group has-feedback pull-right">
                <select class="form-control" [(ngModel)]="formDataAddressRegistration.state"
                (ngModelChange)="search_registrations()" [ngModelOptions]="{standalone: true}">
                    <option value="All">All</option>
                    <option value="New">New</option>
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                    <option value="Failed">Failed</option>
                    <option value="Disabled">Disabled</option>
                </select>
                &ngsp;
                <select class="form-control" [(ngModel)]="formDataAddressRegistration.showAddrType"
                (ngModelChange)="search_registrations()" [ngModelOptions]="{standalone: true}"
                *ngIf="addressTypes && addressTypes.length > 1">
                    <option *ngFor="let t of addressTypes">{{ t }}</option>
                </select>
            </span>
        </form>
    </div>
    <div class="btn-toolbar" *ngIf="auth.isModifiable('AddressRegistration')">
        <div class="btn-group">
            <button type="button" class="btn" (click)="new_address_registration()" [disabled]="disableAdd()">Add...</button>
            <button type="button" class="btn" (click)="edit_address_registration()" [disabled]="disableAddrRegEdit()">Edit...</button>
            <button type="button" class="btn" (click)="remove_address_registration()" [disabled]="!addrRegRemoveEnabled">Remove...</button>
        </div>
        <button type="button" class="btn" (click)="upload_address_registration()" [disabled]="disableAdd()">Upload...</button>
        <div class="btn-group">
            <button type="button" class="btn" (click)="set_address_registration(true)" [disabled]="disableBtnEnable()">Enable</button>
            <button type="button" class="btn" (click)="set_address_registration(false)" [disabled]="disableBtnDisable()">Disable</button>
        </div>
    </div>
    <div class="fen_ag_grid_align" *ngIf="!auth.isModifiable('AddressRegistration')"></div>
    <div *ngIf="addrRegGridInitialized" id="addrListGrid" class="ag-theme-alpine">
        <ag-grid-angular [style]="addrRegGridHeight" [gridOptions]="gridOptions.addressRegistration!"></ag-grid-angular>
    </div>
</ng-template>
