<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Select Organization</h1>
    </div>
    <form id="organization_select_form" class="form" novalidate (submit)="select_org()">
        <div class="modal-body">
            <form role="form" (submit)="search_organizations()">
                <div class="form-group has-feedback pull-left">
                    <i class="form-control-feedback glyphicon glyphicon-search" (click)="search_organizations()"></i>&ngsp;
                    <input type="text" class="form-control" [(ngModel)]="searchkeyOrganizations" name="searchkeyOrganizations" placeholder="Search" />
                </div>
                <div style="height: 15px;"></div>
            </form>
            <app-pagination [limit]="session.portalCfg.items_per_page" [query]="page"></app-pagination>
            <table id="organizations_list" class="table table-click table-condensed table-bordered" *ngIf="isReady">
                <thead>
                    <tr>
                        <th width="25px"></th>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    @for(org of pagerOrganizations.items; track org.ID) {
                        <tr (click)="editee=org">
                            <td><input type="radio" name="orgid" [ngModel]="editee?.ID" id="orgid_{{ org.ID }}" [value]="org.ID" [attr.value]="org.ID"></td>
                            <td>{{ org.Name }}</td>
                            <td>{{ org.Description }}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Select</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
