<h1>Host Connector Settings</h1>
<p>Choose a host connector from the list to configure settings for that host.</p>

<table class="table table-condensed table-bordered table-click" *ngIf="isReady">
    <tbody>
        <tr *ngFor="let host of connHosts" (click)="edit(host)">
            <td>{{ host.FriendlyName }}</td>
        </tr>
    </tbody>
</table>

<button type="button" class="btn" style="margin-top:25px;" (click)="edit_global_new_user_defaults()" *ngIf="isReady">
Edit Global New User Defaults...</button>
