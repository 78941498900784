<h1>Log</h1>

<div class="optionbar">
    <span class="pull-right">
        <p>
            <button type="button" class="btn" (click)="logag_format_config()">Preferences...</button>
        </p>
        <p>
            <button type="button" class="btn" (click)="logag_exportToCsv()">Export log data</button>
        <p>
    </span>
</div>
<div id="log_help">
    <p>
        View the transactions or messages that are processed by your Faxination Server.
        Filter your log on message type ({{ msgTypes.join('/') }}) and choose if the message was outbound or inbound.
        If the UTC creation time of the message is available, then it is converted to your local time for display.
        Otherwise the displayed time represents local time on the server machine.<br/>
        When requesting log entries from a specific time-frame, the results will include 
        the From date and time up to and including the To date and time.
    </p>
    <br/>
</div>

<form novalidate (submit)="filters_reset()" #filterForm1="ngForm" class="filterbar">
    <p>
        <input type="date" name="fromDate" placeholder="From date" [(ngModel)]="filter.Period_Date_Min" (ngModelChange)="dateChange()" title="Enter a starting date like: &quot;2024-07-14&quot;">&ngsp;
        <input type="time" name="fromTime" placeholder="From time" step="1" [(ngModel)]="filter.Period_Time_Min" (ngModelChange)="dateChange()" [disabled]="!filter.Period_Date_Min" title="Enter a starting time like: &quot;08:30:00 AM&quot;">
        <span>&nbsp;-&nbsp;</span>
        <input type="date" name="toDate" placeholder="To date" [(ngModel)]="filter.Period_Date_Max" (ngModelChange)="dateChange()" title="Enter an ending date like: &quot;2024-10-31&quot;">&ngsp;
        <input type="time" name="toTime" placeholder="To time" step="1" [(ngModel)]="filter.Period_Time_Max" (ngModelChange)="dateChange()"  [disabled]="!filter.Period_Date_Max" title="Enter an ending time like: &quot;05:30:00 PM&quot;">
        <span>&nbsp;on&nbsp;</span>
        <select name="filterPeriodField" [(ngModel)]="filter.Period_Field">
            <option value="CreationTime">Job create time</option>
            <option value="SubmissionTime">Job submit time</option>
            <option value="StartRecvTime">Start receive time</option>
        </select>
    </p>
    <p>
        <input type="text" name="searchKey" class="form-control" [(ngModel)]="searchkey" placeholder="Search" />
        <span>&nbsp;</span>
        <select name="filterDatePreset" [(ngModel)]="filter.Date_Preset" (ngModelChange)="datePresetChange()">
            <option value="">Selected dates</option>
            <option>Today</option>
            <option>Yesterday</option>
            <option>Last 7 days</option>
            <option>This month</option>
            <option>Last month</option>
        </select>
        <span>&nbsp;</span>
        <button class="btn" type="submit" [disabled]="!filterForm1.valid">Filter</button>
    </p>
</form>

<div *ngIf="gridInitialized" id="logGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="gridOptions"></ag-grid-angular>
</div>
