import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { AuthService } from '../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigRestAuditLogItem } from '../api/api';
import * as _ from 'underscore';

interface IEditee extends FaxConfigRestAuditLogItem {
    section_active: boolean
}

@Component({
    selector: 'app-auditlog-item',
    imports: [FormsModule, CommonModule],
    templateUrl: './auditlog-item.component.html',
    styleUrl: './auditlog-item.component.css'
})
export class AuditlogItemComponent {

    editee: IEditee[] = [];
    showAdvancedInfo: boolean;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: FaxConfigRestAuditLogItem[], showAdvancedInfo: boolean }
    ) {
        this.showAdvancedInfo = data.showAdvancedInfo;
        // initialize all items to show up collapsed except for the first (the parent)
        _.each(data.editee, (item: FaxConfigRestAuditLogItem) => {
            let e: IEditee = _.extend({}, item);
            e.section_active = (this.editee.length === 0);
            this.editee.push(e);
        });
    }

    toggle_section(item: IEditee): void {
        item.section_active = !item.section_active;
    };

    is_active(item: IEditee): boolean {
        return item.section_active;
    };

    close(): void {
        this.dialogRef.close();
    }
}
