import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { Component, inject, Renderer2 } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestCSDefinition, FaxConfigRestCSFieldDefList, FaxConfigRestCSDigitalSignatures,
    FaxConfigRestCSDisclaimers, FaxConfigRestCSImageExtensions, FaxConfigRestCSField, FaxConfigRestCSPosition} from '../api/api';
import { UtlClass, UtlVec } from '../utils/utl';
import { DialogService } from '../dialog/dialog.service';
import { CoversheetCoverpageComponent } from '../coversheet-coverpage/coversheet-coverpage.component';
import { CoversheetContpageComponent } from '../coversheet-contpage/coversheet-contpage.component';
import { CoversheetDateformatComponent, ICoversheetDateformatEditee } from '../coversheet-dateformat/coversheet-dateformat.component';
import { CoversheetTimeformatComponent, ICoversheetTimeformatEditee } from '../coversheet-timeformat/coversheet-timeformat.component';
import { CoversheetFontDialogComponent, ICoversheetFontEditee } from '../coversheet-font-dialog/coversheet-font-dialog.component';
import { CoversheetDigitalsignatureComponent, ICoversheetDigitalSignatureEditee } from '../coversheet-digitalsignature/coversheet-digitalsignature.component';
import * as _ from 'underscore';

export interface IFieldDef {
    DisplayName: string;
    Field: string;
    Type: string;
    DefaultPrefix: string;
    MaxOccurrences: number;
    active?: boolean;
}

export interface ICoversheetField {
    definition: IFieldDef,
    props: FaxConfigRestCSField;
    sizable: boolean;
    offset: UtlVec;
    dim: UtlVec;
}

export interface ITextArea {
    definition: IFieldDef | null,
    offset: UtlVec;
    dim: UtlVec;
    prefix: string;
}

export interface ICoversheetState {
    activePageTab: string;
    fields: ICoversheetField[];
    firstPageTextArea: ITextArea | null;
    contPageTextArea: ITextArea | null;
}

export interface ICoversheetConfig {
    faxDim: UtlVec;
    scale: number;
    paperDim: UtlVec;
    paperInnerOffset: UtlVec;
    paperInnerDim: UtlVec;
}

interface IDisclaimer {
    FileName: string;
    DisplayName: string;
}

@Component({
    selector: 'app-coversheet',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet.component.html',
    styleUrl: './coversheet.component.css'
})
export class CoversheetComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    private router = inject(Router);

    name?: string;
    decodedName?: string;
    isReady: boolean = false;
    Vec: UtlVec = new UtlVec(0, 0);
    utl: UtlClass = new UtlClass;

    pageTabs: string[] = ['Cover Page', 'Continuous Page', 'Disclaimer Page', 'Settings'];
    coversheetState: ICoversheetState = { // has to be different from CoverPageController .state
        activePageTab: this.pageTabs[0],
        fields: [],                 // for CoverPageController
        firstPageTextArea: null,    // for CoverPageController
        contPageTextArea: null      // for ContPageController
    };

    config: ICoversheetConfig = { // populated when coversheet is loaded
        faxDim: new UtlVec(0, 0),
        scale: 1,
        paperDim: new UtlVec(0, 0),
        paperInnerOffset: new UtlVec(0, 0),
        paperInnerDim: new UtlVec(0, 0),
    }

    coversheet?: FaxConfigRestCSDefinition; // populated when coversheet is loaded
    coversheetFileExtensions: FaxConfigRestCSImageExtensions = [];
    lstDisclaimers: IDisclaimer[] = [{ FileName: '', DisplayName: '<none>'}];
    fieldDefs: IFieldDef[] = [];
    DigitalSignatures: FaxConfigRestCSDigitalSignatures = [];
    coversheetAccept: string = '';

    coverPage = CoversheetCoverpageComponent;
    contPage = CoversheetContpageComponent;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private route: ActivatedRoute, private renderer: Renderer2) {
        this.session.rootPromises.subscribe(() => {
            this.route.params.subscribe(params => {
                this.name = params['name']?? '';
                this.decodedName = decodeURIComponent(this.name!);
                this.init();
            });
        });
    }

    init(): void {
        let promises: {
            p1: Observable<FaxConfigRestCSFieldDefList>;
            p2: Observable<FaxConfigRestCSDefinition>;
            p3: Observable<FaxConfigRestCSDigitalSignatures>;
            p4: Observable<FaxConfigRestCSDisclaimers>;
            p5: Observable<FaxConfigRestCSImageExtensions>;
        } = {
            p1: this.faxSrv.coversheetFieldDefinitions(),
            p2: this.faxSrv.coversheet(this.name!),
            p3: this.faxSrv.coversheetGetDigitalSignatures(),
            p4: this.faxSrv.coversheetGetDisclaimers(),
            p5: this.faxSrv.getCoversheetImageExtensions()
        }
        forkJoin(promises).subscribe({
            next: res => {
                // Field definitions
                this.fieldDefs = _.map(res.p1, def => {
                    return {
                        DisplayName: def.DisplayName?? '',
                        Field: def.Field?? '',
                        Type: def.Type?? '',
                        DefaultPrefix: def.DefaultPrefix?? '',
                        MaxOccurrences: def.MaxOccurrences,
                        active: true
                    };
                });

                // Coversheet definition
                if (res.p2 && res.p2.General) {
                    this.initCoversheet(res.p2);
                } else {
                    // Coversheet not found, probably caused by a switch in organization context while in coversheet editor
                    this.router.navigate(['/coversheet_list']);
                }

                // Digital signatures
                this.DigitalSignatures = res.p3;

                // Disclaimers
                _.each(res.p4, ds => {
                    if (ds) {
                        this.lstDisclaimers.push({ FileName: ds, DisplayName: ds });
                    }
                });
                if (this.coversheet?.Disclaimer && this.coversheet.Disclaimer.Filename) {
                    let found = _.find(this.lstDisclaimers, ds => {
                        return (ds.FileName.toUpperCase() === this.coversheet!.Disclaimer!.Filename!.toUpperCase());
                    });
                    if (found) {
                        this.coversheet.Disclaimer.Filename = found.FileName;
                    }
                }

                // Image extensions
                let exts = '';
                this.coversheetFileExtensions = res.p5;
                _.each(this.coversheetFileExtensions, ext => {
                    if (exts) exts += ',';
                    exts += '.' + ext;
                });
                this.coversheetAccept = exts;

                this.isReady = true;
            },
            error: err => alert(err.message)
        });
    }

    initCoversheet(coversheet: FaxConfigRestCSDefinition): void {
        this.coversheet = coversheet;
        let g = coversheet.General!;
        let cfg = this.config;

        cfg.faxDim = new UtlVec(g.PageWidth, g.PageLength); // (1728, 2200) Fax page dimensions are always the same?
        cfg.scale = 710 / cfg.faxDim.x; // 720, see index.css #content { width }
        cfg.paperDim = cfg.faxDim.scale(cfg.scale);
        cfg.paperInnerOffset = new UtlVec(g.MarginLeft, g.MarginTop).scale(cfg.scale);
        cfg.paperInnerDim = new UtlVec(
                            g.PageWidth! - (g.MarginLeft! + g.MarginRight!),
                            g.PageLength! - (g.MarginTop! + g.MarginBottom!)).scale(cfg.scale);
    }

    draggable(element: HTMLElement, field: ITextArea | ICoversheetField, hookOnMouseDown?: () => void): void {
        var onMouseDown = (event: MouseEvent) => {
            if (hookOnMouseDown) hookOnMouseDown();   // call the hook function first (if any)
            if (event.target !== element) { return; } // don't drag by the <input>
            var minOff = this.config.paperInnerOffset;
            var maxOff = this.config.paperInnerOffset.plus(this.config.paperInnerDim).minus(field.dim);
            var eventOffset = this.utl.eventOffset(event); // remember offset. event object is global in IE.
            this.utl.drag(event, {
                ondrag: (e: MouseEvent) => {
                    let delta = this.utl.eventOffset(e).minus(eventOffset);
                    this.utl.offset(element, field.offset.plus(delta).bound(minOff, maxOff));
                },
                ondrop: (e: MouseEvent) => {
                    let delta = this.utl.eventOffset(e).minus(eventOffset);
                    field.offset = field.offset.plus(delta).bound(minOff, maxOff);
                }
            });
        }
        element.onmousedown = onMouseDown;
    }

    resizable(element: HTMLElement, field: ITextArea | ICoversheetField, hookOnMouseDown?: () => void): void {
        var onMouseDown = (event: MouseEvent) => {
            if (hookOnMouseDown) hookOnMouseDown();   // call the hook function first (if any)
            var maxDim = this.config.paperInnerOffset.plus(this.config.paperInnerDim).minus(field.offset);
            var minDim = new UtlVec(18 * this.config.scale, 18 * this.config.scale);
            var eventOffset = this.utl.eventOffset(event); // remember offset. event object is global in IE.
            this.utl.drag(event, {
                ondrag: (e: MouseEvent) => {
                    var delta = this.utl.eventOffset(e).minus(eventOffset);
                    this.utl.dim(element, field.dim.plus(delta).bound(minDim, maxDim));
                },
                ondrop: (e: MouseEvent) => {
                    var delta = this.utl.eventOffset(e).minus(eventOffset);
                    field.dim = field.dim.plus(delta).bound(minDim, maxDim);
                }
            });
        }
        let handle = this.renderer.createElement('div');
        handle.setAttribute('class', 'resize_handle');
        let image = this.renderer.createElement('i');
        image.setAttribute('class', 'glyphicon glyphicon-signal');
        handle.appendChild(image);
        handle.onmousedown = onMouseDown;
        element.appendChild(handle);
    }

    buildBackgroundSrc(isFirstPage: boolean): string {
        return this.session.portalCfg.prefix + 'Coversheet/Definition/' + encodeURIComponent(this.name!) +
            '/Background?Token=' + encodeURIComponent(this.auth.getAuthToken()?? '') +
            '&FirstPage=' + (isFirstPage ? 'True' : 'False') +
            '&_t=' + new Date().getTime();
    }

    save(): void {
        // prepare data (convert back from 'gui' format)
        let scale = this.config.scale;
        this.coversheet!.FirstPage!.Fields = _.map(this.coversheetState.fields, f => {
            let offset = f.offset.scale(1 / scale);
            let dim = f.dim.scale(1 / scale);
            let rect;
            if (f.sizable) {
                rect = {
                    x1: Math.round(offset.x),
                    y1: Math.round(offset.y),
                    x2: Math.round(offset.x + dim.x),
                    y2: Math.round(offset.y + dim.y)
                };
            } else {
                rect = {
                    x1: Math.round(offset.x),
                    y1: Math.round(offset.y),
                    x2: (f.props.x2 === 1)? 1: Math.round(offset.x) + ((f.props.x2?? 0) - f.props.x1),
                    y2: (f.props.y2 === 1)? 1: Math.round(offset.y) + ((f.props.y2?? 0) - f.props.y1)
                };
            }
            return {
                x1: rect.x1,
                y1: rect.y1,
                x2: rect.x2,
                y2: rect.y2,
                Field: f.props.Field,
                Lines: f.props.Lines,
                Prefix: f.props.Prefix
            };
        });

        if (this.coversheetState.firstPageTextArea) {
            this.coversheet!.FirstPage!.TextArea = this.fromGuiTextArea(this.coversheetState.firstPageTextArea, scale);
        }
        if (this.coversheetState.contPageTextArea) {
            this.coversheet!.NextPages!.TextArea = this.fromGuiTextArea(this.coversheetState.contPageTextArea, scale);
        }
        this.faxSrv.updateCoversheet(this.name!, this.coversheet!).subscribe({
            next: res => this.fenUtils.afterSave(res),
            error: err => alert(err.message)
        });
    }

    fromGuiTextArea(textArea: ITextArea, scale: number): FaxConfigRestCSPosition {
        let offset = textArea.offset.scale(1 / scale);
        let dim = textArea.dim.scale(1 / scale);
        return {
            x1: Math.round(offset.x),
            y1: Math.round(offset.y),
            x2: Math.round(offset.x + dim.x),
            y2: Math.round(offset.y + dim.y)
        };
    }

    setMessageFont(): void {
        let editee = {
            fontName: this.coversheet!.General?.MessageFont?? 'Times New Roman',
            fontSize: this.coversheet!.General?.MessageFontSize?? 9,
            fontItem: 'message body'
        };
        const dialogRef = this.dialog.open(CoversheetFontDialogComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                this.coversheet!.General!.MessageFont = editee.fontName;
                this.coversheet!.General!.MessageFontSize = editee.fontSize;
            }
        });
    }

    setFieldFont(): void {
        let editee: ICoversheetFontEditee = {
            fontName: this.coversheet!.General?.FieldFont?? 'Times New Roman',
            fontSize: this.coversheet!.General?.FieldFontSize?? 9,
            fontItem: 'fields'
        };
        const dialogRef = this.dialog.open(CoversheetFontDialogComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                this.coversheet!.General!.FieldFont = editee.fontName;
                this.coversheet!.General!.FieldFontSize = editee.fontSize;
            }
        });
    }

    setDateFormat(): void {
        let editee: ICoversheetDateformatEditee = {
            dateFormat: this.coversheet!.General?.DateFormat || 'MM/DD/YY'
        };
        const dialogRef = this.dialog.open(CoversheetDateformatComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                this.coversheet!.General!.DateFormat = editee.dateFormat;
            }
        });
    }

    setTimeFormat(): void {
        let editee: ICoversheetTimeformatEditee = {
            timeFormat: this.coversheet!.General?.TimeFormat?? 12
        };
        const dialogRef = this.dialog.open(CoversheetTimeformatComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                this.coversheet!.General!.TimeFormat = editee.timeFormat;
            }
        });
    }

    showDigitalSignatures(): boolean {
        return ((this.DigitalSignatures && this.DigitalSignatures.length > 0)
            || (this.coversheet!.General?.DigitalSignature? true: false));
    }

    setDigitalSignature(): void {
        let editee: ICoversheetDigitalSignatureEditee = {
            DigitalSignatures: this.DigitalSignatures,
            digsig: this.coversheet!.General?.DigitalSignature || 'None'
        };
        const dialogRef = this.dialog.open(CoversheetDigitalsignatureComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(modified => {
            if (modified) {
                if (editee.digsig === 'None') {
                    this.coversheet!.General!.DigitalSignature = '';
                } else {
                    this.coversheet!.General!.DigitalSignature = editee.digsig;
                }
            }
        });
    }
}
