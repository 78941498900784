import { CommonModule } from '@angular/common';
import { Observable, forkJoin, map } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelContractInfo, FaxConfigRestResult, FaxConfigRestKernelContractList } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { ContractEditComponent, EContractFlags, IContractScope, IContract, IConnector } from '../contract-edit/contract-edit.component';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColDef, ColumnState, IRowNode, GridOptions, GridReadyEvent,
    GetContextMenuItemsParams, GetRowIdParams, MenuItemDef, PaginationChangedEvent, FilterChangedEvent,
    ValueFormatterParams, ValueGetterParams, CellDoubleClickedEvent, ProcessHeaderForExportParams, CsvExportParams,
} from 'ag-grid-community';

@Component({
    selector: 'app-contract-list',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './contract-list.component.html',
    styleUrl: './contract-list.component.css'
})
export class ContractListComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    cssGridHeight = { 'height': '300px' };
    contractsGridApi: GridApi | null = null;
    contractsGridOptions?: GridOptions;
    contractsGridInitialized: boolean = false;

    contracts: IContract[] = [];
    connectors: IConnector[] = [];
    contractsModifiable: boolean = false;
    exportEnabled: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.contractsModifiable = this.auth.isModifiable('Kernel Contracts');
        this.session.rootPromises.subscribe(() => this.init());
    }

    connectorTypeComparator(valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean): number {
        let dispA = nodeA?.data?.ConnectorTypeName?? '';
        let dispB = nodeB?.data?.ConnectorTypeName?? '';
        return dispA.localeCompare(dispB);
    }

    contractsGridColDefs: ColDef<IContract>[] = [
        {
            field: 'OrganizationID',
            hide: true,
            filter: null,
            filterParams: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            headerName: 'Organization',
            headerTooltip: 'Organization',
            field: 'OrganizationName',
            tooltipField: 'OrganizationName',
            hide: true,
            filter: null,
            filterParams: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            headerName: 'Name',
            headerTooltip: 'Name',
            field: 'ConnectorName',
            tooltipField: 'ConnectorName'
        },
        {
            headerTooltip: 'Machine',
            field: 'Machine',
            tooltipField: 'Machine'
        },
        {
            headerName: 'Type',
            headerTooltip: 'Type',
            field: 'ConnectorType',
            tooltipField: 'ConnectorTypeName',
            filterValueGetter: (params: ValueGetterParams) => {
                // This value is used for filtering by 'agTextColumnFilter':
                return params.data?.ConnectorTypeName?? '';
            },
            valueFormatter: (params: ValueFormatterParams) => {
                // This value is used for sorting by our custom comparator:
                return params.data?.ConnectorTypeName?? '';
            },
            comparator: (valA: any, valB: any, nodeA: IRowNode, nodeB: IRowNode, isDesc: boolean) => this.connectorTypeComparator(valA, valB, nodeA, nodeB, isDesc)
        },
        {
            headerName: 'Address',
            headerTooltip: 'Address',
            field: 'WakeUpIP',
            tooltipField: 'WakeUpIP',
            filterParams: {
                // The WakeUpIP can be blank, for example in contracts for the Mobile Device protocols.
                // We explicitly set filterOptions = null to overrule our defaultColDef.filterOptions,
                // which causes AG-Grid to fall back on its own default.
                filterOptions: null
            }
        },
        {
            headerName: 'Port',
            headerTooltip: 'Port',
            field: 'WakeUpPort',
            tooltipField: 'WakeUpPort',
            filter: 'agNumberColumnFilter',
            filterParams: {
                // The WakeUpPort can be blank, for example in contracts for the Mobile Device protocols.
                // Again we explicitly set filterOptions = null, so AG-Grid falls back on its own default.
                filterOptions: null,
                allowedCharPattern: '\\d'   // digits only
            },
            filterValueGetter: (params: ValueGetterParams) => {
                // Return a blank string if applicable, so the 'blank/notBlank' filters work.
                // Otherwise return a number, so filters that test for equality will work.
                const val: string = params.data?.WakeUpPort?? '';
                return (val === '')? val: parseInt(val, 10);
            },
        },
        {
            field: 'UserID',
            tooltipField: 'UserID',
            hide: true,
            filterParams: {
                // The UserID can be blank, so again we set filterOptions = null here.
                filterOptions: null
            }
        },
        {
            field: 'Flags',
            tooltipField: 'Flags',
            hide: true,
            filter: 'agNumberColumnFilter',
            filterParams: {
                // The Flags cannot be blank, and we cannot set filterOptions = null here because
                // the AG-Grid default includes 'blank' and 'notBlank' options that we do not want.
                filterOptions: ['equals','notEqual','greaterThan','greaterThanOrEqual','lessThan','lessThanOrEqual','inRange'],
                allowedCharPattern: '\\d'   // digits only
            }
        },
        {
            field: 'LicenseKey',
            tooltipField: 'LicenseKey',
            hide: true
        },
        {
            field: 'ContractID',
            tooltipField: 'ContractID',
            hide: true
        }
    ];

    private init(): void {
        // Show/hide the organization column based on context.
        let org = _.find(this.contractsGridColDefs, item => {
            return item.field === 'OrganizationName';
        });
        if (org) {
            org.hide = !this.session.contextAllOrganizations();
        }
        this.contractsGridOptions = {
            columnDefs: this.contractsGridColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['reset'],
                    // By default, omit 'blank' and 'notBlank' because most fields are not allowed to be blank.
                    filterOptions: ['contains','notContains','equals','notEqual','startsWith','endsWith']
                }
            },
            rowModelType: 'clientSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            defaultCsvExportParams: { allColumns: true, fileName: 'export_contracts.csv' },
            onGridReady: (params: GridReadyEvent) => {
                this.contractsGridApi = params.api;
                this.cssGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
                this.contractsLoadViewState(params.api);
                this.getConnectors().subscribe();
            },
            onCellDoubleClicked: params => this.contractsOnCellDoubleClicked(params),
            onSelectionChanged: params => this.contractsOnSelectionChanged(params.api),
            onPaginationChanged: params => this.contractsOnPaginationChanged(params),
            onFilterChanged: params => this.contractsOnFilterChanged(params),
            getContextMenuItems: params => this.contractsGetContextMenuItems(params),
            getRowId: params => this.contractsGetRowId(params),
            onGridPreDestroyed: params =>this.contractsSaveViewState(params.api)
        };
        this.contractsGridInitialized = true;
    }

    private contractsLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'ContractsViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Show/hide the organization column based on context.
                        let org = val.find(item => { return item.colId === 'OrganizationName'; });
                        if (org) {
                            org.hide = !this.session.contextAllOrganizations();
                        }
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    private contractsSaveViewState(api: GridApi): void {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'ContractsViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        this.cssGridHeight = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }

    private contractsGetRowId(params: GetRowIdParams): string {
        return params.data.ContractID;
    }

    private contractsGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        let sel = params.api.getSelectedNodes()?? [];

        const canEdit = this.contractsModifiable;
        return [
            'copy', 'separator',
            {
                name: 'Add...',
                action: () => this.add_contract(),
                disabled: !canEdit
            },
            {
                name: canEdit? 'Edit entry...': 'View entry...',
                action: () => this.contractsOnCellDoubleClicked(sel[0]),
                disabled: (!sel || sel.length != 1)
            },
            {
                name: 'Remove entries',
                action: () => this.delete_contracts(),
                disabled : (!sel || sel.length == 0 ) || (!canEdit)
            },
            'separator',
            {
                name: 'CSV Export',
                action: () => this.export(),
                disabled : !this.exportEnabled
            }
        ];
    }

    private _encodeType(flgs: number, id: number): number { return (flgs * 256) + id;}

    private getConnectors(): Observable<IConnector[]> {
        return this.faxSrv.GetContractConnectors().pipe(map(res => {
            this.connectors = _.map(res, item => {
                return _.extend({ encodedType: this._encodeType(item.Flags!, item.ID!) }, item);
            });
            this.isReady = true;
            this.refresh();
            return this.connectors;
        }));
    }

    getContracts(): Observable<IContract[]> {
        return this.faxSrv.GetContracts().pipe(map(res => {
            // Sort by OrganizationName / ConnectorName
            let sorted: FaxConfigRestKernelContractList = res.sort((a: FaxConfigRestKernelContractInfo, b: FaxConfigRestKernelContractInfo) => { 
                let i: number = ('' + a.OrganizationName).localeCompare('' + b.OrganizationName);
                if (i === 0) {
                    i = ('' + a.ConnectorName).localeCompare('' + b.ConnectorName);
                }
                return i;
            });
            
            this.contracts = _.map(sorted, item => {
                const cls = item.Flags & EContractFlags.CONTRACT_FLAG_CONNECTORTYPE;  //bitset: 1 for host, 2 for device, 4 AddOn
                let mapped: IContract = _.extend({}, item);
                mapped.encodedType = this._encodeType(cls, item.ConnectorType!)
                let found = _.find( this.connectors, con => {
                    return con.encodedType === mapped.encodedType;
                });
                mapped.ConnectorTypeName = '' + (found? found.Name: mapped.ConnectorType);
                mapped.selected = false;
                return mapped;
            });

            return this.contracts;
        }));
    }

    refresh(): void {
        this.getContracts().subscribe(res => {
            this.exportEnabled = (res && res.length > 0);
            this.contractsGridApi?.setGridOption('rowData', res);
        });
    }

    contractsOnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger contractsOnSelectionChanged:
        if (params.newPage) params.api.deselectAll();
    }

    contractsOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger contractsOnSelectionChanged:
        params.api.deselectAll();
    }

    contractsOnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit_contract(params.data);
        }
    }

    contractsOnSelectionChanged(api: GridApi | null): void {
        let sel = api?.getSelectedNodes()?? [];
        this.canEdit = (sel && sel.length == 1);
        this.canDelete = (sel && sel.length > 0);
    }

    add_contract(): void {
        let def: IContract = {
            ContractID : '',
            ConnectorType: -1,
            ConnectorName: '',
            ConnectorTypeName: '',
            Machine : '',
            WakeUpIP: '0.0.0.0',
            WakeUpPort : '0',   // was 0
            SSLUsage: true,
            UserID: '',
            Password: '',
            Flags : 0,
            encodedType : 0,
            OrganizationID : !this.session.contextAllOrganizations()? this.session.currentOrgId: null
        };
        let conn = _.filter(this.connectors, c => { return c.CanAdd?? false; });

        let scope: IContractScope = {
            mode: 'add',
            editee: def,
            connectors: conn
        };
        const dialogRef = this.dialog.open(ContractEditComponent, {data: scope});
        dialogRef.afterClosed().subscribe(() => {
            this.refresh();
        });
    }

    edit_contract(def: IContract): void {
        let scope: IContractScope = {
            mode: 'edit',
            editee: def,
            connectors: this.connectors
        };
        const dialogRef = this.dialog.open(ContractEditComponent, {data: scope});
        dialogRef.afterClosed().subscribe(() => {
            this.refresh();
        });
    }

    delete_contracts(): void {
        let sel = this.contractsGridApi?.getSelectedNodes()?? [];
        if (sel.length > 0 && confirm('Are you sure you want to delete the selected contract(s)?')) {
            let promises: Observable<FaxConfigRestResult>[] = [];
            _.each(sel, (item: IRowNode<IContract>) => {
                if (item.data?.ContractID) {
                    promises.push(this.faxSrv.DeleteContract(item.data.ContractID));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) {
                            this.refresh();
                        }
                    },
                    error: err => {
                        alert(err.message);
                        this.refresh();
                    }
                });
            }
        }
    }
    
    contractsProcessHeaderCallback(params: ProcessHeaderForExportParams): string {
        // Export the raw field names, not the display names.
        return params.column.getColId();
    }

    export(): void {
        let params: CsvExportParams = {
            processHeaderCallback: params => this.contractsProcessHeaderCallback(params)
        };
        this.contractsGridApi?.exportDataAsCsv(params);
    }
}
