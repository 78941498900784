import { Component, Inject, inject } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule, FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestAzureADConfig, FaxConfigRestOrganizationDef, FaxConfigRestOrganizationAdminDef, FaxConfigRestOrganizationLogging } from '../api/api';
import { FenUintOnlyDirective } from '../fen-uint-only.directive';

import * as _ from 'underscore';

interface IScope {
    editee?: FaxConfigRestOrganizationDef,
    azureConfig?: FaxConfigRestAzureADConfig
}

interface IAdministrator extends FaxConfigRestOrganizationAdminDef {
    IsAzureUser?: boolean;
    Domains?: string[];
    Domain?: string;
    Alias?: string;
    AllowLocal?: boolean;
    AllowFenTenant?: boolean;
    AllowMultiTenant?: boolean;
    AllowSocial?: boolean;
}

interface ILogging extends FaxConfigRestOrganizationLogging {
    BackupAgeMaxString? : string | null;
    BackupAgeMaxInfinite? : boolean;
}

interface IEditee extends FaxConfigRestOrganizationDef {
    Administrator?: IAdministrator;
    Logging?: ILogging | null;
}

@Component({
    selector: 'app-organization-edit',
    imports: [FormsModule, CommonModule, FenUintOnlyDirective],
    templateUrl: './organization-edit.component.html',
    styleUrl: './organization-edit.component.css'
})
export class OrganizationEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IEditee = {};
    isReady: boolean = false;
    modified: boolean = false;

    ID: string = '';
    headerText: string = '';
    pageTabs: string[] = [];
    state: { activePageTab?: string } = {};

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.session.rootPromises.subscribe(() => this.init(data as IScope));
    }

    init(scope: IScope): void {
        if (scope.editee) {
            this.ID = '' + scope.editee.ID;
            if (this.auth.isModifiable('Organizations')) {
                this.headerText = "Edit Organization '" + scope.editee.Name + "'";
            } else {
                this.headerText = "Organization '" + scope.editee.Name + "'";
            }
            this.pageTabs = ['General', 'Contacts', 'Logging'];
            this.state = { activePageTab: this.pageTabs[0] };

            this.faxSrv.GetOrganizationDefinition(scope.editee.ID!).subscribe(res => {
                this.editee = res;
                if (this.editee.Logging) {
                    this.editee.Logging.BackupAgeMaxInfinite = (this.editee.Logging.BackupAgeMax === 0);
                    this.editee.Logging.BackupAgeMaxString = '' + (this.editee.Logging.BackupAgeMax || '');
                } else {
                    this.editee.Logging = {
                        BackupAllowed: false,
                        BackupAgeMax: 0,
                        BackupAgeMaxString: '',
                        BackupAgeMaxInfinite: true,
                    }
                }
                this.isReady = true;
            });
        } else {
            this.ID = '';
            this.headerText = 'Create Organization';
            this.pageTabs = ['General', 'Administrator', 'Contacts'];
            this.state = { activePageTab: this.pageTabs[0] };

            this.editee = {
                System: false,
                Enabled: true,
                Name: '',
                Description: '',
                AllowSubOrganizations: false
            };
            this.editee.ContactTechnical = {
                Name: '',
                Note: '',
                Email: '',
                Phone: ''
            };
            this.editee.ContactCommercial = {
                Name: '',
                Note: '',
                Email: '',
                Phone: ''
            };
            this.editee.Administrator = {
                Source: '',
                Username: '',
                Password: '',
                Department: '',
                Description: '',
                IsAzureUser: scope.azureConfig?.Enabled
            };
            this.editee.Logging = {
                BackupAllowed: false,
                BackupAgeMax: 0,
                BackupAgeMaxString: '',
                BackupAgeMaxInfinite: true,
            };

            if (scope.azureConfig?.Enabled) {
                this.editee.Administrator.Domains = scope.azureConfig.Domains?? [];
                this.editee.Administrator.Domain = this.editee.Administrator.Domains[0]?? '';
                this.editee.Administrator.Alias  = '';
                this.editee.Administrator.AllowLocal = !scope.azureConfig.Enforced;
                this.editee.Administrator.AllowFenTenant = scope.azureConfig.AllowFenTenant?? false;
                this.editee.Administrator.AllowMultiTenant = scope.azureConfig.AllowMultiTenant?? false;
                this.editee.Administrator.AllowSocial = scope.azureConfig.AllowSocial?? false;

                if (this.editee.Administrator.Source === '' && this.editee.Administrator.AllowLocal) {
                    this.editee.Administrator.Source = 'SQLDB';
                }
                if (this.editee.Administrator.Source === '' && this.editee.Administrator.AllowFenTenant) {
                    this.editee.Administrator.Source = 'AzureAD';
                }
                if (this.editee.Administrator.Source === '' && this.editee.Administrator.AllowMultiTenant) {
                    this.editee.Administrator.Source = 'AzureAD_MultiTenant';
                }
                if (this.editee.Administrator.Source === '' && this.editee.Administrator.AllowSocial) {
                    this.editee.Administrator.Source = 'AzureAD_Social';
                }
            } else {
                    this.editee.Administrator.Source = 'SQLDB';
            }
            this.isReady = true;
        }
    }

    doSave(): Observable<FaxConfigRestResult> | null {
        let backupAgeMax: number = parseInt(this.editee.Logging?.BackupAgeMaxString?? '', 10);
        let def: IEditee = {
            Enabled: this.editee.Enabled,
            Name: this.editee.Name,
            Description: this.editee.Description,
            ContactTechnical: {
                Name: this.editee.ContactTechnical?.Name,
                Note: this.editee.ContactTechnical?.Note,
                Email: this.editee.ContactTechnical?.Email,
                Phone: this.editee.ContactTechnical?.Phone
            },
            ContactCommercial: {
                Name: this.editee.ContactCommercial?.Name,
                Note: this.editee.ContactCommercial?.Note,
                Email: this.editee.ContactCommercial?.Email,
                Phone: this.editee.ContactCommercial?.Phone
            },
            Logging: {
                BackupAllowed: this.editee.Logging?.BackupAllowed?? false,
                BackupAgeMax: (this.editee.Logging?.BackupAgeMaxInfinite || isNaN(backupAgeMax)) ? 0 : backupAgeMax
            }
        };
        if (this.ID) {
            def.ID = parseInt(this.ID, 10);
            return this.faxSrv.PutOrganizationDefinition(def.ID, def);
        } else {
            def.Administrator = {
                Source: '',
                Username: '',
                Password: this.editee.Administrator?.Password,
                Department: this.editee.Administrator?.Department,
                Description: this.editee.Administrator?.Description
            };
            if (this.editee.Administrator?.IsAzureUser) {
                if (this.editee.Administrator.Source === 'AzureAD') {
                    if (this.isInvalidAlias(this.editee.Administrator.Alias?? '')) {
                        alert('The email alias cannot contain the characters \"@\", \"?\", \"\\\", or \"/\".');
                        return null;
                    }
                    def.Administrator.Username = this.editee.Administrator.Alias + '@' + this.editee.Administrator.Domain;
                    def.Administrator.Source = this.editee.Administrator.Source;
                } else {
                    // ($scope.editee.Source === 'AzureAD_MultiTenant' || $scope.editee.Source === 'AzureAD_Social')
                    if (this.hasInvalidChars(this.editee.Administrator.Username?? '')) {
                        alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                        return null;
                    }
                    def.Administrator.Username = this.editee.Administrator.Username;
                    def.Administrator.Source = this.editee.Administrator.Source;
                }
            } else {
                if (this.hasInvalidChars(this.editee.Administrator?.Username?? '')) {
                    alert('The username cannot contain the characters \"?\", \"\\\", or \"/\".');
                    return null;
                }
                def.Administrator.Username = this.editee.Administrator?.Username?? null;
                def.Administrator.Source = 'SQLDB';
            }
            return this.faxSrv.PostOrganizationDefinition(def);
        }
    }

    myAfterSave(res: FaxConfigRestResult): void {
        if (res.Errors && res.Errors[0]?.Id === 36) { // ERR_ALREADY_EXISTS
            alert(this.editee.Name + ' already exists.');
        } else if (this.fenUtils.afterSave(res) > 0) {
            this.modified = true;
            this.close();
        }
    }

    submit(): void {
        let sub: Observable<FaxConfigRestResult> | null = this.doSave();
        sub?.subscribe({
            next:  res => { this.myAfterSave(res); },
            error: err => { alert(err.message); }
        });
    }

    hasInvalidChars(name: string): boolean {
        return (
            name.indexOf('?') >= 0 ||
            name.indexOf('\\') >= 0 ||
            name.indexOf('/') >= 0
        );
    }

    isInvalidAlias(name: string): boolean {
        return (name.indexOf('@') >= 0 || this.hasInvalidChars(name));
    }

    hasMultipleDomains(): boolean {
        let len: number = this.editee?.Administrator?.Domains?.length?? 0;
        return (len > 1);
    };

    hasMultipleAllowedSources(): boolean {
        let count = 0;
        if (this.editee.Administrator?.AllowLocal) { count++; }
        if (this.editee.Administrator?.AllowFenTenant) { count++; }
        if (this.editee.Administrator?.AllowMultiTenant) { count++; }
        if (this.editee.Administrator?.AllowSocial) { count++; }
        return (count > 1);
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
