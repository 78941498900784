import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { Component, inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestCSImageExtensions, FaxConfigRestCSCoversheets, FaxConfigRestOrganizationDef, FaxConfigRestCSDefinition } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { CoversheetDisclaimerComponent, ICoversheetDisclaimerEditee } from '../coversheet-disclaimer/coversheet-disclaimer.component';
import { CoversheetImportComponent, ICoversheetImportEditee } from '../coversheet-import/coversheet-import.component';
import { DialogPromptComponent, IDialogPromptData } from '../dialog-prompt/dialog-prompt.component';
import * as _ from 'underscore';

interface ICoversheet {
    name: string;
    selected: boolean;
}

interface IDisclaimer {
    name: string;
    selected: boolean;
}

@Component({
    selector: 'app-coversheet-list',
    imports: [FormsModule, CommonModule],
    templateUrl: './coversheet-list.component.html',
    styleUrl: './coversheet-list.component.css'
})
export class CoversheetListComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);
    public router: Router = inject(Router);

    pageTabs?: string[];
    state: { activePageTab: string } = { activePageTab: 'Coversheets' };
    coversheetFileExtensions: FaxConfigRestCSImageExtensions = [];
    coversheetAccept: string = '';
    default_coversheet: string = 'Default.cs';
    coversheets: ICoversheet[] = [];
    disclaimers: IDisclaimer[] = [];

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    init(): void {
        if (this.auth.isModifiable('Coversheets')) {
            this.pageTabs = [ 'Coversheets', 'Disclaimer Pages' ];
            this.state.activePageTab = this.pageTabs[0];
            this.faxSrv.getCoversheetImageExtensions().subscribe(res => {
                let exts = '';
                this.coversheetFileExtensions = res;
                _.each(this.coversheetFileExtensions, ext => {
                    if (exts) exts += ',';
                    exts += '.' + ext;
                });
                this.coversheetAccept = exts;
            });
        }
        this.refresh_coversheets();
        this.refresh_disclaimers();
    }

    refresh_coversheets(): void {
        if (!this.session.contextAllOrganizations()) {               
            let promises: {
                p1: Observable<FaxConfigRestCSCoversheets>,
                p2: Observable<FaxConfigRestOrganizationDef>
            } = {
                p1: this.faxSrv.coversheets(),
                p2: this.faxSrv.GetOrganizationDefinition(this.session.currentOrgId!)
            };

            forkJoin(promises).subscribe({
                next: res => {
                    this.coversheets = _.map(res.p1, cs => { return { name: cs, selected: false }; });
                    this.default_coversheet = res.p2.Templates?.DefaultFaxTemplate?? 'Default.cs';

                    // If there is a physical file 'nocover.cs' then it overrules the virtual '<NO COVERSHEET>'
                    if (!_.find(this.coversheets, cs => { return cs.name.toLowerCase() === 'nocover.cs'; })) {
                        this.coversheets.unshift({ name: '<NO COVERSHEET>', selected: false });
                        if (this.default_coversheet.toLowerCase() === 'nocover.cs') {
                            this.default_coversheet = '<NO COVERSHEET>';
                        }
                    }
                },
                error: err => { alert(err.message); }
            });
        } else {
            this.coversheets = [];
        }
    }

    refresh_disclaimers(): void {
        if (!this.session.contextAllOrganizations()) {               
            this.faxSrv.coversheetGetDisclaimers().subscribe(disclaimers => {
                this.disclaimers = _.map(disclaimers, ds => { return { name: ds, selected: false }; });
            });
        } else {
            this.disclaimers = [];
        }
    }

    del(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.coversheets, (cs: ICoversheet) => {
            if (cs.selected && cs.name) {
                promises.push(this.faxSrv.deleteCoversheet(cs.name));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) { this.refresh_coversheets(); }
                },
                error: err => alert(err.message)
            });
        }
    }

    neo(): void {
        let data: IDialogPromptData = {
            title: 'New Coversheet',
            text: 'Coversheet File Name:',
            retval: ''
        };
        const dialogRef = this.dialog.open(DialogPromptComponent, {
            data: data
        });
        dialogRef.afterClosed().subscribe((name: string | null) => {
            if (name) { this.neo2(name); }
        });
    }

    private neo2(name: string): void {
        let found = _.find(this.coversheets, cs => {
            return (cs.name.toLowerCase() === name.toLowerCase() ||
                cs.name.toLowerCase() === (name.toLowerCase() + '.cs'));
        });
        if (found) {
            alert('Coversheet ' + found.name + ' already exists.');
            return;
        }
        let coversheet: FaxConfigRestCSDefinition  = { // default coversheet
            General: {
                Version: '3.01',
                PageWidth: 1728,
                PageLength: 2200,
                MarginLeft: 55,
                MarginTop: 55,
                MarginRight: 55,
                MarginBottom: 55,
                FieldFont: "Times New Roman",
                FieldFontSize: 9,
                MessageFont: "Times New Roman",
                MessageFontSize: 9,
                DigitalSignature: "",
                DateFormat: "MM/DD/YY",
                CCListFormat: "%Name%, %Company%%M% ---MORE---%",
                ORListFormat: "%Name%, %Company%%M% ---MORE---%",
                TOListFormat: "%Name%, %Company%%M% ---MORE---%",
                TimeFormat: 12,
                UseCoversheet: true,
                UseDisclaimer: false
            },
            Disclaimer: {
                Filename: ''
            },
            FirstPage: {
                Background: '',
                DPI: 200,
                TextArea: { x1: 200, y1: 500, x2: 1728 - 55, y2: 2200 - 55 },
                Fields: []
            },
            NextPages: {
                Background: '',
                TextArea: { x1: 200, y1: 500, x2: 1728 - 55, y2: 2200 - 55 }
            }
        };
        this.faxSrv.createCoversheet(name, coversheet).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.router.navigate(['/coversheet/' + name]);
            }
        });
    }

    edit(coversheet: ICoversheet): void {
        if (!this.isVirtualNocover(coversheet)) {
            this.router.navigate(['/coversheet/' + coversheet.name]);
        }
    }

    add_disclaimer(): void {
        let editee: ICoversheetDisclaimerEditee = {
            coversheetFileExtensions: this.coversheetFileExtensions,
            coversheetAccept: this.coversheetAccept
        };
        const dialogRef = this.dialog.open(CoversheetDisclaimerComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) { this.refresh_disclaimers(); }
        });
    }

    import_coversheet(): void {
        let editee: ICoversheetImportEditee = {
            coversheetFileExtensions: this.coversheetFileExtensions,
            coversheetAccept: this.coversheetAccept
        };
        const dialogRef = this.dialog.open(CoversheetImportComponent, {
            data: { editee: editee }
        });
        dialogRef.afterClosed().subscribe(ok => {
            if (ok) { this.refresh_coversheets(); }
        });
    }

    remove_disclaimer(): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        _.each(this.disclaimers, (ds: IDisclaimer) => {
            if (ds.selected && ds.name) {
                promises.push(this.faxSrv.coversheetDeleteDisclaimer(ds.name));
            }
        });
        if (promises.length > 0) {
            forkJoin(promises).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) { this.refresh_disclaimers(); }
                },
                error: err => alert(err.message)
            });
        }
    }

    isDefaultCoversheet(name: string): boolean {
        return (this.default_coversheet && name.toUpperCase() === this.default_coversheet.toUpperCase())? true: false;
    }

    isVirtualNocover(coversheet?: ICoversheet | null): boolean {
        return (coversheet && coversheet.name === "<NO COVERSHEET>")? true: false;
    }

    isRemoveEnabled(): boolean {
        if (this.coversheets) {
            let found = _.find(this.coversheets, cs => {
                return cs.selected && !this.isDefaultCoversheet(cs.name) && !this.isVirtualNocover(cs);
            });
            if (found) return true;
        }
        return false;
    }

    isSetDefaultEnabled(): boolean {
        if (this.coversheets) {
            let selection = _.filter(this.coversheets, cs => { return cs.selected; });
            return (selection.length === 1 && !this.isDefaultCoversheet(selection[0].name));
        }
        return false;
    }

    nameStyle(name?: string | null): {} {
        return (name && this.isDefaultCoversheet(name))? { 'font-weight': 'bold' }: { };
    }

    set_default(): void {
        let selection = _.find(this.coversheets, cs => { return cs.selected; });
        if (selection) {
            let def: FaxConfigRestOrganizationDef = {
                Templates: { DefaultFaxTemplate: this.isVirtualNocover(selection)? 'nocover.cs': selection.name }
            };
            this.faxSrv.PutOrganizationValues(def).subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.default_coversheet = selection!.name;
                        selection!.selected = false;
                    }
                },
                error: err => alert(err.message)
            });
        }
    }
}
