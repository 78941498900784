<h1>Outbound Service Management</h1>

<p>
    Outbound Service Management feature allows you to change the assigned direction of
    your configured device services to better match your message flow needs at different times of the day.
</p>

<div *ngIf="auth.isModifiable('Outbound Service Management')" class="btn-toolbar">
    <button type="button" class="btn" (click)="neo()">New</button>
    <div class="btn-group">
        <button type="button" class="btn" (click)="enable()" [disabled]="!canEnable">Enable</button>
        <button type="button" class="btn" (click)="disable()" [disabled]="!canDisable">Disable</button>
    </div>
    <button type="button" class="btn" (click)="remove()" [disabled]="!canRemove">Remove</button>
    <button type="button" class="btn btn-link" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
</div>

<div *ngIf="osmGridInitialized" id="osmGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="osmGridOptions"></ag-grid-angular>
</div>
