<section class="modal wide">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Phone Number Options</h1>
    </div>
    <form id="address_setup_form" class="form" (submit)="submit()" name="addressSetupForm">
        <div class="modal-body">
            <p>
                The list below contains the known connections.
                The Service (Out/InOut) links a connection to an outbound (or both in- and outbound) service of a device. By attaching numbers to a connection the messages sent from this number will be routed via this connection.
                If a separate device-service is used to handle inbound connections, the Service (In) must be specified for Address registration to correctly identify the connection from the service name.
            </p>
            <p *ngIf="session.isMultiTenant()" >
                New connections will be created in the scope of the currently selected Organization-scope. 
            </p>
            <div class="btn-toolbar" *ngIf="pagerReady && isReady" >
                <button type="button" class="btn" (click)="provider_add()">New</button>
                <button type="button" class="btn" (click)="provider_del()" [disabled]="provider_del_disabled()">Remove</button>
            </div>
            <app-pagination [limit]="session.portalCfg.items_per_page" [query]="query"></app-pagination>

            <table *ngIf="pagerReady" class="table table-click table-condensed table-bordered">
                <thead>
                    <tr>
                        <th style="width:25px"></th>
                        <th style="width:80px">Enabled</th>
                        <th style="width:120px" *ngIf="session.contextAllOrganizations()">Organization</th>
                        <th>Service (Out/InOut)</th>
                        <th>Service (In)</th>
                        <th>Display name</th>
                        <th style="width:50px">Used</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of pagerProviders.items" (click)="provider_edit(item)">
                        <td>
                            <input type="checkbox" name="Enabled" [(ngModel)]="item.selected" *ngIf="!isEditing(item)" (click)="$event.stopPropagation()">
                        </td>
                        <td>
                            <span *ngIf="!isEditing(item)" >{{ item.Enabled | boolean }}</span>
                            <input *ngIf="isEditing(item)" type="checkbox" name="tmpEnabled" [(ngModel)]="inEdit!.Enabled" (click)="$event.stopPropagation()">
                        </td>
                        <td *ngIf="session.contextAllOrganizations()">
                            <span title="{{ item.OrganizationName }}">{{ item.OrganizationName }}</span>
                        </td>
                        <td>
                            <span *ngIf="!isEditing(item)" title="{{ item.ServiceName }}">{{ item.ServiceName }}</span>
                            <input type="text" maxlength="255" name="ServiceName" [(ngModel)]="inEdit!.ServiceName" *ngIf="isEditing(item)"
                                title="The Service name of the connection. When using a dedicated outbound service, this identifies the outbound service name." />
                        </td>
                        <td>
                            <span *ngIf="!isEditing(item)" title="{{ item.ServiceNameInbound }}">{{ item.ServiceNameInbound }}</span>
                            <input type="text" maxlength="255" name="ServiceNameInbound" [(ngModel)]="inEdit!.ServiceNameInbound" *ngIf="isEditing(item)"
                                title="The Service name of the inbound connection. When using a dedicated inbound service, this identifies the inbound service name." />
                        </td>
                        <td>
                            <span *ngIf="!isEditing(item)" title="{{ item.ConnectionName }}">{{ item.ConnectionName }}</span>
                            <input *ngIf="isEditing(item)" type="text" maxlength="255" name="DisplayName" [(ngModel)]="inEdit!.ConnectionName"
                                title="The display name of the provider." />
                        </td>
                        <td>{{ item.Used | boolean }}</td>
                    </tr>
                    <tr *ngIf="inEdit && inEdit.ID == -1">
                        <td></td>
                        <td><input type="checkbox" name="glbEnabled" [(ngModel)]="inEdit.Enabled" (click)="$event.stopPropagation()"></td>
                        <td *ngIf="session.contextAllOrganizations()"></td>
                        <td>
                            <input type="text" name="glbServiceName" maxlength="255" [(ngModel)]="inEdit.ServiceName"
                                title="The Service name of the connection. When using a dedicated outbound service, this identifies the outbound service name." />
                        </td>
                        <td>
                            <input type="text" name="glbServiceNameInbound" maxlength="255" [(ngModel)]="inEdit.ServiceNameInbound"
                                title="The Service name of the inbound connection. When using a dedicated inbound service, this identifies the inbound service name." />
                        </td>
                        <td>
                            <input type="text" name="glbDisplayName" maxlength="255" [(ngModel)]="inEdit.ConnectionName" 
                                title="The display name of the provider." />
                        </td>
                        <td>{{ false | boolean }}</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="pagerReady && (pagerProviders.isEmpty() && !inEdit)">No Results</div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!isReady || !inEdit">Save</button>
            <button type="button" class="btn" (click)="provider_cancel_edit()" [disabled]="!isReady || !inEdit">Cancel</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
