import { Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule, NgForm } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestECRHostList, FaxConfigRestECRFaxFormatList, FaxConfigRestECRRule, FaxConfigRestECRDescription } from '../api/api';
import { ComboboxComponent } from '../combobox/combobox.component';
import * as _ from 'underscore';

export interface IEditee {
    index: number,
    rule: FaxConfigRestECRRule | null
}

@Component({
    selector: 'app-ecr-rule',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    templateUrl: './ecr-rule.component.html',
    styleUrl: './ecr-rule.component.css',
    encapsulation: ViewEncapsulation.None
})
export class EcrRuleComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    modified: boolean = false;
    isReadOnly: boolean;

    editee: IEditee;
    action: string;
    displayAction: string;

    messageTypes: string[] = [];
    hostNames: FaxConfigRestECRHostList = [];
    faxFormats: FaxConfigRestECRFaxFormatList = [];
    hostAddressTypes: { [key: string]: string[] } = {};
    rule: FaxConfigRestECRRule = {};
    ruleDescriptionLines: string[] | undefined;

    pageTabs: string[] = ['General', 'Route To', 'Conditions'];
    state: { activePageTab: string } = { activePageTab: this.pageTabs[0] };

    compareOptions: string[] = ['=', '<>', '<', '<=', '>', '>='];
    routingTypes: { [key: string]: string[] } = {
        'FAX': ['CSID', 'DIR', 'FAX', 'FSR', 'ORIGINATOR', 'SR'],
        'SMS': ['FSR', 'MSISDN', 'ORIGINATOR', 'SCAN', 'SMS', 'SR'],
        'MMS': ['FSR', 'MSISDN', 'ORIGINATOR', 'SCAN', 'SMS', 'SR'],
        'TLX': ['FSR', 'ORIGINATOR', 'SCAN', 'SR']
    };

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: IEditee }
    ) {
        this.editee = data.editee;
        this.action = (this.editee.rule === null) ? 'new' : 'edit';
        // this.action can change, so init a separate displayAction string
        this.displayAction = (this.editee.rule === null) ? 'New' : 'Edit';
        this.isReadOnly = !auth.isModifiable("Extended Custom Routing");
        this.init();
    }

    private init(): void {
        let promises: Observable<boolean>[] = [
            this.faxSrv.kernelAddressTypes().pipe(map(types => {
                _.each(types.DefaultHostSystemsMap?? [], host => {
                    if (host.FriendlyName) {
                        this.hostNames.push(host.FriendlyName);
                        this.hostAddressTypes[host.FriendlyName] = _.pluck(host.AddressTypes?? {}, 'Description');
                    }
                });
                return true;
            })),
            this.faxSrv.ecrFaxFormats().pipe(map(formats => {
                this.faxFormats = formats;
                return true;
            }))
        ];

        if (this.action === 'edit') {
            promises.push(
                this.faxSrv.ecrRule(this.editee.index, this.editee.rule?.RuleKey!).pipe(map(rule => {
                    this.rule = rule;
                    return true;
                }))
            );
            promises.push(
                this.faxSrv.ecrRuleDescription(this.editee.index, this.editee.rule?.RuleKey!).pipe(map(desc => {
                    this.setRuleDescription(desc);
                    return true;
                }))
            );
        }

        forkJoin(promises).subscribe({
            next: res => {
                if (this.action === 'new') { // had to wait for hostNames
                    this.rule = {
                        RuleComment: '',
                        RuleEnabled: false,
                        MailSystem: 'SMTP Stack',
                        AddressType: '',
                        SystemAddress: '',
                        MessageClass: 'IPM.FAX',
                        MessageType: 'FAX',
                        RouteOnType: false,
                        RoutingType: '',
                        RoutingCode: '',
                        RouteOnPages: false,
                        PagesCompare: '',
                        RoutePages: 0,
                        RouteOnMsgSize: false,
                        SizeCompare: '',
                        RouteSize: 0,
                        FaxFormat: 'TIF'
                    };
                }
                this.onMailSystemChanged(this.rule.MailSystem!);
                this.isReady = true;
            },
            error: err => {
                console.error(err.message);
            }
        });

        this.faxSrv.messageTypes().subscribe(res => {
            this.messageTypes = res;
        });
    }

    submitEnabled(form: NgForm): boolean {
        const rule = this.rule;
        return (this.isReady && form.valid && rule.MailSystem && rule.AddressType &&
            (!rule.RouteOnType || (rule.RoutingType && rule.RoutingCode)) &&
            (!rule.RouteOnPages || (rule.PagesCompare && rule.RoutePages)) &&
            (!rule.RouteOnMsgSize || (rule.SizeCompare && rule.RouteSize)) )?
            true: false;
    }

    submit(): void {
        this.doSave().subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            },
            error: err => { alert(err.message); }
        });
    }

    apply(): void {
        this.doSave().subscribe({
            next: res => {
                if (this.action === 'new') {
                    this.action = 'edit';
                    this.rule.RuleKey = res.RuleKey; // RuleKey is only returned on POST, not on PUT
                }
                this.setRuleDescription(res);
            },
            error: err => { alert(err.message); }
        });
    }

    setRuleDescription(res: FaxConfigRestECRDescription): void {
        this.ruleDescriptionLines = res.Description?.split('\n');
    }

    onMailSystemChanged(ms: string): void {
        this.rule.MailSystem = ms;
        if (this.hostAddressTypes && this.hostAddressTypes[ms]) {
            let addrType: string | undefined;
            // default to 'email address' for the SMTP Stack
            if (ms === 'SMTP Stack') {
                addrType = _.find(this.hostAddressTypes[ms], item => { return item === 'email address'; });
            }
            this.rule.AddressType = addrType?? this.hostAddressTypes[ms][0]?? '';
        }
    }

    doSave(): Observable<FaxConfigRestECRDescription> {
        let rule = this.rule;
        // normalize a bit
        if (!_.contains(this.routingTypes[rule.MessageType!], rule.RoutingType)) {
            // if RoutingType is not one of the allowed types.
            // This can happen if user first selects a message type and corresponding routing type,
            // and then selects a different message type.
            rule.RoutingType = '';
        }
        if (rule.MailSystem === '<Unspecified>') {
            rule.MailSystem = '';
        }
        if (this.action === 'new') {
            return this.faxSrv.createEcrRule(this.editee.index, rule);
        } else {
            return this.faxSrv.updateEcrRule(this.editee.index, rule.RuleKey!, rule);
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
