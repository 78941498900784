import { Component, Inject, inject } from '@angular/core';
import { Observable, AsyncSubject } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestConnectionInfoList, FaxConfigRestAddressBatchDef } from '../api/api';

interface IEditee {
    AddrType: string,
    AddressStart: string,
    AddressEnd: string,
    ConnectionId: number,
    Description: string
}

interface IScope {
    mode: string,
    editee: IEditee,
    Connections: FaxConfigRestConnectionInfoList,
    formattedConnectionName: string
}

@Component({
    selector: 'app-addresses-batch-edit',
    imports: [FormsModule, CommonModule],
    templateUrl: './addresses-batch-edit.component.html',
    styleUrl: './addresses-batch-edit.component.css'
})
export class AddressesBatchEditComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = true;
    modified: boolean = false;
    operationPending: boolean = false;

    headerText: string = '';
    rangeText: string = '';
    submitText: string = '';
    isDIRNumber: boolean = false;
    scope: IScope;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.scope = data as IScope;
        this.init();
    }

    init(): void {
        const addrTypeUpper = this.scope.editee.AddrType.toUpperCase();
        this.isDIRNumber = (addrTypeUpper === 'DIR');

        if (this.scope.mode == 'add') {
            this.headerText = 'Add ' + addrTypeUpper + ' number(s)';
            this.rangeText = 'Any existing numbers within this range will not be touched.';
            this.submitText = 'Add';
        }
        else if (this.scope.mode == 'edit') {
            this.headerText = 'Edit ' + addrTypeUpper + ' number(s)';
            this.rangeText = 'Any non-existing numbers within this range will be silently ignored.';
            this.submitText = 'Modify';
        }
        else if (this.scope.mode == 'del') {
            this.headerText = 'Remove ' + addrTypeUpper + ' number(s)';
            this.rangeText = 'Any non-existing numbers within this range will be silently ignored.';
            this.submitText = 'Remove';
        }
        else if (this.scope.mode == 'attach') {
            this.headerText = 'Attach ' + addrTypeUpper + ' number(s)';
            this.rangeText = 'Any already attached or any non-existing numbers within this range will be silently ignored.';
            this.submitText = 'Attach';
        }
        else if (this.scope.mode == 'detach') {
            this.headerText = 'Detach ' + addrTypeUpper + ' number(s)';
            this.rangeText = 'Any already detached or any non-existing numbers within this range will be silently ignored. '+
                                'When numbers are attached to users as routing-codes these will be removed.\n' +
                                'Please note that numbers attached to connections owned by an organization cannot be detached from the organization.';
            this.submitText = 'Detach';
        }
        else {
            console.warn('unknown scope.mode')
        }
    }

    doAdd(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestAddressBatchDef = {
            AddrType: this.scope.editee.AddrType,
            AddressStart: this.scope.editee.AddressStart,
            AddressEnd: this.scope.editee.AddressEnd,
            Enabled: true,
            ConnectionId: this.scope.editee.ConnectionId,
            Description: this.scope.editee.Description,
            OrganizationId: null
        };
        return this.faxSrv.PostAddressesBatch(def);
    }

    doEdit(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestAddressBatchDef = {
            AddrType: this.scope.editee.AddrType,
            AddressStart: this.scope.editee.AddressStart,
            AddressEnd: this.scope.editee.AddressEnd,
            ConnectionId: this.scope.editee.ConnectionId,
            Description: this.scope.editee.Description
        };
        return this.faxSrv.PutAddressesBatch(def);
    }

    doDel(forceDelete: boolean): Observable<FaxConfigRestResult> {
        return this.faxSrv.DeleteAddressesBatch(this.scope.editee.AddrType, this.scope.editee.AddressStart, this.scope.editee.AddressEnd, forceDelete);
    }

    doAttach(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestAddressBatchDef = {
            AddrType: this.scope.editee.AddrType,
            AddressStart: this.scope.editee.AddressStart,
            AddressEnd: this.scope.editee.AddressEnd,
            OrganizationId: this.session.currentOrgId
        };
        return this.faxSrv.PutAddressesBatch(def);
    }

    doDetach(forceDetach: boolean): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestAddressBatchDef = {
            AddrType: this.scope.editee.AddrType,
            AddressStart: this.scope.editee.AddressStart,
            AddressEnd: this.scope.editee.AddressEnd,
            OrganizationId: 0,
            Force: forceDetach
        };
        return this.faxSrv.PutAddressesBatch(def);
    }

    validate_batch(): { error: string, count: number } {
        if (this.isDIRNumber) {
            return this.validate_batch_dir();
        } else {
            return this.validate_batch_e164();
        }
    }

    private validate_batch_dir(): { error: string, count: number } {
        let regex = /^[0-9]([0-9] ?)*$/;
        if (!this.scope.editee.AddressStart || !this.scope.editee.AddressStart.match(regex)) {   // not allowed empty
            return { error: 'The start is not a valid number.', count: 0 };
        }
        if (this.scope.editee.AddressEnd) {                                                      // allowed empty
            if (!this.scope.editee.AddressEnd.match(regex)) {   
                return { error: 'The end is not a valid number', count: 0 };
            } else if (this.scope.editee.AddressEnd.length != this.scope.editee.AddressStart.length) {
                return { error: 'The end number must have same length as start number.', count: 0 };
            } else {
                let nStart = parseInt(this.scope.editee.AddressStart.replace(/\D/g,''), 10);
                let nEnd   = parseInt(this.scope.editee.AddressEnd.replace(/\D/g,''), 10);

                let nStartHead = Math.floor(nStart / 10000);
                let nEndHead   = Math.floor(nEnd / 10000);
                let nStartTail = nStart % 10000;
                let nEndTail   = nEnd % 10000;
                
                if (nStartHead != nEndHead) {
                    return { error: 'The end number must differ from the start number only in the last 4 digits.', count: 0 };
                } else if (nEndTail < nStartTail) {
                    return { error: 'The end number must be larger than the start number.', count: 0 };
                }
                return { error: '', count: nEndTail - nStartTail + 1 };
            }
        } else {
            return { error: '', count: 1 };
        }
    }

    private validate_batch_e164(): { error: string, count: number } {
        var regexE164 = /^\+?([0-9] ?){5,15}$/;
        if (!this.scope.editee.AddressStart || !this.scope.editee.AddressStart.match(regexE164)) {   // not allowed empty
            return { error: 'The start number is not in E.164 format, i.e. +19995550123', count: 0 };
        }
        if (this.scope.editee.AddressEnd) {                                                      // allowed empty
            if (!this.scope.editee.AddressEnd.match(regexE164)) {   
                return { error: 'The end number is not in E.164 format, i.e. +19995550123', count: 0 };
            } else if (this.scope.editee.AddressEnd.length != this.scope.editee.AddressStart.length) {
                return { error: 'The end number must have same length as start number.', count: 0 };
            } else {
                var nStart = parseInt(this.scope.editee.AddressStart.replace(/\D/g,''), 10);
                var nEnd   = parseInt(this.scope.editee.AddressEnd.replace(/\D/g,''), 10);

                var nStartHead = Math.floor(nStart / 10000);
                var nEndHead   = Math.floor(nEnd / 10000);
                var nStartTail = nStart % 10000;
                var nEndTail   = nEnd % 10000;
                
                if (nStartHead != nEndHead) {
                    return { error: 'The end number must differ from the start number only in the last 4 digits.', count: 0 };
                } else if (nEndTail < nStartTail) {
                    return { error: 'The end number must be larger than the start number.', count: 0 };
                }
                return { error: '', count: nEndTail - nStartTail + 1 };
            }
        } else {
            return { error: '', count: 1 };
        }
    }

    submit(): void {
        var result = this.validate_batch();
        if (result.error) {
            alert(result.error);
            return;
        }
        this.operationPending = true;

        if (this.scope.mode == 'add') {
            this.doAdd().subscribe((res: FaxConfigRestResult) => {
                this.operationPending = false;
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            });
        }
        else if (this.scope.mode == 'edit') {
            this.doEdit().subscribe((res: FaxConfigRestResult) => {
                this.operationPending = false;
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            });
        }
        else if (this.scope.mode == 'del') {
            this.doDel(false).subscribe((res: FaxConfigRestResult) => {
                this.afterDelete(res).subscribe((res: FaxConfigRestResult) => {
                    this.close();
                });
            });
        }
        else if (this.scope.mode == 'attach') {
            this.doAttach().subscribe((res: FaxConfigRestResult) => {
                this.operationPending = false;
                if (this.fenUtils.afterSave(res) > 0) {
                    this.modified = true;
                    this.close();
                }
            });
        }
        else if (this.scope.mode == 'detach') {
            this.doDetach(false).subscribe(res => {
                this.afterDetach(res).subscribe(res => {
                    this.close();
                });
            });
        }
        else {
            console.warn('unknown scope.mode');
            this.operationPending = false;
        }
    };

    afterDetach(res: FaxConfigRestResult): Observable<FaxConfigRestResult> {
        let subj = new AsyncSubject<FaxConfigRestResult>();
        if (res.Status === 'ok') {
            this.operationPending = false;
            this.modified = true;
            subj.next(res);
            subj.complete();
            return subj;
        }
        let msg = this.fenUtils.formatErrors(res.Errors!);
        if (res.Errors![0].Id == 7 /* WRN_DOES_NOT_SUPPORT */) {
            if (!msg) {
                msg = "One or more " + (this.isDIRNumber? "DIR numbers": "phone numbers") + " in the selected range are assigned to users.";
            }
            if (!confirm(msg + "\nIf you detach the range then the users will no longer have access to those numbers.\nDo you want to continue?")) {
                this.operationPending = false;
                subj.error(res);
                subj.complete();
                return subj;
            }
            this.doDetach(true).subscribe({
                next: res => {
                    this.operationPending = false;
                    this.modified = true;
                    subj.next(res);
                    subj.complete();
                },
                error: res => {
                    this.operationPending = false;
                    subj.error(res);
                    subj.complete();
                }
            });
        } else {
            if (msg) alert(msg);
            this.operationPending = false;
            subj.error(res);
            subj.complete();
        }
        return subj;
    };

    afterDelete(res: FaxConfigRestResult): Observable<FaxConfigRestResult> {
        let subj = new AsyncSubject<FaxConfigRestResult>();
        if (res.Status === 'ok') {
            this.operationPending = false;
            this.modified = true;
            subj.next(res);
            subj.complete();
            return subj;
        }
        let msg = this.fenUtils.formatErrors(res.Errors!);
        if (res.Errors![0].Id == 7 /* WRN_DOES_NOT_SUPPORT */) {
            if (!msg) {
                msg = "One or more " + (this.isDIRNumber? "DIR numbers": "phone numbers") + " in the selected range are assigned to users.";
            }
            if (!confirm(msg + "\nIf you delete the range then the users will no longer have access to those numbers.\nDo you want to continue?")) {
                this.operationPending = false;
                subj.error(res);
                subj.complete();
                return subj;
            }
            this.doDel(true).subscribe({
                next: res => {
                    this.operationPending = false;
                    this.modified = true;
                    subj.next(res);
                    subj.complete();
                },
                error: res => {
                    this.operationPending = false;
                    subj.error(res);
                    subj.complete();
                }
            });
        } else {
            if (msg) alert(msg);
            this.operationPending = false;
            subj.error(res);
            subj.complete();
        }
        return subj;
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
