import { CommonModule, NgFor } from '@angular/common';
import { Observable, forkJoin, finalize, map } from 'rxjs';
import { Component, inject, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { PaginationModule, PaginationComponent } from '../pagination/pagination.module';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestApiKeyList, FaxConfigRestApiKey } from '../api/api';
import { BooleanPipe } from '../utils/boolean.pipe';
import { IPaginationQuery } from '../pagination/pagination.component';
import { DialogService } from '../dialog/dialog.service';
import { ApiKeyNewComponent } from '../api-key-new/api-key-new.component';
import * as _ from 'underscore';

@Component({
    selector: 'app-api-keys',
    imports: [FormsModule, CommonModule, PaginationModule, NgFor, BooleanPipe],
    templateUrl: './api-keys.component.html',
    styleUrl: './api-keys.component.css'
})
export class ApiKeysComponent implements AfterViewInit, IPaginationQuery {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    @ViewChild(PaginationComponent)
    pagerApiKeys!: PaginationComponent;
    public query_api_keys: IPaginationQuery = this;
    pagerReady: boolean = false;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService) {
    }

    ngAfterViewInit() {
        this.pagerApiKeys?.reset();
    }

    invoke(offset: number, limit: number) {
        return this.faxSrv.GetApiKeys(offset, limit).pipe(map(res => {
            this.pagerReady = true;
            return res;
        }));
    }

    select_item(item: FaxConfigRestApiKey) {
        item.selected = item.selected? false: true;
    }

    isAnySelected(): boolean {
        if (this.pagerApiKeys) {
            return _.find(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                return item.selected?? false;
            });
        }
        return false;
    }

    isAnyRevoked(): boolean {
        if (this.pagerApiKeys) {
            return _.find(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                return item.selected && !item.Enabled;
            });
        }
        return false;
    }

    isAnyActivated(): boolean {
        if (this.pagerApiKeys) {
            return _.find(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                return item.selected && item.Enabled;
            });
        }
        return false;
    }

    revoke_api_key(): void {
        if (this.pagerApiKeys) {
            let promises: Observable<FaxConfigRestApiKey>[] = [];
            _.each(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                if (item.selected && item.Enabled && item.ID) {
                    promises.push(this.faxSrv.RevokeApiKey(item.ID));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises)
                    .pipe(finalize(() => { this.pagerApiKeys.refresh(); }))
                    .subscribe({ error: (err) => alert(err.message) });
            }
        }
    }

    activate_api_key(): void {
        if (this.pagerApiKeys) {
            let promises: Observable<FaxConfigRestApiKey>[] = [];
            _.each(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                if (item.selected && !item.Enabled && item.ID) {
                    promises.push(this.faxSrv.ActivateApiKey(item.ID));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises)
                    .pipe(finalize(() => { this.pagerApiKeys.refresh(); }))
                    .subscribe({ error: (err) => alert(err.message) });
            }
        }
    }

    delete_api_key(): void {
        if (this.pagerApiKeys) {
            if (confirm("Are you sure you want to delete the selected API Key(s)?")) {
                let promises: Observable<FaxConfigRestResult>[] = [];
                _.each(this.pagerApiKeys.items, (item: FaxConfigRestApiKey) => {
                    if (item.selected && item.ID) {
                        promises.push(this.faxSrv.DeleteApiKey(item.ID));
                    }
                });
                if (promises.length > 0) {
                    forkJoin(promises)
                        .pipe(finalize(() => { this.pagerApiKeys.refresh(); }))
                        .subscribe({
                            next:  (res) => this.fenUtils.afterSave(res),
                            error: (err) => alert(err.message)
                        });
                }
            }
        }
    }

    static readonly infinite = Date.parse("2037-12-31T00:00:00Z");

    isInfinite(item: FaxConfigRestApiKey): boolean {
        return item.ExpiresDate? (item.ExpiresDate >= ApiKeysComponent.infinite): false;
    }

    new_api_key(): void {
        const dialogRef = this.dialog.open(ApiKeyNewComponent, { data: {} });
        dialogRef.afterClosed().subscribe(created => {
            if (created) { this.pagerApiKeys?.refresh(); }
        });
    }
}
