<h1>Organizations</h1>

<p>
    The list of all known organizations.
	<span *ngIf="auth.isModifiable('Organizations')"> Add, modify or delete an organization.</span>
</p>

<div class="filterbar">
    <form role="form" (submit)="search_organizations()">
        <div class="form-group has-feedback pull-right">
            <i class="form-control-feedback glyphicon glyphicon-search" (click)="search_organizations()"></i>&ngsp;
            <input type="text" class="form-control" [(ngModel)]="searchkeyOrganizations" name="searchkeyOrganizations" placeholder="Search" />
        </div>
    </form>
</div>
<div id="list_content">
    <div class="btn-toolbar" *ngIf="auth.isModifiable('Organizations')">
        <button type="button" class="btn" (click)="new_organization()">New</button>
        <button type="button" class="btn" (click)="remove_organization()" [disabled]="!can_remove_organization()">Remove</button>
    </div>

    <app-pagination [limit]="session.portalCfg.items_per_page" [query]="query_organizations"></app-pagination>

    <table *ngIf="isReady" id="organizations_list" class="table table-click table-condensed table-bordered">
        <thead>
            <tr>
                <th width="25px"></th>
                <th>Enabled</th>
                <th>Name</th>
                <th>Description</th>
                <th>Created</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let org of pagerOrganizations?.items" (click)="edit_organization(org)">
                <td><input type="checkbox" *ngIf="auth.isModifiable('Organizations')" [(ngModel)]="org.selected" (click)="$event.stopPropagation()" id="{{org.ID}}"></td>
                <td>{{ org.Enabled | boolean }}</td>
                <td>{{ org.Name }}</td>
                <td>{{ org.Description }}</td>
                <td>{{ org.CreatedDate | date:'yyyy-MM-dd' }}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="isReady && pagerOrganizations?.isEmpty()">No Results</div>
</div>
