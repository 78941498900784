import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';

@Component({
    selector: 'app-overview',
    imports: [CommonModule, RouterLink],
    templateUrl: './overview.component.html',
    styleUrl: './overview.component.css'
})
export class OverviewComponent {

  constructor(public auth: AuthService, public session: Session){

  }
  
  hasAdminRole = () : boolean => {
    return (this.auth.getRole().indexOf('Administrator') >= 0);
};
}
