import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelMonitoringSenderSMTP, FaxConfigRestResult } from '../api/api';
import * as _ from 'underscore';

export interface ISenderSmtpScope {
    title: string;
    editee: FaxConfigRestKernelMonitoringSenderSMTP;
}

interface ITLSOption {
    UseTLS: boolean;
    MinTLSVerAllowed: string;
    name: string;
}

interface IState {
    activePageTab: string;
    tlsOpt: ITLSOption;
}

@Component({
    selector: 'app-monitoring-sender-smtp',
    imports: [FormsModule, CommonModule],
    templateUrl: './monitoring-sender-smtp.component.html',
    styleUrl: './monitoring-sender-smtp.component.css'
})
export class MonitoringSenderSmtpComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    title: string;
    editee: FaxConfigRestKernelMonitoringSenderSMTP;
    isModifiable: boolean;
    Description: string = '';

    pageTabs: string[] = [ 'Message Properties', 'Connection Parameters' ];
    tlsOptions: ITLSOption[] = [
        { UseTLS: false, MinTLSVerAllowed: '',    name: 'Do not use TLS' },
        { UseTLS: true,  MinTLSVerAllowed: '1.0', name: 'Allow minimum TLS v1.0 ' },
        { UseTLS: true,  MinTLSVerAllowed: '1.1', name: 'Allow minimum TLS v1.1 ' },
        { UseTLS: true,  MinTLSVerAllowed: '1.2', name: 'Allow minimum TLS v1.2 ' }
    ];
    state: IState = { activePageTab: this.pageTabs[0], tlsOpt: this.tlsOptions[0] };

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: ISenderSmtpScope
    ) {
        this.title = data.title;
        this.editee = _.clone(data.editee);
        this.pageTabClick(this.state.activePageTab);
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
        if (this.editee.UseTLS) {
            let sel = _.find(this.tlsOptions, opt => {
                return opt.MinTLSVerAllowed === this.editee.MinTLSVerAllowed
            });
            if (sel) this.state.tlsOpt = sel;
        }
    }

    pageTabClick(pageTab: string): void {
        this.state.activePageTab = pageTab;
        this.Description = 'Click or hover over an option to view its description.';
    }

    private doSave(): Observable<FaxConfigRestResult> {
        this.editee.UseTLS = this.state.tlsOpt.UseTLS;
        this.editee.MinTLSVerAllowed = this.state.tlsOpt.MinTLSVerAllowed;
        return this.faxSrv.updateMonitoringSender(this.editee);
    }

    submitEnabled(): boolean {
        const sender: FaxConfigRestKernelMonitoringSenderSMTP = this.editee;
        const sender_type: string = sender.__type.toLowerCase();
        return (sender_type === 'smtp' &&
            sender.From && sender.To && sender.Port &&
            sender.SmtpServer && this.state.tlsOpt)? true: false;
    }

    save(): void {
        this.doSave().subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    apply(): void {
        this.doSave().subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    focusin(event: any): void {
        this.Description = event.currentTarget.title;
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
