import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult } from '../api/api';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DialogService } from '../dialog/dialog.service';
import { AccountPasswordComponent, IAccountPassword } from '../account-password/account-password.component';

@Component({
    selector: 'app-user-tools',
    imports: [CommonModule],
    templateUrl: './user-tools.component.html',
    styleUrl: './user-tools.component.css'
})
export class UserToolsComponent {
    session: Session = inject(Session)
    auth: AuthService = inject(AuthService)
    faxSrv: FaxConfigApi = inject(FaxConfigApi);
    router = inject(Router);

    constructor (private dialog: DialogService) {
    }

    get userName(){ return this.auth.getUserName() }

    get isLocalAccount(){ return this.auth.isLocalAccount() }

    logout(): void {
        // Wait until POST /Logout completes before navigating away.
        let afterLogout = (): void => {
            this.session.clear();
            this.auth.clear();
            this.router.navigate(['/login']);
        };
        if (this.session.currentUser) {
            let headers: HttpHeaders = new HttpHeaders({ 'Authorization': this.auth.getAuthHeader()?? '' }) ;
            this.faxSrv.POST<FaxConfigRestResult>('Logout', null, { headers: headers , 'observe': 'response'}).subscribe({
                next:  () => { afterLogout(); },
                error: () => { afterLogout(); }
            });
        } else {
            afterLogout();
        }
    }

    change_my_password(): void {
        let account: IAccountPassword = {
            OldPassword: '',
            NewPassword: '',
            VerifyPassword: ''
        };
        const dialogRef = this.dialog.open(AccountPasswordComponent, {
            data: { editee: account }
        });
        dialogRef.afterClosed().subscribe();
    }
}
