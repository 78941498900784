import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigReportList, FaxConfigRestLogReportResults, LogReportEntry } from '../api/api';
import * as _ from 'underscore';

interface IMonthOptions {
    key: string;
    value: Date;
}

interface IBaseStatistics {
    id: string;
    title: string;
    reportName: string;
    monthsBack: boolean;
    dateRange: boolean;
    propertyToTitle: Function;
}

interface IState {
    activePageTab: IBaseStatistics | null;
    activeMsgTab: string;
}

interface IReport {
    generalData: any,
    msgTypes: string[];
    byMessageType: any;
}

@Component({
    selector: 'app-reporting',
    imports: [FormsModule, CommonModule],
    templateUrl: './reporting.component.html',
    styleUrl: './reporting.component.css'
})
export class ReportingComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);

    reports: FaxConfigReportList = [];
    isReady: boolean = false;
    pageTabs: IBaseStatistics[] = [];
    state: IState = { activePageTab: null, activeMsgTab: '' };
    data: FaxConfigRestLogReportResults | null = null;
    error: string = '';
    operationMode = 'byMonth';
    startDate: string = '';
    untilDate: string = '';
    report: IReport | null = null;
    selectedMsgType = null;

    monthOptions: IMonthOptions[] = [];

    monthsBack = '';
    formatting = {
        date: 'yyyy-MM-dd',
        time: 'HH:mm:ss',
        month: 'yyyy-MM'
    };

    pageConfigs: { [key: string]: IBaseStatistics } = {
        'UsageStatistics': {
            id:          'UsageStatisticsPage',
            title:       'Usage Statistics',
            reportName : 'UsageStatistics', 
            monthsBack : true,
            dateRange :  true,
            propertyToTitle: (propName: string): string => {
                switch(propName) {
                    case 'TotalMessagesSent':           return 'Messages Accepted';
                    case 'TotalMessagesReceived':       return 'Messages Received';
                    case 'TotalMessagesDelivered':      return 'Received Messages delivered';
                    case 'TotalMessagesSentSuccess':    return 'Messages Sent successfully';
                    case 'TotalPagesSentSuccess':       return 'Pages in successful Messages';
                    case 'TotalMessagesSentFailed':     return 'Undeliverable Messages';
                    case 'TotalPagesSentFailed':        return 'Pages Transmitted in Undeliverable messages';
                    case 'TotalPagesProcessedFailed':   return 'Pages in Failed messages';
                    case 'TotalPagesReceived':          return 'Pages in Messages Received';
                    case 'TotalPagesDelivered':         return 'Pages in Received Messages delivered';
                    default: 
                        return propName;
                }
            }
        }
    };

    constructor (public auth: AuthService, public session: Session) {
        this.session.rootPromises.subscribe(() => this.init());
    }

    init(): void {
        let user: string | null = this.auth.getUserName();
        if (user) {
            let val: string | null;
            user = user.toLowerCase();
            val = localStorage.getItem(user + 'LogDateFormat');
            this.formatting.date = val? val: 'yyyy-MM-dd';
            val = localStorage.getItem(user + 'LogTimeFormat');
            this.formatting.time = val? val: 'HH:mm:ss';
        }

        // Remove the day from the date format string
        let fmt: string = this.formatting.date.replace(/d+/, '');   // remove the day placeholder
        fmt = fmt.replace(/(\s*,\s*)+/g, ' ').trim();   // replace (comma and any whitespace around it) with a single space
        fmt = fmt.replace(/^[\-\/ ]|[\-\/ ]$/, '');     // remove leading or trailing - and /
        this.formatting.month = fmt.replace(/([\-\/ ])\1/, (str, match) => { return match[0]}); // remove duplicate /-
        
        let now = new Date(Date.now());
        let date = new Date(now.getFullYear(), now.getMonth(), 1);  // the first of every month always exists

        for (let m = 0; m < 12; m++)
        {
            this.monthOptions.push({ key: date.toJSON(), value: date });
            if (date.getMonth() > 0) {
                date = new Date(date.getFullYear(), date.getMonth()-1, 1);  // the first of every month always exists
            } else {
                date = new Date(date.getFullYear()-1, 11, 1);  // the first of every month always exists
            }
        }

        this.faxSrv.GetReports().subscribe(res => {
            this.reports = res;

            res.forEach(name => {
                if (name in this.pageConfigs) {
                    this.pageTabs.push(this.pageConfigs[name]);
                }
            });
            if (this.reports.length > 0) {
                this.state = { activePageTab: this.pageTabs[0], activeMsgTab: '' };
            }
        });

        this.isReady = true;
    }

    GenerateReport(): void {
        let date: Date;
        let argStartDate: string = '';
        let argUntilDate: string = '';
        if (this.operationMode === 'byMonth') {
            this.startDate = '';
            this.untilDate = '';
            
            // Get the proper UTC time-stamps for the date
            argStartDate = this.monthsBack;
            date = new Date(this.monthsBack);
            if (date.getMonth() < 11) {
                date = new Date(date.getFullYear(), date.getMonth()+1, 1);
            } else {
                date = new Date(date.getFullYear()+1, 0, 1);
            }
            argUntilDate = date.toJSON();
        } else {
            this.monthsBack = '';
            date = new Date(this.startDate);
            date.setHours(0, 0, 0);
            argStartDate = date.toJSON();
            // Until date is all of the selected day up to and including the last second
            date = new Date(this.untilDate);
            date.setHours(23, 59, 59, 0);
            argUntilDate = date.toJSON();
        }
        this.error = '';
        this.data = null;
        this.report = null;
        this.selectedMsgType = null;
        if (this.state.activePageTab) {
            this.faxSrv.GetReport(this.state.activePageTab.reportName, '', argStartDate, argUntilDate).subscribe({
                next: res => {
                    this.data = res;
                    this.transformData(res);
                },
                error: err => {
                    this.error = err.message;
                }
            });
        }
    }

    transformData(items: FaxConfigRestLogReportResults): void {
        switch (this.state.activePageTab?.reportName)
        {
        case 'UsageStatistics':
            this.transformUsageStatisticsData(items);
            break;
        }
    }

    groupBy(objectArray: object[], property: string): {} {
        return objectArray.reduce((acc: any, obj: any) => {
            let key: any = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    transformUsageStatisticsData(items: FaxConfigRestLogReportResults): void {
        let byContext: { [key: string]: any } = this.groupBy(items, 'Context' );
        let msgTypes: string[] = [];

        let generalData: any = byContext['undefined'].reduce((acc: LogReportEntry, obj: LogReportEntry) => {
            acc[obj.Property as keyof LogReportEntry] = obj;
            return acc;
        }, {});
        // date comes in as yyyy-MM-dd, parse it so it can be formatted using the log-formatting
        generalData.StartDate.Value = new Date(generalData.StartDate.Value);
        generalData.EndDate.Value = new Date(generalData.EndDate.Value);
        generalData.GenerationTime.Value = new Date(generalData.GenerationTime.Value);
        delete(byContext['undefined']);

        let toTitle = this.state.activePageTab?.propertyToTitle || ((s: any) => {return s;});

        for (let k in byContext) {if (k) msgTypes.push(k);};

        msgTypes.forEach(ctx => {
            byContext[ctx].forEach((e: any) => {
                e.Title = toTitle(e.Property);
            });
        });

        for (let k in byContext) {
            if (k) {
                // Add a list of device services (if more than one) within each context.
                let dev: any[] = [];
                byContext[k] = this.groupBy(byContext[k], 'SubContext');
                for (let name in byContext[k]) {
                    if (name && name !== 'undefined') { dev.push({ Key: name, Name: name }); }
                }
                if (dev.length > 1) {
                    // Important, leave the Key property undefined for this one:
                    dev.splice(0, 0, { Key: undefined, Name: '<All services>' });
                    byContext[k].devices = dev;
                    byContext[k].activeDevice = dev[0];
                } else {
                    byContext[k].activeDevice = {};
                }
            }
        }

        this.report = {
            generalData : generalData,
            msgTypes : msgTypes,
            byMessageType : byContext
        };

        if (msgTypes.length > 0) {
            this.state.activeMsgTab = msgTypes[0];
        }
    }
}
