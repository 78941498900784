<h1>System Information</h1>

<p>
    This page displays information about the Faxination files currently installed on the specified machine.
</p>

<table class="table" id="systemInfo_table">
    <tbody>
        <tr>
            <td>
                <strong>Category:</strong>&nbsp;
                <select [(ngModel)]="selectedCategory" (ngModelChange)="refresh()">
                    <option *ngFor="let k of availableCategories" [value]="k.Category">{{ k.Description }}</option>
                </select>
            </td>
            <td>
                <strong>Machine:</strong>&nbsp;
                <select *ngIf="hasMultipleKernels()" [(ngModel)]="selectedKernel" (ngModelChange)="refresh()">
                    <option *ngFor="let k of availableKernels" [value]="k">{{ k }}</option>
                </select>
                <strong *ngIf="!hasMultipleKernels()">{{ selectedKernel }}</strong>
            </td>
            <td>
                <button type="button" class="btn btn-link" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="systemInfoGridInitialized" id="systemInfoGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="systemInfoGridOptions"></ag-grid-angular>
</div>
