<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>{{ title }}</h1>
    </div>
    <form id="fen_dialog_alert" class="form" novalidate (submit)="close()">
        <div class="modal-body">
            <div style="white-space:pre-wrap;">{{ text }}</div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" tabindex="1">OK</button>
        </div>
    </form>
</section>
