
<style>

</style>

<main class="main">
  <div id="north">
    <div class="container">
        <img id="logo" alt="logo" src="faxination-logo-189x25.png" />
        <!-- Layout: right to left -->
        <app-user-tools></app-user-tools>
        <app-organization-tools></app-organization-tools>
    </div>
  </div>
  <div id="center" class="container">
    <app-navigation></app-navigation>
    <div id="content">
      <router-outlet></router-outlet>
    </div>
  </div>

  
  
  <div id="south">
    <div class="container">
        Faxination Management Portal
        <div class="version">v{{version_info.version}}</div> 
    </div>
  </div>
</main>


