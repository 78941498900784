import { Component, inject } from '@angular/core';
import { FaxConfigApi } from '../api/faxconfig';
import { AuthService } from '../services/auth.service';
import { HttpErrorResponse, HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Session } from '../services/session.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { AccountPasswordComponent, IAccountPassword } from '../account-password/account-password.component';
import { parseBoolean } from '../login/login.component';

@Component({
    selector: 'app-onetimelogin',
    imports: [FormsModule, CommonModule],
    templateUrl: './onetimelogin.component.html',
    styleUrl: './onetimelogin.component.css'
})
export class OnetimeloginComponent {
    private faxSrv: FaxConfigApi = inject(FaxConfigApi);
    private auth: AuthService = inject(AuthService);
    private session: Session = inject(Session);
    private router: Router = inject(Router);

    token: string = '';

    constructor(private dialog: DialogService, private route: ActivatedRoute) {
        this.session.rootPromises.subscribe(() => {
            this.route.queryParams.subscribe(params => {
                this.token = decodeURIComponent(params['token']?? '');
                this.onetimelogin();
            });
        });
    }

    private change_my_password(): void {
        let account: IAccountPassword = {
            OldPassword: '',
            NewPassword: '',
            VerifyPassword: ''
        };
        const dialogRef = this.dialog.open(AccountPasswordComponent, {
            data: { editee: account, isReset: true }
        });
        dialogRef.afterClosed().subscribe(() => {
            this.session.reload(true).subscribe(res => {
                if (res) {
                    this.router.navigate(['/']);
                } else {
                    alert('Could not create login session. Please try again later.');
                    this.auth.clear();
                    this.session.clear();
                    this.router.navigate(['/login']);
                }
            });
        });
    }

    private loginError(stat?: number) {
        if (stat === HttpStatusCode.Unauthorized || stat === HttpStatusCode.Forbidden) {
            alert('One-time login link has expired or is invalid.');
        } else {
            alert('Unable to contact the server at this moment. Please try again later.');
        }
        this.auth.clear();
        this.session.clear();
        this.router.navigate(['/login']);
    }

    private onetimelogin(): void {
        let headers = { 'Authorization': 'Bearer ' + this.token };
        this.faxSrv.LOGIN<any>('OneTimeLogin?result=jwt', null, { headers: headers, observe: "events" }).pipe(
        catchError((error: HttpErrorResponse) => {
            this.loginError(error.status)
            return throwError(() => new Error('OneTimeLogin failed'));
        })).subscribe((res) => {
            switch (res.type) {
                case HttpEventType.Response: {
                    if (res.status === 200 && res.body.Status === 'ok') {
                        this.auth.setCredentials(res.headers.get('x-fen-account'), res.headers.get('x-fen-auth-jwt'));
                        this.auth.setOrganization(res.headers.get('x-fen-org-id') || 0, res.headers.get('x-fen-org-context') || 0);
                        this.auth.setRoleAccess(res.headers.get('x-fen-role')?? '', res.headers.get('x-fen-access')?? '', parseBoolean(res.headers.get('x-fen-adaccount')), '');
                        this.session.afterLogin();
                        this.session.currentUser = this.auth.getUserName();
                        this.session.currentUserOrgId = this.auth.getUserOrgId();
                        this.session.currentOrgId = this.auth.getContextOrgId();
                        this.session.currentUserOrgName = undefined;
                        this.session.currentOrgName = undefined;
                        this.session.prevOrgId = null;
                        this.session.prevOrgName = undefined;
                        this.change_my_password();
                    } else {
                        this.loginError(res.status);
                    }
                    break;
                }
            }
        });
    }
}
