<h1>Number Substitution</h1>

<p>
    Number Substitution allows you to automatically correct outbound fax numbers by removing, and replacing digits.
    You may also use this feature to replace external fax numbers with their internal
    extension equivalents for calls within your own telephony system.
    Finally, the Number Substitution feature can be configured to block individual numbers or ranges of numbers.
</p>

<div class="filterbar" *ngIf="isReady">
    <select name="Machine" [(ngModel)]="machine" (ngModelChange)="onMachineChange()">
        <option *ngFor="let machine of machines" [ngValue]="machine.id">{{ machine.label }}</option>
    </select>
</div>

<div *ngIf="isReady && auth.isModifiable('Number Substitution')" class="btn-toolbar">
    <button type="button" class="btn" (click)="neo()" [disabled]="!machine">New</button>
    <div class="btn-group">
        <button type="button" class="btn" (click)="enable()" [disabled]="!canEnable">Enable</button>
        <button type="button" class="btn" (click)="disable()" [disabled]="!canDisable">Disable</button>
    </div>
    <button type="button" class="btn" (click)="remove()" [disabled]="!canRemove">Remove</button>
    <button type="button" class="btn btn-link" (click)="export()" title="Export the results into a .csv formatted file">Export</button>
</div>

<div *ngIf="gridInitialized" id="numsubstGrid" class="ag-theme-alpine">
    <ag-grid-angular [style]="cssGridHeight" [gridOptions]="gridOptions"></ag-grid-angular>
</div>



