import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestOrganizationPdfTimeStamp, FaxConfigRestKernelFontDef } from '../api/api';
import { ComboboxComponent } from '../combobox/combobox.component';
import * as _ from 'underscore';

@Component({
    selector: 'app-pdftimestamp-config',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    templateUrl: './pdftimestamp-config.component.html',
    styleUrl: './pdftimestamp-config.component.css'
})
export class PdftimestampConfigComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    modified: boolean = false;
    editee: FaxConfigRestOrganizationPdfTimeStamp;
    pdfTimeStampEnabled: boolean;

    DateFormats = [
        'd/MM/yyyy',
        'dd/MM/yyyy',
        'M/dd/yyyy',
        'MM/dd/yyyy',
        'yyyy/MM/dd'
    ];

    TimeFormats = [
        'H:mm',
        'HH:mm',
        'H:mm:ss',
        'HH:mm:ss',
        'h:mm tt',
        'hh:mm tt',
        'h:mm:ss tt',
        'hh:mm:ss tt'
    ];

    GmtFormats = [
        '\'(GMT\'+h\')\'',
        '\'(GMT\'+hh:mm\')\'',
        ''
    ];

    fontStyles = [ 'Regular', 'Italic', 'Bold', 'Bold Italic' ];
    positions  = [ 'Upper Left', 'Upper Right', 'Lower Left', 'Lower Right' ];

    Fonts: FaxConfigRestKernelFontDef[] = [];
    FontStyle: string = 'Bold';
    FontSize: number = 12;
    Position: string = 'Upper Left';
    HorzMargin: number = 0.8;
    VertMargin: number = 0.4;
    PreviewText: string = '';

    datemenu_show: boolean = false;
    timemenu_show: boolean = false;
    gmtmenu_show: boolean = false;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as FaxConfigRestOrganizationPdfTimeStamp;
        this.pdfTimeStampEnabled = this.editee.Enabled?? false;
        this.init();
    }

    init(): void {
        let num: number;
        this.Fonts  = [ { Name: this.editee.FontName?? '' } ];
        this.datemenu_show = false;
        this.timemenu_show = false;
        this.gmtmenu_show  = false;

        if (this.editee.DateFormat && !_.contains(this.DateFormats, this.editee.DateFormat)) {
            this.DateFormats.push(this.editee.DateFormat);
        }

        if (this.editee.TimeFormat && !_.contains(this.TimeFormats, this.editee.TimeFormat)) {
            this.TimeFormats.push(this.editee.TimeFormat);
        }

        if (!this.editee.UseLocalServerTime) {
            this.GmtFormats.splice(2, 0, ' UTC');
        }

        if (this.editee.GmtFormat && !_.contains(this.GmtFormats, this.editee.GmtFormat)) {
            this.GmtFormats.push(this.editee.GmtFormat);
        }

        if (this.editee.FontItalic) {
            if (this.editee.FontWeight && this.editee.FontWeight >= 550) {
                this.FontStyle = 'Bold Italic';
            } else {
                this.FontStyle = 'Italic';
            }
        } else {
            if (this.editee.FontWeight && this.editee.FontWeight >= 550) {
                this.FontStyle = 'Bold';
            } else {
                this.FontStyle = 'Regular';
            }
        }

        num = this.editee.FontSize?? 12;
        if (num >= 1 && num <= 72) {
            this.FontSize = num;
        }

        num = parseFloat(this.editee.HorzMargin?? '0.8');
        if (!isNaN(num)) {
            this.HorzMargin = num;
        }

        num = parseFloat(this.editee.VertMargin?? '0.4');
        if (!isNaN(num)) {
            this.VertMargin = num;
        }

        if (this.HorzMargin >= 0) {
            if (this.VertMargin >= 0) {
                this.Position = 'Upper Left';
            } else {
                this.Position = 'Lower Left';
            }
        } else {
            if (this.VertMargin >= 0) {
                this.Position = 'Upper Right';
            } else {
                this.Position = 'Lower Right';
            }
        }

        this.preview();

        this.faxSrv.getFontsDefs().subscribe(server_fonts => {
            this.Fonts = server_fonts;
            this.isReady = true;
        });
    }

    doSave(): Observable<FaxConfigRestResult> {
        let def: FaxConfigRestOrganizationPdfTimeStamp = _.clone(this.editee);
        def.Enabled = this.pdfTimeStampEnabled;
        def.FontSize = this.FontSize;
        def.HorzMargin = this.HorzMargin.toString(10);
        def.VertMargin = this.VertMargin.toString(10);
        return this.faxSrv.PutOrganizationValues({ PdfTimeStamp: def });
    }

    toggleDateMenu(): void {
        this.datemenu_show = !this.datemenu_show;
    }
    updateDateField(): void {
        this.datemenu_show = false;
        this.preview();
        this.modified = true;
    }

    toggleTimeMenu(): void {
        this.timemenu_show = !this.timemenu_show;
    }
    updateTimeField(): void {
        this.timemenu_show = false;
        this.preview();
        this.modified = true;
    }

    toggleGmtMenu(): void {
        this.gmtmenu_show = !this.gmtmenu_show;
    }
    updateGmtField(): void {
        this.gmtmenu_show = false;
        this.preview();
        this.modified = true;
    }

    style_change(): void {
        if (this.FontStyle === 'Bold' || this.FontStyle === 'Bold Italic') {
            this.editee.FontWeight = 700;   // FW_BOLD
        } else {
            this.editee.FontWeight = 400;   // FW_NORMAL
        }
        if (this.FontStyle === 'Italic' || this.FontStyle === 'Bold Italic') {
            this.editee.FontItalic = true;
        } else {
            this.editee.FontItalic = false;
        }
        this.modified = true;
    }

    pos_change(): void {
        if (this.Position === 'Upper Left') {
            if (this.HorzMargin < 0) {
                this.HorzMargin = -this.HorzMargin;
            }
            if (this.VertMargin < 0) {
                this.VertMargin = -this.VertMargin;
            }
        } else if (this.Position === 'Upper Right') {
            if (this.HorzMargin >= 0) {
                this.HorzMargin = -this.HorzMargin;
            }
            if (this.VertMargin < 0) {
                this.VertMargin = -this.VertMargin;
            }
        } else if (this.Position === 'Lower Left') {
            if (this.HorzMargin < 0) {
                this.HorzMargin = -this.HorzMargin;
            }
            if (this.VertMargin >= 0) {
                this.VertMargin = -this.VertMargin;
            }
        } else if (this.Position === 'Lower Right') {
            if (this.HorzMargin >= 0) {
                this.HorzMargin = -this.HorzMargin;
            }
            if (this.VertMargin >= 0) {
                this.VertMargin = -this.VertMargin;
            }
        }
        this.editee.HorzMargin = this.HorzMargin.toString(10);
        this.editee.VertMargin = this.VertMargin.toString(10);
        this.modified = true;
    }

    preview(): void {
        this.faxSrv.GetPdfTimeStampPreview(
            this.editee.DateFormat?? '',
            this.editee.TimeFormat?? '',
            this.editee.GmtFormat?? '',
            this.editee.UseLocalServerTime?? false
        ).subscribe(res =>
            { this.PreviewText = res.PreviewText?? ''; }
        );
    }

    date_format_change(fmt: string): void {
        this.editee.DateFormat = fmt;
        this.preview();
        this.modified = true;
    }

    time_format_change(fmt: string): void {
        this.editee.TimeFormat = fmt;
        this.preview();
        this.modified = true;
    }

    gmt_format_change(fmt: string): void {
        this.editee.GmtFormat = fmt;
        this.preview();
        this.modified = true;
    }

    localtime_change(): void {
        if (this.editee.UseLocalServerTime) {
            if (this.editee.GmtFormat === ' UTC') {
                this.editee.GmtFormat = '';
            }
            this.GmtFormats.splice(2, 1);
        } else {
            this.GmtFormats.splice(2, 0, ' UTC');
        }
        this.preview();
        this.modified = true;
    }

    any_change(): void {
        this.modified = true;
    }

    submitEnabled(): boolean {
        return (this.isReady && this.modified);
    }

    save(): void {
        this.doSave().subscribe({
            next: res => {
                if (this.fenUtils.afterSave(res) > 0) this.close();
            },
            error: err => alert(err.message)
        });
    };

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
