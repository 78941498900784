import {PermissionSet} from '../constants/app.constants'
import { Injectable , inject} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $cookies = inject(CookieService);

  private _userName: string |null = null;
  private _userOrgId: number = 0;
  private _contextOrgId: number = 0;
  private _authToken: string | null = null;
  private _Role: string = '';
  private _Access : Map<string,number>;
  private _ADAccount:string = '';
  private _Source:string ='';

  constructor(){
    this._Access = new Map<string,number>;
    let testToken = this.$cookies.get('authToken');
    if (testToken){
      this.setCredentials(this.$cookies.get('userName'), testToken);
      this.setOrganization(
        parseInt(this.$cookies.get('userOrgId'), 10),
        parseInt(this.$cookies.get('contextOrgId'), 10));
      this.setRoleAccess(
              this.$cookies.get('Role'),
              this.$cookies.get('Access'),
              this.$cookies.get('ADAccount') === '1',
              this.$cookies.get('Source'));
    }
  }

  setCredentials = (userName : string | null, authToken: string| null) => {
    this._userName = userName
    this._authToken = authToken
    if (authToken!== null) {
      // store in cookies in case of page refresh
      this.$cookies.set('userName', this._userName || '');
      this.$cookies.set('authToken', this._authToken || '');
    } else {
      this.$cookies.delete('userName');
      this.$cookies.delete('authToken');
    }
  }

  setOrganization = (userOrgId: string| number, contextOrgId: number| string) => {
    // ensure we store these values as numbers, not strings!
    this._userOrgId    = parseInt(userOrgId.toString(10)) || 0;
    this._contextOrgId = parseInt(contextOrgId.toString(10)) || 0;
    if (this._userOrgId) {
      // store in cookies in case of page refresh
      this.$cookies.set('userOrgId', this._userOrgId.toString(10));
      this.$cookies.set('contextOrgId', this._contextOrgId.toString(10));
    } else {
      this.$cookies.delete('userOrgId');
      this.$cookies.delete('contextOrgId');
    }
  }

  clear = (): void => {
      this._userName = null;
      this._authToken = null;
      this._userOrgId = 0;
      this._contextOrgId = 0;
      this._Role = '';
      this._ADAccount = '';
      this._Source = '';
      this.$cookies.delete('userName');
      this.$cookies.delete('authToken');
      this.$cookies.delete('userOrgId');
      this.$cookies.delete('contextOrgId');
      this.$cookies.delete('Role'),
      this.$cookies.delete('Access'),
      this.$cookies.delete('ADAccount'),
      this.$cookies.delete('Source');
  };

  knownRoles = () :any => {
      // Keep these in order and in sync with FaxConfigSvc constants
      return { 
          'Global Administrator':     10,
          'Service Administrator':    20,
          'Power Administrator':      23,
          'Administrator':            25,
          'Monitor':                  30,
          'User Manager':             50
      };
  };

  decodeRole = (roleStr : string) :number => {
      return this.knownRoles()[roleStr];
  }

  private decodeAccess(access: string) {

    const self = this;
    // Keep these in order and in sync with FaxConfigSvc constants
    const perm = [
      '',                                      /* 0 (forbidden) */
      PermissionSet.All,                          /* 1 (wildcard, all permissions) */
      PermissionSet.Accounts,                     /* 2 */
      PermissionSet.Licenses,                     /* 3 */
      PermissionSet.Services,                     /* 4 */
      PermissionSet.Coversheets,                  /* 5 */
      PermissionSet.ECR,                          /* 6 */
      PermissionSet.Log,                          /* 7 */
      PermissionSet.OSM,                          /* 8 */
      PermissionSet.OSR,                          /* 9 */
      PermissionSet.Queue,                        /* 10 */
      PermissionSet.Users,                        /* 11 */
      PermissionSet.FaxinationServerMonitor,      /* 12 */
      PermissionSet.NumberSubstitution,           /* 13 */
      PermissionSet.ServiceRequest,               /* 14 */
      PermissionSet.Connectors,                   /* 15 */
      PermissionSet.KernelProperties,             /* 16 */
      PermissionSet.FaxLineLimits,                /* 17 */
      PermissionSet.FaxinationKernelConfig,       /* 18 */
      PermissionSet.AuditLog,                     /* 19 */
      PermissionSet.SDXProperties,                /* 20 */
      PermissionSet.Organizations,                /* 21 */
      PermissionSet.Addresses,                    /* 22 */
      PermissionSet.OrganizationProperties,       /* 23 */
      PermissionSet.KernelContracts,              /* 24 */
      PermissionSet.APIKeys,                      /* 25 */
      PermissionSet.AddressRegistration           /* 26 */
    ]
    var perms = access.split("|");
    self._Access = new Map<string,number>();
    
    perms.forEach( (res) => {
        var acc = res.split(":");
        var p1 = parseInt(acc[0], 10);
        var p2 = parseInt(acc[1], 10);
        if (p1 > 0 && p1 < perm.length) {
          self._Access.set(perm[p1], p2);
        }
    });

  }

  setRoleAccess = (role:string, access:string, adaccount:boolean, source:string) => {
    this._Role = role;
    this._ADAccount = adaccount? '1':'';
    this._Source = source || '';
    this.decodeAccess(access);
    if (role) {
      // store in cookies in case of page refresh
      this.$cookies.set('Role', this._Role);
      this.$cookies.set('Access', access);
      this.$cookies.set('ADAccount', this._ADAccount);
      this.$cookies.set('Source', this._Source);
    } else {
      this.$cookies.delete('Role');
      this.$cookies.delete('Access');
      this.$cookies.delete('ADAccount');
      this.$cookies.delete('Source');
    }
  };

  getUserName = () : string|null => {
      return this._userName
  }

  getUserOrgId = (): number  => {
      return this._userOrgId
  }

  getContextOrgId = (): number  => {
      return this._contextOrgId
  }

  getContextOrgName = () : string => {
    return this._contextOrgId.toString()
  }

  getAuthToken = () : string | null => {
      return this._authToken
  }

  getAuthHeader = (): string|null  => {
      return this._authToken ? "Bearer " + this._authToken: null;
  }

  getRole = (): string  => {
      return this._Role;
  };

  private getAccess(permission: string): number {
    if (this._Access) {
      var ret = this._Access.get(PermissionSet.All);
      if (ret) return ret;
      ret = this._Access.get(permission);
      if (ret) return ret;
    }
    return 0;
  }

  public isViewable(permission: string) : boolean {
    var acc = this.getAccess(permission);
    return (acc > 0 && acc <= 20);
  }

  public isModifiable(permission: string) : boolean {
    var acc = this.getAccess(permission);
    return (acc > 0 && acc <= 10);
  }

  isLocalAccount = () => {
    return (this._ADAccount ||
            (this._Source === 'AzureAD') ||
            (this._Source === 'AzureAD_MultiTenant') ||
            (this._Source === 'AzureAD_Social')
           ) ? false : true;
  };

  updateAuthToken(token: string | null) {
    if (token !== this._authToken) {
      this._authToken = token;
      // store in cookies in case of page refresh
      this.setCredentials(this._userName, this._authToken);
    }
  }



}
