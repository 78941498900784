<a href="javascript:history.back()">&larr; Back to Host Connector Settings</a>

<div id="conn_host_header" *ngIf="isReady">
    <h1>{{ hostGeneral.FriendlyName }}</h1>
</div>

<div *ngIf="isReady">
    <ul class="nav nav-tabs">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>

    <!-- Lazy loading. Wait until isReady -->
    <div [hidden]="state.activePageTab !== 'General'">
        <ng-container *ngTemplateOutlet="conn_general"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Channels'">
        <ng-container *ngTemplateOutlet="conn_channel_list"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Inbound'">
        <ng-container *ngTemplateOutlet="conn_inbound"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'DKIM'">
        <ng-container *ngTemplateOutlet="conn_smtp_dkim"></ng-container>
    </div>
</div>

<!-- General Capabilities -->
<ng-template #conn_general>
    <h3>General capabilities of the {{ hostGeneral.FriendlyName }}</h3>

    <div style="margin-bottom:25px">
    The following are the fixed capabilities that this connector supports.
    The individual channels exposed by this connector may optionally support all, some, or none of these capabilities.
    </div>

    <div>
        <label>Address Types supported:</label>
        <table class="table table-condensed table-bordered">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let addr of hostGeneral.AddrTypes">
                    <td>{{ addr.ExternalName }}</td>
                    <td>{{ addr.Description }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-top:25px" *ngIf="hostGeneral.BodyAsAttachment">
    Message body must be delivered as a separate attachment.
    </div>

    <div style="margin-top:25px" *ngIf="!hostGeneral.BodyAsAttachment && hostGeneral.BodyTypes">
        <label>Message body types supported:</label>
        <table class="table table-condensed table-bordered">
            <tbody>
                <tr *ngFor="let body of hostGeneral.BodyTypes">
                    <td>{{ body.Description }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-top:25px" *ngIf="hostGeneral.AttTypes">
        <label>Attachment types supported:</label>
        <table class="table table-condensed table-bordered" *ngIf="supportsAllAttTypes()">
            <tbody>
                <tr>
                    <td>{{ supportsAllAttTypes()!.Description }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-condensed table-bordered" *ngIf="!supportsAllAttTypes()">
            <tbody>
                <tr *ngFor="let att of hostGeneral.AttTypes">
                    <td>{{ att.Description }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- Channel List -->
<ng-template #conn_channel_list>
    <div>
        <p>Every connector has a default channel where it can receive messages.</p>
        <button type="button" class="btn" (click)="edit_channel(null)">Edit Default Channel...</button>
    </div>

    <div style="margin-top:25px;" *ngIf="hostGeneral.UsesSystemAddresses">
        <p>The {{ hostGeneral.FriendlyName }} supports additional channels with different capabilities.
    Each of these channels will only receive messages which are sent to its System Address.</p>

        <div class="btn-toolbar">
            <button type="button" class="btn" (click)="add_channel()">Add...</button>
            <button type="button" class="btn" (click)="remove_channel()" [disabled]="fenUtils.nothingSelected(hostChannels)">Remove</button>
        </div>
        <table class="table table-click table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="25px"></th>
                    <th>System Address</th>
                    <th>Friendly Name</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let chan of hostChannels" (click)="edit_channel(chan)">
                    <td><input type="checkbox" [(ngModel)]="chan.selected" *ngIf="chan.SystemAddress" (click)="$event.stopPropagation()"></td>
                    <td>{{ chan.SystemAddress }}</td>
                    <td>{{ chan.FriendlyName }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<!-- Inbound Options -->
<ng-template #conn_inbound>
    <div style="margin-bottom:25px">
    Inbound messages routed to users will be passed using the filename template set below. Filenames will automatically receive a proper extension.
    </div>

    <!-- This min-height creates empty space for the FilenameTemplate options to expand into: -->
    <form class="form" style="min-height: 630px;" id="filename_form" name="filenameForm" novalidate>
        <div class="field">
            <label>Template:</label>
            <app-filename-template name="FilenameTemplate" [(model)]="hostGeneral.Inbound!.FilenameTemplate" [options]="FilenameTemplateFields"
                title="Enter the filename format." [disabled]="!isConnectorsModifiable">
            </app-filename-template>&nbsp;
            <img src="gear-icon.png" class="clickable" (click)="fileNameDateTimeFormat()" 
                title="Datetime configuration..." >
        </div>
        <br>
        <button type="button" class="btn btn-primary" (click)="fileNameFormApply()">Apply</button>
    </form>
</ng-template>

<!-- DKIM Options -->
<ng-template #conn_smtp_dkim>
    <form class="form" id="smtp_dkimForm" novalidate>
        <p>
            DomainKeys Identified Mail (DKIM) is a feature that uses cryptography to digitally sign parts of an email message
            before sending it. The recipient of the message can validate the DKIM signature to confirm the identity of the sender,
            and verify that the signed parts of the email were not modified in transit.
            <span *ngIf="isKernelPropsModifiable"> On this page you can configure the SMTP Stack to sign all sent messages and/or validate all received messages.</span>
            <span *ngIf="!isKernelPropsModifiable"> On this page you can view the current SMTP Stack DKIM settings.</span>
        </p>
        <div>
            <label>SMTP send connectors:</label>
            <table class="table table-click table-condensed table-bordered">
                <thead>
                    <tr>
                        <th width="20%">Identifier</th>
                        <th>Friendly Name</th>
                        <th>DKIM Enabled</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sc of availableSendConnectors" (click)="editSendConnector(sc)">
                        <td>{{ sc.Identifier }}</td>
                        <td>{{ sc.FriendlyName }}</td>
                        <td>{{ (sc.DKIMEnabled?? false) | boolean }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div>
            <label>SMTP receive connectors:</label>
            <table class="table table-click table-condensed table-bordered">
                <thead>
                    <tr>
                        <th width="20%">Identifier</th>
                        <th>Friendly Name</th>
                        <th>DKIM Enabled</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rc of availableReceiveConnectors" (click)="editReceiveConnector(rc)">
                        <td>{{ rc.Identifier }}</td>
                        <td>{{ rc.FriendlyName }}</td>
                        <td>{{ (rc.DKIMEnabled?? false) | boolean }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</ng-template>
