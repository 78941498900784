<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Configure PDF Date/Time Stamp</h1>
    </div>
    <form id="pdftimestamp_config_form" class="form" novalidate (submit)="save()" #PdfTimeStampConfigForm="ngForm">
        <div class="modal-body">
            <div class="field">
                <label>Enabled:</label>
                <input type="checkbox" name="Enabled" [(ngModel)]="pdfTimeStampEnabled" (change)="any_change()"
                title="Enable or disable adding a date\time stamp when delivering inbound faxes as PDF">
            </div>

            <h3>Date/Time Stamp</h3>

            <div class="field">
                <label class="field-indented">Use Local Time</label>
                <input type="checkbox" name="UseLocalServerTime" [(ngModel)]="editee.UseLocalServerTime" (change)="localtime_change()"
                title="Format the date/time as local time of either user, organization or the fax server. If unchecked, the date/time will be formatted as UTC.">
            </div>

            <div class="field-indented">
                <p class="alert-info">Any text within single quotes (like 'text') is ignored in the format strings.</p>
            </div>

            <div class="field">
                <label class="field-indented">Date Format:</label>
                <app-combobox name="DateFormat" [model]="editee.DateFormat" (modelChange)="date_format_change($event)" [options]="DateFormats" [required]="true"
                title="Enter the date format, for example 'dd/MM/yyyy'. The format is case-sensitive.">
                </app-combobox>
            </div>
            <div class="field">
                <label class="field-indented">Time Format:</label>
                <app-combobox name="TimeFormat" [model]="editee.TimeFormat" (modelChange)="time_format_change($event)" [options]="TimeFormats" [required]="true"
                title="Enter the time format, for example 'hh:mm:ss tt'. The format is case-sensitive.">
                </app-combobox>
            </div>
            <div class="field">
                <label class="field-indented">GMT Format:</label>
                <app-combobox name="GmtFormat" [model]="editee.GmtFormat" (modelChange)="gmt_format_change($event)" [options]="GmtFormats" [required]="true"
                title="Enter an optional GMT offset format, for example '(GMT+hh:mm)'">
                </app-combobox>
            </div>
            <div class="field">
                <label class="field-indented">Page Position:</label>
                <select name="Position" style="width:160px;" [(ngModel)]="Position" (change)="pos_change()"
                    title="Choose a position on the fax page">
                    <option *ngFor="let p of positions" [value]="p">{{ p }}</option>
                </select>
            </div>

            <h3>Font</h3>

            <div class="field" ng-disabled="!isReady">
                <label class="field-indented">Font:</label>
                <select name="FontName" [(ngModel)]="editee.FontName" (change)="any_change()"
                    title="Choose a font">
                    <option *ngFor="let fs of Fonts" [value]="fs.Name">{{ fs.Name }}</option>
                </select>
            </div>
            <div class="field">
                <label class="field-indented">Font Size:</label>
                <input name="FontSize" type="number" min="1" max="72" required [(ngModel)]="FontSize" (change)="any_change()"
                title="Enter the font size in points (valid values are 1 to 72)">
            </div>
            <div class="field">
                <label class="field-indented">Font Style:</label>
                <select name="FontStyle" style="width:160px;" [(ngModel)]="FontStyle" (change)="style_change()"
                    title="Choose a font style">
                    <option *ngFor="let fs of fontStyles" [value]="fs">{{ fs }}</option>
                </select>
            </div>

            <p class="well"><strong>Preview (current server time):<br></strong>{{ PreviewText }}</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!PdfTimeStampConfigForm.valid || !submitEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
