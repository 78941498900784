import { Component, Inject, inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestOSRDef, FaxConfigRestStringList, FaxConfigRestKernelLoopbackServices } from '../api/api';
import { ComboboxComponent } from '../combobox/combobox.component';
import * as _ from 'underscore';

export interface IOsrRouteEditee {
    RouteType: string;
    MessageType: string;
    RouteValue: string;
}

@Component({
    selector: 'app-osr-route',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    templateUrl: './osr-route.component.html',
    styleUrl: './osr-route.component.css'
})
export class OsrRouteComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    editee: IOsrRouteEditee;
    action: string;
    isReady: boolean = false;
    modified: boolean = false;
    isModifiable: boolean;

    routeValue: string[] = [];
    routeTypes = this.faxSrv.outboundRouteTypes();
    loopbackServices: FaxConfigRestKernelLoopbackServices = [];
    messageTypes: FaxConfigRestStringList = [];

    route: FaxConfigRestOSRDef = {
        Description: '',
        RouteType: '',
        MessageType: '',
        RouteValue: '',
        Block: false,
        Service: '',
        Enabled: false
    };

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.editee = data.editee as IOsrRouteEditee;
        this.action = (this.editee === null) ? 'New' : 'Edit';
        this.isModifiable = auth.isModifiable('Outbound Service Routing');
        this.init();
    }

    private initRoute(route: FaxConfigRestOSRDef) {
        this.route = route;
        if (route && route.RouteValue) {
            this.routeValue = route.RouteType === '03' ? route.RouteValue.split('|') : [route.RouteValue, ''];
        } else {
            this.routeValue = ['', ''];
        }
    }

    isValid(): boolean {
        const route = this.route;
        return (route && // route might not be ready yet.
            (route.Block || route.Service) &&
            (this.routeValue[0] || route.RouteType === '05' || route.RouteType === '99'))? true: false;
    }

    private init(): void {
        let promises: {
            p1: Observable<FaxConfigRestStringList>,
            p2: Observable<FaxConfigRestKernelLoopbackServices>,
            p3?: Observable<FaxConfigRestOSRDef>
        } = {
            p1: this.faxSrv.messageTypes(),
            p2: this.faxSrv.getKernelLoopbackServices()
        };

        if (this.action === 'New') {
            this.initRoute({ // default values
                Description: '',
                RouteType: '99',
                MessageType: 'FAX',
                RouteValue: '',
                Block: false,
                Service: '', // Even if Block: true, still need to send an empty Service property.
                Enabled: true
            });
        } else {
            promises.p3 = this.faxSrv.osrDefinition(
                          this.editee.RouteType,
                          this.editee.MessageType,
                          this.editee.RouteValue
            );
        }

        forkJoin(promises).subscribe({
            next: res => {
                this.messageTypes = res.p1;
                this.loopbackServices = res.p2;
                if (res.p3) this.initRoute(res.p3);
                this.isReady = true;
            },
            error: err => alert(err.message)
        });
    }

    onRouteTypeChanged() {
        if (this.route) {
            if (this.route.RouteType === '03' || this.route.RouteType === '05') {
                this.route.MessageType = 'FAX';
            }
            if (this.route.RouteType === '05' || this.route.RouteType === '99') {
                this.routeValue = ['', ''];
            }
        }
    }

    save(): void {
        let route = this.route;
        if (route) {
            let promise: Observable<FaxConfigRestResult>;
            route.RouteValue = route.RouteType === '03' ? this.routeValue.join('|') : this.routeValue[0];
            if (this.action === 'New') {
                promise = this.faxSrv.createOsrDefinition(route);
            } else {
                promise = this.faxSrv.updateOsrDefinition(
                    this.editee.RouteType,
                    this.editee.MessageType,
                    this.editee.RouteValue,
                    route
                );
            }
            promise.subscribe({
                next: res => {
                    if (this.fenUtils.afterSave(res) > 0) {
                        this.modified = true;
                        this.close();
                    }
                },
                error: err => alert(err.message)
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.modified);
    }
}
