import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { FaxConfigRestJob } from '../api/api';

@Component({
    selector: 'app-queue-job',
    imports: [CommonModule],
    templateUrl: './queue-job.component.html',
    styleUrl: './queue-job.component.css'
})
export class QueueJobComponent {

    isReady: boolean = false;
    queue: string = 'Online';
    job: FaxConfigRestJob;
    selectedTab: number = 0;

    tabs = [
        {
            'title'     : 'General',
            'fields'    : ["ChargeCode", "Message", "CompletionTime","CreationTime","Group","IsSubmitted","JobNumber","LogJobNumber","MaxAttempts","OrgJobNumber","Result","Status","StatusText","SubmissionTime","Template","TotalPages"]
        },
        {
            'title'     : 'From',
            'fields'    : ["From"]
        },
        {
            'title'     : 'To',
            'fields'    : ["To"]
        },
        {
            'title'     : 'Message',
            'fields'    : ["Priority","ReportRequested", "ItemsRecv","MessageLength","MessageType","ReturnFileFormat","ReturnMailTemplate"]
        },
        {
            'title'     : 'Transmission',
            'fields'    : ["Baudrate","DeviceId","Description","Direction","Duration","LineId","OurCSID","RemoteCSID","RoutingCode","RetryScheme","Service","ServiceRoutingId"]
        }
    ];

    constructor(
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: any
    ) {
        this.job = data.job as FaxConfigRestJob;

        if (this.session.contextAllOrganizations()) {
            this.tabs[0].fields.push("OrganizationName");
        }

        for (let tab_key in this.tabs) {
            let newFields: string[] = [];
            for (let field_key in this.tabs[tab_key].fields) {
                let field: string = this.tabs[tab_key].fields[field_key];
                if (typeof(this.job[field as keyof FaxConfigRestJob]) !== "undefined") {
                    newFields.push(field);
                }
            }
            this.tabs[tab_key].fields = newFields;
        }
    }

    isObject(obj: any): boolean {
        // Careful, typeof(undefined) is 'undefined' but typeof(null) is 'object'!
        return (obj !== null && typeof(obj) === 'object');
    }

    close(): void {
        this.dialogRef.close();
    }
}
