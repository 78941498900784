<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" *ngIf="!isReset" (click)="close()">&times;</button>
        <h1>Change My Password</h1>
    </div>
    <form id="account_password_form" class="form" novalidate (submit)="submit()" #accountPasswordForm="ngForm">
        <div class="modal-body">
            <div class="field" *ngIf="!isReset"> 
                <label>Current Password:&nbsp;*</label>
                <input type="password" autocomplete="off" #currentPass="ngModel" name="currentPass" [(ngModel)]="editee.OldPassword" required
                    title="Enter your current password">&ngsp;
                <span class="text-danger" *ngIf="currentPass.dirty && currentPass.errors?.['required']">&nbsp;Your current password is required</span>
            </div>
            <div class="field">
                <label>New Password:&nbsp;*</label>
                <input type="password" autocomplete="off" #newPass="ngModel" name="newPass" maxlength="100" [(ngModel)]="editee.NewPassword" required
                    title="Enter your new password">&ngsp;
                <span class="text-danger" *ngIf="newPass.dirty && newPass.errors?.['required']">&nbsp;Please enter your new password</span>
            </div>
            <div class="field">
                <label>Verify Password:&nbsp;*</label>
                <input type="password" autocomplete="off" #verifyPass="ngModel" name="verifyPass" maxlength="100" [(ngModel)]="editee.VerifyPassword" required
                    title="Re-enter your new password for verification">&ngsp;
                <span class="text-danger" *ngIf="verifyPass.dirty && verifyPass.errors?.['required']">&nbsp;Please re-enter your new password</span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!accountPasswordForm.valid">Save</button>
            <button type="button" class="btn" *ngIf="!isReset" (click)="close()">Close</button>
        </div>
    </form>
</section>
