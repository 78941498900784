import { CommonModule, DatePipe } from '@angular/common';
import { Observable, Subscription, AsyncSubject, forkJoin, timer, of, map } from 'rxjs';
import { Component, inject, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PaginationModule,PaginationComponent } from '../pagination/pagination.module';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestResultId, FaxConfigRestDataSourcesGeneral, FaxConfigRestDataSourceDef,
    FaxConfigRestDbUsersResults, FaxConfigRestUserContainerList, FaxConfigRestUserContainerItem,
    FaxConfigRestDbUser, FaxConfigRestUserResultItem, FaxConfigRestUserResultList,
    FaxConfigRestUsersImportList, FaxConfigRestUsersImportItem
} from '../api/api';
import { IPaginationQuery } from '../pagination/pagination.component';
import { DialogService } from '../dialog/dialog.service';
import { FenUintOnlyDirective } from '../fen-uint-only.directive';
import { UserimportConfigComponent } from '../userimport-config/userimport-config.component';
import { UsersEditComponent, IUsersEditEditee } from '../users-edit/users-edit.component';
import { PermissionSet } from '../constants/app.constants';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, GridOptions, GetContextMenuItemsParams,
    CellDoubleClickedEvent, SelectionChangedEvent, GetRowIdParams,
    MenuItemDef, PaginationChangedEvent, ValueFormatterParams,
    IServerSideDatasource, IServerSideGetRowsParams,
    FilterModel, LoadSuccessParams, FilterChangedEvent
} from 'ag-grid-community';

interface ITreeNode {
    expanded: boolean,
    name: string,
    container: FaxConfigRestUserContainerItem,
    nodes: ITreeNode[] | null;
}

@Component({
    selector: 'app-users',
    imports: [FormsModule, CommonModule, PaginationModule, AgGridAngular, FenUintOnlyDirective],
    providers: [DatePipe],
    templateUrl: './users.component.html',
    styleUrl: './users.component.css'
})
export class UsersComponent implements OnDestroy {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    private fenUtils: FenUtilsService = inject(FenUtilsService);
    private router: Router = inject(Router);

    isReady: boolean = false;
    loadingUsers: boolean = false;
    isViewableUsers: boolean = false;
    isModifiableUsers: boolean = false;
    timerImports: Subscription | null = null;

    @ViewChild('pager_users')
    pagerUsers!: PaginationComponent;
    pagerUsersReady: boolean = false;
    public query_users: IPaginationQuery = {
        invoke: (offset: number, limit: number) => {
            if (this.faxSrv.isLdapProvider(this.dataSource?.ProviderName)) {
                return this.nonDbQueryUsers(offset, limit).pipe(map(res => {
                    this.pagerUsersReady = true;
                    return res;
                }));
            } else {
                return of([]).pipe(map(res => {
                    this.pagerUsersReady = true;
                    return res;
                }));
            }
        }
    };

    @ViewChild('pager_imports')
    pagerImports!: PaginationComponent;
    pagerImportsReady: boolean = false;
    public query_imports: IPaginationQuery = {
        invoke: (offset: number, limit: number) => {
            return this.dbQueryImports(offset, limit).pipe(map(res => {
                this.pagerImportsReady = true;
                return res?? [];
            }));
        }
    };

    pagesize: number = this.session.portalCfg.items_per_page;
    pageTabs: string[] = ['Manage', 'Import'];
    state = { activePageTab: this.pageTabs[0] };

    searchFilters: string[] = ['All','Names','Addresses','Routing codes','Charge codes'];
    removeEnabled: boolean = false;

    formDataUsers = {
        searchKeyUsers: '',
        searchFilter: 'All',
        showHiddenUsers: false,
        importRefreshEvery: ''
    };

    settingsDataSourcesGeneral: FaxConfigRestDataSourcesGeneral = {};
    dataSources: FaxConfigRestDataSourceDef[] = [];
    dataSource?: FaxConfigRestDataSourceDef;

    usersGridOptions?: GridOptions;
    usersGridApi?: GridApi;
    cssGridHeight = { 'height': '300px' };
    usersGridInitialized: boolean = false;

    nodes: ITreeNode[] = [];
    selectedNode: ITreeNode | null = null;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private datePipe: DatePipe) {
        this.session.rootPromises.subscribe(() => {
            this.isViewableUsers = auth.isViewable(PermissionSet.Users);
            this.isModifiableUsers = auth.isModifiable(PermissionSet.Users);
            this.init()
        });
    }

    usersColDefs: ColDef<any>[] = [
        {
            headerName: 'Organization',
            field: 'OrganizationName',
            hide: true,
            filter: null,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            field: 'Name'
        },
        {
            headerName: 'AddressType',
            field: 'AddressType',
            hide: true
        },
        {
            field: 'Address'
        },
        {
            headerName: 'ChargeCode',
            field: 'ChargeCode',
            hide: true
        },
        {
            headerName: 'FaxAllowed',
            field: 'Fax.AllowToSendFax',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [ '0', '1' ],
                valueFormatter: (params: ValueFormatterParams) => {
                    return (params.value !== '0')? 'Yes': 'No';
                },
                suppressSorting: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value? 'Yes': 'No';
            }
        },
        {
            headerName: 'IntFaxAllowed',
            field: 'Fax.AllowToSendFaxInt',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [ '0', '1' ],
                valueFormatter: (params: ValueFormatterParams) => {
                    return (params.value !== '0')? 'Yes': 'No';
                },
                suppressSorting: true
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value? 'Yes': 'No';
            }
        },
        {
            headerName: 'Coversheet',
            field: 'Fax.Coversheet',
            hide: true
        },
        {
            headerName: 'CSID',
            field: 'Fax.CSID',
            hide: true
        },
        {
            headerName: 'FileFormat',
            field: 'Fax.FileFormat',
            hide: true
        }
    ];

    ngOnDestroy() {
        this.timerImports?.unsubscribe();
    }

    init(): void {
        let promises: Observable<void>[] = [
            this.faxSrv.getDataSourcesGeneral().pipe(map(res => {
                this.settingsDataSourcesGeneral = res;
            })),
            this.faxSrv.dataSources().pipe(map(res => {
                // We only support LDAP/GC data sources in single-tenant mode.
                if (this.session.isMultiTenant()) {
                    this.dataSources = _.filter(res, ds => { return !this.faxSrv.isLdapProvider(ds.ProviderName); });
                } else {
                    this.dataSources = res;
                }
                if (this.dataSources.length > 0) {
                    this.dataSource = this.dataSources[0];
                }
            }))
        ];

        forkJoin(promises).subscribe({
            next: () => { this.initGrid(); },
            error: err => alert(err.message)
        });
    }

    private initGrid(): void {
        this.usersColDefs[0].hide = !this.session.contextAllOrganizations();

        this.usersGridOptions = {
            columnDefs: this.usersColDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['apply','reset','cancel'],
                    filterOptions: ['contains','notContains','equals','notEqual'],
                    maxNumConditions: 1,
                    closeOnApply: true
                }
            },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'multiple',
            suppressCsvExport: true,
            suppressExcelExport: true,
            onGridReady: params => {
                this.usersGridApi = params.api;
                var height = Math.max(300, window.innerHeight - 50);
                this.cssGridHeight = { 'height': height + 'px' };
                this.usersLoadViewState(params.api);
                this.dataSourceChanged();
                this.initImports();
                this.isReady = true;
            },
            getRowId: params => this.usersGetRowId(params),
            onCellDoubleClicked: params=> this.usersOnCellDoubleClicked(params),
            onSelectionChanged: params => this.usersOnSelectionChanged(params),
            onPaginationChanged: params => this.usersOnPaginationChanged(params),
            onFilterChanged: params => this.usersOnFilterChanged(params),
            getContextMenuItems: params => this.usersGetContextMenuItems(params),
            onGridPreDestroyed: params => this.usersSaveViewState(params.api)
        };
        this.usersGridInitialized = true;
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        let height = Math.max(300, innerHeight - 50);
        this.cssGridHeight = { 'height': height + 'px' };
    }

    setActivePageTab(pageTab: string): void {
        this.timerImports?.unsubscribe();
        this.state.activePageTab = pageTab;
        if (pageTab === 'Import') {
            this.timerImports = timer(1).subscribe(() => this.refreshImports());
        } else {
            // Reset this, because pagerImports is destroyed when switching to the Manage tab:
            this.pagerImportsReady = false; // reset
            this.refreshUsers();
        }
    }

    usersLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'UsersViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            // Set the visibility of the Organizations column based on context (ignore saved state)
                            idx = val.findIndex(item => { return item.colId === 'OrganizationName'; });
                            if (idx >= 0) {
                                val[idx].hide = !this.session.contextAllOrganizations();
                            }
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    usersSaveViewState(api: GridApi) {
        if (this.isReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'UsersViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    usersDataSource: IServerSideDatasource = {
        getRows: params => this.usersGetRows(params)
    };

    private usersGetRows(params: IServerSideGetRowsParams): void {
        let successData: LoadSuccessParams;
        let orderBy: string[] = [];
        let filters: {}[] = [];
        let fields;
        let count = params.request.endRow! - params.request.startRow!;

        if (!this.dataSource || !this.dataSource.Name) {
            successData = { rowData: [], rowCount: 0 };
            params.success(successData);
            return;
        }

        this.loadingUsers = true;

        if (params.request.sortModel) {
            _.each(params.request.sortModel, item => {
                orderBy.push(item.colId + '_' + item.sort);
            });
        }

        if (params.request.filterModel) {
            let foundEmptySet = _.find(params.request.filterModel, (model: FilterModel, key: string) => {
                if (model['filterType'] === 'text' && model['filter'])
                {
                    filters.push({ field: key + '_' + model['type'], value: model['filter'] });
                }
                else if (model['filterType'] === 'set' && model['values'])
                {
                    if (model['values'].length > 0) {
                        filters.push({ field: key, value: model['values'][0] });
                    } else {
                        // Empty set
                        return true;
                    }
                }
                return false;
            });
            // Return an empty list if an empty 'set' filter is selected in the UI.
            if (foundEmptySet) {
                params.success({ rowData: [], rowCount: 0 });
                return;
            }
        }

        fields = _.pluck(params.api.getAllGridColumns(), 'colId');

        this.faxSrv.dataSourceDbUsers(this.dataSource.Name, params.request.startRow!, count, fields, orderBy, filters).subscribe({
            next: (res: FaxConfigRestDbUsersResults) => {
                this.loadingUsers = false;
                if (res.Results) {
                    _.each(res.Results, user => {
                        user.State = 0;      // editable
                        user.Access = 0x100; // typeUser
                    });
                    successData = { rowData: res.Results };
                    if (res.Results.length < count || !res.searchInfo?.NextRange) {
                        successData.rowCount = params.request.startRow! + res.Results.length;
                    }
                } else {
                    successData = { rowData: [], rowCount: 0 };
                }
                params.success(successData);
            },
            error: err => {
                this.loadingUsers = false;
                params.fail();
            }
        });
    }

    private usersGetRowId(params: GetRowIdParams<FaxConfigRestDbUser>): string {
        // Compose unique ID as: '[OrganizationId:]AddressType:Address', but note
        // that FaxConfig only returns OrganizationIds in All Organizations context!
        let orgId: string;
        if (params.data.OrganizationId) {
            orgId = params.data.OrganizationId.toString(10) + ':';
        } else if (this.session.currentOrgId) {
            orgId = this.session.currentOrgId.toString(10) + ':';
        } else {
            orgId = '';
        }
        return (orgId + params.data.AddressType + ':' + params.data.Address);
    }

    private usersOnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit(params.data);
        }
    }

    private usersGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let menuItems: (string | MenuItemDef)[] = ['copy','separator'];
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        const canEdit = this.isModifiableUsers;
        let sel = params.api.getSelectedNodes();
        menuItems.push({
            name: canEdit? 'Edit User...': 'View User...',
            action: () => this.usersOnCellDoubleClicked(sel[0]),
            disabled: (!sel || sel.length != 1)
        });
        if (canEdit) {
            menuItems.push({
                name: 'New User...',
                action: () => this.neo(),
                disabled: this.session.contextAllOrganizations()
            });
            menuItems.push('separator');
            menuItems.push({
                name: 'Remove User(s)',
                action: () => this.remove(),
                disabled: (!sel || sel.length <= 0)
            });
        }
        return menuItems;
    }

    nonDbQueryUsers(offset: number, limit: number): Observable<FaxConfigRestUserResultList> {
        let subj = new AsyncSubject<FaxConfigRestUserResultList>();
        this.loadingUsers = true;
        let parent = this.selectedNode?.container.AdsPath?? '';
        this.faxSrv.dataSourceUsers(this.dataSource?.Name?? '', parent, this.formDataUsers.showHiddenUsers, offset, limit).subscribe({
            next: res => {
                this.loadingUsers = false;
                subj.next(res.Results?? []);
                subj.complete();
            },
            error: err => {
                this.loadingUsers = false;
                subj.next([]);
                subj.complete();
            }
        });
        return subj;
    }

    private makeNode(container: FaxConfigRestUserContainerItem): ITreeNode {
        return {
            expanded: false,
            name: container.DisplayName?? '',
            container: container,
            nodes: null  // null - uninitialized, [] - initialized, no children
        };
    }

    dataSourceChanged(): void {
        this.selectedNode = null;
        if (this.faxSrv.isDbProvider(this.dataSource?.ProviderName)) {
            this.nodes = [];
            if (this.usersGridApi) {
                this.usersGridApi.setGridOption('serverSideDatasource', this.usersDataSource);
            }
        } else if (this.faxSrv.isLdapProvider(this.dataSource?.ProviderName)) {
            this.faxSrv.dataSourceContainers(this.dataSource!.Name!).subscribe((containers: FaxConfigRestUserContainerList) => {
                this.nodes = _.map(containers, item => this.makeNode(item));
            });
        }
    }

    toggleNode(node: ITreeNode): void {
        if (node.expanded) {
            node.expanded = false;
        } else if (node.nodes === null) {
            this.faxSrv.dataSourceContainers(this.dataSource!.Name!, node.container.AdsPath).subscribe(containers => {
                node.nodes = _.map(containers, item => this.makeNode(item));
                node.expanded = true;
            });
        } else {
            node.expanded = true;
        }
    }

    selectNode(node: ITreeNode) {
        if (node !== this.selectedNode) {
            this.selectedNode = node;
            this.pagerUsers?.reset();
        }
    }

    nodeActionCssClass(node: ITreeNode): string {
        return node.nodes && node.nodes.length === 0 ? 'glyphicon-none' : // no children
            node.expanded ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right';
    }

    nodeTypeCssClass(node: ITreeNode): string {
        return node.container.Type === 'Site' ? 'glyphicon-globe' : (
            node.container.Type !== 'UserContainer' ? 'glyphicon-question-sign' :
            (node.nodes && node.nodes.length > 0 && node.expanded ? 'glyphicon-folder-open' : 'glyphicon-folder-close')
        );
    }

    isEditable(user: FaxConfigRestDbUser | FaxConfigRestUserResultItem) {
        // Access: 0x0100 = typeUser
        // Access: 0x0200 = typeGroup
        // Access: 0x0300 = typeCustomRecipient
        // State bit: 1 = flagHasNoAddress
        // State bit: 2 = flagRequiresAuth
        // State bit: 4 = flagSchemaNotExtended
        return (user.State! & 1) != 1 &&
            (user.Access != 0x0200 || (user.State! & 4) != 4);
    }

    onShowHiddenUsers(): void {
        this.pagerUsers?.refresh();
    }

    edit(user: FaxConfigRestDbUser | FaxConfigRestUserResultItem) {
        let addr: string;
        if (this.dataSource && this.dataSource.Name && this.isEditable(user)) {
            if (this.faxSrv.isDbProvider(this.dataSource.ProviderName)) {
                addr = (user as FaxConfigRestDbUser).AddressType + ':' + user.Address;
            } else {
                addr = (user as FaxConfigRestUserResultItem).Address!;
            }
            let editee: IUsersEditEditee = {
                dataSourceName: this.dataSource.Name,
                userId: addr,
                userName: user.Name
            };
            const dialogRef = this.dialog.open(UsersEditComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.refreshUsers(); }
            });
        }
    }

    neo(): void { // 'new' is a keyword
        if (this.dataSource && this.dataSource.Name) {
            let editee: IUsersEditEditee = {
                dataSourceName: this.dataSource.Name
            };
            const dialogRef = this.dialog.open(UsersEditComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.refreshUsers(); }
            });
        }
    }

    remove(): void {
        if (this.usersGridApi && this.dataSource && this.dataSource.Name) {
            let entries: { DataSource: string, Address: string }[] = [];
            this.usersGridApi.forEachNode(node => {
                if (node.isSelected() && node.data) {
                    entries.push({DataSource: this.dataSource!.Name!, Address: node.data.AddressType + ':' + node.data.Address});
                }
            });
            if (entries.length > 1) {
                if (!confirm("You have chosen to delete multiple users. This action cannot be undone! Are you sure you want to continue?")) {
                    return;
                }
            }
            let promises: Observable<FaxConfigRestResult>[] = [];
            entries.forEach(entry => {
                promises.push(this.faxSrv.deleteDataSourceUser(entry.DataSource, entry.Address))
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) {
                            this.removeEnabled = false;
                            this.refreshUsers();
                        }
                    },
                    error: err => alert(err.message)
                });
            }
        }
    }

    usersOnSelectionChanged(params: SelectionChangedEvent): void {
        let sel = params.api.getSelectedNodes();
        this.removeEnabled = sel? (sel.length > 0): false;
    }

    usersOnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger usersOnSelectionChanged:
        params.api.deselectAll();
    }

    usersOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger usersOnSelectionChanged:
        params.api.deselectAll();
    }

    refreshUsers(): void {
        if (this.faxSrv.isDbProvider(this.dataSource?.ProviderName)) {
            this.usersGridApi?.refreshServerSide({purge: true});
        } else {
            this.pagerUsers?.refresh();
        }
    }

    search(): void {
        if (this.dataSource?.Name && this.dataSource.ProviderName && this.formDataUsers.searchKeyUsers) {
            let path = '/users_search/' + encodeURIComponent(this.dataSource.Name) +
                                    '/' + encodeURIComponent(this.dataSource.ProviderName) +
                                    '/' + encodeURIComponent(this.formDataUsers.searchKeyUsers);
            if (this.formDataUsers.searchFilter) {
                path += '/' + encodeURIComponent(this.formDataUsers.searchFilter);
            } else {
                path += '/All';
            }
            this.router.navigateByUrl(path);
        }
    }

    /////////////////////////////////////////////////////
    // User Imports

    refreshVal: number = 30;
    ImportDescription: string = '';
    ImportFile: File | null = null;
    ImportPromise: Observable<string> | null = null;
    ImportEnabled: boolean = false;

    private dbQueryImports(offset: number, limit: number): Observable<FaxConfigRestUsersImportList> {
        if (this.dataSource && this.dataSource.Name) {
            return this.faxSrv.dataSourceUsersImports(this.dataSource.Name, offset, limit);
        }
        return of([]);
    }

    filechange(event: Event) {
        let target: HTMLInputElement = event.target as HTMLInputElement;
        // Enable the Import button only if the filename changed.
        this.ImportEnabled = false;
        if (target && target.files && target.files[0]) {
            if (target.files[0].name && this.ImportFile && this.ImportFile.name) {
                if (target.files[0].name.toUpperCase() === this.ImportFile.name.toUpperCase()) {
                    return;
                }
            }
            this.ImportEnabled = true;
            this.ImportDescription = '';
            this.ImportFile = target.files[0];
            this.ImportPromise = this.fileRead(this.ImportFile);
        }
    }

    private fileRead(file: File): Observable<string> {
        let subj = new AsyncSubject<string>();
        let reader = new FileReader();
        reader.onload = () => { subj.next(reader.result as string); };
        reader.onerror = () => { subj.next(reader.error?.message?? 'An unexpected error occurred.'); };
        reader.onabort = () => { subj.next(''); };
        reader.onloadend = () => { subj.complete(); };
        reader.readAsDataURL(file);
        return subj;
    }

    import(): void {
        if (this.ImportPromise) {
            this.ImportDescription = 'Uploading...';
            this.ImportEnabled = false;

            this.ImportPromise.subscribe(res => {
                let def = {
                    Owner: this.session.currentUser,
                    Filename: this.ImportFile!.name,
                    Base64data: res.split("base64,")[1]
                };
                this.faxSrv.PostDataSourceUsersImportDef(this.dataSource!.Name!, def).subscribe({
                    next: res => { this.afterImport(res); },
                    error: err => { alert(err.message); }
                });
            });
        }
    }

    afterImport(res: FaxConfigRestResultId): void {
        if (this.fenUtils.afterSave(res) > 0) {
            this.ImportDescription = 'Uploaded import job with id ' + res.Id;
            this.timerImports?.unsubscribe();
            this.refreshImports();
        }
    }

    initImports(): void {
        let num: number = 0;
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'UserImportRefreshInterval';
            let val: string | null = localStorage.getItem(key);
            if (val) {
                let min: number = this.settingsDataSourcesGeneral.RefreshMinimum || 5;
                let max: number = this.settingsDataSourcesGeneral.RefreshMaximum || 300;
                num = parseInt(val, 10);
                if (!Number.isInteger(num) || num < min || num > max) {
                    num = this.settingsDataSourcesGeneral.RefreshDefault || 30;
                }
            }
        }
        this.refreshVal = num || 30;
        this.formDataUsers.importRefreshEvery = this.refreshVal.toString(10);
    }

    refreshImports(): void {
        this.timerImports?.unsubscribe();
        this.pagerImports?.refresh();
        this.timerImports = timer(this.refreshVal * 1000).subscribe(() => this.refreshImports());
    }

    import_refresh_change(): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'UserImportRefreshInterval';
            let min: number = this.settingsDataSourcesGeneral.RefreshMinimum || 5;
            let max: number = this.settingsDataSourcesGeneral.RefreshMaximum || 300;
            let num: number = parseInt(this.formDataUsers.importRefreshEvery, 10);
            if (Number.isInteger(num) && num >= min) {
                this.timerImports?.unsubscribe();
                this.refreshVal = Math.min(num, max);
                localStorage.setItem(key, this.refreshVal.toString(10));
                this.timerImports = timer(this.refreshVal * 1000).subscribe(() => this.refreshImports());
            }
        }
    }

    showCsv(job: FaxConfigRestUsersImportItem): void {
        if (job.HasData && job.DataSource) {
            let token = this.auth.getAuthToken();
            if (token) {
                let url = this.session.portalCfg.prefix +
                       'DataSource/' + encodeURIComponent(job.DataSource) +
                    '/UsersImports/' + encodeURIComponent(job.ID) + '/csv' +
                           '?Token=' + encodeURIComponent(token);
                window.open(url, "csv");
            }
        }
    }

    showLog(job: FaxConfigRestUsersImportItem): void {
        if (job.HasLog && job.DataSource) {
            let token = this.auth.getAuthToken();
            if (token) {
                let url = this.session.portalCfg.prefix +
                    'DataSource/' + encodeURIComponent(job.DataSource) +
                 '/UsersImports/' + encodeURIComponent(job.ID) + '/log' +
                        '?Token=' + encodeURIComponent(token);
                window.open(url, "log");
            }
        }
    }

    userimport_config(): void {
        const dialogRef = this.dialog.open(UserimportConfigComponent, { data: { editee: this.settingsDataSourcesGeneral }});
        dialogRef.afterClosed().subscribe((result: FaxConfigRestDataSourcesGeneral) => {
            if (result) { this.settingsDataSourcesGeneral = result; }
        });
    }
}
