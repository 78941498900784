import { Injectable, inject, OnDestroy } from "@angular/core";
import { Observable, Subscription, AsyncSubject, forkJoin } from 'rxjs';
import { AuthService } from "./auth.service";
import { FaxConfigApi } from "../api/faxconfig"
import { FaxConfigRestConfigGeneral, FaxConfigRestOrganizationDef } from "../api/api";
import { PortalConfig, PortalConfigData } from "./portalconfig.service";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class Session implements OnDestroy {
    auth: AuthService = inject(AuthService);
    faxSrv: FaxConfigApi = inject(FaxConfigApi);
    portalCfg: PortalConfig = inject(PortalConfig);
    rootPromises: Observable<boolean>;
    private rootSubscription: Subscription;

    currentUser: string | null = null;
    currentUserOrgId: number | null = null;
    currentUserOrgName: string | null | undefined = undefined;
    currentOrgName: string | null | undefined = undefined;
    currentOrgId: number | null = null;
    prevOrgId: number | null = null;
    prevOrgName: string | null | undefined = undefined;
    configGeneral: FaxConfigRestConfigGeneral | null = null;
    messageTypes: string[] | null = null;
    licenseSetup: any; 

    servicesCurrentKernel?: string;
    addressesShowAddrType?: string;
    addressesShowOptions?: string;
    AddressRegistrationShowAddrType?: string;
    AddressRegistrationState?: string;

    constructor(http: HttpClient) {
        http.get<PortalConfigData>('config.json').subscribe(resp => {
            this.portalCfg.prefix = resp.prefix || this.portalCfg.prefix;
            this.portalCfg.items_per_page = resp.items_per_page || this.portalCfg.items_per_page;
            this.portalCfg.items_per_dialog = resp.items_per_dialog || this.portalCfg.items_per_dialog;
            this.portalCfg.log_date_format = resp.log_date_format || this.portalCfg.log_date_format;
            this.portalCfg.log_time_format = resp.log_time_format || this.portalCfg.log_time_format;

            if (this.portalCfg.prefix.substring(this.portalCfg.prefix.length - 1) !== '/') {
                this.portalCfg.prefix = this.portalCfg.prefix + '/'
            }

        })
        this.rootPromises = this.reload(true);
        this.rootSubscription = this.rootPromises.subscribe();
    }

    ngOnDestroy(): void {
        this.rootSubscription.unsubscribe();    // necessary?
    }

    clear() : void {
        this.currentUser = null;
        this.currentUserOrgId= null;
        this.currentUserOrgName= undefined;
        this.currentOrgId= null;
        this.currentOrgName= undefined;
        this.prevOrgId= null;
        this.prevOrgName=undefined;
        //this.addressesShowOptions;
        //this.addressesShowAddrType;
    
    }

    setOrganizationContext(orgId: number): void {
        if (orgId != this.currentOrgId) {
            let currentUserOrgId = this.auth.getUserOrgId();
            if (this.currentOrgId !== -1 && this.currentOrgId !== currentUserOrgId) {
                this.prevOrgId = this.currentOrgId;
                this.prevOrgName = this.currentOrgName;
            } else {
                this.prevOrgId = null;
                this.prevOrgName = undefined;
            }
            this.currentOrgId = orgId;
            this.currentOrgName = undefined;
        }
        if (orgId && !this.currentOrgName) {
            if (orgId === -1) {
                this.currentOrgName = 'All organizations';
            } else {
                this.faxSrv.GetOrganizationDefinition(orgId).subscribe(res => {
                    this.currentOrgName = res.Name;
                });
            }
        }
    }

    reload(force: boolean): Observable<boolean> {

        let subj = new AsyncSubject<boolean>();

        if (!this.auth.getAuthToken()) {
            // redirect /login
            subj.next(false);
            subj.complete();
        } else {

            let promises: {
                p1?: Observable<FaxConfigRestOrganizationDef>,
                p2?: Observable<FaxConfigRestOrganizationDef>,
                p3?: Observable<FaxConfigRestConfigGeneral>,
                p4?: Observable<string[]>
            } = { };

            this.currentUser = this.auth.getUserName();

            let currentUserOrgId = this.auth.getUserOrgId();
            if (currentUserOrgId != this.currentUserOrgId) {
                this.currentUserOrgId = currentUserOrgId;
                this.currentUserOrgName = undefined;
            }
            if (currentUserOrgId && !this.currentUserOrgName) {
                promises.p1 = this.faxSrv.GetOrganizationDefinition(currentUserOrgId);
            }

            let orgId = this.auth.getContextOrgId();
            if (orgId != this.currentOrgId) {
                if (this.currentOrgId !== -1 && this.currentOrgId !== currentUserOrgId) {
                    this.prevOrgId = this.currentOrgId;
                    this.prevOrgName = this.currentOrgName;
                } else {
                    this.prevOrgId = null;
                    this.prevOrgName = undefined;
                }
                this.currentOrgId = orgId;
                this.currentOrgName = undefined;
            }
            if (orgId && !this.currentOrgName) {
                if (orgId === -1) {
                    this.currentOrgName = 'All organizations';
                } else {
                    promises.p2 = this.faxSrv.GetOrganizationDefinition(orgId);
                }
            }

            if (!this.configGeneral || force) {
                promises.p3 = this.faxSrv.configGeneral();
            }
            if (!this.messageTypes || force) {
                promises.p4 = this.faxSrv.messageTypes();
            }

            forkJoin(promises)
                .subscribe({
                    next: (res) => {
                        if (res.p1) { this.currentUserOrgName = res.p1.Name; }
                        if (res.p2) { this.currentOrgName = res.p2.Name; }
                        if (res.p3) { this.configGeneral = res.p3; }
                        if (res.p4) { this.messageTypes = res.p4; }
                    },
                    complete: () => {
                        subj.next(true);
                        subj.complete();
                    },
                    error: (err) => {
                        alert(err.message);
                        subj.next(false);
                        subj.complete();
                    }
                });

            this.faxSrv.initDataSources(force);
        }

        return subj;
    }

    afterLogin() :void {
        // Reset any cached values that can heve invalid values due to expired login token
        this.configGeneral = null;
        this.licenseSetup = null;
        this.messageTypes = null;
        //this.faxSrv.initDataSources(true);
    }

    isMultiTenant = (): boolean => {
        return this.configGeneral?.MultiTenantEnabled || false;
    }

    usePhonenumberAdministration = (): boolean => {
        return this.configGeneral?.PhonenumberAdministrationEnabled || false;
    }

    hasMessageType = (msgType: string): boolean => {
        return this.messageTypes?.find((s) => s === msgType) !== undefined;
    }

    contextAllOrganizations = (): boolean => {
        return (this.isMultiTenant() && this.currentOrgId === -1);
    }
}  