import { Component, Inject, inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelMonitoringTemplate } from '../api/api';
import * as _ from 'underscore';

export interface IMonitoringTemplateScope {
    editee: FaxConfigRestKernelMonitoringTemplate;
    bIsNew: boolean;
}

@Component({
    selector: 'app-monitoring-templates',
    imports: [FormsModule, CommonModule],
    templateUrl: './monitoring-templates.component.html',
    styleUrl: './monitoring-templates.component.css'
})
export class MonitoringTemplatesComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    action: string;
    editee: FaxConfigRestKernelMonitoringTemplate;
    isModifiable: boolean;
    bIsNew: boolean;
    changed: boolean = false;

    sections: { [key:string]: boolean } = { // sectionName -> expanded:boolean
        'placeholders': false
    };

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: IMonitoringTemplateScope
    ) {
        this.editee = _.clone(data.editee);
        this.bIsNew = data.bIsNew;
        this.action = this.bIsNew? 'New': 'Edit';
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
    }

    validateTplName(): boolean {
        if (this.editee.Name && this.editee.Name.length > 4) {
            let nameLower = this.editee.Name.toLowerCase();
            if (!nameLower.endsWith('.tpl')) { return false; }
            // Check for invalid filename characters
            if (nameLower.indexOf('.')=== 0) { return false; }
            if (nameLower.indexOf('?') >= 0) { return false; }
            if (nameLower.indexOf('\\')>= 0) { return false; }
            if (nameLower.indexOf('/') >= 0) { return false; }
            if (nameLower.indexOf('*') >= 0) { return false; }
            if (nameLower.indexOf(':') >= 0) { return false; }
            if (nameLower.indexOf('<') >= 0) { return false; }
            if (nameLower.indexOf('>') >= 0) { return false; }
            if (nameLower.indexOf('|') >= 0) { return false; }
            if (nameLower.indexOf('"') >= 0) { return false; }
            return true;
        }
        return false;
    }

    submit(): void {
        this.dialogRef.close(true);
    }

    submitEnabled(): boolean {
        if (this.bIsNew) {
            return this.validateTplName();
        }
        return this.changed;
    }

    is_type(ruletype: string): boolean {
        return (this.editee.RuleType === ruletype
            || !this.editee.RuleType);
    }

    templateChange(): void {
        this.changed = true;
    }

    save(): void {
        this.faxSrv.updateMonitoringTemplate(this.editee).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    apply(): void {
        this.faxSrv.updateMonitoringTemplate(this.editee).subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.changed = false;
            }
        });
    }

    sectionActionCssClass(sectionName: string): string {
        return this.sections[sectionName] ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right';
    }

    toggleSection(sectionName: string): void {
        this.sections[sectionName] = !this.sections[sectionName];
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
