import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestResult, FaxConfigRestUserResultItem, FaxConfigRestUserResultList } from '../api/api';
import { DialogService } from '../dialog/dialog.service';
import { UsersEditComponent, IUsersEditEditee } from '../users-edit/users-edit.component';
import { PermissionSet } from '../constants/app.constants';
import * as _ from 'underscore';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, GridOptions, GetContextMenuItemsParams,
    CellDoubleClickedEvent, SelectionChangedEvent, MenuItemDef, FilterChangedEvent
} from 'ag-grid-community';

@Component({
    selector: 'app-users-search',
    imports: [FormsModule, CommonModule, AgGridAngular],
    templateUrl: './users-search.component.html',
    styleUrl: './users-search.component.css'
})
export class UsersSearchComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    private fenUtils: FenUtilsService = inject(FenUtilsService);

    isSearching: boolean = true;
    isModifiableUsers: boolean = false;
    keyword: string = '';
    dataSourceName: string = '';
    providerName: string = '';
    searchFilter: string = 'All';
    searchFilters: string[] = ['All','Names','Addresses','Routing codes','Charge codes'];
    newkeyword: string = '';
    removeEnabled: boolean = false;
    searchResults: FaxConfigRestUserResultList = [];

    gridApi?: GridApi;
    gridOptions?: GridOptions;
    isGridReady: boolean = false;
    cssGridHeight = { 'height': '300px' };
    gridInitialized: boolean = false;

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private route: ActivatedRoute) {
        this.session.rootPromises.subscribe(() => {
            this.isModifiableUsers = auth.isModifiable(PermissionSet.Users);
            this.route.params.subscribe(params => {
                this.dataSourceName = params['source']?? '';
                this.providerName = params['provider']?? '';
                this.keyword = params['keyword']?? '';
                this.searchFilter = params['filter']?? 'All';
                if (this.dataSourceName && this.providerName && this.keyword) {
                    this.init();
                } else {
                    // This should never happen! If it does then go back.
                    window.history.back();
                }
            });
        });
    }

    columnDefs: ColDef<any>[] = [
        {
            headerName: 'Organization',
            field: 'OrganizationName',
            hide: true,
            suppressColumnsToolPanel: true
        },
        {
            headerName: 'Name',
            field: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset']
            }
        },
        {
            headerName: 'Source',
            field: 'DataSource',
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                // DataSource cannot be blank, so omit 'blank' and 'notBlank' options
                filterOptions: ['contains','notContains','equals','notEqual','startsWith','endsWith']
            }
        },
        {
            headerName: 'Address',
            field: 'Address',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                // Address cannot be blank, so omit 'blank' and 'notBlank' options
                filterOptions: ['contains','notContains','equals','notEqual','startsWith','endsWith']
            }
        }
    ];

    private filterToFlags(filter: string): number {
        if (filter === 'Names') return 1;
        if (filter === 'Addresses') return 2;
        if (filter === 'Routing codes') return 4;
        if (filter === 'Charge codes') return 8;
        return 15;	// All
    }

    private init(): void {
        this.columnDefs[0].hide = !this.session.contextAllOrganizations();
        this.gridOptions = {
            columnDefs: this.columnDefs,
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                suppressFiltersToolPanel: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            rowModelType: 'clientSide',
            rowData: [],
            pagination: true,
            paginationAutoPageSize: true,
            rowSelection: 'multiple',
            suppressCsvExport: true,
            suppressExcelExport: true,
            onGridReady: params => {
                this.gridApi = params.api;
                let height = Math.max(300, window.innerHeight);
                this.cssGridHeight = { 'height': height + 'px' };
                this.loadViewState(params.api);
                this.isGridReady = true;
                this.gridRefresh();
            },
            onCellDoubleClicked: params => this.cbOnCellDoubleClicked(params),
            onFilterChanged: params => this.cbOnFilterChanged(params),
            onSelectionChanged: params => this.cbOnSelectionChanged(params),
            getContextMenuItems: params => this.cbGetContextMenuItems(params),
            onGridPreDestroyed: params => this.saveViewState(params.api)
        };
        this.gridInitialized = true;
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        let height = Math.max(300, innerHeight);
        this.cssGridHeight = { 'height': height + 'px' };
    }

    private loadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'UsersSearchViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex(item => { return !item.hide; });
                        if (idx >= 0) {
                            // Set the visibility of the Organizations column based on context (ignore saved state)
                            idx = val.findIndex(item => { return item.colId === "OrganizationName"; });
                            if (idx >= 0) {
                                val[idx].hide = !this.session.contextAllOrganizations();
                            }
                            api.applyColumnState({ state: val, applyOrder: true });
                            return;
                        }
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    private saveViewState(api: GridApi): void {
        if (this.isGridReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'UsersSearchViewState';
                let val = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    private isEditable(user: FaxConfigRestUserResultItem): boolean {
        // 1 = flagHasNoAddress
        // 2 = flagRequiresAuth
        // 4 = flagSchemaNotExtended
        return user.State !== 1 && user.State !== 5;
    }

    edit(user: FaxConfigRestUserResultItem): void {
        if (user.DataSource && this.isEditable(user)) {
            let editee: IUsersEditEditee = {
                dataSourceName: user.DataSource,
                userId: user.Address,
                userName: user.Name
            };
            const dialogRef = this.dialog.open(UsersEditComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.gridRefresh(); }
            });
        }
    }

    neo(): void { // 'new' is a keyword
        if (this.dataSourceName) {
            let editee: IUsersEditEditee = {
                dataSourceName: this.dataSourceName
            };
            const dialogRef = this.dialog.open(UsersEditComponent, { data: { editee: editee }});
            dialogRef.afterClosed().subscribe(modified => {
                if (modified) { this.gridRefresh(); }
            });
        }
    }

    remove(): void {
        if (this.gridApi && this.dataSourceName) {
            let entries: { DataSource: string, Address: string }[] = [];
            this.gridApi.forEachNode(node => {
                if (node.isSelected() && node.data) {
                    entries.push({DataSource: this.dataSourceName, Address: node.data.Address});
                }
            });
            if (entries.length > 1) {
                if (!confirm("You have chosen to delete multiple users. This action cannot be undone! Are you sure you want to continue?")) {
                    return;
                }
            }
            let promises: Observable<FaxConfigRestResult>[] = [];
            entries.forEach(entry => {
                promises.push(this.faxSrv.deleteDataSourceUser(entry.DataSource, entry.Address))
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) {
                            this.removeEnabled = false;
                            this.gridRefresh();
                        }
                    },
                    error: err => alert(err.message)
                });
            }
        }
    }

    private gridRefresh(clearFilters?: boolean): void {
        if (this.gridApi) {
            this.isSearching = true;
            let afterSearch = (res: FaxConfigRestUserResultList, err?: Error): void => {
                if (err) { alert(err.message); }
                this.searchResults = res;
                this.gridApi?.setGridOption('rowData', this.searchResults);
                if (clearFilters) { this.gridApi?.setFilterModel(null); }
                this.removeEnabled = false;
                this.isSearching = false;
            };
            let flags = this.filterToFlags(this.searchFilter);
            this.faxSrv.searchUsers(this.dataSourceName, this.keyword, flags).subscribe({
                next:  res => { afterSearch(res); },
                error: err => { afterSearch([], err); }
            });
        }
    }

    search(): void {
        if (!this.isSearching && this.newkeyword) {
            this.keyword = this.newkeyword;
            this.gridRefresh(true); // Clear all AG-Grid filters
        }
    }

    private cbGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let menuItems: (string | MenuItemDef)[] = ['copy','separator'];
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        const canEdit = this.isModifiableUsers;
        const canCreate = canEdit && !this.faxSrv.isLdapProvider(this.providerName);
        let sel = params.api.getSelectedNodes();
        menuItems.push({
            name: canEdit? 'Edit User...':'View User...',
            action: () => { this.cbOnCellDoubleClicked(sel[0]); },
            disabled: (!sel || sel.length != 1)
        });
        if (canCreate) {
            menuItems.push({
                name: 'New User...',
                action: () => { this.neo(); },
                disabled: this.session.contextAllOrganizations()
            });
        }
        if (canEdit){
            menuItems.push('separator');
            menuItems.push({
                name: 'Remove User(s)',
                action: () => { this.remove(); },
                disabled: (!sel || sel.length <= 0)
            });
        }
        menuItems.push('separator');
        menuItems.push({
            name: 'CSV Export',
            action: () => { this.export_search(); },
            disabled: (this.searchResults.length <= 0)
        });
        return menuItems;
    }

    private cbOnCellDoubleClicked(params: CellDoubleClickedEvent | IRowNode): void {
        if (params && params.data) {
            this.edit(params.data);
        }
    }

    private cbOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger cbOnSelectionChanged:
        params.api.deselectAll();
    }

    private cbOnSelectionChanged(params: SelectionChangedEvent): void {
        let sel = params.api.getSelectedNodes();
        this.removeEnabled = (sel && sel.length > 0);
    }

    export_search(): void {
        if (this.dataSourceName) {
            let lstUsers = _.pluck(this.searchResults, 'Address') as string[];
            if (lstUsers) {
                this.faxSrv.PostDataSourceUsersExport(this.dataSourceName, lstUsers).subscribe({
                    next: res => {
                        if (this.fenUtils.afterSave(res) > 0) {
                            let file = new Blob([ res.CSV! ], { type : 'text/csv' });
                            let link = document.getElementById("hidden_csv_link");
                            if (link) {
                                link.setAttribute("href", URL.createObjectURL(file));
                                link.click();
                            }
                        }
                    },
                    error: err => { alert(err.message); }
                });
            }
        }
    }
}
