<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Log Preferences</h1>
    </div>
    <form id="logformat_config_form" class="form" novalidate (submit)="save()">
        <div class="modal-body">
            <p>Choose the way the date and time fields are displayed when viewing the log records.</p>

            <div class="field-indented">
                <p class="alert-info">Any text within single quotes (like 'text') is ignored in the format strings.</p>
            </div>

            <div class="field">
                <label class="field-indented">Date Format:</label>
                <app-combobox name="DateFormat" [model]="editee.dateFormat" (modelChange)="onDateFormatChanged($event)" [options]="dateFormats" [required]="true"
                title="Enter the date format, for example 'dd/MM/yyyy'. The format is case-sensitive.">
                </app-combobox>
            </div>
            <div class="field">
                <label class="field-indented">Time Format:</label>
                <app-combobox name="TimeFormat" [model]="editee.timeFormat" (modelChange)="onTimeFormatChanged($event)" [options]="timeFormats" [required]="true"
                title="Enter the time format, for example 'hh:mm:ss a'. The format is case-sensitive.">
                </app-combobox>
            </div>

            <p class="well"><strong>Preview:</strong><br>{{ currentDateTime | date:editee.dateFormat }}&nbsp;{{ currentDateTime | date:editee.timeFormat }}</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!submitEnabled()">Save</button>
            <button type="button" class="btn" (click)="close()">Close</button>
        </div>
    </form>
</section>
