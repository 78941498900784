<div class="combobox">
    <input type="text" [(ngModel)]="model" (ngModelChange)="onChange()" (change)="clickout()" [disabled]="disabled" [required]="required"/>
    @if (!disabled) {
        <span (click)="toggleExpanded($event)"><i class="glyphicon glyphicon-chevron-down"></i></span>
    }
    <ul class="list" [class.hide]="!expanded">
        @for(opt of options; track $index) {
            <li (click)="onSelect($event, opt)">{{ opt }}</li>
        }
    </ul>
</div>
