import { CommonModule, NgFor, DatePipe } from '@angular/common';
import { Observable, AsyncSubject, forkJoin, of } from 'rxjs';
import { Component, inject, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Session } from '../services/session.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FormsModule } from '@angular/forms';
import { FaxConfigApi } from '../api/faxconfig';
import {
    FenRestResult, FaxConfigRestResult, FaxConfigRestAddressList, FaxConfigRestAddressInfo,
    FaxConfigRestConnectionInfoList, FaxConfigRestConnectionInfo, FaxConfigRestUserRouteTypeList,
    FaxConfigRestAddressRegistrationList, FaxConfigRestAddressRegistrationInfo, FaxConfigRestConfigGeneral
} from '../api/api';
import * as _ from 'underscore';

import { AddressesSetupComponent } from '../addresses-setup/addresses-setup.component';
import { AddressAdminEnableComponent } from '../address-admin-enable/address-admin-enable.component';
import { AddressesBatchEditComponent } from '../addresses-batch-edit/addresses-batch-edit.component';
import { AddressesBatchUploadComponent } from '../addresses-batch-upload/addresses-batch-upload.component';
import { AddressRegistrationEditComponent } from '../address-registration-edit/address-registration-edit.component';
import { AddressRegistrationUploadComponent } from '../address-registration-upload/address-registration-upload.component';
import { DialogService } from '../dialog/dialog.service';

import { AgGridAngular } from 'ag-grid-angular';
import {
    GridApi, ColDef, ColumnState, IRowNode, GridOptions, GridReadyEvent, GetContextMenuItemsParams,
    RowDoubleClickedEvent, CellDoubleClickedEvent, SelectionChangedEvent, GetRowIdParams,
    MenuItemDef, PaginationChangedEvent, ValueFormatterParams, CsvExportParams,
    IServerSideDatasource, IServerSideGetRowsParams, ITooltipParams, ICellRendererParams,
    AdvancedFilterModel, FilterChangedEvent
} from 'ag-grid-community';
import { SortModelItem } from 'ag-grid-enterprise';

interface IPageTab {
    Caption: string,
    showTab: boolean,
    isReady: boolean,
    dataLoader: () => void
}

interface ITabCtrl {
    activePageTab: IPageTab | null,
    pageTabs: IPageTab[]
}

@Component({
    imports: [FormsModule, CommonModule, NgFor, AgGridAngular],
    providers: [DatePipe],
    templateUrl: './addresses.component.html',
    styleUrl: './addresses.component.css'
})
export class AddressesComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isReady: boolean = false;
    addrListGridReady: boolean = false;
    addrListGridInitialized: boolean = false;
    loadingAddressList: boolean = false;
    addrRegGridReady: boolean = false;
    addrRegGridInitialized: boolean = false;
    dateFormat: string = 'yyyy-MM-dd';

    addrListAddEnabled: boolean = false;
    addrListEditEnabled: boolean = false;
    addrListRemoveEnabled: boolean = false;
    addrListAttachEnabled: boolean = false;
    addrListDetachEnabled: boolean = false;
    addrListDefaultEnabled: boolean = false;
    addrListExportEnabled: boolean = false;

    addressTypes: string[] = [];

    addrListGridHeight = { 'height': '300px' };
    addrRegGridHeight  = { 'height': '300px' };

    constructor (public auth: AuthService, public session: Session, private dialog: DialogService, private datePipe: DatePipe) {
        this.session.rootPromises.subscribe(res => this.init());
    }

    private init(): void {
        let promises: Observable<any>[] = [];
        this.addrListOnSelectionChanged(null);  // initialize buttons

        this.formDataAddresses.showAddrType = this.session.addressesShowAddrType?? 'FAX';
        if (!this.auth.isViewable('Organizations')) {
            this.formDataAddresses.showOptions  = '1';
        } else {
            this.formDataAddresses.showOptions = this.session.addressesShowOptions?? '1';
        }
    
        if (this.session.usePhonenumberAdministration()) {
            this.tabCtrl.pageTabs[0].showTab = this.auth.isViewable('Addresses') ;
            this.tabCtrl.pageTabs[1].showTab = this.auth.isViewable('AddressRegistration');

            let visible = this.tabCtrl.pageTabs.filter((item: IPageTab) => { return item.showTab; });
            this.tabCtrl.activePageTab = (visible.length > 0) ? visible[0] : null;

            promises.push(of(this.refresh_connections()));
            promises.push(of(this.refresh_addressTypes()));
            visible.forEach((item: IPageTab) => { promises.push(of(item.dataLoader())); });

            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'AddressTabPage';
                let json: string | null = localStorage.getItem(key);
                if (json) {
                    try {
                        let val: string = JSON.parse(json) as string;
                        if (val) {
                            this.tabCtrl.pageTabs.forEach((tab: IPageTab) => {
                                if (tab.Caption === val) { this.setActivePageTab(tab); }
                            });
                        }
                    } catch (err) {
                        // Invalid JSON; ignore it.
                    }
                }
            }
        }

        if (promises.length > 0) {
            forkJoin(promises)
                .subscribe({
                    next:  (res) => {
                        this.isReady = true;
                    },
                    error: (err) => {
                        this.isReady = true;
                    }
                });
        } else {
            this.isReady = true;
        }
    }

    addressTooltipValueGetter(params: ITooltipParams): string {
        let val = params.data? params.data.Address: '';
        if (val) {
            return params.data.Preferred? (val + ' (Default)'): val;
        }
        return '';
    }

    addressCellRenderer(params: ICellRendererParams): string {
        let val = params.data? params.data.Address: '';
        if (val) {
            var span = document.createElement('span');
            if (params.data.Preferred) {
                span.innerHTML = '<b>' + val + '</b> (Default)';
            } else {
                span.innerHTML = val;
            }
            return span.outerHTML;
        }
        return '';
    }

    attachedCellRenderer(params: ICellRendererParams): string {
        let img = document.createElement("img");
        let val = params.data? params.data.OrganizationName: false;
        img.src = val? 'icon-checkmark.png': 'icon-cross.png';
        return img.outerHTML;
    }

    usedCellRenderer(params: ICellRendererParams): string {
        let img = document.createElement("img");
        let val = params.data? params.data.UserNames: false;
        img.src = val? 'icon-checkmark.png': 'icon-cross.png';
        return img.outerHTML;
    }

    createdValueGetter(params: ITooltipParams): string {
        return params.data? this.datePipe.transform(params.data.CreatedDate, this.dateFormat)?? '': '';
    }

    addrListGridColDefs: ColDef<any>[] = [
        {
            field: 'Address',
            tooltipValueGetter: params => this.addressTooltipValueGetter(params),
            headerName: 'Number',
            headerTooltip: 'Number',
            sortable: true,
            suppressFiltersToolPanel: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            },
            cellRenderer: (params: ICellRendererParams) => this.addressCellRenderer(params),
        },
        {
            field: 'AddrType',
            tooltipField: 'AddrType',
            headerName: 'Address Type',
            headerTooltip: 'Address Type',
            hide: true
        },
        {
            field: 'Preferred',
            tooltipField: 'Preferred',
            headerTooltip: 'Preferred',
            hide: true
        },
        {
            field: 'OrganizationName',
            tooltipField: 'OrganizationName',
            headerName: 'Organization',
            headerTooltip: 'Organization',
        },
        {
            field: 'Attached',
            headerTooltip: 'Attached',
            cellRenderer: (params: ICellRendererParams) => this.attachedCellRenderer(params)
        },
        {
            field: 'UserNames',
            tooltipField: 'UserNames',
            headerName: 'Users',
            headerTooltip: 'Users'
        },
        {
            field: 'Used',
            headerTooltip: 'Used',
            cellRenderer: (params: ICellRendererParams) => this.usedCellRenderer(params)
        },
        {
            field: 'ConnectionName',
            tooltipField: 'ConnectionName',
            headerName: 'Connection',
            headerTooltip: 'Connection',
            sortable: true,
            suppressFiltersToolPanel: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'Description',
            tooltipField: 'Description',
            headerTooltip: 'Description',
            sortable: true,
            suppressFiltersToolPanel: false,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['apply','reset','cancel'],
                filterOptions: ['contains','notContains','equals','notEqual'],
                maxNumConditions: 1,
                closeOnApply: true
            }
        },
        {
            field: 'Created',
            tooltipValueGetter: params => this.createdValueGetter(params),
            headerTooltip: 'Created',
            sortable: true,
            valueGetter: (params: any) => this.createdValueGetter(params)
        }
    ];

    gridOptions: {
        addressList?: GridOptions,
        addrListGridApi?: GridApi,
        addressRegistration?: GridOptions,
        addrRegGridApi?: GridApi
    } = {};

    tabCtrl: ITabCtrl = {
        activePageTab: null,
        pageTabs: [
            { Caption: 'Registered', showTab: false, isReady: true, dataLoader: () => this.init_addressList() },
            { Caption: 'Requests',   showTab: false, isReady: true, dataLoader: () => this.init_addressregistrations() }
        ]
    };

    visiblePages(ctrl: ITabCtrl): number {
        return ctrl.pageTabs.filter((item: IPageTab) => { return item.showTab; }).length;
    }

    formDataAddresses = {
        searchKeyAddresses: '',
        showAddrType: 'FAX',
        showOptions: '1'
    }

    connections: FaxConfigRestConnectionInfoList = [];
    azureConfig = {};

    //{{ Tab controller logic
    isTabVisible(tab: IPageTab): boolean {
        return tab.showTab || false;
    }

    visibleTabs(tabs: IPageTab[]): IPageTab[] {
        return tabs.filter(tab => this.isTabVisible(tab));
    }

    setActivePageTab(pageTab: IPageTab | null): void {
        this.tabCtrl.activePageTab = pageTab;
        if (this.isReady && pageTab) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'AddressTabPage';
                localStorage.setItem(key, JSON.stringify(pageTab.Caption));
            }
        }
    };
    //}} Tab controller logic

    private forceColumn(field: string, show: boolean): void {
        let col = _.find(this.addrListGridColDefs, function(item) { return item.field === field; });
        if (col) { col.hide = !show; }
    }

    init_addressList(): void {
        this.gridOptions.addressList = {
            columnDefs: [], // wait until initPromise is resolved
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: false,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                },
                suppressFiltersToolPanel: true,
                suppressColumnsToolPanel: true,
                menuTabs: ['generalMenuTab','filterMenuTab']
            },
            defaultCsvExportParams: { allColumns: true, fileName: 'export_addresses.csv' },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'multiple',
            suppressCsvExport: false,
            suppressExcelExport: true,
            suppressMultiSort: false,
            getRowId: params => this.addrListGetRowId(params),
            onGridReady: params => this.addrListOnGridReady(params),
            onGridPreDestroyed: params =>this.addrListSaveViewState(params.api),
            onRowDoubleClicked: params => this.addrListOnRowDoubleClicked(params),
            onSelectionChanged: params => this.addrListOnSelectionChanged(params.api),
            onPaginationChanged: params => this.addrListOnPaginationChanged(params),
            onFilterChanged: params => this.addrListOnFilterChanged(params),
            getContextMenuItems: params => this.addrListGetContextMenuItems(params)
        };
        this.addrListGridInitialized = true;
    }

    private addrListOnGridReady(params: GridReadyEvent): void {
        this.gridOptions.addrListGridApi = params.api;
        this.addrListGridHeight = { 'height': Math.max(300, window.innerHeight - 100) + 'px' };
        params.api.setGridOption('serverSideDatasource', this.addrListDataSource);
        // Show/hide various columns based on context.
        this.forceColumn('OrganizationName', this.session.isMultiTenant() && this.auth.isViewable('Organizations') && this.session.contextAllOrganizations());
        this.forceColumn('Attached', this.session.isMultiTenant() && this.auth.isViewable('Organizations') && !this.session.contextAllOrganizations());
        this.forceColumn('Used', this.session.contextAllOrganizations());
        this.forceColumn('UserNames', !this.session.contextAllOrganizations());
        this.forceColumn('ConnectionName', !this.session.isMultiTenant() || this.auth.isViewable('Organizations'));
        this.forceColumn('Description', !this.session.isMultiTenant() || this.auth.isViewable('Organizations'));
        this.forceColumn('Created', !this.session.isMultiTenant() || this.auth.isViewable('Organizations'));
        params.api.setGridOption('columnDefs', this.addrListGridColDefs);
        this.addrListLoadViewState(params.api);
        this.addrListGridReady = true;
    }

    addrListLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'AddrListViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Ignore the 'hide' property; column visibility is based on context.
                        val = _.map(val, item => { return _.omit(item, 'hide'); });
                        api.applyColumnState({ state: val, applyOrder: true });
                        return;
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    addrListSaveViewState(api: GridApi): void {
        if (this.addrListGridReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'AddrListViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    formattedConnectionName(id?: number | null, conn?: FaxConfigRestConnectionInfo): string | null | undefined {
        return id ?
                conn? conn.OrganizationName && this.session.isMultiTenant()
                    ? conn.ConnectionName + ' (' + conn.OrganizationName + ')'
                    : conn.ConnectionName : id.toString(10)
                : '';
    }

    private addrListGetRows(params: IServerSideGetRowsParams): Observable<FaxConfigRestAddressList> {
        let subj = new AsyncSubject<FaxConfigRestAddressList>();
        let successData;

        if (!this.session.usePhonenumberAdministration()) {
            successData = { rowData: [], rowCount: 0 };
            params.success(successData);
            subj.next([]);
            subj.complete();
            return subj;
        }
        this.loadingAddressList = true;

        let count = params.request.endRow! - params.request.startRow!;
        let filters: {}[] = [];
        let orderby: string[] = [];

        let filter: any = {
            AddrType: this.formDataAddresses.showAddrType,
            IncludeAttached: this.session.isMultiTenant() && (this.formDataAddresses.showOptions !== '2'),
            IncludeUnattached: this.session.isMultiTenant() && (this.formDataAddresses.showOptions === '2' || this.formDataAddresses.showOptions === '3')
        };

        if (params.request.filterModel) {
            _.each(params.request.filterModel, (model: AdvancedFilterModel, key: string) => {
                if (model.filterType === 'text' && model.filter) {
                    filters.push({ field: key+'_'+model.type, value: model.filter });
                }
            });
            if (filters.length > 0) { filter.Filters = filters; }
        }

        _.each(params.request.sortModel, (item: SortModelItem) => {
            orderby.push(item.colId+'_'+item.sort);
        });
        if (orderby.length > 0) { filter.OrderBy = orderby; }

        if (this.formDataAddresses.showOptions === '4') {
            filter.AddrFilter = 'Used';
        } else if (this.formDataAddresses.showOptions === '5') {
            filter.AddrFilter = 'Unused';
        }

        this.faxSrv.GetAddresses(params.request.startRow!, count + 1, filter)
        .subscribe({
            next: (res: FaxConfigRestAddressList) => {
                _.each(res, (item: FaxConfigRestAddressInfo) => {
                    var p = _.find(this.connections, function(p) { return p.ID === item.ConnectionId; });
                    item.ConnectionName = this.formattedConnectionName(item.ConnectionId, p) ;
                });
                if (res.length <= count) {
                    successData = {
                        rowData: res,
                        rowCount: params.request.startRow! + res.length
                    };
                } else {
                    res.pop();  // There is at least 1 record for the next page
                    successData = { rowData: res, rowCount: -1 };
                }
                this.addrListExportEnabled = (res.length > 0);
                this.loadingAddressList = false;
                params.success(successData);
                this.addrListOnSelectionChanged(params.api);
                subj.next(res);
                subj.complete();
            },
            error: (err) => {
                this.addrListExportEnabled = false;
                this.loadingAddressList = false;
                params.fail();
                subj.next([]);
                subj.complete();
            }
        });

        return subj;
    }

    addrListDataSource: IServerSideDatasource = {
        getRows: params => { 
            this.addrListGetRows(params).subscribe();
        }
    };

    private addrListGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.node && !params.node.isSelected()) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        this.addrListOnSelectionChanged(params.api);

        var items = [];
        if (this.addrListAddEnabled) {
            // Only a Global Administrator can add/edit/remove
            items.push('separator',
            {
                name: 'Add numbers...',
                action: () => this.new_addresses(),
                disabled: false
            },
            {
                name: 'Edit numbers...',
                action: () => this.edit_addresses(),
                disabled: !this.addrListEditEnabled
            },
            {
                name: 'Remove numbers...',
                action: () => this.delete_addresses(),
                disabled: !this.addrListRemoveEnabled
            },
            'separator',
            {
                name: 'Upload numbers...',
                action: () => this.upload_addresses(),
                disabled: false
            });
        }

        if (!this.session.isMultiTenant() || this.auth.isViewable('Organizations')) {
            items.splice(0, 0, 'copy');
            items.push('separator',
            {
                name: 'CSV Export',
                action: () => this.export(),
                disabled: !this.addrListExportEnabled
            });
        }
        return items;
    }

    refresh_connections(): void {
        this.faxSrv.GetConnections({ IncludeGlobal: this.auth.isViewable('Addresses') })
            .subscribe((res: FaxConfigRestConnectionInfoList | FenRestResult) => {
                if (_.isArray(res)) {
                    this.connections = (res as FaxConfigRestConnectionInfoList)?? [];
                } else {
                    this.connections = [];
                }
            });
    }

    refresh_addressTypes(): void {
        this.faxSrv.userRouteTypes()
            .subscribe((res: FaxConfigRestUserRouteTypeList) => {
                this.addressTypes = _.intersection(res, ['FAX', 'DIR', 'SMS']);
            });
    }
        
    refresh_addresses(): void {
        this.gridOptions.addrListGridApi?.refreshServerSide({purge: true});
    }

    private addrListGetRowId(params: GetRowIdParams): string {
        return params.data.ID!.toString(10);
    }

    private addrListOnPaginationChanged(params: PaginationChangedEvent): void {
        // This will trigger addrListOnSelectionChanged:
        if (params.newPage) params.api.deselectAll();
    }

    private addrListOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger addrListOnSelectionChanged:
        params.api.deselectAll();
    }

    private addrListOnSelectionChanged(api: GridApi | null): void {
        if (this.auth.isModifiable('Addresses')) {
            let attached = 0, detached = 0;
            let sel = api? api.getSelectedNodes()?? []: [];

            if (this.auth.isModifiable('All')) {
                this.addrListAddEnabled = true;
                this.addrListEditEnabled = sel.length > 0;
                this.addrListRemoveEnabled = sel.length > 0;
            } else {
                this.addrListAddEnabled = false;
                this.addrListEditEnabled = false;
                this.addrListRemoveEnabled = false;
            }

            _.each(sel, (item: IRowNode) => {
                if (item.data.OrganizationName) { attached++; } else { detached++; }
            });
            this.addrListAttachEnabled = detached > 0 && !this.session.contextAllOrganizations();
            this.addrListDetachEnabled = attached > 0;

            if (!this.session.contextAllOrganizations() && sel.length === 1 && sel[0].data.OrganizationName !== '') {
                this.addrListDefaultEnabled = !sel[0].data.Preferred;
            } else {
                this.addrListDefaultEnabled = false;
            }
        } else {
            this.addrListAddEnabled = false;
            this.addrListEditEnabled = false;
            this.addrListRemoveEnabled = false;
            this.addrListAttachEnabled = false;
            this.addrListDetachEnabled = false;
            this.addrListDefaultEnabled = false;
        }
    }

    addrListCsvSource: IServerSideDatasource = {
        getRows: params => {
            this.addrListGetRows(params).subscribe(res => {
                if (res && res.length > 0) {
                    // Perform the export to CSV.
                    let export_params: CsvExportParams = {
                        columnKeys: _.pluck(this.addrListGridColDefs, 'field') as string[],
                        fileName: 'export_addresses.csv'
                    };
                    var omit = [ 'Attached', 'Used' ];  // skip these columns
                    if (!this.session.isMultiTenant()) omit.push('OrganizationName');
                    export_params.columnKeys = _.difference(export_params.columnKeys as string[], omit);
                    params.api.exportDataAsCsv(export_params);
                }
                // Restore the old block size after CSV export.
                params.api.setGridOption('cacheBlockSize', this.session.portalCfg.items_per_page);
                params.api.setGridOption('serverSideDatasource', this.addrListDataSource);
            });
        }
    };

    export(): void {
        if (this.gridOptions.addrListGridApi) {
            // Temporarily increase the block size to export all rows on all pages.
            this.gridOptions.addrListGridApi.setGridOption('cacheBlockSize', 1000000);
            this.gridOptions.addrListGridApi.setGridOption('serverSideDatasource', this.addrListCsvSource);
        }
    }

    search_addresses(): void {
        this.session.addressesShowAddrType = this.formDataAddresses.showAddrType;  // cache the value
        this.session.addressesShowOptions = this.formDataAddresses.showOptions;
        // rewind to first page of results
        this.gridOptions.addrListGridApi?.refreshServerSide({ purge: true});
        // Clear all selections (this will trigger addrListOnSelectionChanged)
        this.gridOptions.addrListGridApi?.deselectAll();
    }

    new_addresses(): void {
        let def = {
            AddrType: this.formDataAddresses.showAddrType,
            AddressStart: '',
            AddressEnd: '',
            ConnectionId: -1,
            Description: ''
        }
        const dialogRef = this.dialog.open(AddressesBatchEditComponent, {
            data: {
                mode: 'add',
                editee: def,
                Connections: this.connections,
                formattedConnectionName: this.formattedConnectionName
            }
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_addresses(); }
        });
    }

    upload_addresses(): void {
        let def = {
            ConnectionId: -1,
            Description: ''
        };
        const dialogRef = this.dialog.open(AddressesBatchUploadComponent, {
            data: {
                editee: def,
                Connections: this.connections
            }
        });
        dialogRef.afterClosed().subscribe((success: boolean) => {
            if (success) {
                if (this.session.isMultiTenant() && this.formDataAddresses.showOptions == '1') {
                    // switch from 'show attached' to 'show all'
                    this.formDataAddresses.showOptions = '3';
                    this.session.addressesShowOptions = '3';
                }
                this.refresh_addresses();
            }
        });
    }

    private addrListEditAddresses(nodes: IRowNode[]): void {
        let def = {
            AddrType: this.formDataAddresses.showAddrType,
            AddressStart: '',
            AddressEnd: '',
            ConnectionId: 0,
            Description: ''
        };
        _.each(nodes, (item: IRowNode) => {
            if (!def.AddressStart || def.AddressStart > item.data.Address) {
                def.AddressStart = item.data.Address;
                def.ConnectionId = item.data.ConnectionId? item.data.ConnectionId: -1;
                def.Description  = item.data.Description;
            }
            if (!def.AddressEnd || def.AddressEnd < item.data.Address) {
                def.AddressEnd = item.data.Address;
            }
        });
        const dialogRef = this.dialog.open(AddressesBatchEditComponent, {
            data: {
                mode: 'edit',
                editee: def,
                Connections: this.connections,
                formattedConnectionName: this.formattedConnectionName
            }
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_addresses(); }
        });
    }

    private addrListOnRowDoubleClicked(params: RowDoubleClickedEvent): void {
        if (this.addrListEditEnabled) {
            this.addrListEditAddresses([params.node]);
        }
    }

    edit_addresses(): void {
        let nodes = this.gridOptions.addrListGridApi?.getSelectedNodes();
        if (nodes && nodes.length > 0) this.addrListEditAddresses(nodes);
    }

    private addrListRemoveAddresses(nodes: IRowNode[]): void {
        let def = {
            AddrType: this.formDataAddresses.showAddrType,
            AddressStart: '',
            AddressEnd: ''
        };
        _.each(nodes, (item: IRowNode) => {
            if (!def.AddressStart || def.AddressStart > item.data.Address) {
                def.AddressStart = item.data.Address;
            }
            if (!def.AddressEnd || def.AddressEnd < item.data.Address) {
                def.AddressEnd = item.data.Address;
            }
        });
        const dialogRef = this.dialog.open(AddressesBatchEditComponent, {
            data: {
                mode: 'del',
                editee: def,
                Connections: []
            }
        });
        dialogRef.afterClosed().subscribe((res: boolean) => {
            if (res) {
                this.gridOptions.addrListGridApi?.deselectAll();
                this.refresh_addresses();
            }
        });
    }

    delete_addresses(): void {
        let nodes = this.gridOptions.addrListGridApi?.getSelectedNodes();
        if (nodes && nodes.length > 0) this.addrListRemoveAddresses(nodes);
    }

    private addrListAttachAddresses(nodes: IRowNode[]): void {
        let def = {
            AddrType: this.formDataAddresses.showAddrType,
            AddressStart: '',
            AddressEnd: '',
        };
        _.each(nodes, (item: IRowNode) => {
            if (!def.AddressStart || def.AddressStart > item.data.Address) {
                def.AddressStart = item.data.Address;
            }
            if (!def.AddressEnd || def.AddressEnd < item.data.Address) {
                def.AddressEnd = item.data.Address;
            }
        });
        const dialogRef = this.dialog.open(AddressesBatchEditComponent, {
            data: {
                mode: 'attach',
                editee: def,
                Connections: []
            }
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_addresses(); }
        });
    }

    attach_addresses(): void {
        let nodes = this.gridOptions.addrListGridApi?.getSelectedNodes();
        if (nodes && nodes.length > 0) this.addrListAttachAddresses(nodes);
    }

    private addrListDetachAddresses(nodes: IRowNode[]): void {
        let def = {
            AddrType: this.formDataAddresses.showAddrType,
            AddressStart: '',
            AddressEnd: '',
        };
        _.each(nodes, (item: IRowNode) => {
            if (!def.AddressStart || def.AddressStart > item.data.Address) {
                def.AddressStart = item.data.Address;
            }
            if (!def.AddressEnd || def.AddressEnd < item.data.Address) {
                def.AddressEnd = item.data.Address;
            }
        });
        const dialogRef = this.dialog.open(AddressesBatchEditComponent, {
            data: {
                mode: 'detach',
                editee: def,
                Connections: []
            }
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_addresses(); }
        });
    }

    detach_addresses(): void {
        let nodes = this.gridOptions.addrListGridApi?.getSelectedNodes();
        if (nodes && nodes.length > 0) this.addrListDetachAddresses(nodes);
    }

    default_address(): void {
        if (this.gridOptions.addrListGridApi) {
            let selection = this.gridOptions.addrListGridApi.getSelectedNodes();
            if (selection?.length === 1 && !this.session.contextAllOrganizations()) {
                let def = {
                    OrganizationId: (selection[0].data.OrganizationId == 0)? this.auth.getContextOrgId() : null,
                    Enabled: true,
                    Preferred: true
                };
                this.faxSrv.PutAddressesDefinition(selection[0].data.ID, def).subscribe(res => {
                    if (this.fenUtils.afterSave(res) > 0) { this.refresh_addresses(); }
                });
            }
        }
    }

    setup_addresses(): void {
        const dialogRef = this.dialog.open(AddressesSetupComponent, {
            data: {}
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) {
                this.refresh_connections();
                this.refresh_addresses();
            }
        });
    }

    /* Enabling Phone number Administration */
    enablePhonenumberAdministration(): void {
        const pThis = this;
        this.dialog.open(AddressAdminEnableComponent, {data: {
            onEnable: (): void => {
                pThis.faxSrv.updateConfigGeneral(true).subscribe((res: FaxConfigRestResult) => {
                    if (pThis.fenUtils.afterSave(res) > 0) {
                        pThis.isReady = false;
                        pThis.faxSrv.configGeneral().subscribe((res: FaxConfigRestConfigGeneral) => {
                            pThis.session.configGeneral = res;
                            pThis.init();
                        });
                    }
                });
            }
        }});
    }

    /* Phone number registration */

    error_list: { [key: number]: string } = {
        0: 'No error',
        50001: 'The organization was disabled',
        50002: 'The connection was disabled',
        50003: 'The call arrived via a different connection',
        50004: 'The address could not be activated, probably due to duplicate addresses',
        50005: 'The user was not found',
        50006: 'Assigning number to user failed'
    };
    ownedConnections: FaxConfigRestConnectionInfoList = [];

    formDataAddressRegistration = {
        searchKeyAddresses: '',
        showAddrType: 'FAX',
        state: 'Pending'
    };

    addrRegRemoveEnabled: boolean = false;
    loadingAddressRegistrations: boolean = false;

    disableAdd(): boolean {
        return this.session.contextAllOrganizations() || this.ownedConnections.length == 0;
    };

    disableBtnEnable(): boolean {
        let count = 0;
        if (this.gridOptions.addrRegGridApi) {
            this.gridOptions.addrRegGridApi.forEachNode(node => {
                if (node.isSelected() && node.data.Enabled === false && !node.data.ReadOnly && this.ownedConnections.find((conn) => {return node.data.ConnectionId === conn.ID} ) !== undefined) {
                    count++;
                }
            });
        }
        return count == 0;
    };

    disableBtnDisable(): boolean {
        let count = 0;
        if (this.gridOptions.addrRegGridApi) {
            this.gridOptions.addrRegGridApi.forEachNode(node => {
                if (node.isSelected() && node.data.Enabled === true && !node.data.ReadOnly && this.ownedConnections.find((conn) => {return node.data.ConnectionId === conn.ID} ) !== undefined) {
                    count++;
                }
            });
        }
        return count == 0;
    };

    addrRegGridColDefs: ColDef<any>[] = [
        {
            headerName: 'Organization',
            field: 'OrganizationName',
            tooltipField: 'OrganizationName',
            hide: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
        },
        {
            headerName: 'Enabled',
            field: 'Enabled',
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value? 'Yes': 'No';
            },
            sortable: false
        },
        {
            field: 'Address',
            tooltipField: 'Address'
        },
        {
            field: 'Attempts'
        },
        {
            headerName: 'State',
            field: 'State',
            sortable: false
        },
        {
            headerName: 'User',
            field: 'UserAddress',
            tooltipField: 'UserAddress'
        },
        {
            headerName: 'Connection',
            field: 'ConnectionName',
            tooltipField: 'ConnectionName'
        },
        {
            field: 'Description',
            tooltipField: 'Description',
            suppressFiltersToolPanel: true,
            hide: true
        },
        {
            headerName: 'Error',
            field: 'ErrorCode',
            valueFormatter: (params: ValueFormatterParams) => {
                let err = params.value as number;
                return this.error_list[err] !== undefined? this.error_list[err]: ('Unknown error ' + err);
            },
            sortable: false
        }
    ];

    search_registrations(): void {
        this.session.AddressRegistrationShowAddrType = this.formDataAddressRegistration.showAddrType;  // cache the value
        this.session.AddressRegistrationState = this.formDataAddressRegistration.state;
        this.refresh_addressregistrations();
        // Clear all selections (this will trigger addrRegOnSelectionChanged)
        this.gridOptions.addrRegGridApi?.deselectAll();
    }

    addrRegDataSource: IServerSideDatasource = {
        getRows: params => {
            let successData;
            let orderBy: string[] = [];
            let count = params.request.endRow! - params.request.startRow!;

            this.loadingAddressRegistrations = true;

            _.each(params.request.sortModel, item => {
                orderBy.push(item.colId+'_'+item.sort);
            });

            let req_params = {
                Search: this.formDataAddressRegistration.searchKeyAddresses,
                AddrType: this.formDataAddressRegistration.showAddrType,
                State: this.formDataAddressRegistration.state, 
                Info: true,
                OrderBy: orderBy
            };

            this.faxSrv.GetAddressRegistrations(params.request.startRow!, count + 1, req_params)
            .subscribe({
                next: (res: FaxConfigRestAddressRegistrationList) => {
                    this.loadingAddressRegistrations = false;
                    _.each(res, (entry: FaxConfigRestAddressRegistrationInfo) => {
                        entry.State = entry.Completed ? 'Completed':
                                      entry.Enabled   ? 'Pending':
                                      entry.ErrorCode != 0 ? 'Failed': 'Disabled';
                        entry.ReadOnly = entry.Completed ? true: false;
                    });
                    if (res.length <= count) {
                        successData = {
                            rowData: res,
                            rowCount: params.request.startRow! + res.length
                        };
                    } else {
                        res.pop();  // There is at least 1 record for the next page
                        successData = { rowData: res };
                    }
                    params.success(successData);
                },
                error: (err) => {
                    this.loadingAddressRegistrations = false;
                    params.fail();
                }
            });
        }
    };

    init_addressregistrations(): void {
        this.gridOptions.addressRegistration = {
            columnDefs: [], // wait until rootPromises is resolved
            defaultColDef: {
                flex: 1,
                resizable: true,
                sortable: true,
                minWidth: 100,
                columnChooserParams: {
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true
                }
            },
            rowModelType: 'serverSide',
            pagination: true,
            paginationAutoPageSize: true,
            tooltipShowDelay: 500,
            cacheBlockSize: this.session.portalCfg.items_per_page,
            maxBlocksInCache: 1,
            maxConcurrentDatasourceRequests: 1,
            rowSelection: 'multiple',
            suppressCsvExport: true,
            suppressExcelExport: true,
            getRowId: params => this.addrRegGetRowId(params),
            onGridReady: params => this.addrRegOnGridReady(params),
            onGridPreDestroyed: params =>this.addrRegSaveViewState(params.api),
            onCellDoubleClicked: params => this.addrRegOnCellDoubleClicked(params),
            onSelectionChanged: params => this.addrRegOnSelectionChanged(params),
            onPaginationChanged: params => this.addrRegOnPaginationChanged(params),
            onFilterChanged: params => this.addrRegOnFilterChanged(params),
            getContextMenuItems: params => this.addrRegGetContextMenuItems(params)
        };
        this.faxSrv.GetConnections({ ExcludeLoopback: true, IncludeGlobal: this.auth.isViewable('Organizations') })
            .subscribe((res: FaxConfigRestConnectionInfoList | FenRestResult) => {
                if (_.isArray(res)) {
                    this.ownedConnections = (res as FaxConfigRestConnectionInfoList)?? [];
                } else {
                    this.ownedConnections = [];
                }
                this.addrRegGridInitialized = true;
            });
    }

    private addrRegOnGridReady(params: GridReadyEvent): void {
        this.gridOptions.addrRegGridApi = params.api;
        this.addrRegGridHeight = { 'height': Math.max(300, window.innerHeight - 50) + 'px' };
        this.addrRegGridColDefs[0].hide = !this.session.contextAllOrganizations();
        params.api.setGridOption('columnDefs', this.addrRegGridColDefs);
        this.addrRegLoadViewState(params.api);
        params.api.setGridOption('serverSideDatasource', this.addrRegDataSource);
        this.refresh_addressregistrations();
        this.addrRegGridReady = true;
    }

    private addrRegGetRowId(params: GetRowIdParams): string {
        return params.data.ID!.toString(10);
    }

    private addrRegOnSelectionChanged(params: SelectionChangedEvent): void {
        let sel = params.api.getSelectedNodes();
        this.addrRegRemoveEnabled = sel? (sel.length > 0): false;
    }

    private addrRegOnPaginationChanged(params: PaginationChangedEvent): void {
            // This will trigger addrRegOnSelectionChanged:
            params.api.deselectAll();
    }

    private addrRegOnFilterChanged(params: FilterChangedEvent): void {
        // Clear all selections when the user changes a column filter.
        // This will trigger addrRegOnSelectionChanged:
        params.api.deselectAll();
    }

    addrRegSaveViewState(api: GridApi): void {
        if (this.addrRegGridReady) {
            let key: string | null = this.auth.getUserName();
            if (key) {
                key = key.toLowerCase() + 'AddrRegViewState';
                let val: ColumnState[] = api.getColumnState();
                if (val) {
                    localStorage.setItem(key, JSON.stringify(val));
                }
            }
        }
    }

    addrRegLoadViewState(api: GridApi): void {
        let key: string | null = this.auth.getUserName();
        if (key) {
            key = key.toLowerCase() + 'AddrRegViewState';
            let json: string | null = localStorage.getItem(key);
            if (json) {
                try {
                    let val: ColumnState[] = JSON.parse(json) as ColumnState[];
                    if (val) {
                        // Set the visibility of the Organization column based on context (ignore saved state)
                        let org = val.find((item: ColumnState) => { return item.colId === 'OrganizationName'; });
                        if (org) {
                            org.hide = !this.session.contextAllOrganizations();
                        }
                        // Do not apply this state unless at least one column is visible!
                        let idx = val.findIndex((item: ColumnState) => { return !item.hide; });
                        if (idx >= 0) {
                            api.applyColumnState({ state: val, applyOrder: true });
                        }
                        return;
                    }
                } catch (err) {
                    // Invalid ColumnState; do not apply it.
                }
            }
        }
        api.resetColumnState();
    }

    refresh_addressregistrations(): void {
        if (this.gridOptions.addrRegGridApi){
            this.gridOptions.addrRegGridApi.refreshServerSide();
        }
    }

    private addrRegOnCellDoubleClicked(params: CellDoubleClickedEvent): void {
        this.edit_address_registration();
    }

    private addrRegGetContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let menuItems: (string | MenuItemDef)[] = ['copy','separator'];
        if (params.node && !params.node.isSelected() ) {
            params.api.deselectAll();
            params.node.setSelected(true);
        }
        const canModify: boolean = this.auth.isModifiable('AddressRegistration');
        let canEdit: boolean = canModify;

        let sel = params.api.getSelectedNodes();
        if (_.every(sel, (item: IRowNode) => { return item.data && item.data.ReadOnly; })) {
            canEdit = false;    // all selected items are read-only
        }

        if (canModify) {
            menuItems.push({
                name: 'Add requests...',
                action: () => this.new_address_registration(),
                disabled: this.disableAdd()
            });
        }

        menuItems.push({
            name: canEdit ? 'Edit requests...' : 'View requests...',
            action: () => this.edit_address_registration(),
            disabled: (!sel || sel.length <= 0)
        });

        if (canModify) {
            menuItems.push(
                {
                    name: 'Remove requests...',
                    action: () => this.remove_address_registration()
                },
                'separator',
                {
                    name: 'Upload requests...',
                    action: () => this.upload_address_registration(),
                    disabled: this.disableAdd()
                }
            );
        }
        return menuItems;
    }

    disableAddrRegEdit(): boolean {
        if (this.gridOptions.addrRegGridApi) {
            let nodes: IRowNode[] = this.gridOptions.addrRegGridApi.getSelectedNodes();
            let canEdit: boolean = this.auth.isModifiable('AddressRegistration');
            if (!nodes || nodes.length <= 0) {
                canEdit = false;
            } else if (_.every(nodes, item => { return item.data && item.data.ReadOnly; })) {
                canEdit = false;    // all selected items are read-only
            }
            return !canEdit;
        }
        return true;
    }

    edit_address_registration(): void {
        if (this.gridOptions.addrRegGridApi) {
            let nodes = this.gridOptions.addrRegGridApi.getSelectedNodes();
            return this.addrRegEdit(nodes);
        }
    }

    addrRegEdit(nodes: IRowNode[]): void {
        if (nodes && nodes.length > 0) {
            const canEdit = this.auth.isModifiable('AddressRegistration') &&
                !_.every(nodes, item => { return item.data && item.data.ReadOnly; });

            let def: any = {
                AddrType: this.formDataAddresses.showAddrType,
                AddressStart: '',
                AddressEnd: ''
            };
            for (var _key in nodes[0].data) {
                if (_key !== 'Address') {
                    def[_key]= nodes[0].data[_key];
                }
            }
            _.each(nodes, item => {
                if (!def.AddressStart || item.data.Address < def.AddressStart) {
                    def.AddressStart = item.data.Address;
                    def.ConnectionId = item.data.ConnectionId ? item.data.ConnectionId : -1;
                    def.Description  = item.data.Description;
                }
                if (!def.AddressEnd || item.data.Address > def.AddressEnd) {
                    def.AddressEnd = item.data.Address;
                }
            });
            if (def.AddressEnd === def.AddressStart) {
                def.AddressEnd = '';
            }
            const dialogRef = this.dialog.open(AddressRegistrationEditComponent, {
                data: {
                    mode: canEdit? 'edit': 'view',
                    editee: def,
                    formattedConnectionName: this.formattedConnectionName
                }
            });
            dialogRef.afterClosed().subscribe((modified: boolean) => {
                if (modified) { this.refresh_addressregistrations(); }
            });
        }
    }

    new_address_registration(): void {
        if (this.disableAdd()){
            return;
        }
        let def = {
            AddrType: this.formDataAddressRegistration.showAddrType,
            AddressStart: '',
            AddressEnd: '',
            ConnectionId: -1,
            Description: '',
            UserAddress: '',
            UserAddrType: ''
        }
        const dialogRef = this.dialog.open(AddressRegistrationEditComponent, {
            data: {
                mode: 'add',
                editee: def,
                Connections: this.connections,
                formattedConnectionName: this.formattedConnectionName
            }
        });
        dialogRef.afterClosed().subscribe((modified: boolean) => {
            if (modified) { this.refresh_addressregistrations(); }
        });
    }

    upload_address_registration(): void {
        if (this.disableAdd()) {
            return;
        }
        this.faxSrv.GetConnections({ExcludeLoopback: true, IncludeGlobal: this.auth.isViewable('Organizations')}).subscribe(res => {
            if (_.isArray(res)) {
                let def = {
                    ConnectionId: -1,
                    Description: ''
                };
                const dialogRef = this.dialog.open(AddressRegistrationUploadComponent, {
                    data: {
                        editee: def,
                        Connections: res
                    }
                });
                dialogRef.afterClosed().subscribe((success: boolean) => {
                    if (success) { this.refresh_addressregistrations(); }
                });
            }
        });
    }

    remove_address_registration(): void {
        let entries: number[] = [];
        if (this.gridOptions.addrRegGridApi) {
            let last: FaxConfigRestAddressRegistrationInfo = {};
            this.gridOptions.addrRegGridApi.forEachNode(node => {
                if (node.isSelected()) {
                    last = node.data;
                    entries.push(node.data.ID);
                }
            });
            if (entries.length > 1) {
                if (!confirm("You have chosen to delete multiple requests. Are you sure you want to continue?")) {
                    return;
                }
            }
            if (entries.length == 1) {
                // in case of single last also is first;
                if (!confirm("You have chosen to delete request for number '" + last.Address + "'. Are you sure you want to continue?")) {
                    return;
                }
            }
            if (entries.length  > 0) {
                let promises: Observable<FaxConfigRestResult>[] = [];
                entries.forEach(e => {promises.push(this.faxSrv.DeleteAddressRegistration(e))});
                if (promises.length > 0) {
                    forkJoin(promises).subscribe({
                        next: (res) => {
                            let count = this.fenUtils.afterSave(res);
                            this.addrRegRemoveEnabled = (count == promises.length);
                            if (count > 0) { this.refresh_addressregistrations(); };
                        },
                        error: (err) => alert(err.message)
                    });
                }
            }
        }
    }

    set_address_registration(state: boolean): void {
        let promises: Observable<FaxConfigRestResult>[] = [];
        if (this.gridOptions.addrRegGridApi) {
            this.gridOptions.addrRegGridApi.forEachNode(node => {
                if (node.isSelected() && node.data.Enabled != state ) {
                    promises.push(this.faxSrv.PutAddressRegistration(node.data.ID, { Enabled: state }));
                }
            });
            if (promises.length > 0) {
                forkJoin(promises).subscribe({
                    next: (res) => {
                        let count = this.fenUtils.afterSave(res);
                        if (count > 0) { this.refresh_addressregistrations(); };
                    },
                    error: (err) => alert(err.message)
                });
            }
        }
    }

    @HostListener('window:resize', ['$event.target.innerHeight'])
    onResize(innerHeight: number) {
        // Allow extra room for the row(s) of buttons to fit within the viewport
        this.addrListGridHeight = { 'height': Math.max(300, innerHeight - 100) + 'px' };
        this.addrRegGridHeight  = { 'height': Math.max(300, innerHeight - 50) + 'px' };
    }
}
