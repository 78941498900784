<h1>Faxination Server</h1>

<div [hidden]="!isReady">
    <ul class="nav nav-tabs">
        <li *ngFor="let pageTab of pageTabs" [class.active]="pageTab === state.activePageTab">
            <a href="" (click)="state.activePageTab = pageTab;false">{{ pageTab }}</a>
        </li>
    </ul>
    <div [hidden]="state.activePageTab !== 'ATNA Logging' || !auth.isViewable('Kernel Properties')">
        <ng-container *ngTemplateOutlet="page1"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'SMTP' || !auth.isViewable('Kernel Properties')">
        <ng-container *ngTemplateOutlet="page2"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Fax Line Limits' || !auth.isViewable('Fax Line Limits')">
        <ng-container *ngTemplateOutlet="page3"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Number Format' || !auth.isViewable('Kernel Properties')">
        <ng-container *ngTemplateOutlet="page4"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Fax Retry Speeds' || !auth.isViewable('Kernel Properties')">
        <ng-container *ngTemplateOutlet="page5"></ng-container>
    </div>
    <div [hidden]="state.activePageTab !== 'Fax Loopback Services' || !auth.isViewable('Kernel Properties')">
        <ng-container *ngTemplateOutlet="page6"></ng-container>
    </div>
</div>

<ng-template #page1>
    <form id="atna_logging" class="form" novalidate>
        <p>Options for logging to a remote ATNA server.</p>
        <ul class="nav nav-tabs">
            <li *ngFor="let pageTab of atnaTabs.pageTabs" [class.active]="pageTab === atnaTabs.activePageTab">
                <a href="" (click)="atnaTabs.activePageTab = pageTab;false">{{ pageTab }}</a>
            </li>
        </ul>
        <div [hidden]="atnaTabs.activePageTab !== 'General'">
            <ng-container *ngTemplateOutlet="atna1"></ng-container>
        </div>
        <div [hidden]="atnaTabs.activePageTab !== 'Client Certificates'">
            <ng-container *ngTemplateOutlet="atna2"></ng-container>
        </div>
        <div [hidden]="atnaTabs.activePageTab !== 'Trusted Server Certificates'">
            <ng-container *ngTemplateOutlet="atna3"></ng-container>
        </div>
    </form>
</ng-template>

<ng-template #atna1>
    <form id="atna_form" class="form" #AtnaForm="ngForm" novalidate>
        <p *ngIf="isModifiableKernelProps">
            You can globally enable or disable logging to a remote ATNA server, and configure the type of connection.
            If you change any of these values, you must click the 'Apply' button to save the change.
        </p>
        <p *ngIf="!isModifiableKernelProps">
            General ATNA logging connection parameters.
        </p>
        <div class="field">
            <input type="checkbox" name="AtnaEnabled" [(ngModel)]="Atna.Enabled"
                [disabled]="!isModifiableKernelProps" (change)="AtnaGeneralChange()">
            <label>&nbsp;Enable ATNA logging</label>
        </div>
        <div class="field">
            <label>Server address:</label>
            <input type="text" name="AtnaServer" [(ngModel)]="Atna.Server" required
                [disabled]="!isModifiableKernelProps" (change)="AtnaGeneralChange()">
        </div>
        <div class="field">
            <label>Server port:</label>
            <input type="number" name="AtnaPort" [(ngModel)]="Atna.Port" min="1" max="65535" required
                [disabled]="!isModifiableKernelProps" (change)="AtnaGeneralChange()">
        </div>
        <div class="field">
            <label>Protocol:</label>
            <select name="AtnaProtocol" [(ngModel)]="Atna.Protocol" required
                [disabled]="!isModifiableKernelProps" (change)="AtnaGeneralChange()">
                    <option>UDP</option>
                    <option>TCP</option>
                    <option>TLS</option>
            </select>
        </div>
        <div class="field">
            <label>Connection timeout:</label>
            <input type="number" name="AtnaTimeoutSecs" [(ngModel)]="AtnaTimeoutSecs" min="0" max="3600" required
                [disabled]="!isModifiableKernelProps" (change)="AtnaGeneralChange()">&nbsp;seconds
        </div>
        <div class="field" *ngIf="isModifiableKernelProps">
            <label></label>
            <button type="button" class="btn btn-primary" (click)="AtnaApply()" [disabled]="!AtnaGeneralChanged || !AtnaForm.valid">Apply</button>
        </div>
    </form>
</ng-template>

<ng-template #atna2>
    <form id="atna_cert_form" class="form" novalidate>
        <p>This is a list of certificates that Faxination presents to the remote ATNA server when establishing a TLS connection.  Each entry can refer to a certificate in the local machine stores, or to a file on disk.  If it refers to a file, the Store name will be empty.</p>
        <div class="btn-toolbar" *ngIf="isModifiableKernelProps">
            <button type="button" class="btn" (click)="AddClientCert()">Add...</button>
            <button type="button" class="btn" (click)="DelClientCert()" [disabled]="fenUtils.nothingSelected(Atna.ClientCertificates)">Remove</button>
        </div>
        <table class="table table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="25px"></th>
                    <th>Store</th>
                    <th>Issued To</th>
                    <th>Effective Date</th>
                    <th>Expiration Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cert of Atna.ClientCertificates">
                    <td><input type="checkbox" name="AtnaClientSelected" [(ngModel)]="cert.selected" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>{{ cert.StoreName }}</td>
                    <td><span [ngStyle]="certCssStyle(cert)">{{ cert.IssuedTo }}</span></td>
                    <td>{{ cert.EffectiveDate }}</td>
                    <td>{{ cert.ExpirationDate }}</td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-template>

<ng-template #atna3>
    <form id="atna_trust_form" class="form" novalidate>
        <p>This is a list of certificates that are trusted by Faxination.  Certificates received from the remote ATNA server must match a certificate in this list, otherwise the TLS connection is refused.  Each entry can refer to a certificate in the local machine stores, or to a file on disk.  If it refers to a file, the Store name will be empty.</p>
        <div class="btn-toolbar" *ngIf="isModifiableKernelProps">
            <button type="button" class="btn" (click)="AddServerCert()">Add...</button>
            <button type="button" class="btn" (click)="DelServerCert()" [disabled]="fenUtils.nothingSelected(Atna.TrustedServerCertificates)">Remove</button>
        </div>
        <table class="table table-condensed table-bordered">
            <thead>
                <tr>
                    <th width="25px"></th>
                    <th>Store</th>
                    <th>Issued To</th>
                    <th>Effective Date</th>
                    <th>Expiration Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cert of Atna.TrustedServerCertificates">
                    <td><input type="checkbox" name="AtnaTrustSelected" [(ngModel)]="cert.selected" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>{{ cert.StoreName }}</td>
                    <td><span [ngStyle]="certCssStyle(cert)">{{ cert.IssuedTo }}</span></td>
                    <td>{{ cert.EffectiveDate }}</td>
                    <td>{{ cert.ExpirationDate }}</td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-template>

<ng-template #page2>
    <p>Options for SMTP. These are used for example when resetting a password for an account.</p>
    <form id="smtp_form" class="form" #smtpForm="ngForm" novalidate>
        <div class="field">
            <label>Server: *</label>
            <input type="text" name="SmtpServer" [(ngModel)]="Smtp.Server"
                   [disabled]="!isModifiableKernelProps" required tabindex="6"
                   title="Enter the IP address or host name of the mail server which will deliver the email.">
        </div>
        <div class="field">
            <label>Port: *</label>
            <input type="number" name="SmtpPort" [(ngModel)]="Smtp.Port" min="1" max="65535"
                   [disabled]="!isModifiableKernelProps" required tabindex="7"
                   title="Enter the IP port (1 to 65535) to use for connecting to the mail server.">
        </div>
        <div class="field">
            <label></label>
            <input type="checkbox" name="SmtpUseSSL" [(ngModel)]="Smtp.UseSSL" [disabled]="!isModifiableKernelProps">
            <label>&nbsp;Use SSL</label>
        </div>
        <div class="field">
            <label>Username:</label>
            <input type="text" name="SmtpUsername" [(ngModel)]="Smtp.Username"
                   [disabled]="!isModifiableKernelProps || !Smtp.UseSSL" tabindex="8"
                   title="If the mail server requires authentication, enter the user name to authenticate as.">
        </div>
        <div class="field">
            <label>Password:</label>
            <input type="password" name="SmtpPassword" autocomplete="off" [(ngModel)]="Smtp.Password"
                   [disabled]="!isModifiableKernelProps || !Smtp.UseSSL" tabindex="9"
                   title="If the mail server requires authentication, enter the password of the user to authenticate as.">
        </div>
        <div class="field" *ngIf="isModifiableKernelProps">
            <label></label>
            <button type="button" class="btn btn-primary" [disabled]="!smtpForm.valid" (click)="SmtpApply()">Apply</button>
        </div>
    </form>
</ng-template>

<ng-template #page3>
    <form id="faxlinelimits_form" class="form" novalidate>
        <p *ngIf="isModifiableFaxLineLimits">
            You can add special fax telephone numbers here in E.164 format, which are not bound
            by the global &quot;Maximum lines per number&quot; restriction.  For each number, specify how many lines are allowed to send faxes to that
            number simultaneously.  Prefix international numbers with a '+' sign.  When an outbound fax is queued by Faxination, first the Number
            Substitution rule(s) are applied to the destination number, then the result is looked up in this list to determine whether to overrule
            the global restriction.
        </p>
        <p *ngIf="!isModifiableFaxLineLimits">
            This is a list of fax telephone numbers with their own line limits.
            When an outbound fax is queued by Faxination, first the Number Substitution rule(s) are applied to the destination number,
            then the result is looked up in this list to determine whether to overrule the global restriction.
        </p>
        <div class="btn-toolbar" *ngIf="isModifiableFaxLineLimits">
            <button type="button" class="btn" (click)="FaxLineLimits_add()" [disabled]="!isReady">New</button>
            <div class="btn-group">
                <button class="btn" (click)="FaxLineLimits_enable()" [disabled]="!fenUtils.anySelection(FaxLineLimits_pager.items, 'Enabled', false)">Enable</button>
                <button class="btn" (click)="FaxLineLimits_disable()" [disabled]="!fenUtils.anySelection(FaxLineLimits_pager.items, 'Enabled', true)">Disable</button>
            </div>
            <button type="button" class="btn" (click)="FaxLineLimits_del()" [disabled]="fenUtils.nothingSelected(FaxLineLimits_pager.items)">Remove</button>
        </div>
        <app-pagination [limit]="session.portalCfg.items_per_page" [query]="FaxLineLimits_query"></app-pagination>
        <table class="table table-condensed table-bordered" *ngIf="isReady">
            <thead>
                <tr>
                    <th width="25px" *ngIf="isModifiableFaxLineLimits"></th>
                    <th>Fax Number</th>
                    <th>Max Lines</th>
                    <th>Enabled</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of FaxLineLimits_pager.items" (click)="FaxLineLimits_edit(item)">
                    <td *ngIf="isModifiableFaxLineLimits"><input type="checkbox" name="LineLimitSelected" [(ngModel)]="item.selected" (click)="$event.stopPropagation()"></td>
                    <td>{{ item.FaxNumber }}</td>
                    <td>{{ item.MaxLines }}</td>
                    <td>{{ item.Enabled }}</td>
                    <td>{{ item.Description }}</td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-template>

<ng-template #page4>
    <p>
        You can enforce E.164 for outgoing telephone numbers, and/or try to convert to E.164 if possible.  E.164 is the international
        telecommunication numbering plan which allows faxes and text messages to be routed worldwide, by ensuring that every device has a globally
        unique number.  If a number is entered that is not an international number, then it can be converted to E.164 to ensure that a message server
        in a different country (for example, a cloud-based server) can correctly route a call within the country of the sender.
    </p>
    <form class="form" id="faxNumberFormat_form" novalidate>
        <div class="field">
            <input type="checkbox" name="E164Required" [(ngModel)]="numberFormat.E164Required" [disabled]="!isModifiableKernelProps">
            <label>Require all phone numbers to conform to E.164 format</label>
        </div>
        <div class="field">
            <input type="checkbox" name="E164Conversion" [(ngModel)]="numberFormat.E164Conversion" [disabled]="!isModifiableKernelProps">
            <label>Try to convert non-international phone numbers to E.164 format</label>
        </div>
        <div class="field" *ngIf="numberFormat.E164Conversion">
            <label>If the country of the sender is unknown, assume the following default:</label><br/>
            <select name="DefaultCountryISO2" [(ngModel)]="numberFormat.DefaultCountryISO2" [disabled]="!isModifiableKernelProps">
                <option *ngFor="let c of numSubstCountries" [value]="c.ISO2">{{ c.Name }}</option>
            </select>
        </div>
        <br/>
        <p *ngIf="numberFormat.E164Conversion">
            Note that if a phone number cannot be converted to E.164, <i>and</i> the 'Require all phone
            numbers to conform to E.164 format' box is checked, then the outgoing message will be rejected.<br/>
        </p>
        <button type="button" class="btn btn-primary" *ngIf="isModifiableKernelProps" (click)="numberFormatApply()">Apply</button>
    </form>
</ng-template>

<ng-template #page5>
    <p>
        When a fax transmission fails, you can optionally configure the server to lower the transmission speed on subsequent
        attempts.  Each attempt will then use the next highest allowed bitrate that is lower than the last used bitrate.  For example,
        if a fax fails at 14400, the next attempt will switch to 12000 (if allowed).  If 12000 is not allowed, then the next attempt
        will switch to 9600 (if allowed), and so on.  If no bitrates at all are allowed below the last used bitrate, then the transmission
        speed will not change on subsequent attempts.
    </p>
    <form class="form" id="faxRetrySpeeds_form" #FaxRetrySpeedsForm="ngForm" novalidate>
        <div class="field">
            <label></label>
            <input type="checkbox" name="RetryBitrateChange" [(ngModel)]="faxRetrySpeeds.RetryBitrateChange" [disabled]="!isModifiableKernelProps">
            Lower the transmission speed each time a fax fails to send
        </div>
        <table class="table table-condensed" *ngIf="faxRetrySpeeds.RetryBitrateChange">
            <thead>
                <tr>
                    <th width="25px"></th>
                    <th>Transmission Speeds Allowed</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><input type="checkbox" name="Allow14400" [(ngModel)]="faxRetrySpeeds.Allow14400" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>14400 bits/sec</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="Allow12000" [(ngModel)]="faxRetrySpeeds.Allow12000" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>12000 bits/sec</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="Allow9600" [(ngModel)]="faxRetrySpeeds.Allow9600" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>9600 bits/sec</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="Allow7200" [(ngModel)]="faxRetrySpeeds.Allow7200" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>7200 bits/sec</td>
                </tr>
                <tr>
                    <td><input type="checkbox" name="Allow4800" [(ngModel)]="faxRetrySpeeds.Allow4800" [disabled]="!isModifiableKernelProps" (click)="$event.stopPropagation()"></td>
                    <td>4800 bits/sec</td>
                </tr>
            </tbody>
        </table>
        <br>
        <div class="field" *ngIf="isModifiableKernelProps">
            <label></label>
            <button type="button" class="btn btn-primary" [disabled]="!FaxRetrySpeedsForm.valid" (click)="faxRetrySpeedsApply()">Apply</button>
        </div>
    </form>
</ng-template>

<ng-template #page6>
    <form id="faxloopback_form" class="form" novalidate>
        <p *ngIf="isModifiableKernelProps">
Here you can define a list of fax device services to use for loopback routing. This means
that each device service must be configured to send faxes to itself. These device services
can then be selected into &quot;Fax loopback&quot; rules on the <b>Outbound Service Routing</b> page.
        </p>
        <p *ngIf="!isModifiableKernelProps">
Here you can view a list of fax device services that can be used for loopback routing. This means
that each device service must be configured to send faxes to itself. <span *ngIf="auth.isModifiable('Outbound Service Routing')">These device services
can be selected into &quot;Fax loopback&quot; rules on the <b>Outbound Service Routing</b> page.</span>
        </p>
        <table id="fax_loopback_services" class="table table-condensed" style="table-layout: fixed; width: 400px;">
            <thead>
                <tr>
                    <th width="300px"></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of faxLoopbackServices; let idx = index;">
                    <td>{{ item }}</td>
                    <td><button class="btn-link nopadding" type="button" *ngIf="isModifiableKernelProps" (click)="FaxLoopback_del(idx)">Delete</button></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <table id="fax_loopback_newservice" class="table table-condensed" style="table-layout: fixed; width: 400px;" *ngIf="isModifiableKernelProps">
            <tbody>
                <tr>
                    <td width="300px"><input type="text" maxlength="150" name="newLoopbackService" id="new_loopback_service" placeholder="Add service" [(ngModel)]="newLoopbackService.name"></td>
                    <td><button class="btn-link nopadding" type="button" *ngIf="FaxLoopback_add_enabled()" (click)="FaxLoopback_add()">Add</button>
                    <span class="text-danger" *ngIf="FaxLoopback_duplicate()">duplicate</span></td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-template>
