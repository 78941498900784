import { Routes } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { LoginComponent } from './login/login.component';
import { LicenseComponent } from './license/license.component';
import { ServiceRequestComponent } from './service-request/service-request.component';
import { AccountsComponent } from './accounts/accounts.component';
import { EcrComponent } from './ecr/ecr.component';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import { AuthGuard, UnauthGuard } from './auth.guard';
import { NtServicesComponent } from './nt-services/nt-services.component';
import { AddressesComponent } from './addresses/addresses.component';
import { SystemInfoComponent } from './system-info/system-info.component';
import { AuditlogComponent } from './auditlog/auditlog.component';
import { UsersComponent } from './users/users.component';
import { UsersSearchComponent } from './users-search/users-search.component';
import { MailTemplatesComponent } from './mail-templates/mail-templates.component';
import { OsrComponent } from './osr/osr.component';
import { OsmComponent } from './osm/osm.component';
import { QueueComponent } from './queue/queue.component';
import { LogComponent } from './log/log.component';
import { ReportingComponent } from './reporting/reporting.component';
import { OrgSettingsComponent } from './org-settings/org-settings.component';
import { CoversheetListComponent } from './coversheet-list/coversheet-list.component';
import { CoversheetComponent } from './coversheet/coversheet.component';
import { FaxinationServerComponent } from './faxination-server/faxination-server.component';
import { ConnSettingsComponent } from './conn-settings/conn-settings.component';
import { ConnHostComponent } from './conn-host/conn-host.component';
import { MonitoringListComponent } from './monitoring-list/monitoring-list.component';
import { NumSubstListComponent } from './num-subst-list/num-subst-list.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { OnetimeloginComponent } from './onetimelogin/onetimelogin.component';

export const routes: Routes = [
    {path: '', redirectTo: '/overview', pathMatch: 'full'},
    {path: 'overview', component: OverviewComponent, canActivate: [AuthGuard]},
    {path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard]},
    {path: 'organizations', component: OrganizationsComponent, canActivate: [AuthGuard]},
    {path: 'login', component: LoginComponent, canActivate: [UnauthGuard] },
    {path: 'onetimelogin', component: OnetimeloginComponent, canActivate: [UnauthGuard] },
    {path: 'license', component: LicenseComponent, canActivate: [AuthGuard]},
    {path: 'ecr_list', component: EcrComponent, canActivate: [AuthGuard]},
    {path: 'apikeys', component: ApiKeysComponent, canActivate: [AuthGuard]},
    {path: 'service_request', component: ServiceRequestComponent, canActivate: [AuthGuard]},
    {path: 'nt_services', component: NtServicesComponent, canActivate: [AuthGuard]},
    {path: 'addresses', component: AddressesComponent, canActivate: [AuthGuard]},
    {path: 'system_info', component: SystemInfoComponent, canActivate: [AuthGuard]},
    {path: 'auditlog', component: AuditlogComponent, canActivate: [AuthGuard]},
    {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
    {path: 'users_search/:source/:provider/:keyword/:filter', component: UsersSearchComponent, canActivate: [AuthGuard]},
    {path: 'mail_templates', component: MailTemplatesComponent, canActivate: [AuthGuard]},
    {path: 'osr_list', component: OsrComponent, canActivate: [AuthGuard]},
    {path: 'osm_list', component: OsmComponent, canActivate: [AuthGuard]},
    {path: 'queue', component: QueueComponent, canActivate: [AuthGuard]},
    {path: 'log', component: LogComponent, canActivate: [AuthGuard]},
    {path: 'reporting', component: ReportingComponent, canActivate: [AuthGuard]},
    {path: 'org_settings', component: OrgSettingsComponent, canActivate: [AuthGuard]},
    {path: 'coversheet_list', component: CoversheetListComponent, canActivate: [AuthGuard]},
    {path: 'coversheet/:name', component: CoversheetComponent, canActivate: [AuthGuard]},
    {path: 'faxination_server', component: FaxinationServerComponent, canActivate: [AuthGuard]},
    {path: 'conn_settings', component: ConnSettingsComponent, canActivate: [AuthGuard]},
    {path: 'conn_host/:host', component: ConnHostComponent, canActivate: [AuthGuard]},
    {path: 'monitoring_list', component: MonitoringListComponent, canActivate: [AuthGuard]},
    {path: 'num_subst_list', component: NumSubstListComponent, canActivate: [AuthGuard]},
    {path: 'contract_list', component: ContractListComponent, canActivate: [AuthGuard]}
];
