import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestNumSubstDef, FaxConfigRestResult } from '../api/api';
import * as _ from 'underscore';

export interface INumSubstScope {
    editee: FaxConfigRestNumSubstDef | null;
    machine: string;
};

@Component({
    selector: 'app-num-subst',
    imports: [FormsModule, CommonModule],
    templateUrl: './num-subst.component.html',
    styleUrl: './num-subst.component.css'
})
export class NumSubstComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    isNew: boolean = false;
    isReady: boolean = false;
    isModifiable: boolean = false;
    editee: FaxConfigRestNumSubstDef | null;
    subst?: FaxConfigRestNumSubstDef;
    messageTypes: string[] = [];
    machines: string[] = [];
    action: string;

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: INumSubstScope
    ) {
        this.editee = data.editee;
        this.action = (this.editee === null) ? 'New' : 'Edit';
        this.isModifiable = auth.isModifiable('Number Substitution');
        this.session.rootPromises.subscribe(() => {
            this.messageTypes = this.session.messageTypes?? [];
            this.init(data)
        });
    }

    init(data: INumSubstScope): void {
        if (this.editee) {
            this.isNew = false;
            this.faxSrv.numSubstDefinition(
                this.editee.Machine!,
                this.editee.MessageType!,
                this.editee.Number!
            ).subscribe(subst => {
                this.subst = subst;
                this.isReady = true;
            });
        } else {
            this.isNew = true;
            this.subst = { // default values
                Machine: data.machine,
                Description: '',
                MessageType: 'FAX',
                Number: '',
                Substitute: '',
                Block: false,
                Enabled: true
            };
            this.faxSrv.numSubstMachines().subscribe(machines => {
                this.machines = machines;
                this.isReady = true;
            });
        }
    }

    save(): void {
        if (this.isReady) {
            let promise: Observable<FaxConfigRestResult>;
            if (this.isNew) {
                promise = this.faxSrv.createNumSubstDefinition(this.subst!);
            } else {
                promise = this.faxSrv.updateNumSubstDefinition(
                    this.editee!.Machine!,
                    this.editee!.MessageType!,
                    this.editee!.Number!,
                    this.subst!
                );
            }
            promise.subscribe(res => {
                if (this.fenUtils.afterSave(res) > 0) {
                    this.dialogRef.close(true);
                }
            });
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
