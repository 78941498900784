<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Job {{ job.JobNumber }}</h1>
        <div class="modal-subtitle">
            <div><span class="subtitle_label">Date: </span><span class="subtitle_value">{{ job.CreationTime | date:'yyyy-MM-dd' }}, {{ job.CreationTime | date:'HH:mm:ss' }}</span></div>
            <div><span class="subtitle_label">Status:</span> <span class="subtitle_value"> {{ job.StatusText }}</span></div>
        </div>
    </div>
    <div class="modal-body">

        <ul class="nav nav-tabs">
            <li *ngFor="let tab of tabs; let idx = index" [class.active]="selectedTab === idx">
                <a href="" (click)="selectedTab=idx;false">{{ tab.title }}</a>
            </li>
        </ul>

        <table class="table table-condensed">
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of $any(job) | keyvalue" [hidden]="tabs[selectedTab].fields.indexOf($any(item.key)) < 0">
                    <td>{{ item.key }}</td>
                    <td [ngSwitch]="isObject(item.value)">
                        <span *ngSwitchDefault>
                            {{ item.value }}
                        </span>
                        <div *ngSwitchCase="true">
                            <table class="object">
                                <tbody>
                                    <tr *ngFor="let kv of $any(item.value) | keyvalue">
                                        <td class="{{ kv.key }}">{{ kv.key }}</td>
                                        <td>{{ kv.value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="close()">Close</button>
    </div>
</section>
