import { Component, Inject, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Session } from '../services/session.service';
import { AuthService } from '../services/auth.service';
import { FenUtilsService } from "../services/fenutils.service";
import { FaxConfigApi } from '../api/faxconfig';
import { FaxConfigRestKernelMonitoringSenderSqlLogger, FaxConfigRestResult } from '../api/api';
import * as _ from 'underscore';

export interface ISenderSqlLoggerScope {
    title: string;
    editee: FaxConfigRestKernelMonitoringSenderSqlLogger;
}

@Component({
    selector: 'app-monitoring-sender-sqllogger',
    imports: [FormsModule, CommonModule],
    templateUrl: './monitoring-sender-sqllogger.component.html',
    styleUrl: './monitoring-sender-sqllogger.component.css'
})
export class MonitoringSenderSqlloggerComponent {
    public faxSrv: FaxConfigApi = inject(FaxConfigApi);
    public fenUtils: FenUtilsService = inject(FenUtilsService);

    title: string;
    editee: FaxConfigRestKernelMonitoringSenderSqlLogger;
    isModifiable: boolean;
    Description: string = 'Click or hover over an option to view its description.';

    constructor(
        public auth: AuthService,
        public session: Session,
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: ISenderSqlLoggerScope
    ) {
        this.title = data.title;
        this.editee = _.clone(data.editee);
        this.isModifiable = auth.isModifiable('Faxination Server Monitor');
    }

    private doSave(): Observable<FaxConfigRestResult> {
        return this.faxSrv.updateMonitoringSender(this.editee);
    }

    submitEnabled(): boolean {
        return this.editee.Source? true: false;
    }

    test(): void {
        this.faxSrv.testMonitoringSender(this.editee).subscribe(res => {
            if (res.Status === 'ok') {
                alert('Successfully connected to the database.');
            } else {
                alert('Cannot connect to the database using the current parameters.');
            }
        });
    }

    save(): void {
        if (!this.editee.Source) {
            alert('The Source connection string cannot be empty.');
            return;
        }
        this.doSave().subscribe(res => {
            if (this.fenUtils.afterSave(res) > 0) {
                this.dialogRef.close(true);
            }
        });
    }

    apply(): void {
        this.doSave().subscribe(res => {
            this.fenUtils.afterSave(res);
        });
    }

    focusin(event: any): void {
        this.Description = event.currentTarget.title;
    }

    close(): void {
        this.dialogRef.close(false);
    }
}
