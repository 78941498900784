<section class="modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="close()">&times;</button>
        <h1>Add New Rule Template</h1>
    </div>
    <form class="form" name="monitoringNewTemplate" novalidate (submit)="submit()">
        <div class="modal-body">
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_QueueItems" value="QueueItems">
                <label for="newType_QueueItems"><b>QueueItems Template</b></label>
                <p>Create a new email template for a rule which monitors the number of items in the Faxination Queue.</p>
            </div>
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_QueueAge" value="QueueAge">
                <label for="newType_QueueAge"><b>QueueAge Template</b></label>
                <p>Create a new email template for a rule which monitors the ages of items in the Faxination Queue.</p>
            </div>
            <div class="field">
                <input type="radio" name="newtype" [(ngModel)]="newtype" id="newType_MessageCheck" value="MessageCheck">
                <label for="newType_MessageCheck"><b>MessageCheck Template</b></label>
                <p>Create a new email template for a rule which periodically sends messages through Faxination.</p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="!newtype">Next</button>
            <button type="button" class="btn" (click)="close()">Cancel</button>
        </div>
    </form>
</section>
