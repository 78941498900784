import { Component, Inject } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';
import { DIALOG_DATA } from '../dialog/dialog-tokens';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComboboxComponent } from '../combobox/combobox.component';
import * as _ from 'underscore';

export interface ILogFormatConfig {
    dateFormat: string;
    timeFormat: string;
}

@Component({
    selector: 'app-log-format-config',
    imports: [FormsModule, CommonModule, ComboboxComponent],
    templateUrl: './log-format-config.component.html',
    styleUrl: './log-format-config.component.css'
})
export class LogFormatConfigComponent {

    isReady: boolean = false;
    changed: boolean = false;
    editee: ILogFormatConfig;

    dateFormats: string[] = [
        'd-MM-yyyy',
        'dd-MM-yyyy',
        'M-dd-yyyy',
        'MM-dd-yyyy',
        'yyyy-MM-dd'
    ];

    timeFormats: string[] = [
        'H:mm',
        'HH:mm',
        'H:mm:ss',
        'HH:mm:ss',
        'h:mm a',
        'hh:mm a',
        'h:mm:ss a',
        'hh:mm:ss a'
    ];

    currentDateTime: Date = new Date();
    datemenu_show: boolean = false;
    timemenu_show: boolean = false;

    constructor(
        private dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: { editee: ILogFormatConfig }
    ) {
        this.editee = data.editee;
        this.init();
    }

    init(): void {
        if (!_.contains(this.dateFormats, this.editee.dateFormat)) {
            this.dateFormats.push(this.editee.dateFormat);
        }
        if (!_.contains(this.timeFormats, this.editee.timeFormat)) {
            this.timeFormats.push(this.editee.timeFormat);
        }
        this.isReady = true;
    }

    onDateFormatChanged(fmt: string): void {
        this.editee.dateFormat = fmt;
        this.changed = true;
    }

    onTimeFormatChanged(fmt: string): void {
        this.editee.timeFormat = fmt;
        this.changed = true;
    }

    submitEnabled() {
        return (this.isReady && this.changed);
    }

    save(): void {
        this.dialogRef.close(this.editee);
    }

    close(): void {
        this.dialogRef.close(null);
    }
}
